import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { editSchoolTypesAndPhasesModalViewModelQuery, editSchoolTypesAndPhasesModalViewModelQueryVariables } from '../generated/editSchoolTypesAndPhasesModalViewModelQuery';
import { schoolPhaseFieldsFragment } from '../models/SchoolPhase';
import { schoolTypeFieldsFragment } from '../models/SchoolType';
import { schoolSchoolPhaseFieldsFragment } from '../models/SchoolSchoolPhase';
import { schoolSchoolTypeFieldsFragment } from '../models/SchoolSchoolType';


/**
 * Get a the data needed by the EditSchoolTypesAndPhasesModal component.
 * @param options
 */
export function useEditSchoolTypesAndPhasesModalViewModel(schoolId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<editSchoolTypesAndPhasesModalViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<editSchoolTypesAndPhasesModalViewModelQuery, editSchoolTypesAndPhasesModalViewModelQueryVariables>(
        gql`
        query editSchoolTypesAndPhasesModalViewModelQuery($schoolId: ID!) {
            schoolPhases {
                ...schoolPhaseFields
            }

            schoolTypes {
                ...schoolTypeFields
            }

            schoolSchoolTypes  (schoolId: $schoolId) {
                ...schoolSchoolTypeFields
            }

            schoolSchoolPhases (schoolId: $schoolId) {
                ...schoolSchoolPhaseFields
            }
        }

        ${schoolPhaseFieldsFragment}
        ${schoolTypeFieldsFragment}
        ${schoolSchoolPhaseFieldsFragment}
        ${schoolSchoolTypeFieldsFragment}
        `,
        {
            variables: {
                schoolId: schoolId ?? '0'
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy && !!schoolId) {
            load();
        }
    }, [options.lazy, load, schoolId]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
