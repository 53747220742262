import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useSchoolTypes } from '../../api/main/schoolTypes/useSchoolTypes';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { WaypointExtended } from '../shared/WaypointExtended';


/**
 * List of schoolTypes.
 */
export const SchoolTypesList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSchoolTypes({ pageSize: undefined });
    const [isMenuOpenIds, setIsMenuOpenIds] = React.useState<Array<string>>([]);
    const isMenuOpen = React.useCallback((id: string) => isMenuOpenIds.find(it => it === id) ? true : false, [isMenuOpenIds]);
    const toggleMenuOpen = React.useCallback((id: string) => {
        setIsMenuOpenIds(prevState => {
            if (prevState.find(it => it === id)) {
                return prevState.filter(it => it !== id);
            } else {
                return [...prevState, id];
            }
        });
    }, [setIsMenuOpenIds]);
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 4;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('schoolTypesList.heading', 'School types')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <LinkContainer to="/administration/school-types/add">
                                    <Button color="primary">
                                        <FontAwesomeIcon icon="plus" /><> {t('schoolTypesList.add', 'Add')}</>
                                    </Button>
                                </LinkContainer>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-md-none">{t('schoolTypeLists.schoolType', 'SchoolType')}</th>
                            <th className="d-none d-md-table-cell">{t('schoolTypesList.name', 'Name')}</th>
                            <th className="d-none d-md-table-cell">{t('schoolPhasesList.displayOrder', 'Order')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {
                            items?.map(item => (
                                <tr key={item.id} onDoubleClick={e => history.push(`/administration/school-types/edit/${item.id}`)}>
                                    <td className="d-table-cell d-md-none">
                                        <div>{item.name}</div>
                                    </td>
                                    <td className="d-none d-md-table-cell">{item.name}</td>
                                    <td className="d-none d-md-table-cell">{t('common.integer', '{{value, #,0}}', { value: item.displayOrder })}</td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <LinkContainer to={`/administration/school-types/edit/${item.id}`}>
                                                    <Button color="primary" outline>
                                                        <FontAwesomeIcon icon="edit" />
                                                        <> {t('common.edit', 'Edit')}</>
                                                    </Button>
                                                </LinkContainer>
                                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                    <DropdownToggle color="primary" outline caret>
                                                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <LinkContainer to={`/administration/school-types/delete/${item.id}`}>
                                                            <DropdownItem className="text-danger">
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> {t('common.delete', 'Delete')}</>
                                                            </DropdownItem>
                                                        </LinkContainer>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                        
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <WaypointExtended key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
