import { integrationConfig } from "./integrationConfig";

/**
 * Base URL of the rest of theschoolbus website.
 */
const schoolBusBaseUrl = integrationConfig.schoolBusBaseUrl;

export const apiAuthorizationConfig = {
    applicationName: 'MonitoringReporting',

    /**
     * When true we allow developer login, otherwise we don't.
     */
    allowDeveloperLogin: integrationConfig.allowDeveloperLogin,

    /**
     * URL we need to redirect to if we can't complete the login on ourselves.
     */
    loginPageRedirectUrl: `${schoolBusBaseUrl}login?redirect=${encodeURIComponent(`${window.location.protocol}//${window.location.host}/`)}&redirectMode=window`,

    /**
     * URL we need to redirect to if we can't complete the login on ourselves but want to perform a developer login
     */
    developerLoginPageRedirectUrl: `/developer-login`,

    /**
     * Method that performs integration with the existing Login logic of TheSchoolBus via cookies, an API call, etc.
     * so the rest of the AuthorizeService can be unaware of the details of the integration and just know it works.
     */
    getTheSchoolBusLoggedInUser: async (): Promise<{ userName: string, secret: string } | null> => {
        // Call the API of TheSchoolBus to see if we are logged in or not
        //const api = new ApiFetch();
        try {
            //const schoolBusUser = await api.get(`${schoolBusBaseUrl}api/account/getCurrentUser`);
            const schoolBusUserRequest = await fetch(`${schoolBusBaseUrl}api/account/getCurrentUser`, { credentials: 'include', });
            const schoolBusUser = await schoolBusUserRequest.json();

            // If there is no user logged in to theSchoolBus then redirect to the main login page for it.
            const userId = schoolBusUser?.userEmail
                ?? schoolBusUser?.UserEmail
                ?? schoolBusUser?.userProfile?.userId
                ?? schoolBusUser?.UserProfile?.UserId;
            if (!userId) {
                // Will cause a redirect.
                return null;
            }

            // Use the logged in user details to load the user details via our own API.
            return {
                userName: userId,
                secret: 'TheSchoolBus' // Hard coded as a very basic additional level of security.
            };
        } catch (error) {
            if (!!integrationConfig.useFallbackUserOnLoginFail) {
                return {
                    userName: integrationConfig.useFallbackUserOnLoginFail,
                    secret: 'TheSchoolBus' // Hard coded as a very basic additional level of security.
                };
            }

            // Cause a redirect to the login page.
            return null;
        }
    }
};
