import * as React from 'react';
import { Button, Label, FormGroup, Spinner, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges, useChangesArray } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { Guid } from 'guid-string';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useRequirementsRelease } from '../../api/main/requirementsReleases/useRequirementsRelease';
import { useSaveRequirementsReleaseCallback } from '../../api/main/requirementsReleases/useSaveRequirementsReleaseCallback';
import { RequirementsRelease } from '../../api/main/models/RequirementsRelease';
import { Background } from '../shared/Background';
import { RequirementsReleaseVersionLabel } from './RequirementsReleaseVersionLabel';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useToggleState } from 'use-toggle-state';
import { HtmlEditor } from '../../shared/htmlEditor';
import { RequirementsReleaseRequirementArea } from '../../api/main/models/RequirementsReleaseRequirementArea';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { EditRequirementArea } from './EditRequirementArea';
import moment from 'moment';
import { useDeleteRequirementsReleaseRequirementAreaCallback } from '../../api/main/requirementsReleaseRequirementAreas/useDeleteRequirementsReleaseRequirementAreaCallback';
import { useSaveRequirementsReleaseRequirementAreaCallback } from '../../api/main/requirementsReleaseRequirementAreas/useSaveRequirementsReleaseRequirementAreaCallback';
import { useSaveRequirementAreaCallback } from '../../api/main/requirementAreas/useSaveRequirementAreaCallback';
import { useDeleteRequirementAreaCallback } from '../../api/main/requirementAreas/useDeleteRequirementAreaCallback';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { Requirement } from '../../api/main/models/Requirement';
import { useDeleteRequirementsReleaseRequirementCallback } from '../../api/main/requirementsReleaseRequirements/useDeleteRequirementsReleaseRequirementCallback';
import { useSaveRequirementsReleaseRequirementCallback } from '../../api/main/requirementsReleaseRequirements/useSaveRequirementsReleaseRequirementCallback';
import { useSaveRequirementCallback } from '../../api/main/requirements/useSaveRequirementCallback';
import { useDeleteRequirementCallback } from '../../api/main/requirements/useDeleteRequirementCallback';
import { RequirementSchoolPhase } from '../../api/main/models/RequirementSchoolPhase';
import { RequirementSchoolType } from '../../api/main/models/RequirementSchoolType';
import { useSaveRequirementSchoolPhaseCallback } from '../../api/main/requirementSchoolPhases/useSaveRequirementSchoolPhaseCallback';
import { useDeleteRequirementSchoolPhaseCallback } from '../../api/main/requirementSchoolPhases/useDeleteRequirementSchoolPhaseCallback';
import { useSaveRequirementSchoolTypeCallback } from '../../api/main/requirementSchoolTypes/useSaveRequirementSchoolTypeCallback';
import { useDeleteRequirementSchoolTypeCallback } from '../../api/main/requirementSchoolTypes/useDeleteRequirementSchoolTypeCallback';
import { useRequirementsReleaseSupportingData } from '../../api/main/requirementsReleases/useRequirementReleaseSupportingData';
import { RequirementSchoolBusLink } from '../../api/main/models/RequirementSchoolBusLink';
import { useSaveRequirementSchoolBusLinkCallback } from '../../api/main/requirementSchoolBusLinks/useSaveRequirementSchoolBusLinkCallback';
import { useDeleteRequirementSchoolBusLinkCallback } from '../../api/main/requirementSchoolBusLinks/useDeleteRequirementSchoolBusLinkCallback';
import { useCreateDraftFromRequirementsReleaseCallback } from '../../api/main/requirementsReleases/useCreateDraftFromRequirementsReleaseCallback';
import striptags from 'striptags';
import { useCallbackAwaitStateSync } from 'use-callback-await-state-sync';
import { useSendRequirementsReleasePublishNotificationsCallback } from '../../api/main/requirementsReleases/useSendRequirementsReleasePublishNotificationsCallback';
import { useRequirementSchoolBusLinks } from '../../api/main/requirementSchoolBusLinks/useRequirementSchoolBusLinks';

interface EditRequirementsReleaseProps {
    isCreate?: boolean,
    id?: string,
}

/**
 * Create a new requirementsRelease.
 */
export const CreateRequirementsRelease = () => (<EditRequirementsRelease isCreate={true} />);

/**
 * Edit an requirementsRelease
 */
export const EditRequirementsRelease = (props: EditRequirementsReleaseProps) => {
    const { id, isCreate } = props;

    const { t } = useTranslation();
    const { id: paramsId } = useParams();
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useRequirementsRelease(id ?? paramsId);
    const { model, change, changes } = useChanges<RequirementsRelease>(storeModel, isCreate ? { id: Guid.newGuid(), releaseNotes: '' } : {});
    const { data: { schoolPhases, schoolTypes, policyManagerMandatoryPolicies, draftOrCurrentRequirementsRelease, videos, }, isLoading: supportingDataIsLoading, errors: supportingDataErrors } = useRequirementsReleaseSupportingData();
    const { data: { items: storeSchoolBusLinks }, isLoading: requirementsSchoolBusLinksIsLoading, errors: requirementsSchoolBusLinksErrors } = useRequirementSchoolBusLinks({ requirementsReleaseId: id ?? paramsId });
    const isLoading = _isLoading || supportingDataIsLoading || requirementsSchoolBusLinksIsLoading;
    const [save, { errors: saveErrors }] = useSaveRequirementsReleaseCallback();

    const areaLinksManager = useChangesArray<RequirementsReleaseRequirementArea, string>(storeModel?.areas ?? [], item => item.id);
    const [saveAreaLink, { errors: saveAreaLinkErrors }] = useSaveRequirementsReleaseRequirementAreaCallback();
    const [removeAreaLink, { errors: removeAreaLinkErrors }] = useDeleteRequirementsReleaseRequirementAreaCallback();
    const areasManager = useChangesArray<RequirementArea, string>(storeModel?.areas?.map(item => item.area) ?? [], item => item.id);
    const [saveArea, { errors: saveAreaErrors }] = useSaveRequirementAreaCallback();
    const [removeArea, { errors: removeAreaErrors }] = useDeleteRequirementAreaCallback();

    const requirementLinksManager = useChangesArray<RequirementsReleaseRequirement, string>(storeModel?.requirements ?? [], item => item.id);
    const [saveRequirementLink, { errors: saveRequirementLinkErrors }] = useSaveRequirementsReleaseRequirementCallback();
    const [removeRequirementLink, { errors: removeRequirementLinkErrors }] = useDeleteRequirementsReleaseRequirementCallback();

    const storeRequirements = React.useMemo(() => {
        if (!storeModel?.requirements) {
            return [];
        }

        // Get all requirements from the links.
        const all = storeModel?.requirements?.map(item => item.requirement) ?? [];

        // Remove duplicates (as the same requirement can be linked more than once)
        let ret = [];
        for (const item of all) {
            // If this requirement is already in the list, skip it.
            const existing = ret.find(it => it.id === item.id);
            if (existing) {
                continue;
            }

            // Add this requirement to the list.
            ret.push(item);
        }

        return ret;
    }, [storeModel]);
    const requirementsManager = useChangesArray<Requirement, string>(storeRequirements, item => item.id);
    const [saveRequirement, { errors: saveRequirementErrors }] = useSaveRequirementCallback();
    const [removeRequirement, { errors: removeRequirementErrors }] = useDeleteRequirementCallback();
    const [sendPublishNotifications, { errors: sendPublishNotificationErrors }] = useSendRequirementsReleasePublishNotificationsCallback();

    const storeSchoolPhaseLinks = React.useMemo(() => {
        if (!storeModel?.requirements) {
            return [];
        }

        let all = storeModel.requirements.map(it => it.requirement?.appliesToSchoolPhases ?? []).flat();

        // Remove duplicates (as the same requirement can be linked more than once)
        let ret = [];
        for (const item of all) {
            // If this requirement is already in the list, skip it.
            const existing = ret.find(it => it.id === item.id);
            if (existing) {
                continue;
            }

            // Add this requirement to the list.
            ret.push(item);
        }

        return ret;

    }, [storeModel]);
    const schoolPhaseLinksManager = useChangesArray<RequirementSchoolPhase, string>(storeSchoolPhaseLinks, item => item.id);
    const [saveSchoolPhaseLink, { errors: saveSchoolPhaseLinkErrors }] = useSaveRequirementSchoolPhaseCallback();
    const [removeSchoolPhaseLink, { errors: removeSchoolPhaseLinkErrors }] = useDeleteRequirementSchoolPhaseCallback();

    const storeSchoolTypeLinks = React.useMemo(() => {
        if (!storeModel?.requirements) {
            return [];
        }

        let all = storeModel.requirements.map(it => it.requirement?.appliesToSchoolTypes ?? []).flat();
        
        // Remove duplicates (as the same requirement can be linked more than once)
        let ret = [];
        for (const item of all) {
            // If this requirement is already in the list, skip it.
            const existing = ret.find(it => it.id === item.id);
            if (existing) {
                continue;
            }

            // Add this requirement to the list.
            ret.push(item);
        }

        return ret;
    }, [storeModel]);
    const schoolTypeLinksManager = useChangesArray<RequirementSchoolType, string>(storeSchoolTypeLinks, item => item.id);
    const [saveSchoolTypeLink, { errors: saveSchoolTypeLinkErrors }] = useSaveRequirementSchoolTypeCallback();
    const [removeSchoolTypeLink, { errors: removeSchoolTypeLinkErrors }] = useDeleteRequirementSchoolTypeCallback();

    const schoolBusLinksManager = useChangesArray<RequirementSchoolBusLink, string>(storeSchoolBusLinks, item => item.id);
    const [saveSchoolBusLink, { errors: saveSchoolBusLinkErrors }] = useSaveRequirementSchoolBusLinkCallback();
    const [removeSchoolBusLink, { errors: removeSchoolBusLinkErrors }] = useDeleteRequirementSchoolBusLinkCallback();

    const history = useHistory();
    const [isPublishMenuOpen, togglePublishMenuOpen] = useToggleState(false);


    // Seperate validation technique used for when we try to publish.
    const [releaseNotesAreInvalid, setReleaseNotesAreInvalid] = React.useState<boolean>(false);
    const checkIfReleaseNotesAreMissing = React.useCallback((): boolean => {
        const valid = !!striptags(model?.releaseNotes ?? '').replace(/\s/g, '').length;
        if (valid) {
            setReleaseNotesAreInvalid(false);
        } else {
            setReleaseNotesAreInvalid(true);
        }

        return !valid;
    }, [model, setReleaseNotesAreInvalid]);

    // Validate the main model.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            //name: () => !model?.name ? t('editRequirementsRelease.nameRequired', 'Name is required') : '',
            releaseNotes: () => {
                if (!model?.publishDate) {
                    return '';
                }

                if (checkIfReleaseNotesAreMissing()) {
                    return t('editRequirementsRelease.releaseNotesRequired', 'You must provide release notes in order to publish changes to the requirements.');
                }

                return '';                
            },
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model, checkIfReleaseNotesAreMissing]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean, sendEmails?: boolean } = {}) => {
        if (!validate()) {
            return;
        }

        // Save the main model.
        await save(model.id, changes, !!isCreate);

        // Save all the areas.
        for (const item of areasManager.added) {
            await saveArea(item.id, areasManager.changesFor(item.id), true);
        }
        for (const item of areasManager.updated) {
            await saveArea(item.id, areasManager.changesFor(item.id), false);
        }
        for (const item of areasManager.removed) {
            await removeArea(item.id);
        }
        areasManager.markAsSaved();

        // Save all the areas links.
        for (const item of areaLinksManager.added) {
            await saveAreaLink(item.id, areaLinksManager.changesFor(item.id), true);
        }
        for (const item of areaLinksManager.updated) {
            await saveAreaLink(item.id, areaLinksManager.changesFor(item.id), false);
        }
        for (const item of areaLinksManager.removed) {
            await removeAreaLink(item.id);
        }
        areaLinksManager.markAsSaved();

        // Save all the requirements.
        for (const item of requirementsManager.added) {
            await saveRequirement(item.id, requirementsManager.changesFor(item.id), true);
        }
        for (const item of requirementsManager.updated) {
            await saveRequirement(item.id, requirementsManager.changesFor(item.id), false);
        }
        for (const item of requirementsManager.removed) {
            await removeRequirement(item.id);
        }
        requirementsManager.markAsSaved();

        // Save all the requirement links.
        for (const item of requirementLinksManager.added) {
            await saveRequirementLink(item.id, requirementLinksManager.changesFor(item.id), true);
        }
        for (const item of requirementLinksManager.updated) {
            await saveRequirementLink(item.id, requirementLinksManager.changesFor(item.id), false);
        }
        for (const item of requirementLinksManager.removed) {
            await removeRequirementLink(item.id);
        }
        requirementLinksManager.markAsSaved();

        // Save all the school phase links to requirements.
        for (const item of schoolPhaseLinksManager.added) {
            await saveSchoolPhaseLink(item.id, schoolPhaseLinksManager.changesFor(item.id), true);
        }
        for (const item of schoolPhaseLinksManager.updated) {
            await saveSchoolPhaseLink(item.id, schoolPhaseLinksManager.changesFor(item.id), false);
        }
        for (const item of schoolPhaseLinksManager.removed) {
            await removeSchoolPhaseLink(item.id);
        }
        schoolPhaseLinksManager.markAsSaved();

        // Save all the school type links to requirements.
        for (const item of schoolTypeLinksManager.added) {
            await saveSchoolTypeLink(item.id, schoolTypeLinksManager.changesFor(item.id), true);
        }
        for (const item of schoolTypeLinksManager.updated) {
            await saveSchoolTypeLink(item.id, schoolTypeLinksManager.changesFor(item.id), false);
        }
        for (const item of schoolTypeLinksManager.removed) {
            await removeSchoolTypeLink(item.id);
        }
        schoolTypeLinksManager.markAsSaved();

        // Save all the school bus links to requirements.
        for (const item of schoolBusLinksManager.added) {
            await saveSchoolBusLink(item.id, schoolBusLinksManager.changesFor(item.id), true);
        }
        for (const item of schoolBusLinksManager.updated) {
            await saveSchoolBusLink(item.id, schoolBusLinksManager.changesFor(item.id), false);
        }
        for (const item of schoolBusLinksManager.removed) {
            await removeSchoolBusLink(item.id);
        }
        schoolBusLinksManager.markAsSaved();

        // If we need to do so, send emails notifying interested people of the changes.
        if (options.sendEmails) {
            sendPublishNotifications(model.id);
        }

        // Go back to the previous screen.
        if (!options.dontNavigateBack) {
            history.goBack();
        }
    }, [
            validate, save, model, changes, isCreate, history,
            areasManager, areaLinksManager, requirementsManager, requirementLinksManager,
            schoolPhaseLinksManager, saveSchoolPhaseLink, removeSchoolPhaseLink,
            schoolTypeLinksManager, saveSchoolTypeLink, removeSchoolTypeLink,
            schoolBusLinksManager, saveSchoolBusLink, removeSchoolBusLink,
            sendPublishNotifications,
    ]);

    // Sometimes we need to be able to call saveForm() but wait until state is synced first.
    const saveFormAwaitStateSync = useCallbackAwaitStateSync((options?: { dontNavigateBack?: boolean, sendEmails: boolean }) => saveForm(options));

    // Add an area.
    const addArea = React.useCallback(() => {
        const newAreaId = Guid.newGuid();

        // Work out the next display order.
        let nextDisplayOrder = 1;
        for (const item of areaLinksManager.model) {
            if (item.displayOrder >= nextDisplayOrder) {
                nextDisplayOrder = item.displayOrder + 1;
            }
        }

        // Add the area and link models.
        areasManager.addFor({ id: newAreaId, name: '', descriptionHtml: '', archived: false, versionDate: moment().toISOString(), originKey: newAreaId, videoId: null, });
        areaLinksManager.addFor({ id: Guid.newGuid(), requirementsReleaseId: model?.id, requirementAreaId: newAreaId, displayOrder: nextDisplayOrder, archived: false });
    }, [areasManager, areaLinksManager, model]);

    // Order the areas in the order they should be displayed.
    const orderedAreaLinks = React.useMemo(() => {
        if (!areaLinksManager.model) {
            return [];
        }

        let ret = [...areaLinksManager.model];
        ret.sort((a, b) => a.displayOrder - b.displayOrder);
        return ret;
    }, [areaLinksManager]);

    // Pubishing and undoing of publishing.
    //

    // We can unpublish if we are the current release, and we have no draft.
    const canUndoPublishAndCreateDraft = React.useMemo(() => {
        if (!model) {
            return false;
        }

        if (!draftOrCurrentRequirementsRelease) {
            return false;
        }

        return draftOrCurrentRequirementsRelease.id === model.id;
    }, [model, draftOrCurrentRequirementsRelease]);

    // Publish a major version (when save is next pressed).
    const publishMajorVersion = React.useCallback(async () => {
        if (!model) {
            return;
        }

        // Make sure we are valid before we do anything.
        if (!validate() || checkIfReleaseNotesAreMissing()) {
            return;
        }

        // Update the version and publish date.
        change({
            versionNumber: model.versionNumber + 1,
            patchNumber: 0,
            publishDate: moment().toISOString(),
        });

        // Save once the state has been synchronised.
        saveFormAwaitStateSync({ dontNavigateBack: true, sendEmails: true });
    }, [change, model, saveFormAwaitStateSync, validate, checkIfReleaseNotesAreMissing]);

    // Publish a minor version.
    const publishMinorVersion = React.useCallback(async () => {
        if (!model) {
            return;
        }

        // Make sure we are valid before we do anything.
        if (!validate() || checkIfReleaseNotesAreMissing()) {
            return;
        }

        // Update the version and publish date.
        change({
            patchNumber: model.patchNumber + 1,
            publishDate: moment().toISOString(),
        });

        // Save once the state has been synchronised.
        saveFormAwaitStateSync({ dontNavigateBack: true, sendEmails: false /* We only email out on major version changes */ });
    }, [change, model, saveFormAwaitStateSync, validate, checkIfReleaseNotesAreMissing]);

    //// Undo a publish
    //const undoPublish = React.useCallback(async () => {
    //    if (!model) {
    //        return;
    //    }

    //    change({
    //        publishDate: null,
    //    });

    //    saveFormAwaitStateSync({ dontNavigateBack: true });
    //}, [change, model, saveFormAwaitStateSync]);

    // Create a new draft and redirect to editing it.
    const [createDraftFrom, { errors: createDraftFromErrors }] = useCreateDraftFromRequirementsReleaseCallback();
    const [createDraft, { isExecuting: isCreatingDraft, errors: createDraftErrors }] = useAsyncCallback(async () => {
        // First save what we have to the database.
        await saveForm({ dontNavigateBack: true });

        // Then create a new draft in the database.
        await createDraftFrom(model.id);

        // Redirect to take the user into editing the latest draft.  We add a timestamp to the URL
        // as a way to force a reload if we're already on update-requirements.
        history.replace(`/administration/update-requirements/${moment().toISOString()}`);
    }, [model, history]);

    // We are readonly if we are published.
    const readOnly = React.useMemo(() => !!model?.publishDate, [model]);

    // UI
    //
    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>
                            {
                                !isLoading || !model?.publishDate ? (
                                    <>{t('editRequirementsRelease.viewHeading', 'View requirements')}</>
                                ) : (
                                        <>{t('editRequirementsRelease.editHeading', 'Edit requirements')}</>
                                        )
                            }
                            <> </>
                            <small className="text-muted">
                                <RequirementsReleaseVersionLabel model={model} />
                            </small>
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>

                <StickyToolbar>
                    <Row>
                        <Col>
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}
                                    onClick={() => saveForm()}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Save')}</>
                                </ButtonAsync>
                            </ConditionalFragment>
                            <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                        </Col>
                        <Col xs="auto">
                            <ConditionalFragment showIf={!isLoading}>
                                <ConditionalFragment showIf={!model?.publishDate}>
                                    <ButtonGroup>
                                        <ButtonDropdown isOpen={isPublishMenuOpen} toggle={() => togglePublishMenuOpen()}>
                                            <DropdownToggle color="success" caret>
                                                <FontAwesomeIcon icon="flag-checkered" />
                                                <> </>{t('editRequirementsRelease.publish', 'Publish new version')}
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={publishMajorVersion}>
                                                    {t('editRequirementsRelease.publishMajorVersion', 'Major version release')}
                                                </DropdownItem>
                                                <DropdownItem onClick={publishMinorVersion}>
                                                    {t('editRequirementsRelease.publishMinorVersion', 'Minor patch release')}
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </ButtonGroup>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!model?.publishDate && canUndoPublishAndCreateDraft}>
                                    <ButtonGroup>
                                        <ButtonAsync color="primary" onClick={createDraft} isExecuting={isCreatingDraft}
                                            executingChildren={<><FontAwesomeIcon icon="spinner" /><> </>{t('editRequirementsRelease.creatingDraft', 'Creating draft...')}</>}>
                                            <FontAwesomeIcon icon="edit" />
                                            <> </>{t('editRequirementsRelease.createDraft', 'Edit as new draft')}
                                        </ButtonAsync>
                                        {/*
                                        <Button color="danger" onClick={undoPublish}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>{t('editRequirementsRelease.undoPublish', 'Undo publish')}
                                        </Button>
                                        */}
                                    </ButtonGroup>
                                </ConditionalFragment>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadErrors, supportingDataErrors, requirementsSchoolBusLinksErrors, saveFormErrors, saveErrors,
                    saveAreaLinkErrors, saveAreaErrors, removeAreaLinkErrors, removeAreaErrors,
                    saveRequirementLinkErrors, saveRequirementErrors, removeRequirementLinkErrors, removeRequirementErrors,
                    saveSchoolPhaseLinkErrors, removeSchoolPhaseLinkErrors,
                    saveSchoolTypeLinkErrors, removeSchoolTypeLinkErrors,
                    saveSchoolBusLinkErrors, removeSchoolBusLinkErrors,
                    createDraftErrors, createDraftFromErrors,
                    sendPublishNotificationErrors,
                ]} />

                <FormGroup>
                    <Label htmlFor="releaseNotes">{t('editRequirementsRelease.releaseNotes', 'Release notes')}</Label>
                    <HtmlEditor readOnly={readOnly} value={model?.releaseNotes ?? ''} onChange={value => change({ releaseNotes: value })} />
                    {
                        releaseNotesAreInvalid ? (
                            <div className="text-danger">
                                {t('editRequirementsRelease.releaseNotesRequired', 'You must provide release notes in order to publish changes to the requirements.')}
                            </div>
                        ) : !!validationErrors['releaseNotes'] ? (
                                <div className="text-danger">
                                    {validationErrors['releaseNotes']}
                                </div>
                            )
                                : null
                    }
                    <ConditionalFragment showIf={!!validationErrors['releaseNotes']}>
                        <div className="text-danger">
                            {validationErrors['releaseNotes']}
                        </div>
                    </ConditionalFragment>
                </FormGroup>

                <FormGroup>
                    <Label htmlFor="requirementAreas">{t('editRequirementsRelease.requirementAreas', 'Requirement areas')}</Label>
                    <div>
                        {
                            orderedAreaLinks?.map(link => {
                                const area = areasManager.modelFor(link.requirementAreaId);
                                if (!area) {
                                    return null;
                                }

                                return (
                                    <EditRequirementArea key={link.id}
                                        readOnly={readOnly}
                                        model={area} change={changes => areasManager.changeFor(area.id, changes)}
                                        validate={() => true} validationErrors={{}}
                                        linkModel={link} changeLink={changes => areaLinksManager.changeFor(link.id, changes)}
                                        startOpen={!!areaLinksManager.added.find(it => it.id === link.id)}
                                        remove={() => { areaLinksManager.removeFor(link.id); areasManager.removeFor(area.id); }}
                                        requirementsManager={requirementsManager} requirementLinksManager={requirementLinksManager}
                                        schoolPhases={schoolPhases ?? []} schoolTypes={schoolTypes ?? []} policyManagerMandatoryPolicies={policyManagerMandatoryPolicies ?? []}
                                        schoolPhaseLinksManager={schoolPhaseLinksManager} schoolTypeLinksManager={schoolTypeLinksManager}
                                        schoolBusLinksManager={schoolBusLinksManager}
                                        areaManager={areasManager}
                                        videos={videos}
                                    />
                                    );
                            })
                        }

                        <ConditionalFragment showIf={!isLoading && !readOnly}>
                            <Button color="primary" outline onClick={addArea}>
                                <FontAwesomeIcon icon="plus" />
                                <> </>{t('editRequirementsRelease.addArea', 'Add area')}
                            </Button>
                        </ConditionalFragment>

                        <ConditionalFragment showIf={isLoading && !orderedAreaLinks?.length}>
                            <LoadingIndicator fullWidth />
                        </ConditionalFragment>
                    </div>
                </FormGroup>
            </MainContainer>
        </Background>
    );
};
