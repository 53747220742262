import './sidebar.scss';

import * as React from 'react';
import { AppSidebar, AppSidebarFooter, AppSidebarForm, AppSidebarHeader, AppSidebarMinimizer } from '@coreui/react';
import { SidebarNav } from '../../shared/sidebarNav';
import { Navigation } from '../home/Navigation';
import { Nav } from 'reactstrap';
import { SchoolNavigation } from '../schoolNavigation/SchoolNavigation';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetHeaderData } from '../../api/TheSchoolBus/getHeaderData/useGetHeaderData';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { GetHeaderDataTypeHeaderToolbar } from '../../api/TheSchoolBus/getHeaderData/GetHeaderDataType';
import { openSchoolBusLink } from '../../utilities/openSchoolBusLink';

export const Sidebar = () => {
    const { t } = useTranslation();

    // Hide the sidebar if we are showing the certificate, as even if the user is logged in we want to show this page looking
    // as close to an anonymous user would see it as possible.
    const location = useLocation();
    const sidebarHidden = location.pathname.indexOf('/certificate/') >= 0;

    // Get data for the main toolbar of TheSchoolBus header navigation we need to show in the sidebar on mobile phones.
    const { data: headerData } = useGetHeaderData();

    // Handle navigating in to the tsb menu toolbars.
    const [activeTsbToolbar, setActiveTsbToolbar] = React.useState<GetHeaderDataTypeHeaderToolbar>();


    if (sidebarHidden) {
        return (<></>);
    }

    return (
        <AppSidebar fixed display="md">
            <AppSidebarHeader />
            <AppSidebarForm />

            <SidebarNav>
                <div className="sidebar-tsb-menu">
                    {
                        !activeTsbToolbar ? (
                            <>
                                <div className="sidebar-tsb-menu-heading">
                                    <div className="sidebar-tsb-menu-heading-text">
                                        {t('sidebar.tsbMenu.heading', 'Menu')}
                                    </div>
                                </div>
                                {
                                    headerData?.HeaderToolbars?.map(toolbar => {
                                        return (
                                            <div key={`${toolbar.HeaderToolbarId}.${toolbar.DisplayOrder}`}
                                                className="sidebar-tsb-menu-item"
                                                onClick={() => setActiveTsbToolbar(toolbar)}
                                            >
                                                <div className={`sidebar-tsb-menu-item-icon header-toolbar-icon header-toolbar-icon-${toolbar.IconRef}`}></div>
                                                <div className="sidebar-tsb-menu-item-text">{toolbar.DisplayText}</div>
                                                <div className="sidebar-tsb-menu-item-arrow"><FontAwesomeIcon icon="angle-right" /></div>
                                            </div>
                                        );
                                    })
                                }
                            </>
                        ) : (
                                <>
                                    <div className="sidebar-tsb-menu-heading">
                                        <div className="sidebar-tsb-menu-heading-back-arrow"
                                            onClick={() => setActiveTsbToolbar(undefined)}
                                        >
                                            <FontAwesomeIcon icon="angle-left" />
                                        </div>
                                        <div className="sidebar-tsb-menu-heading-text">
                                            {activeTsbToolbar.DisplayText}
                                        </div>
                                    </div>
                                    {
                                        activeTsbToolbar?.HeaderToolbarItems?.map((toolbarItem, toolbarItemIndex) => {
                                            return (
                                                <div key={toolbarItemIndex}
                                                    className="sidebar-tsb-menu-item"
                                                    onClick={() => openSchoolBusLink(toolbarItem.LinkUrl)}
                                                >
                                                    <div className="sidebar-tsb-menu-item-text">{toolbarItem.Title}</div>
                                                </div>
                                            );
                                        })
                                    }
                                </>
                        )
                    }
                </div>

                <Nav className="area-menu-nav">
                    <AuthorizeContainer>
                        <SchoolNavigation />
                    </AuthorizeContainer>
                </Nav>
            </SidebarNav>

            <div className="sidebar-forbes-logo"></div>
            <AppSidebarFooter />
            <AppSidebarMinimizer />
        </AppSidebar>
    );
};

