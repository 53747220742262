import gql from "graphql-tag";
import { userFields } from "../generated/userFields";
import { userTypeFieldsFragment } from "./UserType";

export const userFieldsFragment = gql`
    fragment userFields on User {
        id
        schoolId
        userTypeId
        firstname
        surname
        email
        isMaster
        active
        isDeleted

        userType {
            ...userTypeFields
        }
    }

    ${userTypeFieldsFragment}
`;


export type User = Omit<userFields, '__typename'>;
