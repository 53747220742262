import { StyleSheet, Font } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

//Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu4mxK.woff2' });

Font.register({
    family: 'Poppins', fonts: [
        { src: '/fonts/Poppins/TrueType/Poppins-Regular.ttf', format: 'truetype', },
        { fontStyle: 'italic', src: '/fonts/Poppins/TrueType/Poppins-Italic.ttf', format: 'truetype', },
        { fontWeight: '700', src: '/fonts/Poppins/TrueType/Poppins-Bold.ttf', format: 'truetype', },
        { fontStyle: 'italic', fontWeight: '700', src: '/fonts/Poppins/TrueType/Poppins-BoldItalic.ttf', format: 'truetype', },
    ]
});

/**
 * Styles to use in the PDF.
 */
export const Styles = StyleSheet.create({
    page: {
        //marginTop: 30,
        //marginBottom: 40,
        //marginLeft: 20,
        //marginRight: 20,

        //fontFamily: 'Roboto',
    },
    body: {
        flexGrow: 1,
        fontSize: 11,
        marginTop: 30,
        marginBottom: 40,
        marginLeft: 40,
        marginRight: 40,

        fontFamily: 'Poppins',
    },
    footer: {
    },
    pageNumber: {
        color: 'gray',
        fontSize: 10,
    },
    row: {
        width: '100%',
        flexDirection: 'row',
    },
    col: {
        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    h1: {
        flexGrow: 1,
        fontSize: 20,
        width: '100%',
    },
    h1Small: {
        fontSize: 12,
        color: 'gray',
        borderBottomColor: 'lightgray',
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
        marginBottom: 8,
    },
    h3: {
        marginTop: 8,
        fontSize: 11,
        marginBottom: 4,
        fontWeight: 'bold',
    },
    h4: {
        marginTop: 8,
        fontSize: 14,
    },
    h6: {
        marginTop: 6,
        fontSize: 11,
        fontWeight: 'bold',
    },
    p: {
        marginTop: 4,
        marginBottom: 4,
    },
    small: {
        fontSize: 8
    },
    strong: {
        fontWeight: 700,
    },
    textMuted: {
        color: 'gray'
    },
    table: {
        width: '100%',
    },
    thead: {
        width: '100%',

        backgroundColor: '#041e42',
        color: 'white',
        borderTopStyle: 'solid',
        borderTopWidth: 2,
        borderBottomStyle: 'solid',
        borderBottomWidth: 2,
    },
    tbody: {
        width: '100%',
    },
    trHead: {
        width: '100%',
        flexDirection: 'row',
        fontWeight: 'bold',
    },
    tr: {
        width: '100%',
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
    },
    td: {
        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    th: {
        flexDirection: 'column',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
    },
    div: {
        flexDirection: 'row',

    },
    progressBar: {
        flexDirection: 'row',
        borderBottom: 1,
        borderTop: 1,
        borderLeft: 1,
        borderRight: 1,
        borderColor: 'gray',
    },
    progressBarSection: {
        flexDirection: 'column',
        textAlign: 'center',
    },
    blankSpaceBeforeHeadinglessTables: {
        marginTop: 8,
        marginBottom: 8,
    }
});
