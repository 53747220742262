import { GetHeaderDataType } from "./GetHeaderDataType";
import { useState, useEffect, useCallback } from "react";
import { getHeaderDataMock } from "./GetHeaderData_Mock";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncLoadResult, AsyncLoadOptions } from "../../../shared/abstractStore";
import { integrationConfig } from "../../../configure/integrationConfig";

/**
 * Get the header data from the GetHeaderData API call so we can render the dynamic parts of our header consistantly
 * with the menu and category structure from TheSchoolBus.
 */
export function useGetHeaderData(options: AsyncLoadOptions = {}): AsyncLoadResult<GetHeaderDataType | undefined> {
    const [data, setData] = useState<GetHeaderDataType | undefined>();

    // Load data from the API.
    const [loadFromApi, { isExecuting: isLoading, errors, }] = useAsyncCallback(async () => {
        const result = await fetch(`${integrationConfig.schoolBusBaseUrl}api/header/getheaderdata`, { credentials: 'include', });
        const ret = await result.json();
        setData(ret);
    }, [setData]);

    // Load data from the mock for when the API isn't available (i.e. when we are not in an environment that is a subdomain of a TheSchoolBus app.
    const loadMockData = useCallback(async () => {
        setData(getHeaderDataMock);
    }, [setData]);

    // Depending on the environment we are in, set load to eithre loadFromApi or loadMockData.
    const load = useCallback(() => {
        // If we are on a development environment (i.e. we allow develop logins) then use the mock data.
        if (integrationConfig.allowDeveloperLogin) {
            loadMockData();
        } else {
            // Otherwise load the live data.
            loadFromApi();
        }
    }, [loadMockData, loadFromApi]);

    // Load the data.
    useEffect(() => {
        if (!options.lazy) {
            if (!data && !isLoading && !errors) {
                load();
            }
        }
    });

    // Refresh the data by loading it again.
    const refresh = useCallback(async () => { await load(); }, [load]);

    // Return everything in the consisnt format.
    return {
        data: data,
        isLoading: false,
        errors: undefined,
        refresh: refresh,
    };
}
