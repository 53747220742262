import * as React from 'react';
import { useParams } from 'react-router';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useSchoolReport } from '../../api/main/schoolReports/useSchoolReport';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { useEffectOnceWhen } from 'use-effect-once-when';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

/**
 * Component used to let the user view a report from a link.
 */
export const ViewReportFromLink = () => {
    const {
        id,
        checksum,
    } = useParams();

    const { t } = useTranslation();

    const { data: { model }, isLoading, errors: loadingErrors } = useSchoolReport(id);

    // Check if the checksum is valid (should match the Blob Id.)
    const isChecksumValid = React.useMemo(() => {
        if (!model || !model.blobId) {
            return false;
        }

        if (checksum === model.blobId) {
            return true;
        }

        return false;
    }, [model, checksum]);

    // Once we have loaded, if the checksum is valid redirect to the report.
    useEffectOnceWhen(
        !!model && isChecksumValid,
        () => {
            if (!model?.blob?.url) {
                return;
            }

            // Redirect into the report itself.
            window.location.href = model.blob.url;
        }
    )

    return (
        <>
            <AlertOnErrors errors={[loadingErrors]} />

            <ConditionalFragment showIf={!isLoading && !isChecksumValid}>
                <Alert color="danger">
                    {t('viewReportFromLink.wrongChecksumError', 'Sorry the link you have used is not valid.')}
                </Alert>
            </ConditionalFragment>

            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </>
        )
};