import * as React from "react";

export interface DebounceOptions {
    delay?: number,
}

/**
 * Returns a function that will automatically execute after a delay unless it gets called again, which will reset the timer.
 */
export function useDebounce(callback: () => void, options?: DebounceOptions): () => void {
    const allOptions = {
        // Defaults
        delay: 400,

        ...(options ?? {}),
    };

    // Keep a copy of callback in callbackRef so it always points to the latest one we are passed.
    const callbackRef = React.useRef<() => void>();
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    // When the user calls debounce record the fact we need to make a call.
    const debounceTimerId = React.useRef<any>();
    const action = React.useCallback(() => {
        // If we have an existing timer, clear it.
        if (debounceTimerId.current) {
            clearTimeout(debounceTimerId.current);
        }

        // Set a timer to run the current callback function if its allowed to expire.
        debounceTimerId.current = setTimeout(() => {
            debounceTimerId.current = undefined;
            callbackRef?.current?.();
        }, allOptions.delay);
    }, [allOptions.delay]);

    // When we unmount, if we have a pending debounce, handle it now.
    React.useEffect(() => {
        // Cleanup when the component unmounts.
        return () => {
            // If we have nothing pending, do nothing.
            if (!debounceTimerId.current) {
                return;
            }

            // Clear pending timeout.
            clearTimeout(debounceTimerId.current);

            // Execute the callback now.
            callbackRef?.current?.();
        };
    }, []);

    // Return the method that will queue the running of the callback behind the debunce timer.
    return action;
}
