import gql from "graphql-tag";
import { requirementsReleaseRequirementFields } from "../generated/requirementsReleaseRequirementFields";
import { requirementFieldsFragment } from "./Requirement";
import { requirementAreaFieldsFragment } from "./RequirementArea";

export const requirementsReleaseRequirementFieldsFragment = gql`
    fragment requirementsReleaseRequirementFields on RequirementsReleaseRequirement {
        id
        requirementsReleaseId
        requirementId
        requirementAreaId
        displayOrder
        archived

        requirement {
            ...requirementFields
        }

        area {
            ...requirementAreaFields
        }
    }

    ${requirementFieldsFragment}
    ${requirementAreaFieldsFragment}
`;


export type RequirementsReleaseRequirement = Omit<requirementsReleaseRequirementFields, '__typename' | 'requirement' | 'area'>;
