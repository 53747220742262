/**
 * Returns the URL with special tracking information appended for links to elsewhere in the schoolbus.
 * @param url
 */
export function addTrackingToUrl(url: string | undefined | null): string {
    // If this isn't a schoolbus.net link then don't do special tracking.
    if (!url ||
        (
        url.toLowerCase().indexOf('theschoolbus.net') === -1
        && url.toLocaleLowerCase().indexOf('/') !== 0
        )) {
        return url ?? '';
    }

    // Otherwise we want to append our tracking.
    //

    // Make sure we cope with existing query strings and non-existing query strings.
    let seperator = '?';
    if (url.indexOf('?') >= 0) {
        seperator = '&';
    }

    return `${url}${seperator}linkFrom=compliancemonitor`;
}
