import * as React from 'react';
import { RequirementsRelease } from "../../api/main/models/RequirementsRelease";
import { Badge } from "reactstrap";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export interface RequirementsReleaseVersionLabelProps {
    model: RequirementsRelease | null | undefined,
}

/**
 * Label that shows the current version of this RequirementsRelease model.
 * @param props
 */
export const RequirementsReleaseVersionLabel = (props: RequirementsReleaseVersionLabelProps) => {
    const { model } = props;
    const { t } = useTranslation();

    // Show a Draft label if we have not been released yet.
    if (!model?.publishDate) {
        return (
            <Badge color="warning">
                {t('requirementsReleaseVersionLabel.draft', 'Draft')}
            </Badge>
        );
    }

    // Otherwise show the version number with its publish date.
    return (
        <>
            {
                t(
                    'requirementsReleaseVersionLabel.versionNumber',
                    '{{versionNumber}}.{{patchNumber}} ({{publishDate, L LT}})',
                    {
                        versionNumber: model?.versionNumber ?? '0',
                        patchNumber: model?.patchNumber ?? '0',
                        publishDate: moment(model?.publishDate),
                    }
                )
            }
        </>
    );
};