import './reportHistoryCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, CardTitle, Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConditionalFragment } from 'react-conditionalfragment';
import { NoResultsFound } from '../shared/NoResultsFound';
import moment from 'moment';
import { SchoolReport } from '../../api/main/models/SchoolReport';
import { Blob } from '../../api/main/models/Blob';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { ShareLinkModal } from './ShareLinkModal';

export interface ReportHistoryCardProps {
    reports: Array<SchoolReport & { blob: Blob }> | undefined | null,
    isLoading: boolean,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const ReportHistoryCard = (props: ReportHistoryCardProps) => {
    const {
        reports,
        isLoading,
    } = props;

    const { t } = useTranslation();

    // Reverse the order of the reports so we show them newest first
    const orderedReports = React.useMemo(() => {
        if (!reports) {
            return [];
        }

        const ret = [...reports];
        ret.sort((a, b) => {
            if (a.date === b.date) {
                return 0;
            } else if (a.date < b.date) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [reports]);

    // Allow a modal to be used to share a specific report by setting this stage.
    const [shareSchoolReport, setShareSchoolReport] = React.useState<SchoolReport | null>(null);

    return (
        <>
            <Card className="report-history-card">
                <CardBody tag="div">
                    <CardTitle>
                        <h5>
                            <FontAwesomeIcon icon="file-pdf" />
                            <> </>
                            {t('reportHistoryCard.heading', 'View or share previous reports')}
                        </h5>
                    </CardTitle>

                    {
                        orderedReports?.map(item => (
                            <div key={item.id} className="report-history-card-report">
                                <Row>
                                    <Col>
                                        <strong>
                                            <a href={item.blob.url} target="_blank" rel="noopener noreferrer">
                                                {
                                                    item.isDetailed ? t('reportHistoryCard.detailedReportName', 'Deep dive report from {{date, L LT}}', { date: moment(item.date) })
                                                        : t('reportHistoryCard.detailedReportName', 'Summary report from {{date, L LT}}', {date: moment(item.date)})
                                                }                                
                                            </a>
                                        </strong>
                                        <div className="text-muted">
                                            {t('reportHistoryCard.coversAreas', 'Covers: {{areas}}', { areas: item.areas })}
                                        </div>
                                    </Col>
                                    <Col xs={12} md="auto">
                                        <ButtonGroup>
                                            <Button color="primary" outline onClick={() => window.open(item.blob.url,)}>
                                                {t('reportHistoryCard.view', 'View')}
                                            </Button>
                                            <a id={`downloadButton-${item.id}`} href={item.blob.url} download={item.blob.filename} className="btn btn-outline-primary">
                                                <FontAwesomeIcon icon="download" />
                                                <span className="sr-only">
                                                    <> </>{t('reportHistoryCard.download', 'Download')}
                                                </span>
                                            </a>
                                            <UncontrolledTooltip placement="bottom" target={`downloadButton-${item.id}`}>
                                                {t('reportHistoryCard.download', 'Download')}
                                            </UncontrolledTooltip>
                                            <Button id={`shareButton-${item.id}`} color="primary" outline onClick={() => setShareSchoolReport(item)}>
                                                <FontAwesomeIcon icon="share-square" />
                                                <span className="sr-only">
                                                    <> </>{t('reportHistoryCard.share', 'Share')}
                                                </span>
                                            </Button>
                                            <UncontrolledTooltip placement="bottom" target={`shareButton-${item.id}`}>
                                                {t('reportHistoryCard.share', 'Share')}
                                            </UncontrolledTooltip>
                                        </ButtonGroup>
                                    </Col>
                                </Row>
                            </div>
                            ))
                    }

                    <ConditionalFragment showIf={!reports?.length && !isLoading}>
                        <NoResultsFound>
                            <div className="no-results-found-icon">
                                <FontAwesomeIcon icon={['far', 'frown']} />
                            </div>
                            <p>{t('reportHistoryCard.nothingFound.outstanding', 'You haven\'t generated any reports for this school yet.  When you do they will show here so you can go back to them at any time.')}</p>
                        </NoResultsFound>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!reports?.length && isLoading}>
                        <LoadingIndicator fullWidth />
                    </ConditionalFragment>
                </CardBody>
            </Card>

            <ConditionalFragment showIf={!!shareSchoolReport}>
                <ShareLinkModal
                    isOpen={true} toggle={() => setShareSchoolReport(null)}
                    model={shareSchoolReport as SchoolReport}
                    />
            </ConditionalFragment>
        </>
        );
};