import { apiKeyConfig } from "../configure/apiKeyConfig";
import authService from "../api/api-authorization/AuthorizeService";

/**
 * Service for creating API Keys for sending to the server.
 */
export class ApiKeyService {
    /**
     * Generate an API key we can send to the server.
     */
    generateKey(): string {
        const timestamp = Date.now();
        const userId = authService?._user?.id ?? '';

        return `${apiKeyConfig.clientId}:${userId}:${timestamp}:${'SPA'}`;
    }
}