import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolRequirementComplianceCreateInput } from '../generated/globalTypes';
import { createSchoolRequirementComplianceMutation, createSchoolRequirementComplianceMutationVariables } from '../generated/createSchoolRequirementComplianceMutation';
import { schoolRequirementComplianceFieldsFragment } from '../models/SchoolRequirementCompliance';

/**
 * Get a callback to create a SchoolRequirementCompliance in the store.
 */
export function useCreateSchoolRequirementComplianceCallback(): [(model: SchoolRequirementComplianceCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSchoolRequirementComplianceMutation, createSchoolRequirementComplianceMutationVariables>(
        gql`
            mutation createSchoolRequirementComplianceMutation ($model: SchoolRequirementComplianceCreateInput!) {
                createSchoolRequirementCompliance(model: $model) {
                    ...schoolRequirementComplianceFields
                }
            }

            ${schoolRequirementComplianceFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolRequirementComplianceCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
