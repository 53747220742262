import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useLookupSchoolGroupForSchool } from '../../api/main/schoolGroups/useLookupSchoolGroupForSchool';
import { schoolGroupTypes } from '../../services/schoolGroupTypes/schoolGroupTypes';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { SidebarRequired } from '../shared/SidebarRequired';
import { useCurrentSchoolId } from "../shared/useCurrentSchoolId/useCurrentSchoolId";

/**
 * Sidebar navigation for schools.
 */
export const SchoolNavigation = () => {
    const { t } = useTranslation();

    const user = useCurrentUser();
    const { data: { model: mat } } = useLookupSchoolGroupForSchool(user?.schoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });
    const hasMat = React.useMemo(() => !!mat, [mat]);

    // Use the route to see if we need to change the sidebar to navigate another school in the MAT rather than the user's own school.
    const routeSchoolId = useCurrentSchoolId({ routeOnly: true });
    const schoolRoutePostfix = routeSchoolId ? `/${routeSchoolId}` : '';


    return (
        <>
            <SidebarRequired />

            <AuthorizeContainer>
                <AuthorizeContainer requireRole={IdentityRoles.MATUser}>
                    <ConditionalFragment showIf={hasMat}>
                        <NavItem>
                            <NavLink tag={NavLinkTrackActive} to="/mat-overview">
                                <FontAwesomeIcon icon="calendar-week" className="nav-icon" />
                                <> {t('navigation.matOverview', 'MAT overview')}</>
                            </NavLink>
                        </NavItem>
                    </ConditionalFragment>
                </AuthorizeContainer>      

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/overview${schoolRoutePostfix}`}>
                        <FontAwesomeIcon icon="calendar-week" className="nav-icon" />
                        <> {t('navigation.complianceOverview', 'Overview')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/action-plan${schoolRoutePostfix}`}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> {t('navigation.actionPlan', 'Action plan')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/reports${schoolRoutePostfix}`}>
                        <FontAwesomeIcon icon="print" className="nav-icon" />
                        <> {t('navigation.reports', 'Reports')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/awards${schoolRoutePostfix}`}>
                        <FontAwesomeIcon icon="award" className="nav-icon" />
                        <> {t('navigation.awards', 'Awards')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/school-settings${schoolRoutePostfix}`}>
                        <FontAwesomeIcon icon="user-cog" className="nav-icon" />
                        <> {t('navigation.schoolSettings', 'School settings')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>      

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <> {t('navigation.administration', 'Administration')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
