import gql from "graphql-tag";
import { schoolGroupFields } from "../generated/schoolGroupFields";
import { schoolGroupTypeFieldsFragment } from "./SchoolGroupType";

export const schoolGroupFieldsFragment = gql`
    fragment schoolGroupFields on SchoolGroup {
        id
        schoolGroupName
        schoolGroupTypeId
        
        groupType {
            ...schoolGroupTypeFields
        }
    }

    ${schoolGroupTypeFieldsFragment}
`;


export type SchoolGroup = Omit<schoolGroupFields, '__typename' | 'groupType'>;
