import * as React from "react";

/**
 * Log the user out (client side version of LogoutNotifyController.Logout for when the service worker decides it wants to handle the URL).
 */
export const LogoutNotify = () => {
    React.useEffect(() => {
        // Remove the session cookie.
        localStorage.removeItem('AuthorizeService.persitedUser');

        //// If the user has navigated to this page directly (for some strage reason) then redirect them to / after logging them out.
        //// Because this will usually be triggered in a short-life iframe, we wait 1.5 seconds before doing this, as the iframe will
        //// usually be gone by then, so we don't load any extra pages into it when just trying to logout in an iframe.
        //const timer = setTimeout(() => {
        //    window.location.href = '/';
        //}, 1500);
        //return () => clearTimeout(timer);
    }, []);

    return (<>Logging out</>);
}
