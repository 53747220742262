import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useRequirementsReleases } from '../../api/main/requirementsReleases/useRequirementsReleases';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { RequirementsReleaseVersionLabel } from './RequirementsReleaseVersionLabel';
import { HtmlDisplay } from '../../shared/htmlEditor/HtmlDisplay';
import { WaypointExtended } from '../shared/WaypointExtended';

/**
 * List of requirementsReleases (that are not projects).
 */
export const RequirementsReleasesList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useRequirementsReleases();

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            `${item.versionNumber}.${item.patchNumber}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.releaseNotes.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    // Make sure the URL keeps in sync with the search.
    useReplaceSearchParamsEffect({ search: search });

    const colCount = 4;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('requirementsReleasesList.heading', 'Compliance requirements releases')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-lg-none">{t('requirementsReleasesList.requirementsRelease', 'Release')}</th>
                            <th className="d-none d-lg-table-cell">{t('requirementsReleasesList.name', 'Version')}</th>
                            <th className="d-none d-lg-table-cell">{t('requirementsReleasesList.isAbsence', 'Release notes')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => (
                                <tr key={item.id} onDoubleClick={e => history.push(`/administration/requirements/edit/${item.id}`)}>
                                    <td className="d-table-cell d-lg-none">
                                        <div><RequirementsReleaseVersionLabel model={item} /></div>
                                        <div className="text-muted">
                                            <HtmlDisplay sanatizedHtml={item.releaseNotes} />
                                        </div>
                                    </td>
                                    <td className="d-none d-lg-table-cell"><RequirementsReleaseVersionLabel model={item} /></td>
                                    <td className="d-none d-lg-table-cell"><HtmlDisplay sanatizedHtml={item.releaseNotes} /></td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <LinkContainer to={`/administration/requirements/edit/${item.id}`}>
                                                    <Button color="primary" outline>
                                                        <FontAwesomeIcon icon="edit" />
                                                        <> {t('common.edit', 'Edit')}</>
                                                    </Button>
                                                </LinkContainer>
                                                <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                                    <DropdownToggle color="primary" outline caret>
                                                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <LinkContainer to={`/administration/requirements/delete/${item.id}`}>
                                                            <DropdownItem className="text-danger">
                                                                <FontAwesomeIcon icon="trash" />
                                                                <> {t('common.delete', 'Delete')}</>
                                                            </DropdownItem>
                                                        </LinkContainer>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <WaypointExtended key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};

