import * as React from 'react';
import { Requirement } from '../../api/main/models/Requirement';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { isNullOrUndefined } from 'util';
import { useCalculateCurrentAward } from "./useCalculateCurrentAward/useCalculateCurrentAward";
import { ComplianceProgressBarPreCalculated } from "./ComplianceProgressBarPreCalculated";

export interface ComplianceProgressBarProps {
    size?: 'md' | 'lg',

    requirements: Array<Requirement> | undefined | null,
    compliances: Array<SchoolRequirementCompliance> | undefined | null,
}

/**
 * Progress bar for an compliance of a school's compliance.
 * @param props
 */
export const ComplianceProgressBar = (props: ComplianceProgressBarProps) => {
    const {
        size = 'md',
        requirements,
        compliances,
    } = props;

    // Calculator our compliance across the different levels.
    const currentAward = useCalculateCurrentAward(requirements, compliances, null);
    const {
        bronzeCompliance,
        silverCompliance,
    } = currentAward;

    const bronzeComplete = (bronzeCompliance.unanswered + bronzeCompliance.failed <= 0);
    const silverComplete = bronzeComplete && (silverCompliance.unanswered + silverCompliance.failed <= 0);
    //const goldComplete = silverComplete && (goldCompliance.unanswered + goldCompliance.failed <= 0);

    // Store the initial completeness of each stage.  We use this to turn on animations when a user completes a stage and moves on to the
    // next, but to make sure we don't animate when we're just showing the results.
    const [bronzeInitiallyComplete, setBronzeInitiallyComplete] = React.useState<boolean | undefined>(undefined);
    const [silverInitiallyComplete, setSilverInitiallyComplete] = React.useState<boolean | undefined>(undefined);
    React.useEffect(() => {
        // If we don't have all the data yet, do nothing.
        if (!compliances?.length && !requirements?.length) {
            return;
        }

        if (isNullOrUndefined(bronzeInitiallyComplete)) {
            setBronzeInitiallyComplete(bronzeComplete);
        }

        if (isNullOrUndefined(silverInitiallyComplete)) {
            setSilverInitiallyComplete(silverComplete);
        }
    }, [
        compliances, requirements,
        bronzeComplete, bronzeInitiallyComplete, setBronzeInitiallyComplete,
        silverComplete, silverInitiallyComplete, setSilverInitiallyComplete,
    ]);

    // If we don't know which items to show yet show nothing.
    if (!compliances?.length && !requirements?.length) {
        return (<></>);
    }

    return (
        <ComplianceProgressBarPreCalculated
            currentAward={currentAward}
            size={size}
            bronzeInitiallyComplete={bronzeInitiallyComplete ?? null /* null says we are working it out, undefined would mean not passed */}
            silverInitiallyComplete={silverInitiallyComplete ?? null /* null says we are working it out, undefined would mean not passed */}
        />
    );
};
