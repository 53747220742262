import * as React from 'react';
import { Table, Button, Row, Col, ButtonGroup } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { TableRowButtons } from '../shared/TableRowButtons';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useSchools } from '../../api/main/schools/useSchools';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { RoundedButtonGroup } from '../shared/RoundedButtonGroup';
import { usePersistedState } from '../../shared/usePersistedState';
import { WaypointExtended } from '../shared/WaypointExtended';


/**
 * List of schools.
 */
export const SchoolsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    const [isLicencedForMonitoringReporting, setIsLicencedForMonitoringReporting] = usePersistedState<boolean | undefined>('schoolsList.isLicencedForMonitoringReporting', true);

    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSchools({ isLicencedForMonitoringReporting: isLicencedForMonitoringReporting, pageSize: isLicencedForMonitoringReporting? undefined: 100 /* Paging used for performance reasons */ /* Paging used for performance reasons */ });
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        if (!allItems || !search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.schoolName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.schoolName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item?.establishmentNo?.toString() ?? '').toLocaleLowerCase()?.indexOf(lowerSearch) >= 0
            || (item?.street ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item?.town ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item?.address3 ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item?.county ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item?.postCode ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });

    const colCount = 5;

    return (
        <>
            <Banner fluid>
                <Row>
                    <Col>
                        <h1>{t('schoolsList.heading', 'Schools')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs={12} md="auto">
                            <RoundedButtonGroup>
                                <Button color="secondary" outline={!isLicencedForMonitoringReporting} onClick={() => setIsLicencedForMonitoringReporting(true)}>
                                    {t('schoolsList.licensedOnly', 'Licenced')}
                                </Button>
                                <Button color="secondary" outline={!!isLicencedForMonitoringReporting} onClick={() => setIsLicencedForMonitoringReporting(undefined)}>
                                    {t('schoolsList.licencedAndUnlicneced', 'All')}
                                </Button>
                            </RoundedButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell d-lg-none">{t('schoolLists.school', 'School')}</th>
                            <th className="d-none d-lg-table-cell">{t('schoolsList.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('schoolsList.establishmentNo', 'Establishment No.')}</th>
                            <th className="d-none d-lg-table-cell">{t('schoolPhasesList.address', 'Address')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                      
                        {
                            items?.map(item => (
                                <tr key={item.id} onDoubleClick={e => history.push(`/overview/${item.id}`)}>
                                    <td className="d-table-cell d-lg-none">
                                        <div>{item.schoolName}</div>
                                        <div className="text-muted">{item.establishmentNo}</div>
                                    </td>
                                    <td className="d-none d-lg-table-cell">{item.schoolName}</td>
                                    <td className="d-none d-lg-table-cell">{item.establishmentNo}</td>
                                    <td className="d-none d-lg-table-cell">
                                        <ConditionalFragment showIf={!!item.street}>
                                            {item.street}<br />
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.town}>
                                            {item.town}<br />
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.address3}>
                                            {item.address3}<br />
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.county}>
                                            {item.county}<br />
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!item.postCode}>
                                            {item.postCode}<br />
                                        </ConditionalFragment>
                                    </td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <LinkContainer to={`/overview/${item.id}`}>
                                                    <Button color="primary" outline>
                                                        <FontAwesomeIcon icon="book-reader" />
                                                        <> {t('schoolsList.view', 'View overview')}</>
                                                    </Button>
                                                </LinkContainer>
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                        
                        <ConditionalFragment showIf={isLoading && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && !items?.length && !hasMore()}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoading && hasMore()}>
                            <tr><td colSpan={colCount}>
                                <WaypointExtended key={items?.length ?? 0} onEnter={fetchMore} />
                                <LoadingIndicator fullWidth />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </MainContainer>
        </>
    );
};
