import * as React from 'react';

export interface IsElementVisibleOptions extends IntersectionObserverInit {
    /**
     * When true we will only toggle from not visible to visible and not back.  This is useful when rendering components for example
     * so the state of the component doesn't get lost when it is scrolled off screen.
     *
     * Default is false, but the render RenderIfVisible component defaults this to true unless overriden using unmountIfInvisible.
     */
    notifyVisibleOnly?: boolean,
}

/**
 * Hook that returns true when the element is visible on the screen of the user.
 *
 * This can be used to do "virtual rendering" of large lists or expensive components to improve rendering performance when required.
 * In most cases if rendering is not taking place because of visibility, a simple placeholder of an estimated size should be shown instead so
 * scrolling can still work logically.
 */
export function useIsElementVisible(elementRef: React.RefObject<any>, options?: IsElementVisibleOptions): boolean {
    // Visible state.
    const [isVisible, setIsVisible] = React.useState<boolean>(false);

    // Resolved options using passed options and the defaults.
    const optionsWithDefaults = React.useMemo(() => ({
        // Defaults.
        notifyVisibleOnly: false,

        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: 0,

        // Override anything the user has supplied.
        ...(options ?? {})
    }), [options]);

    // When the element scrolls in or out of the intersection change the visible status.
    React.useEffect(() => {
        if (!elementRef.current) {
            return;
        }

        // If we are only notifiying when we are visible, and we have already done that notification, don't bother attaching the observer.
        if (isVisible && optionsWithDefaults.notifyVisibleOnly) {
            return;
        }

        // Attach an observer to let us check any intersections so we can see when we're on screen.
        const observer = new IntersectionObserver((entries, observer) => {
            let visible: boolean = false;

            for (const entry of entries) {
                if (entry.isIntersecting) {
                    visible = true;
                    break;
                }
            }

            if (optionsWithDefaults.notifyVisibleOnly) {
                if (visible) {
                    setIsVisible(visible);
                    observer.unobserve(elementRef.current);
                }
            } else {
                setIsVisible(false);
            }
        }, optionsWithDefaults);
        observer.observe(elementRef.current);

        // Cleanup the observer when the element changes or unmounts.
        const cleanupElementRefCurrent = elementRef.current;
        return () => observer.unobserve(cleanupElementRefCurrent);
    }, [elementRef, optionsWithDefaults, setIsVisible, isVisible]);

    return isVisible;
}
