import * as React from 'react';
import { Requirement } from '../../api/main/models/Requirement';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, ButtonGroup } from 'reactstrap';
import { TableRowButtons } from '../shared/TableRowButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchInput } from '../shared/SearchInput';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { RequirementArea } from '../../api/main/models/RequirementArea';

export interface AddLinkModalProps {
    isOpen: boolean,
    toggle: () => void,
    requirements: Array<Requirement>,
    requirementLinks: Array<RequirementsReleaseRequirement>,
    areas: Array<RequirementArea>,

    addLink: (requirementId: string) => void,
}

/**
 * Modal to let the user link existing requirements into new sections.
 * @param props
 */
export const AddLinkModal = (props: AddLinkModalProps) => {
    const {
        isOpen, toggle,
        requirements,
        requirementLinks, areas,
        addLink,
    } = props;
    const { t } = useTranslation();

    const orderedRequirements = React.useMemo(() => {
        if (!requirements) {
            return [];
        }

        let ret = [...requirements];
        ret.sort((a, b) => a.name.localeCompare(b.name));
        return ret;
    }, [requirements]);

    // Filtering.
    const [search, setSearch] = React.useState<string>('');
    const filteredRequirements = React.useMemo(() => {
        if (!orderedRequirements) {
            return orderedRequirements;
        }

        if (!search) {
            return orderedRequirements;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return orderedRequirements.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [orderedRequirements, search]);

    return (
        <Modal size="xl" isOpen={isOpen} toggle={toggle} modalClassName="root-app-modal">
            <ModalHeader toggle={toggle}>
                {t('addLinkModal.heading', 'Link to an existing requirement from another section')}
            </ModalHeader>
            <ModalBody>
                <div>
                    <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                </div>
                <Table responsive striped>
                    <thead>
                        <tr>
                            <th className="d-table-cell">{t('addLinkModal.requirementName', 'Requirement')}</th>
                            <th className="d-table-cell">{t('addLinkModal.areas', 'Current areas')}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            filteredRequirements?.map(item => (
                                <tr key={item.id} onDoubleClick={() => addLink(item.id)}>
                                    <td className="d-table-cell">{item.name}</td>
                                    <td className="d-table-cell">
                                        {
                                            areas?.map(area => {
                                                const link = requirementLinks?.find(link => link.requirementId === item.id && link.requirementAreaId === area.id);
                                                if (!link) {
                                                    return null;
                                                }

                                                return (
                                                    <div key={area.id}>
                                                        {area.name}
                                                    </div>
                                                    );
                                            })
                                        }
                                    </td>
                                    <td>
                                        <TableRowButtons>
                                            <ButtonGroup>
                                                <Button color="primary" outline onClick={() => addLink(item.id)}>
                                                    <FontAwesomeIcon icon="link" />
                                                    <> {t('addLinkModal.addLink', 'Add link')}</>
                                                </Button>
                                            </ButtonGroup>
                                        </TableRowButtons>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    {t('common.close', 'Close')}
                </Button>
            </ModalFooter>
        </Modal>
        );
};
