import * as React from 'react';
import { useServices } from "inject-typesafe-react";
import { AppServices } from "../../configure/configureServices";
import { CacheOptions } from '../cacheInRedux';

/**
 * Use state that is persists between sessions so long as the same key is requested.
 * 
 * The persistance provided by this hook does not include mechanics to update multiple instances of a component in use at the same time.  Use a global
 * state management solution such as redux for those situations.
 * 
 * Implementation wise this uses CacheService and provides an API that matches useState() with the exception of needing a key.  This allows the lifecycle
 * management of the CacheService to apply to anything we persist in state (e.g. usually this means the persisting is cleared on logout etc.) so we don't have to worry about
 * an extra API or per-user keys to cope with those sort of concerns.
 */
export function usePersistedState<T>(key: string, initialValue?: T, options?: CacheOptions): [T, (value: React.SetStateAction<T>) => void] {
    // The caching service is where we'll store our value between sessions.
    const cacheService = useServices((services: AppServices) => services.cacheService());

    // Use a state specific to the component initialised on first use to either the value from the cache or the passed in initial value.
    const [stateValue, setStateValue] = React.useState<T | undefined>(cacheService.readFromCache(key) ?? initialValue);

    // Set the state and persist the value set into the cache.
    const setStateAndPersist = React.useCallback((value: React.SetStateAction<T>) => {
        setStateValue((prevState: T | undefined) => {
            // Get the value either directly, or by invoking the callback.
            let newState: T;
            if (value instanceof Function) {
                newState = value(prevState as T);
            } else {
                newState = value;
            }

            // Persist in the cache.
            cacheService.storeInCache(key, newState, options);

            // Return the value to let the state be updated.
            return newState;
        });
    }, [cacheService, key, setStateValue, options]);

    return [stateValue as T, setStateAndPersist];
}