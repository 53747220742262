import './actionCard.scss';

import * as React from 'react';
import { Action } from '../../api/main/models/Action';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { ActionUserTask } from '../../api/main/models/ActionUserTask';
import { Requirement } from '../../api/main/models/Requirement';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { User } from '../../api/main/models/User';
import { School } from '../../api/main/models/School';
import { useTranslation } from 'react-i18next';
import { actionStates, ActionState } from '../../services/actionStates/actionStates';
import moment from 'moment';
import { Card, Row, Col, CardBody, FormGroup, Label, Button, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { getComplianceRequirementUrl } from '../../utilities/getComplianceRequirementUrl';
import { ValidatedDateInput } from '../shared/ValidatedDateInput';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { useToggleState } from 'use-toggle-state';
import striptags from 'striptags';
import { useChanges } from '../../shared/useChanges';
import { useDebounce } from '../shared/hooks/useDebounce';
import { ActionsSortOptions } from './Actions';
import { useActionColor } from './useActionColor';

export interface ActionCardProps {
    model: Action,
    change: (changes: Partial<Action>) => void,
    remove: () => void,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    tasks: Array<ActionUserTask>,
    changeTask: (id: string, changes: Partial<ActionUserTask>) => void,
    addTask: () => void,
    removeTask: (id: string) => void,

    requirement: Requirement | undefined | null,
    requirementLink: RequirementsReleaseRequirement | undefined | null,
    user: User | undefined | null,
    school: School | undefined | null,

    users: Array<{ id: string, firstname: string, surname: string }> | null | undefined,

    saveDebounce: () => void,

    openModal: () => void,

    askIfWantToContinueWorkflowOnCompletion: () => void,

    isFirstInGroup: boolean,

    sortOption: ActionsSortOptions,
    toggleSortOption: (sortOption: ActionsSortOptions) => void,
}

export const ActionCard = (props: ActionCardProps) => {
    const {
        model: underlyingModel, change: underlyingChange, /*remove,*/
        /*validate,*/ validationErrors,
        tasks, changeTask: underlyingChangeTask, addTask, /*removeTask,*/
        requirement, requirementLink, user, school,
        /*users,*/
        saveDebounce: _saveDebounce,
        openModal,
        askIfWantToContinueWorkflowOnCompletion,
        users,
        isFirstInGroup,
        toggleSortOption,
    } = props;
    const { t } = useTranslation();
    const history = useHistory();

    // Create a local overlay of the model so our changes can be handled locally and synced back to the overall array on demand rather than automatically.
    // This creates a better user experience (things don't move around unexpectedly) and a much faster user experience too.
    const { model, change, changes } = useChanges(underlyingModel);

    // Get the first task, as we want to be able to modify that.  If we don't have one we'll create one.
    // NOTE the database still supports multiple tasks for an action, but the UI now only cares about using tasks for who is assigned, so we flatten
    // it in the UI here to a single task.
    // As with model we also want to keep a localised version of this for performance reasons.
    const underlyingTask = tasks.length ? tasks[0] : undefined;
    React.useEffect(() => {
        if (!underlyingTask) {
            addTask();
        }
    }, [underlyingTask, addTask]);
    const { model: task, change: changeTask, changes: taskChanges } = useChanges(underlyingTask);

    // Commit local changes made by change up to our parent by passing them on to realChange.
    const commitChanges = React.useCallback(() => {
        underlyingChange(changes);
        underlyingChangeTask(task.id, taskChanges);
        _saveDebounce();
    }, [changes, taskChanges, _saveDebounce, underlyingChange, underlyingChangeTask, task]);

    // We have use a debounce timer ourselves here to cope with issues around the onBlur event of the date input field.
    const saveDebounce = useDebounce(() => commitChanges());

    // Change the state of this task
    const changeState = React.useCallback((state: ActionState) => {
        change({
            actionState: state.id,
            completedDate: state.isCompleted ? moment().toISOString() : null,
        });

        if (state.isCompleted) {
            askIfWantToContinueWorkflowOnCompletion();
        }

        saveDebounce();
    }, [change, askIfWantToContinueWorkflowOnCompletion, saveDebounce]);

    // Dropdown menu.
    const [isMenuOpen, toggleMenu] = useToggleState();

    // Pinning of the action.
    const togglePinned = React.useCallback(() => {
        change({ isPinned: !model.isPinned });
        saveDebounce();
    }, [model, change, saveDebounce]);

    // Deleting the action.
    const removeAction = React.useCallback(() => {
        change({ archived: true });
        saveDebounce();
    }, [change, saveDebounce]);

    // What colour should we be?
    const color = useActionColor(model);

    // We want to know if model.descriptionHtml is empty or white space.
    const descriptionHtmllIsEmpty = React.useMemo(() => !striptags(model?.descriptionHtml ?? '').replace(/\s/g, '').length, [model]);

    return (
        <Card className={`action-card action-card-${color} ${isFirstInGroup ? 'action-card-first-in-group' : 'action-card-not-first-in-group'}`} color={color} outline>
            <CardBody tag="div">
                <Row>
                    <Col xs={12} lg={6}>
                        <FormGroup>
                            <Label htmlFor="name">{t('actionCard.name.label', 'Action')}</Label>
                            <Row>
                                <Col onClick={() => openModal()} style={{ cursor: 'pointer' }}>
                                    {model.name}
                                </Col>

                                <Col xs="auto">
                                    {/* Notes indication icon */}
                                    {
                                        !descriptionHtmllIsEmpty ? (
                                            <FontAwesomeIcon icon="comment-alt" onClick={() => openModal()} style={{ cursor: 'pointer' }} />
                                        ) : null
                                    }

                                    {/* Pinned indication icon */}
                                    {
                                        model.isPinned ? (
                                            <FontAwesomeIcon icon="thumbtack" onClick={() => togglePinned()} style={{ transform: 'rotate(45deg)', cursor: 'pointer', marginLeft: '2px' }} />
                                        ) : null
                                    }
                                </Col>
                            </Row>
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg="">
                        <FormGroup>
                            <Label htmlFor="actionAddedDate">
                                {t('actionCard.actionAddedDate', 'Due date')}
                                <> </>
                                <FontAwesomeIcon icon="sort" style={{ cursor: 'pointer' }} onClick={() => toggleSortOption('due-date-asscending')} />
                            </Label>
                            <ValidatedDateInput name="actionAddedDate" type="date" value={model?.actionAddedDate ?? ''} onChange={e => { change({ actionAddedDate: e.currentTarget.value }); saveDebounce(); /* onBlur event not trigger in chromium */ }} onBlur={() => saveDebounce()} validationErrors={validationErrors} />
                        </FormGroup>
                    </Col>
                    <Col xs={12} lg="">
                        <FormGroup>
                            <Label htmlFor="actionAssignedTo">
                                {t('actionCard.task.userId.label', 'Assigned to')}
                                <> </>
                                <FontAwesomeIcon icon="sort" style={{ cursor: 'pointer' }} onClick={() => toggleSortOption('assigned-to-asscending')} />
                            </Label>
                            {
                                task ? (
                                    <ValidatedInput key={task.id} name="userId" type="select" value={task?.userId ?? ''} onChange={e => changeTask({ userId: parseInt(e.currentTarget.value) })} onBlur={() => saveDebounce()} validationErrors={validationErrors}>
                                        <option value={0}>{t('editActionUserTask.userUnassigned', '(No user assigned yet)')}</option>
                                        {
                                            users?.map(item => (
                                                <option key={item.id} value={item.id}>{t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.firstname, lastName: item.surname })}</option>
                                            ))
                                        }
                                    </ValidatedInput>
                                ): null
                            }
                        </FormGroup>
                    </Col>
                    <Col xs="" lg={!!model.completedDate? '': 'auto'}>
                        <FormGroup>
                            <ConditionalFragment showIf={!!model.completedDate}>
                                <Label htmlFor="completedDate">
                                    {t('actionCard.completedDate', 'Completed on')}
                                    <> </>
                                    <FontAwesomeIcon icon="sort" style={{ cursor: 'pointer' }} onClick={() => toggleSortOption('completed-date-asscending')} />
                                </Label>
                                <Row>
                                    <Col>
                                        <ValidatedDateInput name="completedDate" type="date" value={model?.completedDate ?? ''} onChange={e => change({ completedDate: e.currentTarget.value })} onBlur={() => saveDebounce()} validationErrors={validationErrors} onClick={e => { e.preventDefault(); openModal(); /* editing inline gets confusing as things move around the screen, so open the modal instead */ }} />
                                    </Col>
                                    <Col xs="auto">
                                        <Button color="danger" outline onClick={() => changeState(actionStates.outstanding)}>
                                            <FontAwesomeIcon icon="undo" />
                                            <> </>{t('actionCard.undoCompletion', 'Undo completion')}
                                        </Button>
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!model.completedDate}>
                                <Row>
                                    <Col>
                                        <Label htmlFor="completedDate">&nbsp;</Label>
                                    </Col>
                                </Row>
                                <div>
                                    <Button color="success" outline onClick={() => changeState(actionStates.completed)}>
                                        <FontAwesomeIcon icon="check" />
                                        <> </>
                                        {t('actionCard.complete', 'Complete')}
                                    </Button>
                                </div>
                            </ConditionalFragment>
                        </FormGroup>
                    </Col>
                    <Col xs="auto">
                        <FormGroup>
                            <Label htmlFor="actions">&nbsp;</Label>
                            <div>
                                <ButtonDropdown isOpen={isMenuOpen} toggle={() => toggleMenu()}>
                                    <DropdownToggle color="link" outline className="action-card-menu-dropdown-toggle">
                                        <FontAwesomeIcon icon="ellipsis-v" />
                                        <span className="sr-only">
                                            <> </>{t('actionCard.menu', 'Menu')}
                                        </span>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => openModal()}>
                                            <FontAwesomeIcon icon="edit" />
                                            <> </>
                                            {t('actionCard.edit', 'Edit action')}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => togglePinned()}>
                                            {
                                                model.isPinned ? (
                                                    <>
                                                        <FontAwesomeIcon icon="thumbtack" />
                                                        <> </>
                                                        {t('actionCard.unpin', 'Unpin')}
                                                    </>
                                                ) : (
                                                        <>
                                                            <FontAwesomeIcon icon="thumbtack" style={{ transform: 'rotate(45deg)' }} />
                                                            <> </>
                                                            {t('actionCard.pin', 'Pin')}
                                                        </>
                                                    )
                                            }
                                        </DropdownItem>
                                        <DropdownItem
                                            disabled={!requirement}
                                            onClick={() => { history.push(getComplianceRequirementUrl(requirement?.originKey ?? '', requirementLink?.requirementAreaId ?? '', user, school?.id)); }}
                                        >
                                            <FontAwesomeIcon icon="arrow-right" />
                                            <> </>
                                            {t('actionCard.goToRequirement', 'Go to requirement')}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => removeAction()} className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> </>
                                            {t('actionCard.delete', 'Delete')}
                                        </DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};
