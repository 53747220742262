import gql from "graphql-tag";
import { requirementAreaFields } from "../generated/requirementAreaFields";

export const requirementAreaFieldsFragment = gql`
    fragment requirementAreaFields on RequirementArea {
        id
        name
        descriptionHtml
        archived
        versionDate
        originKey
        videoId
    }
`;


export type RequirementArea = Omit<requirementAreaFields, '__typename'>;
