import './header.scss';

import * as React from 'react';
import { Input, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetHeaderData } from '../../api/TheSchoolBus/getHeaderData/useGetHeaderData';
import { LinkContainer } from 'react-router-bootstrap';
import { openSchoolBusLink } from '../../utilities/openSchoolBusLink';

export const Header = () => {
    //const schoolId = useCurrentSchoolId();
    const { t } = useTranslation();

    // Get the dyanmic header data so we keep up to date with changes to TheSchoolBus.
    const { data: headerData } = useGetHeaderData();

    // Manage which dropdown menu is open.  This works on exclusivity, and so at any one time
    // at most one drop down can be open.
    const [openMenuId, setOpenMenuId] = React.useState<string | number | null>(null);
    // Once we reach this toolbar index, our drop down menus should be aligned with the right not the left.
    const rightAlignAfterToolbarIndex = 1;

    // Know which category we have the cursor over at any time, so we can do fancy hover colorus without requiring
    // css styles.
    const [activeCategoryId, setActiveCategoryId] = React.useState<string | number | null>(null);

    // UI
    //
    return (
        <AppHeader fixed>
            <div className="header-top-section">
                <AppSidebarToggler className="d-md-none" display="sm" mobile />
                <div className="header-mobile-search-button"
                    onClick={() => openSchoolBusLink('/')}
                >
                </div>

                <div className="header-logo-wrapper">
                    {/*<Link to="/">*/}
                    {/*    <AppNavbarBrand />*/}
                    {/*</Link>*/}
                    <div className="header-tsb-logo" onClick={() => openSchoolBusLink('/')}></div>
                    <div className="header-tnc-logo" onClick={() => openSchoolBusLink('https://nationalcollege.com/')}></div>
                </div>
                <div className="header-top-section-spacing"></div>
                <div className="header-top-right">
                    <div className="header-search-account">
                        <div className="header-search-account-spacing-before"></div>

                        <div className="header-search-account-logo-wrapper">
                            {/*<Link to="/">*/}
                            {/*    <AppNavbarBrand />*/}
                            {/*</Link>*/}
                            <div className="header-tsb-logo" onClick={() => openSchoolBusLink('/')}></div>
                            <div className="header-tnc-logo" onClick={() => openSchoolBusLink('https://nationalcollege.com/')}></div>
                        </div>

                        <div className="header-search-account-spacing-after"></div>

                        <div
                            className="header-search-bar-container"
                            onClick={() => openSchoolBusLink('/') /* We don't want to implement the full search functionality here and end up requiring double maintence, so if it is clicked we send them to TheSchoolBus to perform the search. */}
                        >
                            <span className="header-search-bar-icon">
                                <FontAwesomeIcon icon="search" />
                            </span>
                            <Input className="header-search-bar-input" placeholder={t('header.search.placeholder', 'SEARCH')}></Input>
                        </div>
                        <div className="header-my-account-wrapper">
                            <div className={`header-toolbar ${openMenuId === '_account' ? 'header-toolbar-active' : ''}`} onMouseOver={() => setOpenMenuId('_account')} onMouseLeave={() => setOpenMenuId(null)}>
                                <ButtonDropdown isOpen={openMenuId === '_account'} toggle={() => setOpenMenuId('_account')}>
                                    <DropdownToggle className="header-toolbar-button" outline>
                                        <FontAwesomeIcon icon="user" />
                                    </DropdownToggle>
                                    <DropdownMenu className="header-toolbar-menu" right>
                                        {
                                            headerData?.MyAccount?.map((toolbarItem, toolbarItemIndex) => (
                                                    <DropdownItem key={toolbarItemIndex}
                                                        className="header-toolbar-item"
                                                        onClick={() => openSchoolBusLink(toolbarItem.Url)}
                                                    >
                                                        <div className="header-toolbar-item-title">
                                                            {toolbarItem.Name}
                                                        </div>
                                                    </DropdownItem>
                                                ))
                                        }

                                        <LinkContainer to={{ pathname: '/authentication/logout', state: { local: true } } /* Pass extra state so we know the request has been requested properly by the page. */}>
                                            <DropdownItem
                                                className="header-toolbar-item"
                                            >
                                                <div className="header-toolbar-item-title">
                                                    {t('header.logOut', 'Log Out')}
                                                </div>
                                            </DropdownItem>
                                        </LinkContainer>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </div>
                    </div>
                    <div className="header-toolbar-container">
                        {
                            headerData?.HeaderToolbars?.map((toolbar, toolbarIndex) => {
                                const toolbarMenuId = `${toolbar.HeaderToolbarId}.${toolbar.DisplayOrder}`; // We need to use both HeaderToolbarId and DisplayOrder, because at least in the data we got during development, we have two items with HeaderToolbarId 0.
                                const isActive = openMenuId === toolbarMenuId;

                                // UI for toolbars without a menu.
                                if (!toolbar.HeaderToolbarItems?.length) {
                                    return (
                                        <div key={toolbarMenuId}
                                            className={`header-toolbar ${toolbar.FullWidthVisible ? '': 'header-toolbar-hidden-fullwidth'} ${isActive ? 'header-toolbar-active' : ''}`}
                                            onMouseOver={() => setOpenMenuId(toolbarMenuId)}
                                            onMouseLeave={() => setOpenMenuId(null)}
                                            onClick={() => openSchoolBusLink(toolbar.LinkUrl)}
                                        >
                                            <Button className="header-toolbar-button" outline>
                                                <span className={`header-toolbar-icon header-toolbar-icon-${toolbar.IconRef}`}></span>
                                                {toolbar.DisplayText}
                                            </Button>
                                        </div>
                                    );
                                }

                                // UI for toolbars with a menu.
                                return (
                                    <div key={toolbarMenuId} className={`header-toolbar ${toolbar.FullWidthVisible ? '' : 'header-toolbar-hidden-fullwidth'}  ${isActive ? 'header-toolbar-active' : ''}`} onMouseOver={() => setOpenMenuId(toolbarMenuId)} onMouseLeave={() => setOpenMenuId(null)}>
                                        <ButtonDropdown isOpen={isActive} toggle={() => setOpenMenuId(toolbarMenuId)}>
                                            <DropdownToggle className="header-toolbar-button" outline>
                                                <span className={`header-toolbar-icon header-toolbar-icon-${toolbar.IconRef}`}></span>
                                                {toolbar.DisplayText}
                                            </DropdownToggle>
                                            <DropdownMenu className="header-toolbar-menu" right={toolbarIndex > rightAlignAfterToolbarIndex}>
                                                {
                                                    toolbar.HeaderToolbarItems?.map(toolbarItem => (
                                                        <DropdownItem key={`${toolbarItem.HeaderToolbarItemId}.${toolbarItem.DisplayOrder}`}
                                                            className="header-toolbar-item"
                                                            onClick={() => openSchoolBusLink(toolbarItem.LinkUrl)}
                                                        >
                                                            <div className="header-toolbar-item-title">
                                                                {toolbarItem.Title}
                                                            </div>
                                                            <div className="header-toolbar-item-description">
                                                                {toolbarItem.ItemDescription}
                                                            </div>
                                                        </DropdownItem>
                                                    ))
                                                }
                                            </DropdownMenu>
                                        </ButtonDropdown>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="header-category-links">
                {
                    headerData?.HeaderCategories?.map(category => {
                        const categoryId = `${category.HeaderCategoryId}.${category.DisplayOrder}`; // We don't really need to do this based on data available for testing, but we've done it so it matches the way we handle the toolbar menu ids and therefore has only the same limitations.
                        const isActive = activeCategoryId === categoryId;

                        return (
                            <div key={categoryId}
                                className="header-category-link"
                                onMouseEnter={() => setActiveCategoryId(categoryId)}
                                onMouseLeave={() => setActiveCategoryId(null)}
                                style={{
                                    // Special handling of the hover colours as we can't use classes for these as they are data driven
                                    // and likely to change much more often than the toolbar top level navigation (where we use classes to match the icons).
                                    color: isActive ? category.ColourHex : undefined,
                                    borderBottomColor: isActive ? category.ColourHex : undefined,
                                }}
                                onClick={() => openSchoolBusLink(`/category${category.Url ?? ''}`)}
                            >
                                {category.Name}
                            </div>
                        );
                    })
                }
            </div>
        </AppHeader>
    );
};
