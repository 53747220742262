import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { schoolOverviewViewModelQuery, schoolOverviewViewModelQueryVariables } from '../generated/schoolOverviewViewModelQuery';
import { schoolSchoolPhaseFieldsFragment } from '../models/SchoolSchoolPhase';
import { schoolSchoolTypeFieldsFragment } from '../models/SchoolSchoolType';
import { schoolRequirementComplianceFieldsFragment } from '../models/SchoolRequirementCompliance';
import { schoolFieldsFragment } from '../models/School';
import { actionFieldsFragment } from '../models/Action';


/**
 * Get a the data needed by the SchoolOverview component.
 * @param options
 */
export function useSchoolOverviewViewModel(schoolId: string | undefined | null,  options: AsyncLoadOptions = {}): AsyncLoadResult<schoolOverviewViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<schoolOverviewViewModelQuery, schoolOverviewViewModelQueryVariables>(
        gql`
        query schoolOverviewViewModelQuery($schoolId: ID!) {
            school (id: $schoolId) {
                ...schoolFields
            }

            schoolRequirementCompliances (schoolId: $schoolId) {
                ...schoolRequirementComplianceFields
            }

            actions (schoolId: $schoolId) {
                ...actionFields
            }

            schoolSchoolTypes  (schoolId: $schoolId) {
                ...schoolSchoolTypeFields
            }

            schoolSchoolPhases (schoolId: $schoolId) {
                ...schoolSchoolPhaseFields
            }
        }

        ${schoolFieldsFragment}
        ${schoolRequirementComplianceFieldsFragment}
        ${actionFieldsFragment}
        ${schoolSchoolPhaseFieldsFragment}
        ${schoolSchoolTypeFieldsFragment}
        `,
        {
            variables: {
                schoolId: schoolId || '0',
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy && !!schoolId) {
            load();
        }
    }, [options.lazy, load, schoolId]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
