import './actions.scss';

import * as React from 'react';
import { useParams } from 'react-router';
import { useCurrentRequirementsRelease } from '../../api/main/requirementsReleases/useCurrentRequirementsRelease';
import { Background } from '../shared/Background';
import { Banner } from '../shared/Banner';
import { Row, Col, Container, Spinner, Button, Badge, Collapse, ButtonGroup } from 'reactstrap';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useSchool } from '../../api/main/schools/useSchool';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { StickyToolbar } from '../shared/StickyToolbar';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../api/main/actions/useActions';
import { useChangesArray } from '../../shared/useChanges';
import { Action } from '../../api/main/models/Action';
import { ActionUserTask } from '../../api/main/models/ActionUserTask';
import { actionStates } from '../../services/actionStates/actionStates';
import { Guid } from 'guid-string';
import { useActionSupportingData } from '../../api/main/actions/useActionSupportingData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NoResultsFound } from '../shared/NoResultsFound';
import { useAsyncCallback } from 'react-use-async-callback';
import { useSaveActionCallback } from '../../api/main/actions/useSaveActionCallback';
import { useDeleteActionCallback } from '../../api/main/actions/useDeleteActionCallback';
import { useSaveActionUserTaskCallback } from '../../api/main/actionUserTasks/useSaveActionUserTaskCallback';
import { useDeleteActionUserTaskCallback } from '../../api/main/actionUserTasks/useDeleteActionUserTaskCallback';
import { useSearchParams, useReplaceSearchParamsEffect } from '../../shared/useURLSearchParams';
import { ScrollTo } from '@scottbamford/react-scrollto';
import { SearchInput } from '../shared/SearchInput';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { ActionCard } from './ActionCard';
import { useToggleStateArray } from 'use-toggle-state';
import { EditActionModal } from './EditActionModal';
import { Requirement } from '../../api/main/models/Requirement';
import { CreateNextReviewActionModal } from './CreateNextReviewActionModal';
import { UpdateRequirementModal } from './UpdateRequirementModal';
import { useDebounce } from '../shared/hooks/useDebounce';
import { ActionsPdf } from '../../pdf/actions/ActionsPdf';
import { generatePdfAsBlob } from '../../utilities/generatePdfAsBlob';
import { ButtonAsync } from 'reactstrap-buttonasync';
import FileSaver from 'file-saver';
import { School } from '../../api/main/models/School';


export interface ActionsProps {
}

/**
 * Sort options for the screen.
 */
export type ActionsSortOptions =
    'due-date-asscending' | 'due-date-descending'
    | 'assigned-to-asscending' | 'assigned-to-descending'
    | 'completed-date-asscending' | 'completed-date-descending';

/**
 * Actions for a school.
 * @param props
 */
export const Actions = (props: ActionsProps) => {
    const {
        schoolId: paramsSchoolId,
    } = useParams();

    // Get the item of the action to scroll to (if any)
    const { action: scrollToActionIdParam } = useSearchParams();

    const [scrollToActionId, setScrollToActionId] = React.useState<string>(scrollToActionIdParam ?? '');

    const { t } = useTranslation();

    const user = useCurrentUser();
    const schoolId = paramsSchoolId ?? user?.schoolId?.toString();
    const { data: { model: school }, isLoading: isSchoolLoading, errors: schoolLoadingErrors } = useSchool(schoolId, { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });
    const { data: { model: release }, isLoading: isReleaseLoading, errors: releaseLoadingErrors } = useCurrentRequirementsRelease({ fetchPolicy: "cache-first" });
    const { data: { items: storeActions }, isLoading: isActionsLoading, errors: actionsLoadingErrors } = useActions({ schoolId: schoolId ?? '0' });
    const actionsManager = useChangesArray<Action, string>(storeActions, item => item.id);
    const actionsUserTasksManager = useChangesArray<ActionUserTask, string>(storeActions?.map(item => item.actionUserTasks)?.flat(), item => item.id);
    const [saveAction, { errors: saveActionErrors }] = useSaveActionCallback();
    const [removeAction, { errors: removeActionErrors }] = useDeleteActionCallback();
    const [saveActionUserTask, { errors: saveActionUserTaskErrors }] = useSaveActionUserTaskCallback();
    const [removeActionUserTask, { errors: removeActionUserTaskErrors }] = useDeleteActionUserTaskCallback();

    const { data: { users: schoolUsers }, isLoading: isSupportinDataLoading, errors: supportingDataLoadingErrors } = useActionSupportingData(schoolId);


    // Make sure our isLoading takes all our data loads into account.
    const isLoading = isSchoolLoading || isReleaseLoading || isActionsLoading || isSupportinDataLoading;

    // Get a list of actions we should be showing.
    const actions = actionsManager.model;

    // Allow filtering/searching of actions by text.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');

    // User preferred sort option.
    const [sortOption, _setSortOption] = React.useState<ActionsSortOptions>('due-date-asscending');
    const toggleSortOption = React.useCallback((sortOption: ActionsSortOptions) => {
        _setSortOption(prevState => {
            // If we are toggling to the same option, then toggle the asscending/descending option within it instead of using the passed in option.
            if (prevState === sortOption) {
                let ret = sortOption.replace('asscending', 'descending');
                return ret as ActionsSortOptions;
            }

            // Otherwise use the specific passed in sort option.
            return sortOption;
        });
    }, [_setSortOption]);

    // Function to sort actions by the sortOption.  Kept as a seperate method
    // so we can use it on screen and when generating the pdf.
    const actionsSortCallback = React.useCallback((a: Action, b: Action) => {
        // Pinned items go before unpinned items.
        if (a.isPinned && !b.isPinned) {
            return -1;
        } else if (!a.isPinned && b.isPinned) {
            return 1;
        }

        const getAssignedToName = (action: Action) => {
            const myTasks = actionsUserTasksManager.model.filter(it => it.actionId === action.id);
            if (!myTasks.length) {
                return '';
            }

            const task = myTasks[0];
            const user = schoolUsers.find(it => it.id === task.userId.toString());
            if (!user) {
                return '';
            }

            return `${user.firstname} ${user.surname}`;
        };

        // Sort by the user selected sort.
        switch (sortOption) {
            case 'due-date-asscending':
                if (a.actionAddedDate < b.actionAddedDate) {
                    return -1;
                } else if (a.actionAddedDate > b.actionAddedDate) {
                    return 1;
                }
                break;
            case 'due-date-descending':
                if (a.actionAddedDate < b.actionAddedDate) {
                    return 1;
                } else if (a.actionAddedDate > b.actionAddedDate) {
                    return -1;
                }
                break;
            case 'completed-date-asscending':
                if (a.completedDate < b.completedDate) {
                    return -1;
                } else if (a.completedDate > b.completedDate) {
                    return 1;
                }
                break;
            case 'completed-date-descending':
                if (a.completedDate < b.completedDate) {
                    return 1;
                } else if (a.completedDate > b.completedDate) {
                    return -1;
                }
                break;
            case 'assigned-to-asscending':
                const aName = getAssignedToName(a);
                const bName = getAssignedToName(b);
                if (aName < bName) {
                    return -1;
                } else if (aName > bName) {
                    return 1;
                }
                break;
            case 'assigned-to-descending':
                const aName2 = getAssignedToName(a);
                const bName2 = getAssignedToName(b);
                if (aName2 < bName2) {
                    return 1;
                } else if (aName2 > bName2) {
                    return -1;
                }
                break;
        }

        // Then (if the user selected sort is equal) we sort by due date.
        if (a.actionAddedDate < b.actionAddedDate) {
            return -1;
        } else if (a.actionAddedDate > b.actionAddedDate) {
            return 1;
        }

        // Finally (if everything above is equal) sort by name.
        if (a.name < b.name) {
            return -1;
        } else if (a.name > b.name) {
            return 1;
        }

        // And if we get here, the items are identical for sorting purposes.
        return 0;
    }, [sortOption, actionsUserTasksManager, schoolUsers]);

    // Filter by the user's search client side so it can work when offline as well as online.
    const filteredActions = React.useMemo(() => {
        if (!actions) {
            return actions;
        }

        let ret = actions;

        // Filter out anything that has been archived since the screen loaded.
        ret = actions.filter(item => !item.archived);

        // Filter by the users' search term.
        if (search) {
            let lowerSearch = search.toLocaleLowerCase();

            const searchMatchesUserForAction = (action: Action) => {
                const tasks = actionsUserTasksManager.model.filter(task => task.actionId === action.id);
                for (const task of tasks) {
                    const user = schoolUsers.find(it => it.id === task.userId.toString());
                    if (!user) {
                        return false;
                    }

                    const lowerFullName = `${user.firstname} ${user.surname}`.toLocaleLowerCase();
                    return (lowerFullName.indexOf(lowerSearch) >= 0);
                }
            };

            // Filter the items being displayed.
            ret = actions.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.descriptionHtml.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || searchMatchesUserForAction(item)
            );
        }

        // Order the remaining items (we don't want to bother we another useMemo).
        ret.sort(actionsSortCallback);


        return ret;
    }, [actions, search, actionsUserTasksManager.model, schoolUsers, actionsSortCallback]);

    useReplaceSearchParamsEffect({ search: search });

    // Get a list of requirements so we can look through them (note we don't care about removing duplicates here as we only
    // use this list for lookups)
    const requirementLinks = React.useMemo(() => release?.requirements ?? [], [release]);
    const requirements = React.useMemo(() => release?.requirements?.map(item => item.requirement) ?? [], [release]);

    // Let an action be opened as a modal.
    const [openActionId, setOpenActionId] = React.useState<string>('');

    // Add an action.
    const addAction = React.useCallback(() => {
        const newId = Guid.newGuid();
        actionsManager.addFor({
            id: newId,
            schoolId: schoolId,
            name: '',
            descriptionHtml: '',
            archived: false,
            requirementOriginKey: null,
            isAutomaticFromRequirementCompliance: false,
            actionAddedDate: moment().add(1, 'month').toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notesHtml: '',
            isPinned: false,
        });
        setOpenActionId(newId);
    }, [actionsManager, schoolId, setOpenActionId]);

    // Add a task to an action.
    const addActionUserTask = React.useCallback((actionId: string) => {
        actionsUserTasksManager.addFor({
            id: Guid.newGuid(),
            actionId: actionId,
            userId: 0,
            tasksHtml: '',
            actionAddedDate: moment().toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notesHtml: '',
            archived: false,
        });
    }, [actionsUserTasksManager]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean } = {}) => {
        // Save all the actions.
        for (const item of actionsManager.added) {
            await saveAction(item.id, actionsManager.changesFor(item.id), true);
        }
        for (const item of actionsManager.updated) {
            await saveAction(item.id, actionsManager.changesFor(item.id), false);
        }
        for (const item of actionsManager.removed) {
            await removeAction(item.id);
        }
        actionsManager.markAsSaved();

        // Save all the tasks
        for (const item of actionsUserTasksManager.added) {
            await saveActionUserTask(item.id, actionsUserTasksManager.changesFor(item.id), true);
        }
        for (const item of actionsUserTasksManager.updated) {
            await saveActionUserTask(item.id, actionsUserTasksManager.changesFor(item.id), false);
        }
        for (const item of actionsUserTasksManager.removed) {
            await removeActionUserTask(item.id);
        }
        actionsUserTasksManager.markAsSaved();
    }, [
            actionsManager, saveAction, removeAction,
            actionsUserTasksManager, saveActionUserTask, removeActionUserTask,
    ]);

    // Save everything with some debounce support so we don't try and save after change if the user is making changes quickly.
    const saveFormDebounce = useDebounce(() => saveForm());

    // When an action for a is completed make sure we drive the user natrually into the next step of the workflow if one is suitable..
    const [requirementToScheduleReviewFor, setRequirementToScheduleReviewFor] = React.useState<Requirement | undefined | null>(null);
    const [requirementToLinkTo, setRequirementToLinkTo] = React.useState<Requirement | undefined | null>(null);
    const askIfWantToContinueWorkflowOnCompletion = React.useCallback((action: Action, requirement: Requirement | undefined | null) => {
        // If we haven't got a requirement then do nothing.
        if (!requirement) {
            return;
        }

        // For a review action we want to drive them down setting a next review.
        if (action.isAutomaticFromRequirementCompliance && action.name.indexOf('needs to be reviewed') >= 0) {
            // If the associated requirement doesn't have a review timescale then do nothing.
            if (!requirement.requiresReview) {
                return;
            }

            // If there is already a new review action planned Lookup an for this requirement do nothing.
            const existingNewAction = actionsManager.model.find(item => item.requirementOriginKey === requirement.originKey && item.isAutomaticFromRequirementCompliance && !item.archived && !item.completedDate && item.name.indexOf('needs to be reviewed') < 0);
            if (existingNewAction) {
                return;
            }

            // If we get here we want to allow the user to set a new review timescale to create an action.
            setRequirementToScheduleReviewFor(requirement);
        } else if (action.isAutomaticFromRequirementCompliance && action.name.indexOf('has not been met') >= 0) {
            // For a "has not been met" action we want to drive them down updating their compliance for that requirement.
            setRequirementToLinkTo(requirement);
        } else {
            // For everything else there is no natural workflow to direct the user down so do nothing.
        }
    }, [setRequirementToScheduleReviewFor, actionsManager]);

    // Create a new review action for a requirement.
    const createNextReviewAction = React.useCallback((requirement: Requirement, date: string) => {
        let name = t('actions.nextReview.name', 'Requirement "{{requirementName}}" needs to be reviewed.', { requirementName: requirement.name });


        // Work out the due date.
        let dueDate: Moment = moment(date);
        actionsManager.addFor({
            id: Guid.newGuid(),
            schoolId: schoolId,
            name: name,
            descriptionHtml: '',
            archived: false,
            requirementOriginKey: requirement.originKey,
            isAutomaticFromRequirementCompliance: true,
            actionAddedDate: dueDate.toISOString(),
            actionState: actionStates.outstanding.id,
            completedDate: null,
            notesHtml: '',
            isPinned: false,
        });

        // Save.
        saveFormDebounce();
    }, [t, actionsManager, schoolId, saveFormDebounce]);

    // Work out where to show everything.
    const groups = React.useMemo(() => {
        const ret = filteredActions?.map(item => {
            const dateMoment: Moment = moment(item.actionAddedDate);
            const differenceDays = dateMoment.diff(moment(), 'days');
                        
            let group: string;
            if (actionStates.findById(item.actionState).isCompleted) {
                group = 'completed';
            } else if (differenceDays <= 90) {
                group = 'due';
            } else {
                group = 'scheduled';
            }

            return { group, item };
        });

        return ret ?? [];
    }, [filteredActions]);

    // Create the action card component.
    const createActionCard = (item: Action, index: number) => {
        const myTasks = actionsUserTasksManager.model.filter(it => it.actionId === item.id);
        const myRequirement = requirements?.find(req => req.originKey === item.requirementOriginKey);

        return (
            <ScrollTo key={item.id} shouldScrollTo={item.id === scrollToActionId}>
                <ActionCard
                    isFirstInGroup={index === 0 ? true : false}
                    model={item} change={changes => actionsManager.changeFor(item.id, changes)}
                    validate={() => true} validationErrors={{}}
                    remove={() => actionsManager.removeFor(item.id)}
                    tasks={myTasks} changeTask={(id, changes) => actionsUserTasksManager.changeFor(id, changes)}
                    addTask={() => addActionUserTask(item.id)}
                    removeTask={id => actionsUserTasksManager.removeFor(id)}
                    user={user} school={school}
                    requirement={myRequirement}
                    requirementLink={requirementLinks?.find(link => link.requirementId === myRequirement?.id)}
                    users={schoolUsers}
                    saveDebounce={saveFormDebounce}
                    openModal={() => setOpenActionId(item.id)}
                    askIfWantToContinueWorkflowOnCompletion={() => askIfWantToContinueWorkflowOnCompletion(item, myRequirement)}
                    sortOption={sortOption}
                    toggleSortOption={toggleSortOption}
                />
            </ScrollTo>
        );
    };

    // Create a seciton showing a group of action cards with the middle column fully expanded by default, and the top of the other two sections expanded too.
    const [isSectionOpen, _toggleSectionOpen] = useToggleStateArray([
        'due', 'scheduled', 'completed',
    ]);
    const toggleSectionOpen = React.useCallback((key: string, value?: React.SetStateAction<boolean>) => {
        // When we toggle the section we also clear any scrolled to item as that can prevent collapsing otherwise.
        _toggleSectionOpen(key, value);
        setScrollToActionId('');
    }, [_toggleSectionOpen]);

    const createSection = (group: string) => {
        const myActions = groups.filter(item => item.group === group).map(item => item.item);

        // If there is nothing to show in this section hide the section.
        if (!myActions.length) {
            return null;
        }

        return (
            <>
                {
                    myActions.map((item, index) => createActionCard(item, index))
                }
            </>
        );
    };

    // Generate the certificate report in PDF format.
    const [generatePdf, { isExecuting: isGeneratingPdf, errors: generatePdfErrors }] = useAsyncCallback(async () => {
        // Filter the actions to only include incomplete actions, and then order them by due date.
        let actionsForReport = actionsManager.model.filter(item => !item.completedDate);
        actionsForReport.sort(actionsSortCallback);

        // Generation of the PDF report.
        const blobData = await generatePdfAsBlob(ActionsPdf({
            school: school ?? ({} as School),
            actions: actionsForReport,
            actionUserTasks: actionsUserTasksManager.model,
            schoolUsers: schoolUsers,
            t,
        }));

        return blobData;
    }, [school, actionsManager, actionsUserTasksManager, schoolUsers, t, actionsSortCallback]);

    //// Open the PDF in a new window ready for printing.
    //const [printPdf] = useAsyncCallback(async () => {
    //    const blobData = await generateCertifiatePdf();

    //    // Open the certificate in a new tab.
    //    const url = URL.createObjectURL(blobData);
    //    window.open(url);
    //}, [generateCertifiatePdf]);

    // Download the PDF
    const [downloadPdf] = useAsyncCallback(async () => {
        const blobData = await generatePdf();

        FileSaver.saveAs(blobData, `${school?.schoolName} - Action plan.pdf`);
    }, [generatePdf, school]);


    return (
        <div className="actions">
            <Background>
                <Banner fluid>
                    <StickyToolbar>
                        <Row>
                            <Col>
                                <h2 className="text-muted" style={{ display: 'inline-block' }}>
                                    {t('actions.heading', 'Action plan')}
                                </h2>
                            </Col>
                            <ConditionalFragment showIf={isSaving}>
                                <Col xs="auto">
                                    <Spinner size="sm" />
                                </Col>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={isLoading}>
                                <Col xs="auto">
                                    <LoadingIndicator size="sm" />
                                </Col>
                            </ConditionalFragment>
                            <Col>
                                <div className="d-none d-md-block">
                                    <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                                </div>
                            </Col>
                            <Col xs="auto">
                                <ButtonGroup>
                                    <Button color="primary" onClick={addAction}>
                                        <FontAwesomeIcon icon="plus" />
                                        <> </>{t('actions.addAction', 'Add action')}
                                    </Button>
                                    <ButtonAsync color="primary" outline onClick={downloadPdf}
                                        isExecuting={isGeneratingPdf}
                                        executingChildren={<><Spinner size="sm" /><> </>{t('actions.downloadPdf.generating', 'Preparing PDF...')}</>}
                                    >
                                        <FontAwesomeIcon icon="download" />
                                        <> </>{t('actions.downloadPdf.text', 'Download PDF')}
                                    </ButtonAsync>
                                </ButtonGroup>
                            </Col>
                            <Col xs={12} md="auto">
                                <div className="d-block d-md-none">
                                    <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                                </div>
                            </Col>
                        </Row>
                    </StickyToolbar>
                </Banner>
                <Container fluid className="mt-2">
                    <AlertOnErrors errors={[
                        actionsLoadingErrors, schoolLoadingErrors, releaseLoadingErrors, supportingDataLoadingErrors,
                        saveFormErrors, saveActionErrors, removeActionErrors, saveActionUserTaskErrors, removeActionUserTaskErrors,
                        generatePdfErrors, 
                    ]} />

                    <Row>
                        {/* Outstanding */}
                        <Col xs={12} className="actions-column">
                            <h5 className="actions-column-heading" onClick={() => toggleSectionOpen('due')}>
                                {t('actions.columnHeading.outstanding', 'Outstanding')}
                                <> </>
                                <Badge color="dark" pill>
                                    {t('common.integer', '{{value, #,0}}', { value: groups.filter(item => item.group.indexOf('due') === 0)?.length ?? 0 })}
                                </Badge>
                                <> </>
                                <FontAwesomeIcon icon={isSectionOpen('due') ? 'caret-up' : 'caret-down'} />
                            </h5>

                            <Collapse isOpen={isSectionOpen('due')}>
                                {createSection('due')}
                            </Collapse>

                            {/* In the top section only show a message if we have no items or when fitlered, no matching items */}
                            <ConditionalFragment showIf={!isLoading && !filteredActions.length}>
                                {
                                    !!search ? (
                                        <NoResultsFound search={search} />
                                    ) : (
                                            <NoResultsFound search={search}>
                                                <div className="no-results-found-icon">
                                                    <FontAwesomeIcon icon={['far', 'smile']} />
                                                </div>
                                                <p>{t('acions.nothingFound.outstanding', 'You don\'t have any outstanding actions due in the next 90 days.')}</p>
                                            </NoResultsFound>
                                            )
                                }
                            </ConditionalFragment>
                        </Col>

                        {/* Scheduled for the future */}
                        <Col xs={12} className="actions-column">
                            <h5 className="actions-column-heading" onClick={() => toggleSectionOpen('scheduled')}>
                                {t('actions.columnHeading.scheduled', 'Scheduled for the future')}
                                <> </>
                                <Badge color="dark" pill>
                                    {t('common.integer', '{{value, #,0}}', { value: groups.filter(item => item.group.indexOf('scheduled') === 0)?.length ?? 0 })}
                                </Badge>
                                <> </>
                                <FontAwesomeIcon icon={isSectionOpen('scheduled') ? 'caret-up' : 'caret-down'} />
                            </h5>

                            <Collapse isOpen={isSectionOpen('scheduled')}>
                                {createSection('scheduled')}
                            </Collapse>
                        </Col>

                        {/* Complete */}
                        <Col xs={12} className="actions-column">
                            <h5 className="actions-column-heading" onClick={() => toggleSectionOpen('completed')}>
                                {t('actions.columnHeading.completed', 'Complete')}
                                <> </>
                                <Badge color="dark" pill>
                                    {t('common.integer', '{{value, #,0}}', { value: groups.filter(item => item.group.indexOf('completed') === 0)?.length ?? 0 })}
                                </Badge>
                                <> </>
                                <FontAwesomeIcon icon={isSectionOpen('completed') ? 'caret-up' : 'caret-down'} />
                            </h5>

                            <Collapse isOpen={isSectionOpen('completed')}>
                                {createSection('completed')}
                            </Collapse>
                        </Col>
                    </Row>
                </Container>
            </Background>

            <ConditionalFragment showIf={!!openActionId}>
                <EditActionModal isOpen={true} toggle={() => { saveFormDebounce(); setOpenActionId(''); setScrollToActionId(openActionId); }}
                    model={actions.find(it => it.id === openActionId) ?? ({} as Action)}
                    change={changes => actionsManager.changeFor(openActionId, changes)}
                    validate={() => true} validationErrors={{}}
                    remove={() => actionsManager.removeFor(openActionId)}
                    tasks={actionsUserTasksManager.model.filter(it => it.actionId === openActionId)}
                    changeTask={(id, changes) => actionsUserTasksManager.changeFor(id, changes)}
                    addTask={() => addActionUserTask(openActionId)}
                    removeTask={id => actionsUserTasksManager.removeFor(id)}
                    user={user} school={school}
                    requirement={requirements?.find(req => req.originKey === actions.find(it => it.id === openActionId)?.requirementOriginKey)}
                    requirementLink={requirementLinks?.find(link => link.requirementId === requirements?.find(req => req.originKey === actions.find(it => it.id === openActionId)?.requirementOriginKey)?.id)}
                    users={schoolUsers}
                    saveDebounce={saveFormDebounce}
                    askIfWantToContinueWorkflowOnCompletion={() => askIfWantToContinueWorkflowOnCompletion(actions.find(it => it.id === openActionId) ?? {} as Action, requirements?.find(req => req.originKey === actions.find(it => it.id === openActionId)?.requirementOriginKey))}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={!!requirementToScheduleReviewFor}>
                <CreateNextReviewActionModal isOpen={true} toggle={() => { setRequirementToScheduleReviewFor(null) }}
                    model={requirementToScheduleReviewFor as Requirement}
                    createNextReviewAction={(date: string) => createNextReviewAction(requirementToScheduleReviewFor as Requirement, date)}
                />
            </ConditionalFragment>
            <ConditionalFragment showIf={!!requirementToLinkTo}>
                <UpdateRequirementModal isOpen={true} toggle={() => { setRequirementToLinkTo(null) }}
                    model={requirementToLinkTo as Requirement}
                    requirementLink={requirementLinks.find(item => item.requirementId === requirementToLinkTo?.id)}
                    user={user}
                    school={school}
                />
            </ConditionalFragment>
        </div>
    );
};
