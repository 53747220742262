import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { useCurrentRequirementsRelease } from '../../api/main/requirementsReleases/useCurrentRequirementsRelease';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useSchool } from '../../api/main/schools/useSchool';
import { useParams, useHistory } from 'react-router';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useSchoolRequirementCompliances } from '../../api/main/schoolRequirementCompliances/useSchoolRequirementCompliances';
import { requirementComplianceStates } from '../../services/requirementComplianceStates/requirementComplianceStates';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReportHistoryCard } from './ReportHistoryCard';
import { GenerateCard } from './GenerateCard';
import { useReportsOverviewSupportingData } from '../../api/main/reportsOverview/useReportsOverviewSupportingData';
import { generatePdfAsBlob } from '../../utilities/generatePdfAsBlob';
import { SchoolReportPdf } from '../../pdf/schoolReport/SchoolReportPdf';
import { useAsyncCallback } from 'react-use-async-callback';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { useUploadBlobFromBlobObjectCallback } from '../../api/main/blobs/useUploadBlobFromBlobObjectCallback';
import moment, { Moment } from 'moment';
import { useSaveSchoolReportCallback } from '../../api/main/schoolReports/useSaveSchoolReportCallback';
import { SchoolReport } from '../../api/main/models/SchoolReport';
import { Guid } from 'guid-string';
import { nicelyFormattedEnglishList } from '../../utilities/nicelyFormattedEnglishList';
import { useSchoolReports } from '../../api/main/schoolReports/useSchoolReports';
import { useRequirementsForSchool } from '../schoolOverview/useRequirementsForSchool/useRequirementsForSchool';
import { useCalculateCurrentAward } from '../schoolCompliance/useCalculateCurrentAward/useCalculateCurrentAward';

/**
 * Reports overview page.
 */
export const ReportsOverview = () => {
    const {
        schoolId: paramsSchoolId,
    } = useParams();

    const user = useCurrentUser();
    const schoolId = paramsSchoolId ?? user?.schoolId?.toString();
    const { data: { model }, isLoading: _isLoading, errors: loadingErrors } = useSchool(schoolId, { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });
    const {
        data: { /*schoolTypes, schoolPhases,*/ schoolSchoolTypes, schoolSchoolPhases },
        isLoading: isLoadingSupportingData,
        errors: loadingSupportingDataErrors,
    } = useReportsOverviewSupportingData(schoolId ?? '0');

    const { data: { items: compliances }, isLoading: isCompliancesLoading, errors: complianceLoadErrors } = useSchoolRequirementCompliances({ schoolId: schoolId });

    const { t } = useTranslation();
    const history = useHistory();

    // Load the current release of questions.
    const { data: { model: currentRequirementsRelease }, isLoading: isLoadingCurrentRequirementsRelease, errors: currentRequirementsReleaseErrors } = useCurrentRequirementsRelease({ fetchPolicy: "cache-first" });

    // Load the existing reports for the school.
    const { data: { items: schoolReports }, isLoading: isSchoolReportsLoading, errors: schoolReportsLoadErrors, refresh: refreshSchoolReports } = useSchoolReports({ schoolId: parseInt(schoolId ?? '0') });


    // Make sure isLoading takes into account all the queries we use.
    const isLoading = _isLoading || isLoadingSupportingData || isLoadingCurrentRequirementsRelease || isCompliancesLoading || isSchoolReportsLoading;

    // All requirements (ensuring there are no duplicates).
    const requirements = useRequirementsForSchool(currentRequirementsRelease, schoolSchoolTypes, schoolSchoolPhases);

    // Calculate the school's current award level.
    const currentAward = useCalculateCurrentAward(requirements, compliances, currentRequirementsRelease?.requirements);

    // All areas we track compliance for (with areas removed when there are no requirements for us under them).
    const areas = React.useMemo(() => {
        let ret = currentRequirementsRelease?.areas?.map(item => item.area) ?? [];
        // Remove any areas without any requirements for us.
        ret = ret.filter(item => {
            const myLinks = currentRequirementsRelease?.requirements;
            const myRequirements = requirements?.filter(req => !!myLinks?.find(link => link.requirementId === req.id));
            if (!myRequirements?.length) {
                return false;
            }

            return true;
        });
        
        return ret;
    }, [currentRequirementsRelease, requirements]);

    // Detailed or summary report.
    const [isDetailedReport, toggleDetailedReport] = useToggleState();

    // Allow areas to be included or excluded.
    const [isAreaExcluded, toggleAreaExcluded] = useToggleStateArray();

    // Stored reports for the school.
    const [saveSchoolReport, { errors: saveSchoolReportErrors }] = useSaveSchoolReportCallback();

    const [uploadBlobFromBlobObject] = useUploadBlobFromBlobObjectCallback();
    // Generate the report in PDF format.
    const [generateReport, { isExecuting: isGeneratingReport, errors: generateReportErrors }] = useAsyncCallback(async () => {
        const myAreas = areas?.filter(area => {
            // We can excluded anything that been excluded manually.
            if (isAreaExcluded(area.id)) {
                return false;
            }

            // And we can excluded anything that doesn't have at least one success/failure answer.
            const thisAreasLinks = currentRequirementsRelease?.requirements?.filter(item => item.requirementAreaId === area.id);
            const thisAreasRequirements = requirements?.filter(item => !!thisAreasLinks?.find(link => link.requirementId === item.id));
            const thisAreasCompliances = compliances?.filter(
                item => thisAreasRequirements?.find(req => req.originKey === item.requirementOriginKey)
                    && (requirementComplianceStates.findById(item.requirementComplianceState).isSuccess || requirementComplianceStates.findById(item.requirementComplianceState).isFailure)
            );

            if (!thisAreasCompliances?.length) {
                return false;
            }

            // If we get here we want to include the area in the report.
            return true;
        });


        const myRequirementLinks = currentRequirementsRelease?.requirements?.filter(item => !!myAreas?.find(area => item.requirementAreaId === area.id));
        const myRequirements = requirements?.filter(item => !!myRequirementLinks?.find(link => link.requirementId === item.id));
        const myCompliances = compliances?.filter(
            item => myRequirements?.find(req => req.originKey === item.requirementOriginKey)
                && (requirementComplianceStates.findById(item.requirementComplianceState).isSuccess || requirementComplianceStates.findById(item.requirementComplianceState).isFailure)
        );

        // Generation of the PDF report.
        const blobData = await generatePdfAsBlob(SchoolReportPdf({
            isDetailedReport,
            school: model,
            areas: myAreas,
            requirements: myRequirements,
            requirementLinks: myRequirementLinks,
            compliances: myCompliances,
            currentAward: currentAward,
        }));

        // Turn into a data URL and store in our blob storage.
        //const blobObject = URL.createObjectURL(blobData);
        const dateNow: Moment = moment();
        const blob = await uploadBlobFromBlobObject(`${model?.schoolName ?? schoolId} - ${isDetailedReport ? 'Detailed report' : 'Summary report'} - ${dateNow.format('L LT')}.pdf`, blobData);

        if (!blob) {
            return;
        }

        // Save a copy of the generated report.
        const savedReport: SchoolReport = {
            id: Guid.newGuid(),
            schoolId: parseInt(model?.id ?? '0'),
            date: dateNow.toISOString(),
            userId: parseInt(user?.id ?? '0'),
            isDetailed: isDetailedReport,
            areas: nicelyFormattedEnglishList(myAreas.map(item => item.name)),
            blobId: blob.id,
        };
        await saveSchoolReport(savedReport.id, savedReport, true);

        // Refres the list of school reports so it has our updates.
        refreshSchoolReports();

        // Open the stored blob in a new window.
        if (blob.url) {
            window.open(blob.url);
        }
    }, [model, areas, requirements, currentRequirementsRelease, compliances, isDetailedReport, isAreaExcluded,
        uploadBlobFromBlobObject, schoolId, saveSchoolReport, user, refreshSchoolReports, currentAward]);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h2 className="text-muted">
                                <ConditionalFragment showIf={!!paramsSchoolId}>
                                    <Button color="primary" outline onClick={() => history.goBack()} style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                        <FontAwesomeIcon icon="caret-left" />
                                        <span className="sr-only"><> </>{t('common.back', 'Back')}</span>
                                    </Button>
                                    <> </>
                                </ConditionalFragment>
                                {t('reportsOverview.heading', 'Reports')}
                            </h2>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors, loadingSupportingDataErrors, currentRequirementsReleaseErrors, complianceLoadErrors, generateReportErrors, saveSchoolReportErrors, schoolReportsLoadErrors]} />
                <Row>
                    <Col xs={12} lg={8}>
                        <GenerateCard
                            user={user}
                            school={model}
                            areas={areas}
                            requirements={requirements}
                            requirementLinks={currentRequirementsRelease?.requirements}
                            compliances={compliances}
                            generateReport={generateReport} isGeneratingReport={isGeneratingReport}
                            isLoading={isLoading}
                            isDetailedReport={isDetailedReport} toggleDetailedReport={toggleDetailedReport}
                            isAreaExcluded={isAreaExcluded} toggleAreaExcluded={toggleAreaExcluded}
                        />
                    </Col>
                    <Col xs={12} lg="4">
                        <ReportHistoryCard reports={schoolReports} isLoading={isLoading} />
                    </Col>
                </Row>
            </Container>
        </Background>
        );
};
