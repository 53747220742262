import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { schoolOverviewSupportingDataQuery } from '../generated/schoolOverviewSupportingDataQuery';
import { requirementAreaFieldsFragment } from '../models/RequirementArea';
import { requirementSchoolPhaseFieldsFragment } from '../models/RequirementSchoolPhase';
import { requirementSchoolTypeFieldsFragment } from '../models/RequirementSchoolType';
import { useSchoolOverviewSupportingData_PrecachedResults } from "./useSchoolOverviewSupportingData_PrecachedResults";


/**
 * Get a the supporting data needed by the SchoolOverview component.
 * @param options
 */
export function useSchoolOverviewSupportingData(options: AsyncLoadOptions = {}): AsyncLoadResult<schoolOverviewSupportingDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<schoolOverviewSupportingDataQuery>(
        gql`
        query schoolOverviewSupportingDataQuery {
            currentRequirementsRelease {
                id
                versionNumber
                patchNumber
                publishDate
                archived
                releaseNotes
    
                areas {
                    id
                    requirementsReleaseId
                    requirementAreaId
                    displayOrder
                    archived
                    area {
                        ...requirementAreaFields
                    }
                }
                requirements {
			            id
                    requirementsReleaseId
                    requirementId
                    requirementAreaId
                    displayOrder
                    archived
                    requirement {
                        id
                        name
                        descriptionHtml
                        archived
                        versionDate
                        originKey
                        isStronglyRecommended
                        sourcesHtml
                        policyManagerId
                        policyManagerType
                        requiresReview
                        requiresReviewDescriptionHtml
                        requiresReviewInDefaultMonths
                        notesHtml
                        allowEvidenceUpload
                        standardRating
                        videoId

                        appliesToSchoolPhases {
                            ...requirementSchoolPhaseFields
                        }

                        appliesToSchoolTypes {
                            ...requirementSchoolTypeFields
                        }
                    }
                }
            }
        }

        ${requirementAreaFieldsFragment}
        ${requirementSchoolPhaseFieldsFragment}
        ${requirementSchoolTypeFieldsFragment}
        `,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: (data && data?.currentRequirementsRelease) ? data : useSchoolOverviewSupportingData_PrecachedResults.data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
