import i18next from "i18next";

export interface SchoolGroupType {
    id: number,
    localizedName: () => string
}

class SchoolGroupTypes {
    /**
     * MAT
     */
    multiAcademyTrust = { id: 2, localizedName: () => i18next.t('schoolGroupTypes.multiAcadmeyTrust.name', 'Multi Academy Trust') } as SchoolGroupType;

    ///**
    // * Access to all available States as an array.
    // */
    //items = [
    //    this.multiAcademyTrust,
    //];

    ///**
    // * Find a state from its id.
    // * @param id
    // */
    //findById = (id: string | undefined | null): SchoolGroupType  => {
    //    const ret = this.items.find(item => item.id === id);
    //    if (!ret) {
    //        return this.multiAcademyTrust;
    //    }

    //    return ret;
    //}
}

/**
 * School group types we need to reference specially.
 * 
 * Please note that school group types in general are maintained as data in the database.  This is only a collection of ones we want to reference as special.
 */
export const schoolGroupTypes = new SchoolGroupTypes();

