import { useLocalStorage } from '../../shared/useLocalStorage';
import { useCallback, useMemo } from 'react';
import { isUndefined } from 'util';
import { Requirement } from '../../api/main/models/Requirement';
import { StandardRating } from '../../api/main/models/constants/StandardRatings';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { requirementComplianceStates } from '../../services/requirementComplianceStates/requirementComplianceStates';

/**
 * Structure to store the filter settings as a single object.
 */
export interface RequirementsFilterOptions {
    bronze: boolean,
    silver: boolean,
    gold: boolean,
    unanswered: boolean,
    notMet: boolean,
    met: boolean,
}

/**
 * Default filter settings.
 */
const defaultFilter: RequirementsFilterOptions = {
    bronze: true,
    silver: true,
    gold: true,
    unanswered: true,
    notMet: true,
    met: true,
};

/**
 * Hook around the available filter options for filtering requirements.  This is use primarily on the overview screen, but is a hook
 * so we can also use it (with all settings preserved) when showing an individual area as well.
 */
export function useRequirementsFilterOptions() {
    // Get the filter object, stored in local storage.
    const [storedFilters, setStoredFilter] = useLocalStorage<Partial<RequirementsFilterOptions>>('schoolOverview.filter', {});

    // Merge with defaults.
    // NOTE we do it this way so that if we add anything later, we don't have to worry about them being missing in the stored object.
    const filter: RequirementsFilterOptions = {
        ...defaultFilter,
        ...storedFilters,
    };

    // Reset filter back to defaults.
    const resetFilter = useCallback(() => setStoredFilter({}), [setStoredFilter]);

    // Toggle an option in the filter.
    const toggleFilter = useCallback((key: keyof RequirementsFilterOptions) => setStoredFilter(prevState => {
        return {
            ...prevState,
            [key]: isUndefined(prevState[key])? !defaultFilter[key]: !prevState[key],
        };
    }), [setStoredFilter]);

    // Method to check if we should show a requirement or not based on the current filter.
    const shouldShowRequirement = useCallback((requirement: Requirement, compliance: SchoolRequirementCompliance | undefined | null) => {
        // Check based on standard rating.
        //
        if (!filter.bronze && requirement.standardRating === StandardRating.Bronze) {
            return false;
        }

        if (!filter.silver && requirement.standardRating === StandardRating.Silver) {
            return false;
        }

        if (!filter.gold && requirement.standardRating === StandardRating.Gold) {
            return false;
        }

        // Check based on completion.
        //

        // NOTE unanswered will include skipped, so basicly includes everything except failed and success.
        if (!filter.unanswered && compliance?.requirementComplianceState !== requirementComplianceStates.failed.id && compliance?.requirementComplianceState !== requirementComplianceStates.success.id) {
            return false;
        }

        if (!filter.notMet && compliance?.requirementComplianceState === requirementComplianceStates.failed.id) {
            return false;
        }

        if (!filter.met && compliance?.requirementComplianceState === requirementComplianceStates.success.id) {
            return false;
        }

        // If we get here, we should show this item as we have no reason to filter it out.
        return true;
    }, [filter]);

    // If any filter is toggled off, than we have a simple isFiltered flag set to true.
    const isFiltered = useMemo(() => {
        if (
            !filter.bronze
            || !filter.gold
            || !filter.met
            || !filter.notMet
            || !filter.silver
            || !filter.unanswered
        ) {
            return true;
        }

        return false;
    }, [filter]);

    return {
        filter,
        toggleFilter,
        resetFilter,
        shouldShowRequirement,
        isFiltered,
    };
}
