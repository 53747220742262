import * as React from "react";
import "./videoComponent.scss";
import { Blob } from "../../api/main/models/Blob";

export interface VideoComponentProps {
    videoBlob?: Blob | undefined | null,
    thumbnailBlob?: Blob | undefined | null,
    editMode?: boolean,
    autoPlay?: boolean,
}

/**
 * Component for showing video in a consistant way.
 * @param props
 */
export const VideoComponent = (props: VideoComponentProps) => {
    const {
        videoBlob,
        thumbnailBlob,
        editMode,
        autoPlay,
    } = props;

    return (
        <div className={`embed-responsive embed-responsive-16by9 mb-4 video-component ${editMode ? 'video-component-edit-mode': ''}`}>
            <video
                src={videoBlob?.url ?? ''}
                poster={thumbnailBlob?.url ?? ''}
                autoPlay={autoPlay}
                controls>
            </video>
        </div>
        );
};
