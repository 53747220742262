import * as React from 'react';
import "./areaCertificateThumbnail.scss";

export interface AreaCertificateThumbnailProps {
    awardLevel: string,
}

/**
 * Card with the schools result for an area for the certificate screen.
 * @param props
 */
export const AreaCertificateThumbnail = (props: AreaCertificateThumbnailProps) => {
    const {
        awardLevel
    } = props;

    return (
        <div className={`area-certificate-thumbnail area-certificate-thumbnail-${awardLevel?.toLowerCase() ?? ''}`}>
        </div>
        );
};
