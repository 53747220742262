import * as React from "react";
import { useCurrentSchoolId } from "../shared/useCurrentSchoolId/useCurrentSchoolId";
import { useSchool } from "../../api/main/schools/useSchool";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "reactstrap";
import "./loadingAndCalculatingPlaceholder.scss";
import { LoadingIndicator } from "../shared/LoadingIndicator";

/**
 * Placeholder control to show while we are loading and calculating the school's compliane as the first load can take a long time depending on
 * the amount of data, server performance, etc.
 */
export const LoadingAndCalculatingPlaceholder = () => {
    const schoolId = useCurrentSchoolId();
    const { data: { model: school } } = useSchool(schoolId?.toString(), { fetchPolicy: 'cache-first' });

    const { t } = useTranslation();
    const schoolName = React.useMemo(() => school?.schoolName ?? t('loadingAndCalculatingPlaceholder.defaultSchoolName', 'school'), [school, t]);

    return (
        <Card className="loading-and-calculating-placeholder">
            <Row>
                <Col xs="auto">
                    <div className="loading-and-calculating-placeholder-image">
                        <LoadingIndicator />
                    </div>
                </Col>
                <Col className="loading-and-calcualting-placeholder-text">
                    {t('loadingAndCalculatingPlaceholder.text', 'Calculating {{schoolName}}\'s path to Gold Standard Compliance...', { schoolName})}
                </Col>
            </Row>
        </Card>
        );
}
