import gql from "graphql-tag";
import { requirementSchoolPhaseFields } from "../generated/requirementSchoolPhaseFields";

export const requirementSchoolPhaseFieldsFragment = gql`
    fragment requirementSchoolPhaseFields on RequirementSchoolPhase {
        id
        requirementId
        schoolPhaseId
    }
`;


export type RequirementSchoolPhase = Omit<requirementSchoolPhaseFields, '__typename' | 'schoolPhase'>;
