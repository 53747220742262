import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SchoolPhaseCreateInput } from '../generated/globalTypes';
import { createSchoolPhaseMutation, createSchoolPhaseMutationVariables } from '../generated/createSchoolPhaseMutation';
import { schoolPhaseFieldsFragment } from '../models/SchoolPhase';

/**
 * Get a callback to create a SchoolPhase in the store.
 */
export function useCreateSchoolPhaseCallback(): [(model: SchoolPhaseCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSchoolPhaseMutation, createSchoolPhaseMutationVariables>(
        gql`
            mutation createSchoolPhaseMutation ($model: SchoolPhaseCreateInput!) {
                createSchoolPhase(model: $model) {
                    ...schoolPhaseFields
                }
            }

            ${schoolPhaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SchoolPhaseCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
