import gql from "graphql-tag";
import { schoolSchoolTypeFields } from "../generated/schoolSchoolTypeFields";
import { schoolTypeFieldsFragment } from "./SchoolType";

export const schoolSchoolTypeFieldsFragment = gql`
    fragment schoolSchoolTypeFields on SchoolSchoolType {
        id
        schoolId
        schoolTypeId

        schoolType {
            ...schoolTypeFields
        }
    }

    ${schoolTypeFieldsFragment}
`;


export type SchoolSchoolType = Omit<schoolSchoolTypeFields, '__typename' | 'schoolType'>;
