import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { schoolTypeQuery, schoolTypeQueryVariables } from '../generated/schoolTypeQuery';
import { schoolTypeFieldsFragment } from '../models/SchoolType';
import { mainApiConfig } from '../../../configure/mainApiConfig';

/**
 * Get a SchoolType from the store.
 * @param id
 * @param options
 */
export function useSchoolType(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<schoolTypeQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<schoolTypeQuery, schoolTypeQueryVariables>(
        gql`
        query schoolTypeQuery ($id: ID!) {
            model: schoolType(id: $id) {
                ...schoolTypeFields
            }
        }

        ${schoolTypeFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
