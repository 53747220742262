import * as React from 'react';
import { RequirementAreaCreateInput, RequirementAreaUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateRequirementAreaCallback } from './useCreateRequirementAreaCallback';
import { useUpdateRequirementAreaCallback } from './useUpdateRequirementAreaCallback';
import { RequirementArea } from '../models/RequirementArea';

/**
 * Get a callback to save a RequirementArea in the store using either a create or update.
 */
export function useSaveRequirementAreaCallback(options: SaveInStoreOptions<RequirementArea, string> = {}) {
    const [_create, createStatus] = useCreateRequirementAreaCallback();
    const create = React.useCallback((model: Partial<RequirementArea>) => _create(model as RequirementAreaCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateRequirementAreaCallback();
    const update = React.useCallback((id: string, changes: Partial<RequirementArea>) => _update(id, changes as RequirementAreaUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
