import gql from "graphql-tag";
import { schoolFields } from "../generated/schoolFields";

export const schoolFieldsFragment = gql`
    fragment schoolFields on School {
        id
        establishmentNo
        schoolName
        street
        locality
        address3
        town
        county
        postCode
        schoolLicenceId

        isLicencedForMonitoringReporting
        isMasterSchool
        masterSchoolId
    }
`;


export type School = Omit<schoolFields, '__typename'>;
