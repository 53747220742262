import gql from "graphql-tag";
import { blobFields } from "../generated/blobFields";

export const blobFieldsFragment = gql`
    fragment blobFields on Blob {
        id
        filename
        mimeType
        path
        userId
        description

        url
    }
`;


export type Blob = Omit<blobFields, '__typename'>;
