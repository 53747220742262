import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ActionUserTaskUpdateInput } from '../generated/globalTypes';
import { updateActionUserTaskMutation, updateActionUserTaskMutationVariables } from '../generated/updateActionUserTaskMutation';
import { actionUserTaskFieldsFragment } from '../models/ActionUserTask';

/**
 * Get a callback to update a ActionUserTask in the store.
 */
export function useUpdateActionUserTaskCallback(): [(id: string, model: ActionUserTaskUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateActionUserTaskMutation, updateActionUserTaskMutationVariables>(
        gql`
            mutation updateActionUserTaskMutation ($model: ActionUserTaskUpdateInput!) {
                updateActionUserTask(model: $model) {
                    ...actionUserTaskFields
                }
            }

            ${actionUserTaskFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: ActionUserTaskUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
