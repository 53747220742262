import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Button, Spinner, ButtonGroup } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { AreaComplianceCard } from './AreaComplianceCard';
import { useParams, useHistory } from 'react-router';
import { StickyToolbar } from '../shared/StickyToolbar';
import { ComplianceProgressBar } from '../schoolCompliance/ComplianceProgressBar';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useChangesArray } from '../../shared/useChanges';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import { useSaveSchoolRequirementComplianceCallback } from '../../api/main/schoolRequirementCompliances/useSaveSchoolRequirementComplianceCallback';
import { useDeleteSchoolRequirementComplianceCallback } from '../../api/main/schoolRequirementCompliances/useDeleteSchoolRequirementComplianceCallback';
import { useAddOrUpdateAction } from '../schoolCompliance/useAddOrUpdateAction';
import { useSaveActionCallback } from '../../api/main/actions/useSaveActionCallback';
import { useDeleteActionCallback } from '../../api/main/actions/useDeleteActionCallback';
import { Action } from '../../api/main/models/Action';
import { useChangeCompliance } from '../schoolCompliance/useChangeCompliance';
import { useAsyncCallback } from 'react-use-async-callback';
import { useDebounce } from '../shared/hooks/useDebounce';
import { useSchoolOverviewViewModel } from '../../api/main/schoolOverview/useSchoolOverviewViewModel';
import { useSchoolOverviewSupportingData } from '../../api/main/schoolOverview/useSchoolOverviewSupportingData';
import { useRequirementsForSchool } from './useRequirementsForSchool/useRequirementsForSchool';
import { isNullOrUndefined } from 'util';
import { EditSchoolTypesAndPhasesModal } from '../schoolSettings/EditSchoolTypesAndPhasesModal';
import { LoadingAndCalculatingPlaceholder } from './LoadingAndCalculatingPlaceholder';
import { useLocalStorage } from '../../shared/useLocalStorage';
import { useRequirementsFilterOptions } from './useRequirementsFilterOptions';
import { RequirementsFilter } from './RequirementsFilter';
import "./schoolOverview.scss";

/**
 * School overview. 
 */
export const SchoolOverview = () => {
    const {
        schoolId: paramsSchoolId,
    } = useParams();

    const user = useCurrentUser();
    const schoolId = paramsSchoolId ?? user?.schoolId?.toString();

    const {
        data: {
            school: model,
            schoolRequirementCompliances: storeCompliances,
            actions: storeActions,
            schoolSchoolTypes,
            schoolSchoolPhases,
        },
        isLoading: _isLoading, errors: loadingErrors,
        refresh,
    } = useSchoolOverviewViewModel(schoolId);

    const {
        data: {
            currentRequirementsRelease,
            //videos,
        },
        isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors,
    } = useSchoolOverviewSupportingData({ fetchPolicy: "cache-first" });

    const isLoading = _isLoading || isLoadingSupportingData;

    const { t } = useTranslation();
    const history = useHistory();

    // UI state of the users expanded or filtered areas.
    // NOTE all of this UI state is stored in the localStorage so that we can restore it each time the user returns as the alternative
    // would be that the user has to put it all back in again each time.
    //

    // Expand and collapse sections.
    const [openAreaIds, setOpenAreaIds] = useLocalStorage<Array<string>>('schoolOverview.openAreaIds', []);
    const isAreaOpen = React.useCallback((id: string) => !!openAreaIds.find(it => it === id), [openAreaIds]);
    const toggleAreaOpen = React.useCallback((id: string) => setOpenAreaIds(
        prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            }

            return [
                ...prevState,
                id,
            ];
        }
    ), [setOpenAreaIds]);

    const expandAllAreas = React.useCallback(() => setOpenAreaIds(currentRequirementsRelease?.areas?.map(item => item.area.id) ?? []), [setOpenAreaIds, currentRequirementsRelease]);
    const collapseAllAreas = React.useCallback(() => setOpenAreaIds([]), [setOpenAreaIds]);

    // Filter options.
    const { filter, toggleFilter, resetFilter, shouldShowRequirement, isFiltered, } = useRequirementsFilterOptions();

    // Manager that looks after all compliance records.
    const complianceManager = useChangesArray<SchoolRequirementCompliance, string>(storeCompliances, item => item.id);
    const changeCompliance = useChangeCompliance({
        complianceManager,
        schoolId,
    });
    const [saveCompliance, { errors: saveComplianceErrors }] = useSaveSchoolRequirementComplianceCallback();
    const [removeCompliance, { errors: removeComplianceErrors }] = useDeleteSchoolRequirementComplianceCallback();

    // Manager that looks after actions.
    const actionsManager = useChangesArray<Action, string>(storeActions, item => item.id);
    const [saveAction, { errors: saveActionErrors }] = useSaveActionCallback();
    const [removeAction, { errors: removeActionErrors }] = useDeleteActionCallback();

    // Add or update the auto action for a requirement.
    const addOrUpdateAction = useAddOrUpdateAction({ actionsManager, schoolId });

    // All requirements (ensuring there are no duplicates).
    const requirements = useRequirementsForSchool(currentRequirementsRelease, schoolSchoolTypes, schoolSchoolPhases);

    // All areas we track compliance for (with areas removed when there are no requirements for us under them).
    const areas = React.useMemo(() => {
        let ret = currentRequirementsRelease?.areas?.map(item => item.area) ?? [];
        // Remove any areas without any requirements for us.
        ret = ret.filter(item => {
            const myLinks = currentRequirementsRelease?.requirements;
            const myRequirements = requirements?.filter(req => !!myLinks?.find(link => link.requirementId === req.id));
            if (!myRequirements?.length) {
                return false;
            }

            return true;
        });

        return ret;
    }, [currentRequirementsRelease, requirements]);

    // Save everything.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options: { dontNavigateBack?: boolean } = {}) => {
        // Save all the compliance answers.
        for (const item of complianceManager.added) {
            await saveCompliance(item.id, complianceManager.changesFor(item.id), true);
        }
        for (const item of complianceManager.updated) {
            await saveCompliance(item.id, complianceManager.changesFor(item.id), false);
        }
        for (const item of complianceManager.removed) {
            await removeCompliance(item.id);
        }
        complianceManager.markAsSaved();

        // Save all the actions.
        for (const item of actionsManager.added) {
            await saveAction(item.id, actionsManager.changesFor(item.id), true);
        }
        for (const item of actionsManager.updated) {
            await saveAction(item.id, actionsManager.changesFor(item.id), false);
        }
        for (const item of actionsManager.removed) {
            await removeAction(item.id);
        }
        actionsManager.markAsSaved();
    }, [
        complianceManager, saveCompliance, removeCompliance,
        actionsManager, saveAction, removeAction,
    ]);

    // Save everything with some debounce support so we don't try and save after change if the user is making changes quickly.
    const saveFormDebounce = useDebounce(() => {
        saveForm();
    }, { delay: 2000 });

    // If there are no school phases selected, we need to force the user to select some.  In practice this should only happen on first login for a school.
    const [schoolSetupModalIsOpen, setSchoolSetupModalIsOpen] = React.useState<boolean | undefined>(undefined);
    const closeSchoolSetupModal = React.useCallback(() => { setSchoolSetupModalIsOpen(false); refresh(); }, [setSchoolSetupModalIsOpen, refresh]);
    React.useEffect(() => {
        // If the modal has already been opened or closed then do nothing.
        if (!isNullOrUndefined(schoolSetupModalIsOpen)) {
            return;
        }

        // If we do not yet have the school phases or types loaded, do nothing.
        if (isNullOrUndefined(schoolSchoolPhases) || isNullOrUndefined(schoolSchoolTypes)) {
            return;
        }

        // If at least one phase and type has been selected for the school, do nothing.
        if (schoolSchoolPhases.length && schoolSchoolTypes.length) {
            return;
        }

        // If we get there, we need to open up the modal for initial one time setup.
        setSchoolSetupModalIsOpen(true);
    }, [schoolSetupModalIsOpen, schoolSchoolPhases, schoolSchoolTypes, setSchoolSetupModalIsOpen]);

    // We want to show the loading placeholder if we don't have enough information to show anything else.
    const showLoadingPlaceholder = !currentRequirementsRelease || !model;

    // When the filter is applied, we want to give a count of the number of requirements matching the filter.  So lets get all the matches now.
    const { filteredRequirements, filteredAreas, } = React.useMemo(() => {
        // Filter the requirements down.
        const filteredRequirements = requirements?.filter(requirement => {
            // Get the compliance state (if any) for this requirement.
            const compliance = complianceManager.model.find(it => it.requirementOriginKey === requirement.originKey);

            // Check if we should display this item.
            const shouldShow = shouldShowRequirement(requirement, compliance);
            if (!shouldShow) {
                return false;
            }

            return true;
        }) ?? [];

        // Filter the areas to only those with at least one filtered requirement.
        const filteredAreas = areas?.filter(area => {
            const myLinks = currentRequirementsRelease?.requirements?.filter(it => it.requirementAreaId === area.id);
            const myRequirements = filteredRequirements.filter(it => !!myLinks?.find(link => link.requirementId === it.id)); // Only interested in the filtered items here.

            if (!!myRequirements.length) {
                return true;
            }

            return false;
        }) ?? [];

        return {
            filteredRequirements,
            filteredAreas,
        };
    }, [requirements, complianceManager.model, shouldShowRequirement, currentRequirementsRelease, areas]);
    const hasNoRequirementsToShow = !filteredRequirements?.length && !!requirements?.length;

    return (
        <Background className="school-overview">
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col>
                            <h2 className="text-muted school-overview-h2">
                                <ConditionalFragment showIf={!!paramsSchoolId}>
                                    <Button color="primary" outline onClick={() => history.goBack()} style={{ paddingTop: '2px', paddingBottom: '2px' }}>
                                        <FontAwesomeIcon icon="caret-left" />
                                        <span className="sr-only"><> </>{t('common.back', 'Back')}</span>
                                    </Button>
                                    <> </>
                                </ConditionalFragment>
                            </h2>
                            <div className="school-overview-logo">
                            </div>
                        </Col>
                        <ConditionalFragment showIf={isLoading && !showLoadingPlaceholder /* Don't want two flashing dots during the first load */}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={isSaving}>
                            <Col xs="auto">
                                <Spinner size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs={12} md={8}>
                            <ComplianceProgressBar size="lg" requirements={requirements} compliances={complianceManager.model} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors, loadSupportingDataErrors, saveFormErrors, saveComplianceErrors, saveActionErrors, removeActionErrors, removeComplianceErrors]} />

                <Row className="mb-4">
                    <Col>
                        <RequirementsFilter
                            filter={filter}
                            toggleFilter={toggleFilter}
                            resetFilter={resetFilter}
                        />
                    </Col>
                    <Col>
                        <div className="school-overview-school-name">
                            {model?.schoolName}
                        </div>
                    </Col>
                    <Col sm="auto">
                        <ButtonGroup size="sm">
                            <Button outline onClick={expandAllAreas}>
                                <FontAwesomeIcon icon="angle-double-down" />
                                <> </>
                                {t('schoolOverview.expandAll', 'Expand all')}
                            </Button>
                            <Button outline onClick={collapseAllAreas}>
                                <FontAwesomeIcon icon="angle-double-up" />
                                <> </>
                                {t('schoolOverview.expandAll', 'Collapse all')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                {/* Show a summary when the filter is being used. */}
                {
                    isFiltered ?
                        hasNoRequirementsToShow ? (
                            <div>
                                {t('schoolOverview.noRequirementsToShow.message', 'There are no requirements in any sections that match your filter.')}
                                <> </>
                                <Button color="link" style={{ paddingLeft: '0px', }} onClick={resetFilter}>
                                    {t('schoolOverview.noRequirementsToShow.reset', 'Reset filters')}
                                </Button>
                            </div>
                        )
                            : !openAreaIds.length? (
                                <div>
                                    {t('schoolOverview.itemsAreFiltered.message', 'Expand a section to view filtered results.', { requirementsCount: filteredRequirements.length, areasCount: filteredAreas.length, })}
                                    <> </>
                                    <Button color="link" style={{ paddingLeft: '0px', }} onClick={expandAllAreas}>
                                        {t('schoolOverview.expandAll', 'Expand all sections')}
                                    </Button>
                                </div>
                            )
                                : null
                            : null
                }


                {/* Show the loading placeholder until we have enough data to show something more useful. */}
                {
                    showLoadingPlaceholder ? (<LoadingAndCalculatingPlaceholder />): null
                }
                
                {/* Show a full overview broken down by area */}
                {
                    areas?.map(item => {
                        const myLinks = currentRequirementsRelease?.requirements?.filter(it => it.requirementAreaId === item.id);
                        const myRequirements = requirements?.filter(it => !!myLinks?.find(link => link.requirementId === it.id));
                        const myCompliances = complianceManager.model?.filter(it => !!myRequirements?.find(req => req.originKey === it.requirementOriginKey));

                        return (
                            <AreaComplianceCard
                                key={item.id}
                                area={item}
                                school={model}
                                user={user}
                                requirements={myRequirements}
                                requirementLinks={myLinks}
                                compliances={myCompliances}
                                actionsManager={actionsManager}
                                addOrUpdateAction={addOrUpdateAction}
                                changeCompliance={changeCompliance}
                                saveFormDebounce={saveFormDebounce}
                                isOpen={isAreaOpen(item.id)}
                                toggle={() => toggleAreaOpen(item.id)}
                                shouldShowRequirement={shouldShowRequirement}
                                resetFilter={resetFilter}
                                isFiltered={isFiltered}
                            />
                        );
                    })
                }
            </Container>

            <ConditionalFragment showIf={!!schoolSetupModalIsOpen}>
                <EditSchoolTypesAndPhasesModal
                    isOpen={!!schoolSetupModalIsOpen} toggle={closeSchoolSetupModal}
                    schoolId={schoolId ?? 0}
                    mandatory={true}
                />
            </ConditionalFragment>
        </Background>
        );
};
