import * as React from 'react';
import { SchoolSchoolTypeCreateInput, SchoolSchoolTypeUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolSchoolTypeCallback } from './useCreateSchoolSchoolTypeCallback';
import { useUpdateSchoolSchoolTypeCallback } from './useUpdateSchoolSchoolTypeCallback';
import { SchoolSchoolType } from '../models/SchoolSchoolType';

/**
 * Get a callback to save a SchoolSchoolType in the store using either a create or update.
 */
export function useSaveSchoolSchoolTypeCallback(options: SaveInStoreOptions<SchoolSchoolType, string> = {}) {
    const [_create, createStatus] = useCreateSchoolSchoolTypeCallback();
    const create = React.useCallback((model: Partial<SchoolSchoolType>) => _create(model as SchoolSchoolTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolSchoolTypeCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolSchoolType>) => _update(id, changes as SchoolSchoolTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
