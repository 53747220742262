import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Input, Badge, Collapse } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { StickyToolbar } from '../shared/StickyToolbar';
import { DonutChart, DonutChartData } from './DonutChart';
import { useAdminReportsData } from '../../api/main/adminReports/useAdminReportsData';
import { actionStates } from '../../services/actionStates/actionStates';
import { getStandardRatingMetadata, StandardRating } from '../../api/main/models/constants/StandardRatings';
import { useToggleStateArray } from 'use-toggle-state';
import { adminReportsDataQuery_reports_awardsClaimedBySchool_school } from '../../api/main/generated/adminReportsDataQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdminReportsSchoolSummaryCard } from './AdminReportsSchoolSummaryCard';


/**
 * MAT overview. 
 */
export const AdminReports = () => {
    const { t } = useTranslation();

    const { data: { reports }, isLoading, errors: loadingErrors } = useAdminReportsData();

    const [filterBySchool, _setFilterBySchool] = React.useState<number | null>(null);
    const setFilterBySchool = React.useCallback((value: string) => {
        _setFilterBySchool(!value? null: parseInt(value));
    }, [_setFilterBySchool]);

    // Get a list of the names of all unique schools with any data.
    const schools = React.useMemo(() => {
        const ret: Array<{ schoolId: number, schoolName: string }> = [];

        if (reports?.requirementsCompletedBySchool) {
            for (const item of reports?.requirementsCompletedBySchool) {
                const existing = ret.find(it => it.schoolId === item.schoolId);
                if (existing) {
                    continue;
                }

                ret.push({
                    schoolId: item.schoolId,
                    schoolName: item.school.schoolName,
                });
            }
        }

        if (reports?.areasCompletedBySchool) {
            for (const item of reports?.areasCompletedBySchool) {
                const existing = ret.find(it => it.schoolId === item.schoolId);
                if (existing) {
                    continue;
                }

                ret.push({
                    schoolId: item.schoolId,
                    schoolName: item.school.schoolName,
                });
            }
        }

        if (reports?.actionsCreatedBySchool) {
            for (const item of reports?.actionsCreatedBySchool) {
                const existing = ret.find(it => it.schoolId === item.schoolId);
                if (existing) {
                    continue;
                }

                ret.push({
                    schoolId: item.schoolId,
                    schoolName: item.school.schoolName,
                });
            }
        }

        if (reports?.awardsClaimedBySchool) {
            for (const item of reports?.awardsClaimedBySchool) {
                const existing = ret.find(it => it.schoolId === item.schoolId);
                if (existing) {
                    continue;
                }

                ret.push({
                    schoolId: item.schoolId,
                    schoolName: item.school.schoolName,
                });
            }
        }

        // Sort the list
        ret.sort((a, b) => {
            if (a.schoolName === b.schoolName) {
                return 0;
            } else if (a.schoolName < b.schoolName) {
                return -1;
            } else {
                return 1;
            }
        })

        return ret;
    }, [reports]);

    // Format requirement completion data.
    const requirementsCompletedData = React.useMemo(() => {
        if (!reports?.requirementsCompletedBySchool) {
            return [];
        }

        // Filter by the school if we need to.
        let source = reports?.requirementsCompletedBySchool;
        let getName = (item: any) => item.school.schoolName;
        if (filterBySchool) {
            source = source.filter(item => item.schoolId === filterBySchool);
            getName = (item: any) => item.requirement.name;
        }

        let ret: Array<DonutChartData> = [];
        for (const item of source) {
            const name = getName(item);
            let data = ret.find(it => it.name === name);
            if (!data) {
                data = { name: name, value: 0 };
                ret.push(data);
            }

            data.value += 1;
        }

        return ret;
    }, [reports, filterBySchool]);

    // Format area completion data.
    const areasCompletedData = React.useMemo(() => {
        if (!reports?.areasCompletedBySchool) {
            return [];
        }

        // Filter by the school if we need to.
        let source = reports?.areasCompletedBySchool;
        let getName = (item: any) => item.school.schoolName;
        if (filterBySchool) {
            source = source.filter(item => item.schoolId === filterBySchool);
            getName = (item: any) => item.area.name;
        }

        let ret: Array<DonutChartData> = [];
        for (const item of source) {
            const name = getName(item);
            let data = ret.find(it => it.name === name);
            if (!data) {
                data = { name: name, value: 0 };
                ret.push(data);
            }

            data.value += 1;
        }

        return ret;
    }, [reports, filterBySchool]);

    // Format area completion data.
    const actionsCreatedData = React.useMemo(() => {
        if (!reports?.actionsCreatedBySchool) {
            return [];
        }

        // Filter by the school if we need to.
        let source = reports?.actionsCreatedBySchool;
        let getName = (item: any) => item.school.schoolName;
        if (filterBySchool) {
            source = source.filter(item => item.schoolId === filterBySchool);
            getName = (item: any) => actionStates.findById(item.action.actionState).localizedName();
        }

        let ret: Array<DonutChartData> = [];
        for (const item of source) {
            const name = getName(item);
            let data = ret.find(it => it.name === name);
            if (!data) {
                data = { name: name, value: 0 };
                ret.push(data);
            }

            data.value += 1;
        }

        return ret;
    }, [reports, filterBySchool]);

    // Format awards claimed data.
    const awardsClaimedData = React.useMemo(() => {
        if (!reports?.awardsClaimedBySchool) {
            return [];
        }

        // Filter by the school if we need to.
        let source = reports?.awardsClaimedBySchool;
        let getName = (item: any) => getStandardRatingMetadata(item.standardRating as StandardRating).displayName;
        if (filterBySchool) {
            source = source.filter(item => item.schoolId === filterBySchool);
        }

        let ret: Array<DonutChartData> = [];
        // Add Gold, Silver, Bronze so we always match our colours and desired order.
        ret.push({ name: StandardRating.Gold, value: 0 });
        ret.push({ name: StandardRating.Silver, value: 0 });
        ret.push({ name: StandardRating.Bronze, value: 0 });

        // Add schools to the right groups.
        for (const item of source) {
            const name = getName(item);
            let data = ret.find(it => it.name === name);
            if (!data) {
                data = { name: name, value: 0 };
                ret.push(data);
            }

            data.value += 1;
        }

        return ret;
    }, [reports, filterBySchool]);

    // Award Section collapsing.
    type SectionKey = 'gold' | 'silver' | 'bronze';
    const [isSectionOpen, toggleSectionOpen] = useToggleStateArray<SectionKey>([]);

    // Section component.
    const Section = React.useCallback((props: { text: string, sectionKey: SectionKey, schools: Array<adminReportsDataQuery_reports_awardsClaimedBySchool_school> }) => {
        const {
            text,
            sectionKey,
            schools,
        } = props;

        return (
            <div>
                <h5 style={{ cursor: 'pointer' }} onClick={() => toggleSectionOpen(sectionKey)}>
                    {text}
                    <> </>
                    <Badge color="dark" pill>
                        {t('common.integer', '{{value, #,0}}', { value: schools.length })}
                    </Badge>
                    <> </>
                    <FontAwesomeIcon icon={isSectionOpen(sectionKey) ? 'caret-up' : 'caret-down'} />
                </h5>

                <Collapse isOpen={isSectionOpen(sectionKey)}>
                    {
                        schools.map(item => {
                            const claimedAward = reports?.awardsClaimedBySchool.find(it => it.schoolId.toString() === item.id);

                            return (
                                <AdminReportsSchoolSummaryCard key={item.id}
                                    schoolId={item.id}
                                    schoolName={item.schoolName}
                                    awardLevel={claimedAward?.standardRating}
                                />
                            );
                        })
                    }
                </Collapse>
            </div>
        );
    }, [isSectionOpen, toggleSectionOpen, reports, t]);

    return (
        <Background>
            <Banner fluid>
                <Row className="mb-2">
                    <Col>
                        <h1>
                            {t('adminReports.heading', 'Admin reports')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} lg={4}>
                            <h2 className="text-muted">
                                {t('adminReports.heading', 'Overview')}
                            </h2>
                        </Col>
                        <Col xs={12} lg={8}>
                            <Input type="select" value={filterBySchool ?? ''} onChange={e => setFilterBySchool(e.currentTarget.value)}>
                                <option value="">{t('adminReports.filterBySchool.allSchools', '(All schools)')}</option>
                                {
                                    schools?.map(item => (
                                        <option key={item.schoolId} value={item.schoolId}>{item.schoolName}</option>
                                        ))
                                }
                            </Input>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors]} />

                <Row>
                    <Col xs={12} lg={4}>
                        <DonutChart title={t('adminReports.awardsClaimed', 'Awards claimed')}
                            model={awardsClaimedData}
                            colors={['#d0aa3f', '#bababa', '#f0b26c']}
                        />
                    </Col>
                    <Col xs={12} lg="">
                        {
                            !!filterBySchool ? (
                                // Show just the filtered school if one is selected.
                                <AdminReportsSchoolSummaryCard
                                    schoolId={filterBySchool.toString()}
                                    schoolName={schools?.find(it => it.schoolId === filterBySchool)?.schoolName ?? ''}
                                    awardLevel={reports?.awardsClaimedBySchool?.find(it => it.schoolId === filterBySchool)?.standardRating}
                                    startOpen={true}
                                />
                            ) : (
                                    // Show all schools grouped into expandable groups by award level.
                                    <>
                                        {/* Gold */}
                                        <Section
                                            text={getStandardRatingMetadata(StandardRating.Gold).displayName}
                                            sectionKey="gold"
                                            schools={reports?.awardsClaimedBySchool?.filter(item => item.standardRating === StandardRating.Gold)?.map(item => item.school) ?? []}
                                        />

                                        {/* Silver */}
                                        <Section
                                            text={getStandardRatingMetadata(StandardRating.Silver).displayName}
                                            sectionKey="silver"
                                            schools={reports?.awardsClaimedBySchool?.filter(item => item.standardRating === StandardRating.Silver)?.map(item => item.school) ?? []}
                                        />

                                        {/* Bronze */}
                                        <Section
                                            text={getStandardRatingMetadata(StandardRating.Bronze).displayName}
                                            sectionKey="bronze"
                                            schools={reports?.awardsClaimedBySchool?.filter(item => item.standardRating === StandardRating.Bronze)?.map(item => item.school) ?? []}
                                        />
                                    </>
                                )
                        }
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={4}>
                        <DonutChart title={t('adminReports.requirementsCompleted', 'Requirements completed')} model={requirementsCompletedData}
                        />
                    </Col>
                    <Col xs={12} lg={4}>
                        <DonutChart title={t('adminReports.areasCompleted', 'Areas completed')} model={areasCompletedData} />
                    </Col>
                    <Col xs={12} lg={4}>
                        <DonutChart title={t('adminReports.actionsCreated', 'Actions planned')} model={actionsCreatedData} />
                    </Col>
                </Row>
            </Container>
        </Background>
        );
};
