import gql from "graphql-tag";
import { requirementSchoolBusLinkFields } from "../generated/requirementSchoolBusLinkFields";

export const requirementSchoolBusLinkFieldsFragment = gql`
    fragment requirementSchoolBusLinkFields on RequirementSchoolBusLink {
        id
        requirementId
        linkText
        url
        displayOrder
        archived
    }
`;


export type RequirementSchoolBusLink = Omit<requirementSchoolBusLinkFields, '__typename'>;
