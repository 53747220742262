import * as React from "react";
import { Form, Alert, FormGroup, Label, Spinner } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { useTranslation } from "react-i18next";
import { FormButtons } from "../shared/FormButtons";
import { MainContainer } from "../shared/MainContainer";
import { AccountBackground } from "./AccountBackground";
import { Banner } from "../shared/Banner";
import { useAuthorizeService } from "../../api/api-authorization/useAuthorizeService";
import { useAsyncCallback } from "react-use-async-callback";
import { AuthenticationResultStatus } from "../../api/api-authorization/AuthorizeService";
import { apiAuthorizationConfig } from "../../configure/apiAuthorizationConfig";

interface LoginModel {
    email: string,
}

/**
 * Login screen.
 */
export const DeveloperLogin = () => {
    const { t } = useTranslation();

    const { model, change } = useChanges<LoginModel>({ email: '' });

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            email: () => !model.email ? t('login.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Login by creating a localStorage entry with the user's email.  This gets picked up by AuthorizationService for development use.
    const [loginErrorMessage, setLoginErrorMessage] = React.useState<string | null>(null);
    const authorizeService = useAuthorizeService();
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const [login, { isExecuting: isLoggingIn, errors: loginErrors }] = useAsyncCallback(async (model: LoginModel) => {
        const result = await authorizeService.signInDeveloper(model.email);
        if (result.status !== AuthenticationResultStatus.Success) {
            setLoginErrorMessage(result.message ?? t('login.cannotLogin', 'Cannot login'));
            return;
        }

        // All OK, lets continue.
        setLoginErrorMessage(null);
        setIsDoingFullPageRedirect(true);
        window.location.href = '/';
    }, [authorizeService, setLoginErrorMessage, t, setIsDoingFullPageRedirect]);

    /**
     * Perform a login by and handle the result.
     */
    const performLogin = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        await login(model);
    }, [login, model, validate]);

    // Render the UI.
    return (
        <AccountBackground>
            <Banner>
                <h1>{t('login.signInHeading', 'Developer sign in')}</h1>
            </Banner>
            <MainContainer>
                <Form onSubmit={async e => { e.preventDefault(); await performLogin(); }}>
                    <Alert color="warning">
                        {t('login.developerLoginWarning', 'This page is only enabled in development and test environments and allows you to test the system as different users without requiring the site to be hosted within theschoolbus.net.  This page is not avaialble in production use.  In a production environment whenever you would have been redirect to')}
                        <> </><a className="alert-link" href={apiAuthorizationConfig.loginPageRedirectUrl}>{apiAuthorizationConfig.loginPageRedirectUrl}</a>
                    </Alert>

                    <AlertOnErrors simple errors={[loginErrors, (loginErrorMessage? { message: loginErrorMessage }: null)]} />

                    <FormGroup>
                        <Label htmlFor="email">{t('login.email', 'Email')}</Label>
                        <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                    </FormGroup>

                    <FormButtons>
                        <ButtonAsync type="submit" color="primary" isExecuting={isLoggingIn || isDoingFullPageRedirect}
                            executingChildren={<><Spinner size="sm" /> {t('login.loggingIn', 'Logging in...')}</>}>
                            {t('login.logIn', 'Log in')}
                        </ButtonAsync>
                    </FormButtons>
                </Form>
            </MainContainer>
        </AccountBackground>
    );
};
