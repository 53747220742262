import * as React from 'react';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { RequirementsReleaseRequirementArea } from '../../api/main/models/RequirementsReleaseRequirementArea';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label, Row, Col, Button, Card, CardBody, CardHeader, Collapse, ButtonGroup } from 'reactstrap';
import { ValidatedInput } from 'pojo-validator-reactstrap/dist/lib/commonjs';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { HtmlEditor } from '../../shared/htmlEditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleState } from 'use-toggle-state';
import { ModelArrayChanges } from '../../shared/useChanges';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { Requirement } from '../../api/main/models/Requirement';
import { EditRequirement } from './EditRequirement';
import { Guid } from 'guid-string';
import moment from 'moment';
import { SchoolPhase } from '../../api/main/models/SchoolPhase';
import { RequirementSchoolPhase } from '../../api/main/models/RequirementSchoolPhase';
import { RequirementSchoolType } from '../../api/main/models/RequirementSchoolType';
import { SchoolType } from '../../api/main/models/SchoolType';
import { RequirementSchoolBusLink } from '../../api/main/models/RequirementSchoolBusLink';
import { policyManagerLinkTypes } from '../../services/policyManagerIntegration/policyManagerLinkTypes';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AddLinkModal } from './AddLinkModal';
import { PolicyManagerMandatoryPolicy } from '../../api/main/models/PolicyManagerMandatoryPolicy';
import { StandardRating } from '../../api/main/models/constants/StandardRatings';
import { requirementsReleaseSupportingDataQuery_videos } from '../../api/main/generated/requirementsReleaseSupportingDataQuery';

export interface EditRequirementAreaProps {
    model: RequirementArea,
    change: (changes: Partial<RequirementArea>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    linkModel: RequirementsReleaseRequirementArea,
    changeLink: (changes: Partial<RequirementsReleaseRequirementArea>) => void,

    startOpen?: boolean,

    remove: () => void,

    requirementLinksManager: ModelArrayChanges<RequirementsReleaseRequirement, string>,
    requirementsManager: ModelArrayChanges<Requirement, string>,

    schoolPhases: Array<SchoolPhase>,
    schoolTypes: Array<SchoolType>,
    policyManagerMandatoryPolicies: Array<PolicyManagerMandatoryPolicy>,

    schoolPhaseLinksManager: ModelArrayChanges<RequirementSchoolPhase, string>,
    schoolTypeLinksManager: ModelArrayChanges<RequirementSchoolType, string>,
    schoolBusLinksManager: ModelArrayChanges<RequirementSchoolBusLink, string>,

    areaManager: ModelArrayChanges<RequirementArea, string>,

    readOnly: boolean,

    videos: Array<requirementsReleaseSupportingDataQuery_videos>,
}

/**
 * An area within a RequirementsRelease.
 * @param props
 */
export const EditRequirementArea = (props: EditRequirementAreaProps) => {
    const {
        model, change, validate, validationErrors,
        linkModel, //changeLink,
        startOpen,
        remove,
        requirementLinksManager, requirementsManager,
        schoolPhases, schoolTypes, policyManagerMandatoryPolicies,
        schoolPhaseLinksManager, schoolTypeLinksManager,
        schoolBusLinksManager,
        areaManager,
        readOnly,
        videos,
    } = props;

    const { t } = useTranslation();
    const [isOpen, toggleOpen] = useToggleState(!!startOpen);

    // Add a requirement.
    const addRequirement = React.useCallback(() => {
        const newRequirementId = Guid.newGuid();

        // Work out the next display order.
        let nextDisplayOrder = 1;
        for (const item of requirementLinksManager.model) {
            if (item.displayOrder >= nextDisplayOrder) {
                nextDisplayOrder = item.displayOrder + 1;
            }
        }

        // Add the area and link models.
        requirementsManager.addFor({
            id: newRequirementId, name: '',
            descriptionHtml: '', archived: false, versionDate: moment().toISOString(), originKey: newRequirementId,
            sourcesHtml: '', notesHtml: '', 
            isStronglyRecommended: false,
            policyManagerType: policyManagerLinkTypes.notLinked.id, policyManagerId: 0,
            requiresReview: false,
            requiresReviewInDefaultMonths: 12,
            requiresReviewDescriptionHtml: t('editRequirementArea.requiresReviewDescriptionHtmlDefault', '<p>We recommend you review this requirement annually</p>'),
            allowEvidenceUpload: false,
            standardRating: StandardRating.Bronze,
            videoId: null,
        });
        requirementLinksManager.addFor({
            id: Guid.newGuid(),
            requirementId: newRequirementId,
            requirementAreaId: model?.id,
            requirementsReleaseId: linkModel?.requirementsReleaseId,
            displayOrder: nextDisplayOrder, archived: false
        });
    }, [requirementsManager, requirementLinksManager, model, linkModel, t]);


    // Order the requirements in the order they should be displayed.
    const orderedRequirementLinks = React.useMemo(() => {
        if (!requirementLinksManager.model) {
            return [];
        }

        let ret = requirementLinksManager.model.filter(item => item.requirementAreaId === model?.id);
        ret.sort((a, b) => a.displayOrder - b.displayOrder);
        return ret;
    }, [requirementLinksManager, model]);

    // Add an existing requirement as a link into this area.
    const linkRequirement = React.useCallback((requirementId: string) => {
        // Work out the next display order.
        let nextDisplayOrder = 1;
        for (const item of requirementLinksManager.model) {
            if (item.displayOrder >= nextDisplayOrder) {
                nextDisplayOrder = item.displayOrder + 1;
            }
        }

        // Add a link modal so the requirement is linked in multiple places now.
        requirementLinksManager.addFor({
            id: Guid.newGuid(),
            requirementId: requirementId,
            requirementAreaId: model?.id,
            requirementsReleaseId: linkModel?.requirementsReleaseId,
            displayOrder: nextDisplayOrder, archived: false
        });
    }, [requirementLinksManager, model, linkModel]);

    const [isAddLinkModalOpen, toggleAddLinkModal] = useToggleState(false);

    return (
        <>
            <Card>
                <CardHeader style={{ cursor: 'pointer'}} onClick={() => toggleOpen()}>
                    <Row>
                        <Col>
                            {model.name}
                        </Col>
                        <Col xs="auto">
                            <FontAwesomeIcon icon={ isOpen? 'caret-up': 'caret-down' } />
                            <span className="sr-only">{t('common.seeMore', 'See more')}</span>
                        </Col>
                    </Row>
                </CardHeader>
                <Collapse isOpen={isOpen}>
                    <ConditionalFragment showIf={isOpen}>
                        <CardBody tag="div">
                            <FormGroup>
                                <Label htmlFor="name">{t('editRequirementArea.name', 'Area name')}</Label>
                                <Row>
                                    <Col>
                                        <ValidatedInput name="name" type="text" readOnly={readOnly} value={model?.name ?? ''} onChange={e => change({ name: e.currentTarget.value })} onBlur={() => validate('name')} validationErrors={validationErrors} />
                                    </Col>
                                    <ConditionalFragment showIf={!readOnly}>
                                        <Col xs="auto">
                                            <Button color="link" className="text-danger" onClick={remove} disabled={!!orderedRequirementLinks.length}>
                                                <FontAwesomeIcon icon="trash" />
                                                <span className="sr-only">{t('editRequirementArea.delete', 'Delete area')}</span>
                                            </Button>
                                        </Col>
                                    </ConditionalFragment>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="name">{t('editRequirementArea.descriptionHtml', 'Area description')}</Label>
                                <HtmlEditor readOnly={readOnly} value={model?.descriptionHtml ?? ''} onChange={value => change({ descriptionHtml: value })} />
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="requirements">{t('editRequirementArea.requirement', 'Requirements')}</Label>
                                <div>
                                    {
                                        orderedRequirementLinks?.map(link => {
                                            const requirement = requirementsManager.modelFor(link.requirementId);
                                            if (!requirement) {
                                                return null;
                                            }

                                            const otherLinks = requirementLinksManager.model.filter(it => it.requirementId === link.requirementId && it.id !== link.id);

                                            return (
                                                <EditRequirement key={link.id}
                                                    readOnly={readOnly}
                                                    model={requirement} change={changes => requirementsManager.changeFor(requirement.id, changes)}
                                                    validate={() => true} validationErrors={{}}
                                                    linkModel={link} changeLink={changes => requirementLinksManager.changeFor(link.id, changes)}
                                                    startOpen={!!requirementLinksManager.added.find(it => it.id === link.id)}
                                                    otherLinks={otherLinks}
                                                    remove={() => { requirementLinksManager.removeFor(link.id); requirementsManager.removeFor(requirement.id); }}
                                                    removeLink={() => { requirementLinksManager.removeFor(link.id); }}
                                                    schoolPhases={schoolPhases} schoolTypes={schoolTypes} policyManagerMandatoryPolicies={policyManagerMandatoryPolicies}
                                                    schoolPhaseLinksManager={schoolPhaseLinksManager} schoolTypeLinksManager={schoolTypeLinksManager}
                                                    schoolBusLinksManager={schoolBusLinksManager}
                                                    areas={areaManager.model}
                                                    videos={videos}
                                                />
                                            );
                                        })
                                    }

                                    <ConditionalFragment showIf={!readOnly}>
                                        <ButtonGroup>
                                            <Button color="primary" outline onClick={addRequirement}>
                                                <FontAwesomeIcon icon="plus" />
                                                <> </>{t('editRequirementArea.addArea', 'Add requirement')}
                                            </Button>
                                            <Button color="primary" outline onClick={() => toggleAddLinkModal()}>
                                                <FontAwesomeIcon icon="link" />
                                                <> </>{t('editRequirementArea.addArea', 'Link existing requirement')}
                                            </Button>
                                        </ButtonGroup>
                                    </ConditionalFragment>
                                </div>
                            </FormGroup>
                        </CardBody>
                    </ConditionalFragment>
                </Collapse>
            </Card>

            <ConditionalFragment showIf={isAddLinkModalOpen}>
                <AddLinkModal isOpen={isAddLinkModalOpen} toggle={() => toggleAddLinkModal()}
                    requirements={requirementsManager.model.filter(item => !requirementLinksManager.model.find(it => it.requirementId === item.id && it.requirementAreaId === model.id)) /* filter out anything that is already in this area */}
                    requirementLinks={requirementLinksManager.model.filter(item => item.requirementAreaId !== model.id)}
                    areas={areaManager.model.filter(item => item.id !== model.id)}
                    addLink={linkRequirement}
                    />
            </ConditionalFragment>
        </>
        );
};
