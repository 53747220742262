import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RequirementSchoolPhaseCreateInput } from '../generated/globalTypes';
import { createRequirementSchoolPhaseMutation, createRequirementSchoolPhaseMutationVariables } from '../generated/createRequirementSchoolPhaseMutation';
import { requirementSchoolPhaseFieldsFragment } from '../models/RequirementSchoolPhase';

/**
 * Get a callback to create a RequirementSchoolPhase in the store.
 */
export function useCreateRequirementSchoolPhaseCallback(): [(model: RequirementSchoolPhaseCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createRequirementSchoolPhaseMutation, createRequirementSchoolPhaseMutationVariables>(
        gql`
            mutation createRequirementSchoolPhaseMutation ($model: RequirementSchoolPhaseCreateInput!) {
                createRequirementSchoolPhase(model: $model) {
                    ...requirementSchoolPhaseFields
                }
            }

            ${requirementSchoolPhaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: RequirementSchoolPhaseCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
