import { useServices } from 'inject-typesafe-react';
import { AppServices } from '../../../configure/configureServices';
import { useAsyncCallback } from 'react-use-async-callback';
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { Blob } from '../models/Blob';

/**
 * Get a callback to upload a data: url into a new blob.
 */
export function useUploadBlobFromBlobObjectCallback(): [(filename: string, blob: globalThis.Blob) => Promise<Blob | null>, AsyncActionStatus] {
    const blobUploadService = useServices((services: AppServices) => services.blobUploadService());

    const [action, status] = useAsyncCallback(async (filename: string, blob: globalThis.Blob) => {
        // Upload the file into a blob.
        const ret = await blobUploadService.uploadBlobObject(filename, blob);
        return ret;
    }, [blobUploadService]);

    return [action, status];
}
