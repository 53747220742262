import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RequirementsReleaseUpdateInput } from '../generated/globalTypes';
import { updateRequirementsReleaseMutation, updateRequirementsReleaseMutationVariables } from '../generated/updateRequirementsReleaseMutation';
import { requirementsReleaseFieldsFragment } from '../models/RequirementsRelease';

/**
 * Get a callback to update a RequirementsRelease in the store.
 */
export function useUpdateRequirementsReleaseCallback(): [(id: string, model: RequirementsReleaseUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateRequirementsReleaseMutation, updateRequirementsReleaseMutationVariables>(
        gql`
            mutation updateRequirementsReleaseMutation ($model: RequirementsReleaseUpdateInput!) {
                updateRequirementsRelease(model: $model) {
                    ...requirementsReleaseFields
                }
            }

            ${requirementsReleaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: RequirementsReleaseUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
