import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { MATOverview } from "./MATOverview";
import { MATNavigation } from "./MATNavigation";

export const matOverviewRoutes: Array<RouteEntry> = [
    { path: '/mat-overview', exact: true, component: MATOverview, authorize: true, requireRole: IdentityRoles.MATUser },
    { path: '/mat-overview/:schoolGroupId', exact: true, component: MATOverview, authorize: true, requireRole: IdentityRoles.MATUser },
];

export const matSidebarRoutes: Array<RouteEntry> = [
    { path: '/mat-overview', component: MATNavigation, authorize: true, requireRole: IdentityRoles.MATUser },
];
