import * as React from 'react';
import { Document, Page, Text, View, Image, Link } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { schoolCertificateQuery_model } from '../../api/main/generated/schoolCertificateQuery';
import { TFunction } from 'i18next';

export interface CertificatePdfProps {
    model: schoolCertificateQuery_model,
    qrcodeUrl: string,
    t: TFunction,
}

/**
 * PDF of a school's award certificate.
 */
export const CertificatePdf = (props: CertificatePdfProps) => {
    const {
        model,
        qrcodeUrl,
        t,
    } = props;

    // Generate some URLs we need.
    const baseRoute = `${window.location.protocol}//${window.location.host}`;
    const webBadgeImageUrl = `${baseRoute}/api/webBadge/image/${model?.id ?? ''}`;
    const certificateUrl = `${baseRoute}/certificate/${model?.id ?? ''}`;

    //// Child component to show an award level badge for an area.
    //const AreaAwardBadge = (props: { awardLevel: string }) => {
    //    const { awardLevel } = props;

    //    if (!awardLevel) {
    //        return (<></>);
    //    }

    //    const imageUrl = `${baseRoute}/img/reports/areaCertificateBadge/Award_${awardLevel}.png`;
    //    return (
    //        <Image src={imageUrl} />
    //    );
    //};

    
    // Build the actual report.
    return (
        <Document>
            <Page size="A4" style={Styles.page}>
                <View style={Styles.body}>
                    <View style={{ ...Styles.p, width: '100%' }}>
                        <Image src={webBadgeImageUrl} />
                    </View>

                    <View>
                        <Text style={{ ...Styles.h1, fontSize: 30, textAlign: 'center', }}>
                            <Text>
                                {model.schoolName}
                            </Text>
                        </Text>
                    </View>

                    {/*<View style={Styles.table}>*/}
                    {/*    <View style={Styles.tbody}>*/}
                    {/*        {*/}
                    {/*            model.areas?.map(item => {*/}
                    {/*                return (*/}
                    {/*                    <View key={item.id} style={Styles.tr}>*/}
                    {/*                        <View style={{ ...Styles.td, width: '70%' }}>*/}
                    {/*                            <Text>{item.name}</Text>*/}
                    {/*                        </View>*/}
                    {/*                        <View style={{ ...Styles.td, width: '30%', textAlign: 'right' }}>*/}
                    {/*                            <AreaAwardBadge awardLevel={item.awardLevel} />*/}
                    {/*                        </View>*/}
                    {/*                    </View>*/}
                    {/*                );*/}
                    {/*            })}*/}
                    {/*    </View>*/}
                    {/*</View>*/}

                    <View style={{ ...Styles.row, marginTop: 30, }}>
                        <View style={{ ...Styles.col, width: '30%' }}>
                            <Image src={qrcodeUrl} />
                        </View>
                        <View style={{ ...Styles.col, width: '70%', paddingTop: 84, }}>
                            <Text>
                                {t('certificatePdf.qrcode.hint', 'You can review The Compliance Standard award for {{schoolName}} along with a breakdown of compliance by area using this QR code or by visiting:', { schoolName: model?.schoolName })}
                            </Text>
                            <Text>
                                <Link src={certificateUrl}>
                                    {certificateUrl}
                                </Link>
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

