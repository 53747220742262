import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { RequirementsReleaseCreateInput } from '../generated/globalTypes';
import { createRequirementsReleaseMutation, createRequirementsReleaseMutationVariables } from '../generated/createRequirementsReleaseMutation';
import { requirementsReleaseFieldsFragment } from '../models/RequirementsRelease';

/**
 * Get a callback to create a RequirementsRelease in the store.
 */
export function useCreateRequirementsReleaseCallback(): [(model: RequirementsReleaseCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createRequirementsReleaseMutation, createRequirementsReleaseMutationVariables>(
        gql`
            mutation createRequirementsReleaseMutation ($model: RequirementsReleaseCreateInput!) {
                createRequirementsRelease(model: $model) {
                    ...requirementsReleaseFields
                }
            }

            ${requirementsReleaseFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: RequirementsReleaseCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
