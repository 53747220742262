import gql from "graphql-tag";
import { schoolAwardFields } from "../generated/schoolAwardFields";
import { Guid } from "guid-string";
import moment from "moment";

export const schoolAwardFieldsFragment = gql`
    fragment schoolAwardFields on SchoolAward {
        id
        schoolId
        claimedOnDate
        expiresOnDate
        standardRating
        archived
    }
`;


export type SchoolAward = Omit<schoolAwardFields, '__Awardname'>;

export const schoolAwardDefaultValues = (): Partial<SchoolAward> => {
    const now = moment();

    return {
        id: Guid.newGuid(),
        schoolId: 0,
        claimedOnDate: now,
        expiresOnDate: moment(now).add(1, 'year'),
        standardRating: '',
        archived: false,
    };
};
