import './schoolSummaryCard.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col, CardHeader, Collapse, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleState } from 'use-toggle-state';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LinkContainer } from 'react-router-bootstrap';
import { CurrentAward } from '../schoolCompliance/useCalculateCurrentAward/useCalculateCurrentAward';
import { ComplianceProgressBarPreCalculated } from '../schoolCompliance/ComplianceProgressBarPreCalculated';

export interface SchoolSummaryCardProps {
    schoolId: string,
    schoolName: string,

    currentAward: CurrentAward | null | undefined,

    hasLicence: boolean,
}

/**
 * Card component that lists all area and a schools compliance to it.
 * @param props
 */
export const SchoolSummaryCard = (props: SchoolSummaryCardProps) => {
    const {
        schoolId,
        schoolName,
        currentAward,
        hasLicence,
    } = props;
    const { t } = useTranslation();

    // Allow ourselves to be collapsed.
    const [isOpen, toggleOpen] = useToggleState(false);

    return (
        <Card className="school-summary-card">
            <CardHeader className="school-summary-card-header" style={{ cursor: 'pointer' }} onClick={() => toggleOpen()}>
                <Row>
                    <Col>
                        {schoolName}
                    </Col>
                    <Col>
                        {
                            currentAward ? (
                                <ComplianceProgressBarPreCalculated
                                    currentAward={currentAward}
                                />
                            ): null
                        }
                    </Col>
                    <Col xs="auto">
                        <FontAwesomeIcon icon={isOpen ? 'caret-up' : 'caret-down'} />
                        <span className="sr-only">{t('common.seeMore', 'See more')}</span>
                    </Col>
                </Row>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <ConditionalFragment showIf={isOpen}>
                    <CardBody tag="div">
                        <Row>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/overview/${schoolId}`}>
                                    <Button color="primary" outline className="school-summary-action-button" disabled={!hasLicence}>
                                        <FontAwesomeIcon icon="book-reader" />
                                        <> </>{t('schoolSummaryCard.viewSchoolCompliance', 'View compliance details')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/action-plan/${schoolId}`}>
                                    <Button color="primary" outline className="school-summary-action-button" disabled={!hasLicence}>
                                        <FontAwesomeIcon icon="tasks" />
                                        <> </>{t('schoolSummaryCard.viewSchoolActions', 'View action plan')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                            <Col xs={12} md={4}>
                                <LinkContainer to={`/awards/${schoolId}`}>
                                    <Button color="primary" outline className="school-summary-action-button" disabled={!hasLicence}>
                                        <FontAwesomeIcon icon="award" />
                                        <> </>{t('schoolSummaryCard.viewSchoolActions', 'View awards')}
                                    </Button>
                                </LinkContainer>
                            </Col>
                        </Row>
                        <ConditionalFragment showIf={!hasLicence}>
                            <div className="text-muted mt-2">
                                This school does not have a licence to use compliance monitor. To add a licence for this school please contact us by emailing <a href={`mailto:cody.white@hub4leaders.co.uk?subject=Monitoring%20Reporting%20for%20${encodeURIComponent(schoolName ?? 'school')}`}>cody.white@hub4leaders.co.uk</a>.
                                {/*t('schoolSummaryCard.notLicensed', 'This school does not have a licence to use compliance monitor.  To add a licence for this school please contact us.')*/}
                            </div>
                        </ConditionalFragment>
                    </CardBody>
                </ConditionalFragment>
            </Collapse>
        </Card>
        );
};
