import gql from "graphql-tag";
import { requirementFields } from "../generated/requirementFields";
import { requirementSchoolPhaseFieldsFragment } from "./RequirementSchoolPhase";
import { requirementSchoolTypeFieldsFragment } from "./RequirementSchoolType";

export const requirementFieldsFragment = gql`
    fragment requirementFields on Requirement {
        id
        name
        descriptionHtml
        archived
        versionDate
        originKey
        isStronglyRecommended
        sourcesHtml
        policyManagerId
        policyManagerType
        requiresReview
        requiresReviewDescriptionHtml
        requiresReviewInDefaultMonths
        notesHtml
        allowEvidenceUpload
        standardRating
        videoId

        appliesToSchoolPhases {
            ...requirementSchoolPhaseFields
        }

        appliesToSchoolTypes {
            ...requirementSchoolTypeFields
        }
    }

    ${requirementSchoolPhaseFieldsFragment}
    ${requirementSchoolTypeFieldsFragment}
`;


export type Requirement = Omit<requirementFields, '__typename' | 'schoolBusLinks' | 'appliesToSchoolPhases' | 'appliesToSchoolTypes'>;
