import gql from "graphql-tag";
import { schoolGroupTypeFields } from "../generated/schoolGroupTypeFields";

export const schoolGroupTypeFieldsFragment = gql`
    fragment schoolGroupTypeFields on SchoolGroupType {
        id
        schoolGroupTypeName
    }
`;


export type SchoolGroupType = Omit<schoolGroupTypeFields, '__typename'>;
