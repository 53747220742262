import * as React from 'react';
import { SchoolTypeCreateInput, SchoolTypeUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolTypeCallback } from './useCreateSchoolTypeCallback';
import { useUpdateSchoolTypeCallback } from './useUpdateSchoolTypeCallback';
import { SchoolType } from '../models/SchoolType';

/**
 * Get a callback to save a SchoolType in the store using either a create or update.
 */
export function useSaveSchoolTypeCallback(options: SaveInStoreOptions<SchoolType, string> = {}) {
    const [_create, createStatus] = useCreateSchoolTypeCallback();
    const create = React.useCallback((model: Partial<SchoolType>) => _create(model as SchoolTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolTypeCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolType>) => _update(id, changes as SchoolTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
