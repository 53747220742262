import * as React from "react";
import { Requirement } from "../../../api/main/models/Requirement";
import { SchoolSchoolType } from "../../../api/main/models/SchoolSchoolType";
import { SchoolSchoolPhase } from "../../../api/main/models/SchoolSchoolPhase";
import { schoolOverviewSupportingDataQuery_currentRequirementsRelease } from "../../../api/main/generated/schoolOverviewSupportingDataQuery";
import { filterRequirementsForSchool } from "./filterRequirementsForSchool";

/**
 * Filter the requirements by school type and school phase return the visible requirements.
 */
export function useRequirementsForSchool(currentRequirementsRelease: schoolOverviewSupportingDataQuery_currentRequirementsRelease | null, schoolSchoolTypes: Array<SchoolSchoolType>, schoolSchoolPhases: Array<SchoolSchoolPhase>): Array<Requirement> {
    const ret = React.useMemo(() => {
        if (!currentRequirementsRelease) {
            return [];
        }

        // Extract all requirements from the currentRequirementsRelease.
        const allRequirements = currentRequirementsRelease.requirements
            ?.map(item => item.requirement)
            ?.filter(item => !!item) ?? [];

        return filterRequirementsForSchool(allRequirements, schoolSchoolTypes, schoolSchoolPhases);
    }, [currentRequirementsRelease, schoolSchoolTypes, schoolSchoolPhases]);

    return ret;
}
