import moment, { Moment } from "moment";
import { actionStates } from "../../services/actionStates/actionStates";
import { useMemo } from "react";


/**
 * Returns the colour to use for borders for this action based on its due date and completion status.
 */
export function useActionColor(model: { actionAddedDate: string, actionState: string }) {
    return useMemo(() => getActionColor(model), [model]);
}

/**
 * Returns the colour to use for borders for this action based on its due date and completion status.
 */
export function getActionColor(model: { actionAddedDate: string, actionState: string }) {
    const dateMoment: Moment = moment(model.actionAddedDate);
    const differenceDays = dateMoment.diff(moment(), 'days');

    let ret = 'dark';
    if (actionStates.findById(model.actionState).isCompleted) {
        ret = 'success';
    } else if (differenceDays <= 0) {
        ret = 'danger';
    } else if (differenceDays <= 90) {
        ret = 'warning';
    }

    return ret;
}
