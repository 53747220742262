import * as React from 'react';
import { EditRequirementsRelease } from './EditRequirementsRelease';
import { useDraftOrCurrentRequirementsRelease } from '../../api/main/requirementsReleases/useDraftOrCurrentRequirementsRelease';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useParams } from 'react-router';

/**
 * Edit the current Draft if there is one, or latest published release otherwise.  If there is neither lets create one.
 */
export const EditCurrentRequirementsRelease = () => {
    const { timestamp } = useParams();

    const { data: { model }, isLoading, refresh } = useDraftOrCurrentRequirementsRelease();

    // Sometimes we want the screen to reload itself (including data) without changing route.  We use a timestamp to acheive that.
    const [loadedTimestamp, setLoadedTimestamp] = React.useState<string>(timestamp);
    React.useEffect(() => {
        if (loadedTimestamp !== timestamp) {
            setLoadedTimestamp(timestamp);
            refresh();
        }
    }, [timestamp, refresh, loadedTimestamp]);

    if (!model && isLoading) {
        return (<LoadingIndicator fullWidth />);
    }

    return (
        <EditRequirementsRelease key={`${timestamp}:${model?.id ?? ''}`} id={model?.id} isCreate={!model && !isLoading} />
        );
};
