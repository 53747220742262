import * as React from 'react';

/**
 * React hook for reading and writing a key to local stoage.
 * @param key
 * @param initialValue
*/
export function useLocalStorage<T>(key: string, initialValue: T): [T, React.Dispatch<React.SetStateAction<T>>] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = React.useState<T>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            // Parse stored json or if none return initialValue
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            // If error also return initialValue
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that 
    // persists the new value to localStorage.
    const setValue = React.useCallback((value: any) => {
        try {
            // Allow value to be a function so we have same API as useState
            let valueToStore = value;
            if (value instanceof Function) {
                valueToStore = value(storedValue);
            }

            // Save state
            setStoredValue(valueToStore);

            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            throw error;
        }
    }, [key, storedValue]);

    return [storedValue, setValue];
}
