import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ActionCreateInput } from '../generated/globalTypes';
import { createActionMutation, createActionMutationVariables } from '../generated/createActionMutation';
import { actionFieldsFragment } from '../models/Action';

/**
 * Get a callback to create a Action in the store.
 */
export function useCreateActionCallback(): [(model: ActionCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createActionMutation, createActionMutationVariables>(
        gql`
            mutation createActionMutation ($model: ActionCreateInput!) {
                createAction(model: $model) {
                    ...actionFields
                }
            }

            ${actionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ActionCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
