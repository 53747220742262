import * as React from 'react';
import ReactPDF, { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { Styles } from './Styles';
import { School } from '../../api/main/models/School';
import { RequirementArea } from '../../api/main/models/RequirementArea';
import { Requirement } from '../../api/main/models/Requirement';
import { RequirementsReleaseRequirement } from '../../api/main/models/RequirementsReleaseRequirement';
import { SchoolRequirementCompliance } from '../../api/main/models/SchoolRequirementCompliance';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import striptags from 'striptags';
import { requirementComplianceStates } from '../../services/requirementComplianceStates/requirementComplianceStates';
import { CurrentAward, CurrentAwardArea } from '../../components/schoolCompliance/useCalculateCurrentAward/useCalculateCurrentAward';
import { StandardRating } from '../../api/main/models/constants/StandardRatings';

export interface SchoolReportPdfProps {
    isDetailedReport: boolean,
    school: School | null | undefined,

    areas: Array<RequirementArea> | undefined | null,
    requirements: Array<Requirement> | undefined | null,
    requirementLinks: Array<RequirementsReleaseRequirement> | undefined | null,
    compliances: Array<SchoolRequirementCompliance> | undefined | null,

    currentAward: CurrentAward,
}

/**
 * Executive summary screen for a project, this gives a long report-like breakdown of the project results.
 */
export const SchoolReportPdf = (props: SchoolReportPdfProps) => {
    const {
        isDetailedReport,
        school,
        areas,
        requirements,
        requirementLinks,
        compliances,
        currentAward,
    } = props;


    const baseRoute = `${window.location.protocol}//${window.location.host}`;

    // Work out the best image to show.
    const now = moment();
    let year = now.year() - 2000;
    if (now.month() < 8 /* NOTE: Moment months are zero based so 8 here means September */) {
        year = year - 1;
    }
    const nextYear = year + 1;

    let awardFilePart = '0_TCS_Award_NoAward_RGB_';
    switch (currentAward?.currentLevel) {
        case StandardRating.Bronze:
            awardFilePart = '1_TCS_Award_Bronze_RGB_';
            break;
        case StandardRating.Silver:
            awardFilePart = '2_TCS_Award_Silver_RGB_';
            break;
        case StandardRating.Gold:
            awardFilePart = '3_TCS_Award_Gold_RGB_';
            break;
        default:
            break;
    }

    const webBadgeImageUrl = `${baseRoute}/img/awardImage/${year}-${nextYear}/${awardFilePart}${year}${nextYear}.png`;

    // Child component to show an award level badge for an area.
    const AreaAwardBadge = (props: { awardLevel: string } & Partial<ReactPDF.ImageProps>) => {
        const { awardLevel, ...rest } = props;

        if (!awardLevel) {
            return (<></>);
        }

        const imageUrl = `${baseRoute}/img/reports/areaCertificateBadge/Award_${awardLevel}.png`;
        return (
            <Image src={imageUrl} {...rest} />
        );
    };

    // Child component to show a progress bar.
    const ProgressBar = (props: { currentAwardArea: CurrentAwardArea }) => {
        const { currentAwardArea } = props;

        const showSilver = currentAwardArea?.silverCompliance.totals.notSkipped > 0 && currentAwardArea?.bronzeCompliance.totals.outstanding <= 0;
        const showGold = showSilver && currentAwardArea?.goldCompliance.totals.notSkipped > 0 && currentAwardArea?.silverCompliance.totals.outstanding <= 0;

        const bronzeWidth = showGold ? '34%' : showSilver ? '50%' : '100%';
        const silverWidth = showGold ? '33%' : '50%';
        const goldWidth = '33%';

        return (
            <View style={{ ...Styles.progressBar, }}>
                {/* Bronze */}
                <View style={{ ...Styles.progressBarSection, width: bronzeWidth, backgroundColor: '#f0b26c' }}>
                    <Text>Bronze {currentAwardArea.bronzeCompliance.success} / {currentAwardArea.bronzeCompliance.totals.notSkipped}</Text>
                </View>

                {/* Silver */}
                <ConditionalFragment showIf={showSilver}>
                    <View style={{ ...Styles.progressBarSection, width: silverWidth, backgroundColor: '#bababa' }}>
                        <Text>Silver {currentAwardArea.silverCompliance.success} / {currentAwardArea.silverCompliance.totals.notSkipped}</Text>
                    </View>
                </ConditionalFragment>

                {/* Gold */}
                <ConditionalFragment showIf={showGold}>
                    <View style={{ ...Styles.progressBarSection, width: goldWidth, backgroundColor: '#d0aa3f' }}>
                        <Text>Gold {currentAwardArea.goldCompliance.success} / {currentAwardArea.goldCompliance.totals.notSkipped}</Text>
                    </View>
                </ConditionalFragment>
            </View>
        );
    }

    // Text description of progress.
    const TextDescriptionOfProgress = (props: { myResults: CurrentAward | CurrentAwardArea, scopeText: string }) => {
        const {
            myResults,
            scopeText,
        } = props;
        return (
            <View style={{ fontFamily: 'Poppins', backgroundColor: 'lightgray', borderRadius: '10', fontSize: '12', paddingLeft: '14', paddingRight: '14', marginTop: '2', paddingTop: '2', paddingBottom: '8', }}>
                {/* Current standard text */}
                <Text style={Styles.p}>
                    {
                        !myResults.currentLevel ? (
                            <>
                                {/* No text to show here */}
                            </>
                        ) : myResults.currentLevel === StandardRating.Bronze ?
                            (
                                    <>
                                        {scopeText}, {school?.schoolName ?? 'School'} has met all requirements for the Bronze standard.
                                </>
                            ) : myResults.currentLevel === StandardRating.Silver ?
                                (
                                        <>
                                            {scopeText}, { school?.schoolName ?? 'School' } has met all requirements for the Silver standard.
                                    </>
                                ) : myResults.currentLevel === StandardRating.Gold ?
                                    (
                                            <>
                                                {scopeText}, {school?.schoolName ?? 'School'} has passed the required level of requirements for the Gold standard with {Math.round(myResults?.goldCompliance.totals.percentage).toFixed(0)}% of available Gold standard requirements met.
                                        </>
                                    ) : null
                    }
                </Text>

                {/* Progress to next standard text */}
                <Text style={Styles.p}>
                    {
                        !myResults.currentLevel ? (
                            <>
                                {scopeText}, {school?.schoolName ?? 'School'} has not yet met the Bronze standard but has progressed <Text style={Styles.strong}>{Math.round(myResults.bronzeCompliance.totals.percentage).toFixed(0)}%</Text> towards the award.
                            </>
                        ) : myResults.currentLevel === StandardRating.Bronze && myResults.silverCompliance.totals.percentage > 0 ?
                            (
                                <>
                                        In addition {school?.schoolName ?? 'School'} has progressed <Text style={Styles.strong}>{Math.round(myResults.silverCompliance.totals.percentage).toFixed(0)}%</Text> towards the Silver standard.
                                </>
                            ) : myResults.currentLevel === StandardRating.Silver && myResults.goldCompliance.totals.percentage > 0 ?
                                (
                                    <>
                                            In addition {school?.schoolName ?? 'School'} has progressed <Text style={Styles.strong}>{Math.round(myResults.goldCompliance.totals.percentage).toFixed(0)}%</Text> towards the Gold standard.
                                    </>
                                ) : myResults.currentLevel === StandardRating.Gold ?
                                    (
                                        <>
                                            {/* Nothing to show here */}
                                        </>
                                    ) : null
                    }
                </Text>
            </View>
            );
    };

    // If we don't have a school the report generation failed so just exit.
    if (!school) {
        return (
            <Document>
                <Page size="A4">
                    <Text>PDF could not be generated.</Text>
                </Page>
            </Document>
        );
    }

    // Build the actual report.
    return (
        <Document>
            <Page size="A4" style={Styles.page}>
                <View style={Styles.body}>
                    <View>
                        <Text style={Styles.h1}>
                            <Text>
                                {school.schoolName}
                            </Text>
                        </Text>
                        <Text style={{ ...Styles.h1Small }}>
                            Compliance overview
                        </Text>
                    </View>

                    <Text style={Styles.p}>
                        This report provides a summary the compliance of {school.schoolName} against The Compliance Standard {currentAward.currentLevel ?? 'Bronze'} award using a snapshot taken on {moment().format('L')}.
                    </Text>

                    <View style={{ ...Styles.p, /*height: '130',*/ paddingLeft: 155 }}>
                        <Image src={webBadgeImageUrl} style={{ height: 170, width: 170, }} />
                    </View>

                    <TextDescriptionOfProgress myResults={currentAward} scopeText="Across all areas considered" />

                    <View style={{ ...Styles.table, marginTop: '4', }}>
                        <View style={Styles.thead}>
                            <View style={Styles.trHead}>
                                <View style={{ ...Styles.th, width: '85%' }}>
                                    <Text>Area</Text>
                                </View>
                                <View style={{ ...Styles.th, width: '15%', textAlign: 'right' }}>
                                    <Text></Text>
                                </View>
                            </View>
                        </View>
                        <View style={Styles.tbody}>
                            {
                                areas?.map(item => {
                                    let myCurrentAward = currentAward?.areas?.find(it => it.requirementAreaId === item.id);
                                    if (!myCurrentAward) {
                                        return null;
                                    }
      
                                    return (
                                        <View key={item.originKey} style={Styles.tr}>
                                            <View style={{ ...Styles.td, width: '87%', fontSize: '8pt' }}>
                                                <Text>{item.name}</Text>
                                                <ProgressBar currentAwardArea={myCurrentAward} />
                                            </View>
                                            <View style={{ ...Styles.td, width: '13%', textAlign: 'right' }}>
                                                {
                                                    myCurrentAward.currentLevel ? (
                                                        <AreaAwardBadge awardLevel={myCurrentAward.currentLevel} />
                                                    ): null
                                                }
                                            </View>
                                        </View>
                                    );
                                })}
                        </View>
                    </View>
                </View>
            </Page>

            <ConditionalFragment showIf={isDetailedReport}>
                {
                    areas?.map(area => {
                        const myLinks = requirementLinks?.filter(item => item.requirementAreaId === area.id);
                        const myRequirements = requirements?.filter(item => !!myLinks?.find(link => link.requirementId === item.id));
                        const myCompliances = compliances?.filter(item => !!myRequirements?.find(req => req.originKey === item.requirementOriginKey));

                        const myResults = currentAward.areas?.find(item => item.requirementAreaId === area.id);

                        // Get a list of unmet requirements.
                        const outstandingRequirements = myRequirements?.filter(req => !myCompliances?.find(it => it.requirementOriginKey === req.originKey && (requirementComplianceStates.findById(it.requirementComplianceState).isSkipped || requirementComplianceStates.findById(it.requirementComplianceState).isSuccess)));

                        const nextLevel = !myResults?.currentLevel ? StandardRating.Bronze
                            : myResults?.currentLevel === StandardRating.Bronze ? StandardRating.Silver
                                : myResults?.currentLevel === StandardRating.Silver ? StandardRating.Gold
                                    : StandardRating.Gold;
                        const nextLevelText = !myResults?.currentLevel ? StandardRating.Bronze
                            : myResults?.currentLevel === StandardRating.Bronze ? StandardRating.Silver
                                : myResults?.currentLevel === StandardRating.Silver ? StandardRating.Gold
                                    : `100% ${StandardRating.Gold} compliance`;

                        const outstandingRequirementsForNextLevel = outstandingRequirements?.filter(req => req.standardRating === nextLevel);

                        if (!myResults) {
                            return null;
                        }

                        return (
                            <Page key={area.id} size="A4" style={Styles.page}>
                                <View style={Styles.body}>
                                    <View>
                                        <Text style={Styles.h1}>
                                            <Text>
                                                {area.name}
                                            </Text>
                                        </Text>
                                        <Text style={{ ...Styles.h1Small }}>
                                            {school.schoolName}
                                        </Text>
                                    </View>

                                    <Text style={Styles.p}>
                                        {striptags(area.descriptionHtml)}
                                    </Text>

                                    {
                                    myResults?.currentLevel ? (
                                            <View style={{ ...Styles.p, width: '100%', height: '130', paddingLeft: '35%' }}>
                                                <AreaAwardBadge awardLevel={myResults.currentLevel} style={{ height: 95, width: 200, }} />
                                            </View>
                                        ): null
                                    }

                                    <TextDescriptionOfProgress myResults={myResults} scopeText={`Within ${area.name}`} />


                                    <ConditionalFragment showIf={!!outstandingRequirements?.length}>
                                        <Text style={Styles.blankSpaceBeforeHeadinglessTables}>
                                            <> </>
                                        </Text>
                                        <View style={Styles.table}>
                                            <View style={Styles.thead}>
                                                <View style={Styles.trHead}>
                                                    <View style={{ ...Styles.th, width: '100%' }}>
                                                        <Text>Working to meet the following requirements will enable you to reach {nextLevelText} in this area:</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={Styles.tbody}>
                                                {
                                                    outstandingRequirementsForNextLevel?.map(item => {
                                                        return (
                                                            <View key={item.originKey} style={Styles.tr}>
                                                                <View style={{ ...Styles.td, width: '100%' }}>
                                                                    <Text>{item.name}</Text>
                                                                </View>
                                                            </View>
                                                        );
                                                    })}
                                            </View>
                                        </View>
                                    </ConditionalFragment>
                                </View>
                            </Page>
                        );
                    })
                }
            </ConditionalFragment>
        </Document>
    );
};


