import * as React from 'react';
import { SchoolRequirementComplianceCreateInput, SchoolRequirementComplianceUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolRequirementComplianceCallback } from './useCreateSchoolRequirementComplianceCallback';
import { useUpdateSchoolRequirementComplianceCallback } from './useUpdateSchoolRequirementComplianceCallback';
import { SchoolRequirementCompliance } from '../models/SchoolRequirementCompliance';

/**
 * Get a callback to save a SchoolRequirementCompliance in the store using either a create or update.
 */
export function useSaveSchoolRequirementComplianceCallback(options: SaveInStoreOptions<SchoolRequirementCompliance, string> = {}) {
    const [_create, createStatus] = useCreateSchoolRequirementComplianceCallback();
    const create = React.useCallback((model: Partial<SchoolRequirementCompliance>) => _create(model as SchoolRequirementComplianceCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolRequirementComplianceCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolRequirementCompliance>) => _update(id, changes as SchoolRequirementComplianceUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
