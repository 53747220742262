import { GetHeaderDataType } from "./GetHeaderDataType";

export const getHeaderDataMock: GetHeaderDataType =
{
    "LoggedIn": true,
    "Name": "Dummy - Dont delete",
    "UserType": 3,
    "IsMaster": true,
    "MyAccount": [
        {
            "Name": "Account Details",
            "Url": "/account",
            "UseRouterLink": true,
            "ClassList": ""
        },
        {
            "Name": "School Documents",
            "Url": "/schooldocuments",
            "UseRouterLink": true,
            "ClassList": "documents-to-action"
        },
        {
            "Name": "Manage Users",
            "Url": "/users/overview",
            "UseRouterLink": true,
            "ClassList": ""
        },
        {
            "Name": "Help Centre",
            "Url": "https://theschoolbus.tawk.help/",
            "UseRouterLink": false,
            "ClassList": ""
        }
    ],
    "NFH": [
        {
            "Name": "Ask a question",
            "Url": "/nfh/suggest/newquestion",
            "UseRouterLink": true,
            "ClassList": ""
        },
        {
            "Name": "Suggest a resource",
            "Url": "/nfh/suggest/newsuggestion",
            "UseRouterLink": true,
            "ClassList": ""
        },
        {
            "Name": "Submit your feedback",
            "Url": "/ideasandfeedback",
            "UseRouterLink": true,
            "ClassList": ""
        },
        {
            "Name": "Contact us",
            "Url": "http://hub4leaders.co.uk/contact/contact-us/",
            "UseRouterLink": false,
            "ClassList": ""
        },
        {
            "Name": "Help centre",
            "Url": "/helpcentre",
            "UseRouterLink": true,
            "ClassList": ""
        }
    ],
    "SectorUpdates": [
        {
            "LinkId": 1,
            "LinkTypeId": 1,
            "LinkType": null,
            "Url": "/news",
            "LinkText": "News roundup"
        },
        {
            "LinkId": 2,
            "LinkTypeId": 1,
            "LinkType": null,
            "Url": "/article/education-policy-digest-your-60second-update/7237",
            "LinkText": "The Compliance Digest"
        },
        {
            "LinkId": 3,
            "LinkTypeId": 1,
            "LinkType": null,
            "Url": "/article/this-week-on-theschoolbus/3551",
            "LinkText": "This week on TheSchoolBus"
        },
        {
            "LinkId": 4,
            "LinkTypeId": 1,
            "LinkType": null,
            "Url": "/dailyemailupdates",
            "LinkText": "Sign up to daily updates"
        }
    ],
    "Policies": [
        {
            "LinkId": 5,
            "LinkTypeId": 2,
            "LinkType": null,
            "Url": "/topic/statutory-policies-and-documents/39",
            "LinkText": "Statutory policies"
        },
        {
            "LinkId": 6,
            "LinkTypeId": 2,
            "LinkType": null,
            "Url": "/topic/model-policies-and-documents-good-practice/38",
            "LinkText": "Good practice policies"
        },
        {
            "LinkId": 7,
            "LinkTypeId": 2,
            "LinkType": null,
            "Url": "/article/statutory-policy-toolkit/8392",
            "LinkText": "Statutory policy toolkit"
        }
    ],
    "MyDocumentsToAction": 0,
    "HeaderToolbars": [
        {
            "HeaderToolbarId": 0,
            "DisplayText": "Categories",
            "DisplayOrder": 0,
            "IconRef": "list",
            "LinkUrl": null,
            "IsExternalLink": false,
            "Target": null,
            "FullWidthVisible": false,
            "HeaderToolbarItems": [
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "School Improvement & Inspection",
                    "LinkUrl": "/category/school-improvement-and-inspection",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 1
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Governance",
                    "LinkUrl": "/category/governance",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 2
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Teaching & Learning",
                    "LinkUrl": "/category/teaching-and-learning",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 3
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Safeguarding",
                    "LinkUrl": "/category/safeguarding",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 4
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Admin & Finance",
                    "LinkUrl": "/category/admin-and-finance",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 5
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Staff & Recruitment",
                    "LinkUrl": "/category/staff-and-recruitment",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 6
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Facilities, Health & Safety",
                    "LinkUrl": "/category/facilities-health-and-safety",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 7
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Trusts",
                    "LinkUrl": "/category/trusts",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": null,
                    "DisplayOrder": 8
                }
            ]
        },
        {
            "HeaderToolbarId": 1,
            "DisplayText": "Updates",
            "DisplayOrder": 1,
            "IconRef": "update",
            "LinkUrl": null,
            "IsExternalLink": false,
            "Target": null,
            "FullWidthVisible": true,
            "HeaderToolbarItems": [
                {
                    "HeaderToolbarItemId": 1,
                    "HeaderToolbarId": 1,
                    "Title": "New & Updated",
                    "LinkUrl": "/newandupdated",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Discover the latest resources and important guidance updates",
                    "DisplayOrder": 1
                },
                {
                    "HeaderToolbarItemId": 2,
                    "HeaderToolbarId": 1,
                    "Title": "Daily Briefing",
                    "LinkUrl": "/article/daily-briefing/9678",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Quickly digest today’s need-to-know information",
                    "DisplayOrder": 2
                },
                {
                    "HeaderToolbarItemId": 3,
                    "HeaderToolbarId": 1,
                    "Title": "Weekly Briefing",
                    "LinkUrl": "/article/this-week-on-theschoolbus/3551",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Read our indispensable guide to the latest news, resources and updates",
                    "DisplayOrder": 3
                },
                {
                    "HeaderToolbarItemId": 4,
                    "HeaderToolbarId": 1,
                    "Title": "News Roundup",
                    "LinkUrl": "/news",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Read the latest Education news",
                    "DisplayOrder": 4
                }
            ]
        },
        {
            "HeaderToolbarId": 2,
            "DisplayText": "Library",
            "DisplayOrder": 2,
            "IconRef": "view_headline",
            "LinkUrl": null,
            "IsExternalLink": false,
            "Target": null,
            "FullWidthVisible": true,
            "HeaderToolbarItems": [
                {
                    "HeaderToolbarItemId": 5,
                    "HeaderToolbarId": 2,
                    "Title": "Up-to-speed Summaries",
                    "LinkUrl": "/articlelist?articleTypeId=18",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Get up-to-speed on Education changes instantly",
                    "DisplayOrder": 1
                },
                {
                    "HeaderToolbarItemId": 6,
                    "HeaderToolbarId": 2,
                    "Title": "Your Questions Answered",
                    "LinkUrl": "/articlelist?articleTypeId=7",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "View experts’ answers to frequently asked questions about important topics",
                    "DisplayOrder": 2
                },
                {
                    "HeaderToolbarItemId": 7,
                    "HeaderToolbarId": 2,
                    "Title": "Guidance",
                    "LinkUrl": "/articlelist?articleTypeId=5",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Learn from experts in all aspects of school leadership and compliance",
                    "DisplayOrder": 3
                },
                {
                    "HeaderToolbarItemId": 8,
                    "HeaderToolbarId": 2,
                    "Title": "Policies",
                    "LinkUrl": "/articlelist?articleTypeId=6",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Fast-track compliance with original and compliant model policies",
                    "DisplayOrder": 4
                },
                {
                    "HeaderToolbarItemId": 9,
                    "HeaderToolbarId": 2,
                    "Title": "Templates",
                    "LinkUrl": "/articlelist?articleTypeId=8",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Access time saving tools to respond rapidly to ever-changing requirements",
                    "DisplayOrder": 5
                },
                {
                    "HeaderToolbarItemId": 10,
                    "HeaderToolbarId": 2,
                    "Title": "Risk Assessments",
                    "LinkUrl": "/articlelist?articleTypeId=20",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Reduce risk with easily personalised model risk assessments",
                    "DisplayOrder": 6
                },
                {
                    "HeaderToolbarItemId": 11,
                    "HeaderToolbarId": 2,
                    "Title": "Resource Packs",
                    "LinkUrl": "/feature/resource-packs",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Explore expertly curated resources addressing important topics",
                    "DisplayOrder": 7
                }
            ]
        },
        {
            "HeaderToolbarId": 3,
            "DisplayText": "Tools",
            "DisplayOrder": 3,
            "IconRef": "construction",
            "LinkUrl": null,
            "IsExternalLink": false,
            "Target": null,
            "FullWidthVisible": true,
            "HeaderToolbarItems": [
                {
                    "HeaderToolbarItemId": 12,
                    "HeaderToolbarId": 3,
                    "Title": "Alert Manager (Watchlist)",
                    "LinkUrl": "/watchlist",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Track the latest updates to your favourite resources",
                    "DisplayOrder": 1
                },
                {
                    "HeaderToolbarItemId": 13,
                    "HeaderToolbarId": 3,
                    "Title": "Policy Manager",
                    "LinkUrl": "/compliancemanager",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Approve, distribute, track and monitor your policies",
                    "DisplayOrder": 2
                },
                {
                    "HeaderToolbarItemId": 14,
                    "HeaderToolbarId": 3,
                    "Title": "ASK",
                    "LinkUrl": "/ask",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Ask a question. Suggest a resource. Kickstart something special.",
                    "DisplayOrder": 3
                },
                {
                    "HeaderToolbarItemId": 15,
                    "HeaderToolbarId": 3,
                    "Title": "30-Minute Work Week",
                    "LinkUrl": "/workweek",
                    "IsExternalLink": false,
                    "Target": null,
                    "ItemDescription": "Resolve crucial compliance tasks too important to miss",
                    "DisplayOrder": 4
                },
                {
                    "HeaderToolbarItemId": 16,
                    "HeaderToolbarId": 3,
                    "Title": "TheSchoolYear",
                    "LinkUrl": "https://theschoolyear.co.uk/account/register",
                    "IsExternalLink": true,
                    "Target": null,
                    "ItemDescription": "View every key date, event and deadline in Education",
                    "DisplayOrder": 5
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Compliance Coach",
                    "LinkUrl": "https://compliancecoach.theschoolbus-test.co.uk",
                    "IsExternalLink": true,
                    "Target": null,
                    "ItemDescription": "Learn compliance essentials via expert-led accredited courses",
                    "DisplayOrder": 6
                }
            ]
        },
        {
            "HeaderToolbarId": 0,
            "DisplayText": "Compliance Standard",
            "DisplayOrder": 4,
            "IconRef": "verified",
            "LinkUrl": null,
            "IsExternalLink": false,
            "Target": null,
            "FullWidthVisible": true,
            "HeaderToolbarItems": [
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Overview",
                    "LinkUrl": "https://thecompliancestandard.theschoolbus-test.co.uk/overview",
                    "IsExternalLink": true,
                    "Target": "_self",
                    "ItemDescription": "Track your compliance levels across 12 critical areas",
                    "DisplayOrder": 1
                },
                {
                    "HeaderToolbarItemId": 0,
                    "HeaderToolbarId": 0,
                    "HeaderToolbar": null,
                    "Title": "Awards",
                    "LinkUrl": "https://thecompliancestandard.theschoolbus-test.co.uk/awards",
                    "IsExternalLink": true,
                    "Target": "_self",
                    "ItemDescription": "View, download and share your compliance award",
                    "DisplayOrder": 2
                }
            ]
        }
    ],
    "HeaderCategories": [
        {
            "HeaderCategoryId": 1,
            "Name": "School Improvement & Inspection",
            "DisplayOrder": 1,
            "Url": "/school-improvement-and-inspection",
            "ColourHex": "#ab80f7"
        },
        {
            "HeaderCategoryId": 2,
            "Name": "Governance",
            "DisplayOrder": 2,
            "Url": "/governance",
            "ColourHex": "#00bcd4"
        },
        {
            "HeaderCategoryId": 3,
            "Name": "Teaching & Learning",
            "DisplayOrder": 3,
            "Url": "/teaching-and-learning",
            "ColourHex": "#38b09d"
        },
        {
            "HeaderCategoryId": 4,
            "Name": "Safeguarding",
            "DisplayOrder": 4,
            "Url": "/safeguarding",
            "ColourHex": "#de7e2d"
        },
        {
            "HeaderCategoryId": 5,
            "Name": "Admin & Finance",
            "DisplayOrder": 5,
            "Url": "/admin-and-finance",
            "ColourHex": "#db0e50"
        },
        {
            "HeaderCategoryId": 6,
            "Name": "Staff & Recruitment",
            "DisplayOrder": 6,
            "Url": "/staff-and-recruitment",
            "ColourHex": "#178572"
        },
        {
            "HeaderCategoryId": 7,
            "Name": "Facilities, Health & Safety",
            "DisplayOrder": 7,
            "Url": "/facilities-health-and-safety",
            "ColourHex": "#f73939"
        },
        {
            "HeaderCategoryId": 8,
            "Name": "Trusts",
            "DisplayOrder": 8,
            "Url": "/trusts",
            "ColourHex": "#bb71d6"
        }
    ]
};
