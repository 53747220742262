import * as React from 'react';
import Chart from 'react-apexcharts';

export interface DonutChartData {
    name: string,
    value: number,
}

export interface DonutChartProps {
    title: string,
    model: Array<DonutChartData>,
    chartHeight?: number,
    colors?: Array<string>,
}

/**
 * Generic reusable donut chart for showing stats by school.
 * @param props
 */
export const DonutChart = (props: DonutChartProps) => {
    const {
        title,
        model,
        chartHeight,
        colors,
    } = props;

    const options = React.useMemo(() => ({
        legend: {
            show: false,
        },

        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            label: title || 'Total'
                        }
                    }
                }
            }
        },

        dataLabels: {
            enabled: false,
        },

        colors: colors,

        labels: model.map(item => item.name),
    }), [model, title, colors]);

    var series = React.useMemo(() => model.map(item => item.value), [model]);
    
    // Show the chart.
    return (
        <Chart options = { options } series = { series } type = "donut" height = { chartHeight ?? 400 } />
    );
};

