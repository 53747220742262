import * as React from 'react';
import { VideoCreateInput, VideoUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateVideoCallback } from './useCreateVideoCallback';
import { useUpdateVideoCallback } from './useUpdateVideoCallback';
import { Video } from '../models/Video';

/**
 * Get a callback to save a Video in the store using either a create or update.
 */
export function useSaveVideoCallback(options: SaveInStoreOptions<Video, string> = {}) {
    const [_create, createStatus] = useCreateVideoCallback();
    const create = React.useCallback((model: Partial<Video>) => _create(model as VideoCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateVideoCallback();
    const update = React.useCallback((id: string, changes: Partial<Video>) => _update(id, changes as VideoUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
