import * as React from 'react';
import { ActionCreateInput, ActionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateActionCallback } from './useCreateActionCallback';
import { useUpdateActionCallback } from './useUpdateActionCallback';
import { Action } from '../models/Action';

/**
 * Get a callback to save a Action in the store using either a create or update.
 */
export function useSaveActionCallback(options: SaveInStoreOptions<Action, string> = {}) {
    const [_create, createStatus] = useCreateActionCallback();
    const create = React.useCallback((model: Partial<Action>) => _create(model as ActionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateActionCallback();
    const update = React.useCallback((id: string, changes: Partial<Action>) => _update(id, changes as ActionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
