import { User } from "../api/main/models/User";

/**
 * Returns a link to the action provided.
 */
export function getActionUrl(actionId: string | null, user: User | undefined | null, schoolId: string | undefined | null): string {
    let queryString = '';
    if (!!actionId) {
        queryString = `?action=${encodeURIComponent(actionId ?? '')}`;
    }

    // If this is the user's own school, use the route that doesn't require MAT permissions.
    if (schoolId === user?.schoolId?.toString()) {
        return `/action-plan/${queryString}`;
    }

    // Otherwise use the route that includes the school.
    return `/action-plan/${schoolId ?? ''}/${queryString}`;
}