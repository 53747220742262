import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { adminReportsDataQuery } from '../generated/adminReportsDataQuery';

/**
 * Get a the supporting data needed by the ReportsOverview component.
 * @param options
 */
export function useAdminReportsData(options: AsyncLoadOptions = {}): AsyncLoadResult<adminReportsDataQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<adminReportsDataQuery>(
        gql`
        query adminReportsDataQuery {
            reports {
                requirementsCompletedBySchool {
                    id
                    schoolId
                    requirementId
                    school {
                        id
                        schoolName
                    }
                    requirement {
                        id
                        name
                    }
                }

                areasCompletedBySchool {
                    id
                    schoolId
                    requirementAreaId
                    school {
                        id
                        schoolName
                    }
                    area {
                        id
                        name
                    }
                }

                actionsCreatedBySchool {
                    id
                    schoolId
                    actionId
                    school {
                        id
                        schoolName
                    }
                    action {
                        id
                        name
                        actionState
                    }
                }

                awardsClaimedBySchool {
                    id
                    schoolId
                    standardRating
                    school {
                        id
                        schoolName
                    }
                }
            }
        }`,
        {
            variables: {
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
