import React from "react";
import { requirementComplianceStates } from "../../services/requirementComplianceStates/requirementComplianceStates";
import { SchoolRequirementCompliance } from "../../api/main/models/SchoolRequirementCompliance";
import { Requirement } from "../../api/main/models/Requirement";
import { Guid } from 'guid-string';
import { ModelArrayChanges } from "../../shared/useChanges";
import moment from "moment";


export interface ChangeComplianceOptions {
    complianceManager: ModelArrayChanges<SchoolRequirementCompliance, string>,
    schoolId: number,
}

/**
 * Method to change compliance details.
 *
 * This method is shared between SchoolAreaCompliance and SchoolOverview and so has been abstracted into its own hook to avoid duplication of
 * business logic.
 * 
 * @param options
 */
export function useChangeCompliance(options: ChangeComplianceOptions) {
    const {
        complianceManager,
        schoolId,
    } = options;

    const changeCompliance = React.useCallback((requirement: Requirement, changes: Partial<SchoolRequirementCompliance>) => {
        // Find the record for this requirement.
        let existing = complianceManager.model.find(item => item.requirementOriginKey === requirement.originKey && !item.archived);

        // If we didn't find an entry, create one.
        if (!existing) {
            const newEntry: SchoolRequirementCompliance = {
                id: Guid.newGuid(),
                schoolId: schoolId,
                requirementOriginKey: requirement.originKey,
                date: moment().toISOString(),
                archived: false,
                isImportFromPolicyManager: false,
                notesHtml: '',
                requirementComplianceState: requirementComplianceStates.unanswered.id,

                // Include the changes.
                ...changes,
            };
            complianceManager.addFor(newEntry);
        } else {
            // Apply the changes.
            complianceManager.changeFor(existing.id, changes);
        }
    }, [complianceManager, schoolId]);

    return changeCompliance;
}
