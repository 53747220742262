import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import { useAuthenticatedState } from '../../api/api-authorization/useAuthenticatedState';
import { useCurrentUserRoles } from '../../api/account/useCurrentUserRoles';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { openSchoolBusLink } from '../../utilities/openSchoolBusLink';

export interface AuthorizeRouteProps extends RouteProps {
    requireRole?: string | Array<string>,
}

/**
 * Route component that will check for authorization before showing its component.
 * 
 * NOTE this was originally based on AuthorizeRoute from Microsoft's template but converted to typescript and React hooks and to support more than just the component prop.
 * @param props
 */
export const AuthorizeRoute = (props: AuthorizeRouteProps) => {
    const { isAuthenticated } = useAuthenticatedState();
    const { data: { roles: usersRoles } } = useCurrentUserRoles();
    const [ hasRequiredRole, setHasRequiredRole ] = React.useState<boolean | null>(); 

    // Load the roles only if we need to.
    React.useEffect(() => {
        if (props.requireRole && !isNullOrUndefined(usersRoles)) {
            const requireRoles = [props.requireRole].flat(2);
            let hasRole = false;
            for (let roleToCheck of requireRoles) {
                if (usersRoles.find(it => it === roleToCheck)) {
                    hasRole = true;
                    break;
                }
            }
            setHasRequiredRole(hasRole);
        }
    }, [props.requireRole, usersRoles, setHasRequiredRole, isAuthenticated]);

    // Render the UI
    //
    const { component: Component, render, children, ...rest } = props;

    // Show nothing until we have a defined authenticated state.
    if (isNullOrUndefined(isAuthenticated)) {
        return (<></>);
    }
    
    // If we need to check a role, but can't yet return nothing.
    if (props.requireRole && isNullOrUndefined(usersRoles)) {
        return (<></>);
    }

    // If we don't have the role we require, show text saying so.
    if (hasRequiredRole === false) {
        return (<>Sorry you do not have permission to access this area.</>);
    }

    // If the user doesn't have a IsMaster or SB_Policy_Manager role then we don't give them any access to TCS so redirect them back to the main TSB homepage.
    if (isAuthenticated && !isNullOrUndefined(usersRoles) && !usersRoles.find(role => role === IdentityRoles.IsMaster || role === IdentityRoles.SB_Policy_Manager)) {
        return (
            <Route {...rest}
                render={(props) => {
                    return (<RedirectToTSB />);
                }} />
        );
    }


    // If we get here we are happy, so return the wrapped component.
    return (
        <Route {...rest}
            render={(props) => {
                if (isAuthenticated) {
                    if (Component) {
                        return <Component {...props} />;
                    } else if (render) {
                        return render(props);
                    } else {
                        return children;
                    }
                } else {
                    const redirectUrl = `/authentication/login?returnUrl=${encodeURIComponent(window.location.href)}`
                    return <Redirect to={redirectUrl} />
                }
            }} />
    );
};

// Internal component rendered in the route if we need to redirect the user.
const RedirectToTSB = () => {
    React.useEffect(() => {
        const timeout = setTimeout(() => openSchoolBusLink('/'), 2000);
        return () => clearTimeout(timeout);
    }, []);
    return (<>Sorry you do not have permission to access this area.  We will now redirect you back to your home page...</>);
};
