import * as React from 'react';
import { RequirementSchoolBusLink } from '../../api/main/models/RequirementSchoolBusLink';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { ValidatedInput } from 'pojo-validator-reactstrap/dist/lib/commonjs';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface EditRequirementSchoolBusLinkProps {
    model: RequirementSchoolBusLink,
    change: (changes: Partial<RequirementSchoolBusLink>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    remove: () => void,

    readOnly: boolean,
}

/**
 * Link to The School Bus content (or elsewhere).
 * @param props
 */
export const EditRequirementSchoolBusLink = (props: EditRequirementSchoolBusLinkProps) => {
    const {
        model, change,
        validate, validationErrors,
        remove,
        readOnly,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label htmlFor="linkText">{t('editRequirementSchoolBusLink.linkText', 'Link text')}</Label>
                        <ValidatedInput name="linkText" type="text" value={model?.linkText ?? ''} onChange={e => change({ linkText: e.currentTarget.value })} onBlur={() => validate('linkText')} validationErrors={validationErrors['linkText']} />
                    </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                    <FormGroup>
                        <Label htmlFor="linkText">{t('editRequirementSchoolBusLink.url', 'URL')}</Label>
                        <Row>
                            <Col>
                                <ValidatedInput name="url" type="url" readOnly={readOnly} value={model?.url ?? ''} onChange={e => change({ url: e.currentTarget.value })} onBlur={() => validate('linkText')} validationErrors={validationErrors['url']} />
                            </Col>
                            <Col xs="auto">
                                <Button color="link" className="text-danger" onClick={remove}>
                                    <FontAwesomeIcon icon="trash" />
                                    <span className="sr-only">{t('editRequirementSchoolBusLink.delete', 'Delete link')}</span>
                                </Button>  
                            </Col>
                        </Row>
                    </FormGroup>
                </Col>
            </Row>
        </>
        );
};