import i18next from "i18next";

export interface RequirementComplianceLinkType {
    id: string,
    localizedName: () => string,
    isUpload: boolean,
}

class RequirementComplianceLinkTypes {
    /**
     * File upload
     */
    fileUpload = { id: 'fileUpload', localizedName: () => i18next.t('requirementComplianceLinkTypes.fileUpload.name', 'File upload'), isUpload: true } as RequirementComplianceLinkType;

    /**
     * Link to a TheSchoolBus URL (or another URL).
     */
    urlLink = { id: 'urlLink', localizedName: () => i18next.t('requirementComplianceLinkTypes.urlLink.name', 'Url'), isUpload: false } as RequirementComplianceLinkType;

    /**
     * Link to Policy Manager.
     */
    policyManager = { id: 'policyManager', localizedName: () => i18next.t('requirementComplianceLinkTypes.policyManager.name', 'Managed in Policy manager'), isUpload: false } as RequirementComplianceLinkType;

    /**
     * Access to all available link types as an array.
     */
    items = [
        this.fileUpload,
        this.urlLink,
        this.policyManager,
    ];

    /**
     * Find a state from its id.
     * @param id
     */
    findById = (id: string | undefined | null): RequirementComplianceLinkType | null  => {
        const ret = this.items.find(item => item.id === id);
        if (!ret) {
            return null;
        }

        return ret;
    }
}

/**
 * Requirement compliance states.
 */
export const requirementComplianceLinkTypes = new RequirementComplianceLinkTypes();

