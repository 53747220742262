import { Requirement } from "../../../api/main/models/Requirement";
import { SchoolSchoolType } from "../../../api/main/models/SchoolSchoolType";
import { SchoolSchoolPhase } from "../../../api/main/models/SchoolSchoolPhase";
import { currentRequirementsReleaseQuery_model_requirements_requirement } from "../../../api/main/generated/currentRequirementsReleaseQuery";

/**
 * Filter the requirements by school type and school phase return the visible requirements.
 *
 * You can also use the react hooks:
 *      useRequirementsForSchool() - generates these results for a single school.
 *      useRequirementsForSchools() - generates these results for an array of schools.
 */
export function filterRequirementsForSchool(requirements: Array<currentRequirementsReleaseQuery_model_requirements_requirement> | null, schoolSchoolTypes: Array<SchoolSchoolType>, schoolSchoolPhases: Array<SchoolSchoolPhase>): Array<Requirement> {
    if (!requirements) {
        return [];
    }

    // Remove duplicates (as the same requirement can be linked more than once)
    let ret = [];
    for (const item of requirements) {
        // If this requirement is already in the list, skip it.
        const existing = ret.find(it => it.id === item.id);
        if (existing) {
            continue;
        }

        // Add this requirement to the list.
        ret.push(item);
    }


    // Filter out requirements without a type that matches this school.
    const schoolTypeIds = schoolSchoolTypes?.map(item => item.schoolTypeId) ?? [];
    ret = ret?.filter(item => !!item.appliesToSchoolTypes.find(it => !!schoolTypeIds.find(itt => itt === it.schoolTypeId)));

    // Filter out requirements without a phase that matches this school.
    const schoolPhaseIds = schoolSchoolPhases?.map(item => item.schoolPhaseId) ?? [];
    ret = ret?.filter(item => !!item.appliesToSchoolPhases.find(it => !!schoolPhaseIds.find(itt => itt === it.schoolPhaseId)));

    // Return the filtered results.
    return ret;
}
