import './matOverview.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Collapse, Badge } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { useCurrentRequirementsRelease } from '../../api/main/requirementsReleases/useCurrentRequirementsRelease';
import { ConditionalFragment } from 'react-conditionalfragment';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useParams } from 'react-router';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useSchoolGroup } from '../../api/main/schoolGroups/useSchoolGroup';
import { MATSummaryCard } from './MATSummaryCard';
import { SchoolSummaryCard } from './SchoolSummaryCard';
import { SearchInput } from '../shared/SearchInput';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useSchoolRequirementCompliances } from '../../api/main/schoolRequirementCompliances/useSchoolRequirementCompliances';
import { useLookupSchoolGroupForSchool } from '../../api/main/schoolGroups/useLookupSchoolGroupForSchool';
import { schoolGroupTypes } from '../../services/schoolGroupTypes/schoolGroupTypes';
import { useMATOverviewSupportingData } from '../../api/main/matOverview/useMATOverviewSupportingData';
import { useCurrentUser } from '../../api/api-authorization/useCurrentUser';
import { useToggleStateArray } from 'use-toggle-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCalculateCurrentAwardForSchools } from '../schoolCompliance/useCalculateCurrentAward/useCalculateCurrentAwardForSchools';
import { StandardRating, getStandardRatingMetadata } from '../../api/main/models/constants/StandardRatings';

// School model that we use internally within the component.
type SchoolItem = { id: string, schoolName: string, isLicencedForMonitoringReporting: boolean, };

/**
 * MAT overview. 
 */
export const MATOverview = () => {
    const {
        schoolGroupId: paramsSchoolGroupId,
    } = useParams();

    const { t } = useTranslation();

    // Load the users school details so we cna use to to reverse lookup the MAT if required.
    const user = useCurrentUser();
    const { data: { model: usersSchoolGroup }, isLoading: isUsersSchoolGroupLoading, errors: usersSchoolGroupErrors } = useLookupSchoolGroupForSchool(user?.schoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });

    // Load the MAT details.
    const schoolGroupId = paramsSchoolGroupId ?? usersSchoolGroup?.id ?? '0';
    const { data: { model }, isLoading: _isLoading, errors: loadingErrors } = useSchoolGroup(schoolGroupId);
    const schoolIds = React.useMemo(() => model?.schools?.map(link => link.school.id) ?? [], [model]);
    const { data: { schoolSchoolPhases, schoolSchoolTypes }, isLoading: isSupportingDataLoading, errors: supportingDataLoadingErrors } = useMATOverviewSupportingData(schoolIds);

    // Load the current release of questions.
    const { data: { model: currentRequirementsRelease }, isLoading: isLoadingCurrentRequirementsRelease, errors: currentRequirementsReleaseErrors } = useCurrentRequirementsRelease({ fetchPolicy: "cache-first" });

    // Load the compliance records for all schools within the MAT.
    const { data: { items: compliances }, isLoading: isCompliancesLoading, errors: complianceLoadErrors } = useSchoolRequirementCompliances({ schoolIds: schoolIds });

    // Make sure isLoading takes into account all the queries we use.
    const isLoading = _isLoading || isUsersSchoolGroupLoading || isSupportingDataLoading || isLoadingCurrentRequirementsRelease || isCompliancesLoading;


    // All requirements (ensuring there are no duplicates).
    const requirements = React.useMemo(() => {
        if (!currentRequirementsRelease) {
            return [];
        }

        // Start with all requirements.
        let all = currentRequirementsRelease.requirements
            ?.map(item => item.requirement)
            ?.filter(item => !!item) ?? [];

        // Remove duplicates (as the same requirement can be linked more than once)
        let ret = [];
        for (const item of all) {
            // If this requirement is already in the list, skip it.
            const existing = ret.find(it => it.id === item.id);
            if (existing) {
                continue;
            }

            // Add this requirement to the list.
            ret.push(item);
        }

        // NOTE we don't filter by phase or types as our schools may have many phase/types.

        // Return the filtered results.
        return ret;
    }, [currentRequirementsRelease]);

    // All schools within our MAT (removing duplicates).
    const schools = React.useMemo(() => {
        if (!model) {
            return [];
        }

        // Get an alphabetical list of schools in this trust.
        const mySchoolsWithDuplicates = model.schools.map(link => link.school);

        // Remove duplicates
        let ret: Array<SchoolItem> = [];
        for (const school of mySchoolsWithDuplicates) {
            if (ret.find(it => it.id === school.id)) {
                continue;
            }

            ret.push(school);
        }

        ret.sort((a, b) => a.schoolName.localeCompare(b.schoolName));
        return ret;
    }, [model]);

    // Allow filtering/searching of schools.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    // Filter by the user's search client side so it can work when offline as well as online.
    const filteredSchools = React.useMemo(() => {
        if (!schools || !search) {
            return schools;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return schools.filter(item =>
            item.schoolName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [schools, search]);

    // Divide the unlicensed schools from the licensed schools.
    const licencedSchools = React.useMemo(() => filteredSchools.filter(item => item.isLicencedForMonitoringReporting), [filteredSchools]);
    const unlicencedSchools = React.useMemo(() => filteredSchools.filter(item => !item.isLicencedForMonitoringReporting), [filteredSchools]);

    // Calculate the awards per school.
    const schoolAwards = useCalculateCurrentAwardForSchools(licencedSchools, requirements, compliances, schoolSchoolTypes ?? [], schoolSchoolPhases ?? [], currentRequirementsRelease?.requirements ?? []);

    // Organise the schools into groups based on their awards.
    const {
        goldSchools,
        silverSchools,
        bronzeSchools,
        noAwardSchools,
    } = React.useMemo(() => {
        let goldSchools: Array<SchoolItem> = [];
        let silverSchools: Array<SchoolItem> = [];
        let bronzeSchools: Array<SchoolItem> = [];
        let noAwardSchools: Array<SchoolItem> = [];

        for (const school of licencedSchools) {
            const award = schoolAwards[school.id];
            switch (award?.currentLevel) {
                case StandardRating.Gold:
                    goldSchools.push(school);
                    break;
                case StandardRating.Silver:
                    silverSchools.push(school);
                    break;
                case StandardRating.Bronze:
                    bronzeSchools.push(school);
                    break;
                default:
                    noAwardSchools.push(school);
                    break;
            }
        }

        return {
            goldSchools,
            silverSchools,
            bronzeSchools,
            noAwardSchools,
        };
    }, [schoolAwards, licencedSchools]);

    useReplaceSearchParamsEffect({ search: search });

    // Section collapsing.
    type SectionKey = 'gold' | 'silver' | 'bronze' | 'noAward' | 'unlicensed';
    const [isSectionOpen, toggleSectionOpen] = useToggleStateArray<SectionKey>([
        'gold', 'silver', 'bronze', 'noAward', /* 'unlicensed' */
    ]);

    // Section component.
    const Section = React.useCallback((props: { text: string, sectionKey: SectionKey, schools: Array<SchoolItem> }) => {
        const {
            text,
            sectionKey,
            schools,
        } = props;

        return (
            <div>
                <h5 className="mat-overview-schools-subheading" onClick={() => toggleSectionOpen(sectionKey)}>
                    {text}
                    <> </>
                    <Badge color="dark" pill>
                        {t('common.integer', '{{value, #,0}}', { value: schools.length })}
                    </Badge>
                    <> </>
                    <FontAwesomeIcon icon={isSectionOpen(sectionKey) ? 'caret-up' : 'caret-down'} />
                </h5>

                <Collapse isOpen={isSectionOpen(sectionKey)}>
                    {
                        schools.map(item => {
                            const currentAward = schoolAwards[item.id];

                            return (
                                <SchoolSummaryCard key={item.id}
                                    schoolId={item.id}
                                    schoolName={item.schoolName}
                                    currentAward={currentAward}
                                    hasLicence={item.isLicencedForMonitoringReporting}
                                />
                            );
                        })
                    }
                </Collapse>
            </div>
        );
    }, [isSectionOpen, toggleSectionOpen, schoolAwards, t]);


    return (
        <Background className="mat-overview">
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} lg="">
                            <h2 className="text-muted">
                                {t('matOverview.heading', 'MAT overview')}
                            </h2>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs={12} lg={8}>
                            <SearchInput placeholder={t('matOverview.searchSchools', 'Search schools')} value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>
            <Container fluid className="mt-2">
                <AlertOnErrors errors={[loadingErrors, usersSchoolGroupErrors, currentRequirementsReleaseErrors, complianceLoadErrors, supportingDataLoadingErrors]} />
                <Row>
                    <Col xs={12} lg={4}>
                        <MATSummaryCard
                            goldDisplayName={getStandardRatingMetadata(StandardRating.Gold).displayName}
                            silverDisplayName={getStandardRatingMetadata(StandardRating.Silver).displayName}
                            bronzeDisplayName={getStandardRatingMetadata(StandardRating.Bronze).displayName}
                            bronzeSchools={(bronzeSchools ?? []).length} silverSchools={(silverSchools ?? []).length} goldSchools={(goldSchools ?? []).length} noAwardSchools={(noAwardSchools ?? []).length}
                        />
                    </Col>
                    <Col xs={12} lg={8}>
                        {/* Gold */}
                        <Section
                            text={getStandardRatingMetadata(StandardRating.Gold).displayName}
                            sectionKey="gold"
                            schools={goldSchools}
                        />

                        {/* Silver */}
                        <Section
                            text={getStandardRatingMetadata(StandardRating.Silver).displayName}
                            sectionKey="silver"
                            schools={silverSchools}
                        />

                        {/* Bronze */}
                        <Section
                            text={getStandardRatingMetadata(StandardRating.Bronze).displayName}
                            sectionKey="bronze"
                            schools={bronzeSchools}
                        />

                        {/* No award */}
                        <Section
                            text={t('matOverview.workingTowardsBronze', 'Yet to achieve a compliance standard')}
                            sectionKey="noAward"
                            schools={noAwardSchools}
                        />

                        {/* Unlicensed */}
                        <Section
                            text={t('matOverview.unlicencedHeading', 'Schools without Compliance Monitor licences')}
                            sectionKey="unlicensed"
                            schools={unlicencedSchools}
                        />
                    </Col>
                </Row>
            </Container>
        </Background>
    );
};
