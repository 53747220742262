import { apiAuthorizationConfig } from '../../configure/apiAuthorizationConfig';
import { User, userFieldsFragment } from '../main/models/User';
import { apolloClient } from '../../configure/configureApollo';
import gql from 'graphql-tag';
import { signInQuery, signInQueryVariables } from '../main/generated/signInQuery';
import { signInDeveloperQuery, signInDeveloperQueryVariables } from '../main/generated/signInDeveloperQuery';

export interface AuthenticationResult {
    status: string,
    message?: string,
    state?: any,
}

const localStorageKey = 'AuthorizeService.persitedUser';

/**
 * Authorize service that integrates with The School Bus's users to provide behind the scenes invisible authentication.
 */
export class AuthorizeService {
    _user: User | null;
    _callbacks: Array<{ callback: () => void, subscription: number }>;
    _nextSubscriptionId: number;

    constructor() {
        this._user = null;
        this._callbacks = [];
        this._nextSubscriptionId = 0;
    }

    async isAuthenticated(): Promise<boolean> {
        const user = await this.getUser();
        return !!user;
    }

    async getUser(): Promise<User | null> {
        // Try load from local storage if we haven't got _user set yet.
        if (!this._user) {
            const userJson = localStorage.getItem(localStorageKey);
            if (userJson) {
                this._user = JSON.parse(userJson);
            }
        }

        return this._user ?? null;
    }

    async getAccessToken(): Promise<string | null> {
        const user = await this.getUser();
        return user?.id?.toString() ?? null;
    }

    /**
     * Sign in the user using the special developer sign in (available when testing, but not when live).
     * @param state
     */
    async signInDeveloper(email: string): Promise<AuthenticationResult> {
        // If developer logins are not enabled, then throw an exception.
        if (!apiAuthorizationConfig.allowDeveloperLogin) {
            throw new Error('Developer logins are not enabled in this environment.');
        }

        // Check the user is valid in the database.
        const { data: { user } } = await apolloClient.query<signInDeveloperQuery, signInDeveloperQueryVariables>({
            query: gql`
                query signInDeveloperQuery($userName: String!, $secret: String!) {
                    user: checkUser (userName: $userName, secret: $secret) {
                        ...userFields
                        schoolBusUserRoles
                    }
                }

                ${userFieldsFragment}
            `,
            variables: {
                userName: email,
                secret: 'TheSchoolBus',
            },
            fetchPolicy: "network-only",
        });
        if (user) {
            this._user = user;

            // Persist to local storage.
            localStorage.setItem(localStorageKey, JSON.stringify(user));

            // Let all subscribers know of the change.
            this.notifySubscribers();

            return {
                status: AuthenticationResultStatus.Success,
                message: 'Login successful',
                state: user,
            };
        }

        // If we get here we couldn't log in.
        return {
            status: AuthenticationResultStatus.Fail,
            message: 'Could not log in',
            state: null,
        };
    }

    /**
     * Sign in the user.
     * @param state
     */
    async signIn(state: any): Promise<AuthenticationResult> {
        // Get the cookie containing the user details.
        const schoolBusUser = await apiAuthorizationConfig.getTheSchoolBusLoggedInUser();

        // If we don't have a logged in user redirect to the school bus login page.
        if (!schoolBusUser) {
            return {
                status: AuthenticationResultStatus.Redirect,
                message: 'Need interactive login',
                state: null,
            };
        }


        // Check the user is valid in the database.
        const { data: { user } } = await apolloClient.query<signInQuery, signInQueryVariables>({
            query: gql`
                query signInQuery($userName: String!, $secret: String!) {
                    user: checkUser (userName: $userName, secret: $secret) {
                        ...userFields
                        schoolBusUserRoles
                    }
                }

                ${userFieldsFragment}
            `,
            variables: {
                userName: schoolBusUser.userName,
                secret: schoolBusUser.secret
            },
            fetchPolicy: "network-only",
            });
        if (user) {
            this._user = user;

            // Persist to local storage.
            localStorage.setItem(localStorageKey, JSON.stringify(user));

            // Let all subscribers know of the change.
            this.notifySubscribers();

            return {
                status: AuthenticationResultStatus.Success,
                message: 'Login successful',
                state: user,
            };
        }

        // If we get here we couldn't log in.
        return {
            status: AuthenticationResultStatus.Fail,
            message: 'Could not log in',
            state: null,
        };
    }

    /**
     * Sign out the user.
     * @param state
     */
    async signOut(state: any): Promise<AuthenticationResult> {
        this._user = null;

        // Remove the persisted local storage key.
        localStorage.removeItem(localStorageKey);

        // Let all subscribers know of the change.
        this.notifySubscribers();

        return {
            status: AuthenticationResultStatus.Success,
            message: 'Logged out',
            state: null,
        };
    }
    updateState(user: User | null) {
        this._user = user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void): number {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length === 1) {
            this._callbacks = this._callbacks.splice(subscriptionIndex[0].index as number, 1);
        } else {
            //throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state?: any) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message: string) : AuthenticationResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state: any): AuthenticationResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect(): AuthenticationResult {
        return { status: AuthenticationResultStatus.Redirect };
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
