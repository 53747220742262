import { Requirement } from "../../api/main/models/Requirement";
import { SchoolRequirementCompliance } from "../../api/main/models/SchoolRequirementCompliance";
import { ComplianceProgressResult } from "./ComplianceProgressResult";
import { requirementComplianceStates } from "../requirementComplianceStates/requirementComplianceStates";
import { isNullOrUndefined } from 'util';
import { StandardRating } from "../../api/main/models/constants/StandardRatings";

/**
 * Calculator of the progress of compliance.
 */
export class ComplianceProgressCalculator {
    _requirements: Array<Requirement>;
    _compliances: Array<SchoolRequirementCompliance>;
    _schoolIds: Array<string | null>;

    constructor(requirements: Array<Requirement>, compliances: Array<SchoolRequirementCompliance>, schoolIds?: Array<string>) {
        this._requirements = requirements;
        this._compliances = compliances;
        this._schoolIds = schoolIds ?? [null /* Means we don't filter by school */];
    }

    /**
     * Calculate the compliance for the requirements covered by this calculator.
     */
    calculate(standardRating?: StandardRating): ComplianceProgressResult {
        let unanswered = 0;
        let success = 0;
        let failed = 0;
        let skipped = 0;

        let requirementsForStandard = this._requirements;
        if (standardRating) {
            requirementsForStandard = requirementsForStandard.filter(it => it.standardRating === standardRating);
        }

        // Loop over each requirement for each school (this is how the MAT overview works).
        for (const schoolId of this._schoolIds) {
            const schoolIdAsNumber = schoolId? parseInt(schoolId): null;

            // Look at each requirement and work out how compliant we are.
            for (const requirement of requirementsForStandard) {
                // Find the compliance record.
                let compliance = this._compliances.find(item => item.requirementOriginKey === requirement.originKey && (isNullOrUndefined(schoolIdAsNumber) || item.schoolId === schoolIdAsNumber));

                // If we haven't responded anything to this yet then leave as unanswered.
                if (!compliance) {
                    ++unanswered;
                    continue;
                }

                // Use the state to decide.
                switch (requirementComplianceStates.findById(compliance.requirementComplianceState).id) {
                    case requirementComplianceStates.success.id:
                        ++success;
                        break;
                    case requirementComplianceStates.failed.id:
                        ++failed;
                        break;
                    case requirementComplianceStates.skipped.id:
                        ++skipped;
                        break;
                    case requirementComplianceStates.unanswered.id:
                    default:
                        ++unanswered;
                        break;
                }
            }
        }

        return {
            unanswered: unanswered,
            success: success,
            failed: failed,
            skipped: skipped,

            totals: {
                total: unanswered + success + failed + skipped,
                notSkipped: unanswered + success + failed,
                outstanding: unanswered + failed,
                percentage: (unanswered + success + failed) === 0 ? 0 : (success / (unanswered + success + failed)) * 100,
            },
        };
    }    
}
