import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { shareSchoolReportMutation, shareSchoolReportMutationVariables } from '../generated/shareSchoolReportMutation';
import { schoolReportFieldsFragment } from '../models/SchoolReport';

/**
 * Get a callback to share a SchoolReport via email.
 */
export function useShareSchoolReportCallback(): [(id: string, email: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<shareSchoolReportMutation, shareSchoolReportMutationVariables>(
        gql`
            mutation shareSchoolReportMutation ($id: ID!, $email: String!) {
                shareSchoolReport(id: $id, email: $email) {
                    ...schoolReportFields
                }
            }

            ${schoolReportFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, email: string) => {
        const result = await mutationAction({ variables: { id: id, email: email } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
