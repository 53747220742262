import './standardRatingComplianceProgressBar.scss';

import * as React from 'react';
import { Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { ComplianceProgressResult } from '../../services/complianceProgressCalculator/ComplianceProgressResult';

export interface StandardRatingComplianceProgressBarProps {
    size?: 'md' | 'lg',

    compliance: ComplianceProgressResult,
}

/**
 * Progress bar for an compliance of a school's compliance to a particular standard.
 * @param props
 */
export const StandardRatingComplianceProgressBar = (props: StandardRatingComplianceProgressBarProps) => {
    const {
        size = 'md',
        compliance,
    } = props;

    const { t } = useTranslation();

    const totalUnskipedRequirements = compliance.success + compliance.failed + compliance.unanswered;
    const totalAvailableProgress = compliance.failed + compliance.unanswered;

    return (
        <Progress multi className={`standard-rating-compliance-progress-bar standard-rating-compliance-progress-bar-size-${size}`}>
            {/* Success goes first as progress towards the goal */}
            <ConditionalFragment showIf={compliance.success > 0}>
                <Progress bar color="success" value={totalUnskipedRequirements === 0 ? 0 : Math.ceil((compliance.success / totalUnskipedRequirements) * 100)}>
                    {t('common.integer', '{{value, #,0}}', { value: compliance.success })}
                </Progress>
            </ConditionalFragment>

            {/* Unanswered and failed go together as available progress */}
            <ConditionalFragment showIf={totalAvailableProgress > 0}>
                <Progress bar color="transparent" value={totalUnskipedRequirements === 0 ? 0 : Math.ceil((totalAvailableProgress / totalUnskipedRequirements) * 100)} className="compliance-progress-bar-transparent">
                    {t('common.integer', '{{value, #,0}}', { value: totalAvailableProgress })}
                </Progress>
            </ConditionalFragment>
        </Progress>
    );
};
