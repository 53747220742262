import * as React from "react";
import { AsyncLoadResult, AsyncLoadOptions } from "../../shared/abstractStore";
import { useAsyncCallback } from "react-use-async-callback";
import { useAuthenticatedState } from "../../api/api-authorization/useAuthenticatedState";
import { isNullOrUndefined } from "util";
import { useCache } from "../../shared/useCache";
import { User } from "../main/models/User";
import { SchoolGroup } from "../main/models/SchoolGroup";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { useLookupSchoolGroupForSchool } from "../main/schoolGroups/useLookupSchoolGroupForSchool";
import { schoolGroupTypes } from "../../services/schoolGroupTypes/schoolGroupTypes";
import { useSchool } from "../main/schools/useSchool";


/**
 * Refresh the current user state using the accountService.
 */
const refreshCurrentUserRoles = async (isAuthenticated: boolean | null, user: User | null, mat: SchoolGroup | null, setRoles: (value: RolesState) => void): Promise<void> => {
    // Do nothing if we don't know our authentication state.
    if (isNullOrUndefined(isAuthenticated)) {
        return;
    }

    // If we are not authenticated then we have no roles.
    if (!isAuthenticated) {
        setRoles({ isAuthenticated: false, roles: [] });
        return;
    }

    // Otherwise we are authenticated, so lets work the roles based on the access level of the user in TheSchoolBus.
    let roles: Array<string>;
    switch (user?.userType?.accessLevel ?? 7) {
        case 1: /* HCSS - Hub4Leaders Office Admin */
            roles = [IdentityRoles.SchoolUser, IdentityRoles.MATUser, IdentityRoles.Administration];
            break;
        case 2: /* Contributor - Contributes content to the site */
            roles = [IdentityRoles.SchoolUser /*, IdentityRoles.MATUser, IdentityRoles.Administration */];
            break;
        case 3: /* User - Standard School User */
            roles = [IdentityRoles.SchoolUser];
            break;
        case 4: /* TrialUser - Trial User */
            roles = [];
            break;
        case 5: /* LAEditor - Local Authority Editor */
            roles = [IdentityRoles.SchoolUser];
            break;
        case 6: /* LAAdmin - Local Authority Admin */
            roles = [IdentityRoles.SchoolUser];
            break;
        default: /* No TheSchoolBus access level */
            roles = [];
            break;
    }

    for (const role of (user as User & { schoolBusUserRoles: Array<string>, })?.schoolBusUserRoles ?? []) {
        roles.push(role);
    }

    // If we have an MAT then add that role too.
    if (mat) {
        roles.push(IdentityRoles.MATUser);
    }

    setRoles({ isAuthenticated: true, roles: roles });
};

interface RolesState {
    isAuthenticated: boolean,
    roles: Array<string>,
}

/**
 * Returns the list of roles for the current user.
 */
export function useCurrentUserRoles(options: AsyncLoadOptions = {}): AsyncLoadResult<{ roles: Array<string> | null }> {
    const { isAuthenticated, user } = useAuthenticatedState({ includeUser: true });
    const [roles, setRoles] = useCache<RolesState>('currentUserRoles');
    const { data: { model: school } } = useSchool(user?.schoolId?.toString() ?? '0', { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });
    const { data: { model: mat } } = useLookupSchoolGroupForSchool(user?.schoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });

    const [refresh, { isExecuting: isLoading, errors }] = useAsyncCallback(async () => {
        await refreshCurrentUserRoles(isAuthenticated, user, school?.isMasterSchool? mat: null, setRoles);
    }, [isAuthenticated, user, setRoles, mat, school]);

    React.useEffect(() => {
        if (options.lazy) {
            return;
        }

        // Refresh from the store if we need to.
        if (isNullOrUndefined(isAuthenticated)) {
            return;
        }

        if (!roles || roles.isAuthenticated !== isAuthenticated) {
            refresh();
        }

        // Special handling for loading the MATUser role when we need to.
        if ((!!roles?.roles?.find(it => it === IdentityRoles.MATUser)) !== (!!mat)) {
            refresh();
        }
    }, [refresh, options.lazy, isAuthenticated, roles, mat]);

    return {
        data: { roles: roles?.roles ?? null },
        refresh: refresh,
        isLoading: isLoading,
        errors: errors
    };
}


/**
 * Clear and refresh the cached user roles.
 */
export function useResetCurrentUserRolesCallback() {
    const { isAuthenticated, user } = useAuthenticatedState({ includeUser: true });
    const [/*roles*/, setRoles] = useCache('currentUserRoles');
    const { data: { model: mat } } = useLookupSchoolGroupForSchool(user?.schoolId?.toString() ?? '0', schoolGroupTypes.multiAcademyTrust.id.toString(), { fetchPolicy: 'cache-first' /* Can cache-first here without refreshing each time */ });

    const [refresh] = useAsyncCallback(async () => {
        await refreshCurrentUserRoles(isAuthenticated, user, mat, setRoles);
    }, [isAuthenticated, user, setRoles, mat]);

    return React.useCallback(() => {
        refresh();
    }, [refresh]);
}
