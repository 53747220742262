import * as React from 'react';
import { AdministrationNavigation } from './AdministrationNavigation';
import { useTranslation } from 'react-i18next';
import { NavTiles } from '../shared/NavTiles';
import { Container } from 'reactstrap';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/Background';
import { StickyToolbar } from '../shared/StickyToolbar';

/**
 * Administration menu. 
 */
export const Administration = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <h2 className="text-muted">
                        {t('administration.heading', 'Administration')}
                    </h2>
                </StickyToolbar>
            </Banner>
            <Container fluid>
                <NavTiles>
                    <AdministrationNavigation />
                </NavTiles>
            </Container>
        </Background>
        );
};
