import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteSchoolRequirementComplianceLinkMutation, deleteSchoolRequirementComplianceLinkMutationVariables } from '../generated/deleteSchoolRequirementComplianceLinkMutation';
import { schoolRequirementComplianceLinkFieldsFragment } from '../models/SchoolRequirementComplianceLink';

/**
 * Get a callback to delete a SchoolRequirementComplianceLink in the store.
 */
export function useDeleteSchoolRequirementComplianceLinkCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteSchoolRequirementComplianceLinkMutation, deleteSchoolRequirementComplianceLinkMutationVariables>(
        gql`
            mutation deleteSchoolRequirementComplianceLinkMutation ($id: ID!) {
                deleteSchoolRequirementComplianceLink(id: $id) {
                    ...schoolRequirementComplianceLinkFields
                }
            }

            ${schoolRequirementComplianceLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
