/**
 * Generate a list of items that follow normal english language rules (with oxford comma).
 */
export function nicelyFormattedEnglishList(items: Array<string> | undefined | null): string {
    if (!items || !items.length) {
        return '';
    }

    if (items.length === 1) {
        return items[0];
    }

    if (items.length === 2) {
        return `${items[0]} and ${items[1]}`;
    }

    return `${items.slice(0, items.length - 1).join(', ')}, and ${items[items.length - 1]}`;
};