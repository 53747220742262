import * as React from "react";
import { StandardRating } from "../../../api/main/models/constants/StandardRatings";
import "./awardImage.scss";
import moment from "moment";

export interface AwardImageProps {
    standardRating: StandardRating | null | undefined,
    size?: 'sm' | 'lg',
    date?: string,
}

/**
 * Image showing an award level.
 */
export const AwardImage = (props: AwardImageProps) => {
    const {
        standardRating,
        size = 'lg',
        date,
    } = props;

    // Work out the best image to show.
    const image = React.useMemo(() => {
        const now = moment(date); // If date is empty this will be today.
        let year = now.year() - 2000;
        if (now.month() < 8 /* NOTE: Moment months are zero based so 8 here means September */) {
            year = year - 1;
        }
        const nextYear = year + 1;

        let awardFilePart = '0_TCS_Award_NoAward_RGB_';
        switch (standardRating) {
            case StandardRating.Bronze:
                awardFilePart = '1_TCS_Award_Bronze_RGB_';
                break;
            case StandardRating.Silver:
                awardFilePart = '2_TCS_Award_Silver_RGB_';
                break;
            case StandardRating.Gold:
                awardFilePart = '3_TCS_Award_Gold_RGB_';
                break;
            default:
                break;
        }

        const ret = `/img/awardImage/${year}-${nextYear}/${awardFilePart}${year}${nextYear}.png`;
        return ret;
    }, [standardRating, date]);

    return (
        <div className={`award-image award-image-level-${standardRating ?? 'NoAward'} award-image-size-${size}`} style={{ backgroundImage: `url("${image}")`}}>
        </div>
    );
};
