import * as React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from "reactstrap";
import { useTranslation } from "react-i18next";
import { StandardRating } from "../../api/main/models/constants/StandardRatings";
import { AwardImage } from "./awardImage/AwardImage";
import { ButtonAsync } from "reactstrap-buttonasync";

export interface ClaimAwardModalProps {
    isOpen: boolean,
    toggle: () => void,
    awardLevel: string,
    claimAward: () => void,
    isClaimingAward: boolean,
}

/**
 * Modal used by Awards to let the user claim an unlocked award.
 * @param props
 */
export const ClaimAwardModal = (props: ClaimAwardModalProps) => {
    const {
        isOpen,
        toggle,
        awardLevel,
        claimAward,
        isClaimingAward,
    } = props;

    const { t } = useTranslation();

    return (
        <Modal size="lg" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                {t('claimAwardModal.heading', 'Claim The Compliance Standard {{awardLevel}} award', { awardLevel, })}
            </ModalHeader>
            <ModalBody>
                <p>
                    {t('claimAwardModal.text.p1', 'Congratulations! You have demonstrated your commitment to compliance excellence and creating a safe school environment and achieved The Compliance Standard {{awardLevel}} Award.', { awardLevel, })}
                </p>
                <p>
                    {t('claimAwardModal.text.p2', 'You can share your award on your school website, prospectus, noticeboard – anywhere you like!')}
                </p>
                <div>
                    <AwardImage
                        size="sm"
                        standardRating={(awardLevel as StandardRating) ?? undefined}
                    />
                </div>
                <p>
                    <strong>
                        {t('claimAwardModal.text.p2', 'To claim your award and unlock your web badge and certificate, simply confirm below that the information provided is correct. If you have any questions around a requirement, simply contact us using our online chat or email')}
                        <> </>
                        <a href="mailto:info@theschoolbus.net">info@theschoolbus.net</a>. {/* This is not translation friendly, but we've done it this way anyway as we only expect English to be used */}
                    </strong>
                </p>
            </ModalBody>
            <ModalFooter>
                <ButtonAsync color="primary" onClick={() => claimAward() /* will also toggle the modal */}
                    isExecuting={isClaimingAward}
                    executingChildren={<><Spinner size="sm" /><> </>{t('claimAwardModal.claimingAward', 'Claiming award...')}</>}
                >
                    {t('claimAwardModal.claim', 'I confirm the information provided is true')}
                </ButtonAsync>
                <> </>
                <Button color="primary" outline onClick={() => toggle()}>
                    {t('claimAwardModal.cancel', 'Claim later')}
                </Button>
            </ModalFooter>
        </Modal>
        );
};
