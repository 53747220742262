import { integrationConfig } from '../configure/integrationConfig';

/**
 * Open a url from TheSchoolBus appending the right environment if required.
 * @param url
 */
export function openSchoolBusLink(url: string | null | undefined) {
    // If we have no URL, do nothing.
    if (!url) {
        return;
    }

    // Lets work out the full URL and then open it.
    let fullUrl = url;
    let openInNewWindow: boolean = true;

    // If we have no protocol then we are a relative URL, so append the school bus domain.
    // NOTE we also open only these links in the current window, and not in a seperate tab.
    if (url.indexOf('://') === -1) {
        fullUrl = `${integrationConfig.schoolBusBaseUrl.replace(/\/$/, '')}${fullUrl}`;
        openInNewWindow = false;
    }

    // Redirects to TNC and to ourselves, also do not need to be opened in a new window.
    if (
        url.indexOf('nationalcollege.com') >= 0
        || url.indexOf('thecompliancestandard.') >= 0
        ) {
        openInNewWindow = false;
    }

    // Open the URL.
    if (openInNewWindow) {
        // Open in new window.
        window.open(url);
    } else {
        // Open in this window.
        window.location.href = fullUrl;
    }
}
