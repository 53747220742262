import gql from "graphql-tag";
import { userTypeFields } from "../generated/userTypeFields";

export const userTypeFieldsFragment = gql`
    fragment userTypeFields on UserType {
        id
        typeName
        typeDescription
        accessLevel
    }
`;


export type UserType = Omit<userTypeFields, '__typename'>;
