// This is an pre-cached version of the query from useSchoolOverviewSupportingData executed and cached at run time.
// TODO this would be good to regenerate at compile/development time rather than needing to maintained by hand.
//

export const useSchoolOverviewSupportingData_PrecachedResults = {
    "data": {
        "currentRequirementsRelease": {
            "id": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                "versionNumber": 35,
                    "patchNumber": 5,
                        "publishDate": "2022-09-06T15:07:46.874+00:00",
                            "archived": false,
                                "releaseNotes": "<p>final videos added</p>",
                                    "areas": [
                                        {
                                            "id": "f4a29a60-0acc-4ea2-94b2-d9fc1414cddc",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                            "displayOrder": 8,
                                            "archived": false,
                                            "area": {
                                                "id": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "name": "Health and Safety",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school is compliant with all statutory and good practice health and safety procedures. This area covers requirements for risk management, health and safety training, and more.</p>",
                                                "archived": false,
                                                "versionDate": "2020-08-26T10:39:49.946+00:00",
                                                "originKey": "da2a64d6-512c-49bb-b4d3-db9dbf132561",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "2a3a3e25-c857-42cf-a232-c6ba05bca19a",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                            "displayOrder": 9,
                                            "archived": false,
                                            "area": {
                                                "id": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "name": "Data Protection ",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school is compliant with data protection legislation and regulations. This area covers requirements including essential data protection policies, data protection training, the role of the DPO, and more.</p>",
                                                "archived": false,
                                                "versionDate": "2020-08-27T08:16:41.342+00:00",
                                                "originKey": "9dda38d2-82b8-456e-a9c5-979996fca46e",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "cdc61d63-3332-42ad-bd32-20b3f5a215e4",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                            "displayOrder": 10,
                                            "archived": false,
                                            "area": {
                                                "id": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "name": "Reporting",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school is compliant with all statutory and good practice reporting procedures, and that deadlines for reports are consistently met. This area covers report requirements for finance, pupil performance, safeguarding, incidents, governance, and more.</p>",
                                                "archived": false,
                                                "versionDate": "2020-08-27T10:54:54.595+00:00",
                                                "originKey": "17c8a152-1c8c-4b1c-b51f-c76780aed8f8",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "0a2314e0-f72e-43b6-9b03-efefc75f36e1",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                            "displayOrder": 11,
                                            "archived": false,
                                            "area": {
                                                "id": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "name": "Website",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's website is up-to-date and contains all the required information.</p>",
                                                "archived": false,
                                                "versionDate": "2020-09-04T09:15:52.424+00:00",
                                                "originKey": "31b5b487-dbbe-4a16-9421-17fb3fd9e9fe",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "7a27a0df-f210-4cf6-8e4b-765841620a77",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                            "displayOrder": 12,
                                            "archived": false,
                                            "area": {
                                                "id": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "name": "Policies and Documents",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school has all the necessary statutory policies and documents in place.</p>",
                                                "archived": false,
                                                "versionDate": "2020-09-07T10:19:05.193+00:00",
                                                "originKey": "8ba6c3a5-2cb3-4e5e-8d8f-264bfc2bd2b1",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "36de114c-56f6-4031-b422-80f6ce39e1e8",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                            "displayOrder": 13,
                                            "archived": false,
                                            "area": {
                                                "id": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "name": "Safeguarding",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school is compliant with all statutory safeguarding guidance. This area covers requirements for essential safeguarding policies and procedures, reporting arrangements, and more.</p>",
                                                "archived": false,
                                                "versionDate": "2020-09-07T12:50:42.715+00:00",
                                                "originKey": "7e820aa1-c302-47c4-91e0-c5d42efbea6c",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "94c3cb27-d753-4d69-a7bb-d5837f628c38",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                            "displayOrder": 14,
                                            "archived": false,
                                            "area": {
                                                "id": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "name": "Estate Management ",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's estate management procedures are compliant.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-22T14:02:12.922+00:00",
                                                "originKey": "78b35e2d-abae-4c2a-9afb-1623f7380d98",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "608bf4f9-cd9c-4c07-9245-f26e428f6ebf",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                            "displayOrder": 15,
                                            "archived": false,
                                            "area": {
                                                "id": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "name": "Wellbeing",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's wellbeing practices are compliant.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-22T14:02:45.485+00:00",
                                                "originKey": "a9e0afc5-4849-4c5c-b2a1-e9196d14a942",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "5bd5ac87-93dd-4718-ae66-59240b41ec18",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                            "displayOrder": 16,
                                            "archived": false,
                                            "area": {
                                                "id": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "name": "Environment",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's approach to sustainability is compliant with all relevant requirements.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-23T10:55:28.676+00:00",
                                                "originKey": "c9100f99-ab51-4cff-8717-235832b4d745",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "8a3a96f1-2f2a-4949-b6b6-9cfcb86953cd",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                            "displayOrder": 17,
                                            "archived": false,
                                            "area": {
                                                "id": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "name": "Equality",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's approach to equality is compliant.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-23T10:55:45.238+00:00",
                                                "originKey": "35684b58-9df7-48f6-b186-b1b1c1ca40f2",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "acce69e5-b2fa-4f93-90a0-2f7fc4c118b2",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                            "displayOrder": 18,
                                            "archived": false,
                                            "area": {
                                                "id": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "name": "Finance",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's finance procedures are compliant.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-23T10:56:13.782+00:00",
                                                "originKey": "21fd70b5-f58a-47b1-a7e0-be0a1895344c",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        },
                                        {
                                            "id": "44b06adc-f1af-4668-a78b-267f3d242ad6",
                                            "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                            "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                            "displayOrder": 19,
                                            "archived": false,
                                            "area": {
                                                "id": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "name": "SEND",
                                                "descriptionHtml": "<p>Complete these requirements to ensure your school's SEND procedures are compliant.</p>",
                                                "archived": false,
                                                "versionDate": "2022-08-23T10:56:18.309+00:00",
                                                "originKey": "f1e458ae-bc03-42da-a4b6-712e183b8677",
                                                "videoId": null,
                                                "__typename": "RequirementArea"
                                            },
                                            "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirementArea"
                                        }
                                    ],
                                        "requirements": [
                                            {
                                                "id": "6eaed82a-5540-4422-8fa0-8a0248ad6cd5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1102,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                    "name": "Has a competent person been appointed to ensure health and safety duties are met?",
                                                    "descriptionHtml": "<p>Schools must appoint a competent person responsible for health and safety to ensure they meet their health and safety duties. A competent person is someone with the necessary skills, knowledge and experience to give sensible guidance about managing the health and safety risks at the school. This person can be one or more members of school staff or the school’s employer can arrange external support.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T10:39:57.879+00:00",
                                                    "originKey": "48cf8e1f-9d06-49e0-a1b9-903309af544e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Health and safety: responsibilities and duties for schools’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1bd102c3-cfe1-465a-9ac2-6390b2c584e5",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2bb31f52-5761-4edc-bf55-3f3651cc6e36",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d672ab5-bc2e-477e-ba6b-430904debab8",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d656914e-432d-41ac-8e47-2441076dda24",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "25b8e663-a3df-41d9-96ce-079547a88609",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "85527d1b-395f-4a80-b809-3f9c6ec11aea",
                                                            "requirementId": "06703813-802a-4acd-aa0d-b6ba5f1bb3e8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "18b58750-1936-4977-8b8c-6fa802976c3f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1103,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                    "name": "Does the school have a Health and Safety Policy?",
                                                    "descriptionHtml": "<p>Schools must have a Health and Safety Policy in place. The key elements of the policy should set out:</p><p><br></p><ul><li>The roles and responsibilities within risk management processes.&nbsp;</li><li>The mechanisms to control risk.&nbsp;</li><li>Specific control measures that need to be implemented.&nbsp;</li></ul><p><br></p><p>You may wish to attach or link to your school’s Health and Safety Policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T10:44:00.993+00:00",
                                                    "originKey": "56d03eda-ef40-4e6a-a308-a37b61815eb0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Statutory policies for schools and academy trusts’</p><p>&nbsp;</p><p>DfE ‘Health and safety: responsibilities and duties for schools’</p>",
                                                    "policyManagerId": 8,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0532decd-f9b7-4b96-99cf-8cccaafeff20",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6424a23f-70ed-4dd9-b1a9-24ef664943e9",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "79bc3505-d590-48a1-97f2-5f7b7515d985",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d59a68c-d737-49f5-8e81-0e2d33340c38",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "172962b1-2eb7-4317-b178-013492a019f6",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "df455da9-6abd-4bc3-9107-7779e324d428",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "28014f3d-3105-42f4-a2d7-6e67a20ba968",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1104,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                    "name": "Are procedures in place to ensure all necessary risk assessments are conducted, with findings recorded and proportionate control measures put in place?",
                                                    "descriptionHtml": "<p>Schools are required by law to assess risks and put in place proportionate control measures. Schools must also record details of risk assessments, the measures taken to reduce these risks, and expected outcomes.&nbsp;</p><p><br></p><p>Findings of a risk assessment need to be recorded by identifying the hazards, how people might be harmed by them and what the school has in place to control the risk.&nbsp;&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant risk assessments.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T10:46:54.562+00:00",
                                                    "originKey": "891f8980-d798-4ef8-aa1e-0fd0852dcf63",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE‘Health and safety: responsibilities and duties for schools’, ‘Assessing and managing risks’&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "494f08f1-44c9-4adb-9f29-1b2d5e930e23",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8b15d295-bf44-401f-98f0-c03798530f97",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8e4d6d55-23a1-4632-9723-8a89a44f3864",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b4b766f-583a-48f3-b9fe-e8788798597a",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f22210b-225f-423a-ae8e-fd1bb3f5e899",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8092b94d-7289-4db4-968d-4a1cfc6ecd25",
                                                            "requirementId": "65e965dc-ea39-4e9e-b062-993e3b55015d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fbeec2d9-f486-4f43-a317-731631d016ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1105,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                    "name": "Has a Fire Safety Risk Assessment been conducted? ",
                                                    "descriptionHtml": "<p>Schools are required to undertake risk assessments to identify the general fire precautions needed to safeguard the safety of occupants in case of fire, including their safe means of escape. Schools need to make sure their fire risk assessments are kept up-to-date and are reviewed in detail when any significant alterations are made to the premises.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school’s risk assessment.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:06:41.468+00:00",
                                                    "originKey": "afa43901-ca04-402f-9cf1-934d415f227f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA 'Fire safety in new and existing school buildings’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every six months</p>",
                                                    "requiresReviewInDefaultMonths": 6,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1b377084-4bc5-4653-ad01-a751a7e1a7ec",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "427818fb-afec-4ac5-93a3-1c76872fbc22",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a4b089a3-7b2c-4896-ae5d-9ef45c8fed7e",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1e0e478-8ed5-497e-ad54-0be5961ef98d",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a08561ea-0e72-44e4-9f98-8e212a04f04c",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b3cc5e23-ec64-4e8d-8317-02ad8b014a82",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c983698c-39ca-4cde-b340-a95ebbf27826",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1108,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                    "name": "Have all staff received appropriate health and safety training?",
                                                    "descriptionHtml": "<p>Schools must ensure that staff receive information and training about health and safety, including:</p><p><br></p><ul><li>How to assess risks specific to their job.&nbsp;</li><li>How to meet their roles and responsibilities identified within the Health and Safety Policy.&nbsp;</li></ul><p><br></p><p>Staff whose work involves a greater element of risk will need extra or specific training.</p><p><br></p><p>You may wish to attach or link to relevant training records or documentation.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:11:55.134+00:00",
                                                    "originKey": "1b0b8827-f9e7-4615-8dec-27eb5871844f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Health and safety: responsibilities and duties for schools’, ‘Staff training’&nbsp;&nbsp;&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0074a315-26cf-4cfc-970c-e6cc2228658b",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c8a4947-1591-4d03-b0e3-5ddc4f81544d",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "66ed23ea-dc68-4288-b260-59752c1bffce",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1477fd8-c5b5-4986-8a48-fa0fa74e0ae3",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "13f3415b-d18f-40bd-a550-2ba1ba48aea9",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "15dba486-a1dd-4fb7-8304-c4f8606f1be8",
                                                            "requirementId": "de23299e-420e-4b3e-ad70-fed39c39c1e5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9ce1d094-da20-4fcc-a0af-43017a3fa7aa",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1109,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                    "name": "Do all staff meet their legal health and safety responsibilities? ",
                                                    "descriptionHtml": "<p>The law requires employees to:</p><p><br></p><ul><li>Take reasonable care of their own health and safety and that of others who may be affected by what they do at work.</li><li>Cooperate with their employers on health and safety matters.</li><li>Do their work in accordance with training and instructions.</li><li>Inform the employer of any work situation representing a serious and immediate danger, so that remedial action can be taken.</li></ul><p><br></p><p>Staff also need to follow their school's health and safety procedures.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:13:23.828+00:00",
                                                    "originKey": "a04c9348-6182-424b-b5fa-c6252dae35c4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Health and safety: responsibilities and duties for schools’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1149a3f4-ebb0-4ab2-91f3-4c42729a0df9",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "42fc6fc6-79ba-43b3-9637-1ef7cd726e5b",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "750408f0-a0eb-430e-8a7b-eb7b53847f01",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b1a708dd-c45f-4daf-ab47-b92d8a597d05",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4e287e53-df79-4306-9d04-8cabb69968fc",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9e6955fe-9b0c-438c-9556-a5f32649569c",
                                                            "requirementId": "221142d4-96f6-4a81-9530-d04e8225121c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e4aecee1-ba2a-4134-814e-15ee35104593",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1110,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                    "name": "Does the school have procedures in place to report report certain accidents, diseases and dangerous occurrences?",
                                                    "descriptionHtml": "<p>The Reporting of Injuries, Diseases and Dangerous Occurences Regulations 2013 (RIDDOR) requires employers, and other people in charge of work premises, to report to the HSE and keep records about work-related accidents which cause deaths, reportable injuries, cases of certain industrial diseases, and dangerous incidents with the potential to cause harm. Your school must have procedures in place to report these.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:14:32.058+00:00",
                                                    "originKey": "e5912597-ac71-416f-9639-3ceb38a9743c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>RIDDOR</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2aae7097-f4b8-4208-b249-05d27c52d6a1",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d21082ee-5874-4a0b-85f4-ec8178445db4",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de9ec429-7e80-44bf-9dbb-608476324509",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e48ad45d-6dd3-4c61-9614-6325f32b805c",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "648a731e-23ba-4b40-a6a3-cd7604b28dad",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fd55a87b-cf9e-46cd-8026-fb686edeac43",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "057762d4-4496-4cdc-baa5-2e2777261b5d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1112,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                    "name": "Is the school registered with the Information Commissioner’s Office (ICO)?",
                                                    "descriptionHtml": "<p>Under The Data Protection (Charged and Information) Regulations 2018, schools must register with the ICO. Every organisation that processes personal data is required to pay a fee to the ICO – this will need to be renewed every 12 months.</p><p><br></p><p>You may wish to attach or link to confirmation of your registration.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-27T08:16:51.52+00:00",
                                                    "originKey": "9ccd7fab-c05e-4b30-9d06-6cee7ff21632",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts' </p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1f5b88d5-cac7-4b60-8aaf-40788d7a5a38",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3ca344c6-39dd-48b0-808c-8b54645a221d",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91501252-4ee6-46e6-b5ac-019302b54e64",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e490932b-75f1-424f-9fc1-2de0fc016b1a",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "19c60e2a-aeb6-4194-a127-72cd46ea422c",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8d36db81-6ec0-41fc-9003-907948c354be",
                                                            "requirementId": "fdf945db-5c53-4c79-adfd-d54407511d2a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "190423a1-1324-4f7b-96db-0e936d89ec79",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1115,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                    "name": "Do the monthly management accounts include a variation to budget report?",
                                                    "descriptionHtml": "<p>Academy trusts must include a budget monitoring report on a monthly basis that details any variance to the budget. This allows the trust to establish whether they have over/under spent during the period, allowing for an effective review of its forecast for the year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:03:05.137+00:00",
                                                    "originKey": "b82ac166-38c5-4b89-8a96-f7eb482671d4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA (2019) ‘Academy trust management accounting’, ‘Regulatory obligations’ p.1-2</p><p><br></p><p>EFSA ‘Academies financial handbook’, para.2.21</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement monthly.</p>",
                                                    "requiresReviewInDefaultMonths": 1,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2806b554-c15a-43c0-b667-e6b097563017",
                                                            "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4261f5c1-5c52-4fd7-83bc-9afb07ea3b9b",
                                                            "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b85ea1a-cfb8-4efa-b782-ab9f736a94bd",
                                                            "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "daba83e2-40c9-4504-91a9-b6a3c0b155ee",
                                                            "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "303639cb-5030-4bc2-a742-acdec22a7706",
                                                            "requirementId": "fa0fcbba-9f94-4e0b-8d62-532af170dadc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a67c44ae-ab10-46fd-8a5c-576f2ae58bc5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1116,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                    "name": "Does the trustees’ annual report include key financial performance indicators?",
                                                    "descriptionHtml": "<p>The trust must select key financial performance indicators and measure its performance against them regularly, including analysis, in its annual trustees’ report.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:07:28.944+00:00",
                                                    "originKey": "3cfc545e-9429-4ae8-b2ca-a4720feb56b3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA ‘Academies financial handbook 2020’, para.2.22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "477c6f20-3be2-4823-b7d9-fda2d2ae8158",
                                                            "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "87ff90bc-d9ac-4df7-a9f2-d4d6271b2e74",
                                                            "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b09f5bcc-f420-41c5-871d-b432584c6800",
                                                            "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "faf8f025-2e42-4357-afe5-0d69ed7a5c9b",
                                                            "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6922e7ae-b0df-41a5-8374-a913cf7957eb",
                                                            "requirementId": "f2d1c7d3-7573-49ff-954f-8be310110b09",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bdb4787e-8f2b-458d-b1fd-8217601d8da2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1117,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                    "name": "If the school has 250 or more employees, has the gender pay gap been reported via the government’s reporting website?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010 (Specific Duties and Public Authorities) Regulations 2017, organisations with 250 or more employees must use the <a href=\"https://www.gov.uk/report-gender-pay-gap-data\" rel=\"noopener noreferrer\" target=\"_blank\">government’s reporting website</a> to report the gender pay gap in their organisation. Maintained schools must report by 30 March each year. Academy trusts must report by 4 April each year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:13:47.372+00:00",
                                                    "originKey": "8ebdb911-cbfc-4ab7-9f0d-e64cd077edd3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA ‘Academies financial handbook 2020’, para.2.32</p><p><br></p><p>Advisory, Conciliation and Arbitration Service and Government Equalities Office (2020) ‘Gender pay gap reporting: overview’, para.1-5</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7462fd4d-6ac1-4f27-99f5-7bd1649f71b5",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7bab59e1-613b-469b-82eb-1fd274edc1fd",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ee9a2eb7-ad55-47c9-9514-ae871d817375",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f6a0854e-ce6e-40b1-b9d7-1d81bd97b8fb",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a190b62-9c0a-46a8-b457-890671fcbaff",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "844c970c-ca71-4691-bc90-1062f51de7fa",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dfed9cbb-b7e1-472f-8ca0-26815b8e5a32",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1118,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                    "name": "Has the annual report and accounts been produced, audited and submitted?",
                                                    "descriptionHtml": "<p>Trusts must maintain adequate accounting records and prepare an annual report and accounts in line with the Charity Commission’s Statement of Recommended Practice (SORP) and ESFA’s Accounts Direction. These accounts must be audited. The funding agreement will describe when the accounting period ends; typically it is 31 August. The audited report and accounts must also be submitted to ESFA by 31 December, published on the trust’s website by 31 January, and filed with Companies House (usually by 31 May).</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:26:01.372+00:00",
                                                    "originKey": "c4306f1d-fc6c-48c4-be65-96f055f9516e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by 31 December each year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "195a03fa-5b52-4724-8fa6-3e2ec97ee093",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "446f087a-ea0d-4f31-bea0-b0cd4e5402f1",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e03d59c-bb50-408c-aee1-cffc93e93604",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3c5eac3-3fd2-4f93-ab7f-cf0c5aa6a73b",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a8819cc7-3990-43b4-975e-d541cef0fabe",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d8400662-3f5b-4f7b-ac4b-7936ad592c54",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1119,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                    "name": "Is there a statement on regularity, propriety and compliance as part of the annual accounts?",
                                                    "descriptionHtml": "<p>The ATH requires the trust's accounting officer to complete and sign a statement on regularity, propriety and compliance each year and submit this to the ESFA with the audited accounts.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:29:09.193+00:00",
                                                    "originKey": "4e2b2017-cee2-4b49-9870-bfbe213ab2f0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by 31 December each year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4ba8a7e0-4c96-4a59-a9af-b109e5f18dc3",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "51b3778f-227f-448b-8d38-d19a36dfecd5",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c54e5ad0-0ca8-467d-90ca-f3f324b34b0d",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff100e6e-7b7f-4be2-8ec1-10624308aadc",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6a69fab3-acb7-434a-bab7-ca3356c5431a",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4784b67b-6a31-42a1-bfde-ca04e70234c6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1120,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                    "name": "Has the school provided annual reports for the parents of pupils with SEND?",
                                                    "descriptionHtml": "<p>Your school must provide an annual report for the parents of pupils with SEND about their child’s progress. It is common practice to go beyond this and provide more regular reports to parents on how their child is progressing.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-01T10:19:10.733+00:00",
                                                    "originKey": "31441842-270a-4695-845e-a86459b0da3a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2019) ‘Special educational needs and disability code of practice: 0 to 25 years’, para.6.64</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, though your school may produce more regular reports.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5a182078-f333-4ce3-8ba2-f5b2472934ed",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d577e75a-6aac-4ec8-89a8-0ab8054870e7",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6436817-a174-4127-83c5-68e6e3a4dc44",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "52547e07-bf8f-445a-a7bd-2f9ab4f92e0b",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f61362ca-cc6d-4d19-ab24-eb5f3d3e7ac1",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "16ee3e7c-ae05-45f8-b554-c60eedbb72a3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1125,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                    "name": "Does the school have an up-to-date Data and Cyber-security Breach Prevention and Management Plan?",
                                                    "descriptionHtml": "<p>Academy trusts must be aware of the risk of cyber-crime and&nbsp;put in place proportionate controls to manage these risks.</p><p><br></p><p>A comprehensive plan can help trusts to uphold high standards of security, take suitable precautions, and have systems and procedures in place to support this.</p><p><br></p><p>You may wish to upload a copy of your plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T08:52:16.694+00:00",
                                                    "originKey": "7888636a-172f-4941-ab93-1758c62693a5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>https://www.itgovernance.co.uk/blog/gdpr-compliance-and-information-security-reducing-data-breach</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "54055372-0616-4255-9c69-771f7dcdd220",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c04fa44-3235-4631-9a4e-4ca5021a7c97",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c6ef3f88-8198-47bc-9ac8-8952b708255c",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea9464d4-44d9-4177-b30f-c42cb2903648",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "dc514b2e-a596-491c-9b72-e04858fdb1c6",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "427cd56d-75d2-4481-b04c-55661e831bf3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1131,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                    "name": "Are there procedures in place to ensure a lawful basis for processing data is determined and documented?",
                                                    "descriptionHtml": "<p>Schools must have a valid lawful basis for all their data processing activities – these are:</p><ul><li>Consent</li><li>Contract</li><li>Legal obligation</li><li>Vital interests</li><li>Public task</li><li>Legitimate interests</li></ul><p><br></p><p>If special category data is being processed, both a lawful basis for processing and a special category condition for processing in compliance with Article 9 need to be identified. If data about criminal convictions, criminal offences or related security measures is being processed, both a lawful basis for processing and either ‘official authority’ or a separate condition for processing this data in compliance with Article 10 need to be identified.</p><p><br></p><p>Any basis that is used for processing needs to be documented. You may wish to attach or link to relevant documentation.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:28:10.18+00:00",
                                                    "originKey": "cdf6a57e-5b31-4dfc-a55e-5dba0753b972",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/lawful-basis-for-processing/</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "056efddf-d397-4cbf-8f2f-72d5a847b73c",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "644fb43e-7841-4748-a42c-d951d09b7051",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81958b95-e537-4d94-ace7-a2df305f8b61",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e92d5b40-0fa1-4d4f-bcdf-e7a4f900425d",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "655b0aa9-6fd4-4e38-a782-70bbb2336ece",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c1c3f1a8-199a-4207-ac8b-9a02b48dbd1f",
                                                            "requirementId": "58148bba-5085-4c35-b2ea-9166853cb31c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ed478c09-c316-4d4c-b5f7-9ead8e7fa7fb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1132,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                    "name": "Does the school have processes in place for obtaining, documenting and reviewing consent that are compliant with data protection legislation?",
                                                    "descriptionHtml": "<p>Where consent is being used as the lawful basis for processing data, schools must ensure it is properly obtained for the process for which they require the data.</p><p><br></p><p>You may wish to upload details of your processes for obtaining, documenting and reviewing consent.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:30:47.739+00:00",
                                                    "originKey": "451c3797-af6d-40cd-a4af-6157647ee5f1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1ca2d9fa-ab28-4282-939c-cae3d91f24cd",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a01be6a-96bc-40b2-9b2b-89c48dc7574c",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a80d3ca4-16ae-443b-a010-ee6c29697476",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b1fb4328-e797-48ff-b937-d9e0a3872e45",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2434e9d2-c572-4789-8c95-e125bcbe83b9",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "965a164b-17d7-4aa8-8ef3-6bd73a2bb36c",
                                                            "requirementId": "116fb44b-e560-4683-a38b-874d722a41d9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a85a59ff-7b6d-4f33-ae3b-4430c5943c6f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1134,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                    "name": "Has the school undertaken a data protection impact assessment (DPIA) for processing that is likely to result in a high risk to individuals?",
                                                    "descriptionHtml": "<p>To meet their duties under the UK GDPR, schools must undertake a DPIA where a type of processing is likely to result in a high risk to the rights and freedoms of individuals.</p><p><br></p><p>You may wish to upload a copy of your assessment.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:44:41.09+00:00",
                                                    "originKey": "1b67c5c2-e265-4f90-b12e-dd39960c699c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4680a805-fbee-401d-84bf-2d673e17453d",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4fe14d96-7014-45be-a155-9d557979a19b",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9a405d50-73d7-4e9f-8b02-a1eed5f52844",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dc279982-a155-404a-91a9-16b5cf443fe0",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ec744c3-ff7a-4d1f-a631-3b26a7e4bb50",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8e108cda-8b53-4767-bedc-d98ea34f0229",
                                                            "requirementId": "e66c00b9-e79c-4ea7-8024-a5985ad0f26c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "09ff073b-41b7-4636-a3e9-26bd5dc1224c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1135,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                    "name": "Has the school appointed a named data protection officer (DPO), ensured all staff are aware of who this is and what their role is, and provided the DPO's contact details to the ICO?",
                                                    "descriptionHtml": "<p>Schools are required to appoint a DPO. This person’s role is to oversee the school's compliance with the requirements of the UK GDPR, Data Protection Act 2018 and other data protection legislation. The DPO must be independent, an expert in data protection, adequately resourced, and report to the highest management level. A DPO can be an existing employee or externally appointed.</p><p><br></p><p>Schools also need to make sure staff are informed of who the DPO is and provide the DPO's contact details to the ICO.&nbsp;</p><p><br></p><p>You may wish to attach or link to evidence to show your school has a DPO.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:46:27.732+00:00",
                                                    "originKey": "a9b5190f-112d-44f6-86e8-e7fdedacdc08",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/accountability-and-governance/data-protection-officers/\" rel=\"noopener noreferrer\" target=\"_blank\">https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/accountability-and-governance/data-protection-officers/</a>&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "40d915cc-ac08-4ce7-8dbd-b24d50fd833a",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0bbbbcd-c11f-433e-8b44-7ac3287eb8e7",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dc0a940f-942a-47c2-84c7-73c9dcae3bac",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f4fedfe3-582f-4778-91a6-f82945a5d13f",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8f732e67-42b4-48ea-a36e-9fcb004388ff",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f82f2507-a0bf-4d8a-a333-13c3ef899b4b",
                                                            "requirementId": "d6ff8d65-ecf2-4661-aa5b-bdd5584de648",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "28289580-04f3-4050-9bfc-8f1ade04fd84",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1139,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                    "name": "Does the school have procedures in place to report all reportable data breaches to the ICO?",
                                                    "descriptionHtml": "<p>Schools are required to report certain types of personal data breach to the relevant supervisory authority. Schools must have robust procedures in place to be able to report these within 72 hours of becoming aware of the breach, where feasible. You could attach or link to where these procedures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:54:48.015+00:00",
                                                    "originKey": "eb853d08-fed6-4885-966e-a0e8f1f0a4cc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "03662060-4860-4f03-8628-2e647a753d94",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "473e39c1-d93c-4eab-b49e-c9a555090b00",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "68845f34-613f-4667-8d13-5fc4aae9d680",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f80652d-0049-4144-8629-29ba0145f5d5",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "13557f24-b913-466a-a787-85bb67315613",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f9ac1a96-6d9d-4d24-bf1f-58e884fa37a9",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ca3d7661-80c8-4008-98a8-dbe14cf67034",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1140,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                    "name": "Does the school maintain an up-to-date data breach log?",
                                                    "descriptionHtml": "<p>Schools must keep their own record of all data breaches in an inventory or log. The log needs to contain:</p><ul><li>The facts surrounding the breach.&nbsp;</li><li>The effects of the breach.&nbsp;</li><li>The remedial action taken.&nbsp;</li></ul><p><br></p><p>You may wish to attach or link to your school's log.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:57:21.786+00:00",
                                                    "originKey": "53c4b591-4d69-47d9-b374-eee074a80808",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p><a href=\"https://www.theschoolbus.net/article/data-breach-log/5602\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.theschoolbus.net/article/data-breach-log/5602</a>&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "248e939d-9e90-4288-a4b5-7e1823668794",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "305c0ea5-8339-41c1-a3c4-ffbc26654a17",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6eb03a6c-49b3-4630-9958-ce2ef6e70eb8",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7e1c67d-01e2-4898-8f51-198aa7ede07d",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a73031d8-1edc-4d62-aece-f93def9087a1",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cdfe5391-4c0c-4a1b-a030-4277cfdecf1e",
                                                            "requirementId": "d1bd61b8-5958-47eb-b507-5f7130def7c7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "414084d3-0dee-4d70-ac5a-eb2f0efa7b34",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1144,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                    "name": "Has the school ensured all contracts and agreements are  compliant with data protection legislation?",
                                                    "descriptionHtml": "<p>Schools must ensure all agreements with parties they share data with or who process data on their behalf are in line with data protection legislation, including the UK GDPR.&nbsp;</p><p><br></p><p>You may wish to upload copies of any agreements you have.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T10:12:36.093+00:00",
                                                    "originKey": "5f091d5f-318e-46ce-803d-45723f9a9dad",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2fea9389-8e57-4e74-96e1-649b3a44aa50",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3305fc25-2dd2-444b-9c1d-ff61a458aa0f",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c938d277-911f-4f6f-80d0-c499750eed36",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fb3e114e-67f3-4e41-9d93-a40789e9026e",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "57ce7b78-8745-48d9-a761-7415456c925a",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7b39351c-4936-460d-94a4-216aa4de70b0",
                                                            "requirementId": "48f62656-6f67-4bd1-9507-65e46b043f37",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "53dd7e69-24a1-42c7-9b57-70a6f85caceb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1146,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                    "name": "Has your school published its admissions arrangements?",
                                                    "descriptionHtml": "<p>Mainstream academies and schools whose governing boards determine their admission arrangements, e.g. most foundation schools and voluntary-aided schools, must publish their school’s admission arrangements on their website by a date specified by the DfE each year, usually in March. This also includes publishing a timetable for hearing admission appeals – this must be published by 28 February each year. The school’s admission arrangements must comply with the DfE’s ‘School Admissions Code’ and ‘School Admission Appeals Code’.</p><p><br></p><p>This is not a statutory requirement for special academies unless specified in the trust’s funding agreement. You could upload where this information has been published as evidence that this requirement has been fulfilled. If this requirement does not apply to you, click 'does not apply' from the options.</p><p><br></p><p>You may wish to upload a link or screenshot to where this information has been published as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:21:14.372+00:00",
                                                    "originKey": "ccf0166a-5a9c-475d-87bd-561a2fd924bb",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>The School Admissions Code, The School Admission Appeals Code</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1b25559b-1ce7-4a34-a7a3-aea4ffc323a7",
                                                            "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8b3ba4f6-4eac-4871-8984-4f8139afc364",
                                                            "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ef29eaa1-8e4e-4a51-b082-59a56763d6a9",
                                                            "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "701a0757-b5e7-49fa-9d04-ec182c336d71",
                                                            "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c72adafa-3e00-4d5f-a4b7-bcc285d6b520",
                                                            "requirementId": "ecf34f28-e8da-4a15-b85c-ed6a583a726a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0a521369-6ec4-48ae-86f7-a83ffdda92e6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1149,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                    "name": "Has your school published its latest Ofsted report?",
                                                    "descriptionHtml": "<p><span style=\"color: black;\">Your school must publish either: </span></p><ul><li>A copy of its most recent Ofsted report</li><li>A link to the report on the Ofsted website&nbsp;</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:26:39.601+00:00",
                                                    "originKey": "06c7cb9f-d025-4833-bef6-fa686432cf0c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies/maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "29269ddb-56e4-488f-b34c-eafab4f1bbf2",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4cb95e52-bc5a-43f5-9130-0e1774ec61b3",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "83856421-f880-4349-b2e9-7001b0752c74",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f77f6285-177d-4d1e-91ee-5cf07f7ae031",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4fec3728-86f2-40e6-90de-b8e4fd065d9c",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d49c141b-49fa-472c-99a8-8743819684b0",
                                                            "requirementId": "5e3d19e8-782c-4819-ab82-72d6efafccdd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fa0b0021-da6a-4077-8ec6-4fee01667f98",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "760517fc-8ae8-43a0-873c-2af3603d7470",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1150,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "760517fc-8ae8-43a0-873c-2af3603d7470",
                                                    "name": "Has your school published its latest KS2 results?",
                                                    "descriptionHtml": "<p>You must publish your school's KS2 performance measures, which include:</p><ul><li>Progress scores in reading, writing and maths.</li><li>The percentage of pupils who achieved at least the expected standard in reading, writing and maths.</li><li>The percentage of pupils who achieved at a higher standard in reading, writing and maths.</li><li>The average ‘scaled scores’ in reading and maths.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:28:57.683+00:00",
                                                    "originKey": "ae0b31f0-c724-4ef5-b9a5-207f3e2d359b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "f3913f03-d449-41ae-b3d5-fe7010244cf5",
                                                            "requirementId": "760517fc-8ae8-43a0-873c-2af3603d7470",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "21c8a5b9-0a15-4321-9b66-04a6e81f6439",
                                                            "requirementId": "760517fc-8ae8-43a0-873c-2af3603d7470",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3e0718cb-e392-405c-8daf-be7c83d7dfed",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6b5cd3c0-d999-4979-8af1-f436e54d485b",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1151,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6b5cd3c0-d999-4979-8af1-f436e54d485b",
                                                    "name": "Has your school published its latest KS4 results?",
                                                    "descriptionHtml": "<p>Your school must publish its KS4 performance measures, which include:</p><p> </p><ul><li>The progress 8 score.</li><li>The attainment 8 score.</li><li>The percentage of pupils achieving grade 5 or above in GCSE English and maths.</li><li>The English Baccalaureate average point score.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:32:20.511+00:00",
                                                    "originKey": "fa849e81-3997-468e-b99f-7d9036a2c433",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "e2ddd20f-1e82-4424-9dd7-50e7fa6c589f",
                                                            "requirementId": "6b5cd3c0-d999-4979-8af1-f436e54d485b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6b101c77-4920-448f-b76b-023c2190c538",
                                                            "requirementId": "6b5cd3c0-d999-4979-8af1-f436e54d485b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6ab49eba-c6dd-4f9b-bc71-c45badd262e7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1154,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                    "name": "Has your school published its performance tables?",
                                                    "descriptionHtml": "<p>You must publish a link to:</p><ul><li>The school and college performance tables page.</li><li>The dedicated performance table page for your school.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:40:59.893+00:00",
                                                    "originKey": "6d1b1bdf-8c28-439a-97e6-e73b8082492d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5d1d00f4-c9ed-48d6-aaae-7ed30285480f",
                                                            "requirementId": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d54a2a3-3fac-4c24-a196-25afe4aaa52a",
                                                            "requirementId": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f29ee8ef-2675-40e9-8833-f86fcf21436d",
                                                            "requirementId": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "50a4f107-43a7-4975-ad51-1b03ebe760ae",
                                                            "requirementId": "b8d088cd-bd93-47e8-8f48-b86b8d1cdf1e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e95d25bd-342f-45bb-a0b4-03194fba279d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5fe9372c-3da1-44d3-bd0d-323c74e03a99",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1155,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5fe9372c-3da1-44d3-bd0d-323c74e03a99",
                                                    "name": "Has your school published its primary curriculum information?",
                                                    "descriptionHtml": "<p>You must publish:</p><ul><li>The content of the curriculum in each academic year.</li><li>The content of the RE curriculum, even if it is taught as part of other subjects or called something else.</li><li>The names of any phonics or reading schemes used in KS1.</li><li>How parents and members of the public can find out more about your school’s curriculum.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:43:10.451+00:00",
                                                    "originKey": "06938398-753f-41c6-81ca-6ed2d5743109",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "f3bc1557-9199-4fb0-b5ec-a80e77f93d5e",
                                                            "requirementId": "5fe9372c-3da1-44d3-bd0d-323c74e03a99",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b1efb60d-432c-4c27-9914-046ab6d19e75",
                                                            "requirementId": "5fe9372c-3da1-44d3-bd0d-323c74e03a99",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b895c2c8-7eca-4497-b0f1-1c5a5a5f4544",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "271e6e81-610e-4084-9faa-cd395fe44ea9",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1158,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "271e6e81-610e-4084-9faa-cd395fe44ea9",
                                                    "name": "Has your school published information on phonics reading schemes?",
                                                    "descriptionHtml": "<p>Your school must publish the names of any phonics or reading schemes used in KS1. You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:51:30.188+00:00",
                                                    "originKey": "c1db404b-6756-471b-9b33-83aebcc58e53",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "da24f9c1-03ff-4798-871a-2a0959cc8926",
                                                            "requirementId": "271e6e81-610e-4084-9faa-cd395fe44ea9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "11a05dd8-03c5-451f-9216-73fd57aa5ae6",
                                                            "requirementId": "271e6e81-610e-4084-9faa-cd395fe44ea9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "988e7ae2-4b9a-4945-907d-42ef5e8278cd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f73a59eb-54a5-4b5a-bd6d-6d80eca6fcef",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1159,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f73a59eb-54a5-4b5a-bd6d-6d80eca6fcef",
                                                    "name": "Has your school published information on courses available to pupils at KS4?",
                                                    "descriptionHtml": "<p>Your school must publish a list of courses and GCSEs offered to pupils in KS4. You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:52:42.773+00:00",
                                                    "originKey": "7c2cf430-65aa-4dd7-8e19-c5a0b8dcaa2b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b2647114-bb54-4f75-a71f-a5dd4f733e82",
                                                            "requirementId": "f73a59eb-54a5-4b5a-bd6d-6d80eca6fcef",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c213e4cb-6b5f-41b9-a8ad-82f81b403480",
                                                            "requirementId": "f73a59eb-54a5-4b5a-bd6d-6d80eca6fcef",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5d9a78ee-8df6-4a96-8f0b-2a01ce8d2f11",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8f585b9d-14f8-40e2-9536-19ff44c0c54a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1162,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8f585b9d-14f8-40e2-9536-19ff44c0c54a",
                                                    "name": "Has your school published a link to your school’s 16 to 18 performance tables page? ",
                                                    "descriptionHtml": "<p>You should publish a link to your 16 to 18 performance tables page.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:57:26.628+00:00",
                                                    "originKey": "3e5526d4-9253-4402-9a41-5cb189003a5e",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/guidance/what-academies-free-schools-and-colleges-should-publish-online#exam-and-assessment-results\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/guidance/what-academies-free-schools-and-colleges-should-publish-online#exam-and-assessment-results</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "87a17e9b-6eae-4d52-94b7-fe8fe4297b6f",
                                                            "requirementId": "8f585b9d-14f8-40e2-9536-19ff44c0c54a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b5f86ba7-67e9-448a-be2b-f2b279b3fe70",
                                                            "requirementId": "8f585b9d-14f8-40e2-9536-19ff44c0c54a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "853cf5fe-1560-4217-b4c0-62d507903a7c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1163,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                    "name": "Has your school published its Behaviour Policy, including the anti-bullying strategy?",
                                                    "descriptionHtml": "<p>You must publish the Behaviour Policy on your school website, including the school's anti-bullying strategy. This must take the form of a policy. You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:58:26.91+00:00",
                                                    "originKey": "11f842ef-29ae-4380-9ffe-d8e260ff5d12",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 3,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4baffa3c-cd98-43b9-856b-619d9450aab7",
                                                            "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "583a5b0f-9dee-4cd8-80f8-e78733c332c0",
                                                            "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81cfe3cd-1345-4182-9905-ecc7fc43f668",
                                                            "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ddb44d25-e8ac-4d52-8a07-58dca3e3275e",
                                                            "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3e1d8bd2-5b5a-4e93-9897-b9a99738c700",
                                                            "requirementId": "d76decce-438b-447d-be69-a0e11d7ad0e1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9cf77065-cece-408d-ab8c-ac083882732f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1164,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                    "name": "Has your school published its Complaints Procedures Policy?",
                                                    "descriptionHtml": "<p>Your school must publish details of its complaints procedure on the school website. You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:02:12.117+00:00",
                                                    "originKey": "ac866d78-5aa1-410e-a1f4-1e8eb22066f5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maiantained schools must publish online'</p>",
                                                    "policyManagerId": 12,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4b0840a8-e8c0-4ba4-869c-d1e782cc9a88",
                                                            "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9a2d8f7d-97c4-4140-8dfc-9de848c83ad0",
                                                            "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c9f01475-645d-4de7-9465-4b778cf04543",
                                                            "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec05b754-aeaa-4c02-b4cd-f2c202679406",
                                                            "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b865bedc-149e-4838-b710-4f56a73569a3",
                                                            "requirementId": "c5cb9ae0-1180-4153-85b6-0b886da4d994",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "86ea9b06-8807-40e8-9583-e9c58e6a6970",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1165,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                    "name": "Has your school published its pupil premium strategy for the current academic year?",
                                                    "descriptionHtml": "<p>Maintained schools are required to publish a pupil premium strategy statement. The DfE recommends producing a plan that spans three years, which is updated by the end of the Autumn term each year to reflect any changes to the use of the pupil premium and the needs of pupils.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:04:20.75+00:00",
                                                    "originKey": "9fbbbafb-838a-450d-815b-e996b96c5751",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "00d202bd-0300-4fbb-9963-e5be0789c30b",
                                                            "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "141711a7-4206-4354-aa85-dbbbc9fc227a",
                                                            "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8b07d85-744f-47e2-a49b-a7b43db865ee",
                                                            "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0c6b9ee-d63a-4bae-8d13-10c185db48b0",
                                                            "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "297bcf51-6175-47ae-8407-703aa6b0047e",
                                                            "requirementId": "6083e675-fbde-4df8-97f5-b2036d235ca1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "58ef61a1-57cf-4511-af1b-b2daf81b0eab",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1166,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                    "name": "Has your school published its pupil premium strategy for the current academic year?",
                                                    "descriptionHtml": "<p>Academies must publish a pupil premium strategy statement if it is stated in their trust’s funding agreement. If so, it must include anything outlined in the funding agreement about publishing the pupil premium strategy.</p><p><br></p><p>For academy trusts that do not have this stated in their funding agreements, it is still considered good practice to publish this information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:07:08.507+00:00",
                                                    "originKey": "3040b76f-673d-4670-9310-cac22859a362",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "038aaff6-90bf-49e8-84fe-866a731834d6",
                                                            "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "091d4eed-1917-454f-a332-12eaa81ff9f5",
                                                            "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "58f04613-d511-48cb-b857-a1706a41d2bd",
                                                            "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64c727ad-56a6-4c0d-9ae6-db62f09d7d35",
                                                            "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a9ab93cd-4ed7-4345-bc68-f84a205893d1",
                                                            "requirementId": "9b401676-8ca8-48c0-ba99-f6589b39365a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "13d9e594-c084-4878-b884-125e4d9e1702",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1167,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                    "name": "Has your school published information on the pupil premium for the previous academic year?",
                                                    "descriptionHtml": "<p>Maintained schools are required to publish how the pupil premium was used in the previous year. This can be part of the pupil premium strategy report.</p><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:09:16.16+00:00",
                                                    "originKey": "827b3683-49ea-44a4-bce9-831e44bed218",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5d5e3e5c-8cff-45d5-b9d2-6f0e3061e594",
                                                            "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6eed475-97a4-4ec4-bd65-0d11a2a23755",
                                                            "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e40eb688-f03a-460a-9764-b3f345b9228a",
                                                            "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e8c98ca5-b211-49f1-b08f-2e7a7c124e49",
                                                            "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b2f2f8f0-adb3-43d4-a270-12acee5c78f2",
                                                            "requirementId": "05651414-39b1-4cee-a038-a940e8e546c6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3ebd3e48-1930-4509-99e5-006da0478889",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1168,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                    "name": "Has your school published information on the pupil premium for the previous academic year?",
                                                    "descriptionHtml": "<p>Academies are required to publish how the pupil premium was used in the previous year if it is stated they must do so in their funding agreements. This can be part of the pupil premium strategy report. Academies that do not have this stated in their funding agreements are strongly recommended to publish this information anyway.</p><p> </p><p>You can evidence this by uploading or linking to any policies or documents outlining the school's procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:18:57.876+00:00",
                                                    "originKey": "7d57421c-d881-4793-a0c9-823a7a571d89",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3891ed7d-bf38-4fe5-babd-a83618e55699",
                                                            "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c544756-dc8a-4936-80af-3e17839443de",
                                                            "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a4eb50f-8dec-4fed-81d5-645d411a13c4",
                                                            "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9a197e0-2d01-4187-9a7a-802458175b4c",
                                                            "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "51e92dfe-663a-4f4d-b239-07080a2f838e",
                                                            "requirementId": "41ca9452-4e44-4a5d-a62e-9395d53d4768",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e7aa409d-5550-4eff-b198-eca4a22c7553",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "08a61c23-25b4-497e-9f79-b33a64773eaa",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1169,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "08a61c23-25b4-497e-9f79-b33a64773eaa",
                                                    "name": "Has your school published information on the Year 7 literacy and numeracy catch-up premium?",
                                                    "descriptionHtml": "<p>  If your school has received Year 7 literacy and numeracy catch-up premium funding, you must publish:</p><p><br></p><ul><li>Your funding allocation for the current year</li><li>Details of how you intend to spend your allocation</li><li>Details of how you spent your previous year’s allocation</li><li>How last year’s allocation made a difference to the attainment of the pupils who benefit from the funding</li></ul><p><strong>&nbsp;</strong></p><p><strong>Please note that the DfE has made the decision to discontinue the Year 7 catch-up premium; however, not date has been confirmed for when this will take effect.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:20:15.522+00:00",
                                                    "originKey": "de0b6063-561d-4a7f-8775-55704a19af70",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"http://marker.to/AMRwmk\" rel=\"noopener noreferrer\" target=\"_blank\">http://marker.to/AMRwmk</a></p><p>&nbsp;</p><p><a href=\"https://www.gov.uk/guidance/what-academies-free-schools-and-colleges-should-publish-online\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/guidance/what-academies-free-schools-and-colleges-should-publish-online</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "763d34da-fec1-496b-96cd-965bd1ad257a",
                                                            "requirementId": "08a61c23-25b4-497e-9f79-b33a64773eaa",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "229d1df3-edc1-448a-aa45-4be981248329",
                                                            "requirementId": "08a61c23-25b4-497e-9f79-b33a64773eaa",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b188212f-edb3-4d22-8d1c-83aa2dae8a4f",
                                                            "requirementId": "08a61c23-25b4-497e-9f79-b33a64773eaa",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b2ccb903-c7b1-49da-9b9c-9c10976d7ba0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a3110837-db9a-48c4-a27e-bc07f9281ff2",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1170,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a3110837-db9a-48c4-a27e-bc07f9281ff2",
                                                    "name": "Has your school published information on the PE and sport premium?",
                                                    "descriptionHtml": "<p>Maintained schools that receive the PE and sport premium must publish the following information:</p><p> </p><ul><li>The amount of funding received</li><li>A breakdown of how the funding was spent in the previous academic year</li><li>The impact of how the premium on pupils' physical acativity, participation and attainment</li><li>How the improvements will be sustainable in future</li></ul><p> </p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:21:53.767+00:00",
                                                    "originKey": "7e47ff47-d1fd-4472-ac77-6dbde35befcb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "59717df6-c681-4e68-ac6f-761bf746a8a9",
                                                            "requirementId": "a3110837-db9a-48c4-a27e-bc07f9281ff2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "857f5cf1-69c7-4ee2-ab47-c76b6581591b",
                                                            "requirementId": "a3110837-db9a-48c4-a27e-bc07f9281ff2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6f48d889-1f6c-4b1c-9650-b1573055f2d2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1171,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                    "name": "Has your school published its SEN information report?",
                                                    "descriptionHtml": "<p>Your school must publish a SEN information report. The report must cover how the school implements its policy for pupils with SEND. This requirement may already be fulfilled by publishing the school's accessability plan, providing it includes the required information about SEND.</p><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:24:32.512+00:00",
                                                    "originKey": "d46037a3-efc0-4e0d-9a17-22bdf31e11b9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p><p><br></p><p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 5,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1bdb18ab-a0f2-4f8c-8d7e-6c903f823b6e",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37779cd8-8af5-4917-b28f-e0a40c4d3324",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "80c8198b-4ea1-467e-acee-e4947eddd1e2",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8542601-4368-44fa-964e-8e027b68fdeb",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a40425d-f78d-407e-a08d-d6ba2be1cf22",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "96e15095-e1dd-44ab-9db6-c85bb8937377",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "453527d1-66cb-4dc7-b997-1fc1f9caa9c6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1174,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                    "name": "Has your school published its equality objectives?",
                                                    "descriptionHtml": "<p>You must publish your school's equality objectives. This can be done via the school website. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:36:18.63+00:00",
                                                    "originKey": "4c618120-82a7-4c5f-8d02-e38a97fb032a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p><p><br></p><p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement four years.</p>",
                                                    "requiresReviewInDefaultMonths": 48,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "027eab12-6aae-45ac-ac84-1fa9a71b84e0",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1c6ab2c2-0225-4696-95be-4b91188d70f3",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "48f9e2f2-f77c-4bd4-91fa-4ebe152e4311",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dc85a92-3c10-452a-9662-0fd448f47813",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a117d7d-ea57-435e-97ec-8721cf970cb8",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b6e92455-5f1d-4211-9946-03c0b3ff12cd",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6e95a364-da65-44b5-8822-c17442d6342c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1177,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                    "name": "Has your school published information on handling complaints from the parents of pupils with SEND?",
                                                    "descriptionHtml": "<p>Your school is required to publish its arrangements for handling any complaints from the parents of pupils with SEND about the school’s SEND provision. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:47:06.59+00:00",
                                                    "originKey": "97617b7c-a919-42db-84cc-e5727ae64a16",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online', DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 12,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4ae62842-7cd6-48c4-9855-bf7ce336405e",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c32ee70-9918-4a6a-9e99-bfed6b4ee4d0",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b3b7ca1a-62fe-46b8-b673-2f9a04408340",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "efac668a-543b-4bd7-bd7e-5f4df5b5975f",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "30e0388f-552f-409d-a0ab-14271bb9c3d9",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "380fdbf4-72af-4b23-aeea-26996603e97d",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e4ea87d4-34c7-451a-911b-0abf8d6973e4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1180,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                    "name": "Has your school published information on its governing board?",
                                                    "descriptionHtml": "<p>Your school must publish information about the governing board in line with the DfE’s ‘Constitution of governing bodies of maintained schools’ guidance.&nbsp;You may also choose to publish your school's conflicts of interests register, including a record of business and pecuniary interests, to help fulfil this requirement.</p><p> </p><p> You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:53:59.792+00:00",
                                                    "originKey": "1ce7fd4b-1315-4412-ad36-dc6a68fa79e2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "330a6aa7-8b15-4c79-9e67-732e073ceb46",
                                                            "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "582acefb-0df9-4cd3-a357-0f4d94456940",
                                                            "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8e8f6c1d-673e-4802-bdbc-768f57bc6779",
                                                            "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "edeb7b37-68e7-4dfa-a8b3-8c7dfeba3009",
                                                            "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8dafcfd1-5642-4853-a6e3-20b8eeccf2c2",
                                                            "requirementId": "b379b9ed-bf27-49fa-8290-67fc6b700954",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "156375a9-9412-4a42-9336-b2f11c37c7f1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1181,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                    "name": "Has your school published information on its board of trustees and their duties?",
                                                    "descriptionHtml": "<p>Your school must publish information about the trust board in line with the 'Academy Trust Handbook'.&nbsp;You may also choose to publish your school's conflicts of interests register, including a record of business and pecuniary interests, to help fulfil this requirement.</p><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:56:20.093+00:00",
                                                    "originKey": "17892c7f-3b30-43b3-b7e4-d054a6057e1b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "032b8daa-21bb-466d-9233-9bbe6a00dac2",
                                                            "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "517e9f7c-fe3e-4833-b912-0e369acf4a9d",
                                                            "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ae541f01-d8b3-4ada-8c77-16e8e0840b61",
                                                            "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d61172a2-ca9c-4744-b013-9e0af8cf4e9f",
                                                            "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "44c1a050-60d9-4d13-ae1b-6679b84cfde4",
                                                            "requirementId": "6bb79acf-eaec-4410-98c4-92285fe6a554",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "54cfc718-e25c-42df-99c6-3f03200f8cd2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1183,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                    "name": "Has your school published its Charging and Remissions Policy? ",
                                                    "descriptionHtml": "<p>Your school must publish its Charging and Remissions Policy. It Is strongly recommended this is published on the school website. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:59:24.949+00:00",
                                                    "originKey": "9c81d79d-67e9-4977-90bd-711448fd8519",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 2,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "64d50366-3f37-4fe0-9a56-2107ed750e6a",
                                                            "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c7fc99a-bca6-4ea7-b75a-0e37a62fb086",
                                                            "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0fe33c0-1bd0-4947-bb0b-1a5b88e2a92c",
                                                            "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8991b63-ca0f-4cb1-b554-f74b39f9756d",
                                                            "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "145c13f5-06c5-4423-a694-6bda2385acef",
                                                            "requirementId": "083ab3e7-aaae-4f12-b73a-08ffc4f44c6f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3b1edeb8-ee49-4fae-945b-6ca1893a90c6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1186,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                    "name": "If the school has 250 employees or more, has your school published the required information for gender pay gap reporting?",
                                                    "descriptionHtml": "<p>If your school has 250 or more employees, you must publish information on your school's website about the gender pay gap. Alongside these figures, schools must publish a statement confirming that the information is accurate and signed by an appropriate person – this can be the governing board. You can evidence this by linking to where this information has been published.</p><p><br></p><p><strong>Schools that do not meet the reporting threshold and have not chosen to publish this information anyway may mark this requirement as 'does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T14:02:59.155+00:00",
                                                    "originKey": "362f9e8a-666e-41d4-a766-0b3a88b929a5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "cf7031e6-55ee-448d-b736-dc7af7ae3856",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d364901c-2720-415d-b770-06edf576f156",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3dba175-aa09-463d-9fd3-b66d24176e9c",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e90316cd-106c-4dbc-bc1b-ddebbe8afde8",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "04875701-6622-4da2-90c6-8b8d84052a66",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3a8059a5-6c20-46e7-aa10-ec8c15c1bd83",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7bb3ec9d-69a9-4669-b182-e8642b4fdf1f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1187,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                    "name": "Has your school published information on executive pay?",
                                                    "descriptionHtml": "<p>You must publish how many of your school's employees have a gross income of £100,000 or more, if any, in £10,000 bandings. It is recommended this is published in a table format. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T14:04:55.38+00:00",
                                                    "originKey": "4b3d3c2b-6647-4200-8a1b-0f5c184bd293",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "9dfa78c9-beff-4c63-8c4c-40aeef5c392c",
                                                            "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c02e884a-e978-4003-9549-bfeebd6347ab",
                                                            "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e370d0a1-758c-4569-bbf2-16c74137f6e7",
                                                            "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1edb0e4-10d1-424c-9401-6a4f0e5da529",
                                                            "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "560d2649-af50-4011-a92b-084221955c99",
                                                            "requirementId": "0078d1fa-cce8-4f84-906d-b5e34f7bc3bb",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9878eedc-c328-4517-9d4a-8af938ff2e7c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1188,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                    "name": "Has the board of governors or trustees, or a committee of the board, approved admissions arrangements?",
                                                    "descriptionHtml": "<p>Admissions arrangements must be determined annually. Schools must consult on any changes to admissions arrangements, or, where no changes are made, consult on the arrangements at least every 7 years.</p><p><br></p><p>Where a school is its own admissions authority, the full governing board or board of trustees or a committee of the board is required to approve admissions arrangements. A good way to outline admissions arrangements is through an Admissions Policy.</p><p><br></p><p>Where a school is not its own admissions authority, it should work with their admission authority as required in determining arrangements.</p><p><br></p><p>You may wish to attach or link to your school's Admissions Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:19:34.938+00:00",
                                                    "originKey": "bb354b00-987d-4347-a059-6bcadf2e9b03",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p><p><br></p><p>DfE 'School admissions code'</p>",
                                                    "policyManagerId": 9,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0020a9d9-53c2-4eab-915f-7b10f9029107",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "424c01f0-1e0d-4947-81b7-e70bd16e70f9",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6594d701-106a-49b4-ae01-5f915c76d07c",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f503c631-05e2-490b-b4c6-fbde750dc731",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "77634180-da8a-409b-a6b9-f1676eb40210",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "84323dea-4f1a-445d-9f4a-aa47bc78af36",
                                                            "requirementId": "97380ace-c328-4874-ab8c-dd9fe9fee5b9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "90f42e77-515b-4d39-8acb-041f70ac01b2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1189,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                    "name": "Does the school have a Charging and Remissions Policy?",
                                                    "descriptionHtml": "<p>The DfE requires schools to have a policy on charging and remissions for school activities, and recommends that the policy is reviewed annually. The policy should address areas such as charging for education, optional extras, exam fees, voluntary contributions, refunds and remissions.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:34:12.258+00:00",
                                                    "originKey": "5441af32-cf40-4d35-93b6-02134828a844",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p><p><br></p><p>DfE 'Charging for school activities'</p>",
                                                    "policyManagerId": 2,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "228db389-420f-4456-9344-cf25d351eea8",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "56b2d899-9d26-44f3-bd63-f7a5e7318f88",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e5913036-3bdc-4c70-ba60-2a465cd68dd6",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff48c1d0-88fb-4be3-9247-9cd315892b16",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "14b4c192-119d-4ee4-8585-56ee2a953b20",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "626ad893-3c2a-4b1e-afd6-97c0c52d32d6",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b7671b49-0547-4e32-86fa-5f45b476515f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1190,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                    "name": "Does the school have a Data Protection Policy?",
                                                    "descriptionHtml": "<p>Schools are required to have a Data Protection Policy setting out procedures relating to data protection. This policy can be reviewed and approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>The policy needs to be made available to all staff and an annual record should be kept which shows that staff have read and agreed to the policy.</p><p><br></p><p>You may wish to attach or link to a copy of your policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:40:55.002+00:00",
                                                    "originKey": "565c4665-6800-4c26-b988-97748ed1b0d8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 7,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "15ed248a-0a0b-405f-96df-76894739a607",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1a09854f-1aa4-4e9a-a50f-f324d7855bf4",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2ce5825a-7aac-4c07-91a7-b7f74fecca9c",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a66a62c4-8e29-4443-a47d-21171954cc3e",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "32896e4a-d0cc-4e02-8b05-72102c719988",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7aeabc28-07b5-4e67-8c65-0c61751605f4",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4eed5f40-3131-465f-bf03-f63d5002752b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1191,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                    "name": "Does the school have a Protection of Biometric Data Policy?",
                                                    "descriptionHtml": "<p>Schools using automated biometric recognition systems are required to implement a Protection of Biometric Data Policy that is reviewed by the governing board or equivalent annually. You may wish to upload a copy of your policy.</p><p><br></p><p>If a school does not process biometric data, it is up to the headteacher to consult with the school’s legal advisors and decide whether a policy should be established for the protection of biometric information of children. The DfE advises that schools should state whether they collect biometric data within their SCR and/or Records Management Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:42:35.93+00:00",
                                                    "originKey": "2b0c8539-1255-48eb-951a-2f86617e9418",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 83,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6fc63bab-83cd-4550-aafd-e17aa9121b0b",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "73bc9325-1e99-4cf1-8ba2-1cf08e47568d",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82e9dad7-4c60-43a9-91fa-4a955629ee86",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "97e6a696-f6d1-40c3-8cfa-569a14924976",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8045f443-a4ed-49c8-897c-3964540004de",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f5a00317-0319-4462-b741-0805fa6338c7",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c77ace1b-7eae-45cc-85c9-51be5166ce7f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1192,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                    "name": "Does the school have an up-to-date admissions register?",
                                                    "descriptionHtml": "<p>The law requires all schools to have an admissions register. The admissions register must contain the personal details of every pupil in the school, along with the date of admission or readmission to the school, information regarding parents and carers, and details of the school last attended.</p><p><br></p><p>This should be approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's admissions register.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:46:25.507+00:00",
                                                    "originKey": "a32d285f-a52e-4caa-ac70-be95e2e2b8ed",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p><p><br></p><p>DfE 'School Admissions Code'</p>",
                                                    "policyManagerId": 88,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "350eb11d-fe18-4802-a041-121b71a699ed",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3f271996-2d60-429d-8c2d-bc9f9cad288c",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "65a68bdb-30c5-42d3-a799-0f7506d08163",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8de60c52-b47a-4ba7-be57-97be1370a409",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "447e3b62-8f34-49d0-8172-78a686a76d05",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e841e3c0-e0b3-472f-b7c6-73c314df73ba",
                                                            "requirementId": "c52a23a5-1fc0-44a6-a76e-43924ec4dfe6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fcf399ba-dcac-44b9-b3f5-b894816223b7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1193,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                    "name": "Does the school have an up-to-date attendance register?",
                                                    "descriptionHtml": "<p>It is compulsory for schools to maintain an attendance register for each pupil. Schools must take the attendance register at the start of the first session of each school day and once during the second session. This should be approved by the governing board, an individual governor or the headteacher.</p><p> </p><p>You may wish to attach or link to your school's attendance register.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:48:41.213+00:00",
                                                    "originKey": "df3bd7c4-ca47-4fb9-adae-bec07d1fab20",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts', </p><p><br></p><p>DfE 'School attendance'</p>",
                                                    "policyManagerId": 88,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2ec638c0-0af2-42ff-a759-790149b64e5d",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "63b2c279-aa7e-4050-a87c-aa96ff5a90c7",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "66819bb4-1d50-400e-a70b-71577f1f060b",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a40a4e31-5717-45d2-b3da-221b3de66bfe",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "227292ff-c599-4904-b70e-d249c5ba8998",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "35c7ad55-01c6-4689-9e92-4968cce87c6b",
                                                            "requirementId": "9c47eb47-95af-4851-94a6-9c982709f4c0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "754e3d9f-f01e-4f3f-807a-a887fdfcf59c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1194,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                    "name": "Does the school have the required information published on the website?",
                                                    "descriptionHtml": "<p>Schools have a legal requirement to publish specific information on their websites to comply with The School Information (England) (Amendment) Regulations 2012 and 2016 and other relevant legislation.</p><p>&nbsp;</p><p>This should be approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to link to your school's website.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:53:33.634+00:00",
                                                    "originKey": "fdf58a34-3530-4897-a77a-c6b810a10367",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(31, 67, 79); background-color: rgb(252, 255, 255);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt; [Accessed: 19 August 2020]</p>",
                                                    "policyManagerId": 17,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>This is a live document. Where no changes are made during the year, this should be reviewed annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(31, 67, 79); background-color: rgb(252, 255, 255);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt;</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "00d9fec4-bfdd-48c7-a8aa-8d11b1908fa4",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "55a3a1c4-e37e-44fc-9bb8-b19ac6b488ca",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6bda3d4e-5b57-406d-b253-a8c04bec958c",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7a627b44-0c31-4c48-b43e-728623437636",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a6c206a1-0808-4e28-8f9c-926bc1aa2a91",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c76acdfd-28cf-41e3-bc73-5f0b5dda3d85",
                                                            "requirementId": "8a5c6cf2-071b-48c5-8243-ad0fcc5d7536",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "357cfcaf-9102-4c47-9ffe-daf6bcd628ef",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1195,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                    "name": "Does the school have a Complaints Procedures Policy?",
                                                    "descriptionHtml": "<p>Schools are required to have a written Complaints Procedures Policy to deal with all complaints relating to the school and to any community facilities or services that the school provides.</p><p><br></p><p>This should be approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's Complaints Procedures Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:04:37.876+00:00",
                                                    "originKey": "29ab1b5c-dba7-4489-8315-807cd80c6108",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 12,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "7c4d44dc-a34e-4af4-be67-7e5d59a5ca15",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "24a44667-b8f8-4aea-adeb-5a579839d48b",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "340712fd-a9d1-4ff2-bee7-09d72c885a33",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c7bc385-eb33-4107-9bc7-abc43f579454",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e6594ac-493c-4039-9dbb-328a86143257",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3eda6092-1be7-4f98-8694-911158f6dce3",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "428bd2ef-ab45-4a91-bf01-4725ea8a43e4",
                                                            "requirementId": "02ce1b7f-fcc3-460f-bc61-7a25732a2562",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c6629c36-294c-4553-b5f0-d6bc92a4727f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1196,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                    "name": "Does the school have a Teacher Capability Policy?",
                                                    "descriptionHtml": "<p>Schools are required to have a policy covering teacher capability. The policy should set out the framework for a clear and consistent assessment of the overall performance of teachers, including the headteacher, and for supporting their development within the context of the school’s plan for improving educational provision and performance, and the standards expected of teachers.</p><p> </p><p>This should be approved by the governing board or an individual governor.</p><p> </p><p>You may wish to attach or link to your school's Teacher Capability Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:06:04.645+00:00",
                                                    "originKey": "50f0ef5c-2e77-4c78-8d85-8bd4bf7c2808",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 1,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "47da5b8f-9119-4c61-a417-9915b0121464",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7d6181b2-dd45-449d-a87d-c2d4449b6ef7",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8da19036-89a8-4d75-986b-d2679e6c59df",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dba79df5-9f75-4f9d-9d8b-dbe955ab92e1",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "82295df0-e341-42c5-9c51-5af522ed26a3",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9946aa26-5150-403a-82a4-b84d9db95956",
                                                            "requirementId": "2d4525ca-837d-4248-8852-249dae1ef098",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1d859701-01ab-4892-855f-f97c209a3186",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1197,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                    "name": "Does the school have an Early Career Teacher Induction Policy?",
                                                    "descriptionHtml": "<p>Schools must have an induction policy for early career teachers (ECTs), which has due regard to the ‘Induction for early career teachers (England)’ guidance. </p><p> </p><p>This should be approved by the governing board.</p><p> </p><p>You may wish to attach or link to your school's ECT Induction Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:07:24.664+00:00",
                                                    "originKey": "b081ccfd-dc5d-43c0-9562-538cc911922f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 81,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2f78ba47-a84a-4e94-bb30-81f463663005",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a20c75d-73b9-49ff-8960-5d3a042abfd4",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a920dbd0-12a9-48aa-bb7f-1761fc41e7a1",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ccecd20f-d77f-4d9f-ac79-19eade3b83cc",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "86a60e1d-51dc-4e31-b10b-e0cc103bcbfc",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f20dc1a3-9426-4f92-9887-cb95d3480dd7",
                                                            "requirementId": "416d5b02-6b6b-44cf-a86a-34c66a8920f5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a99f617a-3ccf-4828-b2fb-f50b96717b19",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1198,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "44e47765-c136-4010-8262-356ba2b7e101",
                                                    "name": "Does the school have a Staff Code of Conduct/Staff Behaviour Policy?",
                                                    "descriptionHtml": "<p>A Staff Code of Conduct must be in place to set out the expectations of the school leadership with regards to the behaviour, conduct and performance of school employees.</p><p><br></p><p>In maintained schools, the governing board must establish staff conduct procedures. In academies, the board of trustees can delegate this approval of conduct procedures to a committee of the board, an individual trustee or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's Staff Code of Conduct.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:08:53.601+00:00",
                                                    "originKey": "ba098f54-a91a-43ba-9bf8-0f48c98ed3f6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1d188668-adaf-49ce-9688-2bab6499fc78",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9f70b0d5-6a75-4511-85a4-b784a55577b6",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ac13881e-f149-43cd-b468-01cd76a1bff9",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb46d94e-c636-4ee3-93b4-b89ea0b5c228",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5c0ea8da-20df-4233-9f8d-f3f943d381b9",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6aa956e0-f394-4f94-bc88-832cbecdb373",
                                                            "requirementId": "44e47765-c136-4010-8262-356ba2b7e101",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5fc0b5dd-d803-4114-8614-8509e40c5f44",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1199,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                    "name": "Does the school have a Disciplinary Policy?",
                                                    "descriptionHtml": "<p>A Disciplinary Policy needs to be in place setting out the procedures in place to manage instances of unsatisfactory staff conduct or performance. The policy should serve to ensure disciplinary procedures are undertaken in a fair and consistent manner.</p><p><br></p><p>In maintained schools, the governing board must establish disciplinary procedures. In academies, the board of trustees can delegate this approval of disciplinary procedures to a committee of the board, an individual trustee or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's Disciplinary Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:10:58.894+00:00",
                                                    "originKey": "8eee742c-4b54-4475-82af-5c4756fd628a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts</p>",
                                                    "policyManagerId": 19,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "579201ad-3bf3-4c13-b1e3-dfe30233aeb4",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1a1f1c72-b252-4144-bc97-094ff66eb61d",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "244008bf-5465-4758-bad2-21d1f1592d5d",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "58dfef2b-1fcf-46ca-8bcb-a69cad267bdd",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b69ceff7-d9dd-476d-8d37-68aa2eedc0b7",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d5baac07-89ad-4a73-b376-c0b41cc4079c",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e2200140-f995-4668-b560-3d7424115f3e",
                                                            "requirementId": "c841c09c-ccf3-4c8e-9eab-86f41314d4bd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a9e599ff-056d-4259-9459-de900e4125d8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1200,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                    "name": "Does the school have a Grievance Policy?",
                                                    "descriptionHtml": "<p>A Grievance Policy must be in place for staff to raise a grievance, and should include what managers should do to handle grievances once they have been raised. </p><p> </p><p>This should be approved by the governing board in a maintained school. In an academy, this should be approved by the governing board, an individual governor or the headteacher.</p><p> </p><p>You may wish to attach or link to your school's Grievance Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:12:17.782+00:00",
                                                    "originKey": "41eee686-5780-46c1-bb9a-1400372dc82e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 73,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3b27a79b-62e6-46ca-9852-422cd9144d3d",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "699ede22-50fa-4272-b090-0009b1804982",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "86f48667-ceb7-40af-a389-7a137fe589eb",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c2bc05c9-6992-4b83-9854-949efe78fc06",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "76bb193b-c262-4f16-8fe1-b4ca04c5b3ee",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f918523f-d6d9-4961-995e-7a90274275ed",
                                                            "requirementId": "87e01dc1-f4d8-4474-a266-1eb4385a7709",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "563eaf37-04f8-4ef0-a06c-e6aaf3fc74c1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1201,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                    "name": "Does the school have a Single Central Record?",
                                                    "descriptionHtml": "<p>A single central record (SCR) must be maintained by all schools in a live document.</p><p>&nbsp;</p><p>This should be approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to link to your SCR or provide details of its location. </p><p>&nbsp;</p><p>We do not recommend attaching the SCR as it is likely to contain sensitive personal information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:15:27.946+00:00",
                                                    "originKey": "504e38e7-f1cd-43b5-8e09-7541e62b29e1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: rgb(252, 255, 255); color: rgb(31, 67, 79);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt; [Accessed: 19 August 2020]</p>",
                                                    "policyManagerId": 11,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: rgb(252, 255, 255); color: rgb(31, 67, 79);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt;</p>",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7ffe9043-61b8-4221-bb7c-a674cc74e35e",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "868ab82d-a84e-4346-8703-4bacf3be2338",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bd522004-fc0c-4cf0-9ea3-1b9de1ff8158",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c083222d-39a6-48b5-ab33-624021844704",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6dc99637-57d8-46dc-bf0a-45d286fe2184",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9e673ca2-4270-4646-810c-d34549d02266",
                                                            "requirementId": "9c2cf093-cc86-4989-8e60-74fb3d4b320b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ed90bd74-03e6-4d77-97e6-565afe703f6d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1202,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                    "name": "Does the school have an Allegations of Abuse Against Staff Statement?",
                                                    "descriptionHtml": "<p>The Allegations of Abuse Against Staff Statement must outline the procedures which must be followed for addressing allegations of abuse in a fair, consistent and efficient manner, whilst ensuring the safety of all pupils. This document should cover all staff, including supply staff, volunteers and contractors.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:16:54.199+00:00",
                                                    "originKey": "a7b2f41b-50a8-4efe-b5ce-a44e163c4f6d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 22,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "93455dc9-83f0-49c0-896e-c62d390bb310",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a32ed7ea-53de-49d5-90b5-83db88ea9248",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b8f8bab7-0343-40c8-8506-b9d2467fbed0",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd65e10a-8c5c-452c-8087-4f9210872cc3",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "51751147-2c56-46f1-8615-52d5e809e002",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b8709721-1ce2-4cc8-bd74-86b7634a5c44",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e68644d5-2dbe-4817-8b90-e91601badc8f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1203,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                    "name": "Does the school have a Teachers’ Pay Policy?",
                                                    "descriptionHtml": "<p>Maintained schools must have a Teachers' Pay Policy in place that sets out the basis on which the school determines teachers' pay, with clear performance criteria and the date by which it will determine annual pay reviews. Procedures also need to be established for addressing teachers' grievances in relation to their pay in line with the ACAS Code of Practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:18:29.728+00:00",
                                                    "originKey": "26e7e344-62ce-4f00-805e-42e17bfb0406",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 6,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "213241a4-bfbe-49e8-8efd-3626db427166",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "27d23a4a-51d7-453b-acf1-6d3fa6a1e00a",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aad1d221-26f6-4867-a25b-7d1240fd2bc9",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9c633de-53bf-4ef2-8aa2-2ec4eecb5c20",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b3860ba6-0841-46b6-a6f5-3c0cb29713bf",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f7ffe079-3933-4b8e-8812-7b1a00a6c7d9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1204,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                    "name": "Does the school have a Teachers’ Pay Policy?",
                                                    "descriptionHtml": "<p>All academies should have a pay policy that sets out how decisions are taken on pay matters including pay assessments, pay progression, discretionary payments, and appeals.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:20:00.977+00:00",
                                                    "originKey": "ca1f80e3-299c-4964-b259-9d7d17ed71c3",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE ('Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 6,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "565e00da-c81a-4c90-9ae5-06f89e0ebc89",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "69fced91-14dd-4df6-8d75-993bcfc74441",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "74dd146e-faf2-44fc-8bd8-a7753e6c3314",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7a0e7aea-87b5-41ca-80dc-401de4fb34b5",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f9b64d59-773e-4e33-90da-b807fa71a119",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "92ca15da-b510-4fe7-af42-cd7d177b11fb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1205,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                    "name": "Does the school have an Accessibility Plan?",
                                                    "descriptionHtml": "<p>Schools must have a plan which is aimed at increasing the extent to which pupils with disabilities can participate in every aspect of school life.</p><p>&nbsp;</p><p>This should be approved by the governing board, a committee of the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's Accessibility Plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:21:23.668+00:00",
                                                    "originKey": "5e9246c5-495f-4dec-bc9f-588428e1e4f1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 24,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every three years.</p>",
                                                    "requiresReviewInDefaultMonths": 36,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "250cca9c-85e9-43f1-bc4e-9862c7e1016e",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b65550d-7b11-4f8d-99ce-788325727032",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a9007be9-7dc5-4b4b-a7d5-903b11a62b18",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6ef370d-f7e8-4e49-b3cb-4ed8a1faef20",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2e16cdae-6f4e-4ea7-b0b9-b7979fc7b946",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3b676851-1089-48b4-91fe-28cb247bb8f8",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7962dc37-346b-4498-943a-b3b28dc1d978",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1206,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                    "name": "Does the school have an up-to-date and compliant Child Protection and Safeguarding Policy?",
                                                    "descriptionHtml": "<p>Having child protection policies and procedures in place is a statutory requirement, and these must be compliant with the DfE’s ‘Keeping children safe in education’ (KCSIE) statutory guidance in order to set out a clear and consistent approach to safeguarding and promoting the welfare of pupils.</p><p>&nbsp;</p><p>The governing board or proprietor must approve this policy. Schools can upload their Child Protection and Safeguarding Policy as evidence for this requirement.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:23:10.671+00:00",
                                                    "originKey": "42272741-d535-4337-8c47-0b53a63bf519",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "77c4448d-ac8f-437f-ad12-59e81a3d201d",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "80b2b8eb-fe7a-43c0-acf5-1ebeed33f7e4",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "87c74ab7-540b-4da6-85e1-df0d9aa91a31",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b64a3a00-9e58-4ce5-a602-daf55b986b6b",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "bdc97344-03d1-4595-97ab-a544f4111521",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cade1478-d466-4c88-bbc4-8a592c0798c2",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f30b9cc4-c5ed-4ab0-b17e-51d3bdda7121",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1207,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                    "name": "Does the school have a policy for supporting pupils with health needs who cannot attend school?",
                                                    "descriptionHtml": "<p>Schools have a duty to ensure arrangements are in place to support pupils with medical conditions who cannot attend school. The procedures outlined in this policy should make sure pupils are supported in terms of both physical and mental health.</p><p> </p><p>The governing board must approve this policy.</p><p> </p><p>You may wish to link to or attach your school's related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:26:47.018+00:00",
                                                    "originKey": "533c82df-2f5a-41fe-8016-ed8b57beafa2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 84,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "37d2b0bf-5f85-42bd-b500-a8b00801d207",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d642df91-b840-4d8e-93ae-159ee949e505",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6750fda-6051-4723-9104-491a82d5ed0d",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea309129-cb3c-4c9c-9c9d-6808b6d277b7",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "10d09cf3-ce94-4d94-ba75-a80e7c53eb09",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e2d840b3-6db3-4587-848b-a944b49f02d1",
                                                            "requirementId": "2643c1e7-65b1-4324-a6d6-426c745a337a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ffbf6372-6f67-4c9e-a495-c199f31f4a5a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8f8d2466-6f11-41b0-a246-7393a520c24a",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1209,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8f8d2466-6f11-41b0-a246-7393a520c24a",
                                                    "name": "Does the school have policies in place covering EYFS requirements?",
                                                    "descriptionHtml": "<p>A whole-setting policy should cover the requirements set out within the ‘Statutory framework for the early years foundation stage’. Schools are not required to have separate EYFS policies to cover EYFS requirements where they are already met through an existing policy.</p><p> </p><p>The governing board can delegate approval of this policy.</p><p> </p><p>You may wish to link to or attach your school's related policies.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:29:23.839+00:00",
                                                    "originKey": "26ff88c7-0fb0-428e-a47c-42a6a1d19668",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 21,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "9414d7af-63a5-4466-adad-4531c6d9145a",
                                                            "requirementId": "8f8d2466-6f11-41b0-a246-7393a520c24a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a56164df-f256-4337-a253-b5343fe2d90f",
                                                            "requirementId": "8f8d2466-6f11-41b0-a246-7393a520c24a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f6bba201-2f7d-45b5-b8a9-8299a33af541",
                                                            "requirementId": "8f8d2466-6f11-41b0-a246-7393a520c24a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d824d0a7-e17a-4b00-a4ee-762e5e589cf5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1210,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                    "name": "Does the school have a SEND Policy?",
                                                    "descriptionHtml": "<p>SEND policies must set out the framework for promoting the educational achievement of pupils with SEND by catering to their individual needs and encouraging inclusivity in every way. A SEND Policy will ensure schools have due regard to their statutory responsibilities set out in the ‘Special educational needs and disability code of practice: 0 to 25 years’.</p><p>&nbsp;</p><p>The governing board or proprietor must approve this policy.</p><p><br></p><p>You may wish to link to or attach your school's SEND Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:30:24.942+00:00",
                                                    "originKey": "2feb780e-cc00-4cf2-8e08-854191f58e35",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 89,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually or immediately after any changes during the school year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "204ac4e2-3311-4921-874d-0bd50ff1448f",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4935b612-f763-4230-acbb-e6053b1e9d2b",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7ace00e3-f7a6-4278-b042-794ddfe039e9",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b9b1c9f5-6098-4d9f-a2fd-1edace82046d",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bb2b096-1b56-4a5a-a973-d3337c19b7fd",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "5799e9b7-bf0b-459e-8129-841dbb9d26a8",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "05d3fa98-cc67-44a4-b118-5ee43275c75b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1211,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "109a2363-3c17-4b00-831d-dea04f472251",
                                                    "name": "Does the school have a SEN Information Report?",
                                                    "descriptionHtml": "<p>You must have a SEN Information Report on your school’s policy for pupils with SEN. The report must comply with Section 69 of the Children and Families Act 2014, including:</p><p><br></p><ul><li>The arrangements for the admission of disabled pupils.</li><li>The steps you have taken to prevent pupils with SEND from being treated less favourably than other pupils.</li><li>The facilities you provide to help disabled pupils to access the school.</li></ul><p><br></p><p>You may wish to link to or attach your SEN Information Report.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:32:18.196+00:00",
                                                    "originKey": "f068b076-23a7-4d14-89a9-b9ca8b29a0b8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 5,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a178f5f2-c885-4192-88c5-ff6253719c24",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ab2c6ed6-b723-48de-b551-11dbac625f10",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b015bdce-df76-4db1-a1d1-7b27af9cf121",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de7196a9-4bfe-4793-9669-f1b209fdc002",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4b96db92-a2e3-4774-839c-37a7ac3fdc1c",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f12ec507-72fe-4963-95eb-cfc895408e88",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "daa31a3c-5c00-4bdc-bf21-7a1f15be210c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1212,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                    "name": "Does the school have a Supporting Pupils with Medical Conditions Policy?",
                                                    "descriptionHtml": "<p>Schools have a duty to ensure arrangements are in place to support pupils with medical conditions, in terms of physical and mental health.</p><p> </p><p>In maintained schools, governing boards can approve this requirement. In academies, the proprietor can approve the policy.</p><p> </p><p>You may wish to link to or attach your school's Supporting Pupils with Medical Conditions Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:34:55.658+00:00",
                                                    "originKey": "c5b370ad-e6b4-4ed1-90ec-88bcaca88ed5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 23,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2758e015-6b10-4d35-93dc-db12584cf65e",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8344667e-7369-4cd4-af79-6a5151d2eb0c",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "db9ef7ce-ae29-4df5-94e1-ed0b34726bcc",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dcc7541e-5c7e-4aae-9d06-d53f1dd64b27",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "07bc28fa-3ee9-4f4b-80b0-c4bc90c2ca89",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "aae6282f-5a4f-4ef2-a529-263cb6e146c9",
                                                            "requirementId": "e8a2f3a5-55d5-4195-b758-b401c46a7433",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "75ff1a95-56f3-4b62-9fb6-ac129dbf0110",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "41383e33-0278-490e-99dc-35ce7f641000",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1213,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "41383e33-0278-490e-99dc-35ce7f641000",
                                                    "name": "Does the school have an RSHE Policy?",
                                                    "descriptionHtml": "<p>Schools must have a policy in place covering their RSHE curriculum and should ensure that the policy meets the needs of pupils and parents and reflects the community they serve.</p><p> </p><p>The governing board can delegate the approval of this policy to a committee, individual governor or the headteacher. </p><p> </p><p>You may wish to link to or attach a copy of your school’s RSHE Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:36:27.236+00:00",
                                                    "originKey": "72bd79d6-fc7d-4fc1-b9ec-b5ee83f6c6d3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 27,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "23f47670-959c-4d52-ab5b-f4bfc1e99d4c",
                                                            "requirementId": "41383e33-0278-490e-99dc-35ce7f641000",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4c0ff86c-f7dc-4dd6-ba85-2bdfdd0af001",
                                                            "requirementId": "41383e33-0278-490e-99dc-35ce7f641000",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7f7878c0-5c7a-4bb1-90c3-0cbfc017916a",
                                                            "requirementId": "41383e33-0278-490e-99dc-35ce7f641000",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "95ebf244-a804-4ebd-a81b-051b76f28411",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2e0465bd-470a-4b9e-a49d-9344bfb1a84c",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1214,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2e0465bd-470a-4b9e-a49d-9344bfb1a84c",
                                                    "name": "Does the school have an RSHE Policy?",
                                                    "descriptionHtml": "<p>Schools must have a policy in place covering their RSHE curriculum and should ensure that the policy meets the needs of pupils and parents and reflects the community they serve. Sex education is not compulsory in primary schools; where a school chooses to teach aspects of sex education (which go beyond the national curriculum for science), they need to set this out in their policy and should consult with parents on what is to be covered.</p><p><br></p><p>The governing board can delegate the approval of this policy to a committee, individual governor or the headteacher. </p><p> </p><p>You may wish to link to or attach your school’s RSHE Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:38:41.199+00:00",
                                                    "originKey": "1987b5e9-c7b0-47b3-beb1-c3f9e040195d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 4,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "cebf45c5-2a93-4445-baef-2afa0189394c",
                                                            "requirementId": "2e0465bd-470a-4b9e-a49d-9344bfb1a84c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "05157cc7-df81-4891-904c-9b4c0de6f21f",
                                                            "requirementId": "2e0465bd-470a-4b9e-a49d-9344bfb1a84c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fa39a132-ce44-4ae4-9b56-77a30e8375ac",
                                                            "requirementId": "2e0465bd-470a-4b9e-a49d-9344bfb1a84c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "592c8af2-3a0c-458b-8037-ca8e0d4a99c4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1215,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                    "name": "Does the school have a Behaviour Policy?",
                                                    "descriptionHtml": "<p>A Behaviour Policy must be put in place to outline clear procedures for expectations of pupils’ behaviour, rewarding good behaviour, sanctions, dealing with banned items, etc.</p><p> </p><p>The headteacher is free to delegate approval of this policy.</p><p> </p><p>You may wish to link to or attach your school's Behaviour Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:40:57.057+00:00",
                                                    "originKey": "a856f6e0-13eb-4ba4-b757-fd05356fe302",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 3,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "375e39ce-87ef-44b1-830c-3bd9dd9e8dc8",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7464d2a-97fe-46c1-a603-41c35f36cae8",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3cf38f7-f905-4c6a-89d3-846a0670f0e4",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ef40c057-abfd-4356-8c0a-cd1b8d965d79",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a58d8560-27e5-4bb8-a004-8bcd347d9c90",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e2aff2f9-ccb5-4c2f-a104-95f4f6f24459",
                                                            "requirementId": "8117d55c-f552-41de-9389-611f69bbcc98",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "171330a0-689d-4685-bc3d-32031e265e1d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1216,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                    "name": "Does the school have a behaviour principles written statement?",
                                                    "descriptionHtml": "<p>The requirement for a behaviour principles written statement can be met as a Pupil Code of Conduct, this should outline the school’s expectations from pupils in relation to attendance, punctuality, uniform and behaviour.</p><p><br></p><p>You may wish to link to or attach your school's behaviour principles written statement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:43:00.877+00:00",
                                                    "originKey": "2f7e32ad-2be8-42c2-8813-fb2806374174",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 10,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "37a9bb75-31b5-4b02-a67b-469380f8c54e",
                                                            "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "67e03a57-750b-49ff-a4c0-c531565d1a1b",
                                                            "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "97d6b34e-8d90-49e9-a81b-e85fde72d6ad",
                                                            "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a394ccd8-c122-49ca-9e71-f80eed177166",
                                                            "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c77239ae-07e3-4c02-a24a-dbe7182c5a20",
                                                            "requirementId": "fdd18471-2303-429c-af5d-d4e41f3e9006",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0f6fb733-95f8-48ab-b46d-7eec30ba830f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1217,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                    "name": "Does the school have a Suspension and Exclusions Policy?",
                                                    "descriptionHtml": "<p>Persistent or serious breaches of the school’s Behaviour Policy may lead to exclusion. The procedures which are followed in this situation must be outlined in an Exclusions Policy.</p><p><br></p><p>You may wish to link to or attach your school's Exclusions Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:47:03.54+00:00",
                                                    "originKey": "2c232833-002e-4f12-9e93-a85994fec30a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 80,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "6208b885-3f0c-45fb-8dbd-a2e2c0fbf56f",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "24a736b6-c458-4bb9-8a3d-65a1dbb529b0",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d019f25-5a07-45ae-9970-e08fd3e9fc99",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d3874b1-267d-4e87-a9ab-42794045dc3c",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fd7575ce-2a15-4da7-8d14-69ef55723ffa",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a70625a-091c-4772-97be-5740e7094d3f",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c33f196f-93ee-4b47-8575-6179c0f8a525",
                                                            "requirementId": "26b39751-9305-4bed-b8e9-4a433a4f8a76",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f09f4236-8038-43b2-91ad-8402e1e66997",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1219,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                    "name": "Does the school have a First Aid Policy? ",
                                                    "descriptionHtml": "<p>Schools must have written first aid procedures in place that address key considerations concerning first aid in a clear manner.</p><p> </p><p>Schools are not required to have a specific First Aid Policy; however, a First Aid Policy can be a good way to outline the school’s procedures. </p><p> </p><p>The employer must approve this requirement. In maintained schools, the employer will typically be the governing board or LA. In academy trusts, the employer will typically be the board of trustees. </p><p> </p><p>You may wish to link to or attach your school's First Aid Policy, or the policy which contains your first aid procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:49:41.215+00:00",
                                                    "originKey": "3d39d4e3-9e65-4bad-b823-20415109fff1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 79,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2e6f7238-24be-4d31-87d1-59f1f883e054",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3d5fcb0a-b06d-4c95-aec5-0aa8a570ee22",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0dbdaaf-75d4-4899-a8c5-832ea108aced",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fbaec6d4-52e8-41cb-a602-15d48115db2b",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e8ad5511-696a-4a40-ab01-0eb1f4384e65",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f5daea6f-e800-408d-99e6-08ff50d7cef2",
                                                            "requirementId": "e7bc2832-1e38-41ff-9540-be6684a50d81",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3132f2e8-2962-48e7-a339-5a3b5046a130",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1220,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                    "name": "Does the school have the required premises management documents?",
                                                    "descriptionHtml": "<p>Schools must have premises management documents pertaining to safe management and maintenance such as asbestos, fire safety and statutory testing. Policies and procedures must be compliant with appropriate legislation, including health and safety.</p><p><br></p><p>A Premises Management Policy is a good way outline how the school meets premises management requirements.</p><p><br></p><p>The governing board can delegate approval of this requirement to a committee of the board, an individual governor or the headteacher</p><p><br></p><p>You may wish to link to or attach your school's related documents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:51:27.89+00:00",
                                                    "originKey": "8cca5150-144f-4556-b1ce-7f0c672ab302",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 25,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "17651ae8-ed1b-4357-817b-a34885d0dee7",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "76669848-2967-4d5f-ac64-64009ad585e2",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1feb7b3-ae21-45d5-9978-a17b2b7de74f",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f3158583-2a83-491e-93fa-67e1c3f2a10d",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6fe1154b-cd75-4722-ae0c-ceb5a7cdd88c",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e558958e-ec8c-484d-9361-a1662daa2fd7",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "01eff485-04bd-4687-bfee-0394a1026ee2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1221,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                    "name": "Does the school have an Equality Information and Objectives Statement? ",
                                                    "descriptionHtml": "<p>This is a statutory requirement which ensures equality to all members of the school’s community. Schools are required to have an Equality Information and Objectives Statement in order to comply with the Public Sector Equality Duty.</p><p>&nbsp;</p><p>The governing board can delegate approval of this requirement to a committee of the board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to link to or attach your school's Equality Information and Objectives Statement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:53:46.756+00:00",
                                                    "originKey": "f18b71e2-1e05-4745-ba34-6d7ec8f6f69a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(31, 67, 79); background-color: rgb(252, 255, 255);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt; [Accessed: 19 August 2020]</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(31, 67, 79); background-color: rgb(252, 255, 255);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt;</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "26782064-b1ff-4c10-b2ea-0574f39c1f9c",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a23f734-5360-4bb6-b018-2548e134db19",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d6d6f5e-29a5-4190-a730-9ee8a009821d",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f508fa8c-12d4-46e8-a679-fa42cbd0f143",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0923cc63-ac8f-42d1-a44b-9964e4e5813f",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f59a1e93-ffcf-47ff-9872-15ec64c585d1",
                                                            "requirementId": "d35113a7-883b-4eac-93ba-06fe3ebdbc6f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "24af9f64-d5e9-4b57-b490-c4dff0345e10",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1222,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                    "name": "Does the school have a Governors’ Allowances Policy? ",
                                                    "descriptionHtml": "<p>This policy must be put in place to ensure procedures are in place for all governing board members to receive reimbursements for any expenses where the school has derived benefit from individuals serving their governing duty.</p><p><br></p><p>You may wish to link to or attach your school's Governors' Allowances Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:54:57.351+00:00",
                                                    "originKey": "e9ed177d-021f-49e9-8d09-f28f422dc4e5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 14,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1b0827bc-7002-4c7f-96f6-fd33b0a20d13",
                                                            "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "21132b4c-3390-473e-a95e-99cb4034f127",
                                                            "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "56d58ac7-300b-4d00-b38f-edd0fec224e4",
                                                            "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ddd9334b-e131-494e-a4fe-cf7ccb7b62d3",
                                                            "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "21767f97-6d83-4672-bc14-f321c4248f77",
                                                            "requirementId": "13c84da4-8b21-4834-b940-c80d47c8a5e4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f78cc4b7-a051-4602-9285-4dfd921a8c7d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1224,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                    "name": "Does the school have an Instrument of Government? ",
                                                    "descriptionHtml": "<p>Section 20 of the Education Act 2002 requires all maintained schools to have an Instrument of Government which determines the constitution of the governing board and other matters relating to the school.</p><p> </p><p>You may wish to link to or attach your school's Instrument of Government.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:38:10.955+00:00",
                                                    "originKey": "8f804f51-8497-4fde-839c-501ac2cb574b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 87,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0c238610-e335-4b08-9111-6132047f2395",
                                                            "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f237f6f-b3d9-4265-bb45-2829d5e71d3f",
                                                            "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8ad9485-a02f-4e34-b9c2-68a7bfbebd0a",
                                                            "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7289e21-86d6-4d97-97b2-338dfa321bcb",
                                                            "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "26c34f51-c34f-4e52-a8e3-9ae65dc9aa1a",
                                                            "requirementId": "109ca6b5-14b9-48b5-9e78-2455b02cc5d1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "97ee3d05-3e81-4319-a983-5d8554dc2012",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1225,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                    "name": "Does the governing or trust board maintain a register of business interests of the headteacher and governors or trustees?",
                                                    "descriptionHtml": "<p>Schools and trusts have a statutory duty to declare actual or potential conflicts of interest. Governing boards are required to maintain a register which lists any conflicts of interest.</p><p><br></p><p>You may wish to link to or attach your school's register of interests. This register of interests is a live document and should be updated whenever necessary, and subject to an annual review at a minimum.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:39:46.29+00:00",
                                                    "originKey": "ff8828b1-43a0-4805-9288-b5b2ef5e9778",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 18,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "ac40aa8c-df2e-4461-99ad-bc7d64d184c2",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "594c4921-383e-4891-8e2e-8079810457d2",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e9fe1e59-c088-4eca-a0cb-7eb76eaab9d0",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea1afd67-b52c-41c0-86a5-c84eebc215bc",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed715122-558e-437c-9e09-de921a1aecae",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ad8db43-a022-4e3b-8ae4-596cfc5a7275",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b8e60758-9982-4985-8cb0-5c1c57740c87",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d00b4b3-2dad-44ea-a31a-bfc91c217aca",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1226,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                    "name": "Does the school have a Provider Access Policy Statement?",
                                                    "descriptionHtml": "<p>Schools must publish a statement setting out their arrangements for managing access requests from providers.</p><p> </p><p>You may wish to link to or attach your school's Provider Access Policy Statement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:41:56.747+00:00",
                                                    "originKey": "3bbec5d7-6f5d-4597-a01e-7a8baf5e971d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 86,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4b1a26da-43a4-457b-8599-89e495a918f6",
                                                            "requirementId": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9cc0e1f8-d9cc-42df-8158-a7b4f2bf84a0",
                                                            "requirementId": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5dd89f56-c9d9-4def-b25d-9789793ef84a",
                                                            "requirementId": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "85405bcd-d127-44bf-8cc8-f3d40944d250",
                                                            "requirementId": "9df1d73c-ba77-4276-9e70-012836115b22",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e7d97155-bf59-44d4-855f-799238e58edf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1228,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                    "name": "Has your school published information on its careers programme?",
                                                    "descriptionHtml": "<p>If your school teaches pupils in Year 8 and above, you must publish information about the school's careers programme on the school website.&nbsp;You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:26:59.167+00:00",
                                                    "originKey": "87f6e397-227f-414f-a0ab-c1474a81922a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 85,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "098e5c11-cab7-48ef-b4f0-d031a0b5abd6",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "427e1085-6fe0-4871-9e1b-5a2dc4c57c73",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8ef91eb7-d606-417a-972f-7384bdccb9ca",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "66e7a47a-e77d-4a8f-a71a-1b03ee92422a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1239,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "969e17a0-2958-470a-a51d-0806d529179f",
                                                    "name": "Has the school sent written reports to parents on their child’s progress within the last academic year?",
                                                    "descriptionHtml": "<p>Headteachers must send a written report to parents on their child’s progress for the school year by the end of each Summer term. The report for school leavers must be sent no later than 30 September following the end of the school year in which the pupil left.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:14:41.191+00:00",
                                                    "originKey": "4a770c87-82af-4665-8d4a-5d98a3230bbe",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE and STA ‘School reports on pupil performance: guide for headteachers’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by the end of the Summer term, or by 30 September for leavers.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "016d195d-2130-4735-8ec7-5cb6efb31b60",
                                                            "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2a475eb4-03d4-436a-a5da-fff301b36678",
                                                            "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f13864ab-3c31-4496-923f-2c84eb55c6a2",
                                                            "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f294230f-2000-40f7-afce-e1e587df2cbf",
                                                            "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ff9ad014-074b-4dcf-b13d-a36a19e40f81",
                                                            "requirementId": "969e17a0-2958-470a-a51d-0806d529179f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b6ca9381-796a-4675-a916-9a54d51196ce",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fb305b3a-2056-493b-8cc7-9b854859f9e8",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1240,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fb305b3a-2056-493b-8cc7-9b854859f9e8",
                                                    "name": "Do the school’s pupil progress reports for KS1 and KS2 contain the mandatory information as outlined in schedule 1 of The Education (Pupil Information) (England) Regulations 2005?",
                                                    "descriptionHtml": "<p>Written progress reports must contain certain mandatory information, including information that is dependent on the pupil’s key stage, in line with <a href=\"http://www.legislation.gov.uk/uksi/2005/1437/schedule/1/made\" rel=\"noopener noreferrer\" target=\"_blank\">schedule 1</a> of The Education (Pupil Information) (England) Regulations 2005. KS1 and KS2 reports must include the following information:</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The pupil’s general progress</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The particulars of the pupil’s achievements, highlighting strengths and developmental needs</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">How to arrange a discussion about the report with a teacher at the school</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The pupil’s attendance record</span></p><p><span style=\"background-color: white; color: rgb(11, 12, 12);\">The results of any national curriculum assessments taken by the pupil</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:20:19.531+00:00",
                                                    "originKey": "5a64b37a-c12e-4bd2-a46b-1ea3354c2f28",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE and STA ‘School reports on pupil performance: guide for headteachers’, para.3</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "c22493da-90e8-4a47-8ffa-63b13764dbcc",
                                                            "requirementId": "fb305b3a-2056-493b-8cc7-9b854859f9e8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9febdf7e-1cba-4afc-b4ed-7646b1087232",
                                                            "requirementId": "fb305b3a-2056-493b-8cc7-9b854859f9e8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b105bc55-6cb3-4aad-b2ae-e31222bf299d",
                                                            "requirementId": "fb305b3a-2056-493b-8cc7-9b854859f9e8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d0dc5aa7-809d-4abd-ad8a-d1b869b6ed08",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c049be0d-1b34-4f4e-b177-e1cdff98bc7c",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1241,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c049be0d-1b34-4f4e-b177-e1cdff98bc7c",
                                                    "name": "Do the school’s pupil progress reports for KS4 contain the mandatory information as outlined in schedule 1 of The Education (Pupil Information) (England) Regulations 2005?",
                                                    "descriptionHtml": "<p>Written progress reports must contain certain mandatory information, including information that is dependent on the pupil’s key stage, in line with <a href=\"http://www.legislation.gov.uk/uksi/2005/1437/schedule/1/made\" rel=\"noopener noreferrer\" target=\"_blank\">schedule 1</a> of The Education (Pupil Information) (England) Regulations 2005. KS1 and KS2 reports must include the following information:</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The pupil’s general progress</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The particulars of the pupil’s achievements, highlighting strengths and developmental needs</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">How to arrange a discussion about the report with a teacher at the school</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The pupil’s attendance record</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The grade achieved in subjects for which the pupil was entered for&nbsp;</span>GCSE</p><p>Any other qualification, or unit towards a qualification, and the grade achieved</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:21:52.618+00:00",
                                                    "originKey": "666913bf-41fd-42e2-9c30-00076ac612b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE and STA ‘School reports on pupil performance: guide for headteachers’, para.3</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by the end of the Summer term, or by 30 September for leavers.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "ea39c90e-0560-4208-ad9b-61344b6af418",
                                                            "requirementId": "c049be0d-1b34-4f4e-b177-e1cdff98bc7c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "04ffd2ac-1f0c-4d57-b45d-c769eecdbad9",
                                                            "requirementId": "c049be0d-1b34-4f4e-b177-e1cdff98bc7c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "82b47522-8c74-43f2-bb58-b50ef227a9e8",
                                                            "requirementId": "c049be0d-1b34-4f4e-b177-e1cdff98bc7c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8bdd7ad-555b-4354-9e6f-3f7756e41abd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1242,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                    "name": "Has the governing board put a process in place to be able to report on its pupils’ progress and educational achievements?",
                                                    "descriptionHtml": "<p>Reports on pupils’ progress and educational achievements should be conducted at least annually. The governing board should ensure this procedure is in place.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:23:20.184+00:00",
                                                    "originKey": "675cd35f-c838-45e7-a383-ef85474447d8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Governance handbook’ p.112, para.338</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2e040735-d004-4d4e-9dc4-458a34b18355",
                                                            "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45f011d2-9b1a-47e0-aa65-98e9172f9c07",
                                                            "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c66e9c95-3512-4594-bf51-b1a2d68e242d",
                                                            "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e770a5f4-d33f-4def-9049-63b4803e37c0",
                                                            "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c7db0e56-11af-4109-a30f-b4d880f8cd45",
                                                            "requirementId": "7a0c7cb1-addd-43ee-bddc-d7620f5924de",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "46187b5a-1a3c-47c7-bf78-e301dc01551b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "03d426a6-46e6-48fa-8f1c-e50e13d4c189",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1243,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "03d426a6-46e6-48fa-8f1c-e50e13d4c189",
                                                    "name": "Do the school’s pupil progress reports for all years contain the mandatory information as outlined in schedule 1 of The Education (Pupil Information) (England) Regulations 2005?",
                                                    "descriptionHtml": "<p>Written progress reports must contain certain mandatory information, including information that is dependent on the pupil’s key stage, in line with <a href=\"http://www.legislation.gov.uk/uksi/2005/1437/schedule/1/made\" rel=\"noopener noreferrer\" target=\"_blank\">schedule 1</a> of The Education (Pupil Information) (England) Regulations 2005. Progress reports for all years must include the following information:</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The pupil’s general progress</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">The particulars of the pupil’s achievements, highlighting strengths and developmental needs</span></p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style=\"background-color: white; color: rgb(11, 12, 12);\">How to arrange a discussion about the report with a teacher at the school</span></p><p><span style=\"background-color: white; color: rgb(11, 12, 12);\">The pupil’s attendance record (except for pupils in Reception and in Years 12 and 13, where the pupil is not of compulsory school age)</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:26:10.594+00:00",
                                                    "originKey": "e2e6cef3-220b-49b0-9b71-adf166f86e15",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE and STA ‘School reports on pupil performance: guide for headteachers’, para.3</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by the end of the Summer term, or by 30 September for leavers.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6becaf73-3829-4007-8e9b-7df039634ca4",
                                                            "requirementId": "03d426a6-46e6-48fa-8f1c-e50e13d4c189",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "294bfbe2-f2a9-4884-9f27-1037eadc0a6e",
                                                            "requirementId": "03d426a6-46e6-48fa-8f1c-e50e13d4c189",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b819f9ac-82dc-4f64-9402-b22686d5394f",
                                                            "requirementId": "03d426a6-46e6-48fa-8f1c-e50e13d4c189",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d825482b-6a9c-4d84-a88d-ea51a490af5a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1244,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                    "name": "Does the school have procedures in place to be able to report instances of female genital mutilation (FGM) to the police?",
                                                    "descriptionHtml": "<p>There is a specific legal duty on teachers to report instances of FGM on a child under the age of 18 to the police. Your school must have procedures and training in place for teachers to report instances of FGM to the police and for all other staff to report this to the DSL.</p><p><br></p><p>You can evidence this by uploading or linking to any policies or documents outlining the school's procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:28:15.348+00:00",
                                                    "originKey": "330b3d66-0fc7-4a48-96a3-5ff3b896188a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1d6e885f-b780-448f-be46-b46c47f0c1bb",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "430269d5-25e5-4a86-85f9-657a1901e885",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a26e2e9-14a9-45fa-ab22-fb9d22d951ed",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "710303f1-0321-4bbb-8286-4898be9d7fc4",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ad4d9ca-3bce-4729-aed9-6178e8a6bfa1",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f40b517a-9445-4f18-8bbc-1fefbf26cbca",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "174f6ded-170b-4af7-bbc7-4d06860031ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1245,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                    "name": "Does the school have procedures in place to be able to report staff who pose a risk to pupils?",
                                                    "descriptionHtml": "<p>Procedures should be in place to report concerns about a member of staff posing a risk to pupils – these should be reported to the headteacher. Where the concerns regard the headteacher, procedures should be in place for these to be referred to the chair of the school’s governing board or, where required, the LA.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:30:46.538+00:00",
                                                    "originKey": "dbb3370d-98fb-40b8-8812-7434b3419d42",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) ‘Keeping children safe in education’, para.56</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7e7b6dec-af15-4c55-93dd-e4738cb61420",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "87dee36a-8bec-4f17-93e4-e0f7db529054",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "905397e6-00d9-4eac-a302-efad27d42202",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d1dcbd5d-0519-4c85-9e2d-21794bff0d43",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "115b652f-ff0b-4d0b-ad83-43878b576c82",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4d0f7017-f8e9-4b5c-ac7a-da040e5d845f",
                                                            "requirementId": "bc8e1d27-de05-4ba0-a69f-4740ac217153",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8372cfcb-a4fe-45f1-876f-21d55234a268",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1246,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                    "name": "Does the school have procedures in place so that somebody who is alleged to have harmed a pupil can be reported to the police and children’s social care?",
                                                    "descriptionHtml": "<p>It is important that the DSL (and their deputies) are clear about the local process for referrals and can follow that process.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:32:02.918+00:00",
                                                    "originKey": "ef26c7ec-4aac-4740-b931-502bdcd2b5aa",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) ‘Keeping children safe in education’, 'Reporting to the police', p.75 </p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "11f36b26-0550-4e07-b4e1-ff6c6ca68984",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b3c6bbb-3402-4be3-97bc-ca0773a77895",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "599fb166-2642-404a-8953-1785d479dcd1",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ad6b8d63-b32c-4f60-83dd-237627635a4c",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2486eee2-9560-4b60-9bef-b228c0928537",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8a6e23ca-33a2-47ad-ae3b-0e51e065aed1",
                                                            "requirementId": "c99b3dee-bfa7-4e2e-87ac-aaef738b4405",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "31efd1cb-1f25-4b68-9da5-61c83ce110f7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1247,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                    "name": "Does the school have procedures in place to be able to maintain the confidentiality of safeguarding reports made against a member of staff?",
                                                    "descriptionHtml": "<p>The Education Act 2002 outlines reporting restrictions preventing the publication of any material that may lead to the identification of a teacher who has been accused of harm while an allegation is being investigated or considered. The school must have procedures in place to fulfil this duty.</p><p><br></p><p>You can evidence this by linking to where this information has been published or uploading an attachment.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:37:50.413+00:00",
                                                    "originKey": "f8e2b5f4-deea-4273-9c31-6b474a182f3e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "638cecaf-cbed-4744-aff5-790c2a78f191",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "750157b0-7d89-415e-b5cf-3e67f643ae30",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "869e381e-5c2c-4761-9655-2b555bf58d93",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "964a99cc-e005-4f04-9c47-1cc5080e6048",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "709453c9-eec1-462c-8f0d-23c28c25fe97",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "82c91bbe-13c2-4774-90ca-8414787b8cfe",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a383da0f-6bb7-4cb9-ac32-034c17f4386f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1250,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1923c63c-5530-4445-be10-225a3f614389",
                                                    "name": "Does the school have clear procedures in place for referring pupils at risk of radicalisation to the Channel programme, in line with the Prevent duty?",
                                                    "descriptionHtml": "<p>Schools and childcare providers should have clear procedures in place for protecting pupils at risk of radicalisation. School staff and childcare providers should understand when it is appropriate to make a referral to the Channel programme.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:43:10.145+00:00",
                                                    "originKey": "27bef869-a1be-41a6-9fc6-921489d16e0f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2015) ‘The Prevent duty’, p.6</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "84c4feec-ad76-4762-a985-2b25cb0cbbaf",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aea60eaa-65a5-44bc-a7c6-012de814db09",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d38fd4ff-4a14-4646-a01e-08974baf964b",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e7626643-1da0-44f1-ab45-1966f25b438e",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d045048-1e35-4a94-98d6-1bfb09f03708",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "42759e79-4e35-4737-b277-127e4f3e53ff",
                                                            "requirementId": "1923c63c-5530-4445-be10-225a3f614389",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f74b8e37-6ba0-475b-9e07-6b9fe50d1403",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1252,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                    "name": "Has the governing board received an annual report from the designated teachers of LAC?",
                                                    "descriptionHtml": "<p>Governing boards and trust boards must ensure that they receive and consider an annual report from the designated teachers of LAC and act on any issues raised in the report. You could attach or link to the report as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T09:53:48.04+00:00",
                                                    "originKey": "6e5f676e-1640-47cb-9c1f-53e86894d3eb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Governance handbook’</p>",
                                                    "policyManagerId": 82,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "37baa26e-b8c7-4292-be9a-af32b7c1ca59",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5fa20d1d-2012-4bdc-825d-481848bec056",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a3088d46-a513-48d7-98bf-0d180f5c3d55",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bf6c63b1-b44c-4c5b-aca4-27bf4f9b2aef",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2cdb3966-67e7-4a92-9031-f59158b6bb62",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "38fdb582-5067-4b41-bf76-1e785ae2cfd4",
                                                            "requirementId": "0ecaf8e8-1c20-4761-80f2-6ccedf4d8245",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f4cf5dd9-aea9-4790-ba31-582af2e56e50",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1253,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                    "name": "Has the governing board provided the headteacher with a written report of their appraisal?",
                                                    "descriptionHtml": "<p>The headteacher’s appraisal must include the assessments and recommendations outlined in the ‘Governance handbook’, e.g. setting objectives. You could attach or link to the appraisal report as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T09:56:18.142+00:00",
                                                    "originKey": "907bfdbe-f347-4335-8d78-cd4f29fc97be",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Governance handbook'</p>",
                                                    "policyManagerId": 1,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, or in line with the school’s appraisal process.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7e5dea3b-e7a8-4576-850c-c33b758e7b6c",
                                                            "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4b64dfe-562a-4509-b6a7-87df186eb1b1",
                                                            "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c78c2b72-ee8e-4a12-9ef8-5201cb52e5ff",
                                                            "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "db8a7b5e-94e4-4661-a3ff-f8306fe5e09f",
                                                            "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f034384b-f327-4960-9fea-82b80da904ae",
                                                            "requirementId": "15edbddd-787f-4b3c-a2ed-9522590e6392",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ca52e973-f918-431b-8ce3-c50e90c7ce35",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1259,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                    "name": "Does the school have procedures in place to report all reportable data breaches to the ICO?",
                                                    "descriptionHtml": "<p>The GDPR requires that all organisations report certain types of personal data breach to the relevant supervisory authority. Schools must have robust procedures in place to be able to report these within 72 hours of becoming aware of the breach, where feasible.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:13:51.46+00:00",
                                                    "originKey": "113af450-c33d-4fc4-9aea-ce97b7e38b68",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO, ‘Personal data breaches’, para.1</p>",
                                                    "policyManagerId": 7,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7d3903a2-1e16-47fc-8644-8bb87bc06369",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a5996e0-6f5e-4386-b094-4f9b2705a032",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "da51c2a8-e402-4202-a2f7-772a69b08288",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e9fc1e12-14eb-496b-a75c-56444fd9f816",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "26117a9e-1654-48a2-8ab9-6a9ce44bdc95",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "54e24d83-9c96-40ff-ac06-79431203e4b9",
                                                            "requirementId": "6ae2b2b9-bf16-4966-b22f-bae8e72fdd1b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1cb338eb-4b68-4af3-a50b-7c81d9f9209c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1260,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                    "name": "Does the school have internal procedures in place to report and record all data breaches?",
                                                    "descriptionHtml": "<p>The school should have robust breach detection, investigation and internal reporting procedures in place. This will facilitate decision-making about whether the school needs to notify the relevant supervisory authority and the affected individuals. The school must also keep a record of any personal data breaches, regardless of whether anyone needs to be notified.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:15:46.984+00:00",
                                                    "originKey": "4c76ed07-5c7d-491e-8fbc-d896be6057ce",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO, ‘Personal data breaches’, para.2 and 4</p>",
                                                    "policyManagerId": 7,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "05802218-da2e-4780-928f-50f2786d1a2d",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "23811383-92c1-4a1e-9480-14687f731b39",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "70cb77ab-fd1a-49b5-a5ca-e6bad3fa7527",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de9762d9-48f0-4fdd-ab15-4aa8da121c19",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "18a96d34-7be8-4569-9de3-636b9268464f",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "87e5a341-ad48-469f-aa70-2a4ffb97efa5",
                                                            "requirementId": "5e12fbb7-c322-4aa9-9e94-c28c90123585",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bd963bbc-50ea-4c8f-a5fe-2d8df995cf6e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1261,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                    "name": "Does the school know what information needs to be reported in the event of a data breach?",
                                                    "descriptionHtml": "<p>When reporting a breach, the GDPR says schools must provide, where possible:</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Details of the categories and approximate number of individuals and personal data records affected.</p><p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The name and contact details of the DPO or other contact point where more information can be obtained.</p><p>The likely consequences of the personal data breach and a description of the measures taken, or proposed to be taken, to deal with the personal data breach.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:17:11.49+00:00",
                                                    "originKey": "4c2cf33b-9118-42ad-a4b8-24abc459dd26",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO, ‘Personal data breaches’, ‘What information must a breach notification to the supervisory authority contain?’, para.1</p>",
                                                    "policyManagerId": 7,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "28c37030-2d94-4131-bb7a-48ccab5442bb",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4e88123-35b5-4597-b1a3-0c3beaeaa347",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "df5bcf7e-5733-48eb-b459-b7bcc3fc6f40",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3b893e6-c76b-4122-b915-28fc90efbc8c",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "dc93e1a5-2b6d-4f7a-9bcd-ccffe4291e9c",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e3ce8c40-bc2f-4981-af9c-f84cd2babffd",
                                                            "requirementId": "6ffdcd82-0009-404f-93ab-7ebe3042a6d9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1eb9c704-d198-40c5-8e70-7ad1c0a81ba9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1262,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                    "name": "Has the school returned a termly census?",
                                                    "descriptionHtml": "<p>Every school in England has a statutory duty to complete the school census each term. Maintained schools and PRUs should contact their LA to find out their deadlines for submission.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:22:17.976+00:00",
                                                    "originKey": "a05cc287-285e-4ae9-baca-3793c01a18ee",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Complete the school census’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement termly.</p>",
                                                    "requiresReviewInDefaultMonths": 4,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "03bde12f-6e27-47ae-8976-199c0cef0853",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2c10d9bb-51e8-44d9-8491-5e098602714a",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8e27e1e2-4ae7-43db-aade-efe6656e5a19",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cb4fdf3d-c3fe-4aaa-a2dd-43dbf708f1f7",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5adc129d-754d-4ddc-92c8-32222517cc2b",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a0385165-9091-47c9-99ad-ec063094315c",
                                                            "requirementId": "268dc088-9b03-4b45-8628-b917c58a865f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f6e6a3f1-d9c8-4da4-b37c-1ab413cfb7b1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1263,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                    "name": "Does the school understand what data items are required when returning their school census?",
                                                    "descriptionHtml": "<p>Different categories of schools may need to submit different data items. The school’s category of school is defined by the school phase, and whether it is a special school or a PRU.</p><p>&nbsp;</p><p>The statutory age ranges for each individual school are recorded on ‘Get Information About Schools (GIAS)’.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:23:50.641+00:00",
                                                    "originKey": "50a09e48-709c-4217-b1c5-bc4c54b5bc8c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Complete the school census’, ‘What you need to send us’, para.1-2</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement termly.</p>",
                                                    "requiresReviewInDefaultMonths": 4,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18df4560-6dab-4823-aeb6-f310ef371078",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "31136d8d-6156-4dd0-a943-2972c042cd24",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "52696a3f-af4b-44ee-9e91-2d86c0fd4803",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c312c552-187b-4718-8651-149cd47d9f29",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "01225e31-3469-4cc6-b4bd-3c68045e61ca",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f5fa4521-c5f6-4869-a0c9-f6f1623f79c8",
                                                            "requirementId": "33799151-2469-49be-a818-0d4e8b8923b9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5103991a-c2c0-434b-afe1-cff0ce01c6c2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1264,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                    "name": "Has the school passed on its most recent Ofsted report to the governing board/LA?",
                                                    "descriptionHtml": "<p>If Ofsted also prepares a report of an investigation, the report must be passed to the governing board (or if the school does not have a delegated budget, the LA) as soon as possible.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:26:10.636+00:00",
                                                    "originKey": "5c61ba7b-ed1a-4674-906c-9e234503b641",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Governance handbook’ p.127, para.326</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "49aaf1fe-fdf2-4ad8-ae9c-0c046a79f1b7",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81235d33-1729-4241-9511-e9a31ddef37b",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8ceebac-1805-43ac-9321-8f878b2e5b06",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cc9d81fa-667f-48eb-ba98-d92d15bb73ba",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ee7976a-effb-456f-b843-63c0c1d69693",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b376b9c0-ca13-48b8-8fa6-84054006b3d4",
                                                            "requirementId": "6ecbacaf-8038-4f64-80ed-db5175cec2d7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "eafde635-7977-4142-b3ef-d96acf98ec06",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1265,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b00be563-b864-4669-b450-d57b3d680246",
                                                    "name": "Has the school ensured a copy of the most recent Ofsted report has been delivered to all registered parents?",
                                                    "descriptionHtml": "<p>If Ofsted also prepares a report of an investigation, the report must be passed onto all registered parents within five working days.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:28:33.314+00:00",
                                                    "originKey": "7a304b74-efd7-4929-9fb1-f036f38076cb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Governance handbook’, p.127, para.326 and ‘7.3.1 Section 5 inspections and short inspections’, para.23</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06d9dfa9-0287-4c27-865f-50a532ae03ad",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "245440fe-21d8-48ee-8a6a-0d74909f1b8f",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b105d2b-37ef-47cd-8060-9674b4a1bf5a",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b287dcc9-7032-417c-932b-c0612697afac",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7772be0e-a9a7-4281-8a4c-900a46bad6d6",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8f5574ba-7d4e-43f0-9060-a7f78eeb20b7",
                                                            "requirementId": "b00be563-b864-4669-b450-d57b3d680246",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "90fb23b2-7b34-414a-858e-4bb756de7ba4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1266,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                    "name": "Has the school ensured a copy of the most recent Ofsted report is available to members of the public who wish to see it?",
                                                    "descriptionHtml": "<p>The report must be made available to any member of the public who wishes to see it, at such times and places as may be reasonable, and copies of the report to be provided to anyone who asks.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:29:51.926+00:00",
                                                    "originKey": "3a4097c0-203f-45cd-b70e-29a4b4156ea4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Governance handbook’, ‘7.3.1 Section 5 inspections and short inspections’, para.23</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "046846f6-3494-490e-a5d2-c2838d818bdf",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "430f2863-f9ae-4944-bc45-990665de276d",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4a6d6854-5029-435e-92bc-ea26f820a1f1",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0c0b2ae-4acd-41d9-b06e-235cec070023",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5994f29c-f833-406c-b69e-8ec1a005b162",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b7e08870-aa55-439e-a9d3-3f6334c986c1",
                                                            "requirementId": "ff17c3d8-f88d-41b4-9e43-064660253f5b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d92edcb7-ad8f-4c7d-906b-5b8b72f6d0ce",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1267,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                    "name": "Does the trust have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>Academy trusts are required to have a whistleblowing procedure, to protect staff who report individuals they believe are doing something wrong or illegal.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:30:42.001+00:00",
                                                    "originKey": "33eff5ce-8e42-49d4-9fc4-167ab87e2935",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "94fa84b1-1b69-4638-b97a-42e3a43c09a7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "161e8f34-fc50-4104-8940-09d6bdf3288e",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4632803e-5432-414c-85ab-570da1707cb5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b222a7f1-a14a-45a9-9e0e-2071b40a0268",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8611e76-cd1f-4907-9899-5069a48058f5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bd39f2c-bad2-4081-b32b-9c5fe49d03f1",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b18c562b-6002-40de-af9d-c675a0e89ebf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1270,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                    "name": "Are procedures in place to make sure the school meets its obligations under the Prevent duty, including reporting pupils at risk of radicalisation?",
                                                    "descriptionHtml": "<p>Schools are subject to a duty under section 26 of the Counter-Terrorism and Security Act 2015 to have due regard to the need to prevent people from being drawn into terrorism. This is known as the Prevent duty. Procedures must be in place in your school to make referrals for pupils who are identified as being at risk of radicalisation.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:33:00.442+00:00",
                                                    "originKey": "e4d40d2f-d0ea-4ff0-bb0f-32e7abe2ce0d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "070bb1ef-c683-4134-91f0-e575bf9d5451",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "430364f2-4e74-4e6b-b9b1-7d9316d66d29",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "765cff6d-023a-40e3-bef0-16a4b301e207",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aa55b8b1-f5b1-42dc-a488-6bb0d3dcd2ed",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "225d6c6b-953b-4dbe-962d-3963a816c2c1",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "45d9cd8c-fae1-4b62-b430-e790865421d6",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9ab02dc4-4fa0-4c59-94db-f5376ba374ba",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1282,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "95c3151c-9542-4d16-992a-c903df820aee",
                                                    "name": "Does the school have safeguarding procedures in place to make a referral to the DBS?",
                                                    "descriptionHtml": "<p>There must be procedures in place to make a referral to the DBS if a person in regulated activity has been dismissed or removed from their post due to safeguarding concerns, or would have been had they not resigned. Failure to comply with this legal duty when the criteria are met is considered a criminal offence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:52:38.743+00:00",
                                                    "originKey": "6144be13-33f5-4c43-849f-3dd4058ff9b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21adb6a2-e144-44a8-b434-597899afdba9",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7883a0c0-fdf7-40b2-a92f-cc0709c2a0b5",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b18c09fd-89b9-40b0-afa4-4ea0699f5ebc",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7285188-23a5-4747-b1c0-5ecbb89c4b18",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "12daa589-8c38-41a3-a83d-a9f51723d99d",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "22369885-8a1b-4585-befa-b6d633c543ec",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7ca75146-9647-4393-8ac0-ad53f47f1332",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1286,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                    "name": "Does the governing board or proprietor ensure regular contributions are made to multi-agency working in line with published local arrangements?",
                                                    "descriptionHtml": "<p>Governing boards and proprietors should ensure that the school contributes to multi-agency working.</p><p><br></p><p>Safeguarding partners must set out how they will work together and with any relevant agencies – they will have set out in their published arrangements which organisations and agencies they will be working with, and the expectations for them. Schools must co-operate with the published arrangements.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:56:32.588+00:00",
                                                    "originKey": "da5183b5-7873-443c-a104-8cfd9fc60504",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1050ef34-8d38-40d5-ab89-9e9efe011229",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "51a24762-c1d2-427e-80a9-d3cea6eb0c2f",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a1987ebf-3ea6-4d62-b485-5b2debf32c35",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8479946-d0b6-4eae-99f2-bba2bd27a285",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "93f41841-b80a-41a5-a0ee-c627c45fba8f",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cd36b3d0-7fc3-4272-98d6-d60191aadb67",
                                                            "requirementId": "a8810fe6-e1a5-4436-8a1e-217d9b655695",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cdea1c68-dc78-46a3-ada7-9f7bf2786516",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1287,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                    "name": "Does the school maintain an up-to-date and compliant single central record (SCR)?",
                                                    "descriptionHtml": "<p>Schools must maintain a single central record of pre-appointment checks, more commonly known as the single central record or SCR.&nbsp;</p><p>&nbsp;</p><p>MATs must maintain an SCR detailing checks carried out in each academy within the MAT. Whilst there is no requirement for the MAT to maintain an individual record for each academy individually, the information should be recorded in such a way that allows for details for each individual academy to be provided separately, and without delay, to those entitled to inspect that information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:57:36.346+00:00",
                                                    "originKey": "9e42f7b6-6ffc-475f-bc10-bde7944628d6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 11,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3743717a-58f3-431b-bac6-b4f4c6d18ff8",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "60e2891d-b68c-466d-bc53-8a313b6f1453",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7af5e45a-f3f8-480e-8878-ac190f165355",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec971787-6d97-4c0e-b011-af69234949ee",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "01593e64-305f-4b34-96b9-875dea9486aa",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3aa9a2b2-e56b-40cf-902f-24bad9147f18",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c31b4631-c30d-403c-8db8-12c60a711b38",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1297,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                    "name": "Has the school determined its admissions arrangements?",
                                                    "descriptionHtml": "<p>Admissions arrangements must be determined annually. Schools must consult on any changes to admissions arrangements, where no changes are made at least every 7 years. In a maintained school, it is the responsibility of the LA. A good way to outline admissions arrangements is through an Admissions Policy.</p><p>You may wish to attach or link to your school's Admissions Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-10-02T12:32:07.699+00:00",
                                                    "originKey": "4540c62b-7514-4ea6-92e9-a4454fbf5b20",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2020) 'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt; [Accessed: 19 August 2020]</p><p>DfE (2014) 'School admissions code', p.7</p>",
                                                    "policyManagerId": 9,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>'Statutory policies for schools and academy trusts', &lt;<a href=\"https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"background-color: rgb(252, 255, 255);\">https://www.gov.uk/government/publications/statutory-policies-for-schools-and-academy-trusts/statutory-policies-for-schools-and-academy-trusts</a>&gt;</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4bc79ddf-953f-4d65-a914-1f6e2fd739d4",
                                                            "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "543c2287-1d8f-4eb0-9d98-69ec2eb0f83d",
                                                            "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "740856c6-466b-4207-81aa-7b53cded91fb",
                                                            "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7587268a-5538-4511-bf29-c0dda0aa48bb",
                                                            "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f2034f75-05b9-4eec-8103-8a42b87166f9",
                                                            "requirementId": "fe781c15-665d-45da-b3d9-247ada580ba3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0a851179-dbe2-4a54-9b49-ecc8a1150cc3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1302,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                    "name": "Does your school conduct health and safety risk assessments that cover stress-related ill health arising in the workplace?",
                                                    "descriptionHtml": "<p>Your school must ensure adequate risk assessments are conducted to manage staff stress that can arise from the workplace, in order to comply with health and safety legislation.</p><p><br></p><p>You could upload or link to where these measures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:39:42.694+00:00",
                                                    "originKey": "9ec1d327-6bc9-443e-9b96-b5a98340bf20",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18971508-24b2-43cb-a638-4311a5611225",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "230b17e8-bfbc-4f4a-b2fb-a8de44ec815a",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "273145c4-859b-4e73-be9a-5d8b69c3fad5",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "85ff70ac-9c07-412e-85df-1e138439d20d",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0cdab5c7-7082-45af-a131-265ebdf8b18e",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f1793e2f-cefb-423c-adc7-95cac3228e36",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "20235edc-9f30-4862-92ef-6e9d92689cc5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1303,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                    "name": "Does your school have processes in place to mitigate the causes of stress-related ill health identified in risk assessments?",
                                                    "descriptionHtml": "<p>Your school must ensure effective measures are put in place to mitigate the risks of stress-related ill health in the workplace that have been identified as a result of its risk assessments. You could upload or link to where these measures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:41:46.267+00:00",
                                                    "originKey": "ffe50c98-8bd9-4fd5-a6c5-6207c69b728c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0fe9a4c4-a513-4ee9-8cba-36a636223631",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6be20119-e643-4d90-8cc0-ad762a90dfb6",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "880f0f6c-d5e3-49ed-900f-c99425e4bd0c",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb126b62-ff7c-408d-8464-44284d2c26b0",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "82cf8694-106d-43f6-8cf9-14f2ba0bb3aa",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9631ab09-4b6a-40e9-a798-1d75bb9a6721",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0e148b17-99ed-437d-ad7f-5363c01bc3c7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1304,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                    "name": "Does your school have an asbestos register and management plan?",
                                                    "descriptionHtml": "<p>All schools must have an asbestos management plan. Schools should ensure that they have a site-specific asbestos management plan. If the plan has been drawn up by the LA, the SLT needs to ensure that they are fully aware with it and that where necessary they satisfy themselves that local knowledge has been incorporated.</p><p><br></p><p>You may wish to link to or attach your school’s asbestos management plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:43:05.262+00:00",
                                                    "originKey": "bee05532-3ce0-407d-a817-5d31110cf47a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://www.hse.gov.uk/services/education/asbestos-summary-1011.htm\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.hse.gov.uk/services/education/asbestos-summary-1011.htm</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1ecfb496-b9f9-4f21-8a9c-787c61c87236",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "38c82ab2-59bd-41ec-9a63-b1c77c0d11a4",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdcd31e2-30c4-4d4b-8e15-6fd8bb87fbd3",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf05be81-394e-42e3-b763-0c11607add81",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5e9bdaa3-b01d-4465-befb-a56691536f6c",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c1dc6366-d065-4aec-b7b3-93a83a2944a3",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "378bf4fa-6a41-4079-a911-a06e075a1bf7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1305,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                    "name": "Does your school have a Display Energy Certificate (DEC)?",
                                                    "descriptionHtml": "<p>Display Energy Certificates (DECs) are designed to show the energy performance of public buildings.&nbsp;</p><p><br></p><p>Public authorities must have a DEC for a building if all the following are true:</p><p><br></p><ul><li>It is at least partially occupied by a public authority&nbsp;</li><li>It has a total floor area of over 250 square metres</li><li>It is frequently visited by the public</li></ul><p><br></p><p>Many schools meet the above criteria and, therefore, are required to have a DEC and publicly display this.&nbsp;If your school does not meet the above criteria, you can mark this requirement as not relevant.</p><p><br></p><p>You may wish to link to or attach your school’s DEC.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:43:53.905+00:00",
                                                    "originKey": "6420ce7a-e0c6-4db4-b09c-781c6e6c7289",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/check-energy-performance-public-building</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "40ab0d73-ea97-4b20-931a-9db7d53c3063",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4fa97ed5-7aa9-4350-89ef-7dd6fdc504f3",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8682409c-de31-4083-8228-f3d4d0f4ae8a",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c75c11df-df7d-4231-bde6-8a37b9b36ad0",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "172bbb77-9036-4406-ac2b-cd4a85a2621c",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4a8a5352-1399-4fd9-b2c9-20517c0b36d7",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a0ba69ab-f977-492f-8c96-e5cd37050701",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 1306,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "85872db3-d607-43e6-9886-66418d8222bc",
                                                    "name": "Does the school meet the requirements under Natasha’s Law?",
                                                    "descriptionHtml": "<p>Schools are required to meet the requirements of The Food Information (Amendment) (England) Regulations 2019 (Natasha’s Law). Natasha’s Law strengthened allergen labelling requirements on foods pre-packaged for direct sale.&nbsp;</p><p><br></p><p>You may wish to link to or attach evidence of your school’s compliance with Natasha’s Law.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:45:12.32+00:00",
                                                    "originKey": "21da0b9c-a2f5-401c-8a95-e1207f605871",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://www.theschoolbus.net/article/uptospeed-on-natashas-law/8526</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5631ec3f-098f-440e-b8e3-2d648ececc0d",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ad320623-064a-433b-b13a-7eafd594ef6d",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e8dcb9c9-5d72-4ea4-99c3-81ad54486768",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fc5a5fcc-0ffc-479c-8719-e7b81ac2307b",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "121017bc-aa07-4c08-9f31-7deb0256db1d",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e6217edb-dd5a-4446-91ca-6aa2bcb30aef",
                                                            "requirementId": "85872db3-d607-43e6-9886-66418d8222bc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9e8fc2de-2b28-438f-aed4-82953ce19967",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1307,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                    "name": "Does the school have procedures in place to report report certain accidents, diseases and dangerous occurrences?",
                                                    "descriptionHtml": "<p>The Reporting of Injuries, Diseases and Dangerous Occurences Regulations 2013 (RIDDOR) requires employers, and other people in charge of work premises, to report to the HSE and keep records about work-related accidents which cause deaths, reportable injuries, cases of certain industrial diseases, and dangerous incidents with the potential to cause harm. Your school must have procedures in place to report these.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:14:32.058+00:00",
                                                    "originKey": "e5912597-ac71-416f-9639-3ceb38a9743c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>RIDDOR</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2aae7097-f4b8-4208-b249-05d27c52d6a1",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d21082ee-5874-4a0b-85f4-ec8178445db4",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de9ec429-7e80-44bf-9dbb-608476324509",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e48ad45d-6dd3-4c61-9614-6325f32b805c",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "648a731e-23ba-4b40-a6a3-cd7604b28dad",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fd55a87b-cf9e-46cd-8026-fb686edeac43",
                                                            "requirementId": "eaacc1c9-296b-497f-8bd7-02ed812bcf92",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "79d36272-a509-4565-bee9-90c12ec0013a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1308,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                    "name": "Does the school have a Health and Safety Policy?",
                                                    "descriptionHtml": "<p>Schools must have a Health and Safety Policy in place. The key elements of the policy should set out:</p><p><br></p><ul><li>The roles and responsibilities within risk management processes.&nbsp;</li><li>The mechanisms to control risk.&nbsp;</li><li>Specific control measures that need to be implemented.&nbsp;</li></ul><p><br></p><p>You may wish to attach or link to your school’s Health and Safety Policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T10:44:00.993+00:00",
                                                    "originKey": "56d03eda-ef40-4e6a-a308-a37b61815eb0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE ‘Statutory policies for schools and academy trusts’</p><p>&nbsp;</p><p>DfE ‘Health and safety: responsibilities and duties for schools’</p>",
                                                    "policyManagerId": 8,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0532decd-f9b7-4b96-99cf-8cccaafeff20",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6424a23f-70ed-4dd9-b1a9-24ef664943e9",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "79bc3505-d590-48a1-97f2-5f7b7515d985",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d59a68c-d737-49f5-8e81-0e2d33340c38",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "172962b1-2eb7-4317-b178-013492a019f6",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "df455da9-6abd-4bc3-9107-7779e324d428",
                                                            "requirementId": "0901c976-e8e6-4cf1-9ee4-f54c29e2dede",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2f87b609-b332-4d67-9b82-e310c0e2f75a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 1309,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                    "name": "Has a Fire Safety Risk Assessment been conducted? ",
                                                    "descriptionHtml": "<p>Schools are required to undertake risk assessments to identify the general fire precautions needed to safeguard the safety of occupants in case of fire, including their safe means of escape. Schools need to make sure their fire risk assessments are kept up-to-date and are reviewed in detail when any significant alterations are made to the premises.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school’s risk assessment.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:06:41.468+00:00",
                                                    "originKey": "afa43901-ca04-402f-9cf1-934d415f227f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA 'Fire safety in new and existing school buildings’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every six months</p>",
                                                    "requiresReviewInDefaultMonths": 6,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1b377084-4bc5-4653-ad01-a751a7e1a7ec",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "427818fb-afec-4ac5-93a3-1c76872fbc22",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a4b089a3-7b2c-4896-ae5d-9ef45c8fed7e",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1e0e478-8ed5-497e-ad54-0be5961ef98d",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a08561ea-0e72-44e4-9f98-8e212a04f04c",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b3cc5e23-ec64-4e8d-8317-02ad8b014a82",
                                                            "requirementId": "9677ac1f-b470-4a9d-a16b-63d63cad4d16",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cd6d7e63-7194-4239-8a7d-fc62ea9973f9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1310,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                    "name": "Does your school conduct health and safety risk assessments that cover stress-related ill health arising in the workplace?",
                                                    "descriptionHtml": "<p>Your school must ensure adequate risk assessments are conducted to manage staff stress that can arise from the workplace, in order to comply with health and safety legislation.</p><p><br></p><p>You could upload or link to where these measures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:39:42.694+00:00",
                                                    "originKey": "9ec1d327-6bc9-443e-9b96-b5a98340bf20",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18971508-24b2-43cb-a638-4311a5611225",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "230b17e8-bfbc-4f4a-b2fb-a8de44ec815a",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "273145c4-859b-4e73-be9a-5d8b69c3fad5",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "85ff70ac-9c07-412e-85df-1e138439d20d",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0cdab5c7-7082-45af-a131-265ebdf8b18e",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f1793e2f-cefb-423c-adc7-95cac3228e36",
                                                            "requirementId": "1f3bb3ab-16c5-46cc-91ae-366a611879df",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6452c60b-4826-4706-b01c-cc35d7c130a1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1311,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                    "name": "Does your school have processes in place to mitigate the causes of stress-related ill health identified in risk assessments?",
                                                    "descriptionHtml": "<p>Your school must ensure effective measures are put in place to mitigate the risks of stress-related ill health in the workplace that have been identified as a result of its risk assessments. You could upload or link to where these measures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:41:46.267+00:00",
                                                    "originKey": "ffe50c98-8bd9-4fd5-a6c5-6207c69b728c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0fe9a4c4-a513-4ee9-8cba-36a636223631",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6be20119-e643-4d90-8cc0-ad762a90dfb6",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "880f0f6c-d5e3-49ed-900f-c99425e4bd0c",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb126b62-ff7c-408d-8464-44284d2c26b0",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "82cf8694-106d-43f6-8cf9-14f2ba0bb3aa",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9631ab09-4b6a-40e9-a798-1d75bb9a6721",
                                                            "requirementId": "f734ab7b-634f-4e7e-a88d-dabb1d36fc79",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "35eede6c-9b7a-4d7f-b9f8-3a1715dc2295",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 1312,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                    "name": "Does your school have an asbestos register and management plan?",
                                                    "descriptionHtml": "<p>All schools must have an asbestos management plan. Schools should ensure that they have a site-specific asbestos management plan. If the plan has been drawn up by the LA, the SLT needs to ensure that they are fully aware with it and that where necessary they satisfy themselves that local knowledge has been incorporated.</p><p><br></p><p>You may wish to link to or attach your school’s asbestos management plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:43:05.262+00:00",
                                                    "originKey": "bee05532-3ce0-407d-a817-5d31110cf47a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://www.hse.gov.uk/services/education/asbestos-summary-1011.htm\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.hse.gov.uk/services/education/asbestos-summary-1011.htm</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1ecfb496-b9f9-4f21-8a9c-787c61c87236",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "38c82ab2-59bd-41ec-9a63-b1c77c0d11a4",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdcd31e2-30c4-4d4b-8e15-6fd8bb87fbd3",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf05be81-394e-42e3-b763-0c11607add81",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5e9bdaa3-b01d-4465-befb-a56691536f6c",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c1dc6366-d065-4aec-b7b3-93a83a2944a3",
                                                            "requirementId": "a02145c0-93bd-407c-af4a-276f352cd888",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d56205c-e385-4ffb-b12e-a73002ec3c68",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 1313,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                    "name": "Does your school have a Display Energy Certificate (DEC)?",
                                                    "descriptionHtml": "<p>Display Energy Certificates (DECs) are designed to show the energy performance of public buildings.&nbsp;</p><p><br></p><p>Public authorities must have a DEC for a building if all the following are true:</p><p><br></p><ul><li>It is at least partially occupied by a public authority&nbsp;</li><li>It has a total floor area of over 250 square metres</li><li>It is frequently visited by the public</li></ul><p><br></p><p>Many schools meet the above criteria and, therefore, are required to have a DEC and publicly display this.&nbsp;If your school does not meet the above criteria, you can mark this requirement as not relevant.</p><p><br></p><p>You may wish to link to or attach your school’s DEC.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:43:53.905+00:00",
                                                    "originKey": "6420ce7a-e0c6-4db4-b09c-781c6e6c7289",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/check-energy-performance-public-building</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "40ab0d73-ea97-4b20-931a-9db7d53c3063",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4fa97ed5-7aa9-4350-89ef-7dd6fdc504f3",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8682409c-de31-4083-8228-f3d4d0f4ae8a",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c75c11df-df7d-4231-bde6-8a37b9b36ad0",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "172bbb77-9036-4406-ac2b-cd4a85a2621c",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4a8a5352-1399-4fd9-b2c9-20517c0b36d7",
                                                            "requirementId": "b36181d7-bf44-44f5-92bb-d688354c7d98",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c60b1c5f-245f-480b-8791-f125e63483cb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1319,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                    "name": "Does the school have an up-to-date and compliant Child Protection and Safeguarding Policy?",
                                                    "descriptionHtml": "<p>Having child protection policies and procedures in place is a statutory requirement, and these must be compliant with the DfE’s ‘Keeping children safe in education’ (KCSIE) statutory guidance in order to set out a clear and consistent approach to safeguarding and promoting the welfare of pupils.</p><p>&nbsp;</p><p>The governing board or proprietor must approve this policy. Schools can upload their Child Protection and Safeguarding Policy as evidence for this requirement.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:23:10.671+00:00",
                                                    "originKey": "42272741-d535-4337-8c47-0b53a63bf519",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "77c4448d-ac8f-437f-ad12-59e81a3d201d",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "80b2b8eb-fe7a-43c0-acf5-1ebeed33f7e4",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "87c74ab7-540b-4da6-85e1-df0d9aa91a31",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b64a3a00-9e58-4ce5-a602-daf55b986b6b",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "bdc97344-03d1-4595-97ab-a544f4111521",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cade1478-d466-4c88-bbc4-8a592c0798c2",
                                                            "requirementId": "0467c347-7704-46fd-90d2-58f96ee2ddf3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c52316c6-6267-4fe2-a20c-6072e3789beb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1320,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                    "name": "Does the school have an Allegations of Abuse Against Staff Statement?",
                                                    "descriptionHtml": "<p>The Allegations of Abuse Against Staff Statement must outline the procedures which must be followed for addressing allegations of abuse in a fair, consistent and efficient manner, whilst ensuring the safety of all pupils. This document should cover all staff, including supply staff, volunteers and contractors.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:16:54.199+00:00",
                                                    "originKey": "a7b2f41b-50a8-4efe-b5ce-a44e163c4f6d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 22,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "93455dc9-83f0-49c0-896e-c62d390bb310",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a32ed7ea-53de-49d5-90b5-83db88ea9248",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b8f8bab7-0343-40c8-8506-b9d2467fbed0",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd65e10a-8c5c-452c-8087-4f9210872cc3",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "51751147-2c56-46f1-8615-52d5e809e002",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b8709721-1ce2-4cc8-bd74-86b7634a5c44",
                                                            "requirementId": "1c5efe5a-0103-49f9-be12-c023bb184699",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2b0b5261-d86e-43d5-980f-493ed3437a74",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1321,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                    "name": "Does the school have a Data Protection Policy?",
                                                    "descriptionHtml": "<p>Schools are required to have a Data Protection Policy setting out procedures relating to data protection. This policy can be reviewed and approved by the governing board, an individual governor or the headteacher.</p><p><br></p><p>The policy needs to be made available to all staff and an annual record should be kept which shows that staff have read and agreed to the policy.</p><p><br></p><p>You may wish to attach or link to a copy of your policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:40:55.002+00:00",
                                                    "originKey": "565c4665-6800-4c26-b988-97748ed1b0d8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 7,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "15ed248a-0a0b-405f-96df-76894739a607",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1a09854f-1aa4-4e9a-a50f-f324d7855bf4",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2ce5825a-7aac-4c07-91a7-b7f74fecca9c",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a66a62c4-8e29-4443-a47d-21171954cc3e",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "32896e4a-d0cc-4e02-8b05-72102c719988",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7aeabc28-07b5-4e67-8c65-0c61751605f4",
                                                            "requirementId": "91bdfda4-d75a-43ae-b01d-9022c921207a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "08ee5e05-a9b0-4b7e-974b-8f9cdcc75047",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1322,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                    "name": "Does the school have a Protection of Biometric Data Policy?",
                                                    "descriptionHtml": "<p>Schools using automated biometric recognition systems are required to implement a Protection of Biometric Data Policy that is reviewed by the governing board or equivalent annually. You may wish to upload a copy of your policy.</p><p><br></p><p>If a school does not process biometric data, it is up to the headteacher to consult with the school’s legal advisors and decide whether a policy should be established for the protection of biometric information of children. The DfE advises that schools should state whether they collect biometric data within their SCR and/or Records Management Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:42:35.93+00:00",
                                                    "originKey": "2b0c8539-1255-48eb-951a-2f86617e9418",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 83,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6fc63bab-83cd-4550-aafd-e17aa9121b0b",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "73bc9325-1e99-4cf1-8ba2-1cf08e47568d",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82e9dad7-4c60-43a9-91fa-4a955629ee86",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "97e6a696-f6d1-40c3-8cfa-569a14924976",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8045f443-a4ed-49c8-897c-3964540004de",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f5a00317-0319-4462-b741-0805fa6338c7",
                                                            "requirementId": "b9bbaf13-fc70-4ed6-8d77-804466691644",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a7d88655-ab2d-4736-b07d-47f324ad4736",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1325,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                    "name": "Has a designated teacher with relevant qualifications, experience and training been appointed to promote the achievement and wellbeing of LAC?",
                                                    "descriptionHtml": "<p>Governing boards have a duty to designate a member of staff as having the responsibility to promote the educational achievement of LAC. They must also ensure that they undertake the appropriate training.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T14:51:28.957+00:00",
                                                    "originKey": "fc53fb7e-e512-409a-afab-9d82943f5689",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>The designated teacher for looked-after and previously looked-after children</p>",
                                                    "policyManagerId": 82,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "212046c5-a512-4038-8352-ac3a432540cf",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "542ca740-029e-4946-b852-2f16fd6e1127",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ebda8842-5b84-4424-ab27-d0d3e70d9c10",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f3e1a8ba-9490-4ee3-b308-67cd512d83c3",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "288015c6-cfd9-4f9b-99a8-00b4df0887f9",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "876ee77b-fc3c-441b-98bc-07669f339fa7",
                                                            "requirementId": "308ee362-3f17-4ba3-a4e0-68145049fa7c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fe4d102a-606e-4a98-aaed-93c1ab4fef94",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1326,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                    "name": "Does your school have procedures in place to protect the wellbeing and mental health of staff with disabilities, e.g. protection from discrimination?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 makes it unlawful for any employer to discriminate against disabled people. All employers should be able to make changes to the way that an individual’s employment is structured when required, including removing physical barriers to working and/or providing extra support so that disabled employees are not disadvantaged.</p><p><br></p><p>Employers have a duty to make reasonable adjustments to ensure that disabled employees have the same access to everything involved in doing and keeping a job as non-disabled workers.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T14:57:44.199+00:00",
                                                    "originKey": "1d0302db-4125-43e1-98d1-2d760d666e7e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "98e754f7-0d5c-4959-8c06-b471913ebbc1",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "990d2b8a-e9d9-483f-8c0b-60370860c859",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aee00684-6091-4a0f-8603-cc52e6fe70a6",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1c9a28c-5ce7-4d73-b966-de4a4ef7ad33",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "44ee6c10-1ba0-4177-8d0b-410cb71f482d",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "97bcbc81-1340-4b88-9199-f585aec7e695",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bf559095-b5a7-4e71-8e5b-1c203f9185ab",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1327,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                    "name": "Is your school’s approach to protecting staff members’ wellbeing based on avoiding existing and reasonably foreseeable harm, such as stress, in the workplace?",
                                                    "descriptionHtml": "<p>To comply with the Management of Health and Safety at Work Regulations 1999, your school must ensure that its approach to protecting staff members’ wellbeing is based on avoiding existing and reasonably foreseeable harm, such as stress.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:06:57.466+00:00",
                                                    "originKey": "18bef496-194d-4458-b5c4-2398c84d9bd0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3a851219-8b6b-47b9-bdb6-6cf57412baff",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e02a4c96-ef68-4d81-be78-1c67aea10c26",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3190ff3-0a85-4c3b-ad44-215b6c8b5fc4",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fbe6dfcf-9c47-4593-b799-1d47d22b332b",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5aaa21a6-3963-4a7f-a47c-24acf994dc63",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ec4de9ee-6cb6-4177-93c9-cc98ae254e8b",
                                                            "requirementId": "d89ca6ad-6c21-446d-b04d-03bfe8a86e4f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3572c611-1229-4108-a6f9-832c1e665042",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1328,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                    "name": "Do your school’s policies and procedures take into consideration that prolonged and substantial mental health issues may be considered a disability?",
                                                    "descriptionHtml": "<p>Your school must ensure its policies and procedures take into account that prolonged and substantial mental health issues may be considered a disability under definitions outlined in the Equality Act 2010. You could link to or upload where these procedures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:10:37.599+00:00",
                                                    "originKey": "106c3977-4a05-4098-a7ba-8d6b0100a979",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3242957c-0211-4d15-a04f-12010dc419cf",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c51c5a4-7c81-4464-a1e1-c04f4e3ac261",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "94797eb7-f2c7-44d8-94fb-a0fcbed4a356",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec0cf208-7c3e-475e-8caf-170570b4b43f",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a5d68926-7658-49ae-9ada-d65174ef9863",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e7898d04-f50f-486d-b35d-37a8e92b6c39",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5fa1687b-6328-482e-832b-68f356d71996",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1329,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                    "name": "Does your school involve the designated person responsible for health and safety in changes to the workplace or work practices that could cause stress?",
                                                    "descriptionHtml": "<p>Your school must ensure that the person responsible for health and safety is involved in the implementation of changes to the workplace or workplace practices. This includes changes to the workplace or work practice that are in place to mitigate the risk of stress.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:13:38.59+00:00",
                                                    "originKey": "6a98689b-04ad-4ed8-b04f-6717146f0a9d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8f1cd79a-9e26-4768-be05-33a440d126a5",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d565d5b9-5e5c-45a7-be45-4ad8f579684e",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d5f08f76-0d84-4ef5-95ac-c08d8f8c172f",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5c3ef06-f0f5-4a36-a647-b64a62544c36",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2b8a3568-427e-4319-a7a2-86bff65e4b3a",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "37b5e3f3-69ca-431d-8f1f-db31416e4ada",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "81eb0b3f-5843-4903-91c7-5aed60646331",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1330,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                    "name": "Does your school involve the designated person responsible for health and safety in the creation and undertaking of relevant risk assessments?",
                                                    "descriptionHtml": "<p>Your school must ensure that the person responsible for health and safety is involved in the creation and undertaking of health and safety-related risk assessments, including those relating to stress and wellbeing.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:16:01.206+00:00",
                                                    "originKey": "cc73e060-2723-4196-b1d9-0e79e0273752",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ac0dc49-a72e-4306-a00a-cfa65bf0db31",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45f08474-6262-402c-af50-5b6fd817100d",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "abb67dad-10cd-4107-907e-b2528ad9e131",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff47362b-8e0f-4b80-8fe4-c74a7b2521c5",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3ed1a52d-076a-4c9c-b4d3-010388549fd4",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "52b333a0-2685-4f82-b18c-94c16a372466",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0d88b3be-27fb-466b-ab25-c0803564658a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 1332,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                    "name": "Does the school have the required premises management documents?",
                                                    "descriptionHtml": "<p>Schools must have premises management documents pertaining to safe management and maintenance such as asbestos, fire safety and statutory testing. Policies and procedures must be compliant with appropriate legislation, including health and safety.</p><p><br></p><p>A Premises Management Policy is a good way outline how the school meets premises management requirements.</p><p><br></p><p>The governing board can delegate approval of this requirement to a committee of the board, an individual governor or the headteacher</p><p><br></p><p>You may wish to link to or attach your school's related documents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:51:27.89+00:00",
                                                    "originKey": "8cca5150-144f-4556-b1ce-7f0c672ab302",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 25,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "17651ae8-ed1b-4357-817b-a34885d0dee7",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "76669848-2967-4d5f-ac64-64009ad585e2",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1feb7b3-ae21-45d5-9978-a17b2b7de74f",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f3158583-2a83-491e-93fa-67e1c3f2a10d",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6fe1154b-cd75-4722-ae0c-ceb5a7cdd88c",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e558958e-ec8c-484d-9361-a1662daa2fd7",
                                                            "requirementId": "6393b370-2865-4a3b-b993-4981d88c6612",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e3a80513-913a-4e33-987c-405f50d48e9a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1333,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                    "name": "Has the school got a freedom of information publication scheme?",
                                                    "descriptionHtml": "<p>Under the Freedom of Information Act 2000, schools are required to hold a Publication Scheme that is readily available to the public and details the school's commitment to proactively publish information. Schools must adopt the ICO’s model Publication Scheme in full and without modification, with the exception of adding information where directed to do so within the document itself.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:08:16.084+00:00",
                                                    "originKey": "26c79f1b-f2bf-4d54-b891-de1de4f1b458",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO 'Guide to freedom of information: publication scheme'</p>",
                                                    "policyManagerId": 13,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "03b388e9-28c6-4968-8201-5147b45e89d9",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "043327fb-b4e8-40a2-bb35-cbafe1b915c9",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8401453f-538c-446a-8060-092d7bb9a917",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dace5a6a-b843-40ca-b8b3-895b3105f01d",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "23204d36-62ea-42df-aca6-fecbb5945a63",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b41918ae-dcb1-4cd5-ba28-848f5fbcefc6",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7fc4cafe-8932-4889-a14d-3c0a3cae082c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1334,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                    "name": "Has the school got a freedom of information publication scheme?",
                                                    "descriptionHtml": "<p>Under the Freedom of Information Act 2000, schools are required to hold a Publication Scheme that is readily available to the public and details the school's commitment to proactively publish information. Schools must adopt the ICO’s model Publication Scheme in full and without modification, with the exception of adding information where directed to do so within the document itself.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:08:16.084+00:00",
                                                    "originKey": "26c79f1b-f2bf-4d54-b891-de1de4f1b458",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO 'Guide to freedom of information: publication scheme'</p>",
                                                    "policyManagerId": 13,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "03b388e9-28c6-4968-8201-5147b45e89d9",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "043327fb-b4e8-40a2-bb35-cbafe1b915c9",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8401453f-538c-446a-8060-092d7bb9a917",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dace5a6a-b843-40ca-b8b3-895b3105f01d",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "23204d36-62ea-42df-aca6-fecbb5945a63",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b41918ae-dcb1-4cd5-ba28-848f5fbcefc6",
                                                            "requirementId": "2345364c-7dcc-413a-ab54-2b70bd69e8f1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "15197731-4b0a-48d9-a768-8b3175928830",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1335,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                    "name": "Does the school have all the necessary privacy notices?",
                                                    "descriptionHtml": "<p>Under data protection legislation, schools are required to inform individuals on how their personal data may be collected, stored and used. An effective way of doing this is through privacy notices.</p><p><br></p><p>You may wish to attach or link to your school’s privacy notices.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:11:21.535+00:00",
                                                    "originKey": "e8331e28-85de-408d-aa49-f3c80e8f4b99",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO 'Guide to data protection: what privacy information should we provide?'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "420bed5d-a0f1-445d-b2d0-ff19f68b791d",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "673ebd17-b0de-4b36-a1cb-95ba27a14a02",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6b2048b6-4e2d-4ea0-9a34-e5d6af4fb0d4",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b1a5199b-4f33-4eea-ab8a-4495e9fdffce",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "10ac1438-ad76-400a-bf22-f299d60688a0",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "81767aa6-f8ba-4199-ba96-2058cb3a7ef5",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8d9469f4-20f7-4277-a238-ec298ca60f88",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 1336,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                    "name": "Does the school have all the necessary privacy notices?",
                                                    "descriptionHtml": "<p>Under data protection legislation, schools are required to inform individuals on how their personal data may be collected, stored and used. An effective way of doing this is through privacy notices.</p><p><br></p><p>You may wish to attach or link to your school’s privacy notices.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:11:21.535+00:00",
                                                    "originKey": "e8331e28-85de-408d-aa49-f3c80e8f4b99",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ICO 'Guide to data protection: what privacy information should we provide?'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "420bed5d-a0f1-445d-b2d0-ff19f68b791d",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "673ebd17-b0de-4b36-a1cb-95ba27a14a02",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6b2048b6-4e2d-4ea0-9a34-e5d6af4fb0d4",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b1a5199b-4f33-4eea-ab8a-4495e9fdffce",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "10ac1438-ad76-400a-bf22-f299d60688a0",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "81767aa6-f8ba-4199-ba96-2058cb3a7ef5",
                                                            "requirementId": "a082d347-92f8-4ee2-8692-aaf8586ce762",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0276a977-5923-4549-a941-1609bc881d5b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1337,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                    "name": "Does the school have an up-to-date Data and Cyber-security Breach Prevention and Management Plan?",
                                                    "descriptionHtml": "<p>Academy trusts must be aware of the risk of cyber-crime and&nbsp;put in place proportionate controls to manage these risks.</p><p><br></p><p>A comprehensive plan can help trusts to uphold high standards of security, take suitable precautions, and have systems and procedures in place to support this.</p><p><br></p><p>You may wish to upload a copy of your plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T08:52:16.694+00:00",
                                                    "originKey": "7888636a-172f-4941-ab93-1758c62693a5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>https://www.itgovernance.co.uk/blog/gdpr-compliance-and-information-security-reducing-data-breach</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "54055372-0616-4255-9c69-771f7dcdd220",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c04fa44-3235-4631-9a4e-4ca5021a7c97",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c6ef3f88-8198-47bc-9ac8-8952b708255c",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea9464d4-44d9-4177-b30f-c42cb2903648",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "dc514b2e-a596-491c-9b72-e04858fdb1c6",
                                                            "requirementId": "3b504a9d-da1d-4967-90ab-cba4686311e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cca85bf3-8c08-4dc2-b1c2-acaa42484828",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1383,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                    "name": "Does the school have a SEND Policy?",
                                                    "descriptionHtml": "<p>SEND policies must set out the framework for promoting the educational achievement of pupils with SEND by catering to their individual needs and encouraging inclusivity in every way. A SEND Policy will ensure schools have due regard to their statutory responsibilities set out in the ‘Special educational needs and disability code of practice: 0 to 25 years’.</p><p>&nbsp;</p><p>The governing board or proprietor must approve this policy.</p><p><br></p><p>You may wish to link to or attach your school's SEND Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:30:24.942+00:00",
                                                    "originKey": "2feb780e-cc00-4cf2-8e08-854191f58e35",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 89,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually or immediately after any changes during the school year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "204ac4e2-3311-4921-874d-0bd50ff1448f",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4935b612-f763-4230-acbb-e6053b1e9d2b",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7ace00e3-f7a6-4278-b042-794ddfe039e9",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b9b1c9f5-6098-4d9f-a2fd-1edace82046d",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bb2b096-1b56-4a5a-a973-d3337c19b7fd",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "5799e9b7-bf0b-459e-8129-841dbb9d26a8",
                                                            "requirementId": "8d7ec480-2f62-47d3-9029-a087c7956591",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0ad27496-28ff-4d1c-a3b9-648ebc0f1526",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1384,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "109a2363-3c17-4b00-831d-dea04f472251",
                                                    "name": "Does the school have a SEN Information Report?",
                                                    "descriptionHtml": "<p>You must have a SEN Information Report on your school’s policy for pupils with SEN. The report must comply with Section 69 of the Children and Families Act 2014, including:</p><p><br></p><ul><li>The arrangements for the admission of disabled pupils.</li><li>The steps you have taken to prevent pupils with SEND from being treated less favourably than other pupils.</li><li>The facilities you provide to help disabled pupils to access the school.</li></ul><p><br></p><p>You may wish to link to or attach your SEN Information Report.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:32:18.196+00:00",
                                                    "originKey": "f068b076-23a7-4d14-89a9-b9ca8b29a0b8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 5,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a178f5f2-c885-4192-88c5-ff6253719c24",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ab2c6ed6-b723-48de-b551-11dbac625f10",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b015bdce-df76-4db1-a1d1-7b27af9cf121",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de7196a9-4bfe-4793-9669-f1b209fdc002",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4b96db92-a2e3-4774-839c-37a7ac3fdc1c",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f12ec507-72fe-4963-95eb-cfc895408e88",
                                                            "requirementId": "109a2363-3c17-4b00-831d-dea04f472251",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "86451a7e-4a99-4dba-9e46-af61dd777c16",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1385,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                    "name": "Does the school have an Accessibility Plan?",
                                                    "descriptionHtml": "<p>Schools must have a plan which is aimed at increasing the extent to which pupils with disabilities can participate in every aspect of school life.</p><p>&nbsp;</p><p>This should be approved by the governing board, a committee of the governing board, an individual governor or the headteacher.</p><p><br></p><p>You may wish to attach or link to your school's Accessibility Plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:21:23.668+00:00",
                                                    "originKey": "5e9246c5-495f-4dec-bc9f-588428e1e4f1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 24,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every three years.</p>",
                                                    "requiresReviewInDefaultMonths": 36,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "250cca9c-85e9-43f1-bc4e-9862c7e1016e",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b65550d-7b11-4f8d-99ce-788325727032",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a9007be9-7dc5-4b4b-a7d5-903b11a62b18",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6ef370d-f7e8-4e49-b3cb-4ed8a1faef20",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2e16cdae-6f4e-4ea7-b0b9-b7979fc7b946",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3b676851-1089-48b4-91fe-28cb247bb8f8",
                                                            "requirementId": "cb6fbd3f-17cc-4540-bed4-61c726ec2c0e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cf3240e3-fa3a-4526-94c4-da80cc43316e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1388,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                    "name": "Are there procedures in place to monitor pupils’ attendance through a daily register and investigate any unexplained absences?",
                                                    "descriptionHtml": "<p>Schools are expected to promote good attendance and reduce absence and act early to address patterns of absence. It is important that schools remind parents of their legal duty to ensure their children of compulsory school age attend regularly. The law requires all schools including independent schools to have an admission register and, with the exception of schools where all pupils are boarders, an attendance register.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:28:37.275+00:00",
                                                    "originKey": "21eed02c-d541-4382-8aa5-f18ed9984814",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'School attendance guidance'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41d2ac91-6475-4f3a-a183-d03d53544a04",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "621ee8c8-a041-495c-bcd9-5697e129248f",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8863effd-5d7c-4603-803d-9090e40588a1",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ae12ca6d-e88b-43bc-8121-e26a20aa4123",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "08462bc3-c012-4c73-9293-5d6d88456bc8",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "61ccc534-b015-4176-a81c-851feebd06d6",
                                                            "requirementId": "834a7cb1-e09a-40e7-b1a1-779006aa39a7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1cdb8135-0fec-47af-9429-b1ed5ff8d131",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1389,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                    "name": "Are there procedures in place to inform the LA of all deletions from the admission register when a child is taken off the roll?",
                                                    "descriptionHtml": "<p>Schools must notify the LA when a pupil’s name is to be deleted from the admission register under any of the grounds prescribed in regulation 8 of the Education (Pupil Registration) (England) Regulations 2006. This must be done as soon as the ground for removal is met and no later than the time at which the pupil’s name is removed. </p><p> </p><p>This does not apply where the pupil’s name is removed after they have completed the school’s final year, unless the LA requests this information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:30:54.266+00:00",
                                                    "originKey": "3368e3c2-f9c2-4b9e-88b3-b4fb40fc5cd8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'School attendance guidance'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c041d5c-0983-4ae8-aafc-8da85c773b5d",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "27261120-6a4e-47ff-9643-6c47b368d79a",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7a1c2302-cf8d-46b1-89b5-fbb0fe2de4fe",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "833780ee-1ab0-4e8e-8017-c1bc64ea4e04",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "063897f5-7dda-4631-b841-a81f992c0514",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ac23170d-e14e-4b0f-8f69-356ff3d5ce54",
                                                            "requirementId": "518a14f4-ae98-455b-b8ba-06d3d69fca2f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1a81dcc8-7f10-44a5-a1c6-cd1f489c3671",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1390,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                    "name": "Has the school provided annual reports for the parents of pupils with SEND?",
                                                    "descriptionHtml": "<p>Your school must provide an annual report for the parents of pupils with SEND about their child’s progress. It is common practice to go beyond this and provide more regular reports to parents on how their child is progressing.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-01T10:19:10.733+00:00",
                                                    "originKey": "31441842-270a-4695-845e-a86459b0da3a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2019) ‘Special educational needs and disability code of practice: 0 to 25 years’, para.6.64</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, though your school may produce more regular reports.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5a182078-f333-4ce3-8ba2-f5b2472934ed",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d577e75a-6aac-4ec8-89a8-0ab8054870e7",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6436817-a174-4127-83c5-68e6e3a4dc44",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "52547e07-bf8f-445a-a7bd-2f9ab4f92e0b",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f61362ca-cc6d-4d19-ab24-eb5f3d3e7ac1",
                                                            "requirementId": "e59a8bd4-0c95-4064-9302-9a397d2a1dca",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "86a685a1-4b97-40c7-89c6-283caca70e10",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1391,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                    "name": "Do pupils’ progress reports for all compulsory years contain the correct mandatory information?",
                                                    "descriptionHtml": "<p>Written progress reports must contain certain mandatory information, including information that is dependent on the pupil’s key stage, in line with schedule 1 of The Education (Pupil Information) (England) Regulations 2005. Progress reports for all compulsory years must include the following information:</p><p> </p><p> • The pupil’s general progress</p><p> • The particulars of the pupil’s achievements, highlighting strengths and developmental needs</p><p> • How to arrange a discussion about the report with a teacher at the school</p><p> • The pupil’s attendance record</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:36:23.545+00:00",
                                                    "originKey": "4894c6a6-5c5b-4947-a7c1-5a817f193aff",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE and STA (2021) ‘School reports on pupil performance: guide for headteachers’</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "02ed6df8-162b-4b08-aff7-4a2b5ec57bb1",
                                                            "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77a5f185-28e7-44cd-aa7b-96fab19489f6",
                                                            "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9a73dc4-6515-4d4a-8474-3ffcab906e4b",
                                                            "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "75262038-aca8-4479-9018-ed7315b711c7",
                                                            "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ced9b137-a78c-47f2-89aa-868a77389a36",
                                                            "requirementId": "374bb1f3-95bc-49e4-8ce4-2b04d573dfdc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7fe2a517-985a-45e9-8c53-a6134543dcdf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "50e5e85d-eca0-4250-a0cc-15c7bc0fc53a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1400,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "50e5e85d-eca0-4250-a0cc-15c7bc0fc53a",
                                                    "name": "Has your school published information on the PE and sport premium?",
                                                    "descriptionHtml": "<p>Academies that receive the PE and sport premium must publish certain information stated in the trust's funding agreement with regards to how the premium has been used. If it is not stated in the trust's funding agreement to publish this information, it is still strongly recommended by the DfE to do so. </p><p> </p><p>You can evidence this by uploading or linking to where this information was published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:30:36.103+00:00",
                                                    "originKey": "d2221e9d-97e2-4fb0-b73e-28dbc49b67c7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5b97b7ab-1ddc-4081-baf9-3ad402823ff6",
                                                            "requirementId": "50e5e85d-eca0-4250-a0cc-15c7bc0fc53a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "496b2000-781f-44a3-a548-49e7fc89c632",
                                                            "requirementId": "50e5e85d-eca0-4250-a0cc-15c7bc0fc53a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b6b14607-2df2-4aa3-9fa3-d8cdac74b511",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4ff47f57-4156-4ed1-a823-78e1b4894dca",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1401,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4ff47f57-4156-4ed1-a823-78e1b4894dca",
                                                    "name": "Has your school published information about Year 6 swimming?",
                                                    "descriptionHtml": "<p>Maintained primary schools must publish information about how many of their Year 6 cohort met certain national curriculum requirements in swimming. The school must state how many Year 6 pupils can:</p><p><br></p><ul><li>Swim competently, confidently, and proficiently over a distance of at least 25 metres.</li><li>Use a range of swimming strokes effectively.</li><li>Perform safe self-rescue in various water-based situations.</li></ul><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:32:17.272+00:00",
                                                    "originKey": "1d9eab4b-c612-4652-b389-2de6e28ac1cc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a84daa5e-be05-40ee-89bd-629cec6262e3",
                                                            "requirementId": "4ff47f57-4156-4ed1-a823-78e1b4894dca",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "dbc34102-8364-462d-9f88-2d3e3bb0c586",
                                                            "requirementId": "4ff47f57-4156-4ed1-a823-78e1b4894dca",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7392b39e-b1c3-4554-a8c6-b8e6db005d0f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1403,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                    "name": "Does the school have a clear procedure for identifying and responding to SEND?",
                                                    "descriptionHtml": "<p>The SEND Code of Practice requires providers to have arrangements in place to support children with SEND. These arrangements should include identifying and responding to SEND. Schools should endeavour to identify SEND at the earliest point and then make effective provision to improve the long-term outcomes for pupils.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:45:14.534+00:00",
                                                    "originKey": "f904d7f4-d610-45fd-b8eb-23ad132d0d10",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21e3b6a8-1a20-4963-b54d-c8ef2f62f124",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "633bf31e-ab1c-4d38-942a-1dac02e89fa8",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ba8fec0-1f95-4a57-9820-d6c0630fd83a",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d592daf1-ff36-4f46-97c5-2d87f3a038de",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d6602948-2936-44ea-afa7-aebe08853b80",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ed0610df-641a-4295-8507-67f436e5d5ab",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1b1d7fee-3981-49f0-ac9b-fe3fa13b3954",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1404,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                    "name": "Does the school have procedures in place to ensure that pupils with SEND can access and enjoy the same opportunities at the school as any other pupil?",
                                                    "descriptionHtml": "<p>Schools must ensure that their procedures and functions have regard to the Public Sector Equality Duty (PSED) by promoting the equality of opportunity and fostering good relations between pupils with SEND and without SEND. Information must be published in order to demonstrate compliance with this general duty and specific, measurable objectives to achieve the core aims of the general duty must be prepared and published.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:46:31.467+00:00",
                                                    "originKey": "4c058a6f-a191-4230-8467-5b02627405ad",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "47421b28-899f-4b7c-93c8-4274e18567d2",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c033698-c423-4b6f-aff2-1df747f9785b",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7fc69438-f84b-4aee-8a01-cf9624d64029",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9c2c95b5-c792-4573-b1ea-9170596e6072",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "27701c20-24f9-4942-890e-8db9f1c34645",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e38fb0ac-e738-44f0-9a86-0f6fcf47cea7",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "adddf0a7-67d7-44fe-8859-0c807aa16cf4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1405,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                    "name": "Has the school appointed a teacher to be SENCO?",
                                                    "descriptionHtml": "<p>Governing boards of maintained schools and proprietors of academy schools must ensure that there is a qualified teacher designated as SENCO for the school.</p><p><br></p><p>Where they have not previously been the SENCO at that or any other relevant school for a total period of more than 12 months, they must achieve a National Award in Special Educational Needs Co-ordination within three years of appointment.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:47:15.7+00:00",
                                                    "originKey": "5f78a0a1-884f-4c98-9f18-7bfb085dafc8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3fce8171-5256-4bf9-a9e3-3024edc50399",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e6335c1-99c8-4426-a608-ac704b469947",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ebc837e3-6293-4888-875c-45eff56a6016",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec2593e8-7e29-4ac4-9a9f-5448c2d0f1ba",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0878906b-14d6-4083-b95a-c215aa77dd46",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ef331393-88b0-4044-bfe1-400fb85252be",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "051b85d4-e50d-4385-9e8e-90d31cc35a2c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1406,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                    "name": "Does the school have procedures in place to consult with and inform parents when they are making SEND provision for a pupil?",
                                                    "descriptionHtml": "<p>The SEND code of practice outlines the criteria for involving parents when making decisions for their child’s SEND provision and keeping them updated on how their child is progressing.</p><p><br></p><p>Parents must be involved in the creation of EHC plans and individual healthcare (IHC) plans. Schools must also provide an annual report for parents on their child’s progress; however, most should provide regular reports. Schools should regularly talk to parents to set clear outcomes and review progress. Schools should also meet with parents at least three times each year.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:50:31.668+00:00",
                                                    "originKey": "d3056d66-1cc7-42de-9ad1-c595d4c00138",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "32ca3c43-c5d7-40d5-acbf-a21d5dca110d",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c455b49-37cd-4263-9042-908fded08071",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5c6c32ce-4e2d-449c-9925-4cfc02ee77f0",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c567648-64f8-488e-97f6-7ce6c145d398",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "15cc1291-5690-4a13-9ab3-7b58cf1ea3bf",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9acdde69-0a94-47cb-a697-59511cff6360",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2d0ecc9f-2991-45fe-a6de-9e2257c96210",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1407,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                    "name": "Does the school have procedures in place to evaluate and implement possible reasonable adjustments for pupils to avoid them being put at a substantial disadvantage?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 stipulates that all schools must make reasonable adjustments, including the provision of auxilliary aids and services, to ensure that disabled children and young people are not at a substantial disadvantage compared with their peers.</p><p><br></p><p>Schools are required to consider evidence that a pupil may have a disability under the Equality Act 2010 and what reasonable adjustments may need to be made. Parents should be made aware of any reasonable adjustments made for their child and any reasonable adjustments should be evaluated regularly and when circumstances change.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:05.258+00:00",
                                                    "originKey": "e621fcef-6229-41cb-af84-b385f35bfbbc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6bbee065-de44-4d99-8577-300763f6348c",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c9313bfd-d2ea-4e6f-a146-58f73b712565",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d01e7e29-ea8c-4c38-94b4-63d63ac0d7ad",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f75eb33c-0683-4dee-91db-8b38bcfd8521",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "80902863-c4f5-4a8f-b840-4d599ab38d41",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cfb7178f-9188-42d5-bbde-72761d8afee0",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bd6652ab-a8a4-42d1-8d3d-800717e6f417",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1408,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6007e447-434f-43e8-82a1-dc3232722422",
                                                    "name": "Does the school cooperate with the LA in reviewing pupils’ EHC plans at least annually?",
                                                    "descriptionHtml": "<p>EHC plans must be reviewed by the LA at a minimum every 12 months. The reviews must focus on the pupil’s progress towards achieving the outcomes specified in the EHC plan. The review must also consider whether these outcomes and supporting targets remain appropriate.</p><p><br></p><p>Schools should have due regard for the ‘Reviewing an EHC plan’ section of the SEND code of practice when reviewing pupils’ EHC plans in cooperation with the LA.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:53.122+00:00",
                                                    "originKey": "97828542-0eae-4d26-b9f6-f2a44516bb92",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0ad28feb-a5b0-4d2a-b1e8-e18873d23cd3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "382bb26f-a8b8-40a7-8182-61028e5b6d96",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91691a61-4146-466b-8a64-e7ccadfa5b6e",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8231bd8-6852-4f84-b216-925f8827ebf5",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a0f6561-edbf-4ccd-b29f-127f7ab821f3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a37d8791-7b23-4f97-9fa5-e6b277da6814",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "545f4b66-aabb-4831-8b8c-0e83b652b75c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1409,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                    "name": "Does the school have individual healthcare plans in place for all pupils with medical conditions who need one?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that the school’s policy on supporting pupils with medical conditions covers the role of individual healthcare (IHC) plans and who is responsible for their development. These plans should be reviewed at least annually or earlier if the pupil’s needs change.</p><p><br></p><p>Schools should have due regard to the DfE’s ‘Supporting pupils at school with medical conditions’ guidance when implementing individual healthcare plans.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:53:55.476+00:00",
                                                    "originKey": "e5a2c219-1b64-4bea-97bb-55242103b8ff",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "336d9509-f9aa-4e53-bcda-9d4c6a1e17b5",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "76fba529-0640-464b-a401-bc85b65cfe49",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9fc99223-c3ed-4246-a201-70c6be30d27e",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7e3290c-8ee9-4909-acec-20639b610de4",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "756038bb-aeb8-4110-83cb-732fc506f7c5",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a7bbbdc7-f20e-4321-9acf-a255dbc7d4ec",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bbe1161e-87bb-4550-a6d1-3e99c9ffd6b7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1410,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                    "name": "Has the school provided training for staff members to ensure they can provide pupils with SEND and/or medical conditions with the support they need?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that sufficient staff have received suitable training and are competent before they take on responsibility to support pupils with medical conditions and/or SEND.</p><p><br></p><p>The school’s policy should be clear that any member of staff providing support to a pupil with medical needs should have received suitable training.</p><p><br></p><p>You may wish to attach or link to evidence that shows the appropriate training has been provided.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:05:05.868+00:00",
                                                    "originKey": "18d3d5b6-a983-44b5-a57f-6cebf3d1ff87",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5378f778-4a84-4004-af4b-c0e2bfa2e267",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5be70359-85df-4e74-a8a3-0c03a6ca8c34",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6099511a-b88c-4fca-a666-fdac5b0ce1ba",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f460500-caed-41c7-929a-cab119d8a360",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "49374b6c-fca4-43bf-94c0-a53d667043c7",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d56663a0-445f-4031-b491-b18fb4bc58b2",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e223c5a0-570e-47d5-aed5-e0b3f5dcdd9c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1424,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                    "name": "Does the school cooperate with the LA in reviewing the provision that is available and ensuring it does not disadvantage pupils with SEND?",
                                                    "descriptionHtml": "<p>Schools should cooperate with the LA’s duty under section 13A of the Education and Inspections Act 2006 to ensure that the provision of education promotes high standards, ensures fair access to educational opportunity and promotes the fulfilment by every pupil concerned of their educational potential.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:26:42.764+00:00",
                                                    "originKey": "51d839fa-7a8f-4844-92db-f3304ec7e227",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Education Inspections Act 13A</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "35b4bdf2-ca78-4f67-981e-d5a8b92bceef",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b5970ff-aa5d-4e64-b170-b6ea69ea728a",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ab924026-3484-4e01-af81-5a073cd1e953",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e4132765-6ecc-4dce-abd8-1e9d09ed81dc",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f48c959-9e35-4fe4-8b10-eebd67e1e38f",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9786c282-8b3b-4883-b61a-1ff6dff41a77",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2de4efbb-0319-4f29-8788-6fe3ec1673fd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1427,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                    "name": "Does the school have a clear procedure for identifying and responding to SEND?",
                                                    "descriptionHtml": "<p>The SEND Code of Practice requires providers to have arrangements in place to support children with SEND. These arrangements should include identifying and responding to SEND. Schools should endeavour to identify SEND at the earliest point and then make effective provision to improve the long-term outcomes for pupils.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:45:14.534+00:00",
                                                    "originKey": "f904d7f4-d610-45fd-b8eb-23ad132d0d10",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21e3b6a8-1a20-4963-b54d-c8ef2f62f124",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "633bf31e-ab1c-4d38-942a-1dac02e89fa8",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ba8fec0-1f95-4a57-9820-d6c0630fd83a",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d592daf1-ff36-4f46-97c5-2d87f3a038de",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d6602948-2936-44ea-afa7-aebe08853b80",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ed0610df-641a-4295-8507-67f436e5d5ab",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "09370fd1-1d84-448b-a57b-7a98899dae13",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1428,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                    "name": "Does the school have a clear procedure for identifying and responding to SEND?",
                                                    "descriptionHtml": "<p>The SEND Code of Practice requires providers to have arrangements in place to support children with SEND. These arrangements should include identifying and responding to SEND. Schools should endeavour to identify SEND at the earliest point and then make effective provision to improve the long-term outcomes for pupils.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:45:14.534+00:00",
                                                    "originKey": "f904d7f4-d610-45fd-b8eb-23ad132d0d10",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21e3b6a8-1a20-4963-b54d-c8ef2f62f124",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "633bf31e-ab1c-4d38-942a-1dac02e89fa8",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ba8fec0-1f95-4a57-9820-d6c0630fd83a",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d592daf1-ff36-4f46-97c5-2d87f3a038de",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d6602948-2936-44ea-afa7-aebe08853b80",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ed0610df-641a-4295-8507-67f436e5d5ab",
                                                            "requirementId": "79be2d0d-9881-4e32-885a-56777d155d0e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "da543787-59f2-44c8-ac87-3e86125220f9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1429,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                    "name": "Does the school have procedures in place to ensure that pupils with SEND can access and enjoy the same opportunities at the school as any other pupil?",
                                                    "descriptionHtml": "<p>Schools must ensure that their procedures and functions have regard to the Public Sector Equality Duty (PSED) by promoting the equality of opportunity and fostering good relations between pupils with SEND and without SEND. Information must be published in order to demonstrate compliance with this general duty and specific, measurable objectives to achieve the core aims of the general duty must be prepared and published.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:46:31.467+00:00",
                                                    "originKey": "4c058a6f-a191-4230-8467-5b02627405ad",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "47421b28-899f-4b7c-93c8-4274e18567d2",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c033698-c423-4b6f-aff2-1df747f9785b",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7fc69438-f84b-4aee-8a01-cf9624d64029",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9c2c95b5-c792-4573-b1ea-9170596e6072",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "27701c20-24f9-4942-890e-8db9f1c34645",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e38fb0ac-e738-44f0-9a86-0f6fcf47cea7",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1b0386b8-96c0-4b08-b640-d5f2041cbe95",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1430,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                    "name": "Does the school have procedures in place to ensure that pupils with SEND can access and enjoy the same opportunities at the school as any other pupil?",
                                                    "descriptionHtml": "<p>Schools must ensure that their procedures and functions have regard to the Public Sector Equality Duty (PSED) by promoting the equality of opportunity and fostering good relations between pupils with SEND and without SEND. Information must be published in order to demonstrate compliance with this general duty and specific, measurable objectives to achieve the core aims of the general duty must be prepared and published.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:46:31.467+00:00",
                                                    "originKey": "4c058a6f-a191-4230-8467-5b02627405ad",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "47421b28-899f-4b7c-93c8-4274e18567d2",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c033698-c423-4b6f-aff2-1df747f9785b",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7fc69438-f84b-4aee-8a01-cf9624d64029",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9c2c95b5-c792-4573-b1ea-9170596e6072",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "27701c20-24f9-4942-890e-8db9f1c34645",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e38fb0ac-e738-44f0-9a86-0f6fcf47cea7",
                                                            "requirementId": "8c9ec322-9dda-4ca4-9329-a26bfe3badeb",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "649875b6-3136-45ca-8448-12a44e34722d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1431,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                    "name": "Has the school appointed a teacher to be SENCO?",
                                                    "descriptionHtml": "<p>Governing boards of maintained schools and proprietors of academy schools must ensure that there is a qualified teacher designated as SENCO for the school.</p><p><br></p><p>Where they have not previously been the SENCO at that or any other relevant school for a total period of more than 12 months, they must achieve a National Award in Special Educational Needs Co-ordination within three years of appointment.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:47:15.7+00:00",
                                                    "originKey": "5f78a0a1-884f-4c98-9f18-7bfb085dafc8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3fce8171-5256-4bf9-a9e3-3024edc50399",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e6335c1-99c8-4426-a608-ac704b469947",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ebc837e3-6293-4888-875c-45eff56a6016",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec2593e8-7e29-4ac4-9a9f-5448c2d0f1ba",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0878906b-14d6-4083-b95a-c215aa77dd46",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ef331393-88b0-4044-bfe1-400fb85252be",
                                                            "requirementId": "d3705e75-865a-480f-b9de-a333497b7be1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ecd13de6-b61d-4afe-843d-12fea7b8dca8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1432,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                    "name": "Does the school have procedures in place to consult with and inform parents when they are making SEND provision for a pupil?",
                                                    "descriptionHtml": "<p>The SEND code of practice outlines the criteria for involving parents when making decisions for their child’s SEND provision and keeping them updated on how their child is progressing.</p><p><br></p><p>Parents must be involved in the creation of EHC plans and individual healthcare (IHC) plans. Schools must also provide an annual report for parents on their child’s progress; however, most should provide regular reports. Schools should regularly talk to parents to set clear outcomes and review progress. Schools should also meet with parents at least three times each year.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:50:31.668+00:00",
                                                    "originKey": "d3056d66-1cc7-42de-9ad1-c595d4c00138",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "32ca3c43-c5d7-40d5-acbf-a21d5dca110d",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c455b49-37cd-4263-9042-908fded08071",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5c6c32ce-4e2d-449c-9925-4cfc02ee77f0",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c567648-64f8-488e-97f6-7ce6c145d398",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "15cc1291-5690-4a13-9ab3-7b58cf1ea3bf",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9acdde69-0a94-47cb-a697-59511cff6360",
                                                            "requirementId": "a1b36a87-200f-4bdd-8962-8c8638e18de2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f7957006-8372-4629-8cda-f8254d57bd11",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1433,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                    "name": "Does the school have procedures in place to evaluate and implement possible reasonable adjustments for pupils to avoid them being put at a substantial disadvantage?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 stipulates that all schools must make reasonable adjustments, including the provision of auxilliary aids and services, to ensure that disabled children and young people are not at a substantial disadvantage compared with their peers.</p><p><br></p><p>Schools are required to consider evidence that a pupil may have a disability under the Equality Act 2010 and what reasonable adjustments may need to be made. Parents should be made aware of any reasonable adjustments made for their child and any reasonable adjustments should be evaluated regularly and when circumstances change.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:05.258+00:00",
                                                    "originKey": "e621fcef-6229-41cb-af84-b385f35bfbbc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6bbee065-de44-4d99-8577-300763f6348c",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c9313bfd-d2ea-4e6f-a146-58f73b712565",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d01e7e29-ea8c-4c38-94b4-63d63ac0d7ad",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f75eb33c-0683-4dee-91db-8b38bcfd8521",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "80902863-c4f5-4a8f-b840-4d599ab38d41",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cfb7178f-9188-42d5-bbde-72761d8afee0",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b06ac4a3-cd24-4b86-b5c5-147ee6b08b2d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1434,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6007e447-434f-43e8-82a1-dc3232722422",
                                                    "name": "Does the school cooperate with the LA in reviewing pupils’ EHC plans at least annually?",
                                                    "descriptionHtml": "<p>EHC plans must be reviewed by the LA at a minimum every 12 months. The reviews must focus on the pupil’s progress towards achieving the outcomes specified in the EHC plan. The review must also consider whether these outcomes and supporting targets remain appropriate.</p><p><br></p><p>Schools should have due regard for the ‘Reviewing an EHC plan’ section of the SEND code of practice when reviewing pupils’ EHC plans in cooperation with the LA.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:53.122+00:00",
                                                    "originKey": "97828542-0eae-4d26-b9f6-f2a44516bb92",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0ad28feb-a5b0-4d2a-b1e8-e18873d23cd3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "382bb26f-a8b8-40a7-8182-61028e5b6d96",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91691a61-4146-466b-8a64-e7ccadfa5b6e",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8231bd8-6852-4f84-b216-925f8827ebf5",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a0f6561-edbf-4ccd-b29f-127f7ab821f3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a37d8791-7b23-4f97-9fa5-e6b277da6814",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "10a425f2-9a05-4952-86ff-a0d4f802e59a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1435,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                    "name": "Does the school have individual healthcare plans in place for all pupils with medical conditions who need one?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that the school’s policy on supporting pupils with medical conditions covers the role of individual healthcare (IHC) plans and who is responsible for their development. These plans should be reviewed at least annually or earlier if the pupil’s needs change.</p><p><br></p><p>Schools should have due regard to the DfE’s ‘Supporting pupils at school with medical conditions’ guidance when implementing individual healthcare plans.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:53:55.476+00:00",
                                                    "originKey": "e5a2c219-1b64-4bea-97bb-55242103b8ff",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "336d9509-f9aa-4e53-bcda-9d4c6a1e17b5",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "76fba529-0640-464b-a401-bc85b65cfe49",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9fc99223-c3ed-4246-a201-70c6be30d27e",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7e3290c-8ee9-4909-acec-20639b610de4",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "756038bb-aeb8-4110-83cb-732fc506f7c5",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a7bbbdc7-f20e-4321-9acf-a255dbc7d4ec",
                                                            "requirementId": "88b282b8-d786-4ef9-b43e-d0e1d51bf9d7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "60278307-d78f-4a41-ae1f-e24bea2241c4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1436,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                    "name": "Has the school provided training for staff members to ensure they can provide pupils with SEND and/or medical conditions with the support they need?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that sufficient staff have received suitable training and are competent before they take on responsibility to support pupils with medical conditions and/or SEND.</p><p><br></p><p>The school’s policy should be clear that any member of staff providing support to a pupil with medical needs should have received suitable training.</p><p><br></p><p>You may wish to attach or link to evidence that shows the appropriate training has been provided.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:05:05.868+00:00",
                                                    "originKey": "18d3d5b6-a983-44b5-a57f-6cebf3d1ff87",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5378f778-4a84-4004-af4b-c0e2bfa2e267",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5be70359-85df-4e74-a8a3-0c03a6ca8c34",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6099511a-b88c-4fca-a666-fdac5b0ce1ba",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f460500-caed-41c7-929a-cab119d8a360",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "49374b6c-fca4-43bf-94c0-a53d667043c7",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d56663a0-445f-4031-b491-b18fb4bc58b2",
                                                            "requirementId": "d706d605-31fc-4c28-af29-a35692bee8e4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5bc371d7-2a0a-44a4-afa9-c6bd6942ac1c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1437,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6007e447-434f-43e8-82a1-dc3232722422",
                                                    "name": "Does the school cooperate with the LA in reviewing pupils’ EHC plans at least annually?",
                                                    "descriptionHtml": "<p>EHC plans must be reviewed by the LA at a minimum every 12 months. The reviews must focus on the pupil’s progress towards achieving the outcomes specified in the EHC plan. The review must also consider whether these outcomes and supporting targets remain appropriate.</p><p><br></p><p>Schools should have due regard for the ‘Reviewing an EHC plan’ section of the SEND code of practice when reviewing pupils’ EHC plans in cooperation with the LA.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:53.122+00:00",
                                                    "originKey": "97828542-0eae-4d26-b9f6-f2a44516bb92",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0ad28feb-a5b0-4d2a-b1e8-e18873d23cd3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "382bb26f-a8b8-40a7-8182-61028e5b6d96",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91691a61-4146-466b-8a64-e7ccadfa5b6e",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8231bd8-6852-4f84-b216-925f8827ebf5",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a0f6561-edbf-4ccd-b29f-127f7ab821f3",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a37d8791-7b23-4f97-9fa5-e6b277da6814",
                                                            "requirementId": "6007e447-434f-43e8-82a1-dc3232722422",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "debe72e7-a17a-418c-ae6c-d5ee1ef55de8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1442,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                    "name": "Does the school cooperate with the LA in reviewing the provision that is available and ensuring it does not disadvantage pupils with SEND?",
                                                    "descriptionHtml": "<p>Schools should cooperate with the LA’s duty under section 13A of the Education and Inspections Act 2006 to ensure that the provision of education promotes high standards, ensures fair access to educational opportunity and promotes the fulfilment by every pupil concerned of their educational potential.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:26:42.764+00:00",
                                                    "originKey": "51d839fa-7a8f-4844-92db-f3304ec7e227",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Education Inspections Act 13A</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "35b4bdf2-ca78-4f67-981e-d5a8b92bceef",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b5970ff-aa5d-4e64-b170-b6ea69ea728a",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ab924026-3484-4e01-af81-5a073cd1e953",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e4132765-6ecc-4dce-abd8-1e9d09ed81dc",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f48c959-9e35-4fe4-8b10-eebd67e1e38f",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9786c282-8b3b-4883-b61a-1ff6dff41a77",
                                                            "requirementId": "abe6ceed-92e6-45d5-946b-a2d1cfb95dc5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "88d2fa25-4704-4a6f-b7d3-166ba4bb6844",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1443,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                    "name": "Does the school have procedures in place to evaluate and implement possible reasonable adjustments for pupils to avoid them being put at a substantial disadvantage?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 stipulates that all schools must make reasonable adjustments, including the provision of auxilliary aids and services, to ensure that disabled children and young people are not at a substantial disadvantage compared with their peers.</p><p><br></p><p>Schools are required to consider evidence that a pupil may have a disability under the Equality Act 2010 and what reasonable adjustments may need to be made. Parents should be made aware of any reasonable adjustments made for their child and any reasonable adjustments should be evaluated regularly and when circumstances change.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:52:05.258+00:00",
                                                    "originKey": "e621fcef-6229-41cb-af84-b385f35bfbbc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6bbee065-de44-4d99-8577-300763f6348c",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c9313bfd-d2ea-4e6f-a146-58f73b712565",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d01e7e29-ea8c-4c38-94b4-63d63ac0d7ad",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f75eb33c-0683-4dee-91db-8b38bcfd8521",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "80902863-c4f5-4a8f-b840-4d599ab38d41",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cfb7178f-9188-42d5-bbde-72761d8afee0",
                                                            "requirementId": "d7bd2f1e-befa-4171-87e9-08896f3e31ea",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "17f1c1c9-100a-4217-9eb5-840673dd4438",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1456,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                    "name": "Does the school have a clear strategy for promoting fundamental British values ?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools have a duty to promote the spiritual, moral, cultural, mental and physical development of pupils at the school and of society. The curriculum and the school's wider policies and procedures should promote the fundamental British values of democracy, the rule of law, individual liberty, and mutual respect and tolerance of different faiths and beliefs.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:00:11.802+00:00",
                                                    "originKey": "189d1bfd-d943-46b1-8965-b72212fff62d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/promoting-fundamental-british-values-through-smsc</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01ce8092-6499-4579-8a2c-6928bfccb7ea",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0b782022-05a8-4699-a7f3-db12394ffc46",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1b3acd76-2c17-4b8f-bb2a-2ef8908c297c",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c326bdf2-1b3a-477f-9a8d-ce35abfe429e",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "bad7e8bb-f3b1-4a49-9fbf-4fab17521e4a",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "db83f373-5ed8-4d1f-a01a-475946c9d8d0",
                                                            "requirementId": "3656df82-5a84-495a-bb09-122441cf8afc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6dc47429-fe78-4520-8266-0148b87746ed",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1457,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                    "name": "Can the school demonstrate that it pays due regard to its duty to make reasonable adjustments for staff members with disabilities?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 places public sector organisations under a duty to make changes to their approach or provision to ensure that services are accessible to disabled people as well as everybody else. Reasonable adjustments can mean alterations to buildings by providing lifts, wide doors, ramps and tactile signage. It can also mean making changes to policies, procedures and staff training to ensure that services work equally well for people with disabilities.</p><p><br></p><p>You may wish to attach or link to relevant policies and procedures. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:33:58.649+00:00",
                                                    "originKey": "8a1b31f1-0962-428d-b9f9-291cef3934a0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/reasonable-adjustments-a-legal-duty</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1e83b2d8-1a6f-43a1-9b22-dc4d351c7376",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "323f7aa8-0d7d-4e96-8f08-90bce5461cc0",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64cf5654-b789-4f86-9cfd-972c1eaf9515",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c47fb274-7579-4309-a7ce-3cee80d93a6d",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "db86c17c-4cd9-465b-9e8d-32a834a8dd23",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f2ce6e48-c371-42b8-b9bd-cef3866020ca",
                                                            "requirementId": "cd0e487d-a676-468b-b790-3507597b07d1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d5d2537b-f50a-4b3f-96f9-cc6ef73d934c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1466,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                    "name": "Does the school have an Equality Information and Objectives Statement?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">An Equality Information and Objectives Statement should include a declaration of aims that ensures equality for all members of the school’s community and have due regard to section 149 of the Equality Act 2010.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:31:37.281+00:00",
                                                    "originKey": "a571da7b-62cd-4c33-8a5f-68fee5980af5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Public sector equality duty</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "04a2fac8-14c6-440d-9efa-651fa0a3a0f6",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "61f68d79-8754-471c-a535-df5d6743d561",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e607ac37-209c-4e25-84f5-9bc641abbcea",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f50e769c-bb62-43c1-ba5f-1fa9caf16f88",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "486e5111-9366-4556-8657-69d648ece362",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f9c40ab7-e504-4b97-a40e-a662f15975ff",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bf6bb773-7fc6-4920-87dc-8d0a42a14239",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1467,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                    "name": "Does the school consider equality implications when required?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools are required to have due regard and consideration for the public sector equality duty, which means that there is a requirement to ensure that equality implications are considered before and at the time that policies are developed and decisions are made, not as an afterthought. Such policies and decisions should be kept under constant review.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:33:25.817+00:00",
                                                    "originKey": "ec5238a1-878f-4b47-accf-5030f8f5cc73",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "430a3ed5-06f6-4d0e-99fa-7db275217841",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "96efef6b-fc3d-4bc8-92b5-563edff19f6b",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9987653e-839a-4b03-bfd6-0909f40f5d22",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bcd02835-5ee0-46a5-989f-2044c59042df",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a384f6e-ac39-45e9-ac4e-779337f4c46f",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d22bca69-29e1-4405-b2da-94fb2912ddd2",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "afa18504-886a-420b-9078-96cdb2aa1ae6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1468,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                    "name": "Does the school regularly review its policies, procedures and practices to ensure they do not discriminate against those with protected characteristics?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">It is unlawful for a school to discriminate (directly or indirectly) against individuals by treating them less favourably because of their sex, race, disability, religion, belief, sexual orientation, gender reassingment, pregnancy or maternity. Schools should ensure this is reflected in their policies and kept under constant review.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:36:12.113+00:00",
                                                    "originKey": "bea5b1da-bc63-4285-bdbb-e0367a335e0c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "34158bfe-3ff9-4dea-a079-c05f29d279a2",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "df5f69d7-0ece-4820-abde-940252d8b4aa",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ee345ed9-f75e-4bce-baf3-2d0db7d0a57d",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f51f302d-9e47-4f1c-98e2-212c8194703c",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a603dd0-4465-4995-8b26-8b1ca6697a6a",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e65a1f86-3d09-412c-afa3-3b63610ae48d",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "82f0df9e-912a-4553-bd74-6bf3237e582d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1469,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                    "name": "Can the school demonstrate that it has a clear strategy in place for eliminating discrimination, harassment and victimisation?",
                                                    "descriptionHtml": "<p>The Public Sector Equality Duty (PSED) requires schools to have due regard to the need to eliminate discrimination and any other conduct that is prohibited by the Equality Act 2010.</p><p><br></p><p>Schools should reflect how provisions will be made to make this possible, e.g. in the Behaviour Policy or Anti-bullying Policy. Schools should note evidence that they have made efforts to eliminate discrimination, harassment and victimisation.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:38:05.606+00:00",
                                                    "originKey": "1e96150c-c416-424f-8937-e519812152a8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06f092a8-8aa5-419a-950b-67b5b8805b34",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "55de8a98-7e1a-4058-a18c-2637a15fdbd4",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c20e777d-b537-4d2e-ac5c-81b509f7ee56",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d9bcdc11-17c0-4256-a9c9-cb5b09ac1126",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a763ff3-782c-4075-b5e0-baf76d325f16",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7082b34d-cf3c-4b27-a763-35a6f45fc55b",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5ddb22df-0eba-4f5e-a9cc-385a3e6c6cc8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1472,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                    "name": "Can the school demonstrate that it has a clear strategy in place for eliminating discrimination, harassment and victimisation?",
                                                    "descriptionHtml": "<p>The Public Sector Equality Duty (PSED) requires schools to have due regard to the need to eliminate discrimination and any other conduct that is prohibited by the Equality Act 2010.</p><p><br></p><p>Schools should reflect how provisions will be made to make this possible, e.g. in the Behaviour Policy or Anti-bullying Policy. Schools should note evidence that they have made efforts to eliminate discrimination, harassment and victimisation.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:38:05.606+00:00",
                                                    "originKey": "1e96150c-c416-424f-8937-e519812152a8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06f092a8-8aa5-419a-950b-67b5b8805b34",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "55de8a98-7e1a-4058-a18c-2637a15fdbd4",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c20e777d-b537-4d2e-ac5c-81b509f7ee56",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d9bcdc11-17c0-4256-a9c9-cb5b09ac1126",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a763ff3-782c-4075-b5e0-baf76d325f16",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7082b34d-cf3c-4b27-a763-35a6f45fc55b",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "87dec81c-2df9-4ebb-8f3b-4d8051e4e321",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1473,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                    "name": "Does the school consider equality implications when required?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools are required to have due regard and consideration for the public sector equality duty, which means that there is a requirement to ensure that equality implications are considered before and at the time that policies are developed and decisions are made, not as an afterthought. Such policies and decisions should be kept under constant review.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:33:25.817+00:00",
                                                    "originKey": "ec5238a1-878f-4b47-accf-5030f8f5cc73",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "430a3ed5-06f6-4d0e-99fa-7db275217841",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "96efef6b-fc3d-4bc8-92b5-563edff19f6b",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9987653e-839a-4b03-bfd6-0909f40f5d22",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bcd02835-5ee0-46a5-989f-2044c59042df",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a384f6e-ac39-45e9-ac4e-779337f4c46f",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d22bca69-29e1-4405-b2da-94fb2912ddd2",
                                                            "requirementId": "febd411c-0111-4dad-b499-1e159974c7f4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2b64c948-14e4-4055-abbd-f4acf1e26375",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1474,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                    "name": "Does the school regularly review its policies, procedures and practices to ensure they do not discriminate against those with protected characteristics?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">It is unlawful for a school to discriminate (directly or indirectly) against individuals by treating them less favourably because of their sex, race, disability, religion, belief, sexual orientation, gender reassingment, pregnancy or maternity. Schools should ensure this is reflected in their policies and kept under constant review.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:36:12.113+00:00",
                                                    "originKey": "bea5b1da-bc63-4285-bdbb-e0367a335e0c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "34158bfe-3ff9-4dea-a079-c05f29d279a2",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "df5f69d7-0ece-4820-abde-940252d8b4aa",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ee345ed9-f75e-4bce-baf3-2d0db7d0a57d",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f51f302d-9e47-4f1c-98e2-212c8194703c",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a603dd0-4465-4995-8b26-8b1ca6697a6a",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e65a1f86-3d09-412c-afa3-3b63610ae48d",
                                                            "requirementId": "7dcd52b5-864d-49f2-8928-8238ac319bfd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3de38a61-131a-421e-ae9e-c0e6e5ce387b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1475,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                    "name": "Does the school have an Equality Information and Objectives Statement?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">An Equality Information and Objectives Statement should include a declaration of aims that ensures equality for all members of the school’s community and have due regard to section 149 of the Equality Act 2010.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:31:37.281+00:00",
                                                    "originKey": "a571da7b-62cd-4c33-8a5f-68fee5980af5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Public sector equality duty</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "04a2fac8-14c6-440d-9efa-651fa0a3a0f6",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "61f68d79-8754-471c-a535-df5d6743d561",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e607ac37-209c-4e25-84f5-9bc641abbcea",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f50e769c-bb62-43c1-ba5f-1fa9caf16f88",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "486e5111-9366-4556-8657-69d648ece362",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f9c40ab7-e504-4b97-a40e-a662f15975ff",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f32a9849-0d1d-443f-9055-8305c7a35b2f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1476,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                    "name": "Does the school have an Equality Information and Objectives Statement?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">An Equality Information and Objectives Statement should include a declaration of aims that ensures equality for all members of the school’s community and have due regard to section 149 of the Equality Act 2010.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:31:37.281+00:00",
                                                    "originKey": "a571da7b-62cd-4c33-8a5f-68fee5980af5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Public sector equality duty</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "04a2fac8-14c6-440d-9efa-651fa0a3a0f6",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "61f68d79-8754-471c-a535-df5d6743d561",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e607ac37-209c-4e25-84f5-9bc641abbcea",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f50e769c-bb62-43c1-ba5f-1fa9caf16f88",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "486e5111-9366-4556-8657-69d648ece362",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f9c40ab7-e504-4b97-a40e-a662f15975ff",
                                                            "requirementId": "b5af218c-764e-448a-88e5-74824ff0cf59",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "eab5968a-bbe8-4f0e-922c-9ba7fdd4d111",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1477,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                    "name": "Can the school demonstrate that it has a clear strategy in place for eliminating discrimination, harassment and victimisation?",
                                                    "descriptionHtml": "<p>The Public Sector Equality Duty (PSED) requires schools to have due regard to the need to eliminate discrimination and any other conduct that is prohibited by the Equality Act 2010.</p><p><br></p><p>Schools should reflect how provisions will be made to make this possible, e.g. in the Behaviour Policy or Anti-bullying Policy. Schools should note evidence that they have made efforts to eliminate discrimination, harassment and victimisation.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies and procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:38:05.606+00:00",
                                                    "originKey": "1e96150c-c416-424f-8937-e519812152a8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06f092a8-8aa5-419a-950b-67b5b8805b34",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "55de8a98-7e1a-4058-a18c-2637a15fdbd4",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c20e777d-b537-4d2e-ac5c-81b509f7ee56",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d9bcdc11-17c0-4256-a9c9-cb5b09ac1126",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a763ff3-782c-4075-b5e0-baf76d325f16",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7082b34d-cf3c-4b27-a763-35a6f45fc55b",
                                                            "requirementId": "d50e8e58-c5cd-4477-91d9-168ab5582172",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3f7c4a77-fd39-42b7-a6dd-ba2eebcee10d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1483,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                    "name": "Has your school published its SEN information report?",
                                                    "descriptionHtml": "<p>Your school must publish a SEN information report. The report must cover how the school implements its policy for pupils with SEND. This requirement may already be fulfilled by publishing the school's accessability plan, providing it includes the required information about SEND.</p><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:24:32.512+00:00",
                                                    "originKey": "d46037a3-efc0-4e0d-9a17-22bdf31e11b9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p><p><br></p><p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 5,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1bdb18ab-a0f2-4f8c-8d7e-6c903f823b6e",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37779cd8-8af5-4917-b28f-e0a40c4d3324",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "80c8198b-4ea1-467e-acee-e4947eddd1e2",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8542601-4368-44fa-964e-8e027b68fdeb",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1a40425d-f78d-407e-a08d-d6ba2be1cf22",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "96e15095-e1dd-44ab-9db6-c85bb8937377",
                                                            "requirementId": "3823a4c3-4f42-4e8e-abb0-39a2beb49279",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9edc706e-8bc1-490e-880a-084a6e596dfd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1484,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                    "name": "Has your school published its equality objectives?",
                                                    "descriptionHtml": "<p>You must publish your school's equality objectives. This can be done via the school website. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:36:18.63+00:00",
                                                    "originKey": "4c618120-82a7-4c5f-8d02-e38a97fb032a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p><p><br></p><p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement four years.</p>",
                                                    "requiresReviewInDefaultMonths": 48,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "027eab12-6aae-45ac-ac84-1fa9a71b84e0",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1c6ab2c2-0225-4696-95be-4b91188d70f3",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "48f9e2f2-f77c-4bd4-91fa-4ebe152e4311",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dc85a92-3c10-452a-9662-0fd448f47813",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a117d7d-ea57-435e-97ec-8721cf970cb8",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b6e92455-5f1d-4211-9946-03c0b3ff12cd",
                                                            "requirementId": "4dfc1704-e19c-4e60-a761-b67351e16425",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3cff0510-a581-442c-b3fb-a74124addeb0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1485,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                    "name": "Has your school published information on its careers programme?",
                                                    "descriptionHtml": "<p>If your school teaches pupils in Year 8 and above, you must publish information about the school's careers programme on the school website.&nbsp;You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T12:26:59.167+00:00",
                                                    "originKey": "87f6e397-227f-414f-a0ab-c1474a81922a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 85,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "098e5c11-cab7-48ef-b4f0-d031a0b5abd6",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "427e1085-6fe0-4871-9e1b-5a2dc4c57c73",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8ef91eb7-d606-417a-972f-7384bdccb9ca",
                                                            "requirementId": "83afe3cc-ccf2-455b-aeb4-04b6d462fa89",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6fbaa226-0b31-4a29-9b0e-9bc1c434d8d1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1487,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                    "name": "Has your school published a careers policy statement (provider access statement)?",
                                                    "descriptionHtml": "<p>All secondary schools must publish a policy statement about how their school complies with section 42B of the Education Act 1997, also known as the ‘Baker Clause’. This policy statement must cover the circumstances under which providers of technical education and apprenticeships will be permitted contact with pupils in Years 8 to 13.</p><p> </p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T08:27:31.1+00:00",
                                                    "originKey": "835f140e-bc3d-438b-83b3-a64a27cf33e5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p><p> </p><p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0dbe55ee-3bd8-4612-837f-7b2b1ea2f928",
                                                            "requirementId": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ba624167-461d-446f-8e32-aff42c976de8",
                                                            "requirementId": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "549458b7-14b9-49f0-80e9-1077a82e928f",
                                                            "requirementId": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "af2fd0f5-5307-44fa-aa08-4f34c74341fb",
                                                            "requirementId": "45c115f6-4517-4694-b5e5-c4cee44dfcaf",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "42a0fa56-830b-4e8e-b727-5b45ecb43c19",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 1500,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                    "name": "Does the school have procedures in place to support the mental health and wellbeing of all pupils?",
                                                    "descriptionHtml": "<p>All schools are under a statutory duty to promote the welfare of their pupils. Schools should aim to identify issues and intervene at an early stage to provide effective support. The school’s role includes:</p><p><br></p><ul><li>Prevention – creating a safe and calm environment where mental health problems less likely, improving the mental health and wellbeing of the school population, and helping pupils to become resilient.</li><li>Identification – recognising emerging issues early and accurately.</li><li>Early support – helping pupils access evidence-based early support and interventions.</li><li>Access to specialist support – working effectively with external agencies to provide swift access to specialist support and treatment.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:32:28.286+00:00",
                                                    "originKey": "a842f2f7-b063-4a34-9714-9290d6aac8b9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE - Mental health and behaviour in schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "14609bf2-8785-40b7-b34d-e1cd856b05f8",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6775c30c-c2fb-4ab3-b69c-b8ff4e2bbb06",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82d1220a-0325-461f-a8ed-3d5c7a7b8c50",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfbeec7b-7c41-4662-bc89-68345fc3c8f5",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "69cd9026-7b20-4c13-9b3b-6de1081ae691",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ada1bbbd-414a-4218-89ec-8ba08539e87b",
                                                            "requirementId": "59aae7ff-e27c-4fb7-bc57-89c34cd12f74",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d797132-3179-4c4b-8401-6de16689c828",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 1508,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                    "name": "[Schools that do not meet the threshold should mark this as not applicable] Has your school disclosed the necessary information to meet the Streamlined Energy and Carbon Reporting (SECR) requirements?",
                                                    "descriptionHtml": "<p>SECR requires large unquoted companies that have consumed (in the UK) more than 40,000 kilowatt-hours (kWh) of energy in the reporting period to include energy and carbon information within their directors' (trustees') report, for any period beginning on or after 1 April 2019.</p><p><br></p><p>Large companies, as defined in sections 465 and 466 of the Companies Act 2006, are companies that meet two or more of the following criteria:</p><p><br></p><ul><li>Turnover (or gross income) of £36 million or more</li><li>Balance sheet assets of £18 million or more</li><li>250 employees or more</li></ul><p><br></p><p>Some academy trusts will fall into the above criteria and, therefore, need to meet the requirements.&nbsp;</p><p><br></p><p>The ESFA encourages large academy trusts to reproduce the energy and carbon disclosures from their accounts in a readily accessible format on their website before 31 March each year.</p><p><br></p><p>You may wish to link to or attach information about how you meet the SECR requirements.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:45:45.522+00:00",
                                                    "originKey": "cf91877f-79ab-4119-b7e3-48e687ffbde0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Streamlined Energy and Carbon Reporting (SECR) for academy trusts</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08a5df2d-0511-4cb5-bc4c-d1f4afc1e435",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a345e0d1-df96-477b-a9d7-6b02748fa391",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d01dd6d5-d1c7-4bf0-9aa1-66b88440d367",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e7a7e975-59a4-4836-94c1-53dd8d515753",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4fc1b032-4673-4b18-9424-16d2a51e0665",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "878dd6f0-8a9d-43fd-8024-f165cf3466bf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 1521,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                    "name": "Has your school published information on handling complaints from the parents of pupils with SEND?",
                                                    "descriptionHtml": "<p>Your school is required to publish its arrangements for handling any complaints from the parents of pupils with SEND about the school’s SEND provision. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:47:06.59+00:00",
                                                    "originKey": "97617b7c-a919-42db-84cc-e5727ae64a16",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online', DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 12,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4ae62842-7cd6-48c4-9855-bf7ce336405e",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c32ee70-9918-4a6a-9e99-bfed6b4ee4d0",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b3b7ca1a-62fe-46b8-b673-2f9a04408340",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "efac668a-543b-4bd7-bd7e-5f4df5b5975f",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "30e0388f-552f-409d-a0ab-14271bb9c3d9",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "380fdbf4-72af-4b23-aeea-26996603e97d",
                                                            "requirementId": "4697cb7f-4e62-4e06-9712-7e5853a1e475",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9bbff8cd-1726-4849-ae31-ae266d2def27",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1523,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                    "name": "If the school has 250 employees or more, has your school published the required information for gender pay gap reporting?",
                                                    "descriptionHtml": "<p>If your school has 250 or more employees, you must publish information on your school's website about the gender pay gap. Alongside these figures, schools must publish a statement confirming that the information is accurate and signed by an appropriate person – this can be the governing board. You can evidence this by linking to where this information has been published.</p><p><br></p><p><strong>Schools that do not meet the reporting threshold and have not chosen to publish this information anyway may mark this requirement as 'does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T14:02:59.155+00:00",
                                                    "originKey": "362f9e8a-666e-41d4-a766-0b3a88b929a5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "cf7031e6-55ee-448d-b736-dc7af7ae3856",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d364901c-2720-415d-b770-06edf576f156",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3dba175-aa09-463d-9fd3-b66d24176e9c",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e90316cd-106c-4dbc-bc1b-ddebbe8afde8",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "04875701-6622-4da2-90c6-8b8d84052a66",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3a8059a5-6c20-46e7-aa10-ec8c15c1bd83",
                                                            "requirementId": "701386b3-4575-48b0-b8d8-6697e80a856a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b19a59a0-c8e9-4e1d-a3dc-0e668dd189db",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 1525,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                    "name": "If the school has 250 or more employees, has the gender pay gap been reported via the government’s reporting website?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010 (Specific Duties and Public Authorities) Regulations 2017, organisations with 250 or more employees must use the <a href=\"https://www.gov.uk/report-gender-pay-gap-data\" rel=\"noopener noreferrer\" target=\"_blank\">government’s reporting website</a> to report the gender pay gap in their organisation. Maintained schools must report by 30 March each year. Academy trusts must report by 4 April each year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:13:47.372+00:00",
                                                    "originKey": "8ebdb911-cbfc-4ab7-9f0d-e64cd077edd3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA ‘Academies financial handbook 2020’, para.2.32</p><p><br></p><p>Advisory, Conciliation and Arbitration Service and Government Equalities Office (2020) ‘Gender pay gap reporting: overview’, para.1-5</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7462fd4d-6ac1-4f27-99f5-7bd1649f71b5",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7bab59e1-613b-469b-82eb-1fd274edc1fd",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ee9a2eb7-ad55-47c9-9514-ae871d817375",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f6a0854e-ce6e-40b1-b9d7-1d81bd97b8fb",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a190b62-9c0a-46a8-b457-890671fcbaff",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "844c970c-ca71-4691-bc90-1062f51de7fa",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8997aca6-9e9d-4380-9b95-f9c696df4c22",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1526,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                    "name": "Has your school published information on executive pay?",
                                                    "descriptionHtml": "<p>Your school must publish the number of employees whose benefits exceeded £100,000 for the previous year ending on 31 August. This must be published in a readily-accessible form, in £10,000 bandings. “Benefits”, for this purpose, means the employee’s salary, pension contributions, other taxable benefits and termination payments. This also includes off-roll payment arrangements with someone who is not an employee, as if they were an employee, i.e. the amount the trust has paid for the person’s work.</p><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:52:36.22+00:00",
                                                    "originKey": "1111faee-989a-4a9c-b41d-28b968ead85c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6b42ed55-b572-475b-9c79-afc69fce2f05",
                                                            "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8039b523-dc87-4377-b8fd-b1641f1a39d5",
                                                            "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "88e7019c-6a57-4e38-b802-7cf96770b525",
                                                            "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "96d7be00-6d22-403b-9e7f-63b8cbc0cc25",
                                                            "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0645075a-6f59-4db9-8c43-da3b285b1ec1",
                                                            "requirementId": "90958da8-7374-485e-9d50-81b2403d6ee8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "330be1d3-0bc1-497e-a198-8ee2c6a07c85",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1527,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                    "name": "Has your school published its contact information?",
                                                    "descriptionHtml": "<p>Your school must publish the following:</p><p><br></p><ul><li>The name of the school, its postal address and telephone number</li><li>The name of the staff member who manages queries from parents and other members of the public</li><li>The name and contact details of the SENCO (unless the setting is a special school or a college)</li></ul><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:53:36.207+00:00",
                                                    "originKey": "6de1bdfc-99ec-412c-ae0a-adba10f699e5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "09b99b6b-e542-4be5-b978-9d9cf6da1d8b",
                                                            "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "54b48484-40dd-4633-8cfd-45c00bff862b",
                                                            "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "883cf858-27d7-47a7-acc9-794f1015d7ff",
                                                            "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "922f9615-9483-4127-8a11-5668a7d277d0",
                                                            "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "21872599-6406-4ed6-89ec-a44a81bb9205",
                                                            "requirementId": "1785cb5a-1cc6-4e6d-b3a4-30228a75bf0f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fcea2dcb-4ce4-4900-a10b-57c97bd77d54",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "14fe36f7-78f3-49c8-bc7b-34fa41577785",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1531,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "14fe36f7-78f3-49c8-bc7b-34fa41577785",
                                                    "name": "Has your school published its latest KS5 information?",
                                                    "descriptionHtml": "<p>If your school educates students in KS5, you must publish, from your school's dedicated performance tables, the following:</p><ul><li>Progress</li><li>Attainment</li><li>English and maths progress</li><li>Retention</li><li>Pupil destinations</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:08:43.278+00:00",
                                                    "originKey": "71252476-cfd7-471e-9953-077dd404925e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> What maintained schools must publish online</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "632a2c71-fde2-4a53-adb0-b41910556dbe",
                                                            "requirementId": "14fe36f7-78f3-49c8-bc7b-34fa41577785",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "767ce4a4-94f3-486c-a805-0a537a7c9567",
                                                            "requirementId": "14fe36f7-78f3-49c8-bc7b-34fa41577785",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6f333830-88e1-40f3-9169-d0e796fe4111",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "463ee49b-068f-4967-9ef8-62524c9f5815",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1534,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "463ee49b-068f-4967-9ef8-62524c9f5815",
                                                    "name": "Has your school published its secondary curriculum information?",
                                                    "descriptionHtml": "<p>You must publish:</p><ul><li>The content of the curriculum in each academic year.</li><li>The content of the RE curriculum, even if it is taught as part of other subjects or called something else.</li><li>A list of courses and GCSEs offered to pupils in KS4.</li><li>How parents and members of the public can find out more about your school’s curriculum.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:26:34.147+00:00",
                                                    "originKey": "7f364000-421c-4d1c-8cc0-30f692892ba9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6f386337-8e4b-40af-86e3-dd130c13f7d1",
                                                            "requirementId": "463ee49b-068f-4967-9ef8-62524c9f5815",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ad7850ab-e4d6-45ed-95bf-e5e2ce51eec9",
                                                            "requirementId": "463ee49b-068f-4967-9ef8-62524c9f5815",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fde5ba9a-03c4-47a7-9ad4-a462ecfc5f96",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1539,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                    "name": "Has your school published information on associate members of the governing board?",
                                                    "descriptionHtml": "<p>You must publish information about the governing board in line with the DfE’s ‘Constitution of governing bodies of maintained schools’ guidance. Information about associate governors must also be included. You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:40:08.238+00:00",
                                                    "originKey": "ef44cc8d-b50f-468b-9527-2d20dcae8069",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "376bdc42-4bde-4fe0-86e7-a03ad7629ef1",
                                                            "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "412cded1-fecc-46dc-b2ce-f96dff855649",
                                                            "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdfe42af-dae0-4a98-ae26-7f46606400d7",
                                                            "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fb590c6d-98f2-4094-85d7-e08043b1a69e",
                                                            "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "69738a6d-3a71-44ff-aa61-691b4ee8a92b",
                                                            "requirementId": "dd63400a-89d5-46c7-b5fa-4225f58678f6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6a24593f-f76c-4461-a83c-32abd2848e63",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1540,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                    "name": "Has your school published information on any local governors?",
                                                    "descriptionHtml": "<p>You must publish information about your school's local governing board, if it has one, in line with the 'Academy Trust Handbook'.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:41:53.497+00:00",
                                                    "originKey": "80d27e5d-7423-4498-96c3-de3af5aa1264",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "40bca818-d3a7-44b1-bb5a-1a75512654d7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "63df2492-733c-4b1b-a695-6f7fa68dbbbe",
                                                            "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "92989cd3-89be-43cc-86e5-6a2e5dfdad76",
                                                            "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b340f03e-0901-493d-ad1d-03167ea21e76",
                                                            "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5f667aa-0094-407f-8af2-89d25341b04f",
                                                            "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9be39dfa-bfb3-4055-947f-9a17a4dc5369",
                                                            "requirementId": "4c201288-e05c-4ab3-a901-1113672d7b7e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "746c084e-a3e5-4520-9a36-a519870cf0ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1546,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                    "name": "Has your school published its financial information?",
                                                    "descriptionHtml": "<p>Maintained schools are required to publish a link to the specific page about their school provided by the schools financial benchmarking service.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:48:40.81+00:00",
                                                    "originKey": "95890544-98d8-4911-ab6d-19a2c0c81f8c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>What maintained schools must publish online</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0442648c-d294-4e8f-bc79-192071b3e27e",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "340b451b-407b-49b6-a0ec-f8aa3e4466cd",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "58ad6949-7d02-423a-8b74-ad3d0d6aa7d8",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6ce23cf-25c9-44f8-ba1e-15202b503bee",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c1e8341f-86b2-43bc-a97c-ec2f3304f5ed",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ba083e71-9b12-4bca-a699-ec4effee5db5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1548,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                    "name": "Has your school published its financial information?",
                                                    "descriptionHtml": "<p>Maintained schools are required to publish a link to the specific page about their school provided by the schools financial benchmarking service.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:48:40.81+00:00",
                                                    "originKey": "95890544-98d8-4911-ab6d-19a2c0c81f8c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>What maintained schools must publish online</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0442648c-d294-4e8f-bc79-192071b3e27e",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "340b451b-407b-49b6-a0ec-f8aa3e4466cd",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "58ad6949-7d02-423a-8b74-ad3d0d6aa7d8",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6ce23cf-25c9-44f8-ba1e-15202b503bee",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c1e8341f-86b2-43bc-a97c-ec2f3304f5ed",
                                                            "requirementId": "f3e7f3fb-f20e-44d3-9108-2e59864ab287",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a08fb59d-344f-4125-98de-09fda394386e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1550,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                    "name": "Has your school published its Guide to Information?",
                                                    "descriptionHtml": "<p>Your school must publish a 'Guide to Information', which is a document where schools list the type of information they, or third parties, hold, where this information is stored, and whether there is a charge should an individual request it under the Freedom of Information Act 2000. The ICO provides an example template which it strongly recommends all schools use.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:51:34.443+00:00",
                                                    "originKey": "29846fea-96d1-4e55-a919-b79947d31d52",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>FOI Act</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, or as soon as changes are made.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8c2d9238-2700-443f-926f-4b92c3ea6248",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aea63bb7-978e-4035-9a33-830f990466c1",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "af8bc280-282b-4dbe-97d9-c373ff636fbe",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c59c7bef-a4f5-4e62-ad0f-5614478c97f3",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0ad32382-aab7-43b7-adcd-2124872ff002",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f6ff29ae-1b66-4d0b-b5b7-46d38487d65b",
                                                            "requirementId": "e6cc5586-d96d-4cff-a9d5-ac3df9538b24",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a1545ea1-fd5c-4f76-af8b-55bc1cc943ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1551,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                    "name": "Has the school published all of its necessary privacy notices?",
                                                    "descriptionHtml": "<p>All schools and educational settings must communicate all relevant privacy notices, as data subjects have a right to be informed. Publication via the school website is not the only way to meet this requirement, but doing so can be considered good practice and is an effective method of communication.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:52:37.959+00:00",
                                                    "originKey": "34b3a134-05d2-4583-9991-c2ad7bbdc178",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> FOI Act</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, or as soon as changes are made.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "082f79b0-97a0-4e0a-9d06-0a77a4bb43f5",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "68cf28ab-34a0-4640-be96-bc1340e78835",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b9ee1ba-ab33-4d88-bd17-2403bd3a390e",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b73ddcef-0021-4a22-962e-a17dbefe049d",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7ba95a92-43e2-4bc1-b9fb-eebc7d580e02",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d5266ea6-b168-4cea-a5e1-54e96292cc70",
                                                            "requirementId": "794fed5a-b1ec-4a97-b9f3-ffd15946cb94",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2ed46f10-6f6c-449f-bcda-acb00d6c7e86",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1557,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                    "name": "Does the school have procedures in place to report all reportable data breaches to the ICO?",
                                                    "descriptionHtml": "<p>Schools are required to report certain types of personal data breach to the relevant supervisory authority. Schools must have robust procedures in place to be able to report these within 72 hours of becoming aware of the breach, where feasible. You could attach or link to where these procedures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:54:48.015+00:00",
                                                    "originKey": "eb853d08-fed6-4885-966e-a0e8f1f0a4cc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "03662060-4860-4f03-8628-2e647a753d94",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "473e39c1-d93c-4eab-b49e-c9a555090b00",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "68845f34-613f-4667-8d13-5fc4aae9d680",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f80652d-0049-4144-8629-29ba0145f5d5",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "13557f24-b913-466a-a787-85bb67315613",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f9ac1a96-6d9d-4d24-bf1f-58e884fa37a9",
                                                            "requirementId": "7057e3e4-5ef0-4e28-83ad-37ccaec846b8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c8e43fc3-e443-43ea-8013-f384783d1a55",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5fd3b773-2fe2-4f42-9d35-cc1adaefba37",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1559,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5fd3b773-2fe2-4f42-9d35-cc1adaefba37",
                                                    "name": "Has the governing board appointed a member of senior staff to be the DSL, and ensured this is reflected in their job description?",
                                                    "descriptionHtml": "<p>Every early years setting must have a designated practitioner to take lead responsibility for safeguarding children. They must provide support, advice and guidance to any other staff on an ongoing basis, and on any specific safeguarding issue as required. They must also attend child protection training courses that enable them to identify, understand and respond appropriately to signs of possible abuse and neglect.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:38:40.344+00:00",
                                                    "originKey": "56230db2-6078-4f5a-8c55-52be217a94dc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "61830b05-1622-47c1-9f34-a1a55c52d86e",
                                                            "requirementId": "5fd3b773-2fe2-4f42-9d35-cc1adaefba37",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4319823d-513f-43cb-bc92-492989a96598",
                                                            "requirementId": "5fd3b773-2fe2-4f42-9d35-cc1adaefba37",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "63bdec3a-e2b5-4d7b-9082-37ec3a7cd6f9",
                                                            "requirementId": "5fd3b773-2fe2-4f42-9d35-cc1adaefba37",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8c1827b-5d14-4694-8ba7-5aabbb4c3ef7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1559,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                    "name": "Does the school have procedures in place to be able to report instances of female genital mutilation (FGM) to the police?",
                                                    "descriptionHtml": "<p>There is a specific legal duty on teachers to report instances of FGM on a child under the age of 18 to the police. Your school must have procedures and training in place for teachers to report instances of FGM to the police and for all other staff to report this to the DSL.</p><p><br></p><p>You can evidence this by uploading or linking to any policies or documents outlining the school's procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:28:15.348+00:00",
                                                    "originKey": "330b3d66-0fc7-4a48-96a3-5ff3b896188a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1d6e885f-b780-448f-be46-b46c47f0c1bb",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "430269d5-25e5-4a86-85f9-657a1901e885",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a26e2e9-14a9-45fa-ab22-fb9d22d951ed",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "710303f1-0321-4bbb-8286-4898be9d7fc4",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ad4d9ca-3bce-4729-aed9-6178e8a6bfa1",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f40b517a-9445-4f18-8bbc-1fefbf26cbca",
                                                            "requirementId": "4b538775-7e2f-4f28-9d48-397b84156ca6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "47490cda-9be9-441a-9fb4-eea77dfdd823",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1560,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                    "name": "Does the school have procedures in place to be able to maintain the confidentiality of safeguarding reports made against a member of staff?",
                                                    "descriptionHtml": "<p>The Education Act 2002 outlines reporting restrictions preventing the publication of any material that may lead to the identification of a teacher who has been accused of harm while an allegation is being investigated or considered. The school must have procedures in place to fulfil this duty.</p><p><br></p><p>You can evidence this by linking to where this information has been published or uploading an attachment.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:37:50.413+00:00",
                                                    "originKey": "f8e2b5f4-deea-4273-9c31-6b474a182f3e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "638cecaf-cbed-4744-aff5-790c2a78f191",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "750157b0-7d89-415e-b5cf-3e67f643ae30",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "869e381e-5c2c-4761-9655-2b555bf58d93",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "964a99cc-e005-4f04-9c47-1cc5080e6048",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "709453c9-eec1-462c-8f0d-23c28c25fe97",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "82c91bbe-13c2-4774-90ca-8414787b8cfe",
                                                            "requirementId": "2aba540c-b310-4645-88e8-138a25ac5e7e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b7f4553f-95af-4cbc-a6f1-ef4724717bce",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "37c2b594-4c4b-4098-92c6-471e06e486e9",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1563,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "37c2b594-4c4b-4098-92c6-471e06e486e9",
                                                    "name": "Have all staff members received up-to-date safeguarding training?",
                                                    "descriptionHtml": "<p>Early years providers must train all staff to understand their safeguarding policy and procedures and ensure that all staff have up to date knowledge of safeguarding issues. Training made available by the provider must enable staff to identify signs of possible abuse and neglect at the earliest opportunity.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:39:24.208+00:00",
                                                    "originKey": "907980fc-4691-4304-b729-e6fc4432c3a7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Statutory framework for the early years foundation stage</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7551c5e1-e50d-48d9-ab4c-fd8aae443fd7",
                                                            "requirementId": "37c2b594-4c4b-4098-92c6-471e06e486e9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4336d0d6-a249-4d29-8571-889a009b92e8",
                                                            "requirementId": "37c2b594-4c4b-4098-92c6-471e06e486e9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ef057bc6-13fe-40b9-a8e2-ed5c991ad469",
                                                            "requirementId": "37c2b594-4c4b-4098-92c6-471e06e486e9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "62363ef1-f7bd-4e92-9c99-7629b431deb4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1564,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                    "name": "Are there procedures in place for carrying out pre-employment checks during recruitment processes?",
                                                    "descriptionHtml": "<p>All offers of employment should be conditional until satisfactory completion of the mandatory pre-employment checks. Schools must ensure that their procedures for pre-employment checks are aligned with the safer recruitment section of the most recent version of ‘Keeping children safe in education’.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:42:41.074+00:00",
                                                    "originKey": "49d81c24-ccc7-4dd0-b4da-2a4087ec4bf2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "43d22e4f-b542-46c0-88af-32ec9f013c41",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7979bd1b-3497-4501-95b1-2087442e9bbe",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdf0502b-05aa-4149-bbf6-c04507a2dcff",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ddb15732-3ca6-401e-8ce3-d42feb7eb0b7",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5a507f06-26a5-4c47-adf5-c7dfd57340d7",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8f553dca-bf6a-4425-ad2b-4b3ba149a671",
                                                            "requirementId": "d593d514-4aa2-4e2f-bd69-682a2881be7a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bdb5643f-8e38-4db1-be80-510b093752ad",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 1573,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                    "name": "Does the school have procedures in place to ensure the age and needs of pupils being searched or screened are considered?",
                                                    "descriptionHtml": "<p>When exercising their powers to search and screen pupils, schools must consider the age and needs of pupils being searched or screened. This includes the individual needs or learning difficulties of pupils with SEND and making reasonable adjustments that may be required where a pupil has a disability.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:51:17.272+00:00",
                                                    "originKey": "b5c1e40e-29bb-4b26-8bff-110aa7bd9eb1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Searching, screening and confiscation</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "33ea7194-5399-4308-a395-33b6e6d185ca",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "72318088-6381-4991-a1e8-00b8497d728e",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4d8df6e-7bd0-4924-9007-38a6049ddc79",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c0d354ca-7688-4ff1-9294-01ea486f99d2",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "77303741-d6e2-46c9-a3e7-40f73eb4d40d",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "786011b8-9b96-415e-b40e-4e172f5995fb",
                                                            "requirementId": "f7491fd0-e390-46a6-bae1-4d0abf42d368",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9facfa19-6f69-4eb6-8917-c4428221070c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1576,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                    "name": "Does the school maintain an up-to-date and compliant single central record (SCR)?",
                                                    "descriptionHtml": "<p>Schools must maintain a single central record of pre-appointment checks, more commonly known as the single central record or SCR.&nbsp;</p><p>&nbsp;</p><p>MATs must maintain an SCR detailing checks carried out in each academy within the MAT. Whilst there is no requirement for the MAT to maintain an individual record for each academy individually, the information should be recorded in such a way that allows for details for each individual academy to be provided separately, and without delay, to those entitled to inspect that information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:57:36.346+00:00",
                                                    "originKey": "9e42f7b6-6ffc-475f-bc10-bde7944628d6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 11,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3743717a-58f3-431b-bac6-b4f4c6d18ff8",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "60e2891d-b68c-466d-bc53-8a313b6f1453",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7af5e45a-f3f8-480e-8878-ac190f165355",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec971787-6d97-4c0e-b011-af69234949ee",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "01593e64-305f-4b34-96b9-875dea9486aa",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3aa9a2b2-e56b-40cf-902f-24bad9147f18",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "43c3f9e3-5045-4d77-bebe-cb46e6382d55",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1579,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                    "name": "Does the school maintain an up-to-date and compliant single central record (SCR)?",
                                                    "descriptionHtml": "<p>Schools must maintain a single central record of pre-appointment checks, more commonly known as the single central record or SCR.&nbsp;</p><p>&nbsp;</p><p>MATs must maintain an SCR detailing checks carried out in each academy within the MAT. Whilst there is no requirement for the MAT to maintain an individual record for each academy individually, the information should be recorded in such a way that allows for details for each individual academy to be provided separately, and without delay, to those entitled to inspect that information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:57:36.346+00:00",
                                                    "originKey": "9e42f7b6-6ffc-475f-bc10-bde7944628d6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 11,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3743717a-58f3-431b-bac6-b4f4c6d18ff8",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "60e2891d-b68c-466d-bc53-8a313b6f1453",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7af5e45a-f3f8-480e-8878-ac190f165355",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec971787-6d97-4c0e-b011-af69234949ee",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "01593e64-305f-4b34-96b9-875dea9486aa",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3aa9a2b2-e56b-40cf-902f-24bad9147f18",
                                                            "requirementId": "18bf5ea8-6a51-47a6-b54d-6e78358036e3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f6eb94ed-6358-4ed8-82bb-292bd8154751",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1582,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                    "name": "Are procedures in place to make sure the school meets its obligations under the Prevent duty, including reporting pupils at risk of radicalisation?",
                                                    "descriptionHtml": "<p>Schools are subject to a duty under section 26 of the Counter-Terrorism and Security Act 2015 to have due regard to the need to prevent people from being drawn into terrorism. This is known as the Prevent duty. Procedures must be in place in your school to make referrals for pupils who are identified as being at risk of radicalisation.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:33:00.442+00:00",
                                                    "originKey": "e4d40d2f-d0ea-4ff0-bb0f-32e7abe2ce0d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "070bb1ef-c683-4134-91f0-e575bf9d5451",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "430364f2-4e74-4e6b-b9b1-7d9316d66d29",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "765cff6d-023a-40e3-bef0-16a4b301e207",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aa55b8b1-f5b1-42dc-a488-6bb0d3dcd2ed",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "225d6c6b-953b-4dbe-962d-3963a816c2c1",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "45d9cd8c-fae1-4b62-b430-e790865421d6",
                                                            "requirementId": "201049d7-2c3d-4482-a58b-ba2330f1c596",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c685a390-7578-477f-9453-056d05aeace2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1583,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "95c3151c-9542-4d16-992a-c903df820aee",
                                                    "name": "Does the school have safeguarding procedures in place to make a referral to the DBS?",
                                                    "descriptionHtml": "<p>There must be procedures in place to make a referral to the DBS if a person in regulated activity has been dismissed or removed from their post due to safeguarding concerns, or would have been had they not resigned. Failure to comply with this legal duty when the criteria are met is considered a criminal offence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:52:38.743+00:00",
                                                    "originKey": "6144be13-33f5-4c43-849f-3dd4058ff9b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21adb6a2-e144-44a8-b434-597899afdba9",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7883a0c0-fdf7-40b2-a92f-cc0709c2a0b5",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b18c09fd-89b9-40b0-afa4-4ea0699f5ebc",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7285188-23a5-4747-b1c0-5ecbb89c4b18",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "12daa589-8c38-41a3-a83d-a9f51723d99d",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "22369885-8a1b-4585-befa-b6d633c543ec",
                                                            "requirementId": "95c3151c-9542-4d16-992a-c903df820aee",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "15ce9d76-6a9a-4dff-a109-a4f4c18abdbb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1592,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                    "name": "Has the annual report and accounts been produced, audited and submitted?",
                                                    "descriptionHtml": "<p>Trusts must maintain adequate accounting records and prepare an annual report and accounts in line with the Charity Commission’s Statement of Recommended Practice (SORP) and ESFA’s Accounts Direction. These accounts must be audited. The funding agreement will describe when the accounting period ends; typically it is 31 August. The audited report and accounts must also be submitted to ESFA by 31 December, published on the trust’s website by 31 January, and filed with Companies House (usually by 31 May).</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:26:01.372+00:00",
                                                    "originKey": "c4306f1d-fc6c-48c4-be65-96f055f9516e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by 31 December each year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "195a03fa-5b52-4724-8fa6-3e2ec97ee093",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "446f087a-ea0d-4f31-bea0-b0cd4e5402f1",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e03d59c-bb50-408c-aee1-cffc93e93604",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3c5eac3-3fd2-4f93-ab7f-cf0c5aa6a73b",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a8819cc7-3990-43b4-975e-d541cef0fabe",
                                                            "requirementId": "08e49166-a316-4d9b-9251-611e29bd6303",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "76738122-95c0-4737-b55a-57dc52918e07",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1593,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                    "name": "Is there a statement on regularity, propriety and compliance as part of the annual accounts?",
                                                    "descriptionHtml": "<p>The ATH requires the trust's accounting officer to complete and sign a statement on regularity, propriety and compliance each year and submit this to the ESFA with the audited accounts.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:29:09.193+00:00",
                                                    "originKey": "4e2b2017-cee2-4b49-9870-bfbe213ab2f0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually, by 31 December each year.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4ba8a7e0-4c96-4a59-a9af-b109e5f18dc3",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "51b3778f-227f-448b-8d38-d19a36dfecd5",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c54e5ad0-0ca8-467d-90ca-f3f324b34b0d",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff100e6e-7b7f-4be2-8ec1-10624308aadc",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6a69fab3-acb7-434a-bab7-ca3356c5431a",
                                                            "requirementId": "9afc9ccc-70c4-4237-a82b-b7c1b3090e3e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "393a1d6b-b265-4ef6-b173-75333772590b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1594,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                    "name": "Has the school submitted a well-informed and balanced three-year budget forecast to the LA between 1 May and 30 June?",
                                                    "descriptionHtml": "<p>Schools must submit a three-year budget forecast each year, at a date determined by the LA between 1 May and 30 June. A balanced budget is one that will lead neither to an excessive surplus at the end of the year nor to a deficit. A budget is considered well-informed when it takes account of the best available information on all variables, such as pupil numbers and staffing changes. The LA has a duty to inform schools of the purposes for which they intend to use the forecast.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:19:23.355+00:00",
                                                    "originKey": "bf2b2f19-61c7-4942-a172-1427679fcc19",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Schemes for financing local authority maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ec030aa-4390-4a42-8798-fa23fbf65fb9",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c59527e5-7f02-4ff7-a4f9-49bb06206e04",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d24a5718-ba15-49ff-a624-26862c83d850",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb4ab40b-d261-4e80-91a6-d1a74384f9fb",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9bf02d14-318f-4d99-b2af-57b325c47b70",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8465099c-f042-4526-bc71-0b2b6bf9d06c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1595,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "01441065-2737-4f52-96ae-7443f430cee6",
                                                    "name": "Are there adequate arrangements in place to manage related party transactions (RPTs), including the recording and reporting of these to the LA?",
                                                    "descriptionHtml": "<p>Schools must append a list of RPTs as part of their response in the Schools Financial Value Standard (SFVS), including the number of RPTs and the value for each. Contracts and agreements that are live in the year that the SFVS is being completed for need to be reported. These should continue to be reported until the year in which the contract ceases, at which point they can be removed from the list. Historic related party transactions are not to be reported.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:21:13.001+00:00",
                                                    "originKey": "f30228ac-c18a-4005-84f3-8f2c8e111dee",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Financial transparency of local authority maintained schools and academy trusts</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5072c075-b251-48e3-a610-b5cdbc8091bb",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5b18fdc7-dffa-44e0-9488-9568cff2cdc1",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dfb03fcf-80d9-424b-9994-bd1942d34d9d",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e383a4b3-af7c-46e7-b192-6b24bfefabd7",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "22f2d9df-38c9-455b-9b17-13e80098f129",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2b4ce778-06cd-40d1-acc2-9f4d70608a0c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1596,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                    "name": "Has the trust submitted to the ESFA a budget forecast return, approved by trustees before submission, by the July deadline?",
                                                    "descriptionHtml": "<p>As of 2022, the ESFA only requires a single budget forecast return (BFR), and trusts are no longer required to submit a separate budget forecast return outturn (BFRO) or budget forecast return three year (BFR3Y). The outturn information is now permanently included within the BFR.&nbsp;Deadlines are published annually, but are usually in July -&nbsp;&nbsp;the deadline this year is 26 July 2022. The BFR must be approved by trustees before submission.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:22:02.021+00:00",
                                                    "originKey": "6a820843-5d89-4195-b9d9-e112ae117a3b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Academies budget forecast return</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1bb58b7b-8278-4657-93ea-0729f040381f",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cfffd596-aca9-43cd-a4a3-021ea44147fd",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec456243-0318-4032-97f9-10cb32c299c1",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fc6b9a04-3997-49a4-82a6-d5d59ca5f2b6",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "930618df-b986-40d8-bc7d-9adc139990f2",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "676bdc04-db81-460b-a78e-1a1887477fc0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1597,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                    "name": "Are management accounts shared with the chair of trustees every month, with other trustees six times a year, and considered at board meetings?",
                                                    "descriptionHtml": "<p>As part of the trust's responsibility to monitor the budget, management accounts must be shared with the chair of trustees every month irrespective of the trust's size, and with other trustees six times a year whether or not they meet in each of those months. The board must consider these accounts when it does meet, and minute it.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:23:03.596+00:00",
                                                    "originKey": "a7c6d798-e339-4364-95ae-3b103ba16725",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "89eacffd-fdc2-4de6-a526-073189238aab",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d274633b-aefb-43ba-8510-a2208e714468",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0b96451-be26-4055-887f-3f2a7e2a88ff",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fa80b1ef-3a15-45bb-b2e6-cb1980c9d4e5",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5f4324fc-cd4e-42c2-8696-d35dc132752b",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3aaf37b2-8412-4da2-b678-062ca5efa68b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1598,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                    "name": "Does the trust have a Reserves and Investment Policy?",
                                                    "descriptionHtml": "<p>The ATH requires trust boards to set a policy for holding reserves and explain it in its annual report, as part of its management of the trust's funds. The Academies Accounts Direction provides further guidance on how trusts must report on reserves. Trusts must also have an investment policy to manage and track its financial exposure, and ensure value for money.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:23:46.67+00:00",
                                                    "originKey": "7467e980-daf2-4451-ae1c-199fdf67b87b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "02ec2c57-996a-4917-8814-dca353e787eb",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37929eb3-3cd4-4063-a09a-b2733a57790f",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "583481af-ae5e-4fb4-a0ba-73ab104ae1d7",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e5e9a5f9-a2b8-4537-8fda-fe1dc8ae0879",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b690e754-be36-46c2-ab88-ff29123a6134",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9acf8bc6-0158-489b-9317-cf7fbc71850c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1599,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                    "name": "If you are part of a MAT, is an appeals mechanism in place for constituent academies over funding and allocations?",
                                                    "descriptionHtml": "<p>When managing the General Annual Grant (GAG), the trust must consider the funding needs and allocations of each constitutent academy and must have an appeals mechanism. If a consistituent academy's headteacher feels their academy has been unfairly treated, they should appeal to the trust, and can take their grievance to the Secretary of State via the ESFA if unresolved.</p><p><br></p><p><strong>Academies not part of a MAT should mark this requirement as 'Does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:24:35.867+00:00",
                                                    "originKey": "d3efe120-e5eb-49a4-8c5a-de4dffe2ae71",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a6aca946-3743-4a77-994f-ce56acd47c3c",
                                                            "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ac951083-e86f-4d13-bea4-f9401dd47903",
                                                            "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d00888a9-ec89-4b9d-b02a-c59fd3b81da3",
                                                            "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d542ed0f-95a4-4658-8b6a-9a01b15bcb4a",
                                                            "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d3a09977-5d8b-46cc-918e-8d4ea5a53bf0",
                                                            "requirementId": "ac06f4ad-0019-40ee-bcce-13795a7f5c50",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ce133e5d-e7f8-4d39-baed-dad1f8cb3863",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1601,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                    "name": "Is the trust’s auditor contract and regularity engagement in writing?",
                                                    "descriptionHtml": "<p>The auditor contract and regularity engagement must be in writing and must not cover other services. If additional services are purchased, a separate letter on engagement specifying the work and the fee must be obtained.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:27:31.792+00:00",
                                                    "originKey": "64786541-e825-440d-8c12-765137b2d5fc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06542a1d-e218-4f2a-a5c9-4969298233c4",
                                                            "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45acf094-f923-4467-81fb-64d73f0955fd",
                                                            "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9952c509-a5c6-4f30-b996-d43a796d2c6b",
                                                            "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9f1485e3-3fad-47d9-b09f-05ce95d667e3",
                                                            "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a2177190-0ce8-41b3-86d2-20d59845e0fd",
                                                            "requirementId": "5971c9f9-127e-4fe6-9da9-a6a82518faa4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8b33e69-0c44-4c5a-b486-818a2c5580db",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1602,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                    "name": "If your school or trust meets the reporting threshold, has the gender pay gap been reported via the government’s reporting website?",
                                                    "descriptionHtml": "<p>Any employer with 250 or more employees must comply with regulations on gender pay gap reporting. This includes reporting their gender pay gap information to the government online, via the Gender pay gap service. This information must also be published on the school website.</p><p><br></p><p><strong>Schools under the reporting threshold should mark this requirement as 'Does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:33:28.054+00:00",
                                                    "originKey": "d3741c96-3e16-4ee4-b666-9ad31d5ecb55",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Report your gender pay gap data</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "045a964c-ea08-4e50-b682-eec7df737bf9",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0dfa5c9e-f5b9-4906-90dc-28b050c74358",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37692694-7c98-44f0-899c-33d0b35cc147",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1e50ca0-3fbe-40ce-9c2c-cb165bd2e4f4",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4d17d90a-3a5d-48a6-b27b-dd9e7906fd30",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fd07ced8-45ae-43fc-875b-e564e130c668",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0a048ad9-dbea-45e0-ac3f-495ecdbae259",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1605,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                    "name": "Does the trust have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>Academy trusts are required to have a whistleblowing procedure, to protect staff who report individuals they believe are doing something wrong or illegal.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:30:42.001+00:00",
                                                    "originKey": "33eff5ce-8e42-49d4-9fc4-167ab87e2935",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "94fa84b1-1b69-4638-b97a-42e3a43c09a7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "161e8f34-fc50-4104-8940-09d6bdf3288e",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4632803e-5432-414c-85ab-570da1707cb5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b222a7f1-a14a-45a9-9e0e-2071b40a0268",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8611e76-cd1f-4907-9899-5069a48058f5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bd39f2c-bad2-4081-b32b-9c5fe49d03f1",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cf73790f-ce30-459a-af01-a8b73c160fb3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1607,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                    "name": "Does the trust have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>Academy trusts are required to have a whistleblowing procedure, to protect staff who report individuals they believe are doing something wrong or illegal.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T10:30:42.001+00:00",
                                                    "originKey": "33eff5ce-8e42-49d4-9fc4-167ab87e2935",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "94fa84b1-1b69-4638-b97a-42e3a43c09a7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "161e8f34-fc50-4104-8940-09d6bdf3288e",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4632803e-5432-414c-85ab-570da1707cb5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b222a7f1-a14a-45a9-9e0e-2071b40a0268",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8611e76-cd1f-4907-9899-5069a48058f5",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bd39f2c-bad2-4081-b32b-9c5fe49d03f1",
                                                            "requirementId": "4ffc775c-35a0-4ad5-8bd1-7df545a18e1e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "17fcda0d-bfb4-49eb-b1b1-47861b97251e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1608,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                    "name": "Does the school have a Charging and Remissions Policy?",
                                                    "descriptionHtml": "<p>The DfE requires schools to have a policy on charging and remissions for school activities, and recommends that the policy is reviewed annually. The policy should address areas such as charging for education, optional extras, exam fees, voluntary contributions, refunds and remissions.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T10:34:12.258+00:00",
                                                    "originKey": "5441af32-cf40-4d35-93b6-02134828a844",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p><p><br></p><p>DfE 'Charging for school activities'</p>",
                                                    "policyManagerId": 2,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "228db389-420f-4456-9344-cf25d351eea8",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "56b2d899-9d26-44f3-bd63-f7a5e7318f88",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e5913036-3bdc-4c70-ba60-2a465cd68dd6",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff48c1d0-88fb-4be3-9247-9cd315892b16",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "14b4c192-119d-4ee4-8585-56ee2a953b20",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "626ad893-3c2a-4b1e-afd6-97c0c52d32d6",
                                                            "requirementId": "69f37419-9172-4d97-b81c-a505d504e55e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a9af8d54-0fcf-4525-8a20-9e6b9ee60d75",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1609,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                    "name": "Does the school have a Teachers’ Pay Policy?",
                                                    "descriptionHtml": "<p>Maintained schools must have a Teachers' Pay Policy in place that sets out the basis on which the school determines teachers' pay, with clear performance criteria and the date by which it will determine annual pay reviews. Procedures also need to be established for addressing teachers' grievances in relation to their pay in line with the ACAS Code of Practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:18:29.728+00:00",
                                                    "originKey": "26e7e344-62ce-4f00-805e-42e17bfb0406",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 6,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "213241a4-bfbe-49e8-8efd-3626db427166",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "27d23a4a-51d7-453b-acf1-6d3fa6a1e00a",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aad1d221-26f6-4867-a25b-7d1240fd2bc9",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9c633de-53bf-4ef2-8aa2-2ec4eecb5c20",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b3860ba6-0841-46b6-a6f5-3c0cb29713bf",
                                                            "requirementId": "e9670a19-4516-4f6a-bf8f-a90a01a289f5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "16301aef-d8ad-4f60-95fc-dd7026b5c313",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1610,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                    "name": "Does the school have a Teachers’ Pay Policy?",
                                                    "descriptionHtml": "<p>All academies should have a pay policy that sets out how decisions are taken on pay matters including pay assessments, pay progression, discretionary payments, and appeals.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:20:00.977+00:00",
                                                    "originKey": "ca1f80e3-299c-4964-b259-9d7d17ed71c3",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE ('Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 6,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "565e00da-c81a-4c90-9ae5-06f89e0ebc89",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "69fced91-14dd-4df6-8d75-993bcfc74441",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "74dd146e-faf2-44fc-8bd8-a7753e6c3314",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7a0e7aea-87b5-41ca-80dc-401de4fb34b5",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f9b64d59-773e-4e33-90da-b807fa71a119",
                                                            "requirementId": "7104dcb1-c190-426c-baf3-e6537208efbe",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c0c149ca-d746-43a6-91d0-9c537dd58650",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1611,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                    "name": "Does the governing or trust board maintain a register of business interests of the headteacher and governors or trustees?",
                                                    "descriptionHtml": "<p>Schools and trusts have a statutory duty to declare actual or potential conflicts of interest. Governing boards are required to maintain a register which lists any conflicts of interest.</p><p><br></p><p>You may wish to link to or attach your school's register of interests. This register of interests is a live document and should be updated whenever necessary, and subject to an annual review at a minimum.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:39:46.29+00:00",
                                                    "originKey": "ff8828b1-43a0-4805-9288-b5b2ef5e9778",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts',</p>",
                                                    "policyManagerId": 18,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "ac40aa8c-df2e-4461-99ad-bc7d64d184c2",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "594c4921-383e-4891-8e2e-8079810457d2",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e9fe1e59-c088-4eca-a0cb-7eb76eaab9d0",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea1afd67-b52c-41c0-86a5-c84eebc215bc",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed715122-558e-437c-9e09-de921a1aecae",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ad8db43-a022-4e3b-8ae4-596cfc5a7275",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b8e60758-9982-4985-8cb0-5c1c57740c87",
                                                            "requirementId": "f0150b9b-fbda-407f-9d52-99da89c188ff",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9f5b4fbe-2096-4579-8537-6c85ba8a94e1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1612,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                    "name": "If your school has a revenue deficit above 5 percent, has the school submitted a recovery plan to the LA?",
                                                    "descriptionHtml": "<p>Schools must submit a recovery plan to the LA when their revenue deficit rises above 5 percent at 31 March of any year. LAs have the freedom to set a lower threshold than 5 percent, so schools should check with their LA if this is the case.</p><p><br></p><p><strong>Schools that do not have a revenue deficit above 5 percent, or lower if set by their LA, should mark this requirement as 'Does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:50:00.196+00:00",
                                                    "originKey": "d605d154-5cd8-4bda-8740-8b3ea25eb7f1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3fbb285e-77e8-4a34-9574-b0a5741823f3",
                                                            "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "793f5437-cdde-4beb-8fb5-4dd149727c2e",
                                                            "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7ad43b92-e75a-480b-8b5a-501179765ca7",
                                                            "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ac3509c9-f7dc-4d54-a073-03fd3d60e22d",
                                                            "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "20961463-8a16-4bfa-8d6f-bad5834fd481",
                                                            "requirementId": "777adae4-ea6d-4b0d-8fa8-ab5b330f427e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d5bd542-68fd-45db-86d7-25cd0405b036",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1613,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                    "name": "Does the school have procedures to achieve effective management of resources and value for money?",
                                                    "descriptionHtml": "<p>Schools are required to manage their resources to maximise pupil outcomes, and must seek to achieve effective management of resources and value for money to optimise the use of their resources and to invest in teaching and learning, in line with the LA's purchasing, tendering and contracting requirements.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:51:08.873+00:00",
                                                    "originKey": "324dcb42-10c0-4a6e-beb1-7b99d20eb75a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5df9e0b3-b14d-49a2-869d-17b6e8f03f97",
                                                            "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "727a02d6-ea67-4280-ae90-8e9bfacdfe9d",
                                                            "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8eb3638b-d80d-4cb1-bdba-51c580b5911a",
                                                            "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb699972-7210-41b2-b1e3-8aa1d0091de5",
                                                            "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1837733e-301c-4531-9e7a-bc2316bc317c",
                                                            "requirementId": "384d3791-68ff-43fc-84cd-ff237dd5e05f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d9670a99-f424-448a-95e7-f152045dbc6c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1614,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                    "name": "Has the school published links to the 'school financial benchmarking' on its website?",
                                                    "descriptionHtml": "<p>The DfE requires maintained schools to publish a link from their website to the 'schools financial benchmarking' page, where the Consistent Financial Reporting (CFR) statement is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:52:00.956+00:00",
                                                    "originKey": "4b31ad2f-a6f5-4e02-8ead-f04d898d588f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/902003/Financial_transparency_consultation_response_July_2020.pdf\" rel=\"noopener noreferrer\" target=\"_blank\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/902003/Financial_transparency_consultation_response_July_2020.pdf</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4dcdc961-ad69-40ed-8406-556c98c4bf7a",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5c97a234-72ba-4125-8b08-f9aa76d32fee",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6490ecac-54da-454f-92c3-bf67502e9b1a",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "865cb041-c9ec-452c-b46c-bdd4b29f876d",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b137e8e1-180f-46b3-acd8-e7b186a383c9",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4d11bc99-7f1e-42a1-beae-ed7ce5bd89be",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1615,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                    "name": "Has the governing board ensured all voluntary or private funds are subject to an annual audit and are managed separately from LA funds?",
                                                    "descriptionHtml": "<p>Schools are required to provide audit certificates for voluntary and private funds held, and of the accounts of any trading organisations controlled by the school. Voluntary or private funds must be managed separately from LA funds, be subject to an annual audit and audited statements submitted annually to the LA.</p><p><br></p><p>Schools may wish to upload a copy of any audit certificates as evidence of meeting this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:52:58.859+00:00",
                                                    "originKey": "2ae0c2b0-bde8-4c55-959c-56b253e8cf00",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19761d13-017a-40dc-9517-9438ddd778a7",
                                                            "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2200112e-b343-4695-b79c-e4e3a23082b0",
                                                            "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9cc36a1a-7846-4bdf-ad23-64462a9e94fa",
                                                            "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aad79c02-d3ab-44ea-abd5-bf00e8165c16",
                                                            "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "edf91fbe-f8f2-4f82-9757-d70f445cb497",
                                                            "requirementId": "162162b8-801a-47a1-9357-2e2611b0be56",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a04d0b79-b817-4e3a-8fee-7a933a18e58c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1616,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                    "name": "Does the school only hold bank accounts with banks permitted by the LA and in line with the LA's terms?",
                                                    "descriptionHtml": "<p>The LA's scheme for financing schools will set out the banks which schools are allowed to hold accounts with, and the terms for this. Maintained schools should ensure they adhere to their LA's requirements.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:53:43.08+00:00",
                                                    "originKey": "dfa05c6e-d34e-4736-9b47-c47c1e93bd19",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0032d18d-68d9-4629-b8ba-3f0eed18b404",
                                                            "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "42153bc3-b572-43ae-9918-80fa6b8c8438",
                                                            "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b466587-8d77-4f6c-99cf-ef303308af09",
                                                            "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "73846eb4-fc8f-4cca-8d60-6c6db4660674",
                                                            "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9496e105-b17a-48f6-92aa-822c4efb3fc1",
                                                            "requirementId": "3ac2bd5d-3ab7-47bc-952e-20aa1dce7aca",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "848f4a11-dd1c-4710-819f-6f8f5fe81cc7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1618,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "97371084-46fc-47de-8fa5-0840bd769436",
                                                    "name": "Has the school nominated three to five people to be authorised signatories for cash and banking arrangements?",
                                                    "descriptionHtml": "<p>Schools must nominate three to five people to be authorised signatories for cash and banking arrangements – two of these must be the headteacher and deputy headteacher, and all signatories must be an employee of the school or LA.&nbsp;</p><p><br></p><p>Banking arrangements, including the name of signatories, should be recorded in the school's Finance Policy - schools may wish to upload a copy of this as evidence of meeting the requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:55:55.83+00:00",
                                                    "originKey": "8a4ac0a5-4b33-445b-954d-1158d363b5de",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford LA - Guide to Financial Procedures</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "09ddc554-7456-41f3-9a5c-3cad11cfc899",
                                                            "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3945dd6d-6406-42c7-9086-d9bd98a69f00",
                                                            "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "abcde95d-4c21-42b7-bf8e-b1776f19026e",
                                                            "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fe2e6f36-f60f-4fe1-9219-afc2051db8a2",
                                                            "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a942dda9-2894-4d65-bfc5-1d2ed0d6ed73",
                                                            "requirementId": "97371084-46fc-47de-8fa5-0840bd769436",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "21ef39a2-d7f7-409c-bccb-5118ef175f48",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1619,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                    "name": "Has the school completed the Schools Financial Value Standard and submitted it to the LA?",
                                                    "descriptionHtml": "<p>Maintained schools must complete the Schools Financial Value Standard (SFVS) and submit it to their LA every year. The SFVS is typically completed by the SBM but it must be agreed by the full governing board and signed by the chair, and submitted before the end of the financial year; schools should check if their LA has set a specific deadline.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:56:32.512+00:00",
                                                    "originKey": "013364ff-699b-48f3-9091-afb188338ae8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Schools financial value standard (SFVS) and assurance statement</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2b19d6e3-ec4b-43b0-b1c5-4a1e813cebf9",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3706183e-4361-4165-b1e2-a5841ed0e002",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "74da6de4-8583-444f-9826-7e90d574c73c",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eeee2ab2-5b8f-4adc-90f0-bbc1cc886491",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2cf7cf23-479a-4f22-8ca8-ababbb90ab1a",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "87a762b1-15b7-4662-8426-b2c1043097a2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1620,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                    "name": "Has the trust completed the School Resource Management Self-assessment Tool checklist?",
                                                    "descriptionHtml": "<p>All academy trusts that have an open academy at a specified date have to complete the&nbsp;School Resource Management Self-assessment Tool (SRMSAT) checklist each year. The checklist is to assure the government that resources are being managed effectively, and must be submitted to the ESFA by the specified deadline which is usually in March or April.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:01:29.364+00:00",
                                                    "originKey": "5dde5f6d-1d9a-4a78-8b15-870a273cfddc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://www.gov.uk/guidance/school-resource-management-self-assessment-checklist\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/guidance/school-resource-management-self-assessment-checklist</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08e277ec-d333-40b5-9a17-b0ae1c65d0c6",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1ec3d5c8-c54a-4be9-a78a-c16c1ec3dbea",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e3697c7-8ed8-420a-82b4-f3b6e7ccd194",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3b97538-d92d-41d8-993b-f1a746576e9e",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e3a7ce86-984b-4ac2-9c8b-d22de8a6267b",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b6cb3ab6-5bfd-4bdc-93c4-5d9fdd6c7702",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1621,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                    "name": "Has the school published up-to-date governance information on its website in a readily accessible form?",
                                                    "descriptionHtml": "<p>All schools must publish on their websites up-to-date details of the overall governance arrangements they have put in place, in a readily accessible form, i.e. it does not require downloading or opening a separate document. This includes setting out the structure and remit of the board and any committees (including any</p><p>local governing boards s in a MAT), as well as the full names of the chair of each.</p><p><br></p><p>Schools may wish to upload a screenshot or attach a link to the relevant page on their website as evidence of meeting this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:02:13.325+00:00",
                                                    "originKey": "3f1a8464-cece-4894-999c-fdfae7b88c88",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance handbook section 5.5</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "40bca818-d3a7-44b1-bb5a-1a75512654d7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "12835f9b-a5df-45d9-a59f-67727c54844c",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "453c51cc-3964-4f83-8b66-74cd574f33d4",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5bc3ddce-0607-4b42-bf7c-20bbe852dfa4",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c5d3151-7874-49a8-8555-81abb1d67031",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "218dc58e-fe7f-4442-841d-3447187b99d0",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7f618e93-c187-463d-823d-41fd1ca63407",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9045aee9-7f32-48eb-98fa-36722449c264",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1622,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                    "name": "Does the school maintain an up-to-date inventory of assets in line with the LA's requirements?",
                                                    "descriptionHtml": "<p>LAs typically require schools to maintain and keep an up-to-date inventory of moveable non-capital assets, in a form determined by the LA and which sets out the basic authorisation procedures for the disposal of assets.</p><p> </p><p>Schools may wish to upload their Fixed Asset Register as evidence of meeting this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:03:02.746+00:00",
                                                    "originKey": "628c330d-7b27-483a-8cd6-e0a8618903a4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1ff69353-049b-479c-8d39-5a478dab8468",
                                                            "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "50fa800d-6e0b-413a-8459-24455af3d0d6",
                                                            "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9cdebad4-bde8-48f2-bafc-a369dc5a97a0",
                                                            "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3d53de2-6e29-4f67-8371-8051cbe7bdfe",
                                                            "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2c02b496-96ab-4a60-9b4e-2f65bdea8f8e",
                                                            "requirementId": "4f41a573-9854-4c84-a610-71571feeb04a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "37abe23f-e8ad-47af-8d03-d3136bfb9b05",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1623,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                    "name": "Has the board appointed an audit and risk committee?",
                                                    "descriptionHtml": "<p>Trusts must establish an audit and risk committee, appointed by the board. Trusts with an annual income over £50 million must have a dedicated audit and risk committee. Other trusts must either have a dedicated audit and risk committee or can combine it with another committee, e.g. finance committee.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:03:46.123+00:00",
                                                    "originKey": "84b387d7-c031-4487-9649-710bd036b359",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "24ed1217-d611-45f5-82bd-e11dec617e9f",
                                                            "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3d000e29-6759-4a60-badb-5d4e611cf6f5",
                                                            "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b8da4ef6-072d-45cd-b31a-a3aa0a1fda70",
                                                            "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f10f0c51-a44f-4808-a6c2-202a2fdaa42e",
                                                            "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "99cd22ab-1857-4a08-93cd-83ece74bf59f",
                                                            "requirementId": "dfddf9d4-2b95-45d2-b85f-f676e391f399",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6e2c69e2-ae5d-41dc-a5a8-a10a91be5f9f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1625,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                    "name": "Has the board approved a written scheme of delegation of financial powers that maintains robust internal controls?",
                                                    "descriptionHtml": "<p>Trust boards must approve a written scheme of delegation (SoD) of financial powers that maintains robust internal controls. The SoD should be reviewed annually, and immediately when there has been a change in trust management or organisational structure. Financial scrutiny and oversight should be delegated to a finance committee.</p><p><br></p><p>Trusts may wish to upload a copy of their SoD as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:05:39.989+00:00",
                                                    "originKey": "57e9323b-bfda-4d89-8a10-cd25c894d34d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "130b3234-7af7-4479-8439-d0db0ce8bbc8",
                                                            "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "21b2ad2f-22cc-4f85-86fd-02eb79dd53c7",
                                                            "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "41437ea8-7c57-4e2b-8ff6-9640aeedc448",
                                                            "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfa2ceb6-ff2b-4d02-8a7d-71842768ea10",
                                                            "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "50ad0461-d504-4aca-be06-f85c16457400",
                                                            "requirementId": "eacccfa6-8f5a-4acb-b3ed-f91e614c6399",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1a158cbf-2c9e-469f-9f69-e9c0b1596f86",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1626,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                    "name": "Are procedures in place to secure ESFA approval in advance for certain financial transactions where required?",
                                                    "descriptionHtml": "<p>Certain financial transactions require ESFA approval in advance. This includes:</p><ul><li>Special staff severance payments of £100,000 or more</li><li>Non-statutory/non-contractual payments of £50,000 or more, or where the employee earns over £150,000</li><li>Transactions which are novel, contentious and/or repercussive, ex gratia payments.</li></ul><p><br></p><p>There is a special ESFA approval form for RPTs exceeding £20,000.</p><p><br></p><p>As evidence, your trust could upload a copy of its Finance Policy that contains the appropriate procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:07:01.068+00:00",
                                                    "originKey": "8ea89cbc-aa78-41b2-b250-7d11c811cbd2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c4a0db1-a00b-415e-b209-f62998eedcd9",
                                                            "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "23a1c462-7586-4c24-b7c9-931fc1016217",
                                                            "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "851e1d7b-3f89-4066-b1d0-e7c2aa3cfe1b",
                                                            "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d86e5ea2-6ace-4acc-9a80-d993db6ea381",
                                                            "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "acfbcfc0-b27a-4bc0-8e31-9ab606358afe",
                                                            "requirementId": "b9e67a5e-cd33-4ff1-b43c-592cdd3a1b13",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f0e0b267-999b-42f5-8778-82674093f78d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1627,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                    "name": "Has the trust appointed an external auditor to give an opinion on the accuracy of the annual accounts?",
                                                    "descriptionHtml": "<p>The Companies Act 2006 requires trusts to appoint an external auditor to give an opinion on whether their annual accounts present a true and fair view of its financial performance and position. The appointment should be made by trust members, apart from in circumstances where the Companies Act 2006 permits trustees to do so.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:08:10.455+00:00",
                                                    "originKey": "4ca57de8-2764-4b1f-980d-76413652190b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "02bd823b-68b2-4b26-bff0-9b850011eef3",
                                                            "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6dd39140-6c6d-43f4-b273-e99d9f666e30",
                                                            "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "995b3114-a21b-412d-8659-b6824f1f9817",
                                                            "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d49b256e-b884-47bd-a961-107ebc9418f9",
                                                            "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ef21fd6b-6912-4568-a8e0-13e5455c9538",
                                                            "requirementId": "d7ffd882-f414-47a6-aa09-744781d6a860",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f4c64449-941b-4a84-937e-52c42cebc830",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1628,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                    "name": "Does the trust’s management of risks include contingency and business continuity planning?",
                                                    "descriptionHtml": "<p>The trust must manage risks to ensure its effective operation. Overall responsibility for risk management must be retained by the trust board, and the board's management of risks must include contingency and business continuity planning. The board should review these plans annually.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:08:56.998+00:00",
                                                    "originKey": "f9422fad-d5b8-4c2d-a321-37b844c41662",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0f5ebb5c-261b-431e-9c61-ed404e869997",
                                                            "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e1caed7-03bc-4b6d-97e4-7aa8404b35a4",
                                                            "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f3d028f8-a3e2-4445-9d46-f72d9b37151f",
                                                            "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fd9855b7-46d8-42f3-8964-bd2dd17930e7",
                                                            "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "61fb2f8e-f3b2-454c-bc18-3641eb4df1c2",
                                                            "requirementId": "23915491-d1f2-4e5b-b2e4-402ec3c5a03e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2e82ba3b-1437-4b16-8a37-81afd8a2d3e1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1629,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                    "name": "Does the trust maintain a risk register?",
                                                    "descriptionHtml": "<p>Trusts must manage risks to ensure their effective operation and must maintain a risk register. Overall responsibility for risk management, including ultimate oversight of the risk register, must be retained by the trust board, drawing on advice provided to it by the audit and risk committee. As risks are identified, they should be logged on the register and the associated control measures documented. A risk register should be a ‘live document’ and should be an ongoing process, but the board must review it at least annually.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:09:53.058+00:00",
                                                    "originKey": "54932ab5-985a-443a-b3d5-a9d451bae7a7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 77,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "264a43fd-c6a4-40d5-92bb-16bc0fa3e7d0",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7845c5fd-4e7c-4921-92f4-b135128db836",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c6af57d-9e9a-4592-b79f-25c60f5d3ee7",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4b44d82-8a9c-4166-9e13-c2d3022e9841",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "46ddf6ed-30c7-4b52-b565-446142028467",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "30227469-1e3b-46e5-9d62-7a8694e0c59c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1631,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                    "name": "Has a suitable person being appointed in writing as the trust’s accounting officer?",
                                                    "descriptionHtml": "<p>Trust boards must appoint a named individual as the trust's accounting officer. This should be the senior executive leader, and the individual must be a fit and suitable person for the role. The roles of senior executive leader and accounting officer must not rotate. The accounting officer should be a trust employee; prior ESFA approval must be obtained if it is proposing, in exceptional circumstances, to appoint an accounting officer who will not be an employee.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:11:24.532+00:00",
                                                    "originKey": "9421bd4f-c367-47de-880f-dff5f31023e8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath </p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "10e0de39-7c22-4fd2-a3ce-eb536e3b7e39",
                                                            "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7d6e606a-ab9e-4caa-8b51-92afc5325121",
                                                            "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9c0eab2b-b75e-43bc-ae42-f02af3708f4b",
                                                            "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6b427b8-925e-4c4b-a84e-c2eb5583cd77",
                                                            "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6dc17a3b-1a88-494d-aa58-0fe4a722980d",
                                                            "requirementId": "10be512a-e6b6-4a5b-8cdc-7fb3f3ea30e7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a4a63b1f-15dd-49ae-ba50-a134b986497a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1632,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                    "name": "Does the accounting officer take personal responsibility for assuring the board that there is compliance with the funding agreement and 'Academy Trust Handbook'?",
                                                    "descriptionHtml": "<p>The accounting officer must take personal responsibility (which must not be delegated) for assuring the board that there is compliance with the funding agreement and the ATH.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:23:48.048+00:00",
                                                    "originKey": "bd6ba752-706b-40ea-a2c9-e1d3d0cb0f54",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "641fc488-0ebf-41af-9bc7-90bc7438028f",
                                                            "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6d7e6dc2-2835-438f-a868-e261b8cb6988",
                                                            "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "da44bcf5-9aa0-43a1-a150-68ed865ba288",
                                                            "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dcf54f0c-2c7d-4a75-8616-449f986324b5",
                                                            "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2c7e795c-0421-48ef-8c7d-33245c600059",
                                                            "requirementId": "c0a40268-7f83-4714-8af7-313b6aaeb1be",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7ae99005-8710-4c15-aeb3-a5686c5d874f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1633,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                    "name": "Has the accounting officer completed and signed a statement on regularity, propriety, and compliance and submitted it to the ESFA with the audited accounts?",
                                                    "descriptionHtml": "<p>The accounting officer’s statement on regularity, propriety and compliance must be included in the academy trust’s annual accounts. This is a declaration by them that they have met their responsibilities to Parliament for the resources under their control during the year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:24:19.274+00:00",
                                                    "originKey": "6ff0cb87-84bb-4fc9-8c98-6db6d1ff0acb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "28463b94-29ae-4815-a249-1971d86feff4",
                                                            "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "83fe0369-2964-46c4-b84c-985de9f01edd",
                                                            "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c6b817c0-2959-4273-a7b1-f745e9231f3c",
                                                            "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e1fe6570-13d8-482a-888c-46bf211738ad",
                                                            "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "06f65aad-c3e8-4b97-9c0a-526a0939bc67",
                                                            "requirementId": "14258d94-241d-41d3-9805-dbb24ffad9ad",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "526e0dc7-1419-4736-a24d-30c74c218e28",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1634,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                    "name": "Has the accounting officer demonstrated how the trust has secured value for money via the governance statements in the audited accounts?",
                                                    "descriptionHtml": "<p>The accounting officer has a duty to ensure the trust achieves efficient and effective use of resources (value for money). They must demonstrate how the trust has secured VfM via the governance statement in the audited accounts.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:25:14.111+00:00",
                                                    "originKey": "6722a86e-1de0-4f5e-aff4-1ecdab44114b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "632b0d71-569d-48e0-ba4f-f9dda29bce66",
                                                            "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "95132c1c-3dc4-4ae9-bb5b-3efdad55d13d",
                                                            "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adca239c-d87d-444d-9f6e-65462e010184",
                                                            "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e9b959c3-5788-492c-bfdb-1fdbfa47eb07",
                                                            "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "39f0f4aa-2526-4f48-a364-0153681e095c",
                                                            "requirementId": "882c6f0d-bf81-417d-b395-f9063784acb5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b1d946d7-0238-4f75-9dbd-a069f17c9d0e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1635,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                    "name": "Has the board appointed a CFO who is the trust’s finance director or equivalent, and delegated to them responsibility for the trust’s detailed financial procedures? ",
                                                    "descriptionHtml": "<p>Trust boards must appoint a CFO who is (and whose job title may instead be) the trust’s finance director, business manager or equivalent, to whom responsibility for the trust’s detailed financial procedures is delegated. The CFO should play both a technical and leadership role. The CFO should be a trust employee; prior ESFA approval must be obtained if it is proposing, in exceptional circumstances, to appoint a CFO who will not be an employee.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:25:46.77+00:00",
                                                    "originKey": "1c487363-e327-4380-9b7f-b70351410275",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1edac30e-4d45-46c5-ba97-ec8e641c393f",
                                                            "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2419a849-59a3-4170-a7e7-5a2edcd9b523",
                                                            "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a791d873-2372-4d71-b918-e111c61df89d",
                                                            "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7b00a24-678c-4919-8ef6-0d522ae757e7",
                                                            "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a8962439-b8c5-432d-a091-5dea1777a8ff",
                                                            "requirementId": "0909ff94-435b-4fd1-a470-fd6715a52312",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fdfc9a5e-d248-41fd-872a-0c75f302e8f0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1636,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                    "name": "Are the CFO and their finance staff appropriately qualified and/or experienced?",
                                                    "descriptionHtml": "<p>The ATH places expectations on the levels of skill and experience for the CFO and their finance staff. They must be appropriately qualified and/or experienced. CFOs should also maintain continuing professional development and undertake relevant ongoing training.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:27:00.232+00:00",
                                                    "originKey": "173ee2ff-a461-48dc-8671-70adb80480a3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3af11154-0220-4f93-9f21-fee295f4bdef",
                                                            "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4bc3cfc4-44b2-4531-afb1-f5cd36d9c36f",
                                                            "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c4e19ff5-fbc2-4bf7-8e75-3e7856c024ab",
                                                            "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed0b0534-8cb4-4554-8dc1-36824be8e589",
                                                            "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f72e9bf0-2bf9-4cd1-b2d0-321c31e6b622",
                                                            "requirementId": "cb0c0d20-9f01-4ab0-afdf-ed8900dfea4d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ca5d32d9-75d8-4974-b396-d6ae7b87d2f1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1637,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                    "name": "Has the trust assessed whether the CFO, and others holding key financial posts, should have a business or accountancy qualification and hold membership of a relevant body?",
                                                    "descriptionHtml": "<p>Trusts need to assess whether the CFO and others holding key financial posts should have a business or accountancy qualification and hold membership of a relevant professional body, dependent on the risk, scale and complexity of the trust's financial operations. The ESFA encourages larger trusts to consider accountancy qualifications from professional bodies.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:30:09.792+00:00",
                                                    "originKey": "9ad92d30-dcae-4c03-b58d-7f4745983ccf",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "43bdb7f5-8767-42fb-a707-b58ad52e1162",
                                                            "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b3105746-66fb-4075-8a64-96abd0977838",
                                                            "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ba2c1025-b6d4-4b4d-945d-86986a0e10e2",
                                                            "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dbfb2a3c-cbb3-4b29-93dd-797f994515ed",
                                                            "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e2feb5b1-11ee-4abd-86f9-3d2a61ce2ce8",
                                                            "requirementId": "c8d40284-cb10-4cc6-a807-b03630c02ea6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8f5d0ca-9d21-4e78-bb26-2dd88b4df050",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1638,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                    "name": "Do board meetings take place at least three times a year and business conducted only when quorate?",
                                                    "descriptionHtml": "<p>The board and its committees must meet regularly enough to discharge their responsibilities and ensure robust governance and effective financial management. Trust board meetings must take place at least three times a year, with business conducted only when quorate; however, it is expected that the board will meet more frequently.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:31:06.591+00:00",
                                                    "originKey": "f3d777c1-7423-4d95-bd75-1625e3d4d338",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2bea3704-230c-4891-83db-5123cbdce41a",
                                                            "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c14d96f-2bcc-4a7a-b418-970ddd0853c6",
                                                            "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9926d10b-0170-4a3c-9b5b-87b267bfe7c5",
                                                            "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f400100b-10b5-4985-a791-d98e7cc316a5",
                                                            "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "da9aef33-ea06-4bb0-9bd0-7f0409fcdf04",
                                                            "requirementId": "0ec22ae4-9592-42d4-b0aa-ccbcec682a2e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5ccf059d-1b71-4d1f-a607-5c9018fcd5ac",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1639,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                    "name": "[Trusts where the board met five times or less during the year] Does the board describe in its governance statement, accompanying its annual accounts, how it maintained effective oversight of funds with this frequency of meetings?",
                                                    "descriptionHtml": "<p>The board and its committees must meet regularly enough to discharge their responsibilities and ensure robust governance and effective financial management. Board meetings must take place at least three times a year. If the board meets less than six times a year it must describe in its governance statement, accompanying its annual accounts, how it maintained effective oversight of funds with fewer meetings.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:31:55.944+00:00",
                                                    "originKey": "491c9553-b7a4-4c7f-88f5-295b05bfd068",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "100aa07d-74b7-4737-ad1e-0fbe309c911d",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "226ab35b-144c-40d3-af3d-95afbe852a8b",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a21bcecf-5cea-44fb-9f71-ae5d53e1a101",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "beb2bb62-8b9d-480d-bee3-be71887798d1",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9a56adb6-7033-495a-b81a-88844ab03dd9",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c6eea8c2-28a7-4879-8442-34dc8e3fdf57",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1640,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                    "name": "Has the board minuted its approval of a balanced budget, and any significant changes to it, for the financial year to 31 August?",
                                                    "descriptionHtml": "<p>Trust boards, and any separate committee responsible for finance, must ensure rigour and scrutiny in budget management. They must approve a balanced budget, and any significant changes to it, for the financial year to 31 August. The budget can draw on unspent funds brought forward from previous years. The board must minute its approval.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:32:34.076+00:00",
                                                    "originKey": "def2563c-1e87-47d9-bdd9-571543c9cd68",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19e3d938-e660-47bf-b898-041fb4131ae6",
                                                            "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3db9357b-3e72-4e3a-92da-dcfc5e3e478f",
                                                            "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a28db5aa-060d-4785-92e8-ee3caeafe265",
                                                            "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cfa714a0-f004-46df-b5c3-7c6b90aee5e4",
                                                            "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ee1a694a-d5e6-4374-aabe-23309f57af32",
                                                            "requirementId": "9b805f00-b3c8-4302-87bf-6715a03fb7f0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "21c7bc2e-9d34-4a79-b780-66d18ea57afa",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1642,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                    "name": "Are procedures in place to ensure that earmarked and ring-fenced funds are spent for the purposes for which they are given?",
                                                    "descriptionHtml": "<p>Earmarked and ring-fenced funds, such as pupil premium, must be spent on the purposes for which they are given and it is important procedures are in place to prevent the misuse of funding. Certain funds, such as pupil premium and the PE and sport premium, also contain reporting requirements that need to be complied with.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:38:51.009+00:00",
                                                    "originKey": "c8523c58-f7fe-4c15-9429-2508f0483f48",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford LA guide</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "14f5c940-06f1-4365-8214-0fa727f8c253",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "49db552c-1095-432c-801d-447bafaac219",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "abe6066b-c42c-4e44-9c99-7f3ba7f96ab8",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e8627393-dac2-4e01-87c1-ab911a820060",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2a5ad7ea-ac7b-43d9-bb48-e5da98b3e3a2",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "87e6ec7b-e7e4-48cf-b02e-c0da3c0767d3",
                                                            "requirementId": "5afe6700-a7be-4d11-b83d-b501958277e9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6416110e-d6ba-4799-ab76-0fcc9a606a11",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1644,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                    "name": "Does the trust have adequate insurance cover in compliance with its legal obligations or RPA membership?",
                                                    "descriptionHtml": "<p>The RPA does not cover all risks. Trust should consider the RPA unless commercial insurance provides better value for money. If the trust is not an RPA member, it should determine its own level of commercial insurance to include buildings and contents, business continuity, employers’ and public liability insurance and other cover required.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:41:02.508+00:00",
                                                    "originKey": "fb5f8ad6-4689-4959-9d7e-f89d7c0009d2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0c70875b-be52-442a-81c6-fb3a3b45ad1d",
                                                            "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "746c0c32-baf4-4da3-a1c2-a7c09a2c1a67",
                                                            "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e671b502-83ea-4d57-bbe6-9980a29a9b4d",
                                                            "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec6dc48e-b208-4d6a-87d9-7d24e7753bdc",
                                                            "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4bee3fa9-4ca0-4204-ab2f-2e3cdd98da29",
                                                            "requirementId": "ea559f58-202f-42d5-a3a1-2508949d8ed0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5c68f539-6e38-4e53-a35b-d024977ede7a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1645,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                    "name": "Are procedures in place for the trust board to ensure budget forecasts for the current year and beyond are compiled accurately?",
                                                    "descriptionHtml": "<p>As part of its budget setting responsibilities, trust boards must ensure budget forecasts are compiled accurately, based on realistic assumptions including any provision being made to sustain capital assets, and reflect lessons learned from previous years.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:41:31.872+00:00",
                                                    "originKey": "fce52504-6cb2-435a-8d27-4bcf20f6bc6a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "00b1763f-8d0f-4f15-bee0-d7d834817331",
                                                            "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "17fb2a59-64c0-40b7-b227-81182e87d5ac",
                                                            "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c029cfe-453f-482e-b236-d3d821deccf1",
                                                            "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3e77eb4f-7474-42ec-a76a-1c7de3bab3e6",
                                                            "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6a6558c4-5003-4041-82d0-0041334694ea",
                                                            "requirementId": "7ab40ad4-7264-4b77-a070-0fc66db60580",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dffe8e19-ce20-4ea5-8c8c-bb601be25ce2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1646,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                    "name": "Are procedures in place to ensure the trust prepares management accounts every month setting out its financial performance and position?",
                                                    "descriptionHtml": "<p>This will typically be the CFO's responsibility. The format of monthly management accounts must include an income and expenditure account, variation to budget report, cash flows and balance sheet. These accounts need to be shared with the chair of trustees every month, and with other trustees six times a year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:42:14.973+00:00",
                                                    "originKey": "5b649eb1-8cd4-49a2-a1ca-e8ad8c91a095",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0e2932ce-8694-44b8-95d7-fd7b1ed1f04c",
                                                            "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b43b45b-4aaf-43b6-95c1-6df21c9cae60",
                                                            "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bee069b9-94be-490b-ab92-4ec5ca808c76",
                                                            "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d7de8443-073d-4bc6-8840-974c518e49a9",
                                                            "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "191c0b8e-0462-40bc-97e2-d99788c20670",
                                                            "requirementId": "d8f0b03e-d1f3-4edc-9cab-29364f7e8824",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9e0ac4e5-c472-4007-b786-9365fe97f743",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1647,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                    "name": "Has the trust selected key financial performance indicators to measures its performance against them regularly, including analysis in its annual trustees’ report?",
                                                    "descriptionHtml": "<p>It is for each trust to determine its own key financial performance indicators. This can include targets such as spending on particular areas, e.g. staffing costs, as a percentage of total expenditure, average teacher cost, and revenue reserves as a percentage of total income.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:42:51.31+00:00",
                                                    "originKey": "5045ac28-485f-46f3-8475-7d3580348aaf",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a6742d5-7d32-463f-845b-323accc0dbcf",
                                                            "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3b5b83e9-7df6-49cc-81f5-625370791a33",
                                                            "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "61efc2d1-8eda-4801-9652-e4ad1e26e783",
                                                            "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7220bdc6-0c81-431a-b33f-9a579fedc90f",
                                                            "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3ac35e83-7b6d-48a0-aca7-ffee8b614611",
                                                            "requirementId": "b9891f5d-2d42-41fe-ba85-ac91723fb050",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "efb24fdb-d9da-4568-a628-352c87ed8143",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1653,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                    "name": "Does the school have a Tendering and Procurement Policy?",
                                                    "descriptionHtml": "<p>Trusts need to have a competitive tendering policy in place and applied, ensuring that the procurement rules and thresholds in the Public Contracts Regulations 2015 and Find a Tender service are observed.</p><p><br></p><p>You may wish to upload a copy or attach a link to your trust's relevant policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:54:48.099+00:00",
                                                    "originKey": "b1179f6d-dd98-4412-90f2-bc3011d16a8e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "f184c99d-ed6d-47ad-a348-23ba91b42a66",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8da839b5-074c-4989-bb09-0f4056236c48",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0f9143c-0e3e-43ec-90a9-e6d34f78b1e7",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a9cc5b56-2d71-468f-8c03-fb36b26e2b26",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ffebe417-8172-405c-9551-f39e1a2a0a73",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2dcb94ee-8fd1-41d9-a4af-ecc4ade88df4",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d2ecc3c8-c9a2-4b64-9dcf-a362eb0f5544",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1654,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                    "name": "Has the trust cooperated with risk management auditors and risk managers, and implemented reasonable risk management audit recommendations made to them?",
                                                    "descriptionHtml": "<p>Trusts need to implement or show progress towards risk improvements recommended to them. Not acting, especially where significant risk is identified, could see the trust face prosecution or fines.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:55:24.929+00:00",
                                                    "originKey": "32d68a82-353f-4db9-af0a-77c8d456fc70",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4f7e2c98-ba5f-4d1f-994e-2419dea869c9",
                                                            "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b0c72f8a-4da2-4294-89bf-601d76a0261c",
                                                            "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e37c4ffb-e90d-4b80-b6d8-6d043db15945",
                                                            "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8ab1d55-c3c3-4992-a6fa-25a36fc4dcda",
                                                            "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4da30cef-c7f1-4946-a185-ccb61f49e982",
                                                            "requirementId": "ee3836ee-f1c2-4eef-893f-4e58a9b98fcc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "18921d0c-7949-4ce8-8eb3-db108623f814",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1655,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                    "name": "Are procedures in place to ensure the school completes and submits bank reconciliations as required by the LA?",
                                                    "descriptionHtml": "<p>Schools must complete a bank reconciliation return at least each quarter; however, some LAs may require returns each month.&nbsp;Schools should check their requirements and submit the bank reconciliation returns by the prescribed dates.</p><p> </p><p>You may wish to upload a copy of or attach a link to a school policy containing the relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:55:59.641+00:00",
                                                    "originKey": "a0381506-a112-4b7d-942d-906fa6330c4d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford guide</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "07b478ca-528c-486e-bf42-93f07f3f06eb",
                                                            "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64f976d6-e35d-4d8c-af58-af841b746c1e",
                                                            "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "87601df4-c38b-4dc8-8db6-30226af0cfb6",
                                                            "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9cd2455d-e1c2-4f2d-9971-ef6cf4e1dab4",
                                                            "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "df8f3f58-e65a-455a-87f0-ce1eaa943b26",
                                                            "requirementId": "ed14796e-0909-4ff1-93e7-3b93e8d1974f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "425495d6-9601-42c3-9f76-d3d4a8e1ee30",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1656,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                    "name": "Are procedures in place to provide the LA with details of anticipated and actual expenditure and income?",
                                                    "descriptionHtml": "<p>The form and timing of this will be determined by the LA; however, this should not be more regularly than once every three months except for details regarding tax or banking reconciliation. Please note this is separate to the requirement for an annual budget plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:56:34.59+00:00",
                                                    "originKey": "3449b4cb-cf5f-4e64-bffd-8e7457722de0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Schemes for financing LA maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2bdb12b1-2048-4c51-9107-9a232b3eecaa",
                                                            "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8032ba20-6ec5-4e04-9fa1-2a65b77f6c8b",
                                                            "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c0871eaf-a7e5-4a6d-8725-c6e29bed24de",
                                                            "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f625461e-d1cd-46bb-a0ce-2d270305fc88",
                                                            "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6f27176a-41e1-43ab-ba69-9a8e3d2e1c26",
                                                            "requirementId": "007391cd-c2f1-4ae4-8fc5-403f6c73e5dd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a5cb8db5-3275-409c-948b-6527cd7aadde",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1657,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                    "name": "Does the school have adequate insurance arrangements for premises and contents or membership of the DfE's risk protection arrangement (RPA)?",
                                                    "descriptionHtml": "<p>Schools need to have adequate insurance for premises and contents, or membership of the DfE’s RPA. Insurance arrangements should be covered in the business continuity plan. The LA may require the school to demonstrate that cover relevant to the LA's insurable interests, under a policy arranged by the governing board, is at least as good as the relevant minimum cover arranged by the LA.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:57:01.637+00:00",
                                                    "originKey": "ab92c9a8-fb77-46cb-851e-72a101804692",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS/schemes for financing maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "22072f36-fa88-45cf-ad32-c877f62fd3d7",
                                                            "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2bafb96c-2cc8-4a75-b311-e1896fe90b57",
                                                            "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b63d6de-0762-4838-a8e9-5860d499112f",
                                                            "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0dc6b3b-35a7-4844-9ea9-bb422243253c",
                                                            "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a7270984-8b97-427b-bf3a-4c6f5a8d5fc0",
                                                            "requirementId": "e3061225-ac7b-43cd-a46d-4bfd6697680d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8b5fa202-6de2-40a5-88a3-a5ed8545c301",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1658,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                    "name": "Is the audit and risk committee (or equivalent) provided with regular updates, including an annual report, in line with the scheme of work for delivering internal scrutiny?",
                                                    "descriptionHtml": "<p>Regular updates need to be provided to the audit and risk committee by the person/organisation responsible for delivering internal scrutiny. This should include:</p><ul><li>A report of the work to each audit and risk committee meeting, including recommendations where appropriate to enhance financial and non-financial controls and risk management.</li><li> An annual summary report to the audit and risk committee for each year ended 31 August outlining the areas reviewed, key findings, recommendations and conclusions.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:57:51.218+00:00",
                                                    "originKey": "1b42aff7-f9e8-48af-926b-41c06f26862c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5d9b2be3-993b-4a74-a36c-430133d0a566",
                                                            "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82c24263-7559-472b-8fb4-90a7369d6425",
                                                            "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "979e8789-521c-4023-bad5-108abbfd55dd",
                                                            "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b82085e0-2337-42c5-8768-be1c9f1c97c0",
                                                            "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2a5cedd6-de43-4910-aa51-6579d8ae628a",
                                                            "requirementId": "3d534d01-fcd3-4571-8809-e0f794d1a9c0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6a5a4a41-076b-4aa6-b879-a444bc0856c4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1659,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                    "name": "Is internal scrutiny covered by a scheme of work and delivered by a suitable person/organisation?",
                                                    "descriptionHtml": "<p>Internal scrutiny in a trust must be independent, objective, and conducted by someone/an organisation suitably qualified and experienced and able to draw on technical expertise as required. It must be covered by a scheme of work, driven and agreed by the audit and risk committee, which is timely with the programme of work spread appropriately over the year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:58:40.115+00:00",
                                                    "originKey": "6202e5cb-d761-4f60-902c-e25973bc5378",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2973ee3f-3664-4a4f-9eaf-febe668d7b1a",
                                                            "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77a8881d-8039-41b6-bd7d-551f0e1f71bb",
                                                            "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d7105f0-48a2-46ce-9011-b74c185062f9",
                                                            "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9d522396-b34e-43a4-9348-f5c154463491",
                                                            "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7147b941-5bc3-4f98-9ceb-29c81670b47c",
                                                            "requirementId": "92f7e179-f5b0-4d83-8a70-0aa302a49eb6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4d3c88fa-4849-4911-99e1-248120d15dd4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1660,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                    "name": "Does the trust identify, on a risk basis and with reference to its risk register, the areas it will review each year, modifying its checks accordingly?",
                                                    "descriptionHtml": "<p>As part of the trust's approach to internal scrutiny and risk management, it needs to maintain oversight of its risks and keep these under review. Considerations include identifying where, for instance, procedures or systems have changed and greater scrutiny is required.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:59:20.393+00:00",
                                                    "originKey": "5ef28319-f404-4c80-870a-874452bd42e4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "47aed8f1-9c01-486c-9d0f-380e543f3459",
                                                            "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4fbe1d8e-e8e9-43e2-ae8e-43912ab9997c",
                                                            "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a748980-b2e6-4cce-a23e-2ea4de67855b",
                                                            "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "99c51850-0ab9-4e9b-8335-081f178decd3",
                                                            "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e0a6ba6d-3eaf-41c6-b2fe-670bffabf451",
                                                            "requirementId": "09a9f849-4789-4e62-a211-c8d1baf0923a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "68bbf250-c891-4126-9300-b59ea734a548",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1661,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                    "name": "Have the governing board and headteacher informed all staff of policies and procedures related to fraud and theft, and included this information in the induction for new school staff and governors?",
                                                    "descriptionHtml": "<p>All schools must have a robust system of controls to safeguard themselves against fraudulent or improper use of public money and assets. The governing board and headteacher must inform all staff of the controls in place to prevent this and the consequences of breaching those controls. Induction must include information on policies and procedures related to fraud and theft.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:00:03.216+00:00",
                                                    "originKey": "f3063b4a-0e13-4463-80dd-4f32c4be976e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Schemes for financing maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1169a918-52ec-4462-8835-885029ed5614",
                                                            "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2a049755-7306-4dd8-a0ef-af9261657ffa",
                                                            "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8bd9ee15-180c-479f-92e8-a8b197ac0280",
                                                            "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c124a430-6d9b-467b-b0d9-d531d41ea422",
                                                            "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4f1d6c11-e766-457f-9913-4c2fac58738e",
                                                            "requirementId": "5e8adb5e-8a2a-4a17-9f33-1953bd4022a8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5c15925b-0530-4bc0-8409-844d36c73e24",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1662,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                    "name": "Does the trust's register of interests identify relevant material interests from close family relationships between the trust’s members, trustees and local governors?",
                                                    "descriptionHtml": "<p>The register needs to identify relevant material interests arising from close family relationships, as defined by the Academy Trust Handbook, between those individuals and employees.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:00:44.211+00:00",
                                                    "originKey": "785381a3-5b6a-4101-bc3f-d2646ab06db4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "ac40aa8c-df2e-4461-99ad-bc7d64d184c2",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "599ea578-0cff-4e55-a39f-80d47e3f8497",
                                                            "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6590f4c9-dfac-4180-b1ce-2d394bea3295",
                                                            "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "795f15b9-942a-494a-a14b-f5c4ed2eca50",
                                                            "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf82679a-78dc-495e-acd0-cde922a4be0c",
                                                            "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f9476681-65cd-45a4-8b9d-0ddc32d1ce04",
                                                            "requirementId": "ddc12525-f615-4fd9-b863-79e4f7823298",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fcf21eb1-0622-46be-9d86-180b779e9e4f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1663,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                    "name": "Does the school maintain an up-to-date register of business interests that is freely available for inspection by governors, staff and parents?",
                                                    "descriptionHtml": "<p>The register must, for each member of the governing board and the headteacher, contain any business interests that they or any member of their immediate family have, details of any other educational establishments that they govern, and any relationships between school staff and members of the governing board. There should be an annual review of entries, and the register made freely available for inspection and published on the school website.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:01:18.068+00:00",
                                                    "originKey": "fe61fd98-1672-469b-b772-86422963ab19",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Schemes for financing maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "10a1b613-c462-4eba-a0fe-547bcd387faf",
                                                            "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3566cb0a-9d3c-4437-9931-7954c20ef897",
                                                            "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b63b94c7-5862-4592-af5b-8dbeac495327",
                                                            "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ca573d5d-b82f-4702-aabd-b5d9ee4991c7",
                                                            "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "45e4dfea-7da2-486c-988f-e7441ff1028b",
                                                            "requirementId": "f199b8a0-026a-4615-a4f8-3caf3bf9e941",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "00373dbb-363b-4b13-a702-a1492965ff91",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1664,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                    "name": "Are there trust-wide measures for managing related-party transactions (RPTs), with sufficient records kept and sufficient disclosures made in the annual accounts?",
                                                    "descriptionHtml": "<p>Trusts must be able to demonstrate that RPTs have been conducted in accordance with the high standards of accountability and transparency required within the public sector. The principles outlined in the 'Academy trust handbook' need to be followed when managing RPTs.</p><p> </p><p>You may wish to upload a copy of any relevant records.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:02:08.061+00:00",
                                                    "originKey": "2509b7b6-7f44-4bf0-ba4c-b247ea7a3eee",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "42003941-ce3d-4ab7-8a17-c8c0fa24790c",
                                                            "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "44be509e-3b85-4111-b559-78d5f1f15492",
                                                            "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "66acf779-6a1f-487e-ad28-1c510a789edf",
                                                            "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c64df108-5fe9-4d7b-8821-69da0e02c4ae",
                                                            "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "78912043-d6e1-41e6-bf3f-b15633d9ac23",
                                                            "requirementId": "ff8d1bd1-e3b8-4227-af30-bf4793f5aa0b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "be37cd6a-974f-416b-a84b-064c1aa9b0ae",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1665,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2efd165f-1753-407d-9472-61f74b58039d",
                                                    "name": "Are procedures in place to ensure all contracts and other agreements with related parties are reported to the ESFA in advance?",
                                                    "descriptionHtml": "<p>Reporting to the ESFA for related party transactions (RPTs) must be done using the related party online form. This requirement applies to all such contracts and agreements made on or after 1 April 2019. Prior ESFA approval is required if the deal exceeds £20,000, including if the deal means the cumulative value of contracts and other agreements with the related party exceeds, or continues to exceed, £20,000 in the same financial year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:02:48.625+00:00",
                                                    "originKey": "485fd0b2-86f4-4dc3-bd58-df49056d255a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2600a70e-fe80-4a95-b679-952f3ddf4a25",
                                                            "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "52ba07de-8ddc-4d1b-9da5-2ed365570d9e",
                                                            "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d12135d0-4505-4259-b6e3-4b7098d39774",
                                                            "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0a57a68-6f51-4ff7-a37a-3a04bba1c5dd",
                                                            "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7b23207e-e289-45c1-9c73-ffd0f9c05ea9",
                                                            "requirementId": "2efd165f-1753-407d-9472-61f74b58039d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b4de2481-3217-425d-81cc-421fcf80099b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1667,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                    "name": "Are procedures in place to ensure the trust keeps adequate financial records and retains them in line with the 'Academy trust handbook' (ATH) and its funding agreement?",
                                                    "descriptionHtml": "<p>Trusts must maintain adequate accounting records, and financial records must be operated by more than one person. Trusts must also retain records to verify provision delivered by it, or its subcontractors, in relation to the ATH and its funding agreement, at least six years after the period to which funding relates.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:09:10.366+00:00",
                                                    "originKey": "375084db-f492-4b60-9e06-89361452acc7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "318d2509-1bef-4f87-97d7-acaa75e7154e",
                                                            "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4120fd56-7c81-4df0-86d8-8904f6eb7e82",
                                                            "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c369dda-75c8-413b-8891-4a55f8988da5",
                                                            "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ba08b1ce-4133-4ca2-af03-ec0e3d1719ec",
                                                            "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5c7f63ef-1119-4c70-989a-aff059486c2d",
                                                            "requirementId": "9e49340c-cd1c-4198-952b-6890c346663b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9f11bbb4-544c-468b-af91-ba223ca9207c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1668,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                    "name": "Are procedures in place to ensure the school keeps adequate financial records and retains them for the appropriate period?",
                                                    "descriptionHtml": "<p> Schools need an adequate accounting system that accurately records income and expenditure. Base financial records must be retained in line with the law, and adequate records kept relating to standing orders, direct debits and BACS payments. Cash transactions must also be properly documented and receipted.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:09:44.722+00:00",
                                                    "originKey": "bc816780-7527-4091-b0d3-ec9262721436",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS + Bradford LA</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "31ccc4e8-0982-49bc-8dae-48b64026c683",
                                                            "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6438f841-7d86-4bce-9c33-c3afa1023224",
                                                            "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6cb6ab8a-2f29-476b-855c-557c94d37222",
                                                            "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fa41d8ba-8fb0-4d6f-aaa9-3a59d4b37b2d",
                                                            "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8ca111f2-2489-4990-89b6-4d27e27be01d",
                                                            "requirementId": "3221443e-7c78-4bef-b4a8-815ee219b818",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8c7ffe8c-b3a8-45fb-94d5-fe7e564ec18e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1669,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                    "name": "Does the trust's control framework manage and oversee assets, including the maintenance of a fixed asset register?",
                                                    "descriptionHtml": "<p> As part of the trust's responsibility to establish a control framework, it must include the management and oversight of assets, including the maintenance of a fixed asset register.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:10:21.338+00:00",
                                                    "originKey": "26dc3496-9d7a-4a76-ba68-0c50f4a6b6f7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19698ead-d64e-4fef-bb75-4991076164a2",
                                                            "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "35c296fa-c08e-4d86-a91d-0a25e663960b",
                                                            "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3eaa9677-a560-4c12-bb2b-c92031f831cd",
                                                            "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "94fee76b-ccf1-4231-80ea-5f4d321c6e64",
                                                            "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "25d5d51f-4ed7-44af-b9e5-6869a53452de",
                                                            "requirementId": "076a1c02-bc9d-4fe1-8617-4cf496b8cf75",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5ff9fad7-9b70-4579-a7e1-26ad0bd84efe",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1671,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                    "name": "Does the trust board ensure there is an appropriate, reasonable and timely response by the trust’s management team to findings by external auditors?",
                                                    "descriptionHtml": "<p>The audit process identifies areas that may require improvement. The trust board, taking advice from the audit and risk committee, or equivalent, must ensure there is an appropriate, reasonable and timely response by the trust’s management team to findings by external auditors, taking opportunities to strengthen systems of financial management and control. </p><p> </p><p>You may wish to upload a copy of a relevant policy which outlines the response to an external audit's findings.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:11:39.931+00:00",
                                                    "originKey": "4e959149-9822-4d66-a62a-a8a118d3a285",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a4c61ce-6ec3-4983-9f28-a918a9dfbca4",
                                                            "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5ebded10-4b61-4163-ab9f-c5cb9f8af143",
                                                            "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "79ad2c3b-b207-449b-afcd-02fa0ae5c633",
                                                            "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b84ed463-50af-4aea-9750-c5dcc4e8db96",
                                                            "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "885f2e1d-9c4c-4dc7-be17-e9d078acc7fb",
                                                            "requirementId": "757d0cba-48bb-4c7c-b5f5-c3191990ca23",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2a480ba1-0237-4139-9508-2c9c8437699f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1672,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                    "name": "Are procedures in place to ensure the trust board's decisions about executive pay follow a robust, evidence-based process?",
                                                    "descriptionHtml": "<p>Decisions about executive pay, including salary and any other benefits, must follow a robust, evidence-based process and be a reasonable and defensible reflection of the individual’s role and responsibilities. No individual is allowed to be involved in deciding their own remuneration.</p><p> </p><p>You may wish to upload a copy of your trust's policy outlining procedures for deciding executive pay.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:12:23.589+00:00",
                                                    "originKey": "29c5a2ed-b6ca-4877-bcbf-37ad7902e7e7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0ca03ea5-9b3d-4e1d-be51-7ddec67b5c8f",
                                                            "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c1101d0-7b32-4c34-a3aa-694aab94d46d",
                                                            "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6b0b2000-6deb-4ab2-9a34-bef3fd302deb",
                                                            "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a47bf9ae-272f-4861-8cf0-00f1258e252c",
                                                            "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "76b49814-bbbb-4b77-8a59-cbf163391bf2",
                                                            "requirementId": "0faf3d7e-b93d-4b8d-b888-1539a6027df8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d6badbf4-a1d7-433c-8c1b-6a8e01eed9a0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 1676,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                    "name": "Does the school's holiday pay for part-year staff follow the calendar week method?",
                                                    "descriptionHtml": "<p>Following the Supreme Court's ruling on the Harpur v Brazel case, school employers must review how they have been calculating holiday pay for part-year workers, e.g. TAs on term-time only contracts. If a pro-rata method has been used, then the 'calendar week method’ needs to be adopted.</p><p> </p><p>You may wish to link to or attach evidence of your school's procedure for calculating holiday pay for part-year staff.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:14:56.841+00:00",
                                                    "originKey": "c1420a5e-f4ad-4aa4-a693-95c0b3de0b86",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.theschoolbus.net/article/how-does-harpur-v-brazel-affect-holiday-pay-for-termtime-only-workers/9182\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.theschoolbus.net/article/how-does-harpur-v-brazel-affect-holiday-pay-for-termtime-only-workers/9182&nbsp;</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3d75e874-c608-4705-9ee9-314282a864b7",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c0d82c0-358d-4b03-a164-c04ee6b46982",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c688ffbe-3821-4364-8d33-143663f404c0",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d775c7f1-d742-4a67-a27e-49111b2c5aae",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "affdb98c-613a-4511-ae45-399e7e7b6d0a",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fe8c950d-9115-4e3a-8da9-44e4ab06af2c",
                                                            "requirementId": "30dd7fa8-2433-42de-ae88-2e4f8da0308f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1f195c94-a7e2-4017-a229-b701bb553363",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1678,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                    "name": "Has the school submitted a well-informed and balanced three-year budget forecast to the LA between 1 May and 30 June?",
                                                    "descriptionHtml": "<p>Schools must submit a three-year budget forecast each year, at a date determined by the LA between 1 May and 30 June. A balanced budget is one that will lead neither to an excessive surplus at the end of the year nor to a deficit. A budget is considered well-informed when it takes account of the best available information on all variables, such as pupil numbers and staffing changes. The LA has a duty to inform schools of the purposes for which they intend to use the forecast.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:19:23.355+00:00",
                                                    "originKey": "bf2b2f19-61c7-4942-a172-1427679fcc19",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Schemes for financing local authority maintained schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ec030aa-4390-4a42-8798-fa23fbf65fb9",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c59527e5-7f02-4ff7-a4f9-49bb06206e04",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d24a5718-ba15-49ff-a624-26862c83d850",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb4ab40b-d261-4e80-91a6-d1a74384f9fb",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9bf02d14-318f-4d99-b2af-57b325c47b70",
                                                            "requirementId": "eb85552c-c8a9-45c3-a816-37ec633da79e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5e0eb637-2a86-49e9-9684-254c6c570ac9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1679,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "01441065-2737-4f52-96ae-7443f430cee6",
                                                    "name": "Are there adequate arrangements in place to manage related party transactions (RPTs), including the recording and reporting of these to the LA?",
                                                    "descriptionHtml": "<p>Schools must append a list of RPTs as part of their response in the Schools Financial Value Standard (SFVS), including the number of RPTs and the value for each. Contracts and agreements that are live in the year that the SFVS is being completed for need to be reported. These should continue to be reported until the year in which the contract ceases, at which point they can be removed from the list. Historic related party transactions are not to be reported.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:21:13.001+00:00",
                                                    "originKey": "f30228ac-c18a-4005-84f3-8f2c8e111dee",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Financial transparency of local authority maintained schools and academy trusts</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5072c075-b251-48e3-a610-b5cdbc8091bb",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5b18fdc7-dffa-44e0-9488-9568cff2cdc1",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dfb03fcf-80d9-424b-9994-bd1942d34d9d",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e383a4b3-af7c-46e7-b192-6b24bfefabd7",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "22f2d9df-38c9-455b-9b17-13e80098f129",
                                                            "requirementId": "01441065-2737-4f52-96ae-7443f430cee6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8d6faaf-2a34-4a8f-a1fe-10a0d04ac09f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1680,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                    "name": "Has the trust submitted to the ESFA a budget forecast return, approved by trustees before submission, by the July deadline?",
                                                    "descriptionHtml": "<p>As of 2022, the ESFA only requires a single budget forecast return (BFR), and trusts are no longer required to submit a separate budget forecast return outturn (BFRO) or budget forecast return three year (BFR3Y). The outturn information is now permanently included within the BFR.&nbsp;Deadlines are published annually, but are usually in July -&nbsp;&nbsp;the deadline this year is 26 July 2022. The BFR must be approved by trustees before submission.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:22:02.021+00:00",
                                                    "originKey": "6a820843-5d89-4195-b9d9-e112ae117a3b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Academies budget forecast return</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1bb58b7b-8278-4657-93ea-0729f040381f",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cfffd596-aca9-43cd-a4a3-021ea44147fd",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec456243-0318-4032-97f9-10cb32c299c1",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fc6b9a04-3997-49a4-82a6-d5d59ca5f2b6",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "930618df-b986-40d8-bc7d-9adc139990f2",
                                                            "requirementId": "76f6f47d-06dc-4aac-9433-28a804f469c5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8724b9e6-95c4-4160-ba6b-a3bc8de65d0f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1681,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                    "name": "Are management accounts shared with the chair of trustees every month, with other trustees six times a year, and considered at board meetings?",
                                                    "descriptionHtml": "<p>As part of the trust's responsibility to monitor the budget, management accounts must be shared with the chair of trustees every month irrespective of the trust's size, and with other trustees six times a year whether or not they meet in each of those months. The board must consider these accounts when it does meet, and minute it.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:23:03.596+00:00",
                                                    "originKey": "a7c6d798-e339-4364-95ae-3b103ba16725",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "89eacffd-fdc2-4de6-a526-073189238aab",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d274633b-aefb-43ba-8510-a2208e714468",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e0b96451-be26-4055-887f-3f2a7e2a88ff",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fa80b1ef-3a15-45bb-b2e6-cb1980c9d4e5",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5f4324fc-cd4e-42c2-8696-d35dc132752b",
                                                            "requirementId": "c899b959-757a-4c2e-98f0-af70f8e74ea2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "44d4e096-6175-4d73-99f6-385481f757d0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1682,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                    "name": "Has the school completed the Schools Financial Value Standard and submitted it to the LA?",
                                                    "descriptionHtml": "<p>Maintained schools must complete the Schools Financial Value Standard (SFVS) and submit it to their LA every year. The SFVS is typically completed by the SBM but it must be agreed by the full governing board and signed by the chair, and submitted before the end of the financial year; schools should check if their LA has set a specific deadline.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:56:32.512+00:00",
                                                    "originKey": "013364ff-699b-48f3-9091-afb188338ae8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Schools financial value standard (SFVS) and assurance statement</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2b19d6e3-ec4b-43b0-b1c5-4a1e813cebf9",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3706183e-4361-4165-b1e2-a5841ed0e002",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "74da6de4-8583-444f-9826-7e90d574c73c",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eeee2ab2-5b8f-4adc-90f0-bbc1cc886491",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2cf7cf23-479a-4f22-8ca8-ababbb90ab1a",
                                                            "requirementId": "8e474549-4a93-469c-898c-e31e1c64d6a5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "54635df0-0647-4c72-b1b7-98fdd0ee8b9b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1683,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                    "name": "Has the trust completed the School Resource Management Self-assessment Tool checklist?",
                                                    "descriptionHtml": "<p>All academy trusts that have an open academy at a specified date have to complete the&nbsp;School Resource Management Self-assessment Tool (SRMSAT) checklist each year. The checklist is to assure the government that resources are being managed effectively, and must be submitted to the ESFA by the specified deadline which is usually in March or April.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:01:29.364+00:00",
                                                    "originKey": "5dde5f6d-1d9a-4a78-8b15-870a273cfddc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://www.gov.uk/guidance/school-resource-management-self-assessment-checklist\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/guidance/school-resource-management-self-assessment-checklist</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08e277ec-d333-40b5-9a17-b0ae1c65d0c6",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1ec3d5c8-c54a-4be9-a78a-c16c1ec3dbea",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e3697c7-8ed8-420a-82b4-f3b6e7ccd194",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3b97538-d92d-41d8-993b-f1a746576e9e",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e3a7ce86-984b-4ac2-9c8b-d22de8a6267b",
                                                            "requirementId": "382a1830-7835-4d9d-8f69-2b3b9bc1532d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9c5dc408-cdef-4033-afe2-ff04f0357d20",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 1684,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                    "name": "[Trusts where the board met five times or less during the year] Does the board describe in its governance statement, accompanying its annual accounts, how it maintained effective oversight of funds with this frequency of meetings?",
                                                    "descriptionHtml": "<p>The board and its committees must meet regularly enough to discharge their responsibilities and ensure robust governance and effective financial management. Board meetings must take place at least three times a year. If the board meets less than six times a year it must describe in its governance statement, accompanying its annual accounts, how it maintained effective oversight of funds with fewer meetings.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:31:55.944+00:00",
                                                    "originKey": "491c9553-b7a4-4c7f-88f5-295b05bfd068",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "100aa07d-74b7-4737-ad1e-0fbe309c911d",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "226ab35b-144c-40d3-af3d-95afbe852a8b",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a21bcecf-5cea-44fb-9f71-ae5d53e1a101",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "beb2bb62-8b9d-480d-bee3-be71887798d1",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9a56adb6-7033-495a-b81a-88844ab03dd9",
                                                            "requirementId": "e12c1304-6af1-4083-9d7c-d4a7215efe57",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ee0626ff-f9ad-4852-84d5-a1fe92dd0b93",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1685,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                    "name": "Does the trust have a Reserves and Investment Policy?",
                                                    "descriptionHtml": "<p>The ATH requires trust boards to set a policy for holding reserves and explain it in its annual report, as part of its management of the trust's funds. The Academies Accounts Direction provides further guidance on how trusts must report on reserves. Trusts must also have an investment policy to manage and track its financial exposure, and ensure value for money.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:23:46.67+00:00",
                                                    "originKey": "7467e980-daf2-4451-ae1c-199fdf67b87b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "02ec2c57-996a-4917-8814-dca353e787eb",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37929eb3-3cd4-4063-a09a-b2733a57790f",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "583481af-ae5e-4fb4-a0ba-73ab104ae1d7",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e5e9a5f9-a2b8-4537-8fda-fe1dc8ae0879",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b690e754-be36-46c2-ab88-ff29123a6134",
                                                            "requirementId": "fd9b39c2-c0be-4de3-8efa-a89fcad0d901",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ab0ef71f-7bb3-441a-a23f-7214eaaafd47",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1687,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                    "name": "Does the trust maintain a risk register?",
                                                    "descriptionHtml": "<p>Trusts must manage risks to ensure their effective operation and must maintain a risk register. Overall responsibility for risk management, including ultimate oversight of the risk register, must be retained by the trust board, drawing on advice provided to it by the audit and risk committee. As risks are identified, they should be logged on the register and the associated control measures documented. A risk register should be a ‘live document’ and should be an ongoing process, but the board must review it at least annually.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:09:53.058+00:00",
                                                    "originKey": "54932ab5-985a-443a-b3d5-a9d451bae7a7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 77,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "264a43fd-c6a4-40d5-92bb-16bc0fa3e7d0",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7845c5fd-4e7c-4921-92f4-b135128db836",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c6af57d-9e9a-4592-b79f-25c60f5d3ee7",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4b44d82-8a9c-4166-9e13-c2d3022e9841",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "46ddf6ed-30c7-4b52-b565-446142028467",
                                                            "requirementId": "3794316e-2c35-40f5-bca2-dcfc804861a1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "09b295a3-31a0-4b9f-a14c-299729328875",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1688,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                    "name": "Does the school have a Tendering and Procurement Policy?",
                                                    "descriptionHtml": "<p>Trusts need to have a competitive tendering policy in place and applied, ensuring that the procurement rules and thresholds in the Public Contracts Regulations 2015 and Find a Tender service are observed.</p><p><br></p><p>You may wish to upload a copy or attach a link to your trust's relevant policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:54:48.099+00:00",
                                                    "originKey": "b1179f6d-dd98-4412-90f2-bc3011d16a8e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "f184c99d-ed6d-47ad-a348-23ba91b42a66",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8da839b5-074c-4989-bb09-0f4056236c48",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0f9143c-0e3e-43ec-90a9-e6d34f78b1e7",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a9cc5b56-2d71-468f-8c03-fb36b26e2b26",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ffebe417-8172-405c-9551-f39e1a2a0a73",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2dcb94ee-8fd1-41d9-a4af-ecc4ade88df4",
                                                            "requirementId": "4628f55c-bea3-4868-9ff8-ab621ce74039",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "82158b20-d8c5-4e07-b135-eebdd46e6b12",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 1689,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                    "name": "Has the school consulted unions on required policies?",
                                                    "descriptionHtml": "<p>Schools, as employers, must inform and consult recognised unions on the following changes in the workplace:</p><p><br></p><ul><li>Collective redundancies</li><li>Transfers of business ownership</li><li>Certain changes to pension schemes</li><li>Health and safety</li></ul><p><br></p><p>Employers only have to consult unions that they formally ‘recognise’ – this recognition occurs when a union applies, in writing, to an employer to cooperate on certain issues. If an employer does not take steps to voluntarily recognise a union, that union can apply for&nbsp;<a href=\"https://www.gov.uk/trade-union-recognition-employers/statutory-recognition\" rel=\"noopener noreferrer\" target=\"_blank\" style=\"color: rgb(79, 82, 178);\">statutory recognition</a>.</p><p><br></p><p>If you do not formally recognise any trade unions, you can mark this requirement as not relevant.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-06T14:41:37.67+00:00",
                                                    "originKey": "3ca63372-5450-4b54-91b0-b6086c5a8e5a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>TSB QA</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "3d7729fb-a250-4851-92e4-73c2ddfd1954",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0a9cd857-9c39-4707-8542-8e7b4076187c",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "204558ca-a4d5-47a4-9896-1dec1025816b",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "63c12a4c-22c3-4318-98b2-3f209fd552a9",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "92c58585-f166-4ee8-807c-60c89e0c3635",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6669a7c3-6157-4403-97de-336b07ba3078",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bd5f64d4-82e4-48e1-9b8f-5bd36a5c7a0d",
                                                            "requirementId": "0262b326-9ca8-46f7-b129-cd35beea87ae",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c03f9b64-ca3e-45c1-8b1d-ce0c3194be17",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1690,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                    "name": "If your school or trust meets the reporting threshold, has the gender pay gap been reported via the government’s reporting website?",
                                                    "descriptionHtml": "<p>Any employer with 250 or more employees must comply with regulations on gender pay gap reporting. This includes reporting their gender pay gap information to the government online, via the Gender pay gap service. This information must also be published on the school website.</p><p><br></p><p><strong>Schools under the reporting threshold should mark this requirement as 'Does not apply'.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:33:28.054+00:00",
                                                    "originKey": "d3741c96-3e16-4ee4-b666-9ad31d5ecb55",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Report your gender pay gap data</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "045a964c-ea08-4e50-b682-eec7df737bf9",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0dfa5c9e-f5b9-4906-90dc-28b050c74358",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37692694-7c98-44f0-899c-33d0b35cc147",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1e50ca0-3fbe-40ce-9c2c-cb165bd2e4f4",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4d17d90a-3a5d-48a6-b27b-dd9e7906fd30",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fd07ced8-45ae-43fc-875b-e564e130c668",
                                                            "requirementId": "764fa1b4-25b0-4619-9bb9-a25d7ecc9271",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5b04a62c-6e3d-44f3-9ffb-3c29ad1049b7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1691,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                    "name": "If the school has 250 or more employees, has the gender pay gap been reported via the government’s reporting website?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010 (Specific Duties and Public Authorities) Regulations 2017, organisations with 250 or more employees must use the <a href=\"https://www.gov.uk/report-gender-pay-gap-data\" rel=\"noopener noreferrer\" target=\"_blank\">government’s reporting website</a> to report the gender pay gap in their organisation. Maintained schools must report by 30 March each year. Academy trusts must report by 4 April each year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-28T13:13:47.372+00:00",
                                                    "originKey": "8ebdb911-cbfc-4ab7-9f0d-e64cd077edd3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ESFA ‘Academies financial handbook 2020’, para.2.32</p><p><br></p><p>Advisory, Conciliation and Arbitration Service and Government Equalities Office (2020) ‘Gender pay gap reporting: overview’, para.1-5</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": "07e14c1a-1597-4f1f-822f-4cc014d7b4ec",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7462fd4d-6ac1-4f27-99f5-7bd1649f71b5",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7bab59e1-613b-469b-82eb-1fd274edc1fd",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ee9a2eb7-ad55-47c9-9514-ae871d817375",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f6a0854e-ce6e-40b1-b9d7-1d81bd97b8fb",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a190b62-9c0a-46a8-b457-890671fcbaff",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "844c970c-ca71-4691-bc90-1062f51de7fa",
                                                            "requirementId": "31051646-e087-44bb-8ce1-9cb24a1a2a48",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9556c9a0-6dfb-423a-a32e-b516a6452578",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1692,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                    "name": "Has the school published links to the 'school financial benchmarking' on its website?",
                                                    "descriptionHtml": "<p>The DfE requires maintained schools to publish a link from their website to the 'schools financial benchmarking' page, where the Consistent Financial Reporting (CFR) statement is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:52:00.956+00:00",
                                                    "originKey": "4b31ad2f-a6f5-4e02-8ead-f04d898d588f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/902003/Financial_transparency_consultation_response_July_2020.pdf\" rel=\"noopener noreferrer\" target=\"_blank\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/902003/Financial_transparency_consultation_response_July_2020.pdf</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4dcdc961-ad69-40ed-8406-556c98c4bf7a",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5c97a234-72ba-4125-8b08-f9aa76d32fee",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6490ecac-54da-454f-92c3-bf67502e9b1a",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "865cb041-c9ec-452c-b46c-bdd4b29f876d",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b137e8e1-180f-46b3-acd8-e7b186a383c9",
                                                            "requirementId": "cfb56754-2afe-4dcd-9fa8-295f3f9be978",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "633f66cf-edb4-4f56-99b0-29f0fec96bf2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 1693,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                    "name": "Has the school published up-to-date governance information on its website in a readily accessible form?",
                                                    "descriptionHtml": "<p>All schools must publish on their websites up-to-date details of the overall governance arrangements they have put in place, in a readily accessible form, i.e. it does not require downloading or opening a separate document. This includes setting out the structure and remit of the board and any committees (including any</p><p>local governing boards s in a MAT), as well as the full names of the chair of each.</p><p><br></p><p>Schools may wish to upload a screenshot or attach a link to the relevant page on their website as evidence of meeting this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:02:13.325+00:00",
                                                    "originKey": "3f1a8464-cece-4894-999c-fdfae7b88c88",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance handbook section 5.5</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": "40bca818-d3a7-44b1-bb5a-1a75512654d7",
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "12835f9b-a5df-45d9-a59f-67727c54844c",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "453c51cc-3964-4f83-8b66-74cd574f33d4",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5bc3ddce-0607-4b42-bf7c-20bbe852dfa4",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c5d3151-7874-49a8-8555-81abb1d67031",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "218dc58e-fe7f-4442-841d-3447187b99d0",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7f618e93-c187-463d-823d-41fd1ca63407",
                                                            "requirementId": "ac856122-79d8-4c87-91cb-6ff142ab9886",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "67b82ccf-1c17-48a9-9809-796d169f152a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2106,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                    "name": "Does the school have a School Security Policy and supporting procedures?",
                                                    "descriptionHtml": "<p>Schools should have a Security Policy in place to management and respond to security-related incidents.&nbsp;The policy should form part of a suite of policies to ensure the health, safety and wellbeing of pupils and staff.</p><p><br></p><p>You may wish to link to or attach your school’s Security Policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:08:37.722+00:00",
                                                    "originKey": "f0490349-c787-4213-8286-8e12af8ea7f5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'School and college security’, ‘Getting started on your security policy and plan’ <a href=\"https://www.gov.uk/government/publications/school-and-college-security\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/school-and-college-security</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1498c513-3671-4d55-969c-c516b3fb11ca",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64b6161c-04c9-49df-8242-4d012b0b85a6",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8458f2a5-0402-48cb-ac67-6aeed70f8cf0",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fce7a9e7-57a1-4f38-a4dc-2d855abc09b3",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "06909da4-992a-4b84-ac9e-f195a96dcd34",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "386368c8-dc09-4ead-b3a7-2a37993908cb",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9e2de738-a168-4cf6-90f4-7a0c21f74a0c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2107,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                    "name": "Does the school have an Emergency Plan?",
                                                    "descriptionHtml": "<p>All schools should have emergency plans in place detailing what you would do and how you would respond if you needed to take any temporary actions in the event of an emergency. Emergency plans should be generic enough to cover a range of incidents occurring during, and outside, normal working hours, including weekends and holidays.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school’s Emergency Plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:10:34.078+00:00",
                                                    "originKey": "918b75ed-ebb2-45ac-96e4-20dfc9447115",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'Emergency planning and response for education, childcare, and children’s social care settings'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 11,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4472e6ca-8663-4236-99f5-31fdfaf89835",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "586258f7-3fa8-4bcc-a719-7cef85673ccd",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c3c0c486-6558-4734-b6ba-f0f96e035bc0",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dbbf487f-7771-4e21-9ef2-219592de1351",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "83eddd73-8d4e-4d53-ac3b-47be61ccb5bb",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a246847d-0f73-4737-be2f-6df34612c151",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "29ca7290-5a7f-49ff-ae96-b89aa66d5557",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2111,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                    "name": "Has the school reviewed its health and safety procedures to ensure they are reducing risks?",
                                                    "descriptionHtml": "<p>As part of their monitoring and review process, schools should investigate incidents to ensure that:</p><p>&nbsp;</p><ul><li>They are taking appropriate corrective actions.</li><li>They are sharing learning.</li><li>They put the necessary improvements in place.</li></ul><p><br></p><p>Schools should regularly (at least annually) consider how they measure their performance. They should set up an effective monitoring system, backed up with sensible performance measures.</p><p><br></p><p>Where there is a health and safety incident at the school, the competent person and others with health and safety responsibilities should assess the effectiveness of the school’s procedures and its response. Changes to policies and procedures should be made as necessary.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:15:42.482+00:00",
                                                    "originKey": "d0c3d67c-f790-4b5e-9463-2fb778e072c4",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE ‘Health and safety: responsibilities and duties for schools’, ‘Review and evaluation’&nbsp;&nbsp;&nbsp;<a href=\"https://www.gov.uk/government/publications/health-and-safety-advice-for-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/health-and-safety-advice-for-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "17f745f6-169c-4d13-909c-b051a1255fc7",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4fb49149-a707-4eb6-8966-c9e6dec4f9ff",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8f0e0c57-9ebe-45e7-89ff-e739abc8bdb9",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c030b82a-17ab-4b9b-8133-f77788c1b79e",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3ea67495-6f83-441f-b26b-8c3af122e859",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "98d82421-8c75-49c6-8ca2-02495a547608",
                                                            "requirementId": "661af3af-d8b3-4e2d-a57b-fba34820eb23",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b4b5693b-61b9-455e-a9e3-38b8847cf300",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2114,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                    "name": "Have all staff been provided with training on understanding their duties in relation to protecting and processing personal data?",
                                                    "descriptionHtml": "<p>All staff should be aware of what personal data is, what processing means, and what their duties in handling personal information are. All induction training for new members of staff should include making them aware of their responsibilities under the UK GDPR and Data Protection Act 2018.</p><p><br></p><p>Training should be tailored to specific roles, and refresher training should be conducted annually.&nbsp;</p><p><br></p><p>You may wish to attach or link to a copy of your training log.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-27T10:17:18.385+00:00",
                                                    "originKey": "377cc1a2-4c24-4f7a-88dc-54853e74258e",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "194d66cd-9223-4ab4-b439-8518fe37edf6",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "297fdca3-7e57-4ba9-b309-c6c01fe28757",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "897f3a7e-ad41-42e2-a036-8d7ec9a16c03",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f72105bc-4356-4e63-a328-79a9f2b02a32",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "220b733e-57c5-4f0f-8164-87375d12b328",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e9be53b8-c360-4053-815a-8d1e195a3823",
                                                            "requirementId": "7e642f5c-8089-479e-a653-8a67146d1480",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2083e93e-64ec-480a-8549-6d758a0f2b2d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2121,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                    "name": "Have all members of staff who can influence how data is used, processed and secured been made aware of their data protection responsibilities?",
                                                    "descriptionHtml": "<p>Those who can influence how data is used, processed and secured, such as teaching staff, may have additional responsibilities which they need to be aware of. They may be required to handle paper documents, review high-level data maps, ensure there is a legitimate lawful basis for processing or engage in discussions about the identification and mitigation of risks.&nbsp;</p><p><br></p><p>You may wish to attach or link to a copy of your training log.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T08:35:15.72+00:00",
                                                    "originKey": "fc566c82-91eb-4784-b8d8-400bb2671ec8",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6fe3ebd6-e4f0-48c4-b849-15f6cb34072c",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b21c8d0-b1c2-43ca-bcd7-11705dc33d33",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e37e6161-4d4a-4ff0-bed7-1eb44e892992",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e59607fb-0dac-411a-9bf2-1af6b6aaf32d",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "606e158c-6482-4cc8-a9bb-a0d9388eef43",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "97cb8062-6f3e-47bb-bf33-5c1f059d316f",
                                                            "requirementId": "b3cd61f2-c4ae-47b2-9bc8-56488a0f4b22",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2bc2d429-6755-44da-b6cf-b44c67e50699",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2122,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                    "name": "Have all members of the SLT and those at an executive level received sufficient training on their data protection responsibilities?",
                                                    "descriptionHtml": "<p>Those in school who are responsible and accountable for deciding what and how data is shared and setting school policies on data protection must be sufficiently aware of the content of the UK GDPR and Data Protection Act 2018.</p><p><br></p><p>You may wish to attach or link to a copy of your training log.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T08:39:59.097+00:00",
                                                    "originKey": "2090ff5c-7d42-4e80-8e34-8651dfaed5f0",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1e1cbb6d-5c53-4b47-9bdd-f53c5f9dfb90",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6afdc0cb-bce2-4670-9163-46eb50a2d57b",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bebc1ddc-d94d-4027-a100-da8682edcc6b",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1933699-27e9-43ed-827b-837d7a5cf93b",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b1404926-21ba-458e-8b0d-986060cb4238",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dec3cc80-912f-45e3-b12c-e6a468198cd9",
                                                            "requirementId": "e0870e3e-c17a-40ae-8cea-287fb3c985d1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d2f89a27-4c76-497e-91d0-2090c1f32955",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2124,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                    "name": "Are governors made aware of their responsibilities with regards to compliance with data protection legislation?",
                                                    "descriptionHtml": "<p>Ultimate responsibility and accountability for compliance with data protection legislation sits with governors and trustees, so it’s important that they are made aware of what this involves.&nbsp;</p><p><br></p><p>You may wish to attach or link to a copy of your training log.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T08:46:48.448+00:00",
                                                    "originKey": "6fc4c938-e84e-4fac-b6fe-44e841ba28ea",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2fce15ef-3b99-4a64-a0ba-4c2ef6f9429d",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "495868bd-f4e8-409e-85fd-567c6f6b6849",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "78201de5-9ba9-40cc-be54-45ee22d5bcca",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7dab6d4-cedd-478c-a7ee-f3c3299fc641",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5d2a34bc-6eda-4cc3-aee9-f10be842c66f",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6153b840-495c-4c3b-96cb-8102e2d5af4d",
                                                            "requirementId": "4e3a4a0b-dfea-4010-b3ca-82331a7dd42c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3a52438b-075a-4c62-b878-61a4da22ea53",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2127,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                    "name": "Does the school have agreed procedures for dealing with subject access requests (SARs)?",
                                                    "descriptionHtml": "<p>Schools have one month to respond to SARs – there is a lot to take into consideration when fulfilling a request and abiding by data protection law. It is important that schools have a procedure in place for processing SARs in line with relevant legislation.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:07:29.685+00:00",
                                                    "originKey": "640f602c-48dc-426c-a998-dc4be079eded",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "351c5ee9-00cd-4f17-a7b8-604e14c39ce6",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "784d6328-62aa-4583-b8d3-94b0431bb86e",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9147f788-0984-4112-a27a-8f298623c3f8",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd024feb-35ae-4b1a-ba75-4a7550ce1745",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "30175ef0-767e-4d25-bd5e-26986fbafa8e",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "818b8d55-b8c4-4152-a656-db4375b8193f",
                                                            "requirementId": "b3a782bb-5d6e-4dc5-8273-6de15ac4c807",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "094f739a-0c83-4ec6-8002-5f9499e941de",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2128,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                    "name": "Does the school have agreed procedures for dealing with freedom of information (FOI) requests?",
                                                    "descriptionHtml": "<p>If a member of the public requests information from an organisation, this is known as a FOI request. Schools need to have procedures in place for dealing with these requests and ensuring they are processed properly.</p><p><br></p><p>You may wish to attach or link to your school’s procedure.&nbsp;&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:10:16.02+00:00",
                                                    "originKey": "2094dfc7-7e90-488b-ac27-b6f5227b1b33",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://ico.org.uk/media/for-organisations/documents/1235/definition-document-schools-in-england.pdf\" rel=\"noopener noreferrer\" target=\"_blank\">https://ico.org.uk/media/for-organisations/documents/1235/definition-document-schools-in-england.pdf</a>&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19fe9d98-f551-4be2-bf73-ab2e6d1ba880",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "917defcd-f81f-4675-b77b-c3eb3dc9bedf",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "99b32616-e96b-4eda-98d1-482f094ecb74",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed4d0644-f383-435f-82ac-ea9034f76dd6",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "df0a68ac-1f1f-4341-9e49-5c50c87342c7",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ea6005d8-c6ae-4b1d-82fa-025b7139f8c9",
                                                            "requirementId": "f86cf831-ac31-45f6-b0ec-751e46b64a85",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d622429d-d08d-46e2-a868-fa4959d42bec",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2130,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                    "name": "Does the school maintain a data asset register?",
                                                    "descriptionHtml": "<p>A data asset register allows schools to record all of their data assets, alongside other information regarding processing and data security. It can help schools to keep track of data assets and identify any risks to the security of the data they hold. These risks must be assessed, and an action plan should be developed to eliminate or reduce them.</p><p><br></p><p>You may wish to provide a link to your school's data asset register.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:23:45.447+00:00",
                                                    "originKey": "f27a4cd9-dc2e-4ee2-8c34-fd7fd8b14545",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "379660cc-a419-4219-a760-9e3e00e2f972",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6392e289-c662-4d61-8faf-9a5987dc9c2c",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b17b19ab-894c-4c81-ba29-4870907d759e",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b2a504c4-9a2d-45e4-af96-8acf41d6c40b",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "34b8c6b9-4f3f-4323-afcf-6937d29232dd",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e63b2473-23cf-459b-bb69-b699122ff1df",
                                                            "requirementId": "cb3e5ccd-7c92-4a30-82c8-68dc38a68655",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "77c680a7-1c42-46d7-88ea-c8fad95c86ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2133,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                    "name": "Does the school have a Records Management Policy?",
                                                    "descriptionHtml": "<p>Personal data should be kept for no longer than is necessary for the purposes for which the data is processed. It is important to put policies in place, as well as technical and organisational measures, to adequately prove that the school complies with the UK GDPR’s ‘storage limitation’ principle.</p><p><br></p><p>You may wish to upload a copy of your school's policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:35:01.876+00:00",
                                                    "originKey": "c5b609ce-a4a6-43ce-b768-a021af89bc72",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 18,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6ec68a09-271a-454d-b096-49f2bcdeab7f",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "72dae958-8673-46d7-9b9c-f7df904b06ba",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "88a78f7f-e4f8-4aea-b9bf-cd3f487fa1d3",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a2fc25c5-6469-4f30-82d9-64f0a7f4658c",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ba8bb1ce-b808-4e88-bda0-f3443810f44d",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cc5198be-bd07-468e-a7cd-d84ae181b621",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c57993f5-57e8-432a-8c5a-738a820e1d2f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2138,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                    "name": "Does the DSL understand their responsibilities with regards to sharing information and data for safeguarding purposes?",
                                                    "descriptionHtml": "<p>Data protection legislation does not prevent, or limit, the sharing (or withholding) of information for the purposes of keeping children safe. When DSLs are considering whether to share safeguarding information, it is considered best practice to record who they are sharing that information with and for what reason.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:53:18.908+00:00",
                                                    "originKey": "ed9739c5-75b2-4a89-9164-55611b40319b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "015dab0f-3e0a-4785-a5e9-f3778ee355e0",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1a2389e6-2c5d-456b-aba1-e925df7b88d8",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "988bc7ca-5d95-45c1-bdf6-41f455c82d05",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3da42a9-4ee0-4f53-8d47-610efa0b4a98",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "73d699c9-6a98-4e21-ac1e-35330a150710",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b3c491dc-b121-468b-a00e-fc7ad85b4f46",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d43f599a-5153-4831-af8c-0f0bb6d5d576",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2141,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                    "name": "Does the school maintain an up-to-date disposal of records log?",
                                                    "descriptionHtml": "<p>Schools should keep logs of all records that are due to be disposed of. This record can be useful when dealing with FOI requests and SARs.</p><p><br></p><p>You may wish to provide evidence on the existence of a disposal of records log.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T10:06:47.191+00:00",
                                                    "originKey": "a99cec6e-21a6-4c3e-8feb-af4ed2ef053f",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/disposal-of-records-log/5591</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "92e2fbb3-5ab5-49b3-a928-6f7a93b98883",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dfeaeb68-1ba4-48bb-b21e-bc3b0539b022",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fbc83797-f269-4202-b70c-af0a236f50b0",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fc31ab43-6fe3-4f05-a705-47df27fde325",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8b197279-655b-4ffa-9b6f-d714879ec533",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ec0bb0d6-a17a-4ec0-879d-f2e03f18f610",
                                                            "requirementId": "b4c9c1fc-f99a-4675-84cd-5c6697eac0c2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "22fffa1c-7434-46d6-a5f5-824d28752fbf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2147,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                    "name": "Has your school published its admissions arrangements?",
                                                    "descriptionHtml": "<p>If your LA determines your's school's admissions process, e.g. most community and voluntary-controlled schools, it is not a statutory requirement to publish your school's admissions information; however, it is strongly recommended. You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:23:47.314+00:00",
                                                    "originKey": "28af7837-d04e-4ad6-951b-e179259bf5aa",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>School Admissions Code, School Admissions Appeals Code</p>",
                                                    "policyManagerId": 9,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "51422e26-f479-4229-8cae-1dc93ded22f6",
                                                            "requirementId": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "af93906a-edc6-4481-b18a-3e54a22fc378",
                                                            "requirementId": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea47cff5-92e7-434e-b505-296ec10f595d",
                                                            "requirementId": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "686f95e7-9f46-486b-abc4-bb4709b6c3a7",
                                                            "requirementId": "3fbb4c4d-2db9-4fb6-bc8c-9a775fdc17d0",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "52e15338-30de-409d-aa62-e34ae5f738af",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0a5fe073-08a5-4103-a0f8-04ee3e43352c",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2148,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0a5fe073-08a5-4103-a0f8-04ee3e43352c",
                                                    "name": "Has your school published its admissions arrangements?",
                                                    "descriptionHtml": "<p>16-19 academies, FE colleges and sixth-form colleges are strongly recommended to publish details of their admission arrangements a year before the offer year. This should include the following details:</p><p><br></p><ul><li>Planned open days</li><li>How to apply for a place</li><li>Whether the setting gives priority to admissions from pupils at certain schools</li></ul><p> </p><p> You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:25:18.522+00:00",
                                                    "originKey": "65a7e777-c5c7-404f-b3d6-0640a85df9e4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> School Admissions Code, School Admissions Appeals Code</p>",
                                                    "policyManagerId": 9,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "d68c4429-d37a-4c1e-8600-2dfa56b6494f",
                                                            "requirementId": "0a5fe073-08a5-4103-a0f8-04ee3e43352c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "168586a3-e2ac-4a6c-a0b3-48a45c6aa5d4",
                                                            "requirementId": "0a5fe073-08a5-4103-a0f8-04ee3e43352c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8420da03-7976-4b44-8a4c-3f77d32e9656",
                                                            "requirementId": "0a5fe073-08a5-4103-a0f8-04ee3e43352c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b32f12f2-24c5-45e0-a19c-580fdc644ddf",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "118b431f-48c5-4e6f-ab90-ea35365580e0",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2152,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "118b431f-48c5-4e6f-ab90-ea35365580e0",
                                                    "name": "Has your school published its KS4 pupil destinations information?",
                                                    "descriptionHtml": "<p>You are strongly recommended to publish the percentage of pupils staying in education or moving on to employment after KS4 on the school website.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:36:03.413+00:00",
                                                    "originKey": "bf2f0f41-a15a-444b-bee4-729e04c9c286",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b051abda-02cc-4f16-b3b6-173790ef6a78",
                                                            "requirementId": "118b431f-48c5-4e6f-ab90-ea35365580e0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "082a75ef-d89f-4741-b659-5e48f09899e7",
                                                            "requirementId": "118b431f-48c5-4e6f-ab90-ea35365580e0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "37955da2-be00-45b1-b727-c5d9e58fe3dc",
                                                            "requirementId": "118b431f-48c5-4e6f-ab90-ea35365580e0",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2d4699d5-b4ca-48c2-b312-7b4f57618889",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1a57a052-4062-4a8a-877d-c2264e8ec1da",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2153,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1a57a052-4062-4a8a-877d-c2264e8ec1da",
                                                    "name": "Has your school published its post-16 curriculum information?",
                                                    "descriptionHtml": "<p>If your school offers 16-19 education, you are recommended to publish the following information:</p><ul><li>The 16-19 qualifications on offer</li><li>How the school meets the 16-19 study programme requirements</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:38:02.417+00:00",
                                                    "originKey": "962999ac-e9ec-4626-8978-0f02f7c639a1",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b132811c-9811-4f11-8cf9-43f0611b07c5",
                                                            "requirementId": "1a57a052-4062-4a8a-877d-c2264e8ec1da",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f06f636f-0c9a-426a-886a-3eb6917017d2",
                                                            "requirementId": "1a57a052-4062-4a8a-877d-c2264e8ec1da",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f38865cb-756f-4997-aa84-29d81522e01b",
                                                            "requirementId": "1a57a052-4062-4a8a-877d-c2264e8ec1da",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "92e8bde1-510d-4efb-9b8c-cb581e62c09a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "16c92af3-e50c-4d85-bf09-91633d8d96fe",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2156,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "16c92af3-e50c-4d85-bf09-91633d8d96fe",
                                                    "name": "Has your school published its primary curriculum information?",
                                                    "descriptionHtml": "<p>You should publish:</p><ul><li>The content of the curriculum in each academic year.</li><li>The content of the RE curriculum, even if it is taught as part of other subjects or called something else.</li><li>The names of any phonics or reading schemes used in KS1.</li><li>How parents and members of the public can find out more about your school’s curriculum.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:43:10.771+00:00",
                                                    "originKey": "e07f0365-1ec7-4448-b797-6b607fa3f86c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "feb0ac58-bd3e-4e51-835f-9083e00cd062",
                                                            "requirementId": "16c92af3-e50c-4d85-bf09-91633d8d96fe",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0251ae10-60c4-42a5-8826-8ae3edc5e368",
                                                            "requirementId": "16c92af3-e50c-4d85-bf09-91633d8d96fe",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ba235447-3bbd-4a3e-922a-a1b7824f3221",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "74b73b0d-b9ed-459e-bbfb-c6a7743a4c28",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2160,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "74b73b0d-b9ed-459e-bbfb-c6a7743a4c28",
                                                    "name": "Has your school published its latest KS2 results?",
                                                    "descriptionHtml": "<p>You should publish your school's KS2 performance measures, which include:</p><ul><li>Progress scores in reading, writing and maths.</li><li>The percentage of pupils who achieved at least the expected standard in reading, writing and maths.</li><li>The percentage of pupils who achieved at a higher standard in reading, writing and maths.</li><li>The average ‘scaled scores’ in reading and maths.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:53:48.508+00:00",
                                                    "originKey": "58f0ce58-b130-44b7-a4c4-51287e59ef3a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b03db371-47ef-49ce-8aa2-7897caf38622",
                                                            "requirementId": "74b73b0d-b9ed-459e-bbfb-c6a7743a4c28",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "fdbfc94b-5229-4f47-ae9d-313c424b689e",
                                                            "requirementId": "74b73b0d-b9ed-459e-bbfb-c6a7743a4c28",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f57067e8-b990-4466-8239-fe061d5b9ca7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a604f9ec-328b-4241-a8d1-064d91b5c9df",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2161,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a604f9ec-328b-4241-a8d1-064d91b5c9df",
                                                    "name": "Has your school published its latest KS4 results?",
                                                    "descriptionHtml": "<p>Your school should publish its KS4 performance measures, which include:</p><p><br></p><ul><li>The progress 8 score.</li><li>The attainment 8 score.</li><li>The percentage of pupils achieving grade 5 or above in GCSE English and maths.</li><li>The English Baccalaureate average point score.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T09:55:14.519+00:00",
                                                    "originKey": "27207938-20c5-4ac1-bc3d-5c1bacb0ec51",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "f895fcc0-6c1e-4047-b220-1d19a81f58a8",
                                                            "requirementId": "a604f9ec-328b-4241-a8d1-064d91b5c9df",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0bb34baf-97de-4a6e-8ec0-1c94a9db2993",
                                                            "requirementId": "a604f9ec-328b-4241-a8d1-064d91b5c9df",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "47772007-5f48-4c2d-af01-fa88717e82fd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2173,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                    "name": "Has your school published information on its careers programme?",
                                                    "descriptionHtml": "<p>If your school teaches pupils in Year 8 and above, you should publish information about the school's careers programme on the school website.&nbsp;You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:35:04.349+00:00",
                                                    "originKey": "a3851be0-9014-4e94-a3a2-cfb6d309eb30",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41c3fc64-a482-4048-b700-8334ca862e81",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9447349d-eaf4-47b9-9b65-a6e81b8b7d77",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "092988d4-de4d-4fb5-9673-5ba73b1ff4c0",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8dd12064-351c-417c-9c27-382cd04f316d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2175,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                    "name": "Has your school published its Complaints Procedures Policy?",
                                                    "descriptionHtml": "<p>It is strongly recommended that academies, FE colleges and sixth-form colleges publish their Complaints Procedures Policy on their website unless it is specified as required by their funding agreements.</p><p> </p><p>You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:38:06.499+00:00",
                                                    "originKey": "24060a29-f2aa-4627-96d1-7f1408777b5c",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 12,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "094cadff-6f8f-4d5a-81ae-8516b6dd115f",
                                                            "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "19d21f27-ba55-430b-bd5c-22117403884d",
                                                            "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "633c8ca7-c38f-43c7-8c3c-f22f2a4c3a20",
                                                            "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "89a32717-ba68-4a76-bebf-61ee23a56904",
                                                            "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "bedf196d-cdab-47cc-9f63-15d5e78d6837",
                                                            "requirementId": "785b6eed-63b0-4e10-b6da-60dc86ecb738",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8024f3e8-eb94-43b5-be18-b02f95272080",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2176,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "01768095-4541-4acf-988a-37a6676519c8",
                                                    "name": "Has your school published its whistleblowing procedures?",
                                                    "descriptionHtml": "<p>Whistleblowing procedures must be in place in all academy trusts and you are strongly recommended to publish details of these procedures on your school website. You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:46:02.885+00:00",
                                                    "originKey": "3f83dc1d-3e36-4575-b832-bc13f672aae5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "053e16cd-51a0-453b-ba0d-66ad3fac5066",
                                                            "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "360f19b6-4562-4c56-b285-0c2b4b985f01",
                                                            "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3fc6c783-0663-4450-b523-3c3330e80770",
                                                            "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8bc3bf04-1ba3-4334-8404-1ba6c729da84",
                                                            "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e3a4b478-a7a1-469e-912f-ce760f05463a",
                                                            "requirementId": "01768095-4541-4acf-988a-37a6676519c8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4ed67e69-c6c4-4fe4-a41a-475327d1485c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2178,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                    "name": "Has your trust published its annual reports and accounts?",
                                                    "descriptionHtml": "<p>Academies are strongly recommended to publish information relating to their school’s annual reports and accounts on their school websites:</p><p><br></p><p>You can evidence this by linking to where this information has been published.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:50:35.565+00:00",
                                                    "originKey": "07a5a26f-f30c-4466-a0fc-df5b19fe2e88",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>ATH, DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b05ad49c-4c8d-4eb8-ab7b-8310feea495b",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ccde5963-715b-4ed4-b553-c1a802f6286d",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3bb71f0-30da-4e3e-9e52-31a8b2e20853",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f672d303-7efe-42ae-b427-3040c369858b",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d934c4b-f64c-4c6c-9270-60956e6d7bc3",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d17ec267-6bdb-4267-be31-000f4e206c09",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2184,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                    "name": "Has your school published its Charging and Remissions Policy?",
                                                    "descriptionHtml": "<p>Your school is strongly recommended to publish its Charging and Remissions Policy. It Is recommended this is published on the school website. You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T14:00:40.974+00:00",
                                                    "originKey": "05574464-a453-490b-b82f-c6d3c33ffa89",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 2,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "15e100ab-2fc4-4d98-9f17-31fb1fc743e7",
                                                            "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3ca79307-88b3-4c61-ae2b-919ff7dc827e",
                                                            "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e674e1b-d369-494b-9d7f-068c2d67fb3d",
                                                            "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ba2f2285-bd9d-4405-a4c0-4e6eda00d5be",
                                                            "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "fbdac11d-b0d0-4799-91f7-65de24f4efcb",
                                                            "requirementId": "0328b181-7b82-4255-a8a7-177b972a3773",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6939e552-7504-416a-8f15-faa6438ec484",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2185,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                    "name": "Has your school published its values and ethos?",
                                                    "descriptionHtml": "<p>It is not a statutory requirement for your school to publish a statement on the school’s values and ethos; however, the DfE strongly recommends it. You could attach a link to where this information is published as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T14:01:58.55+00:00",
                                                    "originKey": "96a8f91f-c934-411e-931f-8c1d3881387c",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p> What maintained schools must publish online</p><p> </p><p> What academies must publish online</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c4b1a8d-714d-49cd-b70e-24759763252e",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aeb8e17e-c6f6-48b0-9e84-98ff1a6cd04c",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c695f1e3-4eb4-499a-af08-d21488d0ee9e",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f7b29c8f-3994-47df-82ed-4cfc543cbfad",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "11977f06-dbfa-45a3-879c-11e602b4cdec",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dc4f8188-9267-4511-9ee2-12cd2f4e5175",
                                                            "requirementId": "3672ec37-28a9-454b-9d75-60fc22f02e7e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fa972bc2-2258-4314-955b-f1742f1c2de8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2208,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                    "name": "Does the school have an LAC Policy?",
                                                    "descriptionHtml": "<p>Schools need to have measures in place to ensure LAC and previously-LAC are supported. It is advisable to implement a policy setting out the appropriate support arrangements and any other relevant procedures.</p><p><br></p><p>You may wish to link to attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:28:17.974+00:00",
                                                    "originKey": "040e0ed7-b60f-4189-a4cd-63a3a5bf1e38",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 82,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2403c232-19b0-4dff-b8ad-df362151da59",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c60d1dd-c817-4fe3-8768-e566ea0f6119",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd0fdf4c-681a-4e0c-b06b-bcf844c33895",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6624381-32ad-420c-b1e8-650c3a0e82db",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3e8b4e4f-4eb5-4a0e-82a7-7f512bd5629d",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6e2b15f0-7702-4b70-8f89-5b2cb5ebfec0",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c31e457f-f1b3-48db-9046-6364e208d6b5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2231,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                    "name": "Are there effective policies and procedures in place to prevent child-on-child abuse?",
                                                    "descriptionHtml": "<p>Schools should have a clear policy and procedure with regard to child-on-child abuse and ensure that all staff members are aware of, and understand the importance of challenging, inappropriate behaviours between peers.</p><p><br></p><p>Child-on-child abuse can include, but is not limited to, bullying, abuse in personal peer relationships, physical abuse,</p><p>sexual violence, sexual harassment, causing someone to engage in sexual activity without consent, sharing nude and semi-nude images and upskirting.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:53:58.115+00:00",
                                                    "originKey": "d2f555a2-7dcf-447e-8fb6-ca259ececa3a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08ca3089-66e1-47dd-a60b-500ddfdee984",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "32330e6c-f442-419d-891f-3bacb19fca51",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "443c789d-e6cb-4ca1-921a-d7d63b2d7727",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "afeb9a4a-dcd5-4188-a246-9939ecfb48e1",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2bcc8bc8-f389-4350-85b8-a72dac1e5686",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d8a2f0ee-1135-43b6-90ee-786acdd2586e",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4d98b510-0c3b-4283-94bb-8f4a14f86107",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2234,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                    "name": "Has the school used records of previous safeguarding incidents and responses to develop and enhance its policies, procedures and wider safeguarding practices?",
                                                    "descriptionHtml": "<p>Schools should make sure they evaluate their response to any safeguarding concerns and/or incidents. The DSL and other relevant staff should consider the facts associated with all safeguarding concerns and determine whether any improvements can be made.</p><p><br></p><p>In terms of allegations against staff where the allegation is found to be substantiated, the LADO should review the circumstances of the case with the case manager to determine whether there are any improvements to be made to the school’s procedures to help prevent similar events in the future.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:22:37.601+00:00",
                                                    "originKey": "62f5e1c1-96f0-40ab-b6be-29bf053da2ef",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually or since previous inspection.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "486c4888-e1a7-408f-9767-1aba904914a4",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6566a872-e8b4-4600-957e-d18c01d4be00",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "945e98aa-1b2e-43ad-8dc1-2eb8f6fd300b",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acfdf7f4-6255-4f17-bad4-cc66d3c85362",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a9437531-bb9b-47be-8c2b-4874817fb907",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f0d4fcd6-a86f-4934-8a1c-9e266985351b",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a99c62bf-e61d-47e6-bf9f-8254b77ed29d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2236,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                    "name": "Do all staff have awareness of safeguarding issues that can put children at risk of harm, and what to do if they have a concern about a child’s welfare?",
                                                    "descriptionHtml": "<p>All staff should have an awareness of safeguarding issues that can put children at risk of harm. Behaviours linked to issues such as drug taking and or alcohol misuse, serious violence, deliberately missing education and consensual and non-consensual sharing of nude or semi-nude images can be signs that children are at risk. Schools should ensure that their staff members are well versed on the safeguarding issues as outlined in section one of ‘Keeping children safe in education’.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:26:33.263+00:00",
                                                    "originKey": "4dacb426-5af1-405d-aecf-c8cfaa9c35f5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p> KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "236b8e9c-6016-402f-81d1-e5ba684ea093",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "54f2f972-76b5-4e43-919a-05cfe1615c25",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b46a8218-d19d-465c-969e-adcc7c5093da",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fecb38ef-858a-4c61-8c3e-b7a18a67064c",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4dd659f5-bc9f-4ccf-a051-bfc75b75e86e",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "67caa6cd-fb5b-4cc9-82d6-d01d0dbe7926",
                                                            "requirementId": "bb130cf6-e111-416c-a7fe-ab33c52ee4b5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0e43ec96-1891-48bc-aa11-14455a63003a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2237,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                    "name": "Does the school have an Online Safety Policy?",
                                                    "descriptionHtml": "<p>‘Keeping children safe in education’ (KCSIE) stipulates that schools should have a clear policy on the use of mobile and smart technology which considers the ‘4Cs’ – content, contact, conduct and commerce – as outlined in the Online safety subsection of KCSIE.</p><p><br></p><p>Schools should carefully consider how they will manage pupils’ engagement in harmful content, online harassment and sharing indecent images.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:28:12.663+00:00",
                                                    "originKey": "7d3021ed-30cf-443a-b6fc-4a53d9017c41",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "32038aac-708d-455f-973f-2ecc3fb02292",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dbe912b-6fd9-40e4-b1eb-e2ad8541f2af",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e430b4f-68e6-4084-b87e-4b40844b5fce",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d19b7baa-94ee-4aa4-a508-d4a155962a99",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "67813d2f-a22f-4949-b7fc-31c405af2c2c",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "828b7768-870b-4515-891e-7c052d58a8fe",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b693bbd9-c36c-4a8f-9b4b-c4e45d151743",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2238,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                    "name": "Has the governing board ensured there are appropriate filters and monitoring in place for pupils’ internet use that are appropriate to the relevant age range?",
                                                    "descriptionHtml": "<p>Schools should ensure their school has appropriate filters and monitoring systems in place whilst considering the age range and number of pupils and how often they access the ICT system and the proportionality of costs vs risks. The appropriateness of any filters and monitoring systems are a matter for individual schools and will be informed in part by the risk assessment required by the Prevent duty.</p><p><br></p><p>You may wish to attach or link to evidence that the appropriate systems are in place.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:30:06.226+00:00",
                                                    "originKey": "c162a831-2ba1-4118-b051-c4cb5acdb26a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41c396e0-a2c3-44fc-9048-89eeffc3d7ff",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c258d9a-ca76-4da4-9d87-ad493ded2c3c",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c39feb4-5cd7-4561-830c-046825490a8f",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e429272f-7600-4816-a8ae-c80659cb0e83",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7d1ad248-b134-44a1-b052-de3af1451b3e",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f1a000d6-0bbc-4591-a9d5-023de879f25a",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c9da314c-c930-4323-b339-341b01131154",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2248,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                    "name": "Has the school provided all staff with the appropriate training to be able to manage a safeguarding report?",
                                                    "descriptionHtml": "<p>All staff should be trained to manage a safeguarding report. Local policies and training should dictate exactly how reports should be managed.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:39:08.94+00:00",
                                                    "originKey": "b6a9da6a-5100-4461-a979-4f1c5aa6b682",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "20d75bc1-fd82-43d3-b4cb-147892f9192c",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "57e54f3e-841c-448a-8f5f-3841c71bac58",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6852a23a-f047-4925-960f-0f0bc9150d03",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7136763b-3879-4f19-98b3-f8bd70a03112",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6efd5f96-bde0-4504-b19a-2698b2dd3c42",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e623b4de-ac25-468b-b1d8-e47af4b0846c",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "685e95a2-1d6c-4b8d-8e6d-9bf19f1272e3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2249,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                    "name": "Does the school have procedures in place for reporting an illegal online safety incident?",
                                                    "descriptionHtml": "<p>If an online safety incident is illegal in nature, schools should collect all of the available evidence and ensure the incident is reported to the relevant authority.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:41:31.985+00:00",
                                                    "originKey": "89cd774f-6d75-4302-8294-82c6611c1996",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a1aae97-13e3-4a85-aeb5-e5e9946d91d5",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e4f1908-ee7e-43ab-8703-897e13b6f5f5",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f032f41-4a51-433b-ad23-9dd2625c5c5d",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cbb42a2e-db00-4a50-a8d7-2f73f5c50b58",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9209e73a-7243-4c22-98a2-09faa4f5a5fb",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a00f95b5-ec05-4c1e-959e-e03880728744",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "34bd9d14-2c7a-4bde-9b91-d0466b4b7698",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2254,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                    "name": "Has the governing board received safeguarding reports from the DSL?",
                                                    "descriptionHtml": "<p>The DSL should regularly update the governing board’s safeguarding link governor/trustee and provide safeguarding reports to the governing board. This should be done regularly, but at least termly.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T09:58:01.466+00:00",
                                                    "originKey": "10998c8d-43fc-44f1-ba70-b3842e2a16fa",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>Governance handbook</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement termly.</p>",
                                                    "requiresReviewInDefaultMonths": 4,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "043b3bda-a019-4315-af7b-5baba7b4a782",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2993162b-20c3-42d3-9e2e-87cffdd84d70",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d38ce782-d3f8-4483-8dad-fc259147c18e",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8b08580-cdc0-462d-b1e2-e8c5f3d1cbff",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2157e0bb-12a5-4a38-ab52-d7cf8ff50153",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "94519786-f29e-4991-83ec-88f92338f757",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6826cff7-4eb0-488f-be13-d5f263f48fe5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2269,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                    "name": "Has the school developed a curriculum that ensures pupils are taught how to stay safe from harm, both offline and online?",
                                                    "descriptionHtml": "<p>Schools should consider safeguarding whilst planning the curriculum, any teacher training, the role and responsibilities of the DSL and any parental engagement. Schools should ensure that pupils are taught about safeguarding, including online safety, and recognise that a one size fits all approach may not be appropriate for all children.&nbsp;</p><p><br></p><p>It is important to consider how online safety can be taught as part of a broad and balanced curriculum. This may include covering relevant issues through RSHE.&nbsp;</p><p><br></p><p>You may wish to attach or link to evidence that shows your school has considered how safeguarding should be taught to pupils.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:28:17.55+00:00",
                                                    "originKey": "ade0d2a0-f381-4db5-b9e8-fb69906c47a6",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c1884e5-250e-4123-a674-f7e2c66e8729",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b88a9e5f-0471-42c5-aaf3-94a9cf5649f8",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cb407473-cc0c-42dd-b559-81a1b7038c06",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5758a00-6c21-426d-a4bb-8b2424bd2ab0",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8a39caca-dc89-4752-b261-d3271b20b78f",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f3271b3e-297d-4d98-bee3-ffef21754b66",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "36f03d64-95b0-4a43-af30-48a62026177a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2271,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                    "name": "Has the governing board appointed a member of senior staff to be the DSL, and ensured this is reflected in their job description?",
                                                    "descriptionHtml": "<p>Every school should have a DSL who will provide support to staff to carry out their safeguarding duties and who will liaise closely with other services such as children’s social care.</p><p><br></p><p>The governing board should appoint someone who has the appropriate seniority and be a member of the SLT. The DSL should be given additional time, funding, training, resources and support to ensure they can carry out their role.</p><p><br></p><p>Schools can upload a copy of their DSL job description to evidence this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:35:00.302+00:00",
                                                    "originKey": "54b4844a-4442-422d-baa6-bbbc639b520f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3b15533f-57f5-4f07-8209-aac3d4d374be",
                                                            "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0611c17-cef6-44d7-9d9e-ffef1345b024",
                                                            "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f64025a2-5905-4c18-b62a-f16b880675ba",
                                                            "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "172d7015-c7fd-4f34-9c11-f01c6dd31243",
                                                            "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "77f95b86-e76e-440f-b5f4-a1f9f2835002",
                                                            "requirementId": "f797cc3e-3530-4929-a860-ac2431e40ab3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4dadc683-bec2-4e80-abd9-32fa942b9a3c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2272,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                    "name": "Has the governing board or proprietor appointed at least one deputy DSL, and ensured this is reflected in their job description?",
                                                    "descriptionHtml": "<p>It is a matter for individual schools as to whether they choose to have one or more deputy DSL. Deputies should be trained to the same standard as the DSL and the role should be explicit in their job description. Whilst activities of the DSL can be delegated to appropriately trained deputies, the ultimate lead responsibility for child protection remains with the DSL and this lead responsibility should not be delegated.</p><p><br></p><p>You may wish to attach or link to any evidence confirming that a deputy DSL is in place.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:36:14.393+00:00",
                                                    "originKey": "045926c6-bfd5-4e0d-967e-7b7dde8a726f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5a06f4d0-2b94-4ddd-8f50-9a2840add28e",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8c2cc9b-9906-424c-a59c-623b1968ded9",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b482d1cc-b60a-4408-aff3-8e1a4dc7195f",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "becc9651-4c7e-4eba-bb50-1b758115030a",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0caf014a-6740-4771-bb20-bde8061a2e8b",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3661542f-98bb-4e56-9403-991769fa22e2",
                                                            "requirementId": "20fbb0de-b03f-412e-b32b-79a208f44986",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "29e5812f-6bca-4562-83c7-52116096cc13",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2273,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                    "name": "Has the DSL and any deputies undergone training for conducting their roles and is this refreshed every two years?",
                                                    "descriptionHtml": "<p>The DSL and any deputies should undergo training to provide them with the knowledge and skills required to carry out the role. The training should be updated every two years. Evidence such as recent training certificates can be uploaded for this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:36:45.504+00:00",
                                                    "originKey": "6a3a3407-aeb4-4f88-8c2e-cbf1ab42842a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "145736c5-47ce-4f80-9821-71ef4999c5e8",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3faa61fe-e22e-4336-aca3-4947c6e141c7",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4754582f-abca-43cf-8116-10c560019c42",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ac99539f-e1e2-4f29-9376-5a0ae32bb59b",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4b226029-0881-4067-8a4e-ab6bdb2cbd9e",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4c4cfce0-5762-4100-94fd-459940967f5e",
                                                            "requirementId": "1082ad59-64c5-42a9-9ddd-cacd2e4681bd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8c4ff581-53ee-4776-8d59-51a9ba3f7ba7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2274,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                    "name": "Do staff receive regular safeguarding and child protection updates as required, and at least annually?",
                                                    "descriptionHtml": "<p>In addition to regularly updated safeguarding and child protection training, all staff should receive regular safeguarding and child protection updates, e.g. via email, e-bulletins and staff meetings. Updates should be provided as required, and at least annually, to provide them with relevant skills and knowledge to safeguard children effectively. Schools can upload their Child Protection and Safeguarding Policy as evidence for this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:37:39.431+00:00",
                                                    "originKey": "e6cf14f1-2c1b-45f4-b95f-5dad9810ec35",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "16d64b50-59f8-454c-8cbd-ae2f6ca00a4b",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45e7a95a-9514-4a73-97e2-8c7f2a9d0e21",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a004bb1a-8748-4360-b6e7-a64682f8b117",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ada417ca-9a6e-4509-9c6c-5edbd1284139",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0b063e40-cdd3-4083-91f9-7cc1eb91d040",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "5cb6e57e-d540-4fb4-8110-5dfedf524c1b",
                                                            "requirementId": "3af4af6d-eb8a-44dc-9cba-789aee2d932e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "571ca1e9-5ee9-4eb5-9801-ddeed01d34fd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2275,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                    "name": "Have all members of staff read either part one or annex A of ‘Keeping children safe in education’ (KCSIE)?",
                                                    "descriptionHtml": "<p>Governing boards and proprietors should ensure that those staff who work directly with children read at least part one of KCSIE. Governing boards and proprietors, working with their SLTs and DSLs, should ensure that those staff who do not work directly with children read either part one or Annex A (a condensed version of part one) of KCSIE.&nbsp;</p><p><br></p><p>This is entirely a matter for the school and should be based on their assessment of which guidance will be most effective for staff to safeguard and promote the welfare of children.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:38:52.07+00:00",
                                                    "originKey": "d9b6b762-f57e-4b3a-91f6-53afa551d393",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "c5e2d455-59a2-47cb-8d95-ac9b5b09d11a",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "defa2e4f-d5b1-48b4-a238-fd260d180230",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eee06f13-1361-4345-b89f-9371ad5e9724",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f860314c-4518-43a1-9311-2bd490e96eb7",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "569bc76d-b5df-4039-80e6-40266916ee8f",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "598e0466-0976-4c47-95d5-ed5ce7a2d09f",
                                                            "requirementId": "50d0c052-1393-4743-9aac-8641b429f24b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "98b2d394-27af-4704-a4c1-c83c0bb6caec",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2276,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                    "name": "Have all governors and members of the SLT read ‘Keeping children safe in education’ (KCSIE) in full?",
                                                    "descriptionHtml": "<p>The most recent version of KCSIE should be read and followed by governing boards of all schools, SLTs, proprietors of independent schools and management committees of PRUs.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:41:05.807+00:00",
                                                    "originKey": "0a54bee7-7c8c-4fb0-a244-0eab8e84193e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5920779b-e7d9-43d1-8ebb-eb2bc8e765d4",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "617b30f8-e31d-482b-8ef4-cdb391f4c48e",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "68ac5e33-74ef-4e1a-be0d-28dbd632d177",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "68df2625-55f6-4db2-9012-5e1aa10816b6",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "59391ee1-a559-4fc2-a4a9-e47841cf261c",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ad75c6a2-e5d8-41bd-a304-7e5c414dc8f4",
                                                            "requirementId": "1044efe3-e7f9-430f-936a-e9497d046f4b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fa576739-4a3e-498a-b293-1f205d9b1686",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2277,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                    "name": "Are safer recruitment procedures in place that are compliant with statutory requirements outlined in part three of ‘Keeping children safe in education’ (KCSIE)?",
                                                    "descriptionHtml": "<p>Part three of KCSIE outlines the legal requirements for what schools must do, what they should do and what is considered best practice with regard to recruitment, employment checks and legal reporting duties on employers. Schools should align their recruitment processes with this section of the statutory guidance to ensure that their recruitment processes are compliant and effectively safeguard pupils. Schools can evidence these processes in their Safer Recruitment Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:41:47.669+00:00",
                                                    "originKey": "af4657d1-47e3-4149-af2e-a73d6c9c2523",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "809efb0d-32b4-4690-a1f7-bb0104463407",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "901a346e-4159-4c3e-99ed-6b6fac63bac4",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a3dc2121-2843-4193-9e2a-ba4f124d16e9",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d93a222e-227b-4d5b-924f-61329ddc039b",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0cf43685-7aac-45f0-a1ea-f2e71f212fac",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a0a479a5-0751-459a-96da-7fe21a725d11",
                                                            "requirementId": "a3aa1566-a13b-45d2-b045-bbc5a5297926",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3407e047-1397-4a3b-b7d7-c1be1bc64ab5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2278,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                    "name": "Has the governing board or proprietor ensured that all staff and governors or trustees involved in recruitment have completed safer recruitment training?",
                                                    "descriptionHtml": "<p>Governing boards and proprietors should ensure that those involved in the recruitment and employment of staff to work with children have received appropriate safer recruitment training. This should cover, at a minimum, the content of part three of ‘Keeping children safe in education’ (KCSIE).</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:47:12.453+00:00",
                                                    "originKey": "b85a1754-e462-4762-8a25-5bff7c0ef7eb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">The School Staffing (England) Regulations 2009 </span></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "14ec5cdb-3f8c-4fbe-9e87-bb24ae881f7b",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e8aaa7f-fdbc-4664-aa4a-2fb2143b0909",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bc18a6e6-7a5c-4017-aaa7-77289c63ff6d",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed6d8344-340a-49e0-baa9-18139100407e",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "03fe0f83-5fb9-4282-864e-06aba97a22ab",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "11b24544-90ed-4d0e-99c9-b42643d82341",
                                                            "requirementId": "526b8a0b-048f-4646-b697-47c8784dce29",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "963a9be1-ea24-45b2-b3a3-a58908057377",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2280,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                    "name": "Are all safeguarding incidents and allegations, and all actions taken in response, recorded comprehensively?",
                                                    "descriptionHtml": "<p>All concerns, discussions and decisions made, and the reasons for those decisions, should be recorded in writing. Information should be kept confidential and stored securely. The DfE states that it is good practice to keep concerns and referrals in a separate child protection file for each child.</p><p><br></p><p>Records should include a clear and comprehensive summary of the concern, details of how the concern was followed up and resolved and a note of any action taken, decisions reached and the outcome.</p><p><br></p><p>You may wish to attach or link to evidence that there are processes in place to record all concerns and incidents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:49:39.492+00:00",
                                                    "originKey": "8522a2dd-e69e-4110-a17d-b7c694b0f2d2",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "49504d8f-25fb-40c0-a4a4-304b2a03e1ee",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6e2d3813-0189-47a1-ad08-c6b1f65a3578",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b96e05fb-40fa-4ed9-bd47-d944b2604970",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e8590f3c-d89d-42e5-8a8b-fa846f9eb2d7",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1b28bdc2-bc23-4197-a460-efb9ce2b5f9e",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cfb42071-8afb-4261-a815-b40921b6e675",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "38236c53-a2bb-4f62-9df3-71a8cc2c4df2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2283,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                    "name": "Are there clear processes and procedures in place for handling and referring safeguarding incidents and concerns that meet the guidelines set out in ‘Keeping children safe in education’?",
                                                    "descriptionHtml": "<p>Your referral procedures must meet the standards established in KCSIE. This includes reporting to the DSL, the LA or the LA’s designated person for safeguarding. Written records of referrals should be organised and updated in a timely manner.&nbsp;</p><p><br></p><p>You could upload or link to evidence of where these procedures are outlined.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:53:18.26+00:00",
                                                    "originKey": "109e1e3f-a636-4666-99e5-da35e03a018c",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "36b1cb70-b493-4903-9de2-8fcaa09314ce",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3de9d634-2078-4a41-ab7f-f25ecc6ab0b3",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5aa64881-00f4-48f8-b1c5-7164b8ebbdf0",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c6b63730-ad97-4c61-9440-ae0b7bc20d17",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6c60dc8a-4239-4637-9c46-a845d72b35f0",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "803f051f-5ade-46f9-8624-998e0f1a9a6c",
                                                            "requirementId": "2e006493-6cd4-4859-8747-7549d1a166bb",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ae25d80c-1102-4f5b-b3ee-913c853faac7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2295,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                    "name": "Does the school have a behaviour principles written statement?",
                                                    "descriptionHtml": "<p>The behaviour principles written statement can be met as a Pupil Code of Conduct, this should outline the school’s expectations from pupils in relation to attendance, punctuality, uniform and behaviour. </p><p> </p><p>Although this requirement is not mandatory for academies, the DfE strongly advises academies to have this document in place. </p><p> </p><p>You may wish to link to or attach your school’s behaviour principles written statement.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T14:21:00.599+00:00",
                                                    "originKey": "7320228f-f254-4018-90bb-2da8de4c8261",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p> DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "450d4669-574c-4685-b75a-2922ed2f28ad",
                                                            "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dfe10a7-42ae-45e8-97a1-3c8a142124dc",
                                                            "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6178d5c2-477e-4270-a5d2-cb6ee6a7c7a6",
                                                            "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ca5f7341-a1ae-43fc-b178-a91f295853af",
                                                            "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "31b83a25-63dd-4b69-829a-d960c4bf8297",
                                                            "requirementId": "5661ff97-0f69-4d96-bf5d-6a45e44981e9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5e07da50-c4bd-424e-b202-d637cab4fffe",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2296,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                    "name": "Does the trust have a Trustees' Allowances Policy?",
                                                    "descriptionHtml": "<p>Academy trusts should have a written expenses policy, setting out what is recoverable as an expense and what is not, and they should ensure the policy is clearly understood by all the trustees.</p><p> </p><p>Although this requirement is not mandatory for academies, the DfE strongly advises academies to have this document in place and academies need to follow guidance on expenses from the Charity Commission. </p><p> </p><p>You may wish to link to or attach your school's Trustee Allowances Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T14:24:34.228+00:00",
                                                    "originKey": "f1bf949b-74b1-479c-81af-1a938bf63160",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 14,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3b14011f-a12b-439a-8d41-cfa0b3887029",
                                                            "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "69a45123-34cc-47c6-95be-b7eb16574043",
                                                            "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cca4f531-de2a-42c4-a0f1-b1aa47c13c32",
                                                            "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d12fb805-f631-48b0-adee-4642e0db65f8",
                                                            "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "cb920087-7c8d-4e71-81c5-2a3b8e4e9d74",
                                                            "requirementId": "f1bebfc8-bb6c-472e-aefb-c5bd1feed94c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3221c714-6c41-4d64-9b2d-1e6ad0c77692",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2301,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                    "name": "Does your school have risk assessments in place that cover coronavirus?",
                                                    "descriptionHtml": "<p>As of 1 April 2022, employers are no longer required to explicitly consider coronavirus (COVID-19) in their risk assessments. Instead, employers have been advised to take responsibility for implementing control measures that are appropriate for their circumstances. In line with the DfE’s ‘Health and safety: responsibilities and duties for schools’ guidance, schools are required to have risk assessments covering risks to the health and safety of employees and other persons, including pupils who are not employees of the school. This means that schools may still need to cover coronavirus-related risks in their risk assessments if a school assesses it to be a risk to staff and pupils.</p><p><br></p><p>You may wish to link to or attach your school’s relevant risk assessments.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-22T13:36:03.954+00:00",
                                                    "originKey": "f87cb359-af09-497c-96db-b2061a62c540",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE,  Health and safety: responsibilities and duties for schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0e951117-bec1-4a6a-81d9-f7e4496bb7ec",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "48287cb9-1bf1-41bd-8160-73806ab7cda5",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ae1b8de-1820-43ee-8345-d35e0fc432b0",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c3101f6-4a79-4f3f-9a43-72bc4411bcca",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1fe32b62-4283-4c86-8b9c-2324c842ddb4",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c5405c7b-d290-4df5-ba1c-b80922268996",
                                                            "requirementId": "700eea74-24ce-44b9-ad67-4cfc9a2b8ea1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "11a71fc4-a9c1-43bb-a394-f666b2adfa56",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2314,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                    "name": "Does the school have an Emergency Plan?",
                                                    "descriptionHtml": "<p>All schools should have emergency plans in place detailing what you would do and how you would respond if you needed to take any temporary actions in the event of an emergency. Emergency plans should be generic enough to cover a range of incidents occurring during, and outside, normal working hours, including weekends and holidays.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school’s Emergency Plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:10:34.078+00:00",
                                                    "originKey": "918b75ed-ebb2-45ac-96e4-20dfc9447115",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'Emergency planning and response for education, childcare, and children’s social care settings'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 11,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4472e6ca-8663-4236-99f5-31fdfaf89835",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "586258f7-3fa8-4bcc-a719-7cef85673ccd",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c3c0c486-6558-4734-b6ba-f0f96e035bc0",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dbbf487f-7771-4e21-9ef2-219592de1351",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "83eddd73-8d4e-4d53-ac3b-47be61ccb5bb",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a246847d-0f73-4737-be2f-6df34612c151",
                                                            "requirementId": "1d5dfb8d-28f9-4ccc-9b2a-874cd346c8d6",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "36e06269-1d8d-45a1-ad57-53689c23aeeb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2315,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                    "name": "Does the school have a School Security Policy and supporting procedures?",
                                                    "descriptionHtml": "<p>Schools should have a Security Policy in place to management and respond to security-related incidents.&nbsp;The policy should form part of a suite of policies to ensure the health, safety and wellbeing of pupils and staff.</p><p><br></p><p>You may wish to link to or attach your school’s Security Policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:08:37.722+00:00",
                                                    "originKey": "f0490349-c787-4213-8286-8e12af8ea7f5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'School and college security’, ‘Getting started on your security policy and plan’ <a href=\"https://www.gov.uk/government/publications/school-and-college-security\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/school-and-college-security</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1498c513-3671-4d55-969c-c516b3fb11ca",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64b6161c-04c9-49df-8242-4d012b0b85a6",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8458f2a5-0402-48cb-ac67-6aeed70f8cf0",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fce7a9e7-57a1-4f38-a4dc-2d855abc09b3",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "06909da4-992a-4b84-ac9e-f195a96dcd34",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "386368c8-dc09-4ead-b3a7-2a37993908cb",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c316e139-4b74-470f-beca-775ddd58c0e0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2316,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                    "name": "Does the school have a School Security Policy and supporting procedures?",
                                                    "descriptionHtml": "<p>Schools should have a Security Policy in place to management and respond to security-related incidents.&nbsp;The policy should form part of a suite of policies to ensure the health, safety and wellbeing of pupils and staff.</p><p><br></p><p>You may wish to link to or attach your school’s Security Policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-08-26T12:08:37.722+00:00",
                                                    "originKey": "f0490349-c787-4213-8286-8e12af8ea7f5",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'School and college security’, ‘Getting started on your security policy and plan’ <a href=\"https://www.gov.uk/government/publications/school-and-college-security\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/school-and-college-security</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1498c513-3671-4d55-969c-c516b3fb11ca",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64b6161c-04c9-49df-8242-4d012b0b85a6",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8458f2a5-0402-48cb-ac67-6aeed70f8cf0",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fce7a9e7-57a1-4f38-a4dc-2d855abc09b3",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "06909da4-992a-4b84-ac9e-f195a96dcd34",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "386368c8-dc09-4ead-b3a7-2a37993908cb",
                                                            "requirementId": "6278ff57-b518-4db7-afe6-9c731e4fb972",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f0cb8c6c-6862-42bc-9eb3-90f46c2031c9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2317,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                    "name": "Does your school have an Asset Management Plan?",
                                                    "descriptionHtml": "<p>Schools should have an Asset Management Plan. This is the working document which will inform a school’s operational day-to-date activities on the estate. The plan should:</p><p><br></p><ul><li>Include data and information about the estate.</li><li>Set out the work necessary to maintain the estate.</li><li>Set out the actions needed to achieve the estate strategy.</li><li>Cover the short to medium term (up to two years).</li><li>Be signed off by your governing board.</li><li>Be aligned to the estate vision and strategy.</li></ul><p><br></p><p>You may wish to link to or attach your school’s plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T10:35:17.76+00:00",
                                                    "originKey": "cba9f663-2e66-4bfe-93e3-e0fa87dc8ddc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE, GEMS</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "90ff0a30-ebe7-4a51-abee-23d4c5a90065",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d67476f6-3112-41c6-88f4-58f791afe818",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f22e637a-bb04-4617-a093-7871c8ea36a0",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fa74c278-da35-4ff5-9f6d-c9737ca60fac",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "74c4873f-12f9-4c13-973c-6fd78a8b31ac",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "96cdccac-2db6-482c-96f8-019bb9c9c903",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "53453bbe-ed55-4779-a45b-597bbb1359e0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2318,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                    "name": "Does your school have an Asset Management Plan?",
                                                    "descriptionHtml": "<p>Schools should have an Asset Management Plan. This is the working document which will inform a school’s operational day-to-date activities on the estate. The plan should:</p><p><br></p><ul><li>Include data and information about the estate.</li><li>Set out the work necessary to maintain the estate.</li><li>Set out the actions needed to achieve the estate strategy.</li><li>Cover the short to medium term (up to two years).</li><li>Be signed off by your governing board.</li><li>Be aligned to the estate vision and strategy.</li></ul><p><br></p><p>You may wish to link to or attach your school’s plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T10:35:17.76+00:00",
                                                    "originKey": "cba9f663-2e66-4bfe-93e3-e0fa87dc8ddc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE, GEMS</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "90ff0a30-ebe7-4a51-abee-23d4c5a90065",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d67476f6-3112-41c6-88f4-58f791afe818",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f22e637a-bb04-4617-a093-7871c8ea36a0",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fa74c278-da35-4ff5-9f6d-c9737ca60fac",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "74c4873f-12f9-4c13-973c-6fd78a8b31ac",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "96cdccac-2db6-482c-96f8-019bb9c9c903",
                                                            "requirementId": "0bebbf53-c3a6-44f5-ba5f-840e8cc18066",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4ce4086e-ea1a-440f-a46b-d335ba6a5599",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2331,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                    "name": "Does your school regularly conduct staff wellbeing surveys in order to learn about stressors present in the workplace?",
                                                    "descriptionHtml": "<p>Your school should regularly conduct staff wellbeing surveys in order to learn about stressors present in the workplace and work to act on the results of the survey. You may wish to upload a blank copy of a recent survey you sent out to staff.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:24:03.424+00:00",
                                                    "originKey": "c02f20f5-007c-4208-a28c-a822ec37ca9d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7215dc77-776b-4df2-bfae-7f33dbd558a2",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8815ff5c-fea2-4254-96e5-59851de01177",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c5207d9-dbc7-418a-a664-ea75a288663e",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e899862-2201-425d-8e67-0387b516de7d",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1b8b67fa-40a2-4197-93e9-a2a48ce1ac0d",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "56bc9703-f6d9-4388-966f-d6e8e8d63bd5",
                                                            "requirementId": "2f507d14-0a09-4932-9413-518ab9ae5900",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "28a6e1d5-6a20-47f1-b8d9-c2ed6bd469fe",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2338,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                    "name": "Does the school have an up-to-date Data and Cyber-security Breach Prevention and Management Plan?",
                                                    "descriptionHtml": "<p>It is crucial that schools develop robust processes to ensure data is protected from unauthorised access. A comprehensive plan can help schools to uphold high standards of security, take suitable precautions, and have systems and procedures in place to support this.</p><p><br></p><p>You may wish to upload a copy of your plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:21:57.746+00:00",
                                                    "originKey": "bd1044d7-7672-41e2-8412-17763c19b9fc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.itgovernance.co.uk/blog/gdpr-compliance-and-information-security-reducing-data-breach</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "33620608-06fe-4104-bb1a-0a27e29a111c",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4a5bb485-6deb-4086-8492-5690069bf1a9",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81f1e8f5-9ece-47f0-910b-88427357118a",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a3e196d7-1e2a-44d1-8859-6b0d6dc13471",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9ce87fb7-c20c-4b0b-a741-b89db4cdccaa",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cc3064c1-b60f-4d54-ad0c-559bfd80683c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2339,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                    "name": "Does the school have an up-to-date Data and Cyber-security Breach Prevention and Management Plan?",
                                                    "descriptionHtml": "<p>It is crucial that schools develop robust processes to ensure data is protected from unauthorised access. A comprehensive plan can help schools to uphold high standards of security, take suitable precautions, and have systems and procedures in place to support this.</p><p><br></p><p>You may wish to upload a copy of your plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:21:57.746+00:00",
                                                    "originKey": "bd1044d7-7672-41e2-8412-17763c19b9fc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.itgovernance.co.uk/blog/gdpr-compliance-and-information-security-reducing-data-breach</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "33620608-06fe-4104-bb1a-0a27e29a111c",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4a5bb485-6deb-4086-8492-5690069bf1a9",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81f1e8f5-9ece-47f0-910b-88427357118a",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a3e196d7-1e2a-44d1-8859-6b0d6dc13471",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9ce87fb7-c20c-4b0b-a741-b89db4cdccaa",
                                                            "requirementId": "1d775968-a65c-4c91-9b81-4c3f11c8e1a9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "93299aa3-155b-4baf-9499-172d7982e67f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2340,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                    "name": "Does the school have a Records Management Policy?",
                                                    "descriptionHtml": "<p>Personal data should be kept for no longer than is necessary for the purposes for which the data is processed. It is important to put policies in place, as well as technical and organisational measures, to adequately prove that the school complies with the UK GDPR’s ‘storage limitation’ principle.</p><p><br></p><p>You may wish to upload a copy of your school's policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:35:01.876+00:00",
                                                    "originKey": "c5b609ce-a4a6-43ce-b768-a021af89bc72",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 18,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6ec68a09-271a-454d-b096-49f2bcdeab7f",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "72dae958-8673-46d7-9b9c-f7df904b06ba",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "88a78f7f-e4f8-4aea-b9bf-cd3f487fa1d3",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a2fc25c5-6469-4f30-82d9-64f0a7f4658c",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ba8bb1ce-b808-4e88-bda0-f3443810f44d",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cc5198be-bd07-468e-a7cd-d84ae181b621",
                                                            "requirementId": "6ac0f168-6db3-45a3-99d9-31b52748e176",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8ba15a77-2458-4114-b555-0e41b01af861",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2341,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                    "name": "Does the school have a Business Continuity Plan?",
                                                    "descriptionHtml": "<p>When planning for the inevitable, it is important that schools have clear procedures in place and, whilst most incidents can be handled in line with usual school procedures, there are some incidents which would cause a greater damage to the school and its community and require more definitive procedures. </p><p> </p><p>Having a Business Continuity Plan shows that you have considered potential risks to your school and have procedures in place to manage these risks and respond should an incident occur. </p><p> </p><p>You may wish to attach or link to your school’s plan.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T07:44:28.487+00:00",
                                                    "originKey": "6c569d88-8d8b-46d8-8cf7-51082d03f0bc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://www.theschoolbus.net/article/business-continuity-plan/790</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1fe32456-eb0a-422b-84de-d1a1075dc723",
                                                            "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "340b1e03-b786-4e62-892e-c6491db7756b",
                                                            "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "66e163ff-b58d-42fb-b9a2-737d739f114a",
                                                            "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c9d4943-af36-45e9-be77-178981f7a993",
                                                            "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7122a6fa-82f6-42e9-ab4d-3d424aa7c5da",
                                                            "requirementId": "3c69404b-9b0f-4b32-b363-1287d7800695",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "17398128-24ac-4c46-9069-317b47e83d36",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2342,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                    "name": "Does the school have a Staff Attendance and Absence Policy?",
                                                    "descriptionHtml": "<p>Regular attendance at work is an integral part of each employee’s contract of employment; however, it's important to recognise that employees will, on occasion, have genuine and acceptable reasons to be absent from work. </p><p> </p><p>It is advisable to have a policy in place to manage staff attendance and absence. </p><p> </p><p>You may wish to link to or attach your school’s relevant policy or procedure.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:14:12.23+00:00",
                                                    "originKey": "3e574fbf-0f43-4f56-b4d2-dde20f4df428",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://www.theschoolbus.net/article/staff-attendance-and-absence-policy/291</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "05c97c90-73a3-4029-beb9-187bc5d42d2a",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b61d34bb-e6c5-4254-b521-abbf7d11cc35",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3c0e51e-db12-4d6e-a7b6-32c6e909e397",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d9e08c63-652b-4978-90ff-ab8425539e3b",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "91012ba8-4769-4632-8708-f1b9f6c32c68",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e64f6e47-9466-4e5c-96d0-97ac00cbbe02",
                                                            "requirementId": "fc82a12d-bcf4-4082-94ca-d7f95f1ff953",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "860cee18-0e97-47db-8615-4dcfb7ad16ee",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2343,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                    "name": "Does the school have an Attendance and Absence Policy for pupils?",
                                                    "descriptionHtml": "<p>An effective whole school culture of high attendance is underpinned by clear expectations, procedures, and responsibilities. To ensure all leaders, staff, pupils, and parents understand these expectations, all schools are expected to have a clear, written school attendance policy based on the expectations set out in the DfE’s ‘Working together to improve school attendance’ guidance. </p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:15:31.771+00:00",
                                                    "originKey": "9a1c0780-2552-4c72-b6cf-74a54ceb9808",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE 'Working together to improve school attendance'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5578bdaf-c3df-48d4-b840-ac43cc80259f",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9ffc57e4-3148-4593-bf52-a0c1de37728d",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a5ade1b6-721b-442c-8dff-ea89ed3dc95b",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "de2169ec-9723-4e4d-af76-3b87d2140702",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a43be754-3932-4397-ab5e-539239e96360",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c1a01830-1885-48f7-ad3c-16c023d4f5fa",
                                                            "requirementId": "9a2c1a4e-7d97-4273-92c8-efce111f4da7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "eac866db-39f0-46a7-8181-10123c0ad5b1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2345,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                    "name": "Does the school have a Remote Education Policy?",
                                                    "descriptionHtml": "<p>It is important for schools to have remote education plans in place, in cases where it is not possible or contrary to government guidance for some or all pupils to attend face-to-face provision. Having a policy in place can help to ensure that high-quality education is delivered to pupils remotely and that pupils are kept safe while learning from home.</p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:20:00.339+00:00",
                                                    "originKey": "331eec0e-b599-4fac-afd1-f11996980855",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/remote-education-policy/7653&nbsp;&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0898f554-c164-4f95-ad8e-c879891c4689",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3d701744-499d-4b2c-ba0f-356dd6b83f5e",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3e6de9f0-cb9f-4dc7-a2f5-a12c00735965",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "590a68da-ef93-452b-acef-6fff9639dbb6",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1f7fb958-3f31-4fc7-aeaf-aab99df01b0e",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "2aa07a2a-4946-4e92-9896-2437e871d87c",
                                                            "requirementId": "d062fe6e-357e-4473-8e64-fa6eadee9784",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "92760eaf-d205-418d-92e0-13e82c91b2c5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2346,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                    "name": "Does the school have an Anti-Fraud and Corruption Policy?",
                                                    "descriptionHtml": "<p>All schools should be committed to operating with the highest ethical standards and to acting with integrity in all of their activities. It is advisable to have a policy in place setting out responsibilities regarding the prevention of fraud and corruption and how suspected and actual fraud will be managed.</p><p><br></p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:23:45.05+00:00",
                                                    "originKey": "bdd4b3fb-1649-4359-9d8d-c907a81eadb8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/antifraud-and-corruption-policy/3882</p><p>https://www.theschoolbus.net/article/academy-antifraud-and-corruption-policy/6799&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "161b0486-36a7-48ee-bb72-0d60b878d8e3",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b99d088-f554-468c-9ac9-2efd718800b7",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "86d51261-f23b-47cb-a6ac-a38719c5f465",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b215973c-51a6-47c2-8f28-4029f4a15703",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "34970e68-2b25-4ee6-90c5-dcc0343b1df2",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "64a17e61-2379-4440-83a2-f7dc22d9f8d3",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e5689982-3331-4db8-9662-a8b6f114e282",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2347,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                    "name": "Does the school have an Anti-Fraud and Corruption Policy?",
                                                    "descriptionHtml": "<p>All schools should be committed to operating with the highest ethical standards and to acting with integrity in all of their activities. It is advisable to have a policy in place setting out responsibilities regarding the prevention of fraud and corruption and how suspected and actual fraud will be managed.</p><p><br></p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:23:45.05+00:00",
                                                    "originKey": "bdd4b3fb-1649-4359-9d8d-c907a81eadb8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/antifraud-and-corruption-policy/3882</p><p>https://www.theschoolbus.net/article/academy-antifraud-and-corruption-policy/6799&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "161b0486-36a7-48ee-bb72-0d60b878d8e3",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4b99d088-f554-468c-9ac9-2efd718800b7",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "86d51261-f23b-47cb-a6ac-a38719c5f465",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b215973c-51a6-47c2-8f28-4029f4a15703",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "34970e68-2b25-4ee6-90c5-dcc0343b1df2",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "64a17e61-2379-4440-83a2-f7dc22d9f8d3",
                                                            "requirementId": "b18810a9-7581-4c30-ac99-ed7af38bb1cc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f37373c2-5d95-4462-a114-d31c33f46b92",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2348,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                    "name": "Does the school have a Conflicts of Interest Policy?",
                                                    "descriptionHtml": "<p>Members of the governing board and school staff have an obligation to act in the best interests of the school community to avoid situations where there may be a potential conflict of interest. It is advisable to have a policy in place setting out how conflicts of interest will be managed. </p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:26:29.129+00:00",
                                                    "originKey": "1b6dbddb-67e6-4972-984b-07c7e4f326d0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://www.theschoolbus.net/article/conflicts-of-interest-policy/2445&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3dd47841-611a-498c-ab23-72deb6d7035f",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acc68b1e-f3d6-4f03-b545-8e4097dc186c",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7d3039e-52b2-448e-a3c8-57796bf2e979",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "da88a81b-cf99-4c29-9140-3eeeaa9d4f90",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4cb285bb-1e3a-4427-9c66-3f855c5afb8c",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "be8d1790-8ef4-477b-904c-d097fc5b437d",
                                                            "requirementId": "51140886-2149-4ab9-b95c-2aaf441d552d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "aa88b755-179a-45c4-9855-e0fd7694797d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2349,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                    "name": "Does the trust have a policy covering risk management?",
                                                    "descriptionHtml": "<p>Guidance from the ESFA states that academy trusts should have a risk management policy defining their approach to risk management. This document should set out the framework the trust has adopted for risk management and should include the trust’s risk appetite, the processes for identifying, categorising, measuring risks and its strategy for treating risks. The policy should also include roles and responsibilities, monitoring, reporting and review procedures, and training arrangements to ensure effective risk management is embedded throughout the trust.</p><p> </p><p>While there is no specific guidance for maintained schools on having a risk management policy, it is advisable for maintained schools to have one in place. </p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:28:02.375+00:00",
                                                    "originKey": "ac40ddfb-b572-4db1-8a7e-830883b21d53",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/academy-trust-financial-management-good-practice-guides/academy-trust-risk-management&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0377a28a-31ba-46e4-a4ca-4ac38cd4a7ec",
                                                            "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3c07b44d-d3fe-48e7-a0f0-21788997a964",
                                                            "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "599a6069-1480-4e51-979d-49841fbae161",
                                                            "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "88fa00f0-3f41-45b7-97aa-620c4cabebe3",
                                                            "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "50a90ff2-5ab5-43ee-a538-ea2faf1365b2",
                                                            "requirementId": "1bfd683c-2c41-4f22-881a-79bc1c03c2a4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e4457977-cbce-4474-a22d-38e6f5622f4f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2351,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                    "name": "Does the school have a COSHH Policy?",
                                                    "descriptionHtml": "<p>The Control of Substances Hazardous to Health Regulations (COSHH), require employers to prevent their employees from being exposed to substances hazardous to health. Where exposure cannot be prevented, employers must ensure that the exposure is adequately controlled, through the use of risk assessments and appropriate control measures.</p><p><br></p><p>It is advisable to have a policy in place outlining the measures in place for handling and storing substances that are potentially hazardous to health.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:34:11.171+00:00",
                                                    "originKey": "117dedd5-c670-45eb-9755-26beb0ba5acc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/coshh-policy/2718</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0302e961-9650-405e-8471-9dab8640cdb0",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "446098c1-539f-4e19-9e11-bf1b4a3e8e22",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f32df24-346b-46bb-a88a-7fbe2526b5a6",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7776a2a-93be-483f-802c-179b7762d662",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1aa47325-c5f0-40e4-87c2-eeb0cb57a2db",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "22b88ab3-5c80-4cb1-994a-a09081ac5f38",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3b26d494-a85f-47cd-81ea-6137de48c2da",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2352,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                    "name": "Does the school have a COSHH Policy?",
                                                    "descriptionHtml": "<p>The Control of Substances Hazardous to Health Regulations (COSHH), require employers to prevent their employees from being exposed to substances hazardous to health. Where exposure cannot be prevented, employers must ensure that the exposure is adequately controlled, through the use of risk assessments and appropriate control measures.</p><p><br></p><p>It is advisable to have a policy in place outlining the measures in place for handling and storing substances that are potentially hazardous to health.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:34:11.171+00:00",
                                                    "originKey": "117dedd5-c670-45eb-9755-26beb0ba5acc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/coshh-policy/2718</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0302e961-9650-405e-8471-9dab8640cdb0",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "446098c1-539f-4e19-9e11-bf1b4a3e8e22",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f32df24-346b-46bb-a88a-7fbe2526b5a6",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7776a2a-93be-483f-802c-179b7762d662",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1aa47325-c5f0-40e4-87c2-eeb0cb57a2db",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "22b88ab3-5c80-4cb1-994a-a09081ac5f38",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5da7f2e2-e06c-4e84-9412-5de89d1f6b55",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2353,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                    "name": "Does the school have a COSHH Policy?",
                                                    "descriptionHtml": "<p>The Control of Substances Hazardous to Health Regulations (COSHH), require employers to prevent their employees from being exposed to substances hazardous to health. Where exposure cannot be prevented, employers must ensure that the exposure is adequately controlled, through the use of risk assessments and appropriate control measures.</p><p><br></p><p>It is advisable to have a policy in place outlining the measures in place for handling and storing substances that are potentially hazardous to health.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:34:11.171+00:00",
                                                    "originKey": "117dedd5-c670-45eb-9755-26beb0ba5acc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/coshh-policy/2718</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0302e961-9650-405e-8471-9dab8640cdb0",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "446098c1-539f-4e19-9e11-bf1b4a3e8e22",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f32df24-346b-46bb-a88a-7fbe2526b5a6",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7776a2a-93be-483f-802c-179b7762d662",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1aa47325-c5f0-40e4-87c2-eeb0cb57a2db",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "22b88ab3-5c80-4cb1-994a-a09081ac5f38",
                                                            "requirementId": "71e597e8-d0b4-4a7b-ba5f-16825b819832",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4478e1c0-3c50-4c17-a165-b2ca347995b8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2354,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9522d129-4859-4191-adab-0251d23d8be4",
                                                    "name": "Does the school have a Premises Management Policy?",
                                                    "descriptionHtml": "<p>Schools have a duty to ensure that buildings under its control comply with the statutory and regulatory standards, including considering the physical condition and suitability of all buildings, facilities and systems on the premises.</p><p><br></p><p>It is advisable to have a policy setting out procedures and responsibilities for managing the premises.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:37:16.888+00:00",
                                                    "originKey": "f9799f29-9782-45bb-8fe6-8d8181d88eaf",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/premises-management-policy/2111</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "59a5deb2-2ab5-4a0c-b0cb-3951f2a2bbe1",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5cf9207d-a63f-42c0-95d1-5de899cad5d5",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "994acb1c-a1eb-4ea4-a4c0-030583f1d0c2",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e45cf6e9-edac-4b2d-9e1c-a98a8e3f5886",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7bcb73aa-bb82-4508-8502-f286cd2ae22a",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f6918001-460f-4626-ba40-80b68026574d",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9bc08673-494f-4dba-8879-61fc333ad275",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2355,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9522d129-4859-4191-adab-0251d23d8be4",
                                                    "name": "Does the school have a Premises Management Policy?",
                                                    "descriptionHtml": "<p>Schools have a duty to ensure that buildings under its control comply with the statutory and regulatory standards, including considering the physical condition and suitability of all buildings, facilities and systems on the premises.</p><p><br></p><p>It is advisable to have a policy setting out procedures and responsibilities for managing the premises.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:37:16.888+00:00",
                                                    "originKey": "f9799f29-9782-45bb-8fe6-8d8181d88eaf",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/premises-management-policy/2111</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "59a5deb2-2ab5-4a0c-b0cb-3951f2a2bbe1",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5cf9207d-a63f-42c0-95d1-5de899cad5d5",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "994acb1c-a1eb-4ea4-a4c0-030583f1d0c2",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e45cf6e9-edac-4b2d-9e1c-a98a8e3f5886",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7bcb73aa-bb82-4508-8502-f286cd2ae22a",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f6918001-460f-4626-ba40-80b68026574d",
                                                            "requirementId": "9522d129-4859-4191-adab-0251d23d8be4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c45ea834-3b92-4621-b79b-9ede5d5485d5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2356,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                    "name": "Does the school have a Lettings Policy?",
                                                    "descriptionHtml": "<p>Schools need to ensure they have procedures in place relating to renting out the school premises for non-school activities. It is advisable to have a policy in place that outlines how lettings will be managed and the responsibilities of the school and hirers.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:40:34.441+00:00",
                                                    "originKey": "d1953569-ad39-471e-9d01-b6ac4dc2ef1e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/academy-lettings-policy/5497&nbsp;</p><p>https://www.theschoolbus.net/article/lettings-policy-for-maintained-schools/2697</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1226b894-4287-45c0-a563-04d4dfdf294c",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1c963e82-271a-4421-a941-261ecb0a0bac",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1da2a94c-af28-42dd-8e3a-d89071f5b86a",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acc2b207-1dc4-49b3-a025-34cbf51ed94e",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b4790215-d3b1-45bf-a2ab-652add402d60",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c4029bf4-6301-4543-9ffc-df93b59ef64b",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f9cd7a05-8535-4544-911b-1cb38a1136cd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2357,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                    "name": "Does the school have a Lettings Policy?",
                                                    "descriptionHtml": "<p>Schools need to ensure they have procedures in place relating to renting out the school premises for non-school activities. It is advisable to have a policy in place that outlines how lettings will be managed and the responsibilities of the school and hirers.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:40:34.441+00:00",
                                                    "originKey": "d1953569-ad39-471e-9d01-b6ac4dc2ef1e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/academy-lettings-policy/5497&nbsp;</p><p>https://www.theschoolbus.net/article/lettings-policy-for-maintained-schools/2697</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1226b894-4287-45c0-a563-04d4dfdf294c",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1c963e82-271a-4421-a941-261ecb0a0bac",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1da2a94c-af28-42dd-8e3a-d89071f5b86a",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acc2b207-1dc4-49b3-a025-34cbf51ed94e",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b4790215-d3b1-45bf-a2ab-652add402d60",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c4029bf4-6301-4543-9ffc-df93b59ef64b",
                                                            "requirementId": "7a6fe516-0678-4652-bf58-e65b157898b1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "76b4eb38-b606-48a8-9e96-d54cf3afa213",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2358,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                    "name": "Does the school have a Visitor Policy?",
                                                    "descriptionHtml": "<p>Schools should have clear procedures in place for managing visitors to the school site. It is also important that visitors understand the measures in place so they can be followed. Having a Visitor Policy in place is a useful way of ensuring the procedures are clear and can be applied consistently.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:42:48.275+00:00",
                                                    "originKey": "4ec8f771-42ba-4b0e-868f-370549a64af7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/visitor-policy/1185</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01e553ba-e796-4ed7-bc94-e4b0adc4f594",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0b742096-4443-40ee-b0d4-c8fc27ffa55f",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "514fc9cc-65f9-44ed-b487-4f81a7b66d6d",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f1add4c-cce4-4aba-a388-66b4c9dee483",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c9c02e30-8116-48ab-9444-cd6d4c182495",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dac948d3-1771-4c77-9edd-d4124675d2d1",
                                                            "requirementId": "c106f4bc-1c9a-4c0a-b7e0-869701fab34a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a6dae232-ff67-4169-97a1-66bfec8a647b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2359,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                    "name": "Does the school have a Uniform Policy?",
                                                    "descriptionHtml": "<p>Schools should have a policy in place which sets out school uniform requirements. When developing uniform policies, governing boards are expected to give the highest priority to the costs for parents, and they should be able to demonstrate how best value for money has been achieved when developing or making any changes to the school’s uniform policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:44:05.663+00:00",
                                                    "originKey": "2fdf34b2-d097-4389-aeea-b68d3274d918",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/school-uniform/school-uniforms&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "21316f69-add1-4628-968d-19ca8392df42",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b539088-6233-426f-838e-ea1cbcfd675c",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7b423ae-65e9-4b59-b8e0-96d865405194",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ebc52c1a-e45b-4b81-911e-77c8fe26b325",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f721716-74a4-489d-b61a-e8cbcc2617f9",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e1584195-ca10-4f5d-b250-9aa135b355fc",
                                                            "requirementId": "27498741-6360-4e63-97d3-cc191cf2e199",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b9ef824b-5f5f-4db6-ab7d-d4cc4738c9b7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2360,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                    "name": "Does the school have a Flexible Working Policy?",
                                                    "descriptionHtml": "<p>The government encourages schools to adopt a flexible working policy which responds to the needs of their staff and addresses any challenges to flexible working in their setting. Having a Flexible Working Policy in place can help schools to ensure their approach is made clear to all staff and that procedures are implemented consistently across the school.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:45:49.828+00:00",
                                                    "originKey": "556217be-17a9-431b-a84e-cc8a2a50c550",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/flexible-working-in-schools/flexible-working-in-schools--2&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1597d21a-51e3-4e8e-b5fe-69a8c2e01f21",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4e53b7a4-8dfa-4ba8-bd06-1cab0c50c42d",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5af487ae-9414-4ca5-ab20-4a9136037208",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "89b6807f-2507-4052-b560-bd8f9085ebaa",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "40b7ed9e-0b48-46a1-bd28-73b886a96cf9",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "da88c495-fb5c-4eff-9032-b9c5f3381158",
                                                            "requirementId": "ff246157-ea7c-401a-849a-d0cf30746d6c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2cd5faea-2bf4-4927-b546-b176dfb695f9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2361,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                    "name": "Does the school have an Induction Policy?",
                                                    "descriptionHtml": "<p>Schools should have clear induction procedures in place. It is advisable to have these procedures outlined within a policy that is accessible to existing and prospective staff members.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:47:09.801+00:00",
                                                    "originKey": "8b8102e9-bc30-4ca5-b20a-d7002d3ba776",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/induction-of-new-staff-policy/290</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1da9d9ac-f848-4d8e-9995-47d0c4d9eee8",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6aa6bf8d-fa3d-4210-94b2-5bd8e854fe19",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ea95221-e92b-48fc-bc6a-c2b1825d92c5",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fce36281-668e-4d7c-a168-cd83a74fc950",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "52a1aa7f-4fba-42a1-8830-f684c090a7fe",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e38f65bf-625c-4bc2-957a-b7e1841e9e1b",
                                                            "requirementId": "9b38f34a-30c2-413e-a6c2-d83c39d9055c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "93bbced5-b0d8-4942-a86a-42cb658e1ce6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2362,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                    "name": "Does the school have a Maternity, Paternity, Adoption, Parental and Parental Bereavement Leave Policy?",
                                                    "descriptionHtml": "<p>It is vital that schools understand their duties in relation to parental leave and pay – it is also important that staff understand their responsibilities and rights. It is advisable to have a policy in place setting out details and procedures relating to family leave and pay.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:48:20.226+00:00",
                                                    "originKey": "429f3bee-fef4-4fda-9c14-afeee45fe999",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/maternity-paternity-adoption-parental-and-parental-bereavement-leave-policy/4493</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1269840b-1613-41c4-962b-8432253a1490",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "995b2dc7-d6ba-435c-9e1b-8e6f813992cd",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "afc18d60-3c23-438a-8640-76c1c66c58ec",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bae6b638-06ea-49d3-82c3-ce146e29679e",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "94243c18-ba1a-45d4-a3c8-e9b5d4208be1",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cd847470-b403-402f-a2ef-bceb1d5e2338",
                                                            "requirementId": "8e2ee403-87e6-47d2-a2c6-8268187380c9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e4a9e064-88dd-4348-be19-99ed7cf69809",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2363,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                    "name": "Does the school have a Searching, Screening and Confiscation Policy?",
                                                    "descriptionHtml": "<p>Schools have powers to search as outlined in the Education Act 1996. To demonstrate they comply with legal requirements when using these powers, it is advisable for schools to have a policy in place setting out searching, screening and confiscation procedures, including under what circumstances a search would be carried out.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:50:06.846+00:00",
                                                    "originKey": "ec273a11-512d-48eb-99f0-4fa160339c5a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/searching-screening-and-confiscation-policy/696&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0e5e5a9b-f064-438c-969b-aa9d82e6a4b5",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "464d6f08-3d58-4e85-b37c-0a4dfc667d98",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4de47e34-b9f0-4e81-9d12-f281034694c0",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f988d835-4db5-425d-beb8-fa5f6db95f92",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "12af52ed-8c3e-4343-9cc2-ba3ffb4fa63d",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dae391c1-98eb-40a0-a631-ceeac471f83b",
                                                            "requirementId": "19f71991-55bd-4571-ab82-69a88036e36b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d2aefe8-a79a-4a60-a0a6-c96a6d5f4fb0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2364,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                    "name": "Does the school have a Staff Bullying and Harassment Policy?",
                                                    "descriptionHtml": "<p>Schools have a duty of care to their staff members to ensure they are protected from bullying and harassment. It is advisable to have a policy in place outlining the processes and actions that will be taken to prevent and manage any instances of bullying and harassment.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:51:16.706+00:00",
                                                    "originKey": "35bd946e-f415-4e56-a8a4-7c20aca088b2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/staff-bullying-and-harassment-policy/1967&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "ad5ae372-30b1-4ea4-9e48-5227217d2e9c",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b40874bb-58c8-41ce-b57a-4590053f3ead",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cc2e23bf-7086-45ef-9ddb-3ecf36fbba2d",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f51cacb2-ec35-46d8-b454-e253f2c690dd",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7e4bf71b-c44e-467f-8621-41d0238c865d",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bdb1fbb1-2f80-424d-9bf2-10e300868341",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "06ccba1b-061f-42df-8524-4bbfdd3a3e4c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2365,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                    "name": "Does the school have a Support Staff Performance Management Policy?",
                                                    "descriptionHtml": "<p>Schools should have clear procedures in place outlining the performance management process for support staff – it is advisable to set out these procedures in a policy. The policy should cover areas such as how performance is monitored and how staff will be supported.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:52:43.937+00:00",
                                                    "originKey": "f5b444b9-e783-4b49-a0f9-b8b4aef0758c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/support-staff-performance-management-policy/1564</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "13bb1d73-7f0e-4182-8f77-74a3dff2cf6d",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5d8378c5-4c83-4353-88cd-c231505d4014",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "615a67ff-ed0e-4900-b072-5621cfea1ff6",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1b4b9bd-9e89-42e0-9f6c-92558f0799d7",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1b7894d4-8320-4415-94ad-7c78803be5d9",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3fda5115-8cf5-489b-aa4c-a5c4525417f7",
                                                            "requirementId": "36448705-5d3f-4366-b4b6-c58fb173cdb3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9abd2aa7-41f1-424f-a9e7-ba32b1adc8fa",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2366,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                    "name": "Does the school have a Staff Bullying and Harassment Policy?",
                                                    "descriptionHtml": "<p>Schools have a duty of care to their staff members to ensure they are protected from bullying and harassment. It is advisable to have a policy in place outlining the processes and actions that will be taken to prevent and manage any instances of bullying and harassment.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:51:16.706+00:00",
                                                    "originKey": "35bd946e-f415-4e56-a8a4-7c20aca088b2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/staff-bullying-and-harassment-policy/1967&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "ad5ae372-30b1-4ea4-9e48-5227217d2e9c",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b40874bb-58c8-41ce-b57a-4590053f3ead",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cc2e23bf-7086-45ef-9ddb-3ecf36fbba2d",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f51cacb2-ec35-46d8-b454-e253f2c690dd",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7e4bf71b-c44e-467f-8621-41d0238c865d",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bdb1fbb1-2f80-424d-9bf2-10e300868341",
                                                            "requirementId": "410f54cc-1da6-42a2-935c-3b31c4442edd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e1424254-9a50-44f7-a2cf-44a8da0093e1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2367,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                    "name": "Does the school have a Teacher Appraisal Policy?",
                                                    "descriptionHtml": "<p>Schools need to have a clear and consistent policy in place for the appraisal of teachers. The policy should outline appraisal principles and processes, as well as staff members' roles and responsibilities in performance appraisal.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:54:36.519+00:00",
                                                    "originKey": "d92ab50d-30b3-46d3-8588-3396174e24b6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/teacher-appraisal-policy/8456&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "78cfb383-a5b7-4e6f-87c8-29c94215a393",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b88d903e-13e9-4269-894e-f4480405fb36",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c034004e-3eca-45bb-8d22-edff7924faac",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c63158d9-b9b7-4895-bea5-978d9d3bc7c3",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d044bba-cba0-45b0-a30c-8fca92738432",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b9fbdbc2-4948-4307-81df-56940760f16f",
                                                            "requirementId": "6f92048f-de0d-4352-855f-9d16e623632d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "aabf3f87-ba8c-4b8d-923a-961d4051212c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2368,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                    "name": "Does the school have a Volunteer Policy?",
                                                    "descriptionHtml": "<p>Schools need to have a procedure in place for managing any school volunteers. It is advisable to have a policy in place setting out these procedures. The policy should cover areas such as safeguarding, risk assessments and volunteers’ expected conduct.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:57:00.285+00:00",
                                                    "originKey": "46dcd1a4-963d-4ade-86bb-29edc85aa212",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/volunteer-policy/1980</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2d95c959-0ddc-40f7-8712-addea7c22e22",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4260be14-8e4c-45c3-94ec-75acb71c2965",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "54d32373-5a48-4cc1-b1f0-6ae624191784",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdf654b7-3a8b-4c32-a9e1-eb935653638d",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5b781f40-848f-4987-9d4e-a9f6a2e2089f",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f816f8f1-d16b-42ec-acbd-1b12ddcce0be",
                                                            "requirementId": "2f53cd8b-b4e7-4f39-a674-df8644b7078e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "618b7991-e2df-4038-a824-cc33f5cc520e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2369,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "def6b294-15cf-461b-a970-8322df23adaf",
                                                    "name": "Does the school have a Physical Intervention Policy?",
                                                    "descriptionHtml": "<p>There are circumstances when it is appropriate for staff in schools to use reasonable force to safeguard children. It is important for schools to have a policy is in place which sets out when reasonable force may be necessary and how this would be conducted.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:58:06.72+00:00",
                                                    "originKey": "abe86cae-e3f5-47ec-b818-96d05a3c2899",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE, KCSIE&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4e2c96ab-331d-441e-8468-7a7d67b44989",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e6a4874-c474-43b2-ba58-e53b9e3368a3",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "73c8fbf5-83c9-4254-a217-354769e0e487",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dae3d2e5-938b-40b5-9163-f784f58892bd",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "32d95d72-209c-4c26-ba4d-af1dd0d757d1",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c796110d-0340-44ae-95da-cc227a2f01bf",
                                                            "requirementId": "def6b294-15cf-461b-a970-8322df23adaf",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "29fb843b-7848-4151-b02a-bc98c7796448",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2370,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                    "name": "Does the school have a Prevent Duty Policy?",
                                                    "descriptionHtml": "<p>Protecting pupils from the risk of radicalisation is an essential aspect of the school’s wider safeguarding duties. Having a policy in place helps to ensure that all members of staff are alert to changes in pupils’ behaviour which could indicate that they may be in need of help or protection, as well as to actively assess the risk of pupils being drawn into terrorism. In addition, the policy should outline the correct procedures to follow in the implementation of the government's Prevent strategy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:01:12.16+00:00",
                                                    "originKey": "c24543cc-aa9e-4861-861b-fb0eecfe67b6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/prevent-duty-policy/2452&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6d3a49bc-69c0-4613-94eb-fcb033023f7c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "92f3be72-df93-46d3-a499-c60db1c50e24",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adfe3149-e1df-4bfe-b7ac-efde0ff5370d",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b19da128-bff4-49c6-b49c-964961ca0f3c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "546a0340-d1fb-4d74-9d2d-847c9b3a095c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "faeadd90-aaf4-4d63-b40e-64ce932ad5b6",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "eb4ce714-cd37-4a14-be1d-0ec54ba54f69",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2371,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                    "name": "Does the school have a Prevent Duty Policy?",
                                                    "descriptionHtml": "<p>Protecting pupils from the risk of radicalisation is an essential aspect of the school’s wider safeguarding duties. Having a policy in place helps to ensure that all members of staff are alert to changes in pupils’ behaviour which could indicate that they may be in need of help or protection, as well as to actively assess the risk of pupils being drawn into terrorism. In addition, the policy should outline the correct procedures to follow in the implementation of the government's Prevent strategy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:01:12.16+00:00",
                                                    "originKey": "c24543cc-aa9e-4861-861b-fb0eecfe67b6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/prevent-duty-policy/2452&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6d3a49bc-69c0-4613-94eb-fcb033023f7c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "92f3be72-df93-46d3-a499-c60db1c50e24",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adfe3149-e1df-4bfe-b7ac-efde0ff5370d",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b19da128-bff4-49c6-b49c-964961ca0f3c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "546a0340-d1fb-4d74-9d2d-847c9b3a095c",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "faeadd90-aaf4-4d63-b40e-64ce932ad5b6",
                                                            "requirementId": "dab99106-7c45-41e9-a4a7-9bd652086804",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c2b58832-4d68-4229-a16c-3c3624c79315",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2372,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                    "name": "Does the school have an Allergen and Anaphylaxis Policy?",
                                                    "descriptionHtml": "<p>To ensure the safety of pupils and staff with allergies, it is vital that schools have clear procedures in place in relation to managing allergens and responding to allergic reactions and anaphylaxis. </p><p> </p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:05:20.04+00:00",
                                                    "originKey": "7b3aaf4b-6abf-429f-887b-992bab5b14eb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/allergen-and-anaphylaxis-policy/4941</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4bfebb86-fd96-4698-8bcc-c31adfd7afa1",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5bdb96e7-d6f6-4c6d-b514-39bc4338251d",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8f926908-a1b1-4a69-8560-8430525ffb85",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b0d8ce2f-a2da-4f41-a1e2-5cdf69b4886f",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "321d05e6-c878-4591-93f5-9c24df91261b",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4449da9a-f37d-4da6-92e3-f5c5f20e5153",
                                                            "requirementId": "348bcf20-f41a-4768-bc69-cc91bdd7dc3f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "37121578-5bd8-4831-a6ea-92aa84f1bd4f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2373,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                    "name": "Does the school have a Travel to and from School Policy and Plan?",
                                                    "descriptionHtml": "<p>All schools should be committed to ensuring all pupils are able to travel to and from school safely. It is advisable to have a policy in place detailing the procedures that are in place to ensure pupils can travel safely to and from school, and setting out how to ensure school travel is sustainable.</p><p> &nbsp;</p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:06:44.574+00:00",
                                                    "originKey": "6eaeefb1-b5b4-453f-b03e-f6b3faa8e106",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/travel-to-and-from-school-policy-and-plan/717</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "523f55ad-e548-49db-847c-152f50ca6849",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6ec88d5c-6e83-4652-bd93-30ca872e054c",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adaabf14-3a69-49c1-8082-163e20783c57",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9d3929f-75d5-428f-a7fc-28a18479a07b",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0dfb5981-a8f5-4d39-968d-8d339376b432",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ff68778d-4a06-41c2-9184-db35b2f677bd",
                                                            "requirementId": "163770cb-4a0b-44b9-af4f-f4b8271dd265",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "97ef3cf1-025c-4175-91a1-a452d66cc5f9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2374,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                    "name": "Does the school have an LAC Policy?",
                                                    "descriptionHtml": "<p>Schools need to have measures in place to ensure LAC and previously-LAC are supported. It is advisable to implement a policy setting out the appropriate support arrangements and any other relevant procedures.</p><p><br></p><p>You may wish to link to attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T11:28:17.974+00:00",
                                                    "originKey": "040e0ed7-b60f-4189-a4cd-63a3a5bf1e38",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'Statutory policies for schools and academy trusts'</p>",
                                                    "policyManagerId": 82,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2403c232-19b0-4dff-b8ad-df362151da59",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c60d1dd-c817-4fe3-8768-e566ea0f6119",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd0fdf4c-681a-4e0c-b06b-bcf844c33895",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6624381-32ad-420c-b1e8-650c3a0e82db",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3e8b4e4f-4eb5-4a0e-82a7-7f512bd5629d",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6e2b15f0-7702-4b70-8f89-5b2cb5ebfec0",
                                                            "requirementId": "cfa65b2b-07e8-430f-b771-134d8230561c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8b489054-47eb-41ef-84e2-ff24b0fe3f69",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2386,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                    "name": "Does your school have processes in place to address individual staff members’ wellbeing concerns?",
                                                    "descriptionHtml": "<p>Your school should have processes in place to address individual staff members’ wellbeing concerns, e.g. individual risk assessments, one-to-one meetings, an open-door policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T15:21:26.121+00:00",
                                                    "originKey": "ea141124-1b40-42f1-b11d-0ce117b3d797",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "16a05515-cb34-4e5a-95c6-7d028cad3736",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a83794ec-1c68-4da6-97b3-31daf8b778e6",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bbdbad78-44f4-49e6-8cad-5060438dbf3e",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c9dd0d78-a0ca-45ae-818b-23d8b1057f2e",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "10a94db3-836e-4692-aedd-1542ae2340e4",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "1d5b4508-0e92-4147-82c8-09bce4421502",
                                                            "requirementId": "53df76e6-108e-4d1b-b37b-f27747eb3eb5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7526860f-f6e6-4a5d-b2a4-d60037465f7c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2387,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                    "name": "Are school staff trained to identify the potential need for early mental health help for vulnerable pupils?",
                                                    "descriptionHtml": "<p>School staff should be trained to identify the potential need for early mental health help for vulnerable pupils. This training could take place in-person or online. You may wish to upload an example of any training certificates.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T15:25:04.835+00:00",
                                                    "originKey": "f2a61f2e-19a8-441a-8b29-9fd03f92bc3b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8bc12286-e898-490c-bc88-f2709c96b6dd",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "90f4d20c-da42-4b41-9636-e4ad32f38d78",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0fd146a-423a-4b36-8823-a8c5299ce0fe",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed0ef93c-ed60-4f67-bf82-ba00245bfc7d",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "315c64fb-8212-4956-9dfe-c9b0c0dd635a",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6aa81797-574e-42f0-aa28-96186ce5b9e6",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f2d32651-4a0d-4624-ab9e-8b21b607f1a2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2392,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                    "name": "Has the trust board provided the headteacher with a written report of their appraisal?",
                                                    "descriptionHtml": "<p>The headteacher’s appraisal should include the assessments and recommendations outlined in the ‘Governance handbook’, e.g. setting objectives. You could attach or link to the appraisal report as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:43:37.15+00:00",
                                                    "originKey": "95839747-a8da-4e99-addd-ba601ff0dab6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE ‘Governance handbook'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1375f01b-cb90-4280-876b-8be07061d2a2",
                                                            "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2ec5b33f-5314-47f4-864f-7d9bcf8908a2",
                                                            "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "571d8759-e791-4206-bed0-1481d0147c03",
                                                            "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "afc1d097-a8a1-4ddf-87ef-22105356e319",
                                                            "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "525b465d-effd-456b-8547-7e73476ba07f",
                                                            "requirementId": "102e9eb8-8d0a-4799-8ebb-d8359e87f05b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2d3ecb92-b389-479a-bca8-c12fd441c8d6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2393,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                    "name": "Has the governing board received safeguarding reports from the DSL?",
                                                    "descriptionHtml": "<p>The DSL should regularly update the governing board’s safeguarding link governor/trustee and provide safeguarding reports to the governing board. This should be done regularly, but at least termly.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T09:58:01.466+00:00",
                                                    "originKey": "10998c8d-43fc-44f1-ba70-b3842e2a16fa",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>Governance handbook</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement termly.</p>",
                                                    "requiresReviewInDefaultMonths": 4,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "043b3bda-a019-4315-af7b-5baba7b4a782",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2993162b-20c3-42d3-9e2e-87cffdd84d70",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d38ce782-d3f8-4483-8dad-fc259147c18e",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f8b08580-cdc0-462d-b1e2-e8c5f3d1cbff",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2157e0bb-12a5-4a38-ab52-d7cf8ff50153",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "94519786-f29e-4991-83ec-88f92338f757",
                                                            "requirementId": "fc44e5b6-19b9-4603-bef4-376652cf784c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b5829154-71cb-4fb6-ab64-2c5378dd87ce",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2394,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                    "name": "Does the school have procedures in place to report any incidents of discrimination, harassment and victimisation that occur in the school to the governing board?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place for staff to report any incidents of discrimination, harassment and victimisation that occur in the school, and to ensure these incidents are reported to the governing board.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:51:21.893+00:00",
                                                    "originKey": "803ece97-7991-44e8-a96e-dea515c0da0c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1e3e9c1c-88cf-497d-872c-d4bf897d8660",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "40409725-3cbf-4705-bb60-28343f4e64ef",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b65dc796-ca8d-4c94-a2a1-473e47770f70",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfea5d62-6daa-45f2-bdcf-092a9eeec145",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c381255d-51c6-4cad-9aef-2cced240c9ee",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "217890db-4a81-42a5-90a5-8ec468a6ad6c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2395,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                    "name": "Does the school have procedures in place to report any incidents of discrimination, harassment and victimisation that occur in the school to the board of trustees?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place for staff to report any incidents of discrimination, harassment and victimisation that occur in the school, and to ensure these incidents are reported to the board of trustees.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T07:00:21.272+00:00",
                                                    "originKey": "c3ce7cb0-cc44-420e-bfd8-536cfebecf80",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18b25f1e-7a08-475b-b55b-01af2e633ffe",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "19e9d45b-cbdf-4bb1-a1c4-7839b05cefd6",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6025270f-0e99-4541-b8ea-a1fe37dc31ce",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "717e2f58-83fe-42de-b7ca-d32597662e88",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "cf7bc726-2d9c-4713-a12a-e753c2c5a244",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "db71c230-36e9-4083-8e15-334467c26025",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2396,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                    "name": "Does the school have procedures in place to report any incidents of discrimination, harassment and victimisation that occur in the school to the board of trustees?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place for staff to report any incidents of discrimination, harassment and victimisation that occur in the school, and to ensure these incidents are reported to the board of trustees.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T07:00:21.272+00:00",
                                                    "originKey": "c3ce7cb0-cc44-420e-bfd8-536cfebecf80",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18b25f1e-7a08-475b-b55b-01af2e633ffe",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "19e9d45b-cbdf-4bb1-a1c4-7839b05cefd6",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6025270f-0e99-4541-b8ea-a1fe37dc31ce",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "717e2f58-83fe-42de-b7ca-d32597662e88",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "cf7bc726-2d9c-4713-a12a-e753c2c5a244",
                                                            "requirementId": "593949bd-1d25-4958-a63e-9f1d801687d2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5b21e0ae-fe42-4063-9f3c-1075bb19676f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2397,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                    "name": "Does the school have procedures in place to report any incidents of discrimination, harassment and victimisation that occur in the school to the governing board?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place for staff to report any incidents of discrimination, harassment and victimisation that occur in the school, and to ensure these incidents are reported to the governing board.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T06:51:21.893+00:00",
                                                    "originKey": "803ece97-7991-44e8-a96e-dea515c0da0c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1e3e9c1c-88cf-497d-872c-d4bf897d8660",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "40409725-3cbf-4705-bb60-28343f4e64ef",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b65dc796-ca8d-4c94-a2a1-473e47770f70",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfea5d62-6daa-45f2-bdcf-092a9eeec145",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c381255d-51c6-4cad-9aef-2cced240c9ee",
                                                            "requirementId": "d48db177-6047-4a42-a10b-b498337de0e2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1c7e889f-7f70-4e1e-9def-ef437242cd95",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e09166ad-9df9-4102-ac34-df171d2e54c5",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2398,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e09166ad-9df9-4102-ac34-df171d2e54c5",
                                                    "name": "Has your school published information on phonics reading schemes?",
                                                    "descriptionHtml": "<p>Your school should publish the names of any phonics or reading schemes used in KS1. You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:09:57.208+00:00",
                                                    "originKey": "d5da7da4-a872-48a3-8f73-5e50b9704857",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19b0d1de-db41-47d7-8f53-0380402b73f1",
                                                            "requirementId": "e09166ad-9df9-4102-ac34-df171d2e54c5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9be8f256-01f1-4ef3-9e00-d2b2347695d8",
                                                            "requirementId": "e09166ad-9df9-4102-ac34-df171d2e54c5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b867e339-8261-4bb4-9489-5b32b691f0c9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bea4f12f-af6b-4425-a04c-18180388bf88",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2399,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bea4f12f-af6b-4425-a04c-18180388bf88",
                                                    "name": "Has your school published information on courses available to pupils at KS4?",
                                                    "descriptionHtml": "<p>Your school should publish a list of courses and GCSEs offered to pupils in KS4. You can evidence this by providing a link to where this information is published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:13:46.905+00:00",
                                                    "originKey": "df1e1b0d-8dcf-4f8e-b4f1-36ac44ca763d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "7f9762e1-bf72-42d5-897b-405b7fe029b8",
                                                            "requirementId": "bea4f12f-af6b-4425-a04c-18180388bf88",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f558065c-9f94-4d96-b331-59d1fcbf824c",
                                                            "requirementId": "bea4f12f-af6b-4425-a04c-18180388bf88",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4af201f9-2598-4a61-9851-6d41e731a86b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b440111f-85b3-43bb-9d6d-64a98dde6c2c",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2402,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b440111f-85b3-43bb-9d6d-64a98dde6c2c",
                                                    "name": "Has your school published information about Year 6 swimming?",
                                                    "descriptionHtml": "<p>Academies should publish information about how many of their Year 6 cohort met certain national curriculum requirements in swimming,. The school should state how many Year 6 pupils can: </p><p> </p><ul><li>Swim competently, confidently, and proficiently over a distance of at least 25 metres.</li><li>Use a range of swimming strokes effectively.</li><li>Perform safe self-rescue in various water-based situations.</li></ul><p> </p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T08:33:45.341+00:00",
                                                    "originKey": "50962aee-b5af-4aab-a607-dfb7b7121189",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a7551702-37d3-4854-ba90-6e7c62947218",
                                                            "requirementId": "b440111f-85b3-43bb-9d6d-64a98dde6c2c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5778f1c0-41e4-4357-b4f7-27abdf175c70",
                                                            "requirementId": "b440111f-85b3-43bb-9d6d-64a98dde6c2c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "75d311ec-aabf-42de-84bd-3f5a5b6828d0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2411,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                    "name": "Is there a senior individual within the governing board designated to take leadership responsibility for pupils with SEND?",
                                                    "descriptionHtml": "<p>All governing boards must have regard to the statutory ‘SEND Code of Practice: 0 to 25 years’ guidance. There should be an individual on the board or committee with specific oversight of the school’s arrangements for SEND.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:06:38.401+00:00",
                                                    "originKey": "79422d10-7637-44da-92cf-b9fe566916df",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance Handbook</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01d4a66e-f8a8-4f9e-aab6-2efecc3d2bda",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "15affbed-48c7-4948-8f37-82e666780715",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "780c951b-fc34-4e2d-9f31-0870315e66ff",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ad3726b0-fa58-4279-a69a-fbd46bbceb1c",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5c8873dd-2115-45b0-882a-96b2278fc56d",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "61a4e873-1fb4-4747-882a-8fc3389280ad",
                                                            "requirementId": "51f6b596-305c-4abc-b22a-0f32d6161700",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "444dc3b5-4b12-4123-979a-83d7a92a55fd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2412,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                    "name": "Does the school review and update the support it provides for pupils with SEND regularly, and at least annually?",
                                                    "descriptionHtml": "<p>The impact and quality of support provided for pupils with SEND should be evaluated by a practitioner and the SENCO, working with pupils' parents and taking into account pupils' views. They should agree any changes and parents should have clear information about the impact of the support provided, as well as be involved in planning next steps.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:07:23.478+00:00",
                                                    "originKey": "0f221c45-ca23-48b1-933f-45e40faa8c60",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a9f1f57-50b5-464f-b3fa-a8c53719ce45",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3a25b2b8-c8b2-490f-9880-25eb70e6600b",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfbd48e7-58fe-4502-a5bb-3e19a3f3a547",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3ed8cb9-b4eb-4381-9978-45a481dc46cb",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "22886dbe-27e6-40d8-aeb7-eb830061ee71",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "67d2be72-207f-43cb-8efe-15b63d88ad48",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "63921938-6e3c-4696-a24f-75c1d08bb58d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2413,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                    "name": "Has the school implemented a clear procedure to follow when it is notified of any diagnosis that means a pupil requires adjustments to the support and educational provision they receive?",
                                                    "descriptionHtml": "<p>The DfE’s ‘Supporting pupils at school with medical conditions’ states that governing boards should ensure that the school’s policy sets out the procedures to be followed whenever the school is notified that a pupil has a medical condition. Where a new diagnosis arises, schools should make every effort to ensure that arrangements are put in place within two weeks. Schools do not have to wait for a formal diagnosis before providing support to pupils.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:11:21.161+00:00",
                                                    "originKey": "603cb8e0-ab4d-4efe-8a07-3883ee10e5af",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6d191e1f-d638-4b8f-b103-fc63694a193a",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "796b20c8-e1df-40de-a0d4-b62d0713162e",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d04b4688-4eba-4f27-849e-78f51474bf9e",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f698309b-0d95-4dcd-804c-9ffec8297475",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1dcef282-2a46-46a8-aff5-22e71d7ba43d",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7761468d-dd48-416c-8eed-140b70670827",
                                                            "requirementId": "0fb6926f-58a7-498f-acf1-77e953dc6eef",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4a90cd34-a3d4-4302-9e26-2a70e246cb93",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2414,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                    "name": "Does the school have procedures in place for supporting pupils with SEND to transition between phases of education and prepare for adult life?",
                                                    "descriptionHtml": "<p>Support&nbsp;for pupils with SEND should include planning and preparation for the transition between phases of education and preparation for adult life. To support transition, schools should share information with the school the pupil is moving to. Schools should agree with parents and pupils what information will be shared as part of this planning process.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:12:17.613+00:00",
                                                    "originKey": "cb428570-f76b-43d4-aa19-0ae4e670f2f0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "49799124-3e80-4be2-8623-50da16ebd3aa",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "59eea29d-f577-4230-a8da-3bc58cd295d1",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d54e35c5-f243-495f-a79f-925945577e9a",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ef73f57b-5dc1-4c87-8c9a-c2cbaf1c6017",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "124ccefb-85ac-4f50-b9c1-cf952db7b12c",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b7eb9455-5d29-4ff8-9470-e43a9a559735",
                                                            "requirementId": "0b5570c3-d581-4abd-8b09-2bc1d6e29d57",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "34911438-8c27-47bc-aabc-2f678b7943c4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2415,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                    "name": "Does the school have procedures in place to review the quality of teaching and educational support for pupils with SEND?",
                                                    "descriptionHtml": "<p>The quality of teaching for pupils with SEND and the progress made by pupils should be a core part of the school’s performance management arrangements and its approach to professional development for all teaching and support staff. Schools should regularly and carefully review the quality of teaching for all pupils. This includes reviewing and, if required, improving teachers’ understanding of strategies to identify and support pupils with SEND and enhancing their knowledge of the SEND most frequently encountered.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:13:03.807+00:00",
                                                    "originKey": "bc0e7c49-33db-4088-9605-057cca318ffd",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1a84f16f-470a-4abf-81ab-f7f2fd45c293",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "865cf48a-5ff2-4f5f-bcd8-0514a4b22c15",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e2a4f64-77ff-45ab-8390-255726d1a272",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dd78e1a1-4360-453a-b171-3cd4b831b321",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d4de09d8-58a6-4a25-986b-6f5a7a1889e0",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ee8bceea-d592-42a2-b972-f4e56e4b2042",
                                                            "requirementId": "dbf51ffa-9045-4b62-85fa-a71b9441e662",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dbc1db93-dcf6-4a04-b7e8-4ea5c26be8f6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2416,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                    "name": "Does the school have procedures in place to involve specialists in the education of pupils with SEND?",
                                                    "descriptionHtml": "<p>Where a pupil continues to make less-than-expected progress, despite evidence-based support and interventions that are matched to the pupil’s area of need, practitioners should consider involving appropriate specialists; for example, health visitors, speech and language therapists, Portage workers, educational psychologists or specialist teachers. Specialists may be able to identify effective strategies, equipment, programmes or other interventions to enable the pupil to make progress towards the desired learning and development outcomes. The decision to involve specialists should be taken with pupils' parents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:15:56.853+00:00",
                                                    "originKey": "853582d9-86ae-4147-8240-5dc3530f8218",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "230d7aa6-68ad-4a45-a167-d41e988a793e",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7ae4ffd5-5495-45c5-9c18-4a732094870d",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b3de6df-f81d-49dc-9abf-f054e3f842f0",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a21ca7f5-c3c2-4be8-87ec-20971b7f0f39",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9a573394-8d8a-463b-bf8d-4c9a3a6dec45",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f4d1ef8f-5f6f-42e9-946e-7026c870f885",
                                                            "requirementId": "25f2cf4f-eb9f-4f44-ab92-f7b83b606521",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "65bdb66c-0d3b-4073-b249-379899cf7eca",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2417,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4623e777-c04d-4b08-b51b-698315938b13",
                                                    "name": "Does the school have a clear picture of the resources currently available for supporting pupils with SEND, including areas of funding that may be used?",
                                                    "descriptionHtml": "<p>The SENCO, headteacher and governing board should consider their strategic approach to meeting the needs of pupils with SEND in the context of the resources available, including any resources targeted at particular groups, e.g. the pupil premium. As part of normal budget planning, it is for each school to determine their approach&nbsp;to using their resources to support the progress of pupils with SEND.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:16:51.275+00:00",
                                                    "originKey": "2e690afe-a15e-4a8e-a43d-53e22be3a3c2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0a72b140-4710-4ca1-acb8-940c4fa3f0d9",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "396d2f4e-c522-48de-a78d-732958e22c51",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4e8572f4-bfc2-444c-ae3c-fecfdda75638",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "baf0876f-06c6-4e37-9252-ba5599a40f40",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "95952b01-1628-44c9-93aa-a058e2577a9b",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a4a8ba95-b225-4945-a202-2559bb18dd10",
                                                            "requirementId": "4623e777-c04d-4b08-b51b-698315938b13",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8ce9849a-7ec1-4625-9b75-63dc967f8bd9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2418,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                    "name": "Does the school have a clear strategy for using funding and resources to meet pupils’ support needs?",
                                                    "descriptionHtml": "<p>It is for schools, as part of their normal budget planning, to determine their approach to using their resources to support the progress of pupils with SEND. The SENCO, headteacher, and governing board or proprietor should establish a clear picture of the resources that are available to the school. They should consider their strategic approach to meeting the needs of pupils with SEND in the context of the total resources available, including any resources targeted at particular groups, such as the pupil premium.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:17:41.709+00:00",
                                                    "originKey": "d34b2891-c585-4590-b6e0-bfac5c1a8415",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5a3883e6-63fd-4074-9645-828bc41df41b",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6155177f-8b46-4db0-a0a8-ac7ea65bd24e",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "722e8dde-0bbb-4b1c-85bc-ef59591fcbc8",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "78a6f7df-e4ca-45e6-b782-3e24ea9f3014",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4c2c8e35-671f-4821-8349-132b2ab2c3b6",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ca6d7880-1b84-4c8c-b360-6a6063163457",
                                                            "requirementId": "c930f94e-ddee-44ec-8141-0b5b3d043584",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bd863f8e-2ab6-4f47-bc8c-831f4746d84e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2419,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                    "name": "Has the school established relationships with local health services to ensure they can receive guidance on the support they provide for pupils with SEND or medical conditions?",
                                                    "descriptionHtml": "<p>In making decisions about the support they provide, schools should establish relationships with relevant local health services to help them. It is crucial that schools receive and fully consider advice from healthcare professionals, and listen to and value the views of parents and pupils.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:19:13.141+00:00",
                                                    "originKey": "93430995-4b31-438e-a94d-910fd1139081",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/803956/supporting-pupils-at-school-with-medical-conditions.pdf\" rel=\"noopener noreferrer\" target=\"_blank\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/803956/supporting-pupils-at-school-with-medical-conditions.pdf</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "14a61f83-ee8e-4b48-b3b0-21decc7415ac",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "204a8da1-a4b1-4274-88fc-f7b57afade89",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37d04d7c-2867-497e-84db-d701a193f2f8",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f221d490-e40d-46b2-b7ef-ed68d2c8c822",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "99cc8107-58be-443f-a7d9-f2cbc4b54eb3",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f37556e4-0ad5-4a0e-82a8-39da4a815e43",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6c1afdc0-d34d-44f0-8dd0-b579363f82dd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2420,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                    "name": "Does the school involve pupils with SEND in the formulation of their support plans and allow them to express their views?",
                                                    "descriptionHtml": "<p>When identifying pupils as needing SEND support, schools should ensure that pupils' own views are heard as well as those of their parents and relevant external support services. The impact and quality of the support and interventions should be evaluated along with the views of the pupil and adapted accordingly.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:22:27.609+00:00",
                                                    "originKey": "83afdf81-9f57-4f53-90be-f765ff75716c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "433afeb5-bdf5-4e3b-bb7f-a09fc8c20cff",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "43bc1bf9-5b6d-4d6c-a844-ec244d3ef13a",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "89279560-e429-4caf-a5e3-c505f844efc2",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d5b45a06-02c2-4c6a-b986-51bbb908e79a",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "71fe4fe9-99b8-487b-af1d-2317c71ca9c6",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d9d649ce-af4d-4d4a-8032-03c6ada1582d",
                                                            "requirementId": "11a3265e-6165-4d23-9ef7-575461a4cebf",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8ed17f05-10cb-4e3c-8b80-7498904c0221",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2421,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                    "name": "Does the school include the quality of SEND provision as a core part of its performance management arrangements and approach to professional development for staff?",
                                                    "descriptionHtml": "<p>The SEND Code of Practice stipulates that the quality of teaching for pupils with SEND and the progress made by pupils should be a core part of the school’s performance management arrangements and its approach to professional development for all teaching and classroom support staff.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:23:37.418+00:00",
                                                    "originKey": "fbce9189-8392-4aa8-9f05-ed08fdaab3f3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0131f5bd-28eb-4fc3-8f4c-670638deaba2",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "235225f4-a64b-4182-b115-975abbc3cd71",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5b47cd5a-a61e-496b-9216-8ed0cc283d8e",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fb65e883-66c4-4100-bdca-246bba04ce93",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "03b2d133-b94f-414a-8f5d-1acccec8f8d4",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fd279750-b566-4217-8667-5d5b6c8b80ad",
                                                            "requirementId": "f68a853a-6f39-40df-b327-bb6df2efc98a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f5ec51cc-0d0a-42a9-9dae-693158b7e729",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2422,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                    "name": "Does the school ensure that written records are kept of all instances where medicine and/or treatment has been administered to a pupil?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that written records are kept of all medicines administered to children. Records offer protection to staff and pupils and provide evidence that agreed procedures have been followed. Schools should keep a record of all medicines administered to individual pupils, stating what, how and how much was administered, when and by whom. Any side effects of the medication to be administered at school should be noted.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:24:34.966+00:00",
                                                    "originKey": "7baca7aa-906d-4be6-95ea-44af4e1ebfc1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0a009ffd-a4d6-4d43-b2b6-e50099f96543",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "26de4c21-dddd-40ec-be36-55ed9dc739b6",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c8d53ea-7fcb-473a-b711-0925e875228f",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b01cafc4-6c94-45a8-96e2-8dae59d90fc5",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "38e41ba4-fa65-4d7c-8951-a830fe13817d",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c8eeb090-12a3-40ec-a184-1791fe6d1e49",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d954e90-57f9-4b64-9fc7-d6cfad084c50",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2423,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                    "name": "Does the school ensure that there are personalised procedures in place for any emergency situations that may arise from a pupil’s SEND or medical conditions?",
                                                    "descriptionHtml": "<p>Where a pupil has an individual healthcare plan, this should clearly define what constitutes an emergency and explain what to do, including ensuring that all relevant staff are aware of emergency symptoms and procedures. Other pupils in the school should know what to do in general terms, such as informing a teacher immediately.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:25:53.096+00:00",
                                                    "originKey": "eaf46404-d450-43aa-8ee0-155e150e1af8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "211ebb21-a997-46ce-911f-a516bc77e39f",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3deba4ed-b9da-4e2e-bbe9-b84dcfa2f218",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77f32e8d-2bdc-4dd5-8a19-36d6ea2dc0bb",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dc006091-18fc-4c2d-aea2-18d2c87ed273",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "72e0e9c4-d584-4051-914b-8eb76be566c7",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bc0fe8d9-2f5b-4ee4-8a79-7a078886c7cd",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9782a1b9-777e-4ca9-95c8-a3a0c666b002",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2438,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                    "name": "Does the school review and update the support it provides for pupils with SEND regularly, and at least annually?",
                                                    "descriptionHtml": "<p>The impact and quality of support provided for pupils with SEND should be evaluated by a practitioner and the SENCO, working with pupils' parents and taking into account pupils' views. They should agree any changes and parents should have clear information about the impact of the support provided, as well as be involved in planning next steps.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:07:23.478+00:00",
                                                    "originKey": "0f221c45-ca23-48b1-933f-45e40faa8c60",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a9f1f57-50b5-464f-b3fa-a8c53719ce45",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3a25b2b8-c8b2-490f-9880-25eb70e6600b",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfbd48e7-58fe-4502-a5bb-3e19a3f3a547",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3ed8cb9-b4eb-4381-9978-45a481dc46cb",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "22886dbe-27e6-40d8-aeb7-eb830061ee71",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "67d2be72-207f-43cb-8efe-15b63d88ad48",
                                                            "requirementId": "4fa4e0c7-d02d-486d-b8bf-7b49b344300b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ac2c8809-e7d7-4b31-bff0-5441525869d6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2439,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                    "name": "Has the school established relationships with local health services to ensure they can receive guidance on the support they provide for pupils with SEND or medical conditions?",
                                                    "descriptionHtml": "<p>In making decisions about the support they provide, schools should establish relationships with relevant local health services to help them. It is crucial that schools receive and fully consider advice from healthcare professionals, and listen to and value the views of parents and pupils.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:19:13.141+00:00",
                                                    "originKey": "93430995-4b31-438e-a94d-910fd1139081",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/803956/supporting-pupils-at-school-with-medical-conditions.pdf\" rel=\"noopener noreferrer\" target=\"_blank\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/803956/supporting-pupils-at-school-with-medical-conditions.pdf</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "14a61f83-ee8e-4b48-b3b0-21decc7415ac",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "204a8da1-a4b1-4274-88fc-f7b57afade89",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "37d04d7c-2867-497e-84db-d701a193f2f8",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f221d490-e40d-46b2-b7ef-ed68d2c8c822",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "99cc8107-58be-443f-a7d9-f2cbc4b54eb3",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f37556e4-0ad5-4a0e-82a8-39da4a815e43",
                                                            "requirementId": "893f7866-3c7a-410b-b5e5-889da9cd65f2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0facdec3-7890-449b-b545-7666f194fd55",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2440,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                    "name": "Does the school ensure that written records are kept of all instances where medicine and/or treatment has been administered to a pupil?",
                                                    "descriptionHtml": "<p>Governing boards should ensure that written records are kept of all medicines administered to children. Records offer protection to staff and pupils and provide evidence that agreed procedures have been followed. Schools should keep a record of all medicines administered to individual pupils, stating what, how and how much was administered, when and by whom. Any side effects of the medication to be administered at school should be noted.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:24:34.966+00:00",
                                                    "originKey": "7baca7aa-906d-4be6-95ea-44af4e1ebfc1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0a009ffd-a4d6-4d43-b2b6-e50099f96543",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "26de4c21-dddd-40ec-be36-55ed9dc739b6",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c8d53ea-7fcb-473a-b711-0925e875228f",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b01cafc4-6c94-45a8-96e2-8dae59d90fc5",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "38e41ba4-fa65-4d7c-8951-a830fe13817d",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c8eeb090-12a3-40ec-a184-1791fe6d1e49",
                                                            "requirementId": "ec23e4d8-b123-4c1f-8c35-e6d106763981",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "df9b9f68-6bef-4e3e-a5f6-0ef142047fba",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2441,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                    "name": "Does the school ensure that there are personalised procedures in place for any emergency situations that may arise from a pupil’s SEND or medical conditions?",
                                                    "descriptionHtml": "<p>Where a pupil has an individual healthcare plan, this should clearly define what constitutes an emergency and explain what to do, including ensuring that all relevant staff are aware of emergency symptoms and procedures. Other pupils in the school should know what to do in general terms, such as informing a teacher immediately.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:25:53.096+00:00",
                                                    "originKey": "eaf46404-d450-43aa-8ee0-155e150e1af8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Supporting pupils at school with medical conditions</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "211ebb21-a997-46ce-911f-a516bc77e39f",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3deba4ed-b9da-4e2e-bbe9-b84dcfa2f218",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77f32e8d-2bdc-4dd5-8a19-36d6ea2dc0bb",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dc006091-18fc-4c2d-aea2-18d2c87ed273",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "72e0e9c4-d584-4051-914b-8eb76be566c7",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bc0fe8d9-2f5b-4ee4-8a79-7a078886c7cd",
                                                            "requirementId": "93b384e0-e1d1-4555-8e67-84b385ec72bc",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ab213183-96c8-444e-8af1-f1ec3945428e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2458,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                    "name": "Does the school teach content about all protected characteristics throughout the curriculum?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Ofsted’s ‘Inspecting teaching of protected characteristics in schools’ guidance states that schools are not required to teach about all the protected characteristics in every year group; however, the curriculum should be planned and delivered so that children develop age-appropriate knowledge and understanding during their time at school. Inspectors will gather evidence on how schools promote equality and pupils’ understanding of the protected characteristics.  You may wish to attach relevant procedures and policies to evidence this requirement.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:37:00.377+00:00",
                                                    "originKey": "15ac0263-3227-4cf9-835e-1f2f951670b6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/inspecting-teaching-of-the-protected-characteristics-in-schools/inspecting-teaching-of-the-protected-characteristics-in-schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4133ab55-bd31-4d1a-a73b-92760464c080",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a18621a-107a-4a26-b1f4-32a0b080e3e4",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "84d83f5a-88d9-4342-a2a3-9c3afeda3158",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8f54fd38-04e7-413f-bf05-f0f74bca6825",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "00060200-5c9c-4521-b141-8473cdcb0d0d",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f074e994-999a-432f-9aad-78e657fb2e04",
                                                            "requirementId": "61a54101-956b-41ad-b357-5fe09da884a3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0652d155-31d5-414f-a552-d75f876eee22",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2459,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                    "name": "Does the school observe and teach pupils about cultural traditions, awareness events and celebrations of marginalised groups represented in the school community?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Ofsted's inspection handbook outlines how it inspects schools' provision for the cultural development of pupils, including developing their interest in exploring, improving understanding of, and showing respect for, different faiths and cultural diversity. Pupils should be taught to understand, accept, respect and celebrate diversity and demonstrate this through their respect and attitudes towards different religious, ethnic and socio-economic groups in the local, national and global communities.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:39:03.281+00:00",
                                                    "originKey": "8a6b13b8-7363-4abc-80e9-6bc508ab6abe",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/school-inspection-handbook-eif/school-inspection-handbook</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4e15cb19-36b7-4611-b646-a2e23af08093",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6736b208-da09-4ded-bab0-7c0632303e52",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "879eb7d2-c3d4-4792-a443-99745feb0293",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9f62e6b1-2502-4bd9-8135-d681c0652084",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "265d8e10-971f-453c-8672-ac0dc22dfbd8",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dd3c9799-d85a-4258-ba78-d3b836e8be16",
                                                            "requirementId": "8aa29d66-a8aa-4f8c-8cb0-8aa17b942406",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d4d2dd16-7b94-44a0-9074-f11dd44c5612",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2460,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                    "name": "Do the school’s equality objectives fit the school's specific circumstances and needs, and are they achievable and measurable?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools are under a specific duty to prepare and publish equality objectives. Schools are free to choose the equality objectives that best suit their individual circumstances and contribute to the welfare of their pupils and the school community. Objectives need to be specific, measurable and used as a tool to help improve the school experience of a range of different pupils. Publication of information in future years should include evidence of the steps being taken and progress made towards meeting the equality objectives that the school has already set itself.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:40:52.281+00:00",
                                                    "originKey": "10563d7a-cdff-47a6-ae89-15de2c7f14b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1b9e2479-20a3-4126-a873-ef1864e444a9",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77a1c27c-554f-4eb1-b94a-5586ab407e38",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b7f274e-851d-42d6-98ba-c4a009af0287",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9fcbbb16-b08b-408c-b636-ab7bdadae2ab",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "724b1d33-e6c1-4f04-865c-4cf4db86bf01",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7cfa52c2-375b-4b3d-afc9-59c359a09c96",
                                                            "requirementId": "6991e101-abf3-49b4-8087-b4f18767a054",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "707e2967-6d13-4720-a4ca-82ac9f4fd9cc",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2461,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                    "name": "Have all staff members received up-to-date training about the protected characteristics, equality and inclusion?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools should evidence that they are aware of the requirements of the Equality Act 2010 and determined to comply with the non-discrimination provisions. Schools can use evidence of staff training on the Equality Act as a note of how equality issues are monitored. Schools are required by Ofsted to promote pupils’ awareness and understanding of all the protected characteristics effectively – teachers will therefore need to have received appropriate training to educate pupils in this regard.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:42:27.847+00:00",
                                                    "originKey": "74ca1511-918e-4ecd-9455-3cbf342b6b36",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/inspecting-teaching-of-the-protected-characteristics-in-schools/inspecting-teaching-of-the-protected-characteristics-in-schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "29c7cf57-be64-4ec9-a56a-6f8888e94bc4",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2ad4342c-1e87-49a3-886d-7d2a71c4bf56",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c18e3ba6-1222-4d96-8d19-db06cd81df1a",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e2a978e4-4201-4fa0-8335-81dfb5c80546",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8d5ec6d8-f8b6-4e1a-9ae2-4b397eed261b",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a81ac78d-7283-485c-9fde-d4fb86bcd924",
                                                            "requirementId": "df116e12-6b3f-4333-b314-4bee363317e4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0afce469-302b-492b-b8bc-113e9082d155",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2462,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                    "name": "Does the school have procedures in place to identify, and take positive action to support, those with protected characteristics?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools should take positive action in order to target measures that are designed to alleviate disadvantages experienced by, or to meet the particular needs of, pupils with protected characteristics under the Equality Act 2010. Such measures need to be a proportionate way of achieving the relevant aim.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:43:54.698+00:00",
                                                    "originKey": "a58fd4bb-eb73-4b02-b8bf-0a05d376c207",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1473901c-d2c0-4acb-af3d-9ff68855e0b9",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5f9fae82-4a1a-4b3b-982a-99b812764569",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6329082d-f2a3-4aa9-ad69-6e44d12bf18e",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8dfde46-367a-4b88-a1d9-cad3dcb28dd2",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "37d85bd0-da8c-4e53-8b46-6c172fb6c46f",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "844ff67d-5eec-45fc-a50e-7ab408d6bb8f",
                                                            "requirementId": "15ad57e5-1f09-4f30-a976-b54885a0755d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3548efb1-3204-4413-8454-03595bd9d5db",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2463,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                    "name": "Does the school integrate LGBTQ+ content appropriately throughout the curriculum in an age-appropriate manner?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">At the point at which schools consider it appropriate to teach their pupils about LGBTQ+ content, they should ensure that this is fully integrated into their programmes of study for this area of the curriculum rather than delivered as a stand-alone unit or lesson. Schools are free to determine how this is done; however, it is generally expected that this content will be taught as part of RSHE. </span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:45:38.196+00:00",
                                                    "originKey": "181d9391-51ac-4fa8-b9c0-e7d066691ea3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">DfE - Plan your relationships, sex and health curriculum &amp; Relationships, Education,RSE and health education</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "56f82ac4-0074-4ae6-b1ea-ae44ab23fd42",
                                                            "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "751deffd-48ab-46fe-9cd9-49e835955ef3",
                                                            "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c8cd3dd-ca2d-42fd-9984-dba3eaa56aef",
                                                            "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a63350d7-2cbb-4d0b-ad3e-5f2b5fb9ee1a",
                                                            "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ff091619-5e59-43a3-a8f1-21e440d360db",
                                                            "requirementId": "44e47152-4ef7-4e80-b14d-fbaa2d5b488b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dfe7577b-a289-4f88-9eb2-895f67b6b8ec",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2470,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                    "name": "Does the school have a policy for equality and inclusion?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010, schools have a duty to have “due regard” to equality considerations. It is good practice for schools to keep a written record to show that they have actively considered their equality duties and asked themselves relevant questions. It is not a statutory requirement to have such a policy or document; however, for the purpose of demonstrating that the school has due regard for its duties under the Equality Act 2010, it may be useful to incorporate a clear and carefully considered policy for equality and inclusion.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:40:04.571+00:00",
                                                    "originKey": "b26dd0c2-41f1-4bab-851a-3c4b24339771",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "31a5b53d-771d-4be3-9ae7-66994599baad",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "34ebe4b2-4dcf-40a7-ab4f-daab4c3d03c8",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "406e319d-d136-4f68-be45-fcb5499bccf4",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f4f36e3c-aee5-4902-a30c-181481c0b37e",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "151a384e-4165-43df-90f2-c663c98c7325",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3036cd74-80af-4629-a9f4-f83603dd3c4b",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b60cb735-46ad-491c-974d-a70a1e183038",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2471,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                    "name": "Does the school have a policy for equality and inclusion?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010, schools have a duty to have “due regard” to equality considerations. It is good practice for schools to keep a written record to show that they have actively considered their equality duties and asked themselves relevant questions. It is not a statutory requirement to have such a policy or document; however, for the purpose of demonstrating that the school has due regard for its duties under the Equality Act 2010, it may be useful to incorporate a clear and carefully considered policy for equality and inclusion.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:40:04.571+00:00",
                                                    "originKey": "b26dd0c2-41f1-4bab-851a-3c4b24339771",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "31a5b53d-771d-4be3-9ae7-66994599baad",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "34ebe4b2-4dcf-40a7-ab4f-daab4c3d03c8",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "406e319d-d136-4f68-be45-fcb5499bccf4",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f4f36e3c-aee5-4902-a30c-181481c0b37e",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "151a384e-4165-43df-90f2-c663c98c7325",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3036cd74-80af-4629-a9f4-f83603dd3c4b",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5b63d32c-c4e6-42fb-b7b9-a77dfa0444af",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2478,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                    "name": "Does the school have a policy for equality and inclusion?",
                                                    "descriptionHtml": "<p>Under the Equality Act 2010, schools have a duty to have “due regard” to equality considerations. It is good practice for schools to keep a written record to show that they have actively considered their equality duties and asked themselves relevant questions. It is not a statutory requirement to have such a policy or document; however, for the purpose of demonstrating that the school has due regard for its duties under the Equality Act 2010, it may be useful to incorporate a clear and carefully considered policy for equality and inclusion.</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:40:04.571+00:00",
                                                    "originKey": "b26dd0c2-41f1-4bab-851a-3c4b24339771",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/315587/Equality_Act_Advice_Final.pdf</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "31a5b53d-771d-4be3-9ae7-66994599baad",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "34ebe4b2-4dcf-40a7-ab4f-daab4c3d03c8",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "406e319d-d136-4f68-be45-fcb5499bccf4",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f4f36e3c-aee5-4902-a30c-181481c0b37e",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "151a384e-4165-43df-90f2-c663c98c7325",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3036cd74-80af-4629-a9f4-f83603dd3c4b",
                                                            "requirementId": "545bc1c1-ca60-4918-89f2-cc271b633911",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b40cbee4-7b0f-4863-b2b9-8dc3e7a002c9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2479,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                    "name": "Does the school conduct individual risk assessments to ensure individuals with SEND can access the school safely?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Most individuals with disabilities will not need additional health and safety considerations; however, where an individual's disability or SEN creates a specific health and safety risk, these should be treated on an individual case-by-case basis. They should not result in blanket policies that place restrictions on all disabled individuals, or automatically assume that pupils with SEND are a greater risk. Where additional health and safety considerations are essential, these should not place unreasonable restrictions on individuals’ rights.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:55:35.861+00:00",
                                                    "originKey": "52ff61f1-1635-46b3-844f-4aeb60924321",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.hse.gov.uk/services/education/understanding-the-risks.htm</p>",
                                                    "policyManagerId": 89,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41a1a94d-922f-466f-8ec9-7601612382d3",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a2deb1e-eea7-4aeb-acdc-46e16e7df871",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9798f561-6177-4641-b582-aa9a2ce7e259",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a620c2b3-639c-440a-818a-f586664a2325",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d5104f5-1e8f-44d5-8d13-d115d27154ae",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e9dec519-c840-4d84-84c2-bbfb69c9ca32",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "484d89c3-82de-4720-a7a2-81c01a9dbf5c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2481,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                    "name": "Does the school conduct individual risk assessments to ensure individuals with SEND can access the school safely?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Most individuals with disabilities will not need additional health and safety considerations; however, where an individual's disability or SEN creates a specific health and safety risk, these should be treated on an individual case-by-case basis. They should not result in blanket policies that place restrictions on all disabled individuals, or automatically assume that pupils with SEND are a greater risk. Where additional health and safety considerations are essential, these should not place unreasonable restrictions on individuals’ rights.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:55:35.861+00:00",
                                                    "originKey": "52ff61f1-1635-46b3-844f-4aeb60924321",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.hse.gov.uk/services/education/understanding-the-risks.htm</p>",
                                                    "policyManagerId": 89,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41a1a94d-922f-466f-8ec9-7601612382d3",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a2deb1e-eea7-4aeb-acdc-46e16e7df871",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9798f561-6177-4641-b582-aa9a2ce7e259",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a620c2b3-639c-440a-818a-f586664a2325",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d5104f5-1e8f-44d5-8d13-d115d27154ae",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e9dec519-c840-4d84-84c2-bbfb69c9ca32",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6c09095a-4d44-4d1b-85e5-02816b4be0c3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 2482,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                    "name": "Does the school conduct individual risk assessments to ensure individuals with SEND can access the school safely?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Most individuals with disabilities will not need additional health and safety considerations; however, where an individual's disability or SEN creates a specific health and safety risk, these should be treated on an individual case-by-case basis. They should not result in blanket policies that place restrictions on all disabled individuals, or automatically assume that pupils with SEND are a greater risk. Where additional health and safety considerations are essential, these should not place unreasonable restrictions on individuals’ rights.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:55:35.861+00:00",
                                                    "originKey": "52ff61f1-1635-46b3-844f-4aeb60924321",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.hse.gov.uk/services/education/understanding-the-risks.htm</p>",
                                                    "policyManagerId": 89,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41a1a94d-922f-466f-8ec9-7601612382d3",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a2deb1e-eea7-4aeb-acdc-46e16e7df871",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9798f561-6177-4641-b582-aa9a2ce7e259",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a620c2b3-639c-440a-818a-f586664a2325",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d5104f5-1e8f-44d5-8d13-d115d27154ae",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e9dec519-c840-4d84-84c2-bbfb69c9ca32",
                                                            "requirementId": "23628c96-9d9b-445c-bbcc-c5c06eef26f4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e05a13f5-51b4-4615-83b5-76ef54a32cbb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2486,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                    "name": "Has your school published information on its careers programme?",
                                                    "descriptionHtml": "<p>If your school teaches pupils in Year 8 and above, you should publish information about the school's careers programme on the school website.&nbsp;You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:35:04.349+00:00",
                                                    "originKey": "a3851be0-9014-4e94-a3a2-cfb6d309eb30",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE 'What academies must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41c3fc64-a482-4048-b700-8334ca862e81",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9447349d-eaf4-47b9-9b65-a6e81b8b7d77",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "092988d4-de4d-4fb5-9673-5ba73b1ff4c0",
                                                            "requirementId": "1d464f90-fdcc-4da5-bb90-c4de9e537c62",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "238ca514-2904-43ea-a3b0-8a64193d9f18",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2488,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                    "name": "Are there policies and procedures in place to effectively manage bullying and harassment against staff?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place to effectively protect staff from bullying and harassment and to manage incidents and consequential mental health issues if they arise. You may wish to upload a copy of your Grievance Policy, or any other policies that outline how bullying and harassment against staff will be managed.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T10:48:44.985+00:00",
                                                    "originKey": "ed18d5c3-12e4-4da7-9a77-738ed732c16a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 73,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "09470187-3660-4e07-a8aa-7e94d3385839",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "09cf2c8f-3756-4470-8952-d9e6fe37284e",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8b3c8cda-3ee4-4655-ab74-1f236ed9e597",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b79b6a76-4198-47c4-a124-ef10e20387ae",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "96256b34-6486-4c50-9ff9-5953547af3df",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f3ff6f64-ccb4-4dc2-85ff-5323b9415039",
                                                            "requirementId": "5365a45e-5264-4c14-ab86-b46ddd5a6d9f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5baf1d64-2a8a-4efb-902f-50e4aaf03e78",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2489,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                    "name": "Are there procedures in place to effectively manage bullying and harassment against pupils?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place to effectively protect pupils from bullying and harassment and to manage incidents and consequential mental health issues if they arise. You may wish to upload any copies of the relevant policies that outline how bullying and harassment will be managed.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T10:52:53.024+00:00",
                                                    "originKey": "8a2384e7-94e3-4905-82f8-edd4e3c7a8f9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 3,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0796a9b5-93c4-418a-9621-fa301bf87bd1",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0968ac90-e105-442a-b9da-d91c0be389f9",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ddaab4c-ec5f-4c01-a396-44b67e876f5d",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b8c40dfb-aa27-497a-8bfb-161252035106",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9b0920c2-9f6d-48cf-9c88-e554bbe94ffa",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fb6df407-ff77-4fe2-8581-85b99b81c5e2",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c420d58b-ff6d-4305-a092-7fb754653cec",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2490,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                    "name": "Are there policies and procedures in place to support staff and pupils with events in their personal lives, e.g. Bereavement Policy?",
                                                    "descriptionHtml": "<p>Your school should have effective procedures in place to support staff and pupils in terms of mental health and wellbeing with events that happen in their personal lives, such as bereavement or maternity. You may wish to upload some examples of any related policies as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T10:57:32.307+00:00",
                                                    "originKey": "cc4d0be5-8f11-4b8e-b9fe-a52f70ee5123",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p><p><br></p><p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "453e4420-56a2-4b59-b360-03d3953edf78",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ab64338-e2fc-47b5-ba43-2ace1a9fc568",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e28363b-78cd-48e9-811b-26ed5d8b69de",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e92a8d33-c259-486b-965b-ffcbed7ed308",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "03a00060-bbef-401b-b126-14a9d48a2c87",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ff25db34-9a7d-447b-a4ab-f2845de09b9b",
                                                            "requirementId": "1c98a2e4-90fc-48d1-bf4d-7b655184d8c3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "62eba6b7-4b60-4701-a627-565128f0341d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2499,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "99173b09-5040-40f5-ac18-25423cb88427",
                                                    "name": "Does the school have procedures in place for recording all incidents of discrimination, harassment and victimisation that occur in the school?",
                                                    "descriptionHtml": "<p>Schools must follow anti-discrimination law and take steps, under The Equality Act 2010, to prevent discrimination, harassment and victimisation within the school. Staff should develop a consistent approach to monitoring bullying incidents in their school; for some schools, this may mean recording incidents. The DfE expects schools to exercise their own judgement as to what will work best for their pupils in relation to such incidents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:29:22.721+00:00",
                                                    "originKey": "4d30d9be-07b6-4749-aa60-9f35a0418b80",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE - Preventing and tackling bullying</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "559608b2-0af0-401c-869b-1ebf039dde8c",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5f4bceae-1231-41aa-91cf-f2c552be0fc8",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf00ae88-0aca-4059-8a57-4f028466af3a",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0751565-1019-4966-960c-60df4af7e879",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "740bec53-844f-4424-a90e-2fc844804f69",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fefe513f-4402-45ab-a1e2-d9105b1f3f0d",
                                                            "requirementId": "99173b09-5040-40f5-ac18-25423cb88427",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "81c206c4-6902-40a9-8ac6-c9c1d6ceff77",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2503,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                    "name": "Have all governors, trustees, and staff members with management and/or recruitment responsibilities received anti-discrimination training?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools must not discriminate against any prospective employee on the basis of any protected characteristics in relation to appointments, pay and conditions. When recruiting staff, employers and governing boards must be aware of these responsibilities as set out in the Equality Act 2010. Delivering anti-discrimination training to these individuals will help to ensure this duty is obliged.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:44:20.096+00:00",
                                                    "originKey": "197466f2-383d-410e-833b-01ae9067e370",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/staffing-and-employment-advice-for-schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "029a197c-2a2b-4545-8637-b4b4a0868b02",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dc19059-fb2f-4317-b509-54b2869da556",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b55fc79-f285-4a12-9e2d-d9998652b278",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9ea9b874-d27d-4fbb-a998-f2293c31d618",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d15ece2b-3135-4e77-bb76-4eabdc9f8449",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d4012a72-7b51-4e83-8d58-8f893802b75d",
                                                            "requirementId": "dd8acadf-451c-4e09-bc13-d75fd0e16397",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b729b557-e7d6-4508-8150-00936a7be827",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2506,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                    "name": "Does the school use records of previous incidents of discrimination, harassment and victimisation to inform and improve future practice?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Recording incidents may help schools to monitor incident numbers, identify where bullying is recurring between the same pupils, and fulfil the duty under The Equality Act 2010 to eliminate discrimination, harassment and victimisation.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:48:56.292+00:00",
                                                    "originKey": "a2194adc-1512-48fb-86e9-be850519afe1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/preventing-and-tackling-bullying</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "436909fc-201b-4276-9c0e-3a5e20077914",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5326f112-9114-45d2-952c-c8221ae4d6f3",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5ef182f6-1c4c-4a13-ae99-4f0d252b12fc",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e970119-197a-4e99-a278-4eac47378293",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ba250ac1-afcf-4206-a6e4-23a9ee941cf0",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dc4dd58c-956c-41c0-8c45-423713098219",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c0d67c60-2461-4c4f-ab8f-aaa79d3a70c7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2507,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                    "name": "Does the school use records of previous incidents of discrimination, harassment and victimisation to inform and improve future practice?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Recording incidents may help schools to monitor incident numbers, identify where bullying is recurring between the same pupils, and fulfil the duty under The Equality Act 2010 to eliminate discrimination, harassment and victimisation.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:48:56.292+00:00",
                                                    "originKey": "a2194adc-1512-48fb-86e9-be850519afe1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/preventing-and-tackling-bullying</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "436909fc-201b-4276-9c0e-3a5e20077914",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5326f112-9114-45d2-952c-c8221ae4d6f3",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5ef182f6-1c4c-4a13-ae99-4f0d252b12fc",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e970119-197a-4e99-a278-4eac47378293",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "ba250ac1-afcf-4206-a6e4-23a9ee941cf0",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "dc4dd58c-956c-41c0-8c45-423713098219",
                                                            "requirementId": "75897873-a02b-4f1e-9fa8-4cf987db3e33",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2bf2d1ef-b42e-4bec-a6f9-da1c8d8c8754",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2509,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                    "name": "Does your school have an estate vision?",
                                                    "descriptionHtml": "<p>To support strategic estate management, schools should have an estate vision. An estate vision should set out your ambition for the estate in 5 to 10 years’ time. Principally, the estate vision should describe how the estate will support the educational vision and strategy of your school. It should be prepared in consultation with relevant stakeholders (particularly any landowning parties) and should be signed off by your governing board.</p><p><br></p><p>You may wish to link to or attach your school’s estate vision.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:48:14.245+00:00",
                                                    "originKey": "626d1f0f-c4b8-43ba-9b9a-7e587e193ada",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "a4e2fd43-23a4-4b03-894c-bf6dc49e1249",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "be656516-e7dd-4195-a1ab-552581b76636",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dcfcb824-ea3b-4910-a44f-f04a0c5d0e69",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e77a5443-31c4-4894-bc9d-0e2dd7b0466e",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7f677318-6c60-45a4-99e5-6c2fe583ebe5",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "998f9fe1-54a8-44c4-8bee-3ccd992ada3a",
                                                            "requirementId": "43692551-e7d2-4143-8df5-43ed72446cd4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e9c8ded4-97e9-48ee-baf0-3b0dd20ff43e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2510,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                    "name": "Does your school have an estate strategy?",
                                                    "descriptionHtml": "<p>To support strategic estate management, schools should have an estate strategy. An estate strategy should:</p><p><br></p><ul><li>Explain how you will carry out the estate vision.</li><li>Cover the next 3 to 5 years.</li><li>Identify projected funding for estate development.</li><li>Outline anticipated outcomes and improvements to deliver the education strategy.</li><li>Be signed off by your governing board.</li><li>Be reviewed alongside the estate vision.</li></ul><p><br></p><p>You may wish to link to or attach your school’s estate strategy.&nbsp;</p><p><br></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:52:15.24+00:00",
                                                    "originKey": "31ef527a-b1bd-4ce0-a231-22c3052e9427",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5da93332-52fe-4bfd-a1c6-aa764bba4e14",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d5feef6-7c3a-4967-b4f6-fb894a884dc6",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e8e6348-1e80-468f-b05d-23cf6e3b7492",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ce6cd269-93cd-469c-9fd4-5713891480d9",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4b6b9b7c-ab7c-47b4-bcb3-a4eeff2b2dce",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a3008253-23cf-4e39-9fd5-0141d86a76fd",
                                                            "requirementId": "bd09454c-0fa9-4b67-b42d-5e36de479a61",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0587c38b-a38e-422f-bb2c-8fc732501e89",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2511,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                    "name": "Does your school have a strategic review process in place for estate management?",
                                                    "descriptionHtml": "<p>It is important for schools to strategically review their estates. A strategic review is a structured process which can help schools to understand:</p><p><br></p><ul><li>Whether the estate is meeting its needs.</li><li>How well the estate is performing.</li><li>Options for the future.</li></ul><p><br></p><p>It will help you develop and review your Asset Management Plan</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:55:25.737+00:00",
                                                    "originKey": "a1c04d5c-c0b1-4510-810a-337d11873c03",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "59465218-7352-4f8c-b5b0-70449daff2c7",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "67e50b7f-46e3-43c2-b49f-b6a786ede867",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d6e0fcc8-de48-4e98-954e-fda42edd1da4",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e89f45d4-f348-4f13-ba9f-7d281e4d4b1b",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "99764fd4-b2b4-401f-8c41-d811c9d06845",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a2c771ea-6e42-45ca-a378-86ea9b021dd7",
                                                            "requirementId": "e79380af-6d92-41ad-8700-0ef5625082fd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8737e2df-05ee-4ebb-85ad-cc0881c69def",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2512,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                    "name": "Does your school have a three-to-five-year budget plan for the estate?",
                                                    "descriptionHtml": "<p>Schools should have a three-to-five-year budget plan for the estate. The plan should include:</p><p><br></p><ul><li>A prioritised maintenance plan.</li><li>Information on how you will achieve value for money through investment.&nbsp;</li><li>Actual and potential funding sources.&nbsp;</li></ul><p><br></p><p>The budget plan should be included as part of the estate strategy. You may wish to link to or attach your school’s estate strategy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:58:34.335+00:00",
                                                    "originKey": "77a88a63-a416-4a68-befc-51b544bb288f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0243cf09-38fe-46aa-af4c-e447c159d7f6",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf39c1c8-e358-4858-96d3-706607b88fd3",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e7b688b0-92b3-42ec-bc63-fbbfdc17f2b8",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "65dd2136-ee63-4140-83ed-6673fdd41a49",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e12ca35b-3500-4be8-88ee-650d6ca0ccf6",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "359458fa-0b74-4d94-b8c9-b2e03a0d6bb3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2513,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                    "name": "Do you have all the information required to understand your school estate, including its condition and suitability?",
                                                    "descriptionHtml": "<p>Schools need to collect a variety of information to help them manage their estates effectively.&nbsp;</p><p><br></p><p>Schools should hold data and information to support their estate management documents, and record ownership, status (such as condition) and operating information about each asset (land and buildings) in the estate.</p><p><br></p><p>Schools need to make sure they have the relevant procedures in place to collect this information.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:01:42.506+00:00",
                                                    "originKey": "4290780b-bfd9-478f-b376-439ad1c1ee74",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "00463002-8282-4441-b75c-f333f43ae0c6",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7fb172d3-479f-407e-904b-09873e1bb5cb",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c4da7c37-a546-40c2-86a6-04f56aa49a03",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dd9cc2a5-c316-433a-a748-00821b3ab10d",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4a254ca9-6a3e-4396-9514-fab607b751ee",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "64d338c3-7bca-4bc1-b9d1-13913e6b30bd",
                                                            "requirementId": "507173ec-50fb-42ab-b54f-90ce50485fd8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ae8428d0-7c78-4692-a30a-8e9ef9abd44c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2514,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                    "name": "Does your school have an agreed approach to performance management for assets across the estate?",
                                                    "descriptionHtml": "<p>Schools should have an agreed approach to performance management for assets across the estate. This should include:</p><p><br></p><ul><li>A consistent approach to the measurement of property-related performance.</li><li>How this information will be used to improve performance.</li></ul><p><br></p><p>You may wish to link to or attach your school’s performance management procedure.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:02:53.409+00:00",
                                                    "originKey": "6fd3b980-0e91-4d1a-a650-9da7eedac5c4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8ab9a6af-59e7-4ebf-82d1-ccc5f9b32f86",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "997ee9dd-752a-4955-9065-0cf14619fa93",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c08c1173-2b2d-4b18-bc4f-381deec33832",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "df0a3daa-8d1e-4f83-a115-5dfb4cebf182",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "36248b07-d9d9-4898-8ba3-9e9cb00c63c0",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8b6ce982-b055-4351-aba6-669db09a7cc1",
                                                            "requirementId": "f1b998a8-29b2-408d-898d-76a34487502c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1807ed6d-4189-46e2-92e2-e39b2a379647",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2515,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                    "name": "Does your school have an approach to managing and reducing its energy and water use?",
                                                    "descriptionHtml": "<p>Schools need to have a consistent and challenging approach to:</p><p><br></p><ul><li>Monitoring and managing energy and water use.</li><li>Reducing energy and water use.</li><li>Maximising energy efficiency in buildings.</li></ul><p><br></p><p>You may wish to link to or attach your school’s relevant procedures.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:05:57.649+00:00",
                                                    "originKey": "57972261-c8b0-4229-a75e-8f9b2d7640d6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "50a9e3f3-0e25-4d8f-97c4-f7317545509d",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5903e8da-ddb2-4fa6-bc92-35d0ddaf1c16",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adc85acf-7db0-4122-9fdd-1246f253111e",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0000412-52ee-44e3-86b0-bf887a3373b7",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1733d1b7-73f4-498b-a806-285af23c555d",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "56a5d606-51a4-49db-aee4-cb20d5c0b8c4",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b207a283-190a-4c93-a621-7596f6883874",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2516,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                    "name": "Does your school adhere to the statutory restrictions on acquisitions and disposals that apply to your school?",
                                                    "descriptionHtml": "<p>Through strategic estate management, schools may identify opportunities to:</p><p><br></p><ul><li>Acquire new premises and sites.</li><li>Dispose of surplus space – both land and buildings.</li><li>Grant a third party use of the land and buildings, including for the purposes of generating income.</li></ul><p><br></p><p>You should be aware of any protections and the statutory restrictions on the disposal or acquisition of land or buildings. Different types of organisation have to handle disposals differently.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:08:50.78+00:00",
                                                    "originKey": "e324bda1-fb23-440a-a312-6ef674ce8043",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "64849d45-fbc3-44f7-8dd1-9c335ae4959f",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "891b6bcf-8eba-4d03-86ba-bbebf4cb04bd",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cfc9281e-19e6-4c73-beb9-c68b0e4bae78",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e4a4d076-8242-44ed-98ca-7950efa49a95",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "483c80e2-ef51-42a0-a6a8-d1622478fcec",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "798d30a1-66be-436b-a560-9211673eda5e",
                                                            "requirementId": "fcb8093d-8466-45a9-b879-3f10507d4070",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e3c24f7d-3ae6-4e98-b054-10e0cf8daa5d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2517,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                    "name": "Does your school have a plan for maintaining the estate?",
                                                    "descriptionHtml": "<p>Planned and prioritised maintenance is an important part of strategic estate management. To support this, schools should have:</p><p><br></p><ul><li>An overall maintenance plan for the estate.</li><li>A plan for day-to-day planned maintenance.</li></ul><p><br></p><p>Having the above in place will help schools to develop an estate strategy and Asset Management Plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:09:51.201+00:00",
                                                    "originKey": "46ce7f82-5b29-4a8a-a97b-dab350baee9f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2ed7c1f6-557a-41d8-a309-9219586b9003",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2f83eba6-5d0c-42fd-b49c-c2465ef22d12",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3a0204fa-502c-49b1-b894-1b434d2f05d7",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "df4aefcc-46d8-4f31-b642-2f2bd19257d7",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1e1863ed-973a-4205-a986-54e892e26e2f",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3f60c31b-6373-4185-a2d7-a7560d839926",
                                                            "requirementId": "8d8e3723-83ab-44dc-aa20-5a6be3e8e150",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1eff0540-385f-4337-b899-2a4c78c6a2a9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2518,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                    "name": "Does your school have its statutory maintenance and testing certificates?",
                                                    "descriptionHtml": "<p>All schools will need to inspect and test their buildings, plant and equipment as part of the estate maintenance regime. Schools should keep certificates and details of all statutory examinations, testing and remedial work.</p><p><br></p><p>You may wish to link to or attach any relevant certificates.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:35:47.66+00:00",
                                                    "originKey": "f89c67a7-0a41-4268-be76-81b1a7827d6a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "9bc2b64d-2ece-407d-b4b0-496d1ea325eb",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9d88fde5-454f-42af-9fe0-75887f76c395",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d5c91378-67b2-45f0-84f5-f64bbf04839f",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6dca3c7-7d42-4522-b7c7-b6055ee31768",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1ada881f-1074-42ff-9b69-0cbcedb94fd8",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c551c57b-9048-414c-a158-70bf66859e88",
                                                            "requirementId": "fa1480b7-2582-4cf8-ae4e-06b54eca6515",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "02f84a61-2ab6-4379-814e-70ea69fd9e16",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2519,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                    "name": "Has your school conducted a legionella risk assessment?",
                                                    "descriptionHtml": "<p>To help make sure the school estate is safe, schools should conduct a legionella risk assessment. Risks need to be identified and assessed in accordance with the relevant HSE code of practice.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school’s risk assessment.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:37:48.456+00:00",
                                                    "originKey": "f4a8ce88-8da7-4550-9b88-107f0c56f27c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19df0c28-3bb2-4378-bec6-6081d7d431cd",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "67b3388e-84b9-4187-aa56-1832ae6b9dbe",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ade4fad2-ebaf-42df-b570-778d30332f5f",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f755c2ae-c6b7-4184-a2c5-d23a3822fa51",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "73b17e47-1f34-4c96-93ee-078f443d6b78",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a1518889-6c00-48a3-a710-482d4359f394",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "923ab0f2-8e9e-429c-b188-4d175024727e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 2520,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                    "name": "Does the school consider energy consumption and environmental impact when conducting performance management of the estate?",
                                                    "descriptionHtml": "<p>When conducting performance management of the school estate, it is important to consider environmental sustainability. The following should be considered:</p><p><br></p><ul><li>Energy consumption – calculate energy consumption by reference to the area of the building&nbsp;</li><li>Environmental impact – assess emissions per area of the building&nbsp;</li></ul><p><br></p><p>You may wish to link to or attach your school’s method for assessing environmental impact.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:39:10.854+00:00",
                                                    "originKey": "f759ca5d-8a70-42c8-9aa9-46df9043601b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3d1397dc-ba30-4456-baaf-9aaa6824bb39",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "44270360-49ae-46fe-ba8f-d7abe99aabb8",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "44aafb49-c900-482b-9c62-d047db9718cf",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "901e4d14-b97a-45fe-816b-35a565f8e02b",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a396099d-b500-433f-86ba-4f1433c53f6d",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ad8fbae3-a0b3-4a96-84b1-304d58358957",
                                                            "requirementId": "14b41484-63ba-4d41-9344-1e826064c29e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "71b31c98-f724-41c5-9ea0-615bb74824de",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2522,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                    "name": "If your school has fewer than 250 employees, has your school published voluntary information for gender pay gap reporting?",
                                                    "descriptionHtml": "<p>If your school has fewer than 250 employees, you do not need to publish information on your school's website about the gender pay gap; however, you are recommended to do so as good practice. If you publish these figures, you are strongly recommended to also publish a statement confirming that the information is accurate and signed by an appropriate person – this can be the governing board. You can evidence this by linking to where this information has been published.</p><p><br></p><p><strong>Schools that have 250 or more employees must publish this information. You may mark this requirement as 'does not apply' and instead focus on the gender pay gap reporting requirement that applies to your school.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:45:42.333+00:00",
                                                    "originKey": "6245787b-7212-4535-8d73-7d4916890616",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "178ea23d-dc48-4994-aee7-fc34de07f5ff",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91965d69-447b-4348-924c-1411c7ea4e5c",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9fc2ff07-3628-4071-a5ef-5a26a06b2bb6",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f31f7177-01ce-46ec-87e7-b6f30203c758",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2a7451b4-768f-4c1f-bd58-3213bbd8f929",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7a2665d4-f6d8-4908-a0e5-59defd9dcbc7",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "03ffdcf1-f2e4-420b-907b-92b8218e036d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 2524,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                    "name": "If your school has fewer than 250 employees, has your school published voluntary information for gender pay gap reporting?",
                                                    "descriptionHtml": "<p>If your school has fewer than 250 employees, you do not need to publish information on your school's website about the gender pay gap; however, you are recommended to do so as good practice. If you publish these figures, you are strongly recommended to also publish a statement confirming that the information is accurate and signed by an appropriate person – this can be the governing board. You can evidence this by linking to where this information has been published.</p><p><br></p><p><strong>Schools that have 250 or more employees must publish this information. You may mark this requirement as 'does not apply' and instead focus on the gender pay gap reporting requirement that applies to your school.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:45:42.333+00:00",
                                                    "originKey": "6245787b-7212-4535-8d73-7d4916890616",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "178ea23d-dc48-4994-aee7-fc34de07f5ff",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91965d69-447b-4348-924c-1411c7ea4e5c",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9fc2ff07-3628-4071-a5ef-5a26a06b2bb6",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f31f7177-01ce-46ec-87e7-b6f30203c758",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2a7451b4-768f-4c1f-bd58-3213bbd8f929",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7a2665d4-f6d8-4908-a0e5-59defd9dcbc7",
                                                            "requirementId": "f92eb1b4-cc6b-4903-b9e5-ad02d5d7396a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "429c8227-8939-4122-bab8-8165f70bfdae",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2528,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                    "name": "Has your school published its contact information?",
                                                    "descriptionHtml": "<p>Your school should publish the following:</p><p><br></p><ul><li>The name of the school, its postal address and telephone number</li><li>The name of the staff member who manages queries from parents and other members of the public</li><li>The name and contact details of the SENCO (unless the setting is a special school or a college)</li><li>The name of the headteacher</li><li>The name and address of the chair of trustees</li><li>The sponsor's full name (or organisation name if an organisation) and contact details</li></ul><p><br></p><p>You can evidence this by linking to where this information has been published.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:54:40.931+00:00",
                                                    "originKey": "7a4616d3-91ef-46ff-8f64-c757ccb90c35",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "30b28905-805f-41f5-8227-0808a08e7fb7",
                                                            "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "361ce9ad-eba3-4475-8d76-28fecb6f318f",
                                                            "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d8e43b12-7a6e-4784-aa6d-a28f6514e363",
                                                            "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f15d50e8-7b7f-4d75-b430-523598538b05",
                                                            "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "d33d001b-92bd-47f7-928f-c930dfa0f01c",
                                                            "requirementId": "f657d0df-40c1-4b6f-90a4-0b87f7213cee",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "438a9969-09ad-4911-879d-640d8566f0d9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2529,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                    "name": "Has your trust published its annual reports and accounts?",
                                                    "descriptionHtml": "<p>Academies are strongly recommended to publish information relating to their school’s annual reports and accounts on their school websites:</p><p><br></p><p>You can evidence this by linking to where this information has been published.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-04T13:50:35.565+00:00",
                                                    "originKey": "07a5a26f-f30c-4466-a0fc-df5b19fe2e88",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>ATH, DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "b05ad49c-4c8d-4eb8-ab7b-8310feea495b",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ccde5963-715b-4ed4-b553-c1a802f6286d",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3bb71f0-30da-4e3e-9e52-31a8b2e20853",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f672d303-7efe-42ae-b427-3040c369858b",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2d934c4b-f64c-4c6c-9270-60956e6d7bc3",
                                                            "requirementId": "ada06fc1-f84b-4f10-b677-873493b51a2b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "42e08c23-67db-467e-b2cf-4b85159dd63d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2530,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                    "name": "Has your school published its Uniform Policy on the website?",
                                                    "descriptionHtml": "<p>The DfE strongly recommends that your school publishes its Uniform Policy on the school website to allow parents and pupils (and prospective pupils and parents) to access it and understand it; however, this is not a statutory requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T09:59:21.207+00:00",
                                                    "originKey": "100a39e0-ef78-45ce-bd6d-03dff8c093bd",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE 'School Uniforms guidance'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0077a360-fadb-4d55-accf-4bdb3d09ba7c",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7a33368-708b-4feb-9ded-f696a582cf78",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cfd4c8e9-722d-48f7-9a0c-5d41359ad7ef",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f22af21d-5003-4961-b3c0-0338b0e4d2c8",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "10611b68-8571-416b-8373-e68e698cd946",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a1b711d7-e585-4fe9-8e1e-c1abaac88866",
                                                            "requirementId": "9ff9ff49-8a59-451d-a272-2b3fbe59e188",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a632e5c3-9d74-4322-ad7f-378f952d2f33",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "95701dd8-02cd-41a5-8e6c-7408584b808d",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2532,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "95701dd8-02cd-41a5-8e6c-7408584b808d",
                                                    "name": "Has your school published its latest KS5 information?",
                                                    "descriptionHtml": "<p>If your school educates students in KS5, you should publish, from your school's dedicated performance tables, the following:</p><ul><li>Progress</li><li>Attainment</li><li>English and maths progress</li><li>Retention</li><li>Pupil destinations</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:09:59.676+00:00",
                                                    "originKey": "2a4187c8-b75e-4dcd-8233-584d8f2f208b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> What academies should publish online</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "f3a956c4-a947-4aeb-b31b-d20c78b136fd",
                                                            "requirementId": "95701dd8-02cd-41a5-8e6c-7408584b808d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b50f3d86-f4b3-4654-83e5-072a3a1bb7d2",
                                                            "requirementId": "95701dd8-02cd-41a5-8e6c-7408584b808d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "14acedae-141c-4c82-88b3-293386736b97",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2533,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                    "name": "Has your school published its performance tables?",
                                                    "descriptionHtml": "<p>You should publish a link to:</p><ul><li>The school and college performance tables page.</li><li>The dedicated performance table page for your school.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:17:32.683+00:00",
                                                    "originKey": "7b08719a-ff25-4e01-937a-c6311f5ed5fb",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01a974bf-15bd-4c75-b460-9e10d0518e9d",
                                                            "requirementId": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b441157d-761b-41b3-b7f9-85e46c53e946",
                                                            "requirementId": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bf52b3e4-b75f-40f7-985a-d76277c19e04",
                                                            "requirementId": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "29c8fea2-189d-4006-b46b-ad4cca4b2442",
                                                            "requirementId": "1ab36cf9-ae80-400d-8268-e3d38c2ff658",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f8449d9d-fe25-49b7-ac5e-2ea4ccb0d033",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c655bb7c-3b2a-412f-a40f-2ea935b03f35",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2535,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c655bb7c-3b2a-412f-a40f-2ea935b03f35",
                                                    "name": "Has your school published its secondary curriculum information?",
                                                    "descriptionHtml": "<p>You should publish:</p><ul><li>The content of the curriculum in each academic year.</li><li>The content of the RE curriculum, even if it is taught as part of other subjects or called something else.</li><li>A list of courses and GCSEs offered to pupils in KS4.</li><li>How parents and members of the public can find out more about your school’s curriculum.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:31:04.109+00:00",
                                                    "originKey": "aa4a79ab-ddc2-4bdb-a7d8-321db91f8616",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5e18acef-6067-47bb-afc2-17b05daa0f88",
                                                            "requirementId": "c655bb7c-3b2a-412f-a40f-2ea935b03f35",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "af384d25-8db0-472b-a214-7a46b2869628",
                                                            "requirementId": "c655bb7c-3b2a-412f-a40f-2ea935b03f35",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4835f1f6-17fe-4880-a698-f4df49752a62",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2536,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                    "name": "Has your school published information about its approach to the curriculum for SEND provision?",
                                                    "descriptionHtml": "<p>You are strongly recommended to publish your school's approach to ensuring the curriculum is accessible to pupils with SEND.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:32:42.225+00:00",
                                                    "originKey": "a7fea9ad-0cda-41f8-9b94-77f82c4f0cb1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08561c99-6bda-49df-8005-87d4e250a6fd",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "113b1fc0-ce23-4338-811f-d923b8a086e4",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9574b803-5f23-436e-9a7d-87f6cf7b7824",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea0967bd-6441-49f6-962e-ded03c708e70",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3127dca1-0ab5-4d1e-954c-2a6e1e0f49c9",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7c527cfd-20aa-4906-abdf-502e16f36899",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5ad2c59b-0ab1-4228-8c65-4c00c3cf7789",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 2537,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                    "name": "Has your school published information about its approach to the curriculum for SEND provision?",
                                                    "descriptionHtml": "<p>You are strongly recommended to publish your school's approach to ensuring the curriculum is accessible to pupils with SEND.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:32:42.225+00:00",
                                                    "originKey": "a7fea9ad-0cda-41f8-9b94-77f82c4f0cb1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08561c99-6bda-49df-8005-87d4e250a6fd",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "113b1fc0-ce23-4338-811f-d923b8a086e4",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9574b803-5f23-436e-9a7d-87f6cf7b7824",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ea0967bd-6441-49f6-962e-ded03c708e70",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3127dca1-0ab5-4d1e-954c-2a6e1e0f49c9",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7c527cfd-20aa-4906-abdf-502e16f36899",
                                                            "requirementId": "34b68be7-fa2b-4fec-8997-d6cbc1492733",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f3a3663f-a544-4bcd-811d-ba1beaa3b73e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2538,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                    "name": "Has your school published its Behaviour Policy, including the anti-bullying strategy?",
                                                    "descriptionHtml": "<p>You should publish the Behaviour Policy on your school website, including the trust's anti-bullying strategy. This should take the form of a policy. You could upload where this information has been published as evidence that this requirement has been fulfilled.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:36:54.188+00:00",
                                                    "originKey": "41d44474-d346-458e-9fc5-918e354290f8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "115c9a47-cdc0-4f2c-a076-961828f6f4d6",
                                                            "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1fc8798a-c8bf-4b5d-ace3-0e65dc840ef6",
                                                            "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "760982e5-ae83-4d01-b990-c27858a11d44",
                                                            "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d1cf152-17aa-417f-8a5b-f1d38d7a259f",
                                                            "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "c7069252-7136-4d6f-b7e5-642fc8139319",
                                                            "requirementId": "94bb4faa-709d-4b58-9a1b-5ee702bc44a5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "99c88e85-35c4-4640-b391-effe2addfebd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6b30091f-d9fb-4fb8-adbd-5088d9a97db8",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2542,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6b30091f-d9fb-4fb8-adbd-5088d9a97db8",
                                                    "name": "Has your school published a statement on its website about the use of the 16-19 bursary fund?",
                                                    "descriptionHtml": "<p>All eligible schools are strongly recommended to publish a statement on their websites that sets out how they will use the 16-19 bursary fund. This statement should include:</p><ul><li>The eligibility criteria for students.</li><li>The type of support being offered at the school or setting.</li><li>A supporting statement which explains that the support available to students is via the bursary fund.</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:44:06.363+00:00",
                                                    "originKey": "200e3b56-aa31-468b-964c-d76f32cfcfb9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE 'What academies should publish online'</p><p><br></p><p>DfE 'What maintained schools must publish online'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "ef17e977-400c-4a4b-ab94-73c6aa73a4b5",
                                                            "requirementId": "6b30091f-d9fb-4fb8-adbd-5088d9a97db8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b8adec97-50d5-4f56-9a69-3d74e9126d73",
                                                            "requirementId": "6b30091f-d9fb-4fb8-adbd-5088d9a97db8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c2698d2d-0883-4a03-b789-aa59b67cd313",
                                                            "requirementId": "6b30091f-d9fb-4fb8-adbd-5088d9a97db8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1630ee56-2331-4581-8f5e-32e019c40b30",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2543,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                    "name": "Has your school published the number of formal complaints received in the previous year?",
                                                    "descriptionHtml": "<p>It is strongly recommended for your school to publish the number of formal complaints received in the previous year. This is not a statutory requirement; however, it is considered good practice to do so.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:45:25.19+00:00",
                                                    "originKey": "1c74c288-bc00-4c4e-b666-360e04387731",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> The Education (Independent School Standards) Regulations 2014</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08cd3c54-41de-4837-a8d0-0233c1b79694",
                                                            "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "735753d8-85aa-495a-883f-32f3b81549b8",
                                                            "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "93322f08-0e8e-47bb-807d-cdb966bf25ed",
                                                            "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e4ea092a-a228-43c9-be68-323c6b59f853",
                                                            "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8c0926c4-db50-4de2-9fc3-2d2576ee2b78",
                                                            "requirementId": "2ac89636-7735-406f-b242-4e95f02242c1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d075e4c4-8672-4297-9e88-9f7447f62c94",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2544,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                    "name": "Has your school published its EAL Policy?",
                                                    "descriptionHtml": "<p>It is strongly recommended for your school to publish the particulars of educational and welfare provision for pupils with EAL – this could be achieved by publishing an EAL Policy on the school website.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:46:11.345+00:00",
                                                    "originKey": "ebb94cd2-a9e6-4e9a-8898-883e18a0d067",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>The Education (Independent School Standards) Regulations 2014</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "46b490d9-5a13-47d9-9d1f-fe38d592feb4",
                                                            "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5acbdbef-1696-43c9-8d1d-7a7693a52e44",
                                                            "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c9d6227-1a37-4c78-b092-bedd8b3b7dce",
                                                            "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "956f4ee7-ac16-446f-aa93-0b672279bd58",
                                                            "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0e6840d9-a67e-4992-bc0c-7c0e6106cab9",
                                                            "requirementId": "f46e3d5e-3678-4b88-b6c1-fa62eda838d5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a353a826-8d2c-42a4-8463-39fa9efc2c1b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2545,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                    "name": "Has your school published its financial information?",
                                                    "descriptionHtml": "<p>Your school is strongly recommended to publish the following information relating to finances and financial regulation:</p><ul><li>Annual report</li><li>Annual audited accounts</li><li>Memorandum of association</li><li>Articles of association</li><li>Names of trustees and members</li><li>Funding agreement</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:47:20.046+00:00",
                                                    "originKey": "9864eec7-afb9-45fb-8309-c62775eea6d7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>The Education (Independent School Standards) Regulations 2014</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "13ad2758-3d87-447e-b467-6e25b6d74819",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2fc43ca8-191b-4b37-8920-44a70503bd22",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c55aff32-0a07-4825-90ab-df96c2515ad5",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d1d5e8ac-9f1e-45be-8f55-abcc743d4700",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "237cecb2-224d-4bdd-95f3-c952f4307648",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "66598729-a7bb-4594-b98b-dcd2c5f523de",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2547,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                    "name": "Has your school published its financial information?",
                                                    "descriptionHtml": "<p>Your school is strongly recommended to publish the following information relating to finances and financial regulation:</p><ul><li>Annual report</li><li>Annual audited accounts</li><li>Memorandum of association</li><li>Articles of association</li><li>Names of trustees and members</li><li>Funding agreement</li></ul>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:47:20.046+00:00",
                                                    "originKey": "9864eec7-afb9-45fb-8309-c62775eea6d7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>The Education (Independent School Standards) Regulations 2014</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "13ad2758-3d87-447e-b467-6e25b6d74819",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2fc43ca8-191b-4b37-8920-44a70503bd22",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c55aff32-0a07-4825-90ab-df96c2515ad5",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d1d5e8ac-9f1e-45be-8f55-abcc743d4700",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "237cecb2-224d-4bdd-95f3-c952f4307648",
                                                            "requirementId": "4e446b4c-eb39-4c85-b532-1fce9f929d34",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a86d9fc3-242b-4140-8dc2-7a9b585f1a68",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2549,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                    "name": "Has your school published its first aid procedures?",
                                                    "descriptionHtml": "<p>It is strongly recommended for academies to publish their first aid procedures on their website; however, it is not statutory to do so, nor is it a statutory requirement to have a standalone First Aid Policy. First aid procedures in schools must be covered, but may be included in the school’s Health and Safety Policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:50:23.592+00:00",
                                                    "originKey": "43d8278f-cc37-42ab-b1ee-085d571223c4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>The Education (Independent School Standards) Regulations 2014</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "61261ea7-33e5-4287-9f14-fef2c88d3346",
                                                            "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a1818f8-a3e3-4c89-a8e8-c273aad42e57",
                                                            "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b90e823b-f6de-41e7-8db4-11f3a7144ba7",
                                                            "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e3592f4f-48a8-42f9-b319-fe3f6371bbbc",
                                                            "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "beca854e-2c50-4ba6-8ede-583094a574f0",
                                                            "requirementId": "c4098f54-4358-48fd-9dae-097cad179ef2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f9d7e50b-0690-43d5-9420-776096edf7a5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2553,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                    "name": "If your school has 250 or more employees, has the school published its public sector apprenticeship target?",
                                                    "descriptionHtml": "<p>Schools with 250 or more employees are no longer required to publish this information; however, it is strongly recommended that your school does so.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T11:02:02.499+00:00",
                                                    "originKey": "73e4f7dd-eaca-4088-9d1b-b443a00173b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE (2022) Public sector apprenticeship target</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "102f612a-b333-4894-8f73-76f0fa3a892e",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "25897b4c-3e68-4e74-8211-829899095c37",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "83bc2c1f-280e-42b6-814f-ef0e5355a13b",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cb5d4a98-1156-43bf-a2d8-a9a2b794882d",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1acde5fb-8b7d-40e6-81eb-da1d6ede8570",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d815d136-4f6c-402d-8de1-9045ac283eff",
                                                            "requirementId": "ebba02db-6ba7-4846-b505-6bf01883049c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9356848d-3333-48cd-9e25-3264ebbd2f1a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 2554,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "60144387-79d5-4bac-a837-820fd540eff3",
                                                    "name": "Has your school published its RSHE Policy?",
                                                    "descriptionHtml": "<p>Your school is required to have an RSHE Policy (or RHE Policy if your school is a primary school not teaching sex education) and should publish it on the school’s website. It is not a statutory requirement to publish the RSHE Policy on the school website; however, it must be readily accessible to parents and members of the public who wish to view it. Ofsted expects the RSHE Policy to be published on the school’s website. You could link to where this information is published as evidence for this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T11:06:02.56+00:00",
                                                    "originKey": "ac611064-bf50-4679-8226-585ccb6d93f2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE RSHE guidance</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3f731684-4b6e-4ece-a440-06701582043a",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9da8de3e-312d-43b3-8b75-cc2b5411ee69",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bb27c716-8f11-4b25-a369-f0c2831c3d71",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f369da2e-6808-4990-a817-c95f857476b2",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "64234031-6203-4d04-bd89-42c7ccb64455",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bffb63ea-a9e9-4e87-a566-34a2e1b1145b",
                                                            "requirementId": "60144387-79d5-4bac-a837-820fd540eff3",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ba82e381-620d-4990-8e7a-dd52c49b0d07",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2555,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                    "name": "Does the school maintain an assessment of risks to data protection that are considered to be high or medium?",
                                                    "descriptionHtml": "<p>Following a data audit, an assessment of risks to data protection that will be considered by the school to be high or medium should be maintained. Schools should clearly identify what these risks are and how they are being addressed. This could include identifying any shortcomings in the school’s network security infrastructure and keeping data security policies up-to-date. This should be documented as evidence towards compliance.</p><p><br></p><p>You may wish to provide evidence relating to your school’s assessment of risks.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T15:39:21.409+00:00",
                                                    "originKey": "c3b5ff05-766c-4327-957f-feb7b982a8e5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Data Protection Toolkit for Schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "23d95936-4f5e-4b9c-a1f3-516ed4b8e64f",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "330201b7-4b17-4f3a-9085-a682c1acd184",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e512ea52-f1c3-4da0-bc68-236565449b18",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e6d45297-da5b-4e5e-9333-8dea0fa8d877",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3a25c794-5a69-41a0-b88b-b43db1ca9b95",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f805567d-44bc-4bd0-9d8d-59495cad6229",
                                                            "requirementId": "3c44c6e7-35f4-4f6d-8fac-212f212ff953",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4b544a09-3ff8-4dcc-baff-f0731547b6b1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 2556,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                    "name": "Does the school have agreed procedures for handling a request for erasure?",
                                                    "descriptionHtml": "<p>Individuals have the right to have their personal data erased and may make requests to schools to erase the data the school holds on them. It is important that schools have a documented procedure setting out how they deal with erasure requests and that this process is made available to the school community.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school’s procedure.&nbsp;&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T15:40:03.504+00:00",
                                                    "originKey": "a50b3c6b-e884-4d6a-bc96-5f24565fda51",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/right-to-erasure/&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2184ecb3-ed38-432d-b43e-0ee6c6e9b010",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2642f84a-8e35-452c-9cc7-1e159881d147",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3bcbe445-3bcc-4b62-aa7a-39588b04b4c4",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "46254ec0-938c-4860-adc8-00d5a76a7bdb",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "bd7ce5db-628e-4565-b86a-67790ace20bd",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c6326d9b-2abf-47e6-9cb1-a6151f0928a8",
                                                            "requirementId": "203a9598-0c62-409e-bbdd-14c41a514475",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "65a4b6e0-6c84-4be1-9f42-f0d80e045857",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2558,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                    "name": "Does the DSL understand their responsibilities with regards to sharing information and data for safeguarding purposes?",
                                                    "descriptionHtml": "<p>Data protection legislation does not prevent, or limit, the sharing (or withholding) of information for the purposes of keeping children safe. When DSLs are considering whether to share safeguarding information, it is considered best practice to record who they are sharing that information with and for what reason.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:53:18.908+00:00",
                                                    "originKey": "ed9739c5-75b2-4a89-9164-55611b40319b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "015dab0f-3e0a-4785-a5e9-f3778ee355e0",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1a2389e6-2c5d-456b-aba1-e925df7b88d8",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "988bc7ca-5d95-45c1-bdf6-41f455c82d05",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3da42a9-4ee0-4f53-8d47-610efa0b4a98",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "73d699c9-6a98-4e21-ac1e-35330a150710",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b3c491dc-b121-468b-a00e-fc7ad85b4f46",
                                                            "requirementId": "ee529454-13a9-49ae-a13f-195522a16f8e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "fdfd5888-a684-4bf6-a120-25c722cca967",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2561,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                    "name": "Does the school have procedures in place for reporting an illegal online safety incident?",
                                                    "descriptionHtml": "<p>If an online safety incident is illegal in nature, schools should collect all of the available evidence and ensure the incident is reported to the relevant authority.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:41:31.985+00:00",
                                                    "originKey": "89cd774f-6d75-4302-8294-82c6611c1996",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a1aae97-13e3-4a85-aeb5-e5e9946d91d5",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e4f1908-ee7e-43ab-8703-897e13b6f5f5",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6f032f41-4a51-433b-ad23-9dd2625c5c5d",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cbb42a2e-db00-4a50-a8d7-2f73f5c50b58",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9209e73a-7243-4c22-98a2-09faa4f5a5fb",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a00f95b5-ec05-4c1e-959e-e03880728744",
                                                            "requirementId": "a29c4954-644f-4f01-9004-95f1e1b42a16",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "caf75b32-e2c2-4f9f-89b4-06dccc2a1ef2",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2562,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                    "name": "Has the school provided all staff with the appropriate training to be able to manage a safeguarding report?",
                                                    "descriptionHtml": "<p>All staff should be trained to manage a safeguarding report. Local policies and training should dictate exactly how reports should be managed.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T08:39:08.94+00:00",
                                                    "originKey": "b6a9da6a-5100-4461-a979-4f1c5aa6b682",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "20d75bc1-fd82-43d3-b4cb-147892f9192c",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "57e54f3e-841c-448a-8f5f-3841c71bac58",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6852a23a-f047-4925-960f-0f0bc9150d03",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7136763b-3879-4f19-98b3-f8bd70a03112",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6efd5f96-bde0-4504-b19a-2698b2dd3c42",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e623b4de-ac25-468b-b1d8-e47af4b0846c",
                                                            "requirementId": "a57f32ff-00e8-4f91-9f9f-9b0fb87b2e40",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9e4cea84-53b0-462b-941d-8e8720cda9dd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2565,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                    "name": "Are there clear processes and principles for sharing and withholding information for the purposes of safeguarding children that are communicated to all staff?",
                                                    "descriptionHtml": "<p>Schools should ensure that all staff members are aware of their expectation to share information that might be critical in keeping children safe. The DSL or deputy should always be available to discuss safeguarding concerns and staff members who have such concerns should share these in accordance with the DfE's 'Information sharing' guidance.</p><p><br></p><p>Information sharing should be necessary and proportionate, relevant, adequate, accurate, timely, secure and kept on record.&nbsp;</p><p><br></p><p>You may wish to evidence this requirement by linking to or attaching relevant&nbsp;procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:43:37.774+00:00",
                                                    "originKey": "a3a2a9e5-f5cb-44ea-8d10-c21626067e67",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "63091f54-7b63-464b-a246-0b2b4c7c5d6e",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a1b31511-69b6-415f-91bf-4abb50b3fe50",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b975033c-219a-4aee-9d40-41de0bc4daa4",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c29fc5eb-3c08-46cc-b027-ab96b30881a4",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3967c571-caf8-4151-a35e-be34509ef0bd",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "70dd8c9a-9de6-4cb6-8144-7ee88811874c",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dd73287a-f5d9-4e99-87af-cc9ec6a4c9d8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2566,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                    "name": "Are procedures in place for providing early help?",
                                                    "descriptionHtml": "<p>Early help means providing support as soon as a problem emerges at any point in a child's life – schools should ensure that procedures are incorporated to prepare staff to be able to identify children that may benefit from early help, e.g. pupils with disabilities, mental health needs or those who show signs of being drawn in to anti-social or criminal behaviour. Schools should ensure they are aware of the local procedures in place for providing early help.&nbsp;</p><p><br></p><p>You may wish to evidence this requirement by linking to or attaching relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:44:37.281+00:00",
                                                    "originKey": "d65025c9-fde7-4deb-af98-ccdf7770450b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6f174512-dd4b-4267-9527-b2231746650b",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7aba91f8-2460-4308-a6b3-3827952c317b",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8d8cd8f-d235-413a-9ae0-af172d5af689",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7015549-002c-45cd-b1c1-6f226acbb743",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2f04d777-3f8f-4c54-b6fb-27bbfa6ebf41",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "71d6d47a-80f1-491d-b5b3-95ff6873087a",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0555f867-a0b5-4b22-a332-63be033bef9f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2567,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                    "name": "Is there a senior individual within the governing board designated to take leadership responsibility for safeguarding arrangements?",
                                                    "descriptionHtml": "<p>The 'Governance handbook' states that all governing boards should have an individual to take leadership responsibility for the school’s safeguarding arrangements, which include the Prevent duty.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:45:30.893+00:00",
                                                    "originKey": "ef7613d6-baaf-4bf3-8d8a-ca8e8a89fcfa",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance handbook</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "188ae461-9598-4cb8-8d9f-d992818afa58",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ece377c-ea5f-4106-a8b0-bcd563e515f2",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f2d2edd-e132-43cb-b5ab-2e1933bc3e78",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a9db32e-eb8b-4f03-916d-bc35b978948a",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0e33d607-1f01-4bb8-b253-38a18f4dc028",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b88f4448-0ba7-493f-bfb8-35af0b9b2cf7",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d8a99552-914e-4312-aa07-2f0c45245a68",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2568,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                    "name": "Has the governing board or proprietor arranged sufficient safeguarding training for all staff and governors which is refreshed annually?",
                                                    "descriptionHtml": "<p>Governing boards and proprietors should ensure that, as part of the requirement for staff to undergo regularly updated safeguarding training, safeguarding training for staff is integrated, aligned and considered as part of the whole-school safeguarding approach and wider training and curriculum planning.</p><p><br></p><p>You may wish to attach or link to evidence of training taking place to demonstrate compliance with this requirement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:46:26.372+00:00",
                                                    "originKey": "4b457ca4-86b9-449d-b101-ed7b80e43db7",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "697cc939-7b1b-43e5-b275-bbc32f9913e6",
                                                            "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b353d2d7-b8bb-45dc-9e88-c66674965c41",
                                                            "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c5a8288a-79a9-47e1-8bda-aff56ba5e991",
                                                            "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0f11369a-7340-4b76-86dd-9dbef40ef6e1",
                                                            "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "59da90de-efb4-4ac5-b39d-ec567a092baa",
                                                            "requirementId": "9560a440-e140-4b3d-8a4d-5f6b18593091",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "509566e6-c741-417a-91c8-33f81ad83c3e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2569,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                    "name": "Has the governing board implemented a proportionate, risk-based approach to requesting information from temporary staff and volunteers?",
                                                    "descriptionHtml": "<p>‘Keeping children safe in education’ states that governing boards and proprietors should take a proportionate risk-based approach to the level of information that is provided to temporary staff, volunteers and contractors.</p><p><br></p><p>Under no circumstances should volunteers who have undergone no checks be left unsupervised or allowed to work in regulated activity. Schools should undertake a written risk assessment and use their professional judgement and experience when deciding what checks, if any, are required. Section three of the safer recruitment part of ‘Keeping children safe in education’ provides further information.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:47:50.092+00:00",
                                                    "originKey": "b1e42b84-772d-44ed-aac1-235ce278bde6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "20f685a6-ecf7-49fd-9fcb-3dcc2a43631a",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "453beccd-2f03-42ea-aedf-94ae2dd33e6f",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "63382297-2bf2-4e4f-bb8e-7cd3e8b548b7",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f00a6783-066f-480d-8e3c-275fd435d69f",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4521225c-fc99-429f-96b2-f4d0e57e29c8",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "888cf778-e15e-437a-96e4-b16f4d97123b",
                                                            "requirementId": "669f8ee3-8af8-4ba5-8c78-04aa3798a8be",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "34b6d261-8245-4796-84b4-42a79a878bba",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2571,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                    "name": "Are procedures implemented and well-promoted so that pupils can easily and confidently report abuse?",
                                                    "descriptionHtml": "<p>Systems should be in place, and they should be well promoted, easily understood and easily accessible for children to confidently report, any form of abuse or neglect, knowing their concerns will be treated seriously, and knowing they can safely express their views and give feedback.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:49:22.262+00:00",
                                                    "originKey": "2e241354-5388-4cae-8066-96935f56dde8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "414efadb-410a-48a6-bac2-7f26178c9011",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "48aea523-bd0c-4906-b421-f21ffc0f8d1f",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ef0f9e2-09cb-44f2-a212-73a44d5ce169",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c21730b5-dfd6-4b53-b656-caa1aae2a426",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7270a6e3-24d1-43af-b7e8-0e5dabf673db",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "89c22e95-2ddf-41f0-9062-6bf36494c1a9",
                                                            "requirementId": "f34ec93c-dcfe-40b1-8be9-44f55a4e5515",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "10427333-858d-497f-9d83-4941174bdb6b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2572,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                    "name": "Does the school have procedures in place for staff to be able to report safeguarding concerns about other staff members?",
                                                    "descriptionHtml": "<p>Procedures should be in place to report concerns about a member of staff posing a risk to pupils – these should be reported to the headteacher. Where the concerns regard the headteacher, procedures should be in place for these to be referred to the chair of the school’s governing board or, where required, the LA’s designated officer(s) (LADOs).</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:50:19.883+00:00",
                                                    "originKey": "53089634-86ae-4ed5-8f55-172f51b09db5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "bdbd441b-92a6-4667-84bb-76e552b8f64b",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1802dc5-3a5a-46f8-80f1-54acdefd5c66",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7b1ae77-2a26-4250-9537-ffa017c6649a",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dea529c7-0783-44d8-bb73-8672e9dc1a14",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0be83f11-bde6-4174-a6b7-a1f993253102",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "51d465ac-6e19-4a73-895f-abe20bd1ac26",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cd370260-1da1-4776-84fb-abe863bfe90c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 2574,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                    "name": "Does the school have procedures covering how to protect pupils who are at risk of virginity testing and hymenoplasty?",
                                                    "descriptionHtml": "<p>The government has made it illegal to carry out, offer, or aid and abet virginity testing or hymenoplasty in any part of the UK, as part of the Health and Care Act 2022. It is also illegal for UK nationals and residents to do these outside the UK.</p><p><br></p><p>Schools should address how pupils will be protected using existing structures, policies and procedures designed to safeguard children.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school's relevant policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:52:18.044+00:00",
                                                    "originKey": "64da41a0-ec5c-4be3-86eb-cc3fc9398250",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DHSC 'Virginity testing and hymenoplasty: multi-agency guidance'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "087f35e1-b80b-4315-8e41-2f953558b5cc",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6dcf5f56-a142-4b5d-81b6-b1e9fa23af35",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "98f20080-7e2f-4270-93d4-bde75bf974dd",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d11d4809-a478-4c0b-a901-8149770e90b9",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "720dd7bc-9064-4c16-83a9-7c05ac17cd2b",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8c7a821c-3741-4eb8-85ac-f1c1836f0769",
                                                            "requirementId": "41a46462-1a44-49ed-8f32-23b67cfdba74",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "87fbf999-ee34-47c1-904a-a590178e0c90",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2577,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                    "name": "Does the school have an Online Safety Policy?",
                                                    "descriptionHtml": "<p>‘Keeping children safe in education’ (KCSIE) stipulates that schools should have a clear policy on the use of mobile and smart technology which considers the ‘4Cs’ – content, contact, conduct and commerce – as outlined in the Online safety subsection of KCSIE.</p><p><br></p><p>Schools should carefully consider how they will manage pupils’ engagement in harmful content, online harassment and sharing indecent images.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:28:12.663+00:00",
                                                    "originKey": "7d3021ed-30cf-443a-b6fc-4a53d9017c41",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "32038aac-708d-455f-973f-2ecc3fb02292",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dbe912b-6fd9-40e4-b1eb-e2ad8541f2af",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e430b4f-68e6-4084-b87e-4b40844b5fce",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d19b7baa-94ee-4aa4-a508-d4a155962a99",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "67813d2f-a22f-4949-b7fc-31c405af2c2c",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "828b7768-870b-4515-891e-7c052d58a8fe",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d0c6a66d-7b50-4cb0-9b76-1e0c7326bdfc",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2578,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                    "name": "Are there effective policies and procedures in place to prevent child-on-child abuse?",
                                                    "descriptionHtml": "<p>Schools should have a clear policy and procedure with regard to child-on-child abuse and ensure that all staff members are aware of, and understand the importance of challenging, inappropriate behaviours between peers.</p><p><br></p><p>Child-on-child abuse can include, but is not limited to, bullying, abuse in personal peer relationships, physical abuse,</p><p>sexual violence, sexual harassment, causing someone to engage in sexual activity without consent, sharing nude and semi-nude images and upskirting.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:53:58.115+00:00",
                                                    "originKey": "d2f555a2-7dcf-447e-8fb6-ca259ececa3a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08ca3089-66e1-47dd-a60b-500ddfdee984",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "32330e6c-f442-419d-891f-3bacb19fca51",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "443c789d-e6cb-4ca1-921a-d7d63b2d7727",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "afeb9a4a-dcd5-4188-a246-9939ecfb48e1",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2bcc8bc8-f389-4350-85b8-a72dac1e5686",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d8a2f0ee-1135-43b6-90ee-786acdd2586e",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "98dbd2ac-71f9-4e1b-af8d-87b22b3b86c5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2580,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                    "name": "Does the school have an Online Safety Policy?",
                                                    "descriptionHtml": "<p>‘Keeping children safe in education’ (KCSIE) stipulates that schools should have a clear policy on the use of mobile and smart technology which considers the ‘4Cs’ – content, contact, conduct and commerce – as outlined in the Online safety subsection of KCSIE.</p><p><br></p><p>Schools should carefully consider how they will manage pupils’ engagement in harmful content, online harassment and sharing indecent images.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:28:12.663+00:00",
                                                    "originKey": "7d3021ed-30cf-443a-b6fc-4a53d9017c41",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "32038aac-708d-455f-973f-2ecc3fb02292",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4dbe912b-6fd9-40e4-b1eb-e2ad8541f2af",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e430b4f-68e6-4084-b87e-4b40844b5fce",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d19b7baa-94ee-4aa4-a508-d4a155962a99",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "67813d2f-a22f-4949-b7fc-31c405af2c2c",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "828b7768-870b-4515-891e-7c052d58a8fe",
                                                            "requirementId": "4e4d4125-6795-4954-b29e-d0ba96c9ee3f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "944ea3b3-3ad6-4347-b4e5-a7983606935e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2581,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                    "name": "Are there effective policies and procedures in place to prevent child-on-child abuse?",
                                                    "descriptionHtml": "<p>Schools should have a clear policy and procedure with regard to child-on-child abuse and ensure that all staff members are aware of, and understand the importance of challenging, inappropriate behaviours between peers.</p><p><br></p><p>Child-on-child abuse can include, but is not limited to, bullying, abuse in personal peer relationships, physical abuse,</p><p>sexual violence, sexual harassment, causing someone to engage in sexual activity without consent, sharing nude and semi-nude images and upskirting.&nbsp;</p><p><br></p><p>You may wish to attach or link to your school's relevant policies.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T12:53:58.115+00:00",
                                                    "originKey": "d2f555a2-7dcf-447e-8fb6-ca259ececa3a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 20,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08ca3089-66e1-47dd-a60b-500ddfdee984",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "32330e6c-f442-419d-891f-3bacb19fca51",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "443c789d-e6cb-4ca1-921a-d7d63b2d7727",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "afeb9a4a-dcd5-4188-a246-9939ecfb48e1",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2bcc8bc8-f389-4350-85b8-a72dac1e5686",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d8a2f0ee-1135-43b6-90ee-786acdd2586e",
                                                            "requirementId": "8585970e-f1e4-4d5b-9cbb-c4f692e2df38",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5551cfdd-a98d-4df0-9aef-88d3f1415154",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2584,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                    "name": "Are there clear processes and principles for sharing and withholding information for the purposes of safeguarding children that are communicated to all staff?",
                                                    "descriptionHtml": "<p>Schools should ensure that all staff members are aware of their expectation to share information that might be critical in keeping children safe. The DSL or deputy should always be available to discuss safeguarding concerns and staff members who have such concerns should share these in accordance with the DfE's 'Information sharing' guidance.</p><p><br></p><p>Information sharing should be necessary and proportionate, relevant, adequate, accurate, timely, secure and kept on record.&nbsp;</p><p><br></p><p>You may wish to evidence this requirement by linking to or attaching relevant&nbsp;procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:43:37.774+00:00",
                                                    "originKey": "a3a2a9e5-f5cb-44ea-8d10-c21626067e67",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "63091f54-7b63-464b-a246-0b2b4c7c5d6e",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a1b31511-69b6-415f-91bf-4abb50b3fe50",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b975033c-219a-4aee-9d40-41de0bc4daa4",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c29fc5eb-3c08-46cc-b027-ab96b30881a4",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3967c571-caf8-4151-a35e-be34509ef0bd",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "70dd8c9a-9de6-4cb6-8144-7ee88811874c",
                                                            "requirementId": "a0bf66c3-dae5-4b4b-a746-e07aa106c7b8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bac6649e-c5aa-4759-b987-a9431fe9d2d9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2585,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                    "name": "Are procedures in place for providing early help?",
                                                    "descriptionHtml": "<p>Early help means providing support as soon as a problem emerges at any point in a child's life – schools should ensure that procedures are incorporated to prepare staff to be able to identify children that may benefit from early help, e.g. pupils with disabilities, mental health needs or those who show signs of being drawn in to anti-social or criminal behaviour. Schools should ensure they are aware of the local procedures in place for providing early help.&nbsp;</p><p><br></p><p>You may wish to evidence this requirement by linking to or attaching relevant procedures.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:44:37.281+00:00",
                                                    "originKey": "d65025c9-fde7-4deb-af98-ccdf7770450b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6f174512-dd4b-4267-9527-b2231746650b",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7aba91f8-2460-4308-a6b3-3827952c317b",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8d8cd8f-d235-413a-9ae0-af172d5af689",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b7015549-002c-45cd-b1c1-6f226acbb743",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2f04d777-3f8f-4c54-b6fb-27bbfa6ebf41",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "71d6d47a-80f1-491d-b5b3-95ff6873087a",
                                                            "requirementId": "1feb65b2-e16e-49b2-b6e4-0ddbb2dd019b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "2f90a362-5ecc-49fa-ab71-bf54279d6f10",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2586,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                    "name": "Are all safeguarding incidents and allegations, and all actions taken in response, recorded comprehensively?",
                                                    "descriptionHtml": "<p>All concerns, discussions and decisions made, and the reasons for those decisions, should be recorded in writing. Information should be kept confidential and stored securely. The DfE states that it is good practice to keep concerns and referrals in a separate child protection file for each child.</p><p><br></p><p>Records should include a clear and comprehensive summary of the concern, details of how the concern was followed up and resolved and a note of any action taken, decisions reached and the outcome.</p><p><br></p><p>You may wish to attach or link to evidence that there are processes in place to record all concerns and incidents.</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:49:39.492+00:00",
                                                    "originKey": "8522a2dd-e69e-4110-a17d-b7c694b0f2d2",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "49504d8f-25fb-40c0-a4a4-304b2a03e1ee",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6e2d3813-0189-47a1-ad08-c6b1f65a3578",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b96e05fb-40fa-4ed9-bd47-d944b2604970",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e8590f3c-d89d-42e5-8a8b-fa846f9eb2d7",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1b28bdc2-bc23-4197-a460-efb9ce2b5f9e",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "cfb42071-8afb-4261-a815-b40921b6e675",
                                                            "requirementId": "25a478f2-3301-4019-a83a-8c1f2bcfd771",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "64cc6a19-f92a-4c0a-9448-74531ab53e99",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2587,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                    "name": "Has the school used records of previous safeguarding incidents and responses to develop and enhance its policies, procedures and wider safeguarding practices?",
                                                    "descriptionHtml": "<p>Schools should make sure they evaluate their response to any safeguarding concerns and/or incidents. The DSL and other relevant staff should consider the facts associated with all safeguarding concerns and determine whether any improvements can be made.</p><p><br></p><p>In terms of allegations against staff where the allegation is found to be substantiated, the LADO should review the circumstances of the case with the case manager to determine whether there are any improvements to be made to the school’s procedures to help prevent similar events in the future.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:22:37.601+00:00",
                                                    "originKey": "62f5e1c1-96f0-40ab-b6be-29bf053da2ef",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually or since previous inspection.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "486c4888-e1a7-408f-9767-1aba904914a4",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6566a872-e8b4-4600-957e-d18c01d4be00",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "945e98aa-1b2e-43ad-8dc1-2eb8f6fd300b",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acfdf7f4-6255-4f17-bad4-cc66d3c85362",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a9437531-bb9b-47be-8c2b-4874817fb907",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f0d4fcd6-a86f-4934-8a1c-9e266985351b",
                                                            "requirementId": "fec0bf26-5f95-4d1a-a3a8-fe4f67430d58",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "37ebe52d-8ec8-4dc0-b27d-c07f323a3a42",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2588,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                    "name": "Has the governing board ensured there are appropriate filters and monitoring in place for pupils’ internet use that are appropriate to the relevant age range?",
                                                    "descriptionHtml": "<p>Schools should ensure their school has appropriate filters and monitoring systems in place whilst considering the age range and number of pupils and how often they access the ICT system and the proportionality of costs vs risks. The appropriateness of any filters and monitoring systems are a matter for individual schools and will be informed in part by the risk assessment required by the Prevent duty.</p><p><br></p><p>You may wish to attach or link to evidence that the appropriate systems are in place.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-07T13:30:06.226+00:00",
                                                    "originKey": "c162a831-2ba1-4118-b051-c4cb5acdb26a",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "<p><br></p>",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "41c396e0-a2c3-44fc-9048-89eeffc3d7ff",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4c258d9a-ca76-4da4-9d87-ad493ded2c3c",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7c39feb4-5cd7-4561-830c-046825490a8f",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e429272f-7600-4816-a8ae-c80659cb0e83",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7d1ad248-b134-44a1-b052-de3af1451b3e",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f1a000d6-0bbc-4591-a9d5-023de879f25a",
                                                            "requirementId": "ac4976c6-b126-49e7-97a5-46c9ec03be93",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "710ee836-ddba-4600-a1be-2bd15ac7cb30",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2589,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                    "name": "Has the school developed a curriculum that ensures pupils are taught how to stay safe from harm, both offline and online?",
                                                    "descriptionHtml": "<p>Schools should consider safeguarding whilst planning the curriculum, any teacher training, the role and responsibilities of the DSL and any parental engagement. Schools should ensure that pupils are taught about safeguarding, including online safety, and recognise that a one size fits all approach may not be appropriate for all children.&nbsp;</p><p><br></p><p>It is important to consider how online safety can be taught as part of a broad and balanced curriculum. This may include covering relevant issues through RSHE.&nbsp;</p><p><br></p><p>You may wish to attach or link to evidence that shows your school has considered how safeguarding should be taught to pupils.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-08T12:28:17.55+00:00",
                                                    "originKey": "ade0d2a0-f381-4db5-b9e8-fb69906c47a6",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c1884e5-250e-4123-a674-f7e2c66e8729",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b88a9e5f-0471-42c5-aaf3-94a9cf5649f8",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cb407473-cc0c-42dd-b559-81a1b7038c06",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5758a00-6c21-426d-a4bb-8b2424bd2ab0",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8a39caca-dc89-4752-b261-d3271b20b78f",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "f3271b3e-297d-4d98-bee3-ffef21754b66",
                                                            "requirementId": "546549c9-6819-4bcb-94cf-7a1fe94d4365",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "dfbd238f-08b4-4519-a17f-b991517ecc8f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 2590,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                    "name": "Is there a senior individual within the governing board designated to take leadership responsibility for safeguarding arrangements?",
                                                    "descriptionHtml": "<p>The 'Governance handbook' states that all governing boards should have an individual to take leadership responsibility for the school’s safeguarding arrangements, which include the Prevent duty.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:45:30.893+00:00",
                                                    "originKey": "ef7613d6-baaf-4bf3-8d8a-ca8e8a89fcfa",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance handbook</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "188ae461-9598-4cb8-8d9f-d992818afa58",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ece377c-ea5f-4106-a8b0-bcd563e515f2",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f2d2edd-e132-43cb-b5ab-2e1933bc3e78",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8a9db32e-eb8b-4f03-916d-bc35b978948a",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0e33d607-1f01-4bb8-b253-38a18f4dc028",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b88f4448-0ba7-493f-bfb8-35af0b9b2cf7",
                                                            "requirementId": "93331908-f04f-4533-bd83-cf91af8eaadd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9d4726ff-f093-489e-b9c3-42cfdc6fb5e3",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2591,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                    "name": "Does the school have procedures in place for staff to be able to report safeguarding concerns about other staff members?",
                                                    "descriptionHtml": "<p>Procedures should be in place to report concerns about a member of staff posing a risk to pupils – these should be reported to the headteacher. Where the concerns regard the headteacher, procedures should be in place for these to be referred to the chair of the school’s governing board or, where required, the LA’s designated officer(s) (LADOs).</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:50:19.883+00:00",
                                                    "originKey": "53089634-86ae-4ed5-8f55-172f51b09db5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "bdbd441b-92a6-4667-84bb-76e552b8f64b",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1802dc5-3a5a-46f8-80f1-54acdefd5c66",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7b1ae77-2a26-4250-9537-ffa017c6649a",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dea529c7-0783-44d8-bb73-8672e9dc1a14",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0be83f11-bde6-4174-a6b7-a1f993253102",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "51d465ac-6e19-4a73-895f-abe20bd1ac26",
                                                            "requirementId": "15eefd02-5b53-4844-94a9-e002ed98fe4c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4b686d1c-fac3-4565-a737-8112047c9bcd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2600,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                    "name": "Are procedures in place to retender the external auditor's contract at least every five years?",
                                                    "descriptionHtml": "<p>Regarding the appointment of external auditors, the ATH says that trusts should retender their extenal auditor contract at least every five years. When evaluating, the audit and risk committee must have regard to its duties under the ATH.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:26:34.563+00:00",
                                                    "originKey": "e2a13795-a338-4e08-9210-c70e08ac062e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "142dd029-7833-4052-99de-7b3f5581eb20",
                                                            "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2514f00b-14f6-4669-bd4a-0d91184ec52e",
                                                            "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "38f91857-01ec-49e1-8c9e-879d5d311672",
                                                            "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4519bc2a-9fe2-4b9b-8e49-bde401e37e23",
                                                            "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "390d2da0-2e4b-456c-8aa4-5f3769479323",
                                                            "requirementId": "3bb9797a-28f8-4150-9c7e-d169f6ba68e3",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e7cc1aed-759f-4eef-9216-afbd9fbf1cf9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 2603,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                    "name": "Does the school have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>All schools should have whistleblowing procedures in place. These should be based on your LA's procedure; the DfE advises asking your LA for a copy of their whistleblowing arrangements and tailoring them to fit your school’s circumstances. You should appoint at least one member of staff and at least one governor who other members of staff can contact if they wish to report concerns.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:39:39.723+00:00",
                                                    "originKey": "a1e520a7-1c45-41e8-b129-11781c8ab131",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Whistleblowing procedures for maintained schools</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0b0a1e03-4ae5-4030-9ea2-498118e168b1",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "246793e3-0da2-444d-9ef6-f493e8aee2b6",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3fded9cc-4940-4830-8a97-7ed02b4deb51",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "75d506a0-1f21-4533-a8c1-db663a8535bf",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "31e49712-ac32-494e-be32-eaac4953cfc3",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bc1967cf-d100-4c7b-9330-a053e54df75a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2604,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                    "name": "Does the school have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>All schools should have whistleblowing procedures in place. These should be based on your LA's procedure; the DfE advises asking your LA for a copy of their whistleblowing arrangements and tailoring them to fit your school’s circumstances. You should appoint at least one member of staff and at least one governor who other members of staff can contact if they wish to report concerns.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:39:39.723+00:00",
                                                    "originKey": "a1e520a7-1c45-41e8-b129-11781c8ab131",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Whistleblowing procedures for maintained schools</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0b0a1e03-4ae5-4030-9ea2-498118e168b1",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "246793e3-0da2-444d-9ef6-f493e8aee2b6",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3fded9cc-4940-4830-8a97-7ed02b4deb51",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "75d506a0-1f21-4533-a8c1-db663a8535bf",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "31e49712-ac32-494e-be32-eaac4953cfc3",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bb340c57-2645-4428-8403-1ce412fc7a80",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2606,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                    "name": "Does the school have a Whistleblowing Policy?",
                                                    "descriptionHtml": "<p>All schools should have whistleblowing procedures in place. These should be based on your LA's procedure; the DfE advises asking your LA for a copy of their whistleblowing arrangements and tailoring them to fit your school’s circumstances. You should appoint at least one member of staff and at least one governor who other members of staff can contact if they wish to report concerns.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:39:39.723+00:00",
                                                    "originKey": "a1e520a7-1c45-41e8-b129-11781c8ab131",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Whistleblowing procedures for maintained schools</p>",
                                                    "policyManagerId": 78,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0b0a1e03-4ae5-4030-9ea2-498118e168b1",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "246793e3-0da2-444d-9ef6-f493e8aee2b6",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3fded9cc-4940-4830-8a97-7ed02b4deb51",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "75d506a0-1f21-4533-a8c1-db663a8535bf",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "31e49712-ac32-494e-be32-eaac4953cfc3",
                                                            "requirementId": "94fa35d1-528b-4366-aeec-3ffdeb4b831a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bf6cea6f-1952-4d1d-8acf-b07afc519d02",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2617,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                    "name": "Are procedures in place to control credit card use and fully clear balances each month?",
                                                    "descriptionHtml": "<p>Where permitted by the LA, schools can use credit or charge cards; however, no interest charges should be incurred by the school, with balances fully cleared on a monthly basis. Use should also be controlled, with no unauthorised or inappropriate purchases.</p><p><br></p><p><strong>Schools that do not use credit cards should select 'Does not apply' for this requirement.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T08:54:34.457+00:00",
                                                    "originKey": "f6bd609e-4d62-447b-974d-aceaa4266c46",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.gov.uk/government/publications/schemes-for-financing-schools/schemes-for-financing-local-authority-maintained-schools</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "297100af-cb65-46ef-a99f-dd4185f880c3",
                                                            "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "374dc770-6de6-4bb4-9d59-ac05bc3bd506",
                                                            "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "639b909b-646e-4b75-aaa0-b93d7778f668",
                                                            "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a708cdc6-4dfe-4906-8385-610353dc1684",
                                                            "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "7550519b-6129-419b-b38c-869f165500df",
                                                            "requirementId": "e5efd679-112b-4bab-86b0-8d87cca1a975",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7feca22f-a31d-4073-90d3-1fdebc2db53e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2624,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                    "name": "Does the audit and risk committee meet at least three times a year?",
                                                    "descriptionHtml": "<p>Trusts with a dedicated audit and risk committee should ensure the committee meets at least three times a year. Trusts that have combined the autdit and risk committee with another committee should also ensure that committee meets at least three times a year.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:04:54.229+00:00",
                                                    "originKey": "0056415d-963f-44c5-874d-93ebae74bfd5",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01da7d8b-6a09-4e63-a3c4-6d9ec3747b5b",
                                                            "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "146bed4d-84e5-440e-8f04-c587bf2d3499",
                                                            "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7d7698a2-ca17-41b5-8dfd-eb13b3c65ea1",
                                                            "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81df1c85-a6f6-490f-9fde-337225875acf",
                                                            "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0e5c4d68-e807-4355-89d2-884adfdc753a",
                                                            "requirementId": "9c986dec-a3e1-4a7b-bde4-a94ccca5396e",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "961d051c-fab9-4d43-9c6e-8961e622294d",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2630,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                    "name": "Does the school maintain a risk register?",
                                                    "descriptionHtml": "<p>Schools should maintain a risk register to ensure their effective operation and that risks are appropriately managed. As risks are identified, they should be logged on the register and the associated control measures documented. A risk register should be a ‘live document’ and should be an ongoing process, but the board should review it at least annually.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:10:48.263+00:00",
                                                    "originKey": "e0606c4c-8b8e-4e0e-bd79-a3b2d0e24495",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "260ae2b3-6a39-4f90-b2d1-c6e2c2ac6630",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6a99972b-bba4-42b7-9ec4-6c142fb1d81f",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c157d23-39a5-448f-89fd-57b28562b0ca",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f2187065-3e30-43a5-99e2-31e169f30b72",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f766853f-52eb-400e-8c41-5efd626f0a69",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "becc8b8e-7599-45a4-8ce0-d062fd06bde0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2641,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                    "name": "Does the board challenge pupil number estimates and review these termly?",
                                                    "descriptionHtml": "<p>As part of the board's budget setting responsibilities, it should challenge pupil number estimates as these underpin revenue projections, and review these termly.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:37:42.639+00:00",
                                                    "originKey": "3fdd8db9-89cc-4ac4-ac08-ecf2ca4f0bb4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>ath</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement termly</p>",
                                                    "requiresReviewInDefaultMonths": 3,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06cdc222-be4d-44c2-8f6f-2838279dcfa9",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "08d77bca-4aad-4e5f-99d3-e7c7aabf5b4a",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "11cbac57-f4fb-4ef9-b484-94a56832c2a1",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d74552cd-3392-472f-b3cd-7e85ffb32c30",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "63145e4b-ea87-4b2f-bce2-5b460b01f422",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7cd4ada8-0816-4a45-86fd-a88faac0e4fd",
                                                            "requirementId": "d9b50e7b-4762-47e2-b38d-30a49b3db8a0",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6dfb189a-2133-40a4-a514-44952aeb7cc9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2643,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                    "name": "Does the governing board receive clear and concise monitoring reports of the school's budget position at least six times a year?",
                                                    "descriptionHtml": "<p>Clear and concise monitoring reports enable the governing board to review income and expenditure against the agreed budget. Reports should identify variances, provide meaningful explanations for these and explain what will be done to re-balance the budget. They should be in an easy to understand format that can be automatically generated from base financial records. To enable the governing board to meet their statutory responsibilities for the financial management of the school this should take place at least six times a year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:39:57.395+00:00",
                                                    "originKey": "e7afd2a3-b603-46a4-a2d3-116e43f58259",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2744aad4-f4ab-422b-93ee-babcd7bd5878",
                                                            "requirementId": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "326ade37-057c-4616-9cd6-053f8e47d2c1",
                                                            "requirementId": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "50e82a41-8c21-4f80-b491-347300c5513b",
                                                            "requirementId": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f07f516b-bf04-46b6-aef7-2340f1d63dcf",
                                                            "requirementId": "9e1d0f66-d9f8-4af4-8052-9e65a889b24a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "17d767b0-c412-439d-9093-246760d9c62e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2648,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                    "name": "Are procedures in place to regularly review the financial skill of the governing board and plans in place to address any gaps?",
                                                    "descriptionHtml": "<p>It is essential for the governing board to have access to adequate financial skills and to maintain these skills to ensure they meet their statutory responsibilities for the financial management. Schools should analyse their governing board’s skills on a regular basis to identify any skills gaps. The governing board's skills should be reviewed whenever appropriate, e.g. if there are significant changes to the membership or to individual governors’ roles and responsibilities.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:43:22.605+00:00",
                                                    "originKey": "110f6e7e-e411-4fcc-82fb-1e7509dd12ce",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS checklist</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6e5495c8-bb55-4672-9a82-b6437a821029",
                                                            "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6fd744d5-744e-41c9-9802-bc92f4bb12d6",
                                                            "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "acfa9d78-2cd7-4dab-a255-e75078db5a68",
                                                            "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf691326-61b0-41d4-8b61-96db0aa76653",
                                                            "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a24aaf26-66c2-4b62-b608-045450aea434",
                                                            "requirementId": "77850ae7-b586-47ff-897f-aeca233fdcb9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "14114e7b-12a4-4fd4-bd27-10dc23c09304",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2649,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                    "name": "Does the school benchmark its income and expenditure annually and investigate further where any category appears to be out of line?",
                                                    "descriptionHtml": "<p>Financial benchmarking should be undertaken annually, but it can also be carried out at any time when reviewing school contracts for procuring goods and services. Schools should report their findings from benchmarking to the governing board.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:44:08.684+00:00",
                                                    "originKey": "47adca37-bf5d-4050-a72b-ad6ddf1da148",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS checklist</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "202d5972-53e8-4007-a79e-2d958d77d19c",
                                                            "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3860d068-70a1-4665-8f10-653d59176409",
                                                            "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "69c291f3-f0a4-4bc7-b7f3-7c2f32b6e7b3",
                                                            "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9807bd11-982d-4da9-a189-d8a3ec275810",
                                                            "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "e9dca279-d63c-4051-a15a-c5b707835279",
                                                            "requirementId": "159f07c7-790f-4a10-b407-4492ec42a6cd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bb294cd7-2ad5-4590-8cc2-1bd3baaffe23",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2650,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                    "name": "Are written procedures in place for purchasing goods and services that complies with the law and LA requirements and achieves value for money?",
                                                    "descriptionHtml": "<p>Schools should have written procedures in place for purchasing goods and services, and must ensure they follow legal requirements and their LA's Schools Contract Standing Orders. A Tendering and Procurement Policy is an effective way of meeting this requirement. Schools also have a duty to ensure purchases or leases made with public funds are fair, legal and transparent, as well as securing the best possible value for money. </p><p> </p><p>You may wish to attach a copy of or link to a relevant school policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:44:40.964+00:00",
                                                    "originKey": "02626308-4d5d-4bfb-8f53-bcc4bd07ee5f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Braford LA guide</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "13151b5d-b1f8-430f-bf9e-22c9b68280fb",
                                                            "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "618b0d4f-93f3-408d-b683-5c21397890bd",
                                                            "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "95535ed6-9752-48b4-8efc-c89f69ed5885",
                                                            "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c56809d7-3ce7-4db4-847d-197f7088a68a",
                                                            "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "133fd6f3-ae74-4171-88db-ac0bc137da17",
                                                            "requirementId": "dcce65a9-2e35-4d12-8e14-9816462cf95c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "cdbea540-c172-4560-b349-d859436ef256",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2651,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                    "name": "Are contingency arrangements in place for the absence of specialist finance staff?",
                                                    "descriptionHtml": "<p>It is very important that school finance systems can continue to operate in the absence of a key member of staff. Larger schools or trusts may employ enough staff with financial skills to be able to ensure that staff know one another’s jobs and the school can manage internal cover, but smaller schools or trusts may require other arrangements, e.g. agreements between neighbouring schools, agreements between primary schools and local secondary schools, or insurance arrangements to secure expertise from the LA.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:45:20.532+00:00",
                                                    "originKey": "f4d4dc2d-8cc6-4a8b-ba9b-bef6e1ea28e1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "09d76300-8541-4049-ae7b-4db9ec475ed2",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1259472e-03f2-4b04-bc36-07b2ce4f9167",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "625b92b9-3adf-417e-840e-57598062987e",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e2921cec-1685-4925-a642-541557b52cb3",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b4232502-b886-4712-aac2-5ed6df068b8d",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "db7bdcdf-679e-4f62-9860-9bfb996c2614",
                                                            "requirementId": "4410f3c0-7df2-4f6f-87d0-d0749c10290d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "44b6cfaa-bc5e-4beb-b5c5-46d73b21cd0e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2652,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                    "name": "Does the school have a finance committee, or equivalent, with clear terms of reference and a knowledgeable and experienced chair?",
                                                    "descriptionHtml": "<p>All schools should have a finance committee to which the board delegates financial scrutiny and oversight; however, the finance committee is non-statutory and so governors and trustees can determine their individual requirements and delegate their financial responsibilities</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:46:01.259+00:00",
                                                    "originKey": "da536262-c6af-4656-bdf9-864272540d95",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SVFS and ATH</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0a75da99-a0fa-4453-ac93-6f911b311fd7",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2eeda5a3-93da-4ed8-973e-b5eccf7bd807",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4bbf7ead-2492-4cc0-8a78-74982d77cf7c",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ca711e1e-ea0b-491f-85b3-b442545eb5ca",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0e6f1a5a-10df-4d50-884e-58c0a75e1780",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e21829ff-4987-4c33-888f-9c78ca8cf6f8",
                                                            "requirementId": "f5d65b6d-b115-4401-8061-b6f3228c0ebd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9d356b69-b5c3-4547-918a-38b60aa68dfc",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2670,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                    "name": "Are procedures in place to maintain appropriate segregation of duties in all financial matters?",
                                                    "descriptionHtml": "<p>Segregation of duties is a key principle in financial control, aiming to reduce the risk of error or fraud. It represents the separation of those responsibilities or duties, which would, if combined, enable one individual to record and process a complete transaction. Segregation of duties reduces the risk of error or intentional manipulation through checks built into the routine.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:11:05.378+00:00",
                                                    "originKey": "82382d88-5654-4c42-ad38-94b8dd59c364",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> ATH + SVFS</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0c54f3e5-fc47-4e5c-bbab-0e20237a83b0",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "28b08ed8-ce98-4970-bdd2-b47410485016",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "85421906-165d-44d5-88d5-6372d23afd21",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bb270f27-1ba2-40d9-9bc8-ba9d05f3c5b7",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "231258d7-2675-4b92-8cd5-00409928bef9",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b9f0e326-50ae-477d-a433-830e1bb85848",
                                                            "requirementId": "6b82d697-3325-4c15-906d-fcb6a1a674b1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "9cb633f6-f9a1-4114-bc7d-327c36000681",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2673,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                    "name": "Has the governing board established a formal budget process and timetable?",
                                                    "descriptionHtml": "<p>To provide a sound basis for budget preparation, the school should have formal processes and timetables to ensure that all relevant financial and non-financial factors are considered and that information is available on a timely basis. To aid budget preparation, the school should maintain both financial and non-financial information, e.g. pupil and staff numbers. The information should be prepared consistently.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:13:08.373+00:00",
                                                    "originKey": "3aef95ef-796d-4cb2-ba27-d774fe3b052f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford LA</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01ccb46b-4a8a-49a8-b71c-cb40e89b7740",
                                                            "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0b8fb076-173f-44f4-a0e7-0a0ffae853ee",
                                                            "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d3108f1e-db22-47ce-b58f-90e4d657b7ee",
                                                            "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f63da0e2-51cc-4c32-a020-adbaf57f6d5a",
                                                            "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f19c6923-0351-4be7-b332-12d1e64f5902",
                                                            "requirementId": "b80affb1-1edc-496c-b23c-019b7693bf61",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6b86f7d9-9e8b-4d83-93c4-2e14047bb7f4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 2674,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                    "name": "Does the school have a Support Staff Pay Policy?",
                                                    "descriptionHtml": "<p>It is important to have a fair and transparent policy covering the pay structure of support staff at the school. Most maintained schools follow the Local Government Services Pay Agreement and ‘The Green Book’, and many trusts choose to do so too.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:13:46.117+00:00",
                                                    "originKey": "4c0bb4f9-3516-480d-8305-df9777b54fdc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://neu.org.uk/advice/support-staff-pay-and-conditions\" rel=\"noopener noreferrer\" target=\"_blank\">https://neu.org.uk/advice/support-staff-pay-and-conditions</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0df04a8b-f605-40b5-bb50-14a883166563",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6a742029-2f44-4783-a3e7-9e61da84b3aa",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8607f462-fee8-42b9-b919-9a25d96aac64",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8b22646-f954-42c9-ada2-9c0893ff4209",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a65443bc-82ee-45d7-8b10-0fa5aa916f17",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "aa9c01ad-9cd0-491f-937f-0ceb51129ef1",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "735b2812-96e8-4ac4-a171-be05ed3a3758",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2686,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                    "name": "Does the school maintain a risk register?",
                                                    "descriptionHtml": "<p>Schools should maintain a risk register to ensure their effective operation and that risks are appropriately managed. As risks are identified, they should be logged on the register and the associated control measures documented. A risk register should be a ‘live document’ and should be an ongoing process, but the board should review it at least annually.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T09:10:48.263+00:00",
                                                    "originKey": "e0606c4c-8b8e-4e0e-bd79-a3b2d0e24495",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "260ae2b3-6a39-4f90-b2d1-c6e2c2ac6630",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6a99972b-bba4-42b7-9ec4-6c142fb1d81f",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6c157d23-39a5-448f-89fd-57b28562b0ca",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f2187065-3e30-43a5-99e2-31e169f30b72",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f766853f-52eb-400e-8c41-5efd626f0a69",
                                                            "requirementId": "5f28b967-590a-49e9-adf5-c36b765a7515",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "894f6c2a-e95c-4f71-bd28-5684d79f3f56",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 2689,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                    "name": "Does the school have a Support Staff Pay Policy?",
                                                    "descriptionHtml": "<p>It is important to have a fair and transparent policy covering the pay structure of support staff at the school. Most maintained schools follow the Local Government Services Pay Agreement and ‘The Green Book’, and many trusts choose to do so too.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:13:46.117+00:00",
                                                    "originKey": "4c0bb4f9-3516-480d-8305-df9777b54fdc",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><a href=\"https://neu.org.uk/advice/support-staff-pay-and-conditions\" rel=\"noopener noreferrer\" target=\"_blank\">https://neu.org.uk/advice/support-staff-pay-and-conditions</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0df04a8b-f605-40b5-bb50-14a883166563",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6a742029-2f44-4783-a3e7-9e61da84b3aa",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8607f462-fee8-42b9-b919-9a25d96aac64",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8b22646-f954-42c9-ada2-9c0893ff4209",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a65443bc-82ee-45d7-8b10-0fa5aa916f17",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "aa9c01ad-9cd0-491f-937f-0ceb51129ef1",
                                                            "requirementId": "2a1af9ac-7879-40ea-827f-64628b8f9724",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "21cca229-1b58-4b05-bd3c-bcead863aef9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 3129,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                    "name": "Has the school created a high-level data map?",
                                                    "descriptionHtml": "<p>It is important that schools understand their ‘data ecosystems’, as this will aid schools in ensuring they are compliant with data protection. Data ecosystems refer to the places that personal data is stored and used in a school. A school needs to be aware of what personal data it is processing, so that it can establish whether the data is being processed in compliance with the data protection legislation.</p><p><br></p><p>Creating a data flow map will highlight any gaps in compliance and areas of oversight, making it a critical first step to compliance under the GDPR.</p><p><br></p><p>You may wish to link to or attach your school's data map.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T09:18:03.233+00:00",
                                                    "originKey": "e7af0cf2-deb6-46eb-a6c8-604b31caa703",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "355dbc4b-e30b-454a-b15f-b79da8161a01",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b06889b-c4c5-4578-b45c-cae47f8a5922",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "dddf6102-e063-447c-b4f1-3a1300593532",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fd54fcad-a6cc-424b-b5f3-949e3af54351",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "11b2e2fa-fdc7-41ff-9baa-6e61a616d21b",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "72377d41-99c4-443d-89f5-9d4d3b753281",
                                                            "requirementId": "eba57485-d333-47f5-9ab1-eca3966b6010",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "60cc7099-0001-4190-ab14-b995c2451aec",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 3143,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                    "name": "Has the DPO prepared a report to the governing board on the school’s compliance with data protection legislation?",
                                                    "descriptionHtml": "<p>A DPO needs to be able to report directly to the governing board on the school’s compliance with data protection legislation. It is for schools to decide how often the DPO should report to the board, but this should be done on at least an annual basis.&nbsp;</p><p><br></p><p>You may wish to attach or link to the DPO’s latest report to the board.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T10:10:18.955+00:00",
                                                    "originKey": "0a69931c-cdb5-43a4-a69d-d63d03042f8c",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2180807e-8fc5-4dbe-9170-a5c6f33efc4c",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "257555fd-8261-4013-ad79-024ed4eb1426",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "358cdb6e-e27e-4c70-a8dd-e738aeafe1e3",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81a1bc76-fa6e-4ef8-ad28-e932e34b7bdf",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "05ee97ca-d2cd-4a6e-a5bc-912e38086e41",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3738a170-5e6e-4f67-8ccd-dfe27f507cfa",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "55123722-e1b2-4ad8-a217-57fb488c6a54",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3323,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                    "name": "Has an individual at the school being assigned responsibility for the school’s environmental and sustainability programme?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">The most effective approaches to promoting sustainability in a school will involve more than one individual; however, it is important to ensure that someone is responsible for leading and maintaining progress against the school’s goals. This person needs backing by the SLT and their role needs to be provided the necessary status to ensure others engage. </span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T14:05:49.694+00:00",
                                                    "originKey": "00add64b-b862-4a8e-a1c2-09b3413e81f4",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2ca9fac1-d45f-4911-b889-f7e5217d92b5",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4a7716c7-d821-45af-bfae-f11b5110dc02",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "60638bed-f2dc-4990-a084-4bbb2a7d6e4a",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8e118e1f-4086-445a-836c-1affb9f84a09",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "8be025b2-de90-4c88-ab69-d08f7c2d4c49",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "eaf8e1a5-6312-4a73-92d6-4d61ad356ae7",
                                                            "requirementId": "665da614-754d-45c2-b48c-4f9ac7697d0c",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6d14bc80-fac5-4a74-b1d9-6606b59819d4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3324,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                    "name": "Are environmental and sustainability issues covered in the curriculum?",
                                                    "descriptionHtml": "<p>Linking what is taught as part of the curriculum to wider school activities related to the environment and sustainability can help to build momentum for change through pupil leadership and engagement. </p><p><br></p><p>It is also important for pupils to have an awareness of key environmental issues and have an opportunity to engage in learning about these issues. </p><p><br></p><p>You may wish to link to or attach any relevant programmes of work or curriculum plans. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T14:08:22.999+00:00",
                                                    "originKey": "ae1ab812-e540-4f45-be0d-b0e3123131fd",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "444aa3ad-60d3-43c8-ad64-64f8938add7a",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5d5f2420-0db7-44e6-bfa6-b87636768dc3",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7f21664f-dabd-404d-8230-c6b2a8696f24",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a1cb2c5b-8403-4b76-81a4-923de72ce5a0",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2b676ecb-58f3-44ec-9f6d-151e3761266c",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "58d46c25-4b09-401e-8e81-7846bdf3be37",
                                                            "requirementId": "3d4a10b9-010c-420d-a962-8411c4758692",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1f07881e-40a5-485d-bc8d-9f22ea49ea5c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3344,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e489351b-2781-4c4f-96e3-a70994625418",
                                                    "name": "Does the school have a policy covering the curriculum?",
                                                    "descriptionHtml": "<p>All schools should recognise the importance of offering a broad and balanced curriculum which is underpinned a shared school vision. Having a policy covering the curriculum can help to ensure the curriculum is applied consistently across the school. This does not need to be a standalone policy. </p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:17:46.484+00:00",
                                                    "originKey": "eb959400-5f77-4d97-abee-bd0dfe0af14a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> https://www.theschoolbus.net/article/primary-curriculum-policy/7271 </p><p> https://www.theschoolbus.net/article/secondary-curriculum-policy/7348&nbsp;&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "180e86d7-66c6-4324-b1c4-833b8a0b322b",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a73cc661-f0c0-484b-8997-c17ebc8592cc",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "db80a35b-f0f6-4a2f-8726-7d38929d4f18",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f90096c2-794a-4d1b-a293-bd7c232e2ba4",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "56fcd0dd-640d-478e-8f50-5da256830017",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ac65bc33-c427-4d40-83d2-21da38ee2594",
                                                            "requirementId": "e489351b-2781-4c4f-96e3-a70994625418",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1fd4a1d4-fba1-4fe0-996c-23a2f2d8588e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3350,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                    "name": "Does the school have a policy covering risk management?",
                                                    "descriptionHtml": "<p>While there is no specific guidance for maintained schools on having a risk management policy, it is advisable for maintained schools to have one in place. This does not need to be a standalone policy. </p><p> </p><p>You may wish to link to or attach your school’s policy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T08:29:15.157+00:00",
                                                    "originKey": "c17e71d2-01da-45a1-ae15-e98330467446",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.gov.uk/government/publications/academy-trust-financial-management-good-practice-guides/academy-trust-risk-management&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c96dd7b-93f8-4b16-8324-2b9a46d7af49",
                                                            "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "26dcf46d-572a-4326-bdc5-a3dde2731e00",
                                                            "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8ad84d8c-89c8-41f8-b229-f9617d4aca21",
                                                            "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c061da94-bf68-4081-a74b-d6c81538721a",
                                                            "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "f5af7e37-1fdd-4078-9cd2-8bcb1e861bfd",
                                                            "requirementId": "a973ebd7-ccf8-41cb-88aa-bd7079f992f7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3bb0ff83-070b-4fc1-88e3-35d6f91c72d6",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3375,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                    "name": "Does the school ensure that all staff and volunteers understand the importance of maintaining confidentiality through a Staff Confidentiality Policy?",
                                                    "descriptionHtml": "<p>All staff need to understand the expectations around maintaining an appropriate level of confidentiality, including in relation to matters involving data protection and safeguarding. An effective way of ensuring all staff understand these expectations is through the adoption of a written policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:11:07.311+00:00",
                                                    "originKey": "68c142f8-33f1-4e21-82c4-7f45db999e22",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/staff-confidentiality-policy/2534&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "12fe1f5a-b8db-4e3b-988f-ab9b07b36ce9",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d0919ff-b659-4acc-a8eb-08e749b1e8de",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6430f9b6-2a76-4968-8843-750370e3081d",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6afc75dd-f90d-491b-8950-2c0dc4ba9c7a",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "425da0d1-a40e-4e16-8d46-78955859c178",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "595c74dd-22c6-4134-aebc-f000b0bcc3e5",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "a9fe761c-bf90-46e0-80b3-51396c6ce245",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 3376,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                    "name": "Does the school ensure that all staff and volunteers understand the importance of maintaining confidentiality through a Staff Confidentiality Policy?",
                                                    "descriptionHtml": "<p>All staff need to understand the expectations around maintaining an appropriate level of confidentiality, including in relation to matters involving data protection and safeguarding. An effective way of ensuring all staff understand these expectations is through the adoption of a written policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:11:07.311+00:00",
                                                    "originKey": "68c142f8-33f1-4e21-82c4-7f45db999e22",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/staff-confidentiality-policy/2534&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "12fe1f5a-b8db-4e3b-988f-ab9b07b36ce9",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d0919ff-b659-4acc-a8eb-08e749b1e8de",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6430f9b6-2a76-4968-8843-750370e3081d",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6afc75dd-f90d-491b-8950-2c0dc4ba9c7a",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "425da0d1-a40e-4e16-8d46-78955859c178",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "595c74dd-22c6-4134-aebc-f000b0bcc3e5",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "416782b9-63c1-4644-bb63-51a4ec6b8cf4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                "requirementAreaId": "4de0c2fd-bc85-4832-805f-441c868d0607",
                                                "displayOrder": 3377,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                    "name": "Does the school ensure that all staff and volunteers understand the importance of maintaining confidentiality through a Staff Confidentiality Policy?",
                                                    "descriptionHtml": "<p>All staff need to understand the expectations around maintaining an appropriate level of confidentiality, including in relation to matters involving data protection and safeguarding. An effective way of ensuring all staff understand these expectations is through the adoption of a written policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:11:07.311+00:00",
                                                    "originKey": "68c142f8-33f1-4e21-82c4-7f45db999e22",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/staff-confidentiality-policy/2534&nbsp;</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "12fe1f5a-b8db-4e3b-988f-ab9b07b36ce9",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d0919ff-b659-4acc-a8eb-08e749b1e8de",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6430f9b6-2a76-4968-8843-750370e3081d",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6afc75dd-f90d-491b-8950-2c0dc4ba9c7a",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "425da0d1-a40e-4e16-8d46-78955859c178",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "595c74dd-22c6-4134-aebc-f000b0bcc3e5",
                                                            "requirementId": "8ac34b1e-5d17-4683-afcc-c03c394a9e22",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "bb025532-053d-4652-bd64-5c96d1971ae4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3378,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                    "name": "Does your school have a policy covering environmental sustainability?",
                                                    "descriptionHtml": "<p>Schools should recognise their responsibility to ensure that pupils have the necessary skills and knowledge to understand environmental concerns, so that they can apply this to their lives and adopt an eco-friendly lifestyle.</p><p><br></p><p>Implementing a policy on environmental sustainability can help to encourage both pupils and staff to think about the environment and how their actions will impact upon their local surroundings, as well as the global environment. This does not need to be a standalone policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:13:25.25+00:00",
                                                    "originKey": "88113df2-1e36-4c97-a7f4-95b9a266c2db",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/environmental-sustainability-policy/5540</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "74b91e96-5dd9-4385-abb4-124561e5d611",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c2042814-1924-4268-b900-ae85e41285b7",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cbe806c1-3b9e-4152-bf42-d9264a464cee",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e75916eb-9d2e-41a1-aa89-ab56d3a4cbc5",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "17335179-b306-447f-a97b-8b84707c462a",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7b82153d-d52d-4d5c-9604-0e1d31547d64",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "989f2e6f-197b-45c5-9958-bfcf8b9a7e73",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3379,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                    "name": "Does your school have a policy covering environmental sustainability?",
                                                    "descriptionHtml": "<p>Schools should recognise their responsibility to ensure that pupils have the necessary skills and knowledge to understand environmental concerns, so that they can apply this to their lives and adopt an eco-friendly lifestyle.</p><p><br></p><p>Implementing a policy on environmental sustainability can help to encourage both pupils and staff to think about the environment and how their actions will impact upon their local surroundings, as well as the global environment. This does not need to be a standalone policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:13:25.25+00:00",
                                                    "originKey": "88113df2-1e36-4c97-a7f4-95b9a266c2db",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/environmental-sustainability-policy/5540</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "74b91e96-5dd9-4385-abb4-124561e5d611",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c2042814-1924-4268-b900-ae85e41285b7",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cbe806c1-3b9e-4152-bf42-d9264a464cee",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e75916eb-9d2e-41a1-aa89-ab56d3a4cbc5",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "17335179-b306-447f-a97b-8b84707c462a",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7b82153d-d52d-4d5c-9604-0e1d31547d64",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "35da7af7-c62a-4a79-b19c-9740807981c0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3380,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                    "name": "Does the school have a policy covering infection control?",
                                                    "descriptionHtml": "<p>Schools should have measures in place to ensure staff and pupils are protected against infectious diseases, and staff understand how to respond to infection control incidents. It is good practice to outline these measures in a policy. This does not need to be a standalone policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:15:06.556+00:00",
                                                    "originKey": "8b07aa00-7bdc-4c4c-bfb0-ae40bbb58eb1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/infection-control-policy/5538</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4d206d4e-4875-4272-8b6e-2ac896f0f6fc",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "529feed6-dcf8-4a88-9c75-c86a71b837c5",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c8dfcd9-9b9c-4146-97b5-a0e9a5278275",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "95d49a8d-4c56-4322-9a1d-ff0c250e2d8b",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ccbc92b-b328-4b32-82b4-3a87092d9ff9",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "88427950-493a-4321-a7ef-2d5e71447b5f",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "87ac7d52-aca0-422d-8578-0c3d25b04ded",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 3381,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                    "name": "Does the school have a policy covering infection control?",
                                                    "descriptionHtml": "<p>Schools should have measures in place to ensure staff and pupils are protected against infectious diseases, and staff understand how to respond to infection control incidents. It is good practice to outline these measures in a policy. This does not need to be a standalone policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:15:06.556+00:00",
                                                    "originKey": "8b07aa00-7bdc-4c4c-bfb0-ae40bbb58eb1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/infection-control-policy/5538</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "4d206d4e-4875-4272-8b6e-2ac896f0f6fc",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "529feed6-dcf8-4a88-9c75-c86a71b837c5",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c8dfcd9-9b9c-4146-97b5-a0e9a5278275",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "95d49a8d-4c56-4322-9a1d-ff0c250e2d8b",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6ccbc92b-b328-4b32-82b4-3a87092d9ff9",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "88427950-493a-4321-a7ef-2d5e71447b5f",
                                                            "requirementId": "38ced6fd-288e-4a0f-960d-db0b2df22ad2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0e798c0b-e3c6-4275-ac5a-bc18a19ceeb5",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                "requirementAreaId": "1dc85f28-d00d-45cd-8354-0e5b53a604bf",
                                                "displayOrder": 3382,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                    "name": "Does the school have a policy covering educational visits and school trips?",
                                                    "descriptionHtml": "<p>To ensure school trips can be conducted safely, schools should have clear procedures in place outlining key responsibilities and how school trips will be conducted – schools can outline these procedures in a policy. This does not need to be a standalone policy. </p><p> </p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:17:14.57+00:00",
                                                    "originKey": "3992e859-fefa-42eb-ad08-b15084300719",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/educational-visits-and-school-trips-policy/4210</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "09aea95d-1268-4c49-b567-71c2e3db2c4e",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1147312e-d71a-49a4-9141-991a7706ae51",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8f93b46d-d3d0-4742-884e-a3b7bcc2eb04",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "902d75d8-a3bb-42a1-ab6b-d53435cd45dc",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9cbd0da2-fe6d-4945-9100-c953b3dae68b",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b422a299-e4f7-4fae-a079-8d550a6aa7e0",
                                                            "requirementId": "07b4c6a1-7c99-4a0b-bb3d-73622067de82",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "025dfd50-c37d-42e2-8c89-ab658ceb5314",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 3425,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                    "name": "Does the school involve the SENCO in changes to school practices that could affect the mental health and wellbeing of pupils with SEND? ",
                                                    "descriptionHtml": "<p>In circumstances where changes to school practices could affect a pupil with SEND, the school, in collaboration with the SENCO, should consider whether changes to SEND provision will be required to maintain the same quality of support.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:32:11.331+00:00",
                                                    "originKey": "fa230f03-501f-4e5b-9d18-5896a09a537d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18ff5b73-d16c-454d-913a-8bbe8fbb5970",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b2140e1b-2678-44dc-9d95-a0fc6a17fbc6",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c681445e-4af5-4eda-81fa-910649cb2d00",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d34ba921-08d7-46e0-902d-5ff8f31c73e7",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "86a4dbe9-ee3d-4e1c-a643-9014beb2f429",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a4f61845-4336-4e4d-b703-f78b3fa0659f",
                                                            "requirementId": "3e5c337d-61e9-49a1-992c-567569c0f9a1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4ed02394-ee92-43c6-827e-05f04ce89342",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                "requirementAreaId": "fe1434fe-dd0f-4adf-85ce-209af86dedd0",
                                                "displayOrder": 3426,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                    "name": "Does the school participate in local conversations and SENCO clusters to share best practice with other schools in the area? ",
                                                    "descriptionHtml": "<p> Schools supporting pupils with SEND may find it useful to collaborate with other institutions in the local area to see what the local arrangements are for supporting those with SEND.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T09:32:48.122+00:00",
                                                    "originKey": "9f22c95a-da11-41ff-9ddb-8c0ef0be641f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> SEND code of practice</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1c4a348f-f03d-4e78-a71e-bb57cedee9be",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "3bdcf5d7-b7f0-40a1-a571-17dd1ab2c231",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7e6e4ab8-04d4-45ce-bb00-5a30ad6395d5",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82ccec3f-a0b0-4fca-a461-204eb040f902",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1008aed8-7653-40ab-87b1-ab58378a3dc6",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4a0f87fb-4561-43a6-9db5-649114bac881",
                                                            "requirementId": "ec95289c-fc5c-45e6-b64e-8c269b6e535d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "303afeeb-c7d0-4246-a85d-95ca22d04700",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3444,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                    "name": "Has advice been sought on how to utilise the premises properly to save energy and reduce energy consumption? ",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools should seek advice on how to utilise their premises efficiently to save energy and/or reduce energy consumption. Any advice should be actioned as appropriate, and schools should ensure that staff and pupils are aware of any measures in place that they need to follow. </span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:32:28.757+00:00",
                                                    "originKey": "4c3a024c-65c1-44ee-a61a-defb5cd43769",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ae191f0-283a-4643-ad95-bc7c5767b8f2",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4391d8c3-f0c3-43a0-908c-eeecdf6de9d2",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "60c5f629-93fe-4078-997a-c5d2257922a0",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a31311f3-40fe-42e3-8432-9d0977966d05",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2b65de74-98ae-4c4c-8856-3fd1c008fd68",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "349c1995-ba8f-46a3-9639-d792acb5d89e",
                                                            "requirementId": "ffd101f2-72e9-4374-9dae-361c67fedb5d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "e99c07ce-248f-4b3e-843d-5085fa1ef8d4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3445,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                    "name": "Does the school adopt a sustainable approach to procurement?",
                                                    "descriptionHtml": "<p>Schools should have a variety of programmes in place that support sustainable school travel, such as walking busses and cycling schemes. </p><p><br></p><p>You may wish to link to or attach further information about your school’s sustainable travel programmes. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:33:48.347+00:00",
                                                    "originKey": "b8a74d82-57c6-4303-a043-4251ac053ac8",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "722243e5-269e-460f-93a8-f4a2a4d73bad",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0569da4-f774-45e3-af41-e78ba0ec5ee3",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a7375041-0db7-46ec-b975-f723c4479038",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bdcd99c0-3e33-430d-963b-aa82906f4c5d",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5422e36d-00bc-4f57-9ac6-13cd20f2a86b",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c6e14900-250a-4748-9b63-fcac92e59e74",
                                                            "requirementId": "61fe0a65-383e-4a91-8ece-0f0f53dad635",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "322bdbe2-be26-4ffa-8939-0360dd0f7c79",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3446,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                    "name": "Does the school have programmes in place for sustainable school travel?",
                                                    "descriptionHtml": "<p>Schools should have a variety of programmes in place that support sustainable school travel, such as walking busses and cycling schemes. </p><p><br></p><p>You may wish to link to or attach further information about your school’s sustainable travel programmes. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:34:58.403+00:00",
                                                    "originKey": "6701346c-3204-48db-9d0a-c68b7190aa59",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf </span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "199462f9-3baa-4d48-befc-b45a679ac6e1",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "817efd71-5090-466f-987b-a94973bac426",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8d71b27d-5149-40ae-b166-b67592d831b9",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b75edc21-6fc4-4800-bb7b-6fd1a908fa13",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "71c26ee9-9446-405c-9ff4-0c22013deb73",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8e05ce8e-a0c7-4a56-83be-6dd2be4e224a",
                                                            "requirementId": "8eae7837-dcbc-4725-9408-fa1de7785d12",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "40451d5a-3874-47ae-99a6-20a5c1e07e13",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3447,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                    "name": "Does the school adopt sustainable catering practices?",
                                                    "descriptionHtml": "<p>There are steps that schools can take that can have a positive and significant effect on the sustainability of the school food system, e.g. aligning menus with seasonal production and harvesting cycles. </p><p><br></p><p>Schools should adopt approaches that are appropriate to their circumstances. </p><p><br></p><p>You may wish to attach or link to further information about the sustainability of your school’s catering practices. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:35:57.39+00:00",
                                                    "originKey": "a8edcd94-ac2b-4976-a594-2541555f0084",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf </span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2f4d4354-7527-42ea-ac90-ce83fd160f8e",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ce29a1a-aaa3-42d8-8fd6-38291600d685",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a7092bc7-0a6c-4c38-bdb2-69aefc1e5e7b",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d0ca4eb5-ef5c-4b36-aa08-dd3fd2caa0e4",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "541898ef-995d-419c-98ff-71f1d9f9265e",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "adb35b1b-2c65-4f87-92a8-be9f880c30fe",
                                                            "requirementId": "8052013d-b9d2-46a0-9d88-1130dba2dc11",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "be1011f2-c89f-4647-bc5e-901c43956975",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3448,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                    "name": "Are there measures in place to encourage biodiversity on the premises?",
                                                    "descriptionHtml": "<p>Supporting biodiversity is very important – schools can play their part by ensuring that they encourage biodiversity on their premises. Biodiversity projects in school grounds can contribute to the health and wellbeing of pupils, their families and other members of the local community. In addition, they encourage an active interest in the outdoor world.</p><p><br></p><p>Schools should assess what programmes to encourage biodiversity would be appropriate for them. </p><p><br></p><p>You may wish to attach or link to further information about your school’s approach to encouraging biodiversity on the premises. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:36:56.756+00:00",
                                                    "originKey": "c4403f0f-2c5a-41dd-871b-d79d14789ae6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://assets.publishing.service.gov.uk/government/uploads/system/uploads/attachment_data/file/187037/DFE-32056-2012.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2a90ebf2-560b-4bda-9244-b430980f21b0",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "33da7844-1b4c-41f7-9b33-216108ec8fe1",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5a61ef7e-c646-498a-8f9c-4833ee2c8f64",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bc58f6f9-0aa2-4cc2-bb36-5c8abe827595",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "42b3628d-447c-499b-9bab-e45db9425abf",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a27b5b20-3f53-4236-9eb9-8474b3c033d5",
                                                            "requirementId": "05424854-29b4-4062-98ef-7c4501bf3131",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "4555d7d6-22c8-4454-94b0-943ff76e71a9",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3449,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                    "name": "Is sustainability a strategic priority for the school?",
                                                    "descriptionHtml": "<p>Governing boards can take the decision to make environmental sustainability a key priority for their schools by identifying it as a key element of their school’s strategy. This means that environmental considerations will form part of the school’s decision-making and the work of the board.</p><p><br></p><p>You may wish to attach or link to further information about your school’s strategic priorities.  </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:38:06.563+00:00",
                                                    "originKey": "ce6b8d90-ca86-481a-9f8d-592d88251414",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">NGA - ‘Environmental sustainability’ https://www.nga.org.uk/getmedia/42a15743-7a17-4dfb-b15a-804e5f26b5a2/nga-environmental-sustainability-20211104.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "29a75c06-e17e-4cc0-a27a-6a3457850ef7",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "af990c49-a9f5-44d9-9c67-c2a7cdf535cb",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e1ca6057-4f8b-41de-bdbc-b42e002cd49e",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f93e41a1-b90d-4bb8-9811-20545d4cb65f",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "04236413-da1e-4616-8222-61290a08d213",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "343de670-1c32-4a5d-810d-9fbc9fc738fb",
                                                            "requirementId": "6e2221f9-dc84-44ea-8010-5f74c5a5cb8a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7aff0d20-f6e7-4783-b0b8-a52ba286ae0a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3450,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                    "name": "Do the school’s values support environmental sustainability?",
                                                    "descriptionHtml": "<p>Governing boards should consider whether their school’s existing values support environmental sustainability and consult stakeholders on whether the values need to be updated to ensure care for the environment is more explicitly incorporated. Incorporating environmental sustainability into a school’s values would show a school’s commitment to this issue. </p><p><br></p><p>You may wish to link to or attach a statement of your school's values.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:39:07.376+00:00",
                                                    "originKey": "0350f5af-e488-4f1c-ad17-571bf73d6cf6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">NGA - ‘Environmental sustainability’ https://www.nga.org.uk/getmedia/42a15743-7a17-4dfb-b15a-804e5f26b5a2/nga-environmental-sustainability-20211104.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "49f56573-f82d-473d-ab0b-db8a59aac929",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8523b28a-3917-41c9-b4eb-dbfaba845703",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c466eaff-3210-4c32-8f25-3fc70de0f6bb",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ef75a710-d4d0-49ef-a38a-1a6b20d4230a",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5053d535-6b7b-456f-b5ff-5e5f7ac55865",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "51470309-bd17-4b1b-a833-21f59e665d6c",
                                                            "requirementId": "a82aa00f-e8d7-4400-a7fb-6ef1dd6b608f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "186cbf25-898d-4da6-bc9d-ebbec612a436",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3451,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "cdd17092-46b5-4374-b844-7427327b038f",
                                                    "name": "Does the school support wider environmental projects in the local area?",
                                                    "descriptionHtml": "<p>Schools should consider how they can work with local organisations and individuals on environmental projects. Engagement with other local project further displays a school’s commitment to environmental sustainability. </p><p><br></p><p>You may wish to link to or attach details of any projects your school is involved in. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:39:59.893+00:00",
                                                    "originKey": "af156449-ee34-4098-9065-5f277ad0a598",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">NGA - ‘Environmental sustainability’ https://www.nga.org.uk/getmedia/42a15743-7a17-4dfb-b15a-804e5f26b5a2/nga-environmental-sustainability-20211104.pdf</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "9362c51f-942f-4b53-917c-0aea6357b787",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a2f1e348-d6e5-4ec1-a38b-789bc8f3eb8a",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1e9366e-47b0-4b4a-be15-3f633407f2fa",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "deaed36c-86a3-44cd-85de-1cfc8ca67990",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0835c7fb-536c-4cdb-b640-47363809eace",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "798d39e8-d2b6-433a-abf6-20b40f4ad7d5",
                                                            "requirementId": "cdd17092-46b5-4374-b844-7427327b038f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "07f2c85e-58ae-4e0e-8d34-fff9920fddce",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3452,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                    "name": "Does the school have an environment committee? ",
                                                    "descriptionHtml": "<p>An eco-committee can be the driving force behind a school’s approach to environmental sustainability. The committee can be made up of staff, pupils, governors and other relevant members of the local community. </p><p><br></p><p>The purpose of the committee would be to represent the ideas of the whole school and to lead the school’s sustainability initiatives. </p><p><br></p><p>You may wish to link to or attach further information about your school’s committee. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:41:03.384+00:00",
                                                    "originKey": "6eeefb04-f971-4762-87bf-59619573eb67",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://www.ecoschools.global/seven-steps</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "59338e01-c20e-49aa-9f46-56c49d2fd047",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "84f765da-ab1e-45c9-a112-ced04078c986",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a22d2d3a-f16e-4e8c-90b2-5b6f1ae58a24",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e26c49a8-ab33-4d83-8d35-338b9085aabc",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "33fd4982-0bc8-4329-b918-2bebac5a0f9a",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "abf90bbf-0c0a-49b7-91f3-2da07360d107",
                                                            "requirementId": "1efb6d74-8d97-4ec4-aa81-96425ef13150",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "11ba4654-89bb-488e-8080-30d7afb69908",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3453,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                    "name": "Has the school carried out a sustainability audit?",
                                                    "descriptionHtml": "<p>By carrying out a sustainability audit, schools can identify their current environmental impact and highlight areas of strength and any areas that need to be improved. The audit should look at areas including how the school works with other organisations, evaluating the school's energy consumption and more. </p><p><br></p><p>The results of this audit can be used to inform a sustainability action plan. </p><p><br></p><p>You may wish to link to or attach your school’s sustainability audit. </p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:41:42.382+00:00",
                                                    "originKey": "b49ee60a-bd58-4d32-a343-7856c63bac83",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://www.ecoschools.global/seven-steps</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "056afda1-b3cd-420e-a5d0-f7330fdc4b16",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a01440ea-64ee-48fa-8707-509d5a4cd87b",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cd08d50c-cc07-4360-87db-7a1e1f3cbf52",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf8ff97e-8462-4d84-8de2-8052977f619c",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "dd173b23-255f-4bbb-84be-023e66b80176",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "de92521d-4e39-4ff5-9029-d035f62041dc",
                                                            "requirementId": "ff059f2b-e713-4a94-868f-4b258d3e101f",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7f905da4-0059-49c8-9f8a-52ca51253534",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3454,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                    "name": "Does the school have a sustainability action plan?",
                                                    "descriptionHtml": "<p>A sustainability action plan should be informed by the results of a sustainability audit. The action plan should aim to resolve and/or improve any issues identified in the audit and should be at the centre of a school’s sustainability approach. </p><p><br></p><p>You may wish to link to or attach your school’s sustainability action plan.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:42:29.741+00:00",
                                                    "originKey": "07db58a8-0562-41b2-87d1-970a33ff0191",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://www.ecoschools.global/seven-steps</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ea42ae2-515b-40c2-af9a-10031bb7cc5b",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "77f4c4fb-b18d-4a7f-b618-cae877fd5897",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "80c7a811-8911-4869-abbe-748eaf2023e8",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c7359b2a-a433-4db4-8de7-9223eddb532f",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4c86811f-cfbb-4937-afbc-bd16f6e67753",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6dc2f668-8ea8-4507-ab3c-3f5689c5904c",
                                                            "requirementId": "a1ed24f2-8e13-40d1-ae17-cf27baef0a0a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "7f534f00-1023-4ac4-a4b5-807f320d30b0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3455,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                    "name": "Does the school publish a statement to show it is committed to environmental sustainability?",
                                                    "descriptionHtml": "<p>An effective way of highlighting a school’s commitment to environmental sustainability is by developing a statement setting out this commitment and publishing this so it is accessible to all members of the school community, e.g. on the school website. </p><p><br></p><p>The statement should list the main objectives detailed in the school’s sustainability action plan. You may wish to link to or attach a copy of your school's statement.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T13:43:18.568+00:00",
                                                    "originKey": "af43ee1a-dde6-4455-9996-a5f420a84e2b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">https://www.ecoschools.global/seven-steps</span></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0d499f75-80e1-4651-9338-e03cea38707e",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "91acddb1-051a-4161-888a-ccd87444a4f3",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "eb691cf8-e619-4ee4-b12b-e37fe0307677",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f6584ca0-857d-43ae-874e-c2a9cef9c61f",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "18b5dac5-dccf-4097-b759-29c24f2c695a",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6562e96f-9127-4ecc-85fc-7e718fd1860d",
                                                            "requirementId": "bdc9ac4a-86fc-473b-884d-22092c0b208b",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0a4d2f01-d90d-47ac-bf3a-85186421aeaa",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3464,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                    "name": "Does the school have a designated member of staff appointed to lead on equality and inclusion practices within the school?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">It is good practice for workplaces to have an equality, diversity and inclusion champion at senior level who can speak up for under-represented groups and flag any issues that need addressing. </span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:49:09.06+00:00",
                                                    "originKey": "6f809df8-c92d-43da-b193-2ee1918a3eb6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.acas.org.uk/improving-equality-diversity-and-inclusion/making-your-workplace-inclusive</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3d8eccc8-d111-4ea0-b1ee-ac302bac70c8",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "619e2345-7d75-482e-a21d-3ba4b8f94e3b",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b97a0319-9a86-4f07-a695-e57d2ce8428b",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf791840-81a1-47c3-8981-810a04d6ab2f",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "08f1e6bf-cdcc-45bd-a4e7-4ac0f8659d4f",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "8e89d52e-ec12-43fb-8315-9c5a26e43973",
                                                            "requirementId": "9e0acfc6-2ac4-4f08-b972-437b36e84d0d",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "434f57e4-1901-4d95-b54a-02ef0264be44",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3465,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                    "name": "Has the school appointed a member of the governing board to have strategic oversight of the school’s equality and inclusion practices?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Governing boards should make sure that their school complies with all aspects of discrimination law and schools must have due regard to the need to publish equality objectives and information which demonstrates how this is being done. Appointing a member of the governing board or board of trustees to oversee this requirement is a strong way to ensure that the school has due regard to its duties under the Equality Act 2010.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T14:50:57.254+00:00",
                                                    "originKey": "59830b25-a2a0-47fd-80f6-957fc4b05568",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Governance handbook</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2baf4d6c-9396-4ae8-bd56-8460427c9098",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "abeef406-0bd8-45da-8e54-0f5f165e7cf7",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e5eecaa2-68d5-47da-8cc7-912bf7994299",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f05a2227-18a8-4a7c-8411-ad71ba1365db",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b65f3c25-0cb1-4908-8084-8c7e16647f89",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ef5f7602-afa4-4ab8-b856-0d9a6a2e114f",
                                                            "requirementId": "9b71def4-d11c-4f82-8cdb-f0053efad77a",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "201cf1a5-9f83-44fa-89a9-5439eab02a84",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3480,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                    "name": "Does the school regularly seek feedback from pupils and staff from marginalised groups regarding how safe and protected they feel from discrimination at school?",
                                                    "descriptionHtml": "<p>It is good practice for schools to have systems in place which enable marginalised groups to have their viewpoints and experiences considered closely.</p><p><br></p><p>You could upload a survey template or a school policy outlining procedures for collecting feedback as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-25T15:57:47.907+00:00",
                                                    "originKey": "b03c3f4d-ea85-4cc6-ba97-6b9cd3442d80",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://neu.org.uk/media/11236/view</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "592392c7-152d-4c03-9cbd-e569a2fb1c2e",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "89576904-cb47-4dcb-856e-e3eac2f92f15",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bbb60057-c664-4417-9163-6c37c9bf532b",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f9b15512-12f9-4783-b6ec-fd4c903ba623",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "cf3bd560-9697-4dba-a8bb-2467bd6da2b6",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fb0e1023-36e9-4a28-bbfb-4d5cd72ba597",
                                                            "requirementId": "b322059e-683d-42e5-a40d-ba5004e43f02",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "661825d1-34f2-473b-af15-952fb9a38031",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3491,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                    "name": "Does the school have a mental health lead or other designated member of staff who coordinates the school’s mental health support, e.g. pastoral lead?",
                                                    "descriptionHtml": "<p>It is recommended as good practice to have a mental health lead, or a designated person who leads the school's mental health effort. This may be a pastoral lead or other person with suitable training. You may wish to upload a copy of this person's job description as evidence towards this practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T10:59:32.957+00:00",
                                                    "originKey": "164b8a20-ec48-4bf0-880a-5ba46964462b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "396daa72-db07-40de-8512-aaca50589635",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64a06c07-e830-49cc-93bb-3e4af6140960",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ca8ff25e-021b-4146-9a74-bd42f7933fa5",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e2dcf005-def7-40f3-a404-34a964c0c8d6",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "60ff1dbb-afca-40f4-9821-08124a393011",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "9970a473-ca60-48f7-8608-e069d60faec1",
                                                            "requirementId": "87a4502b-92dd-4567-a5dc-52d2b6bf5aa2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "97248341-bb43-4cb2-80ba-72ff8e6bee3b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3492,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                    "name": "Are managers and supervisors trained on mental health issues staff may experience in the workplace?",
                                                    "descriptionHtml": "<p>It is recommended as good practice that staff managers and supervisors are training on mental health issues that staff may experience in the workplace in order to help support and understand their needs. You may wish to upload any training certificates or other evidence that managers and supervisors are taking part in this practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:01:45.902+00:00",
                                                    "originKey": "9c9b03c7-debb-46c0-b15e-ba6cb3e1351a",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0cea2e41-34ca-4029-b3ba-76deb49624cf",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c38ce0d-9d22-48d0-abf9-e5fffbf063af",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8cac3b2d-2572-472c-b2e7-d64ba3e4d1d8",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bd60f4fe-680e-4372-a0f6-672680112c7c",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1ef657cf-a515-4d6f-b520-2c22aa18de14",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "375335b6-0553-4b2e-b038-4f71e249f154",
                                                            "requirementId": "a9e4b937-4fe8-4cad-b9b6-257b3ba70f47",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "559ff830-6c5b-4772-b437-c7c5c6a87923",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3493,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                    "name": "Are teachers and other relevant members of staff trained on mental health issues that may be present in pupils?",
                                                    "descriptionHtml": "<p>It is recommended good practice that staff, especially teachers, are trained on the mental health issues that may be present in pupils in school. This is to help them identify concerns and support pupils with their individual needs. You may wish to upload an example of a training certificate or other evidence that staff are taking part in this practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:04:04.383+00:00",
                                                    "originKey": "49879699-b6c3-48cd-8f8d-6185e36dcf91",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1de67482-3b5e-4325-a242-5e2541ca3950",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a51898a0-f96e-484f-a420-38af1b42909b",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cdfcfb1b-c3c0-4233-9344-7a176672feb5",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5a93bc9-c00a-4ea1-85ec-da0e5827075a",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "6b2bbba7-15fa-4c57-b370-f70a7d18519d",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "d6c5e675-eeb1-4591-b684-20dfeff0f997",
                                                            "requirementId": "d9d30db8-12b0-4484-93a8-ab1ac9a947d5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ecb8413e-eb3d-4f76-a887-1607d8c9e2f8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3494,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "a14b0cec-3184-4917-b284-db1db6885183",
                                                    "name": "Are policies in place to allow for a mental health-friendly culture in the school, e.g. flexible working policy?",
                                                    "descriptionHtml": "<p>It is recommended good practice that schools allow for a mental health-friendly working policy. An example of this may be implementing a flexible working policy that includes considerations for those with mental health needs. You may wish to upload any relevant policies as supporting evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:06:24.427+00:00",
                                                    "originKey": "a7a2e011-9e7e-4a42-8603-eda2008b1910",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "56632923-c95f-4bc2-89ab-479ea7e4cc39",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "95c45b22-2836-4ca5-955b-92079d0a7875",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a3956a53-0727-4b95-b33a-09f3125c55d4",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b4ec35d8-b45d-40ea-a29a-0c51f598ce27",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "90693c9f-c472-4677-8b32-24b77db0fec2",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "b74e8f44-e3b0-4c81-a8aa-66a04eb18797",
                                                            "requirementId": "a14b0cec-3184-4917-b284-db1db6885183",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "696a4404-1351-4d7f-bd88-9f04f34e1b6c",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3495,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                    "name": "Do line managers check in with employees on a regular basis to allow them to communicate about any wellbeing concerns they may have?",
                                                    "descriptionHtml": "<p>It is recommended good practice that line managers check in with employees on a regular basis to provide them with the opportunity to speak about any issues they are facing, including issues affecting their mental health. This should be done on a regular basis to be effective.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:08:33.18+00:00",
                                                    "originKey": "1e01c305-69a4-4a15-b38b-5e29a628e349",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Health and safety: responsibilities and duties for schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06e481db-7775-4ee9-91cc-a2a08e545a03",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c2e19c6-bd36-4d35-9d82-ff1d000e3432",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9b1c15b9-bf50-426e-b4ca-4a15da0359e2",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a8d25b79-7283-43aa-9c63-d712a84c285c",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "b22e95b6-d082-40d8-a97f-fd997c39c9f1",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bc52bd31-4be6-429e-ad3f-cff14ab3c787",
                                                            "requirementId": "001de1e2-e2bc-4908-8f7f-d98e563c8542",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "23376cd7-b037-40ce-bb8d-a234e2776732",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3496,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                    "name": "Does the school regularly conduct pupil wellbeing surveys in order to learn about stressors present in school?",
                                                    "descriptionHtml": "<p>It is recommended good practice that pupil surveys are conducted to learn about and act upon stressors affecting pupils in school. You may wish to attach a blank copy of any recent pupil surveys you have sent around to support that your school takes part in this practice.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:11:28.813+00:00",
                                                    "originKey": "697d04c0-ca29-4351-9bae-860056889e82",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5b735dd7-562e-47c4-9755-4bde91e86053",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "83ccfa9e-268b-47de-b899-af5f3c00a646",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "bfacf1ed-5dbf-4d73-a5e7-d4f266e04e80",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c5dec022-a662-421e-8414-619a8986207d",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "688e5b48-a79b-48ea-85dc-c8b0c6c6dfdb",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "742f5241-a324-49ce-83b3-6bfc43a9e1da",
                                                            "requirementId": "48a57389-d27d-47e0-8c5a-991544c692cd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ec6fcc0a-bd5f-4d11-8976-dd266d34d5d8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3497,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                    "name": "Does the school have an overarching wellbeing strategy?",
                                                    "descriptionHtml": "<p>It is recommended good practice for the school to have an overarching mental health and wellbeing strategy in place and that this is regularly reviewed for its effectiveness. You may wish to upload any evidence to support the implementation of school's wellbeing strategy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:22:57.138+00:00",
                                                    "originKey": "0b68ceeb-cc8f-40f5-95c3-e9ed9b54cc50",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Health and safety: responsibilities and duties for schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "22548325-20de-4d84-a3fd-3b7e7b69f5e6",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "36714529-0efb-4cd4-9d0b-3e2a5bf00baa",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5e335e58-b56e-436c-a9b9-e4d47af39cd2",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "da9c1f23-193c-4223-a608-5fb2c3f3d56f",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "07637a37-40ae-4ee1-a5b5-e9845966fcaa",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bdff4aa1-7d40-48ea-8057-2b001973b164",
                                                            "requirementId": "d630f1b6-440e-4eaa-9421-183226cddda5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d7f049f9-0dc4-41b6-98db-666528552f90",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                "requirementAreaId": "8813735d-1f18-41d4-b7b7-e4f3e4bd3323",
                                                "displayOrder": 3498,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                    "name": "Does the school curriculum enable pupils to develop the knowledge they need to look after their own wellbeing?",
                                                    "descriptionHtml": "<p>It is recommended good practice that your school's curriculum allows pupils to develop the knowledge they need to look after their wellbeing. This may take place in lessons such as RSHE and PSHE. You may wish to upload supporting evidence such as the relevant curriculum policies or lesson plans.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:27:07.583+00:00",
                                                    "originKey": "3cb9d62b-3875-4a1d-9854-9eadc721dd6b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "205480b5-e542-4d95-ba79-29b6dc989a44",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "459c386c-869b-49be-a61b-7610c0061384",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5c082c59-a1e8-4d10-a14b-70d445713f2f",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f42eab6e-c22e-438e-916a-0641be6ce679",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "000d8be3-8768-4e0a-a5d0-8790f9878b29",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "37450ef1-b0c8-460b-b1c8-909fd75bc0b1",
                                                            "requirementId": "307c2f20-e1eb-4bbe-be7e-ffa840fdaf10",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "64faf678-9fb9-4b2e-9709-4bf50513f9c1",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3501,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                    "name": "Does the school keep a written record to show that equality duties have been actively considered in the development of policies, procedures and practices?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">It is good practice for schools to keep a written record to show that they have actively considered their equality duties and asked themselves relevant questions. Schools will not automatically be seen has not having due regard if they do not keep a record; however, It will be easier to demonstrate that the duty has been met if a record is made at the time.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:41:15.58+00:00",
                                                    "originKey": "402b6d74-58af-4030-a9fc-7bcf5881b0a2",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Equality Act 2010 and Schools</span></p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "6b3cdcf2-c5cf-43f4-a741-d30edda96bef",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "82c16671-3473-4b8c-83c8-9e448e3e3ea8",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cef7e428-f289-496a-a894-ea694f346b9b",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "fb5286c1-7930-49b0-8823-1a22d301b3c7",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "16dc7761-fd9c-4d39-83f0-57ff8b7ddee6",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7b1a35aa-3f5b-4e82-9acf-ca8ad08c058f",
                                                            "requirementId": "ec859064-a863-4d7c-a6d4-8ec2c270e539",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c987c25c-e1a8-4b1b-937b-0426709b6707",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3502,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                    "name": "Does the school produce equality schemes in relation to the protected characteristics individually?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schools were previously required to produce equality schemes related to race, disability and gender; however, under the specific duties as outlined in The Equality Act 2010, there are no requirements to create equality schemes. Schools, however, may choose to continue producing such a scheme if it helps them to comply with the Equality Duty and they can expand it to cover the additional protected characteristics</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:43:10.25+00:00",
                                                    "originKey": "125529ed-7622-455b-965c-997864581073",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Equality Act 2010 and Schools</span></p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "5597ade2-6650-4fb6-8694-35df85ec8cbb",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "839fcb32-f880-4cad-904a-09639eda304f",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "88bdad8c-2470-4cc8-b1a1-fa6d3e0cc7b9",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b309dd1f-c020-46eb-ac3d-e8b0fa012fc7",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0440e0c0-b0d4-4320-a2a1-02a276369d45",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c59d242f-70c6-4e95-91a8-eebdca45048a",
                                                            "requirementId": "bf83c720-2061-47c3-b43e-0ca4d6021cc5",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "24f9f726-aeb9-409a-b991-601fce728ee7",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3504,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                    "name": "Does the school have up-to-date and accurate data showing the first language and the ethnic and racial composition of the pupils and staff members in its school community?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">Schedule 2 of The Education (Pupil Information) (England) Regulations 2005 states that information forming part of the common transfer file should include information relating to each pupil's ethnic group and first language.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:45:54.545+00:00",
                                                    "originKey": "4d1ad8df-332e-4818-bbd7-6616c35a2fce",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.legislation.gov.uk/uksi/2005/1437/made</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "015051b9-f3c0-411c-a7ba-ff409315df0d",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "39dac2cd-0b92-4176-870c-af4875bf6b1c",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "726e871a-7bb4-4864-a11b-43d5137c4dd7",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f1d700d3-267b-4214-bc47-c801e58cd677",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "161eca01-6d0f-40ea-b0c0-8fc8ecae47d1",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c921f496-9328-4a17-8814-a04492f3926d",
                                                            "requirementId": "f00004cd-1525-48c3-9f2b-a6b1ec6f44a2",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "f35f1959-1072-458a-b2fe-9deea50c6f2f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3505,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                    "name": "Does the school understand the problems and issues faced by pupils and staff members from marginalised groups in the school?",
                                                    "descriptionHtml": "<p><span style=\"color: rgb(0, 0, 0);\">All workplaces and schools should ensure their pupils and staff feel valued. Marginalised groups should feel safe to come up with different ideas and raise issues with the SLT . Staff members and pupils from marginalised groups should be included in the creation and implementation of any policies related to supporting inclusion and promoting equality and diversity.</span></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T11:47:38.116+00:00",
                                                    "originKey": "a9e2e88d-5678-4cfb-ab2d-16d4d5aea6b1",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.acas.org.uk/improving-equality-diversity-and-inclusion/making-your-workplace-inclusive</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1a65d690-a297-4b3c-94b9-71022bbe6302",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "2d4e2c1c-3934-4daa-a5da-eb43708d91a2",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a05e09af-2408-44e9-a0f7-88f018042227",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f170cddd-1756-4546-a0a0-588bbd4e96d4",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "45325f86-0df8-43b6-a95f-4a16eb06131e",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e4d8158e-eecd-4ae9-b9d5-4d2308bb3541",
                                                            "requirementId": "effcb971-95da-48df-88f6-8448f5b8ed14",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "3fcf01f5-0166-4950-8b8c-25432ae94e0b",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 3541,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                    "name": "Has your school published information about its remote education offering?",
                                                    "descriptionHtml": "<p>It is&nbsp;no longer statutory&nbsp;for schools to publish information about their remote education provision, though the DfE recommends doing so if the school wishes to provide clarity and transparency to parents and prospective parents about what is offered.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:42:52.906+00:00",
                                                    "originKey": "dc0bbe32-2fb0-4a12-8960-8d0c6c056aac",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> DfE (2022) Providing remote education: guidance for schools</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "16043da3-aa8a-4298-ad3c-6969bc9a8747",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5b0eeab7-3005-4a5b-b16b-447ab10558e8",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9396d965-af60-49bd-9268-afa0e2cc48da",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0b4a562-7c82-4280-b0d3-cdd1fdc5ac02",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4293935d-8d2b-4aac-b7e5-1c52c4c1cfd5",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "58311fef-aa14-4c01-abe6-49bd3dd43e9c",
                                                            "requirementId": "59fc1e1a-4685-4040-87f5-07b4ddf50f08",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8abdfff2-d54b-4727-bfd9-4722b5c0f755",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                "requirementAreaId": "599bc526-906e-4276-8e63-36c99cd1c64b",
                                                "displayOrder": 3552,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                    "name": "If your school has fewer than 250 employees, has the school voluntarily published its public sector apprenticeship target?",
                                                    "descriptionHtml": "<p>The public sector apprenticeships target ended on 31 March 2022; however, the&nbsp;DfE is asking all schools and trusts with 250 or more employees to continue to gather, and later report on, new apprenticeship starts, the total number of apprentices, and an organisational headcount. This could be published on the school or trust’s website, although it is not the only way to communicate this information.</p><p><br></p><p>If your school or trust has fewer than 250 employees and you have not chosen to provide this information voluntarily, please select 'Does not apply' for this requirement. <strong>If your school has 250 employees or more, it is recommended that you publish this information. Click 'Does not apply' on this requirement for now; another requirement already covers this basis for you.</strong></p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-31T10:54:13.752+00:00",
                                                    "originKey": "5965c432-c011-42f3-b52d-76c9ff1f5140",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>DfE (2022) Public sector apprenticeship target</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0ff3ee94-4363-4119-b07d-6efc136faa53",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4e0de816-c4d7-41f5-b5b8-9852fa02c21d",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "64e98fc0-a8fd-4ab3-99aa-8262fbf90823",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "784c8c5c-199a-448a-b564-b2cfae2c9f12",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "528edead-9391-4f28-a4cd-4bb53b60a130",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "923dad44-c8f4-40b9-8b22-f690c3c4c191",
                                                            "requirementId": "87027d43-9a59-434d-8b3c-50edf3bf24f1",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "c9726c49-0739-4d74-85b8-089f7bf27ba4",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 3570,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                    "name": "Does the governing board carry out an annual review of the school’s approach to online safety?",
                                                    "descriptionHtml": "<p>It is advised in ‘Keeping children safe in education’ that schools should consider carrying out an annual review of their approach to online safety, supported by an annual risk assessment that considers and reflects the risks their children face. Annual reviews should be supported by a risk assessment that considers the school’s specific circumstances.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:48:41.613+00:00",
                                                    "originKey": "69a98f22-acf0-43d1-a2e1-46fb3732a55d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "46ceaeb5-302a-4442-b6c7-f151948b04f7",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "72f39ec2-e141-44f8-a40c-13b83281287f",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7b5b2e8b-57af-4474-8473-dfae1ce76109",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a1770b3e-df22-4f1f-a31c-b45b551de734",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f4e6662-478e-4f60-9a88-cddce4b6db95",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "63695f40-b80f-4246-b8c6-0469fc081f9b",
                                                            "requirementId": "e3ecb814-716f-426e-9c1f-ed34db9c73f9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "049fc533-ce9f-490d-8704-424d70463576",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 3575,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                    "name": "Does the school have procedures in place to conduct online searches on shortlisted candidates?",
                                                    "descriptionHtml": "<p>As part of the shortlisting process, schools should consider carrying out an online search as part of their due diligence on the shortlisted</p><p>candidates. This may help identify any incidents or issues that have happened, and are publicly available online, which the school might want to explore with the applicant at interview.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school's policy that covers recruitment procedures.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-01T16:52:54.933+00:00",
                                                    "originKey": "6f36b554-4e0d-45db-abaa-185cf29a2734",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "01d70aa5-15c3-4221-8048-35e29be692c5",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "1fa71987-e64d-42c7-a13f-310c883181cf",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45bb4150-a78d-45fb-bdb4-cf20ff006c0b",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a6714ea4-e45e-4e51-a6c8-2798ac1cfe44",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "5d71f549-c764-4ffe-a7fa-28a5f34ffac2",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "c50f08ca-2395-43d6-929a-f9fb3caca7f4",
                                                            "requirementId": "22219bcd-b400-4e88-a3b8-715a0d9c1971",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "ae48db4e-d460-4949-a69d-48accd1e248a",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 3666,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                    "name": "Is school property visibly marked and subject to a physical annual check?",
                                                    "descriptionHtml": "<p>In order to ensure assets are managed and kept secure, school or trust property should be visibly marked where appropriate and be subject to a physical check each year.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:08:27.996+00:00",
                                                    "originKey": "da65c65d-f04c-48a1-84d8-625c23361696",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford LA guide</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "18ae8b68-9771-444a-b6f6-50dc7487a63a",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "9e3e3f4a-1c8e-4257-ad95-dc69fa631077",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf178349-c8fe-4733-9ca8-a50cfff7095b",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d9ba3e2c-4694-4773-977e-faff134b0500",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "0344edf5-35a2-46b3-a374-7d08ebb9d0e8",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "bbd6464b-0668-45bf-8586-ae57e9967477",
                                                            "requirementId": "ef38c05b-0ca8-476e-83ea-db99805ab5e8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "29b18a05-8742-4643-aeb3-c1e0f4ee7772",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 3675,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                    "name": "Have authorisation limits for signing orders and certifying payments been set and documented?",
                                                    "descriptionHtml": "<p>The limits for the authorisation of transactions by each person or committee should be determined by the school by reference to the type of transaction, the size and the organisation of the school.&nbsp;Authorisation limits should be documented and communicated to all budget holders. A list of school staff authorised to sign orders should be maintained.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:14:17.744+00:00",
                                                    "originKey": "53007a86-644e-49f8-8515-22ca15fd96a3",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> Bradford LA</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "1dcfdea3-24f6-4a18-9430-a2b429430009",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8b5aa97b-8ad7-4410-a3fe-87d8c793563d",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c83606ad-514c-4c29-9eb5-08ef411d9917",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "da3393f6-7c0e-4fac-a7c4-7ffeab01c29a",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3f0408c9-467c-452c-8049-c4ad5cd26733",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "4795579d-b266-4843-85ae-20d0ed752428",
                                                            "requirementId": "04a8f953-89e0-4e19-9fce-87a4c21b5505",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6faf0436-2cbd-4f92-824c-072252b72ed8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 3677,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                    "name": "Has the school assessed its historic liability for claims on holiday pay? ",
                                                    "descriptionHtml": "<p>Following the Supreme Court's ruling on the Harpur v Brazel case, schools are advised to review their historic liability and consider whether they are likely to be subject to any claims about how holiday pay for part-year workers has been calculated; this includes making a judgement call on whether to make a back-payment rather than bearing the risk of a claim. Legal advice should be sought where necessary.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-09-02T10:15:29.655+00:00",
                                                    "originKey": "429028b1-1aef-458f-8b9d-7d5475d29445",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p> <a href=\"https://www.theschoolbus.net/article/how-does-harpur-v-brazel-affect-holiday-pay-for-termtime-only-workers/9182\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.theschoolbus.net/article/how-does-harpur-v-brazel-affect-holiday-pay-for-termtime-only-workers/9182&nbsp;</a></p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "06891665-758e-46d9-8262-cdfd0ceb2432",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "25988f6b-9daa-4169-aae7-43f97136fcc5",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "6aadfcb5-790e-48e4-a756-bef7baa8d4aa",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "7dde35db-a306-4f91-b0fe-b210da94b97d",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a28451f6-a373-47c1-baeb-bc6a4f8c0c76",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "ca601f71-02e6-4b7e-ac46-36ad9e7af56b",
                                                            "requirementId": "3aea08e3-71a4-4d2f-8487-4bed93f7d1c7",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "80700859-e669-4b6d-a418-20859fb8691e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                "requirementAreaId": "bb7c40be-f330-4fec-ba54-0a368fa91a0f",
                                                "displayOrder": 3678,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                    "name": "Does your school have a policy covering environmental sustainability?",
                                                    "descriptionHtml": "<p>Schools should recognise their responsibility to ensure that pupils have the necessary skills and knowledge to understand environmental concerns, so that they can apply this to their lives and adopt an eco-friendly lifestyle.</p><p><br></p><p>Implementing a policy on environmental sustainability can help to encourage both pupils and staff to think about the environment and how their actions will impact upon their local surroundings, as well as the global environment. This does not need to be a standalone policy.</p><p><br></p><p>You may wish to link to or attach your school’s related policy.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T09:13:25.25+00:00",
                                                    "originKey": "88113df2-1e36-4c97-a7f4-95b9a266c2db",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>https://www.theschoolbus.net/article/environmental-sustainability-policy/5540</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "74b91e96-5dd9-4385-abb4-124561e5d611",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c2042814-1924-4268-b900-ae85e41285b7",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cbe806c1-3b9e-4152-bf42-d9264a464cee",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e75916eb-9d2e-41a1-aa89-ab56d3a4cbc5",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "17335179-b306-447f-a97b-8b84707c462a",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "7b82153d-d52d-4d5c-9604-0e1d31547d64",
                                                            "requirementId": "40b437de-185b-48c5-890f-ca7274b51fa4",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "8f34a79e-5fb7-466d-a4b7-63dad352f4cb",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3678,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                    "name": "[Schools that do not meet the threshold should mark this as not applicable] Has your school disclosed the necessary information to meet the Streamlined Energy and Carbon Reporting (SECR) requirements?",
                                                    "descriptionHtml": "<p>SECR requires large unquoted companies that have consumed (in the UK) more than 40,000 kilowatt-hours (kWh) of energy in the reporting period to include energy and carbon information within their directors' (trustees') report, for any period beginning on or after 1 April 2019.</p><p><br></p><p>Large companies, as defined in sections 465 and 466 of the Companies Act 2006, are companies that meet two or more of the following criteria:</p><p><br></p><ul><li>Turnover (or gross income) of £36 million or more</li><li>Balance sheet assets of £18 million or more</li><li>250 employees or more</li></ul><p><br></p><p>Some academy trusts will fall into the above criteria and, therefore, need to meet the requirements.&nbsp;</p><p><br></p><p>The ESFA encourages large academy trusts to reproduce the energy and carbon disclosures from their accounts in a readily accessible format on their website before 31 March each year.</p><p><br></p><p>You may wish to link to or attach information about how you meet the SECR requirements.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:45:45.522+00:00",
                                                    "originKey": "cf91877f-79ab-4119-b7e3-48e687ffbde0",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Streamlined Energy and Carbon Reporting (SECR) for academy trusts</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "08a5df2d-0511-4cb5-bc4c-d1f4afc1e435",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a345e0d1-df96-477b-a9d7-6b02748fa391",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d01dd6d5-d1c7-4bf0-9aa1-66b88440d367",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e7a7e975-59a4-4836-94c1-53dd8d515753",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "4fc1b032-4673-4b18-9424-16d2a51e0665",
                                                            "requirementId": "65c6553f-efd5-4023-a368-6c085aec64f9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0cb885f4-9ec6-4549-9d1f-11ed8daf77a8",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                "requirementAreaId": "2a53a926-ec02-4c46-b30a-9f9261de8141",
                                                "displayOrder": 3679,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                    "name": "Does your school have an approach to managing and reducing its energy and water use?",
                                                    "descriptionHtml": "<p>Schools need to have a consistent and challenging approach to:</p><p><br></p><ul><li>Monitoring and managing energy and water use.</li><li>Reducing energy and water use.</li><li>Maximising energy efficiency in buildings.</li></ul><p><br></p><p>You may wish to link to or attach your school’s relevant procedures.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:05:57.649+00:00",
                                                    "originKey": "57972261-c8b0-4229-a75e-8f9b2d7640d6",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years.</p>",
                                                    "requiresReviewInDefaultMonths": 24,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "50a9e3f3-0e25-4d8f-97c4-f7317545509d",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "5903e8da-ddb2-4fa6-bc92-35d0ddaf1c16",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "adc85acf-7db0-4122-9fdd-1246f253111e",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f0000412-52ee-44e3-86b0-bf887a3373b7",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "1733d1b7-73f4-498b-a806-285af23c555d",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "56a5d606-51a4-49db-aee4-cb20d5c0b8c4",
                                                            "requirementId": "968800fb-6931-4483-a8d7-764d21b9f152",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "0d27cbf7-dd82-44cc-ba9d-bd79b950dd3e",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3680,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                    "name": "Does your school have procedures in place to protect the wellbeing and mental health of staff with disabilities, e.g. protection from discrimination?",
                                                    "descriptionHtml": "<p>The Equality Act 2010 makes it unlawful for any employer to discriminate against disabled people. All employers should be able to make changes to the way that an individual’s employment is structured when required, including removing physical barriers to working and/or providing extra support so that disabled employees are not disadvantaged.</p><p><br></p><p>Employers have a duty to make reasonable adjustments to ensure that disabled employees have the same access to everything involved in doing and keeping a job as non-disabled workers.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T14:57:44.199+00:00",
                                                    "originKey": "1d0302db-4125-43e1-98d1-2d760d666e7e",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "98e754f7-0d5c-4959-8c06-b471913ebbc1",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "990d2b8a-e9d9-483f-8c0b-60370860c859",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "aee00684-6091-4a0f-8603-cc52e6fe70a6",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "c1c9a28c-5ce7-4d73-b966-de4a4ef7ad33",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "44ee6c10-1ba0-4177-8d0b-410cb71f482d",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "97bcbc81-1340-4b88-9199-f585aec7e695",
                                                            "requirementId": "c683f23d-96b2-4ff5-8627-dd2c18ad2150",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "6c4551e8-cdaa-46a0-b7d5-e07efe392b47",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                "requirementAreaId": "d2120f1a-f641-481a-b11d-c21436abcaa1",
                                                "displayOrder": 3681,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                    "name": "Do your school’s policies and procedures take into consideration that prolonged and substantial mental health issues may be considered a disability?",
                                                    "descriptionHtml": "<p>Your school must ensure its policies and procedures take into account that prolonged and substantial mental health issues may be considered a disability under definitions outlined in the Equality Act 2010. You could link to or upload where these procedures are outlined as evidence.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:10:37.599+00:00",
                                                    "originKey": "106c3977-4a05-4098-a7ba-8d6b0100a979",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>Equality Act 2010</p>",
                                                    "policyManagerId": 15,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3242957c-0211-4d15-a04f-12010dc419cf",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "8c51c5a4-7c81-4464-a1e1-c04f4e3ac261",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "94797eb7-f2c7-44d8-94fb-a0fcbed4a356",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ec0cf208-7c3e-475e-8caf-170570b4b43f",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "a5d68926-7658-49ae-9ada-d65174ef9863",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e7898d04-f50f-486d-b35d-37a8e92b6c39",
                                                            "requirementId": "e09c098e-2495-40a8-bc4a-f9098dd75ab8",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "5eef7584-1c55-4676-a35d-ba2f3d385111",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 3682,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                    "name": "Does your school involve the designated person responsible for health and safety in changes to the workplace or work practices that could cause stress?",
                                                    "descriptionHtml": "<p>Your school must ensure that the person responsible for health and safety is involved in the implementation of changes to the workplace or workplace practices. This includes changes to the workplace or work practice that are in place to mitigate the risk of stress.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:13:38.59+00:00",
                                                    "originKey": "6a98689b-04ad-4ed8-b04f-6717146f0a9d",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8f1cd79a-9e26-4768-be05-33a440d126a5",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d565d5b9-5e5c-45a7-be45-4ad8f579684e",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "d5f08f76-0d84-4ef5-95ac-c08d8f8c172f",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f5c3ef06-f0f5-4a36-a647-b64a62544c36",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "2b8a3568-427e-4319-a7a2-86bff65e4b3a",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "37b5e3f3-69ca-431d-8f1f-db31416e4ada",
                                                            "requirementId": "57742a4d-3e34-418f-a182-cbcb6df70df9",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "b5abf89d-667a-4086-b4a2-b42443889e69",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 3683,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                    "name": "Does your school involve the designated person responsible for health and safety in the creation and undertaking of relevant risk assessments?",
                                                    "descriptionHtml": "<p>Your school must ensure that the person responsible for health and safety is involved in the creation and undertaking of health and safety-related risk assessments, including those relating to stress and wellbeing.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-23T15:16:01.206+00:00",
                                                    "originKey": "cc73e060-2723-4196-b1d9-0e79e0273752",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>HSE 'Tackling work-related stress using</p><p>the Management Standards approach'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Bronze",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "3ac0dc49-a72e-4306-a00a-cfa65bf0db31",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "45f08474-6262-402c-af50-5b6fd817100d",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "abb67dad-10cd-4107-907e-b2528ad9e131",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ff47362b-8e0f-4b80-8fe4-c74a7b2521c5",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "3ed1a52d-076a-4c9c-b4d3-010388549fd4",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "52b333a0-2685-4f82-b18c-94c16a372466",
                                                            "requirementId": "17edff2d-3d9c-43f0-a4af-01a0b180f449",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "1aa46387-475d-4a28-a415-8428b73a77bd",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 3684,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                    "name": "Are school staff trained to identify the potential need for early mental health help for vulnerable pupils?",
                                                    "descriptionHtml": "<p>School staff should be trained to identify the potential need for early mental health help for vulnerable pupils. This training could take place in-person or online. You may wish to upload an example of any training certificates.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-24T15:25:04.835+00:00",
                                                    "originKey": "f2a61f2e-19a8-441a-8b29-9fd03f92bc3b",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": false,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": false,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "8bc12286-e898-490c-bc88-f2709c96b6dd",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "90f4d20c-da42-4b41-9636-e4ad32f38d78",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "a0fd146a-423a-4b36-8823-a8c5299ce0fe",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ed0ef93c-ed60-4f67-bf82-ba00245bfc7d",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "315c64fb-8212-4956-9dfe-c9b0c0dd635a",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "6aa81797-574e-42f0-aa28-96186ce5b9e6",
                                                            "requirementId": "05c444a0-605b-4984-9c9a-b634806515bd",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d9ecabd4-265c-4071-b940-dfbc4c8b22e0",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                "requirementAreaId": "d567befc-cfbe-4a49-a421-12e3fcb5aaec",
                                                "displayOrder": 3685,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                    "name": "Are there procedures in place to effectively manage bullying and harassment against pupils?",
                                                    "descriptionHtml": "<p>Your school should have procedures in place to effectively protect pupils from bullying and harassment and to manage incidents and consequential mental health issues if they arise. You may wish to upload any copies of the relevant policies that outline how bullying and harassment will be managed.</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-26T10:52:53.024+00:00",
                                                    "originKey": "8a2384e7-94e3-4905-82f8-edd4e3c7a8f9",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>KCSIE 22</p>",
                                                    "policyManagerId": 3,
                                                    "policyManagerType": "linked",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0796a9b5-93c4-418a-9621-fa301bf87bd1",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "0968ac90-e105-442a-b9da-d91c0be389f9",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "4ddaab4c-ec5f-4c01-a396-44b67e876f5d",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "b8c40dfb-aa27-497a-8bfb-161252035106",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "9b0920c2-9f6d-48cf-9c88-e554bbe94ffa",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "fb6df407-ff77-4fe2-8581-85b99b81c5e2",
                                                            "requirementId": "55b7d6dd-b02a-43b0-b0db-6adb4465cf33",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "98abaf41-7458-45eb-b9f3-f46ea2e2deef",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                "requirementAreaId": "39b50ba7-54a5-4824-b744-20eb07957b4c",
                                                "displayOrder": 3686,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                    "name": "Does your school have a three-to-five-year budget plan for the estate?",
                                                    "descriptionHtml": "<p>Schools should have a three-to-five-year budget plan for the estate. The plan should include:</p><p><br></p><ul><li>A prioritised maintenance plan.</li><li>Information on how you will achieve value for money through investment.&nbsp;</li><li>Actual and potential funding sources.&nbsp;</li></ul><p><br></p><p>The budget plan should be included as part of the estate strategy. You may wish to link to or attach your school’s estate strategy.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T07:58:34.335+00:00",
                                                    "originKey": "77a88a63-a416-4a68-befc-51b544bb288f",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement every two years</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "0243cf09-38fe-46aa-af4c-e447c159d7f6",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "cf39c1c8-e358-4858-96d3-706607b88fd3",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "e7b688b0-92b3-42ec-bc63-fbbfdc17f2b8",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "65dd2136-ee63-4140-83ed-6673fdd41a49",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "e12ca35b-3500-4be8-88ee-650d6ca0ccf6",
                                                            "requirementId": "7d3b45ad-61f4-4f78-bf17-3e0c118fa762",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "d69bb1be-0c8b-49c0-8a6f-439cbb77fa91",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                "requirementAreaId": "bc67fac8-4c8d-4612-874a-6279e10138ae",
                                                "displayOrder": 3687,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                    "name": "Has your school conducted a legionella risk assessment?",
                                                    "descriptionHtml": "<p>To help make sure the school estate is safe, schools should conduct a legionella risk assessment. Risks need to be identified and assessed in accordance with the relevant HSE code of practice.&nbsp;</p><p><br></p><p>You may wish to link to or attach your school’s risk assessment.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2022-08-30T08:37:48.456+00:00",
                                                    "originKey": "f4a8ce88-8da7-4550-9b88-107f0c56f27c",
                                                    "isStronglyRecommended": false,
                                                    "sourcesHtml": "<p>GEMs</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Silver",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "19df0c28-3bb2-4378-bec6-6081d7d431cd",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "67b3388e-84b9-4187-aa56-1832ae6b9dbe",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "ade4fad2-ebaf-42df-b570-778d30332f5f",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "f755c2ae-c6b7-4184-a2c5-d23a3822fa51",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "73b17e47-1f34-4c96-93ee-078f443d6b78",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "a1518889-6c00-48a3-a710-482d4359f394",
                                                            "requirementId": "1ceac1cd-7159-41c6-907c-02977b883623",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            },
                                            {
                                                "id": "93573290-9457-4c2a-b074-44528a17707f",
                                                "requirementsReleaseId": "59e44639-8815-4ca1-9b4b-19d14b540af6",
                                                "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                "requirementAreaId": "0fed3d3e-9284-462b-a403-7ccb2facbf2f",
                                                "displayOrder": 3688,
                                                "archived": false,
                                                "requirement": {
                                                    "id": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                    "name": "Has the DPO prepared a report to the governing board on the school’s compliance with data protection legislation?",
                                                    "descriptionHtml": "<p>A DPO needs to be able to report directly to the governing board on the school’s compliance with data protection legislation. It is for schools to decide how often the DPO should report to the board, but this should be done on at least an annual basis.&nbsp;</p><p><br></p><p>You may wish to attach or link to the DPO’s latest report to the board.&nbsp;</p>",
                                                    "archived": false,
                                                    "versionDate": "2020-09-03T10:10:18.955+00:00",
                                                    "originKey": "0a69931c-cdb5-43a4-a69d-d63d03042f8c",
                                                    "isStronglyRecommended": true,
                                                    "sourcesHtml": "<p>DfE (2018) 'Data protection: a toolkit for schools'</p>",
                                                    "policyManagerId": 0,
                                                    "policyManagerType": "",
                                                    "requiresReview": true,
                                                    "requiresReviewDescriptionHtml": "<p>We recommend you review this requirement annually.</p>",
                                                    "requiresReviewInDefaultMonths": 12,
                                                    "notesHtml": "",
                                                    "allowEvidenceUpload": true,
                                                    "standardRating": "Gold",
                                                    "videoId": null,
                                                    "appliesToSchoolPhases": [
                                                        {
                                                            "id": "2180807e-8fc5-4dbe-9170-a5c6f33efc4c",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "6d5680cb-32ae-4682-9db8-2e8abeae5f88",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "257555fd-8261-4013-ad79-024ed4eb1426",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "97875660-57c9-4598-b0a7-52389c5efbaf",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "358cdb6e-e27e-4c70-a8dd-e738aeafe1e3",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "3fe26f9e-4e66-467a-8cdc-6f0669324d9d",
                                                            "__typename": "RequirementSchoolPhase"
                                                        },
                                                        {
                                                            "id": "81a1bc76-fa6e-4ef8-ad28-e932e34b7bdf",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolPhaseId": "2687d6b6-fd6e-479c-bbc7-5674bc16b88a",
                                                            "__typename": "RequirementSchoolPhase"
                                                        }
                                                    ],
                                                    "appliesToSchoolTypes": [
                                                        {
                                                            "id": "05ee97ca-d2cd-4a6e-a5bc-912e38086e41",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolTypeId": "c1c4ebbc-03d8-4725-8824-338bcd5ac5c7",
                                                            "__typename": "RequirementSchoolType"
                                                        },
                                                        {
                                                            "id": "3738a170-5e6e-4f67-8ccd-dfe27f507cfa",
                                                            "requirementId": "9e5dd759-154c-4cb4-b47b-4c50763fb030",
                                                            "schoolTypeId": "8d8150d5-a4b4-43d1-96de-08aa725b678c",
                                                            "__typename": "RequirementSchoolType"
                                                        }
                                                    ],
                                                    "__typename": "Requirement"
                                                },
                                                "__typename": "CurrentRequirementsRelease_RequirementsReleaseRequirement"
                                            }
                                        ],
                                            "__typename": "CurrentRequirementsRelease"
        }
    }
} as any;
