import './roundedButtonGroup.scss';

import * as React from 'react';
import { ButtonGroupProps, ButtonGroup } from 'reactstrap';

export interface RoundedButtonGroupProps extends ButtonGroupProps {
}

/**
 * ButtonGroup with rounded corners.
 * @param props
 */
export const RoundedButtonGroup = (props: RoundedButtonGroupProps) => {
    const { className, children, ...rest } = props;

    return (
        <ButtonGroup {...rest} className={`rounded-button-group ${className ?? ''}`}>
            {children}
        </ButtonGroup>
        );
};
