import * as React from 'react';
import { SchoolSchoolPhaseCreateInput, SchoolSchoolPhaseUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSchoolSchoolPhaseCallback } from './useCreateSchoolSchoolPhaseCallback';
import { useUpdateSchoolSchoolPhaseCallback } from './useUpdateSchoolSchoolPhaseCallback';
import { SchoolSchoolPhase } from '../models/SchoolSchoolPhase';

/**
 * Get a callback to save a SchoolSchoolPhase in the store using either a create or update.
 */
export function useSaveSchoolSchoolPhaseCallback(options: SaveInStoreOptions<SchoolSchoolPhase, string> = {}) {
    const [_create, createStatus] = useCreateSchoolSchoolPhaseCallback();
    const create = React.useCallback((model: Partial<SchoolSchoolPhase>) => _create(model as SchoolSchoolPhaseCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSchoolSchoolPhaseCallback();
    const update = React.useCallback((id: string, changes: Partial<SchoolSchoolPhase>) => _update(id, changes as SchoolSchoolPhaseUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
