import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { SchoolAreaCompliance } from "./SchoolAreaCompliance";

export const schoolComplianceRoutes: Array<RouteEntry> = [
    { path: '/compliance/:requirementAreaId', exact: true, component: SchoolAreaCompliance, authorize: true },

    // New style other school routing.  Should be used for all new links.
    { path: '/compliance-for/:schoolId/:requirementAreaId', exact: true, component: SchoolAreaCompliance, authorize: true, requireRole: IdentityRoles.MATUser },
    // Old style other school routing, left for backwards compatability.
    { path: '/compliance/:requirementAreaId/:schoolId', exact: true, component: SchoolAreaCompliance, authorize: true, requireRole: IdentityRoles.MATUser },
];
