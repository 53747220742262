import { RouteEntry } from "../../shared/routes";
import { AdministrationNavigation } from "./AdministrationNavigation";
import { Administration } from "./Administration";
import { IdentityRoles } from "../../configure/security/IdentityRoles";

export const administrationRoutes: Array<RouteEntry> = [
    { path: '/administration', exact: true, component: Administration, authorize: true, requireRole: IdentityRoles.Administration },
];

export const administrationSidebarRoutes: Array<RouteEntry> = [
    { path: '/administration', component: AdministrationNavigation, authorize: true, requireRole: IdentityRoles.Administration },
];
